import styled from 'styled-components';

export const Grid = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CalendarGrid = styled.div`
  padding: 16px 12px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};

  .react-calendar {
    width: 100%;
    background: transparent;
    border: none;
  }

  .react-calendar__navigation__label {
    font-family: ${({ theme }) => theme.fontFamily};
    font-weight: bold;
    font-size: 16px;
  }

  .react-calendar__navigation button:enabled:hover {
    background: ${({ theme }) => theme.colors.backgroundDark};
    border-radius: 24px;
  }

  .react-calendar__navigation__arrow {
    font-size: 24px;
    font-weight: bold;
  }

  .react-calendar__month-view__weekdays__weekday,
  abbr {
    font-family: ${({ theme }) => theme.fontFamily};
    color: ${({ theme }) => theme.colors.grayText};
    text-decoration: none;
  }

  .react-calendar__tile--now {
    background: transparent;
  }

  .react-calendar__tile--hasActive {
    &:enabled:hover,
    &:enabled:focus {
      background: ${({ theme }) => theme.colors.blue};
      abbr {
        color: ${({ theme }) => theme.colors.white};
        font-size: 14px;
      }
    }
  }

  .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;

    abbr {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      color: ${({ theme }) => theme.colors.black};
      font-family: ${({ theme }) => theme.fontFamily};
      font-size: 14px;
      font-weight: 500;
      border-radius: 24px;
    }

    &:enabled:hover {
      background: transparent;
    }

    &--active {
      background: transparent;

      abbr {
        background: ${({ theme }) => theme.colors.white};
        color: ${({ theme }) => theme.colors.blue};
        border: 2px solid ${({ theme }) => theme.colors.blue};
      }

      &:enabled:hover,
      &:enabled:focus {
        background: transparent;

        abbr {
          background: ${({ theme }) => theme.colors.white};
          color: ${({ theme }) => theme.colors.blue};
          border: 2px solid ${({ theme }) => theme.colors.blue};
        }
        &.goalMetDay > abbr {
          background: ${({ theme }) => theme.colors.yellowDark};
          color: ${({ theme }) => theme.colors.black};
          border: 2px solid ${({ theme }) => theme.colors.yellow};

          &:hover {
            background: ${({ theme }) => theme.colors.yellow};
            color: ${({ theme }) => theme.colors.black};
          }
        }
      }
    }
  }

  .activeCalendarDay > abbr {
    background: ${({ theme }) => theme.colors.blue};
    color: ${({ theme }) => theme.colors.white};
  }
  .goalMetDay {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-right: 3.33px !important;
    padding-left: 3.33px !important;

  }
  .goalMetDay.goalMetDay > abbr {
    background: ${({ theme }) => theme.colors.yellowDark};
    color: ${({ theme }) => theme.colors.white};
    border: 2px solid ${({ theme }) => theme.colors.yellow};
    width: 45px;
    height: 40px;


  clip-path: polygon(
    50% 0%,
    61% 35%,
    98% 35%,
    68% 57%,
    79% 91%,
    50% 70%,
    21% 91%,
    32% 57%,
    2% 35%,
    39% 35%
  );

}
`;

export const Action = styled.div`
  display: flex;
  justify-content: center;
  border-top: 1px solid ${({ theme }) => theme.colors.borderLight};
  padding: 8px;
`;
