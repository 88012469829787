import React, { FC, useCallback } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { parseDate } from 'utils/date';
import { ActionContainer, TwoColGrid } from 'styles';
import { User, UserProfileValues } from 'models';
import { genders } from 'assets/data';

import Button from 'components/UI/Button/Button';
import TextField from 'components/UI/TextField/TextField';
import SelectField from 'components/UI/SelectField';

type Props = {
  submitForm: (values: UserProfileValues) => void;
  onDeleteUser: () => void;
  data: User;
};

const EditProfileForm: FC<Props> = ({ submitForm, onDeleteUser, data }) => {
  const intl = useIntl();
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      firstName: data.firstName || null,
      lastName: data.lastName || null,
      gender: data.gender || 'Unknown',
      dateOfBirth: data.dateOfBirth ? parseDate(data.dateOfBirth) : null,
      lengthCm: data.lengthCm || null,
      weightKg: data.weightKg || null,
    },
  });

  // Submit
  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    (values) => {
      submitForm({
        firstName: values.firstName,
        lastName: values.lastName,
        gender: values.gender,
        dateOfBirth: values.dateOfBirth,
        lengthCm: values.lengthCm,
        weightKg: values.weightKg,
      });
    },
    [submitForm]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        label={intl.formatMessage({
          id: 'inputFirstNameLabel',
          defaultMessage: 'First name',
          description: 'Label for first name input',
        })}
        placeholder={intl.formatMessage({
          id: 'inputFirstNamePlaceholder',
          defaultMessage: 'Enter first name',
          description: 'Placeholder for first name input',
        })}
        error={errors.firstName}
        register={register('firstName', {
          required: intl.formatMessage({
            id: 'inputErrorFieldRequired',
            defaultMessage: 'Field is is required',
            description: 'Input error for field required',
          }),
        })}
      />
      <TextField
        label={intl.formatMessage({
          id: 'inputLastNameLabel',
          defaultMessage: 'Last name',
          description: 'Label for last name input',
        })}
        placeholder={intl.formatMessage({
          id: 'inputLastNamePlaceholder',
          defaultMessage: 'Enter last name',
          description: 'Placeholder for last name input',
        })}
        error={errors.lastName}
        register={register('lastName', {
          required: intl.formatMessage({
            id: 'inputErrorFieldRequired',
            defaultMessage: 'Field is is required',
            description: 'Input error for field required',
          }),
        })}
      />
      <TwoColGrid>
        <SelectField
          label={intl.formatMessage({
            id: 'inputGenderLabel',
            defaultMessage: 'Gender',
            description: 'Label for gender input',
          })}
          register={register('gender', {
            required: intl.formatMessage({
              id: 'inputErrorFieldRequired',
              defaultMessage: 'Field is is required',
              description: 'Input error for field required',
            }),
          })}
          options={genders}
          error={errors.gender}
        />
        <TextField
          label={intl.formatMessage({
            id: 'inputBirthDateLabel',
            defaultMessage: 'Birth date',
            description: 'Label for birth date input',
          })}
          placeholder={intl.formatMessage({
            id: 'inputBirthDatePlaceholder',
            defaultMessage: 'Enter birth date',
            description: 'Placeholder for birth date input',
          })}
          type="date"
          error={errors.dateOfBirth}
          register={register('dateOfBirth', {
            required: intl.formatMessage({
              id: 'inputErrorBirthDateRequired',
              defaultMessage: 'Birth date is required',
              description: 'Input error for birth date required',
            }),
          })}
        />
      </TwoColGrid>
      <TwoColGrid>
        <TextField
          label={intl.formatMessage({
            id: 'inputLengthLabel',
            defaultMessage: 'Length (cm)',
            description: 'Label for length input',
          })}
          placeholder={intl.formatMessage({
            id: 'inputLengthPlaceholder',
            defaultMessage: 'Enter length (cm)',
            description: 'Placeholder for length input',
          })}
          type="number"
          error={errors.lengthCm}
          register={register('lengthCm')}
        />
        <TextField
          label={intl.formatMessage({
            id: 'inputWeightLabel',
            defaultMessage: 'Weight (kg)',
            description: 'Label for weight input',
          })}
          placeholder={intl.formatMessage({
            id: 'inputWeightPlaceholder',
            defaultMessage: 'Enter weight (kg)',
            description: 'Placeholder for weight input',
          })}
          type="number"
          error={errors.weightKg}
          register={register('weightKg')}
        />
      </TwoColGrid>
      <ActionContainer>
        <Button type="submit" background="blue" disabled={!isDirty}>
          <FormattedMessage
            id="confirmButton"
            defaultMessage="Confirm"
            description="Confirm button text"
          />
        </Button>
      </ActionContainer>
      <ActionContainer>
        <Button background="transparent" color="error" onClick={onDeleteUser}>
          <FormattedMessage
            id="deleteAccountButton"
            defaultMessage="Delete account"
            description="Delete account button text"
          />
        </Button>
      </ActionContainer>
    </form>
  );
};

export default EditProfileForm;
