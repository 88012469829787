import React, { FC, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';

import Button from 'components/UI/Button/Button';

import { ActionContainer } from 'styles';
import SelectField from 'components/UI/SelectField';
import { useGetCompanyStructureQuery } from 'store';
import { SelectOption } from 'models';
import { FieldGrid } from './styles';
import Loader from 'components/UI/Loader';
import { usePretendCompany } from 'hooks/usePretendCompany';

type Props = {
  submitForm: (data: CreateDepartmentFormData) => void;
  departmentId?: string;
};

export type CreateDepartmentFormData = {
  departmentId: string;
};

const UpdateUserDepartmentForm: FC<Props> = ({ submitForm, departmentId }) => {
  const intl = useIntl();
  const companyId = usePretendCompany();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<CreateDepartmentFormData>({
    defaultValues: {
      departmentId: departmentId ?? '',
    },
  });

  const { data, isLoading } = useGetCompanyStructureQuery({ companyId });

  const departmentOptions: SelectOption[] = useMemo(() => {
    if (!data) {
      return [];
    }

    const departments = (data.departments ?? []).map(({ id, description }) => ({
      value: id,
      name: description ?? '',
    }));

    const headOfficeDepartments = (data.headOffices ?? []).flatMap(
      (headoffice) =>
        (headoffice.offices ?? []).flatMap((office) =>
          (office.departments ?? []).map((department) => ({
            value: department.id,
            name: `${department.description} - ${office.description} (${headoffice.description})`,
          }))
        )
    );

    const officeDepartments = (data.offices ?? []).flatMap((office) =>
      (office.departments ?? []).map((department) => ({
        value: department.id,
        name: `${department.description} - ${office.description}`,
      }))
    );

    // concat and sort departments alphabetically
    return [
      ...departments,
      ...officeDepartments,
      ...headOfficeDepartments,
    ].sort((a, b) => a.name.localeCompare(b.name));
  }, [data]);

  useEffect(() => {
    if (departmentId) {
      setValue('departmentId', departmentId);
    }
  }, [departmentId, setValue]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <FieldGrid>
        <SelectField
          label={intl.formatMessage({
            id: 'inputDepartmentLabel',
            defaultMessage: 'Department',
            description: 'Label for department input',
          })}
          register={register('departmentId')}
          placeholder={intl.formatMessage({
            id: 'inputDepartmentPlaceholder',
            defaultMessage: 'No department',
            description: 'Placeholder for department input',
          })}
          defaultValue={departmentId}
          options={departmentOptions}
          error={errors.departmentId}
        />
      </FieldGrid>
      <ActionContainer>
        <Button type="submit" background="blue">
          <FormattedMessage
            id="saveButton"
            defaultMessage="Save"
            description="Save button text"
          />
        </Button>
      </ActionContainer>
    </form>
  );
};

export default UpdateUserDepartmentForm;
