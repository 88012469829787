import React, {
  FC,
  useEffect,
  Fragment,
  useMemo,
  useState,
  useCallback,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ContentWidth, Description, FlexContainer, MediumGap } from 'styles';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  setLayoutHeader,
  localizationSelector,
  useGetCompanyReportQuery,
  useGetCompanyQuizDatesQuery,
  useLazyGetOrganizationEntityStatisticsQuery,
  useGetFilteredHealthquizResultsQuery,
  useGetBaselineStatisticsQuery,
  useDeleteHealthQuizResultsMutation,
} from 'store';
import * as routes from 'router/routes';

import Heading, { Tag } from 'components/UI/Heading';
import HeroSmall from 'components/UI/Heros/HeroSmall';
import Loader from 'components/UI/Loader';

import {
  CardList,
  HeroGrid,
  HeroCol,
  ChartWrap,
  ProgressHeader,
  OverallResult,
  ButtonContainer,
} from './styles';
import { formatDate } from 'utils/date';
import StaticCard from 'components/UI/Cards/StaticCard';
import CompanyResultFilter from 'components/forms/CompanyResultFilter';
import { useForm } from 'react-hook-form';
import SelectField from 'components/UI/SelectField';
import { usePretendCompany } from 'hooks/usePretendCompany';
import HealthBarChart from 'components/fragments/HealthBarChart';
import HeadingWithInfo from 'components/UI/HeadingWithInfo';
import ContentModal from 'components/modals/ContentModal';
import Button from 'components/UI/Button';
import toast from 'react-hot-toast';
import { ResultFilter } from 'components/forms/CompanyResultFilter/CompanyResultFilter';
import AdminDetailedHealthCard from 'components/cards/AdminDetailedHealthCard';
import ProgressBarWithAverage from 'components/UI/ProgressBarWithAverage';
import ToggleButton from 'components/UI/ToggleButton';
import { ColorHighlight } from 'components/fragments/AdminQuizResults/styles';
import HealthWheelCompany from 'components/fragments/HealthWheel/HealthWheelCompany/HealthWheelCompany';
import ButtonWithIcon from 'components/UI/ButtonWithIcon';
import { IconType } from 'components/UI/Icon';
import SendQuizReminderModal from 'components/modals/SendQuizReminderModal';
import ConfirmActionPopup from 'components/popups/ConfirmActionPopup';
import useModal from 'hooks/useModal';

const AdminHealthReport: FC = () => {

  // State
  const [resultFilter, setResultFilter] = useState<ResultFilter>();

  // Hooks
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { language } = useAppSelector(localizationSelector);
  const companyId = usePretendCompany();
  const report = useGetCompanyReportQuery({ companyId });
  const [deleteHealthQuizResults, { isLoading: deleteHealthQuiz }] = useDeleteHealthQuizResultsMutation();

  const {
    modalOpen: confirmDeleteOpen,
    onCloseModal: onCloseDeleteModal,
    onOpenModal: onOpenDeleteModal,
  } = useModal();

  const {
    modalOpen: confirmReminderOpen,
    onCloseModal: onCloseReminderModal,
    onOpenModal: onOpenReminderModal,
  } = useModal();

  const handleDeleteHealthQuizResults = async () => {
    await deleteHealthQuizResults({ companyId: companyId ?? '' }).unwrap();
    onCloseDeleteModal();
    toast.success(
      intl.formatMessage({
        id: 'resetHealthQuizSuccess',
        defaultMessage: 'Health quiz results have been reset.',
        description: 'health quiz reset success text',
      })
    );
  }

  const { register, watch, setValue } = useForm<{ healthScorePeriod: string }>({
    defaultValues: {
      healthScorePeriod: '',
    },
  });
  const healthScorePeriod = watch('healthScorePeriod');


  const [healthScoreChartModalOpen, setHealthScoreChartModalOpen] = useState<
    null | 'quiz' | 'users'
  >(null);
  const [selectedHealthScoreIndex, setSelectedHealthScoreIndex] =
    React.useState<number | null>();
  const [selectedBaselineIndex, setSelectedBaselineIndex] = React.useState<
    number | null
  >();

  const [
    getHealthScores,
    { data: healthScoreData, isFetching: healthScoreIsFetching },
  ] = useLazyGetOrganizationEntityStatisticsQuery();

  const selectedResult = useMemo(
    () =>
      selectedHealthScoreIndex != null && healthScoreData != null
        ? healthScoreData[selectedHealthScoreIndex]
        : null,
    [selectedHealthScoreIndex, healthScoreData]
  );

  const [enableBaseline, setEnableBaseline] = React.useState<boolean>(false);

  const wellrBaselineStatistics = useGetBaselineStatisticsQuery(undefined, {
    skip: enableBaseline === false,
  });

  const selectedBaselineResult = useMemo(
    () =>
      selectedBaselineIndex != null && wellrBaselineStatistics?.data != null
        ? wellrBaselineStatistics?.data[selectedBaselineIndex]
        : null,
    [selectedBaselineIndex, wellrBaselineStatistics?.data]
  );

  const resultQueryParams = useMemo(() => {
    if (selectedResult == null || resultFilter == null) {
      return null;
    }
    return {
      companyId,
      organizationEntityId: selectedResult.organizationEntityId,
      language: language?.languageCode ?? '',
      age: resultFilter.ageFilter,
      date: healthScorePeriod,
      gender: resultFilter.genderFilter,
      role: resultFilter.roleFilter,
    };
  }, [
    companyId,
    healthScorePeriod,
    language?.languageCode,
    resultFilter,
    selectedResult,
  ]);

  const filteredHealthTestResults = useGetFilteredHealthquizResultsQuery(
    resultQueryParams as NonNullable<typeof resultQueryParams>,
    {
      skip: resultQueryParams === null,
    }
  );

  const quizDatesResponse = useGetCompanyQuizDatesQuery({ companyId });

  const onOpenHealthScoreChartModal = useCallback(
    (type: 'quiz' | 'users') => setHealthScoreChartModalOpen(type),
    []
  );
  const onCloseHealthScoreChartModal = useCallback(
    () => setHealthScoreChartModalOpen(null),
    []
  );

  useEffect(() => {
    if (healthScorePeriod === '' && quizDatesResponse.isSuccess) {
      setValue(
        'healthScorePeriod',
        quizDatesResponse.data.length > 0 ? quizDatesResponse.data[0] : 'latest'
      );
    }
  }, [quizDatesResponse, healthScorePeriod, setValue]);

  useEffect(() => {
    if (healthScorePeriod !== '') {
      getHealthScores(
        {
          date: healthScorePeriod === 'latest' ? undefined : healthScorePeriod,
          companyId,
        },
        true
      );
    }
  }, [getHealthScores, healthScorePeriod, companyId]);

  // Set header
  useEffect(() => {
    dispatch(
      setLayoutHeader({
        title: intl.formatMessage({
          id: 'pageAdminReportTitle',
          defaultMessage: 'Report',
          description: 'Page title for admin report',
        }),
        inverted: true,
      })
    );
  }, [dispatch, intl]);

  // Users completed percentage
  const usersCompletedQuizPercentage = useMemo(() => {
    if (!report.data) {
      return 0;
    }
    const { completedHealthQuizes, totalHealthQuizes } =
      report.data.organizationEntityStatistics;

    return totalHealthQuizes > 0
      ? parseFloat(
        ((completedHealthQuizes / totalHealthQuizes) * 100).toFixed(1)
      )
      : 0;
  }, [report.data]);

  const healthScoreFilter = useMemo(
    () =>
      healthScoreData?.map(
        ({
          organizationEntityDescription,
          organizationEntityType,
          role,
          gender,
          age,
        }) => ({
          organizationEntityDescription,
          organizationEntityType,
          role,
          gender,
          age,
        })
      ) ?? [],
    [healthScoreData]
  );

  const baselineFilter = useMemo(
    () =>
      wellrBaselineStatistics?.data?.map(({ role, gender, age }) => ({
        role,
        gender,
        age,
      })) ?? [],
    [wellrBaselineStatistics?.data]
  );

  const quizPeriodOptions = (quizDatesResponse.data ?? []).map((item) => ({
    value: item,
    name: formatDate(new Date(item)),
  }));

  const onResultCallback = useCallback(
    (
      index: number | null,
      filters: ResultFilter,
      baselineIndex: number | null
    ) => {
      setSelectedHealthScoreIndex(index);
      setSelectedBaselineIndex(baselineIndex);
      setResultFilter(filters);
    },
    []
  );

  const healthScoreSection = useMemo(() => {
    if (quizDatesResponse.isLoading) {
      return <Loader color="blue" padding />;
    }
    if (!healthScoreData && !healthScoreIsFetching) {
      return null;
    }

    const healthScore = selectedResult?.averageHealthScore ?? null;
    const baselineHealthScore =
      selectedBaselineResult?.averageHealthScore ?? null;

    return (
      <>
        <Heading tag={Tag.H4}>
          <FormattedMessage
            id="adminHealthReportHealthscoreTitle"
            defaultMessage="Health outcomes"
            description="Admin health report healthscore section title"
          />
        </Heading>
        <StaticCard padding>
          <SelectField
            label={intl.formatMessage({
              id: 'selectHealthScorePeriodLabel',
              defaultMessage: 'Select period',
              description: 'Label for selection health score period',
            })}
            options={quizPeriodOptions}
            register={register('healthScorePeriod')}
          />
          <br />
          <CompanyResultFilter
            results={healthScoreFilter}
            baselineResults={baselineFilter}
            resultCallback={onResultCallback}
          />
          <MediumGap />
          <Heading tag={Tag.H2}>
            <FormattedMessage
              id="pageAdminQuizResultsTitle"
              defaultMessage="Results"
              description="Title before the results on the admin quiz results page"
            />
          </Heading>
          <ColorHighlight color="blue">
            <FlexContainer gap={10} alignItems="center">
              <Heading tag={Tag.H5}>
                <FormattedMessage
                  id="pageAdminQuizResultsCompareBaselineTitle"
                  defaultMessage="Compare with Wellr's average"
                  description="Title for the baseline toggle on the admin quiz results page"
                />
              </Heading>
              <ToggleButton
                isActive={enableBaseline}
                onToggle={setEnableBaseline}
              />
            </FlexContainer>
          </ColorHighlight>
          <br />
          <br />
          {healthScoreIsFetching ? (
            <>
              <br />
              <Loader color="blue" />
              <br />
            </>
          ) : (
            <>
              <OverallResult>
                <ProgressHeader>
                  <Heading tag={Tag.H3}>
                    <FormattedMessage
                      id="adminHealthReportHealthIndexTitle"
                      defaultMessage="Average health index"
                      description="Admin health report health index title"
                    />
                  </Heading>
                  <span>{healthScore ? `${healthScore}%` : 'N/A'}</span>
                </ProgressHeader>
                <ProgressBarWithAverage
                  progress={healthScore}
                  color="black"
                  average={enableBaseline ? baselineHealthScore : null}
                  averageTitle={intl.formatMessage(
                    {
                      id: 'adminHealthReportWellrAverageHealthIndex',
                      defaultMessage: `Wellr's average health index = {baselineHealthScore}%`,
                      description: `Average health index for Wellr`,
                    },
                    { baselineHealthScore }
                  )}
                />
                {wellrBaselineStatistics.isLoading && (
                  <>
                    <br />
                    <Loader color="blue" size={24} />
                  </>
                )}
                <br />
                <br />
                {(selectedResult?.usersInHealthCategories != null ||
                  selectedResult?.quizesInHealthCategories != null) && (
                    <ChartWrap>
                      {selectedResult?.usersInHealthCategories != null && (
                        <div>
                          <HeadingWithInfo
                            tag={Tag.H5}
                            onClickInfo={() =>
                              onOpenHealthScoreChartModal('users')
                            }
                          >
                            <FormattedMessage
                              id="adminHealthReportUsersInHealthCategoriesTitle"
                              defaultMessage="Users in health categories"
                              description="Chart title for users in health categories on admin health report page"
                            />
                          </HeadingWithInfo>
                          <HealthBarChart
                            fontSize={13}
                            data={selectedResult.usersInHealthCategories}
                            baselineData={
                              enableBaseline
                                ? selectedBaselineResult?.usersInHealthCategories
                                : undefined
                            }
                          />
                        </div>
                      )}
                      {selectedResult?.quizesInHealthCategories != null && (
                        <div>
                          <HeadingWithInfo
                            tag={Tag.H5}
                            onClickInfo={() =>
                              onOpenHealthScoreChartModal('quiz')
                            }
                          >
                            <FormattedMessage
                              tagName="span"
                              id="adminHealthReportQuizesInHealthCategoriesTitle"
                              defaultMessage="Health category quiz"
                              description="Chart title for quizes in health categories on admin health report page"
                            />
                          </HeadingWithInfo>
                          <HealthBarChart
                            fontSize={13}
                            data={selectedResult.quizesInHealthCategories}
                            baselineData={
                              enableBaseline
                                ? selectedBaselineResult?.quizesInHealthCategories
                                : undefined
                            }
                          />
                        </div>
                      )}
                    </ChartWrap>
                  )}
              </OverallResult>
              {filteredHealthTestResults.data != null &&
                filteredHealthTestResults.data.length > 0 && (
                  <>
                    <br />
                    <Heading tag={Tag.H4}>
                      <FormattedMessage
                        id="adminHealthReportHealthTestResultsTitle"
                        defaultMessage="Results per health test"
                        description="Admin health report health test results title"
                      />
                    </Heading>
                    <CardList isLoading={filteredHealthTestResults.isFetching}>
                      {filteredHealthTestResults.data.map((item) => (
                        <AdminDetailedHealthCard
                          key={item.quizDefinition.id}
                          slug={item.quizDefinition.slug}
                          category={item.quizDefinition.healthCategory}
                          results={item.averageResult}
                          display={{
                            title: item.averageResult.resultTitle,
                            color: item.averageResult.color,
                          }}
                          link={`${routes.ADMIN_HEALTH_REPORT}/${item.quizDefinition.slug?.current}`}
                          onClickInfo={() =>
                            onOpenHealthScoreChartModal('users')
                          }
                          showBaseline={enableBaseline}
                        />
                      ))}
                    </CardList>
                  </>
                )}
            </>
          )}
        </StaticCard>
        <ContentModal
          isOpen={healthScoreChartModalOpen === 'users'}
          onClose={onCloseHealthScoreChartModal}
          title={intl.formatMessage({
            id: 'adminHealthReportUsersInHealthCategoriesTitle',
            defaultMessage: 'Users in health categories',
            description:
              'Chart title for users in health categories on admin health report page',
          })}
        >
          <FormattedMessage
            id="adminHealthReportUsersInHealthCategoriesInfoText"
            defaultMessage={`
              The statistics show how many employees have tests with results within a specific health category.{NewLine}{NewLine}
              Focus Area: Has one or more tests with results indicating a "Focus Area".{NewLine}{NewLine}
              Change is needed: Has one or more tests with results indicating "Change is needed" but does not have a test in the "Focus Area" category.{NewLine}{NewLine}
              Approved: Has one or more tests with results indicating "Approved" but no test with a lower result.{NewLine}{NewLine}
              Good: Only has test results within this level.`}
            description="Modal info text for 'users in health categories' chart"
            values={{ NewLine: <br /> }}
            tagName={'p'}
          />
        </ContentModal>
        <ContentModal
          isOpen={healthScoreChartModalOpen === 'quiz'}
          onClose={onCloseHealthScoreChartModal}
          title={intl.formatMessage({
            id: 'adminHealthReportQuizesInHealthCategoriesTitle',
            defaultMessage: 'Health category quiz',
            description:
              'Chart title for quizes in health categories on admin health report page',
          })}
        >
          <FormattedMessage
            id="adminHealthReportQuizesInHealthCategoriesInfoText"
            defaultMessage={`
              The percentage of test responses in each health category divided by the total number of answered tests.{NewLine}{NewLine}
              Example: Out of 100 answered tests, 10 fall into the "Focus Area" group. The "Focus Area" group then becomes 10%.`}
            description="Modal info text for 'quizes in health categories' chart"
            values={{ NewLine: <br /> }}
            tagName={'p'}
          />
        </ContentModal>
      </>
    );
  }, [
    quizDatesResponse.isLoading,
    healthScoreData,
    healthScoreIsFetching,
    selectedResult?.averageHealthScore,
    selectedResult?.usersInHealthCategories,
    selectedResult?.quizesInHealthCategories,
    selectedBaselineResult?.averageHealthScore,
    selectedBaselineResult?.usersInHealthCategories,
    selectedBaselineResult?.quizesInHealthCategories,
    intl,
    quizPeriodOptions,
    register,
    healthScoreFilter,
    baselineFilter,
    onResultCallback,
    enableBaseline,
    wellrBaselineStatistics.isLoading,
    filteredHealthTestResults.data,
    filteredHealthTestResults.isFetching,
    healthScoreChartModalOpen,
    onCloseHealthScoreChartModal,
    onOpenHealthScoreChartModal,
  ]);

  return (
    <Fragment>
      <HeroSmall background="purple" illustrationColor="purpleDark">
        <HeroGrid>
          <HeroCol>
            <p>
              <FormattedMessage
                id="pageTestHistoryUsersCompletedPercentage"
                defaultMessage="% of users completed health tests "
                description="Users completed quiz in test history"
              />
            </p>
            <Heading>{`${usersCompletedQuizPercentage}%`}</Heading>
          </HeroCol>
        </HeroGrid>
      </HeroSmall>
      <ContentWidth isSurface>
        <HealthWheelCompany data={{ result: filteredHealthTestResults.data || [] }} language={language!.languageCode} />
        <Heading tag={Tag.H4}>
          <FormattedMessage
            id="adminTestsAboutTitle"
            defaultMessage="About tests"
            description="About title for admin tests"
          />
        </Heading>
        <Description>
          <FormattedMessage
            id="adminHealthReportDescription"
            defaultMessage="The tests lay the foundation for the company's health index and should be taken continuously. Here, you can see an overview and delve into the test results."
            description="Description for admin health report page"
          />
        </Description>
        <Heading tag={Tag.H4}>
          <FormattedMessage
            id="adminHealthQuizReminderSectionTitle"
            defaultMessage="Health quiz reminder"
            description="Section title for health quiz reminder on admin health report page"
          />
        </Heading>
        <p>
          <FormattedMessage
            id="adminHealthQuizReminderDescription"
            defaultMessage="Send reminder email to users that have health quizzes yet to be completed."
            description="Description for health quiz reminder on admin health report page"
          />
        </p>
        <ButtonContainer>
          <Button
            color="white"
            background="blue"
            onClick={onOpenReminderModal}>
            <FormattedMessage
              id="sendHealthQuizReminderButtonLabel"
              defaultMessage="Send reminder"
              description="Send health quiz reminder button label"
            />
          </Button>
          {/* TODO: Turn off until we are sure we want to have this functionality or if we should have this like this for statistics
          <ButtonWithIcon
            color="orange"
            background="white"
            border='orange'
            onClick={onOpenDeleteModal}
            icon={IconType.Refresh}
          >
            <FormattedMessage
              id="resetHealthQuizButtonLabel"
              defaultMessage="Reset health quiz"
              description="Reset health quiz button label"
            />
          </ButtonWithIcon>
           */}
        </ButtonContainer>
        <br />
        <br />
        {healthScoreSection}
      </ContentWidth>
      <SendQuizReminderModal
        isOpen={confirmReminderOpen}
        onClose={onCloseReminderModal}
        companyId={companyId!}
      />
      <ConfirmActionPopup
        isOpen={confirmDeleteOpen}
        onConfirm={async () => {
          await handleDeleteHealthQuizResults();
        }}
        isLoading={deleteHealthQuiz}
        onAbort={onCloseDeleteModal}>
        <>
          <Heading tag={Tag.H4}>
            <FormattedMessage
              id="resetHealthQuizButtonLabel"
              defaultMessage="Reset health quiz"
              description="Reset health quiz button label"
            />
          </Heading>
          <FormattedMessage
            id="resetHealthQuizForNextPeriodConfirmBody"
            defaultMessage="Are you sure you want to reset the health quiz results for the next period?"
            description="Body text in the reset health quiz popup"
          />
        </>
      </ConfirmActionPopup>
    </Fragment>
  );
};

export default AdminHealthReport;
