import styled from 'styled-components';

export const CardList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const CardItem = styled.div`
  padding: 16px;
`;
