import { FC, useEffect, Fragment, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { ContentWidth, MediumGap } from 'styles';
import { useAppDispatch } from 'hooks/redux';
import { setLayoutHeader, useGetCompaniesQuery } from 'store';

import Loader from 'components/UI/Loader/Loader';
import Table from 'components/UI/Table/Table';

import * as routes from 'router/routes';
import { useNavigate } from 'react-router-dom';
import ListMenu from 'components/UI/ListMenu';
import { IconType } from 'components/UI/Icon';

const AdminCompanies: FC = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Hooks
  const { data, isFetching } = useGetCompaniesQuery();

  // Set header
  useEffect(() => {
    dispatch(
      setLayoutHeader({
        title: intl.formatMessage({
          id: 'pageAdminCompaniesTitle',
          defaultMessage: 'Companies',
          description: 'Page title for admin companies',
        }),
        inverted: false,
      })
    );
  }, [dispatch, intl]);

  const tableContent = useMemo(() => {
    if (isFetching) {
      return <Loader color="blue" padding />;
    }
    if (!data) {
      return null;
    }
    const tableData = data.map((company) => ({
      id: company.id,
      description: company.description,
      healthMinQuizResultsForStats: company.healthQuizMinResultsForStats,
      pulseMinQuizResultsForStats: company.pulseQuizMinResultsForStats,
    }));

    return (
      <Table
        columns={[
          { key: 'id', title: 'ID' },
          {
            key: 'description',
            title: intl.formatMessage({
              id: 'tableColumnHeaderCompanyDescription',
              description: 'Table column header for company description',
              defaultMessage: 'Description',
            }),
          },
          {
            key: 'healthMinQuizResultsForStats',
            title: intl.formatMessage({
              id: 'tableColumnHeaderMinHealthQuizResultForStats',
              description:
                'Table column header for minimum health quiz results for stats',
              defaultMessage: 'Min. health quiz results for stats',
            }),
          },
          {
            key: 'pulseMinQuizResultsForStats',
            title: intl.formatMessage({
              id: 'tableColumnHeaderMinPulseQuizResultForStats',
              description:
                'Table column header for minimum pulse test results for stats',
              defaultMessage: 'Min. pulse quiz results for stats',
            }),
          },
        ]}
        data={tableData}
        onRowClick={(row) => {
          navigate(`${routes.ADMIN_COMPANIES}/${row.id}`);
        }}
      />
    );
  }, [data, isFetching, navigate, intl]);

  return (
    <Fragment>
      <ContentWidth isSurface>
        <ListMenu
          menu={[
            {
              id: 1,
              text: intl.formatMessage({
                id: 'pageAdminAddCompanyButton',
                defaultMessage: 'Add new company',
                description: 'Button label to add a new company',
              }),
              icon: IconType.PlusCircle,
              link: routes.ADMIN_COMPANIES_CREATE,
            },
          ]}
        />
        <MediumGap />
        {tableContent}
      </ContentWidth>
    </Fragment>
  );
};

export default AdminCompanies;
