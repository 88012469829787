import { baseApi } from '../baseApi';

import { CreateUserAccessRightsRequestBody, GetUserAccessRightResponse } from 'models/accessRight/accessRight';


const accessRightApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        createUserAccessRights: builder.mutation<void, CreateUserAccessRightsRequestBody>({
            query: (body) => {
                return ({
                    url: '/accessRights',
                    method: 'POST',
                    params: {
                        requestAccessRight: body.requestAccessRight,
                        membershipId: body.membershipId,
                    },
                    body: body.organizationIds,
                });
            },
        }),
        getUserAccessRights: builder.query<GetUserAccessRightResponse, string>({
            query: (membershipId) => {
                return {
                    url: '/accessRights',
                    method: 'GET',
                    params: {
                        membershipId: membershipId,
                    },
                };
            },
        }),
    }),
    });

    export const {
        useCreateUserAccessRightsMutation,
        useGetUserAccessRightsQuery,
    } = accessRightApi;
