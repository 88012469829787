import { FC } from 'react';
import { Image } from 'models';

import Heading, { Tag } from 'components/UI/Heading';
import Button from 'components/UI/Button';

import {
  Container,
  Overlay,
  CardContent,
  CardText,
  CardAction,
  ImageView,
  Text,
} from './styles';

type Props = {
  onClick?: () => void;
  buttonText: string;
  title: string;
  text?: string;
  image?: Image | null;
};

const HeroLarge: FC<Props> = ({ onClick, buttonText, title, text, image }) => {
  return (
    <Container>
      {image && <ImageView src={image.src} alt={image.alt} />}
      <Overlay>
        <CardContent>
          <CardText>
            <Heading style={{fontSize:'39px'}} tag={Tag.H2}>{title}</Heading>
            {text && <Text>{text}</Text>}
          </CardText>
          <CardAction>
            <Button onClick={onClick} size='small' backgroundOpacity={0.7} background="blue">
              {buttonText}
            </Button>
          </CardAction>
        </CardContent>
      </Overlay>
    </Container>
  );
};

export default HeroLarge;
