import React, { FC, useEffect, Fragment, useMemo, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ContentWidth } from 'styles';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  setLayoutHeader,
  localizationSelector,
  useGetAvailableQuizesQuery,
  useGetMyHealthWheelQuery,
} from 'store';
import * as routes from 'router/routes';

import Badge from 'components/UI/Badge';
import Loader from 'components/UI/Loader';
import Heading, { Tag } from 'components/UI/Heading';
import HeroSmall from 'components/UI/Heros/HeroSmall';
import TestHistoryCard from 'components/cards/TestHistoryCard';
import EmptyState from 'components/UI/EmptyState';
import { IconType } from 'components/UI/Icon';

import { CardList, CategoryHeader, HealthIndex } from './styles';

const TestHistory: FC = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { language } = useAppSelector(localizationSelector);

  // Hooks
  const wheel = useGetMyHealthWheelQuery({ language: language?.languageCode });
  const quizes = useGetAvailableQuizesQuery({
    language: language?.languageCode,
    inclDeletedResults: true,
  });

  // Set header
  useEffect(() => {
    dispatch(
      setLayoutHeader({
        title: intl.formatMessage({
          id: 'tabHealthTests',
          defaultMessage: 'Tests',
          description: 'Tab item for health tests',
        }),
        inverted: true,
        icon: IconType.Back,
        link: routes.TESTS,
      })
    );
  }, [dispatch, intl]);

  // Render diff
  const renderDiff = useCallback((latest: number, previous: number | null) => {
    if (previous === null) {
      return null;
    }

    const diff = ((latest - previous) / previous) * 100;
    const isLarger = latest > previous;

    return (
      <Badge
        color="black"
        background={isLarger ? 'success' : 'error'}
      >{`${diff.toFixed()}%`}</Badge>
    );
  }, []);

  // Content
  const content = useMemo(() => {
    // Loading
    if (quizes.isLoading) {
      return <Loader color="blue" padding />;
    }

    // No data
    if (!quizes.data || !quizes.data.some((item) => item.quizResults.length)) {
      return (
        <EmptyState iconType={IconType.Tests} padding>
          <FormattedMessage
            id="pageTestHistoryEmptyState"
            defaultMessage="No test history found"
            description="Empty state for test history"
          />
        </EmptyState>
      );
    }

    return quizes.data.map((item) => (
      <TestHistoryCard
        key={item.quizSlug}
        category={item.quizDefinition.healthCategory}
        results={item.quizResults}
      />
    ));
  }, [quizes]);

  // Health index
  const healthIndex = useMemo(() => {
    if (!wheel.data) {
      return null;
    }
    const latest = wheel.data.latest.averagePercentage;
    const previous = wheel.data.nextLatest.averagePercentage;

    if (latest === null) {
      return null;
    }
    return (
      <CategoryHeader>
        <p>
          <FormattedMessage
            id="pageTestHistoryHealthIndex"
            defaultMessage="Health index"
            description="Health index for test history"
          />
        </p>
        <HealthIndex>
          <Heading>{`${latest.toFixed()}%`}</Heading>
          {renderDiff(latest, previous)}
        </HealthIndex>
      </CategoryHeader>
    );
  }, [renderDiff, wheel]);

  return (
    <Fragment>
      <HeroSmall>{healthIndex}</HeroSmall>
      <ContentWidth isSurface>
        <Heading tag={Tag.H4}>
          <FormattedMessage
            id="pageTestHistoryTitle"
            defaultMessage="Test history"
            description="Section title for test history"
          />
        </Heading>
        <CardList>{content}</CardList>
      </ContentWidth>
    </Fragment>
  );
};

export default TestHistory;
