import styled from 'styled-components';
import { breakpoints } from 'styles';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

export const Container = styled.div`
  position: relative;
  -webkit-text-size-adjust: none; // fixes bug with safari scaling font-sizes inside swiper on mobile
`;

export const Slider = styled(Swiper)`
  overflow: ${({ slidesPerView }) =>
    typeof slidesPerView === 'number' ? 'hidden' : 'inherit'};

  .swiper-pagination {
    bottom: 24px;
    @media (max-width: ${breakpoints.m}px) {
      bottom: 4px;
    }

    .swiper-pagination-bullet {
      opacity: 1;
      &.swiper-pagination-bullet-active {
        background-color: ${({ theme }) => theme.colors.blueDark};
      }
      background-color: ${({ theme }) => theme.colors.backgroundDark};
    }
  }
`;

export const Slide = styled(SwiperSlide)`
  width: auto;
  height: auto;
`;

export const SliderNavs = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
`;
