import React, {
  FC,
  Fragment,
  useCallback,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  localizationSelector,
  setLayoutHeader,
  useGetUserQuery,
  useGetAvailableContestPreviewQuery,
  useStartChallengeMutation,
} from 'store';

import { getImage } from 'utils/asset';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useLocalizedText } from 'hooks/useLocalizedText';
import { Category, ContentWidth, Gap } from 'styles';
import * as routes from 'router/routes';
import { ContestUser } from 'models';

import { IconType } from 'components/UI/Icon';
import WellrCoins from 'components/UI/WellrCoins';
import Heading, { Tag } from 'components/UI/Heading';
import Button from 'components/UI/Button/Button';
import Loader from 'components/UI/Loader';
import HeroSmall from 'components/UI/Heros/HeroSmall';
import EmptyState from 'components/UI/EmptyState';
import RichText from 'components/fragments/RichText/RichText';
import ContentModal from 'components/modals/ContentModal';
import ChallengeForm from 'components/forms/ChallengeForm';

import { ButtonGrid, Byline, CategoryHeader } from './styles';
import { DifficultyText } from 'components/cards/CompetitionCard/styles';

const AvailableChallengeOverview: FC = () => {
  const intl = useIntl();
  const { slug } = useParams();
  const getText = useLocalizedText();
  const dispatch = useAppDispatch();
  const { language } = useAppSelector(localizationSelector);

  // Hooks
  const [startContest, startResult] = useStartChallengeMutation();
  const user = useGetUserQuery();
  const { data, isLoading } = useGetAvailableContestPreviewQuery({
    slug,
    language: language?.languageCode,
  });

  // State
  const [startChallengeOpen, setStartChallengeOpen] = useState<boolean>(false);

  // Set header
  useEffect(() => {
    dispatch(
      setLayoutHeader({
        title: intl.formatMessage({
          id: 'pageCompetitionsAvailableChallengeTitle',
          defaultMessage: 'Available challenges',
          description: 'Section title for available challenges',
        }),
        inverted: true,
        icon: IconType.Back,
        link: routes.COMPETE_AVAILABLE_CHALLENGES,
      })
    );
  }, [dispatch, intl]);

  // Actions
  const onStart = useCallback(
    async (
      start: string,
      userId: string,
      users: Partial<ContestUser>[],
      displayCreatorsEmail: boolean
    ) => {
      if (slug) {
        startContest({
          slug,
          start,
          userId,
          users,
          displayCreatorsEmail,
        });
      }
    },
    [startContest, slug]
  );
  const onOpenStartChallengeModal = useCallback(
    () => setStartChallengeOpen(true),
    []
  );
  const onCloseStartChallengeModal = useCallback(
    () => setStartChallengeOpen(false),
    []
  );

  // Accept modal content
  const modalContent = useMemo(() => {
    if (startResult.isLoading || !user.data) {
      return <Loader color="blue" padding />;
    }
    return (
      <ChallengeForm onStart={onStart} membership={user.data.membership} />
    );
  }, [onStart, startResult, user]);

  // Navigate
  if (startResult.isSuccess) {
    return (
      <Navigate to={`${routes.COMPETE}/challenges/${startResult.data.id}`} />
    );
  }

  // Loading
  if (isLoading) {
    return <Loader color="blue" padding />;
  }

  // No data
  if (!data) {
    return (
      <EmptyState iconType={IconType.Competition} padding>
        <FormattedMessage
          id="pageCompetePreviewEmptyState"
          defaultMessage="Preview not found"
          description="Empty state for preview"
        />
      </EmptyState>
    );
  }

  const { title, difficulty, description, wellrPoints, image } = data;

  return (
    <Fragment>
      <HeroSmall image={getImage(image)}>
        <CategoryHeader>
          <Category>
            <FormattedMessage
              id="pageChallengeCategoryTitle"
              defaultMessage="Challenge"
              description="Challenge category title"
            />
          </Category>
          <Heading>{getText(title)}</Heading>
          {difficulty ? (
            <>
              {intl.formatMessage({
                id: `difficulty`,
                defaultMessage: `Difficulty`,
                description: `Challenge difficulty level`,
              })}
              {": "}
              <DifficultyText
                difficulty={difficulty}>
                {intl.formatMessage({
                  id: `${difficulty.toLocaleLowerCase()}`,
                  defaultMessage: `${difficulty}`,
                  description: `${difficulty} difficulty level`,
                })}
              </DifficultyText>
            </>
          ) : null}
          <Byline>
            <WellrCoins count={wellrPoints} right />
          </Byline>
        </CategoryHeader>
      </HeroSmall>
      <ContentWidth isSurface>
        <ButtonGrid>
          <Button background="blue" onClick={onOpenStartChallengeModal}>
            <FormattedMessage
              id="customizeChallengeButton"
              defaultMessage="Customize challenge"
              description="Start challenge button text"
            />
          </Button>
        </ButtonGrid>
        <Gap />
        <Heading tag={Tag.H4}>
          <FormattedMessage
            id="challengeAboutButton"
            defaultMessage="About the challenge"
            description="Challenge about button"
          />
        </Heading>
        {description && <RichText noLanguage>{description}</RichText>}
      </ContentWidth>
      <ContentModal
        isOpen={startChallengeOpen}
        onClose={onCloseStartChallengeModal}
        title={intl.formatMessage({
          id: 'pageAvailableChallengeStartTitle',
          defaultMessage: 'Challenge',
          description: 'Available challenge page start title',
        })}
      >
        {modalContent}
      </ContentModal>
    </Fragment>
  );
};

export default AvailableChallengeOverview;
