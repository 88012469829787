import React, { FC, useCallback, useMemo, useState } from 'react';
import {
  FieldValues,
  RegisterOptions,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import {
  Form,
  Container,
  InputBase,
  InputMessage,
  HelperText,
  ErrorMessage,
  SearchButton,
} from './styles';

type Props = {
  placeholder?: string;
  onSearch: (value: string) => void;
  registerOptions?: RegisterOptions;
  disabled?: boolean;
  helperText?: string;
  defaultValue?: string;
};

const TextField: FC<Props> = ({
  placeholder,
  onSearch,
  registerOptions,
  helperText,
  defaultValue = '',
  disabled = false,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      search: defaultValue,
    },
  });

  // Submit
  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    ({ search }) => onSearch(search),
    [onSearch]
  );

  // State
  const [active, setActive] = useState<boolean>(false);

  // Message
  const message = useMemo(() => {
    if (errors.search) {
      return <ErrorMessage>{errors.search.message as string}</ErrorMessage>;
    }
    if (helperText) {
      return <HelperText>{helperText}</HelperText>;
    }
    return null;
  }, [helperText, errors]);

  // Focus
  const onFocus = () => setActive(true);
  const onBlur = () => setActive(false);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Container>
        <InputBase
          {...register('search', registerOptions)}
          type="text"
          placeholder={placeholder}
          error={Boolean(errors.search)}
          disabled={disabled}
          onFocus={onFocus}
          onBlur={onBlur}
          active={active}
        />
        <SearchButton type="submit">
          <FormattedMessage
            id="searchButton"
            defaultMessage="Search"
            description="Search button text"
          />
        </SearchButton>
      </Container>
      <InputMessage>{message}</InputMessage>
    </Form>
  );
};

export default TextField;
