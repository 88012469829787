import styled from 'styled-components';
import { ThemeColor } from 'styles';

type Props = {
  background: ThemeColor;
  color: ThemeColor;
  value?: string;
};

export const Container = styled.div<Props>`
  color: ${({ color, theme }) => theme.colors[color]};
  background-color: ${({ background, theme }) => theme.colors[background]};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1px;
  width: 18px;
  height: 17px;
  font-size: 14px;
  visibility: ${({ value }) => value ? 'visible' : 'hidden'};
  letter-spacing: ${({ value }) => {
    if (value && value.length > 1) return -2
    return 0
  }}px;
  position: absolute;
  transform: translate(-8px, -8px);
`;

export const Badge = styled.img`
  border-radius: 50%;
  height: 100%;
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
`;