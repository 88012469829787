import { ContentText } from "models/content/content"

export enum NotificationType {
  Individual,
  Company,
  Department,
  Office,
  HeadOffice,
  UmbrellaCompany
}

export enum RepeatEnum {
  None,
  Daily,
  Weekly,
  Monthly
}

export enum NotificationMethod {
  All,
  Phone,
  Email
}

export type NotificationSchedule = {
  id: string
  membershipId: string
  companyId: string | null
  organizationId: string | null
  organizationDescription: string | null
  start: string
  stop: string
  repeatInterval: RepeatEnum
  creationAndSendDateOfNotification: string;
  notificationDataId: string
  ctaLabel: ContentText
  type: NotificationType
  preferredNotificationMethod: NotificationMethod
  isActive: boolean
}

export type NotificationScheduleOrganizationQuery = {
  date: string;
  organizationId: string;
};

export type NotificationScheduleOrganizationIn = {
  companyId: string;
  organizationId: string;
  start: string;
  stop: string;
  repeatInterval: RepeatEnum;
  contentSlug: string;
  preferredNotificationMethod: NotificationMethod;
  redirectLink: string;
};

export type NotificationSchedulePersonalIn = {
  start: string;
  stop: string;
  repeatInterval: RepeatEnum;
  contentSlug: string;
  preferredNotificationMethod: NotificationMethod;
  redirectLink: string;
};