import { useGetUserQuery } from 'store';

const useUserLevel = () => {
  const { level } = useGetUserQuery(undefined, {
    selectFromResult: (result) => ({ level: result.data?.level }),
  });

  return level;
};

export default useUserLevel;
