import React, {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';

import { FilterTag } from 'models';
import { addOrRemoveFromArray } from 'utils/array';
import { useLocalizedText } from 'hooks/useLocalizedText';

import Checkbox from 'components/UI/Checkbox';
import Icon, { IconType } from 'components/UI/Icon';
import IconButton from 'components/UI/IconButton';
import Heading, { Tag } from 'components/UI/Heading';
import SlideoutModal from 'components/UI/SlideoutModal';
import Button from 'components/UI/Button';

import {
  Header,
  Body,
  ButtonContent,
  ButtonGrid,
  FilterItem,
  ActionButtons,
} from './styles';

type Props = {
  tags?: FilterTag[];
  defaultFilters?: string[];
  submitFilters: (filters: string[]) => void;
};

const FilterModal: FC<Props> = ({
  defaultFilters = [],
  tags,
  submitFilters,
}) => {
  const getText = useLocalizedText();

  // State
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [filters, setFilters] = useState<string[]>([]);

  useEffect(() => setFilters(defaultFilters), [defaultFilters]);

  // Open / close
  const onToggle = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  // Click
  const onClick = useCallback(
    (slug: string) => () => {
      setFilters(addOrRemoveFromArray(filters, slug));
    },
    [filters]
  );

  // Submit
  const onSubmit = useCallback(() => {
    submitFilters(filters);
    setIsOpen(false);
  }, [submitFilters, filters]);

  // Clear
  const onClear = useCallback(() => {
    submitFilters([]);
    setFilters([]);
  }, [submitFilters]);

  // Content
  const content = useMemo(() => {
    if (!tags) {
      return null;
    }
    return tags.map(({ slug, title }) => (
      <FilterItem key={slug} onClick={onClick(slug)}>
        <Checkbox name={slug} isChecked={filters.includes(slug)} />
        <span>{typeof title === 'string' ? title : getText(title)}</span>
      </FilterItem>
    ));
  }, [getText, onClick, tags, filters]);

  return (
    <Fragment>
      <ButtonGrid>
        <Button
          color="blue"
          background="transparent"
          border="borderDark"
          onClick={onToggle}
        >
          <ButtonContent>
            <FormattedMessage
              id="filterButton"
              defaultMessage="Filter"
              description="Title for filter modal"
            />
            <Icon type={IconType.Filter} color="blue" />
          </ButtonContent>
        </Button>
      </ButtonGrid>
      <SlideoutModal isOpen={isOpen} onClose={onToggle}>
        <div>
          <Header>
            <Heading tag={Tag.H3}>
              <FormattedMessage
                id="filterModalTitle"
                defaultMessage="Filter"
                description="Title for filter modal"
              />
            </Heading>
            <IconButton onClick={onToggle} padding>
              <Icon type={IconType.Close} />
            </IconButton>
          </Header>
          <Body>{content}</Body>
          <ActionButtons>
            <Button
              color="blue"
              background="transparent"
              border="borderDark"
              onClick={onClear}
            >
              <FormattedMessage
                id="clearFilterButton"
                defaultMessage="Clear ({count})"
                description="Clear filter button text"
                values={{ count: filters.length }}
              />
            </Button>
            <Button background="blue" onClick={onSubmit}>
              <FormattedMessage
                id="useButton"
                defaultMessage="Use"
                description="Use button text"
              />
            </Button>
          </ActionButtons>
        </div>
      </SlideoutModal>
    </Fragment>
  );
};

export default FilterModal;
