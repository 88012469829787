import { baseApi } from '../baseApi';
import { NotificationSchedule, NotificationScheduleOrganizationIn, NotificationScheduleOrganizationQuery, NotificationSchedulePersonalIn } from 'models/notificationSchedule/notificationSchedule';
import { dateStringToISOString, getFirstDateOfMonth } from 'utils/date';
import { addMonths, startOfMonth } from 'date-fns';

const notificationScheduleApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getMonthlyOrganizationNotificationSchedules: builder.query<NotificationSchedule[], NotificationScheduleOrganizationQuery>({
      query: ({ date, organizationId }) => ({
        url: `/notification-schedule/organization/${organizationId}?from=${dateStringToISOString(
          getFirstDateOfMonth(new Date(date))
        )}&to=${dateStringToISOString(
          startOfMonth(addMonths(new Date(date), 1))
        )}`,
        method: 'GET',
      }),
    }),
    getActiveOrganizationNotificationSchedules: builder.query<NotificationSchedule[], { organizationId?: string }>({
      query: ({ organizationId }) => ({
        url: `/notification-schedule/active/organization/${organizationId}`,
        method: 'GET',
      }),
    }),
    getMyActiveNotificationSchedules: builder.query<NotificationSchedule[], void>({
      query: () => ({
        url: `/notification-schedule/active/me`,
        method: 'GET',
      }),
    }),
    addOrganizationNotificationSchedule: builder.mutation<void, NotificationScheduleOrganizationIn>({
      query: (dto) => ({
        url: '/notification-schedule/organization',
        method: 'POST',
        body: dto
      }),
    }),
    addPersonalNotificationSchedule: builder.mutation<void, NotificationSchedulePersonalIn>({
      query: (dto) => ({
        url: '/notification-schedule/me',
        method: 'POST',
        body: dto
      }),
    }),
    removeCompanyNotificationSchedule: builder.mutation<void, { companyId: string, notificationDataId: string  }>({
      query: ({ companyId, notificationDataId }) => ({
        url: `/notification-schedule/remove/${companyId}/${notificationDataId}`,
        method: 'DELETE',
      }),
    }),
    removeNotificationSchedule: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `/notification-schedule/remove/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetMonthlyOrganizationNotificationSchedulesQuery,
  useGetActiveOrganizationNotificationSchedulesQuery,
  useGetMyActiveNotificationSchedulesQuery,
  useAddOrganizationNotificationScheduleMutation,
  useAddPersonalNotificationScheduleMutation,
  useRemoveNotificationScheduleMutation,
  useRemoveCompanyNotificationScheduleMutation,
} = notificationScheduleApi;