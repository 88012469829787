import { useMemo } from 'react';
import { localizationSelector, useGetUserLevelsQuery } from 'store';
import { getImage } from 'utils/asset';
import { useAppSelector } from './redux';
import starGreenBadge from 'assets/vectors/star-green-badge.svg';

const useLevelIcon = (level: number | null, iconSize: number) => {
  const { language } = useAppSelector(localizationSelector);
  const userLevels = useGetUserLevelsQuery({
    language: language?.languageCode,
  });

  const levelIcon = useMemo(() => {
    if (!userLevels.data || level == null) {
      return null;
    }
    const userLevel = userLevels.data.find(
      ({ levelNumber }) => levelNumber === level
    );
    if (!userLevel || userLevel.image == null) {
      return { src: starGreenBadge, alt: 'star badge' };
    }
    return getImage(userLevel.image, iconSize * 2);
  }, [userLevels.data, level, iconSize]);

  return levelIcon;
};

export default useLevelIcon;
