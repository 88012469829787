import styled from 'styled-components';

type Props = {
  isLast: boolean;
};

export const Item = styled.div<Props>`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  border-bottom: ${({ theme, isLast }) =>
    !isLast ? `1px solid ${theme.colors.borderLight}` : 'none'};
  padding: 0 8px 0 16px;
  min-height: 58px;

  h4 {
    margin: 0;
  }
`;

export const ItemCol = styled.div`
  display: flex;
  align-items: center;
`;

export const ImgContainer = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 13px;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.backgroundDark};
  border: 1px solid ${({ theme }) => theme.colors.backgroundDark};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
`;

export const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
