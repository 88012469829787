import { createContext, useContext, useState } from 'react';

export const SelectedMembershipsContext = createContext<{
  selected: string[];
  onToggle: (membershipId: string | string[]) => void;
}>({
  selected: [],
  onToggle: () => {},
});

export const useMembershipsContext = () =>
  useContext(SelectedMembershipsContext);

export const useMembershipState = () => {
  const [selectedMemberships, setSelectedMemberships] = useState<string[]>([]);

  const toggleSelectMembership = (membershipId: string | string[]) => {
    if (Array.isArray(membershipId)) {
      const newSelectedMemberships = [...selectedMemberships];
      for (const id of membershipId) {
        if (newSelectedMemberships.includes(id)) {
          newSelectedMemberships.splice(newSelectedMemberships.indexOf(id), 1);
        } else {
          newSelectedMemberships.push(id);
        }
      }
      setSelectedMemberships(newSelectedMemberships);
    } else {
      if (selectedMemberships.includes(membershipId)) {
        setSelectedMemberships(
          selectedMemberships.filter((id) => id !== membershipId)
        );
      } else {
        setSelectedMemberships([...selectedMemberships, membershipId]);
      }
    }
  };

  return { selectedMemberships, toggleSelectMembership };
};