import { FC, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useUpdateContestBonusStepsMutation } from 'store';

import Icon, { IconType } from 'components/UI/Icon';
import IconButton from 'components/UI/IconButton';
import Heading, { Tag } from 'components/UI/Heading';
import SlideoutModal from 'components/UI/SlideoutModal';

import { Header, Body } from './styles';
import { useForm } from 'react-hook-form';
import { ActionContainer } from 'styles';
import Button from 'components/UI/Button';
import TextField from 'components/UI/TextField';
import Loader from 'components/UI/Loader';

type UpdateCompetitionUserForm = {
  bonus: string;
};

type Props = {
  contestId: string;
  currentBonus: number;
  isOpen: boolean;
  onClose: () => void;
};

const UpdateCompetitionHealthQuizBonusModal: FC<Props> = ({
  contestId,
  currentBonus,
  isOpen,
  onClose,
}) => {
  const intl = useIntl();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<UpdateCompetitionUserForm>();

  useEffect(() => {
    setValue('bonus', currentBonus.toString());
  }, [currentBonus, setValue]);

  const [updateBonusSteps, { isLoading }] =
    useUpdateContestBonusStepsMutation();

  // Content
  const content = useMemo(() => {
    if (isLoading) {
      return <Loader color="blue" padding />;
    }
    return (
      <form
        onSubmit={handleSubmit(async (form) => {
          console.log('the form', form);
          await updateBonusSteps({
            contestId,
            bonusSteps:
              form.bonus === '0' || form.bonus === ''
                ? null
                : parseInt(form.bonus),
          }).unwrap();
          onClose();
        })}
      >
        <TextField
          label={intl.formatMessage({
            id: 'inputBonusStepsLabel',
            defaultMessage:
              'Steps bonus for having completed all health quizzes',
            description: 'Label for alias input',
          })}
          type="number"
          error={errors.bonus}
          register={register('bonus')}
        />
        <ActionContainer>
          <Button type="submit" background="blue">
            <FormattedMessage
              id="updateButton"
              defaultMessage="Update"
              description="Update button text"
            />
          </Button>
        </ActionContainer>
      </form>
    );
  }, [
    isLoading,
    handleSubmit,
    intl,
    errors.bonus,
    register,
    updateBonusSteps,
    contestId,
    onClose,
  ]);

  return (
    <SlideoutModal isOpen={isOpen} onClose={onClose}>
      <div>
        <Header>
          <Heading tag={Tag.H4}>
            <FormattedMessage
              id="contestCompletedHealthQuizBonusLabel"
              defaultMessage="Bonus from health quizzes"
              description="Label for contest completed health quiz bonus"
            />
          </Heading>
          <IconButton onClick={onClose} padding>
            <Icon type={IconType.Close} />
          </IconButton>
        </Header>
        <Body>{content}</Body>
      </div>
    </SlideoutModal>
  );
};

export default UpdateCompetitionHealthQuizBonusModal;
