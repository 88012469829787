import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as storage from 'utils/storage';
import { AppDispatch } from 'store/store';

type Company = { id: string; description: string };

export type AdminState = {
  selectedCompany: null | Company;
};

const initialAdminState: AdminState = {
  selectedCompany: null,
};

// Export slice
const adminSlice = createSlice({
  name: 'admin',
  initialState: initialAdminState,
  reducers: {
    setActiveCompany: (state, action: PayloadAction<Company>) => {
      state.selectedCompany = action.payload;
      storage.setItem('admin_active_company', action.payload);
    },
  },
});

// Export actions
export const { setActiveCompany } = adminSlice.actions;

// Export reducer
export const adminReducer = adminSlice.reducer;

// Thunks
export function checkCompany() {
  return async (dispatch: AppDispatch) => {
    const company = storage.getItem<Company>('admin_active_company');

    if (company) {
      dispatch(setActiveCompany(company));
    }
  };
}
