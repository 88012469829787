import Icon, { IconType } from 'components/UI/Icon';
import { FC } from 'react';
import StaticCard from '../StaticCard';
import { CardDescription, CardTitle, CardWrap } from './styles';

type Props = {
  title: string | number;
  icon: IconType;
  description: string;
};

const StatisticsCard: FC<Props> = ({ title, icon, description }: Props) => {
  return (
    <StaticCard padding>
      <CardWrap>
        <CardTitle>{title}</CardTitle>
        <Icon type={icon} color="blue" />
      </CardWrap>
      <CardDescription>{description}</CardDescription>
    </StaticCard>
  );
};

export default StatisticsCard;
