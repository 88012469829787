import styled, { css } from 'styled-components';

type Props = {
  disabled?: boolean;
  error?: boolean;
  active?: boolean;
  narrow?: boolean;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SelectBase = styled.select<Props>`
  border: 2px solid
    ${({ theme, error, active }) => {
    if (error) {
      return theme.colors.error;
    }
    if (active) {
      return theme.colors.blue;
    }
    return theme.colors.borderLight;
  }};
  border-radius: 8px;
  padding: ${({ narrow }) => (narrow ? '8px' : '16px')};
  padding-right: 40px;
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ narrow }) => (narrow ? '12px' : '16px')};
  background-image: linear-gradient(
      45deg,
      transparent 50%,
      ${({ theme }) => theme.colors.black} 50%
    ),
    linear-gradient(
      135deg,
      ${({ theme }) => theme.colors.black} 50%,
      transparent 50%
    ),
    linear-gradient(to right, #ccc, #ccc);
  background-position: ${({ narrow }) =>
    narrow
      ? `calc(100% - 15px) calc(1em + 2px),
    calc(100% - 10px) calc(1em + 2px), calc(100% - 2.5em) 0.5em`
      : `calc(100% - 20px) calc(1em + 6px),
    calc(100% - 15px) calc(1em + 6px), calc(100% - 2.5em) 0.5em`};
  background-size: 5px 5px, 5px 5px, 0 1.5em;
  background-repeat: no-repeat;
  outline: none;
  -webkit-appearance: none;

  &:focus {
    background-image: linear-gradient(
        45deg,
        ${({ theme }) => theme.colors.black} 50%,
        transparent 50%
      ),
      linear-gradient(
        135deg,
        transparent 50%,
        ${({ theme }) => theme.colors.black} 50%
      ),
      linear-gradient(to right, #ccc, #ccc);
    background-position: ${({ narrow }) =>
    narrow
      ? `calc(100% - 10px) calc(1em + 2px),
      calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;`
      : `calc(100% - 15px) calc(1em + 6px),
      calc(100% - 20px) calc(1em + 6px), calc(100% - 2.5em) 0.5em;`};
    background-size: 5px 5px, 5px 5px, 0 1.5em;
    background-repeat: no-repeat;
    outline: 0;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;

export const Label = styled.label`
  font-size: 14px;
`;

export const InputMessage = styled.div`
  margin-bottom: 16px;
`;

const Message = css`
  font-size: 14px;
  margin-top: 8px;
  text-align: left;
  margin: 8px 0 0;
`;

export const ErrorMessage = styled.p<Props>`
  ${Message};
  color: ${({ theme }) => theme.colors.error};
`;

export const HelperText = styled.p`
  ${Message};
`;

export const SelectedList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

export const SelectedItem = styled.li`
  display: inline-block;
  margin-right: 5px;
  margin-top: 5px;
  padding:2px;
  padding-left: 8px;
  border-radius: 4px;
  background-color: rgb(235, 242, 245);
`;

export const RemoveButton = styled.button`
  border: none;
  display:flex;
  align-items: center;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
`;