import styled from 'styled-components';
import { breakpoints } from 'styles';

export const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;

  h4 {
    margin: 0;
    width: 100%;

    @media (max-width: ${breakpoints.s}px) {
      margin: 16px 0 8px;
    }
  }

  @media (max-width: ${breakpoints.s}px) {
    flex-direction: column;
  }
`;

export const SmallButtonGrid = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
`;

export const TopListItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
