import React, { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import * as routes from 'router/routes';
import { regExp } from 'utils/string';
import { LoginValues } from 'models';

import Button from 'components/UI/Button/Button';
import TextField from 'components/UI/TextField/TextField';

import { ActionContainer } from 'styles';

type Props = {
  submitForm: (data: LoginValues) => void;
};

const LoginForm: FC<Props> = ({ submitForm }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // Submit
  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    (values) => {
      const loginValues: LoginValues = {
        email: values.email,
        password: values.password,
      };
      submitForm(loginValues);
    },
    [submitForm]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        label={intl.formatMessage({
          id: 'inputEmailLabel',
          defaultMessage: 'Email',
          description: 'Label for email input',
        })}
        placeholder={intl.formatMessage({
          id: 'inputEmailPlaceholder',
          defaultMessage: 'Enter email',
          description: 'Placeholder for email input',
        })}
        error={errors.email}
        register={register('email', {
          required: intl.formatMessage({
            id: 'inputErrorEmailRequired',
            defaultMessage: 'Email is required',
            description: 'Input error for email required',
          }),
          pattern: {
            value: regExp.email,
            message: intl.formatMessage({
              id: 'inputErrorEmailInvalid',
              defaultMessage: 'Please enter a valid email',
              description: 'Input error for email invalid',
            }),
          },
        })}
      />
      <TextField
        label={intl.formatMessage({
          id: 'inputPasswordLabel',
          defaultMessage: 'Password',
          description: 'Label for password input',
        })}
        placeholder={intl.formatMessage({
          id: 'inputPasswordPlaceholder',
          defaultMessage: 'Enter password',
          description: 'Placeholder for password input',
        })}
        register={register('password', {
          required: intl.formatMessage({
            id: 'inputErrorPasswordRequired',
            defaultMessage: 'Password is required',
            description: 'Input error for password required',
          }),
        })}
        type="password"
        error={errors.password}
      />
      <ActionContainer>
        <Button type="submit" color="black" background="yellow">
          <FormattedMessage
            id="loginButton"
            defaultMessage="Login"
            description="Login button text"
          />
        </Button>
      </ActionContainer>
      <ActionContainer>
        <Button
          onClick={() => navigate(routes.RESET_PASSWORD)}
          color="white"
          background="transparent"
        >
          <FormattedMessage
            id="resetPasswordButton"
            defaultMessage="Reset password"
            description="Reset password button text"
          />
        </Button>
        <Button
          onClick={() => navigate(routes.REGISTER_INVITE)}
          color="white"
          background="transparent"
        >
          <FormattedMessage
            id="registerInviteButton"
            defaultMessage="Sign up for Wellr"
            description="Register invite button text"
          />
        </Button>
      </ActionContainer>
    </form>
  );
};

export default LoginForm;
