import styled from 'styled-components';
import { ThemeColor } from 'styles';

type Props = {
  fill?: ThemeColor;
  stroke?: ThemeColor;
};

export const Group = styled.g<Props>`
  fill: ${({ theme, fill }) => (fill ? theme.colors[fill] : 'none')}};
  stroke: ${({ theme, stroke }) => (stroke ? theme.colors[stroke] : 'none')}};
`;

export const Path = styled.path<Props>`
  fill: ${({ theme, fill }) => (fill ? theme.colors[fill] : 'none')}};
  stroke: ${({ theme, stroke }) => (stroke ? theme.colors[stroke] : 'none')}};
`;

export const Rect = styled.rect<Props>`
  fill: ${({ theme, fill }) => (fill ? theme.colors[fill] : 'none')}};
  stroke: ${({ theme, stroke }) => (stroke ? theme.colors[stroke] : 'none')}};
`;

export const Circle = styled.circle<Props>`
  fill: ${({ theme, fill }) => (fill ? theme.colors[fill] : 'none')}};
  stroke: ${({ theme, stroke }) => (stroke ? theme.colors[stroke] : 'none')}};
`;

export const Line = styled.line<Props>`
  fill: ${({ theme, fill }) => (fill ? theme.colors[fill] : 'none')}};
  stroke: ${({ theme, stroke }) => (stroke ? theme.colors[stroke] : 'none')}};
`;
