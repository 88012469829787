import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { ContentWidth } from 'styles';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useLocalizedText } from 'hooks/useLocalizedText';
import {
  localizationSelector,
  setLayoutHeader,
  useDeleteFavoriteMutation,
  useGetFavoritesQuery,
} from 'store';
import { ReferenceType } from 'models';
import { getRefLink } from 'utils/link';

import { IconType } from 'components/UI/Icon';
import Loader from 'components/UI/Loader';
import EmptyState from 'components/UI/EmptyState';
import IconCard from 'components/UI/Cards/IconCard';

import { CardList } from './styles';

const Favorites: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const getText = useLocalizedText();
  const intl = useIntl();
  const { language } = useAppSelector(localizationSelector);

  // Hooks
  const { data, isLoading } = useGetFavoritesQuery({
    language: language?.languageCode,
  });
  const [deleteFavorite, deleteResult] = useDeleteFavoriteMutation();

  // Set header
  useEffect(() => {
    dispatch(
      setLayoutHeader({
        title: intl.formatMessage({
          id: 'pageFavoritesTitle',
          defaultMessage: 'Favourites',
          description: 'Page title for favorites',
        }),
      })
    );
  }, [dispatch, intl]);

  // Actions
  const onClick = useCallback(
    (type: ReferenceType, slug: string) => () =>
      navigate(getRefLink(type, slug)),
    [navigate]
  );
  const onDeleteClick = useCallback(
    (type: ReferenceType, reference: string) => () => {
      if (!deleteResult.isLoading) {
        deleteFavorite({ type, reference });
      }
    },
    [deleteFavorite, deleteResult.isLoading]
  );

  // Content
  const content = useMemo(() => {
    // Loading
    if (isLoading) {
      return <Loader color="blue" padding />;
    }

    // No data
    if (!data?.length) {
      return (
        <EmptyState iconType={IconType.FavoritesOutline} padding>
          <FormattedMessage
            id="pageFavoritesEmptyState"
            defaultMessage="No favorites yet"
            description="Empty state for favorites"
          />
        </EmptyState>
      );
    }

    return (
      <CardList>
        {data.map(({ id, title, typeTitle, type, reference }) => {
          return (
            <IconCard
              key={id}
              title={getText(title)}
              category={getText(typeTitle)}
              onClick={onClick(type, reference)}
              onIconClick={onDeleteClick(type, reference)}
              icon={IconType.Favorites}
            />
          );
        })}
      </CardList>
    );
  }, [getText, onClick, onDeleteClick, data, isLoading]);

  return <ContentWidth isSurface>{content}</ContentWidth>;
};

export default Favorites;
