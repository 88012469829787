import { useMemo } from 'react';
import { components } from 'generated/api';
import { useGetBaselineQuizResultQuery } from 'store';
import { GetFilteredHealthquizResultsType } from 'store/adminService/endpointDefinitions';
import { useActiveLanguage } from './useActiveLanguage';

type Results = Pick<
  | GetFilteredHealthquizResultsType['result'][number]['averageResult']
  | components['schemas']['QuizStatisticsResultDisplayDto'],
  | 'scorePercentageAverage'
  | 'usersCompleted'
  | 'usersTotal'
  | 'usersCompletedQuizPercentage'
  | 'averageAssessment'
  | 'resultTitle'
  | 'age'
  | 'gender'
  | 'role'
>;

const useQuizBaselineData = (
  quizSlug: string | undefined,
  results: Results | null | undefined,
  useFiltersFromResults: boolean = true
) => {
  const language = useActiveLanguage();

  const baseline = useGetBaselineQuizResultQuery(
    { language: language.languageCode, quizSlug: quizSlug as string },
    { skip: quizSlug === undefined }
  );

  const baselineResult = useMemo(() => {
    if (baseline.data?.averageResults == null || results == null) {
      return null;
    }
    if (useFiltersFromResults) {
      return (
        baseline.data.averageResults.find(
          (item) =>
            item.gender === results.gender &&
            item.role === results.role &&
            item.age === results.age
        ) ?? null
      );
    }
    return (
      baseline.data.averageResults.find(
        (item) =>
          item.gender === 'All' && item.role === 'All' && item.age === 'All'
      ) ?? null
    );
  }, [results, baseline.data?.averageResults, useFiltersFromResults]);

  const baselinePercentage = useMemo(() => {
    return baselineResult?.scorePercentageAverage ?? null;
  }, [baselineResult]);

  const baselineChartData = useMemo(() => {
    return (
      baselineResult?.averageAssessment?.map((bar: any) => ({
        color: bar.color,
        percentage: bar.percentage,
        resultTitle: bar.title,
      })) ?? []
    );
  }, [baselineResult?.averageAssessment]);

  return {
    baselineResult,
    baselinePercentage,
    baselineChartData,
    isLoading: baseline.isLoading,
  };
};

export default useQuizBaselineData;
