import React, { FC, Fragment, useCallback, useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';

import { IngredientItem, ProgressItem, RecipeContext } from 'models';
import { useLocalizedText } from 'hooks/useLocalizedText';
import { getIngredientsDataSummary } from 'utils/recipe';
import { roundDecimalNo } from 'utils/number';
import { Gap } from 'styles';

import Heading, { Tag } from 'components/UI/Heading';
import SelectField from 'components/UI/SelectField';
import StaticCard from 'components/UI/Cards/StaticCard';
import ProgressBar from 'components/UI/ProgressBar';

import {
  HeaderCol,
  HeaderGrid,
  IngredientRow,
  NutritionalGrid,
  ProgressGrid,
  ProgressHeader,
} from './styles';

const RecipeIngredients: FC = () => {
  const intl = useIntl();
  const { data } = useOutletContext<RecipeContext>();
  const { ingredients, numberOfServings } = data;
  const getText = useLocalizedText();

  const { register, watch } = useForm({ defaultValues: { numberOfServings } });

  // Servings
  const showNoServings = watch('numberOfServings');
  const nutritions = useMemo(
    () => getIngredientsDataSummary(ingredients),
    [ingredients]
  );

  const getIngredient = useCallback(
    (item: IngredientItem) => {
      const { unit, numberOfUnits, ingredient } = item;

      const activeNoServings = numberOfServings / showNoServings;
      const count = numberOfUnits / activeNoServings;
      const unitValue = unit?.mass || unit?.volume || null;
      const text =
        count > 1
          ? ingredient.descriptionPlural
          : ingredient.descriptionSingular;

      // Text only
      if (!numberOfUnits) {
        return <p>{getText(text)}</p>;
      }

      // Count + text
      if (
        !unit ||
        !unitValue ||
        !unit.abbreviationPlural ||
        !unit.abbreviationSingular
      ) {
        return (
          <Fragment>
            <b>{roundDecimalNo(count)}</b>
            <p>{getText(text)}</p>
          </Fragment>
        );
      }

      const unitText =
        count > 1 ? unit.abbreviationPlural : unit.abbreviationSingular;

      // Count + unit + text
      return (
        <Fragment>
          <b>{`${roundDecimalNo(count)} ${getText(unitText)}`}</b>
          <p>{getText(text)}</p>
        </Fragment>
      );
    },
    [numberOfServings, showNoServings, getText]
  );

  const nutritionList = useMemo<ProgressItem[]>(() => {
    const { carbohydrate, protein, fat, total } = nutritions;
    return [
      {
        text: intl.formatMessage({
          id: 'pageDietRecipeCarbonhydrate',
          defaultMessage: 'Carbonhydrate',
          description: 'Page title for carbonhydrate',
        }),
        value: carbohydrate / total,
        color: 'pink',
      },
      {
        text: intl.formatMessage({
          id: 'pageDietRecipeProtein',
          defaultMessage: 'Protein',
          description: 'Page title for protein',
        }),
        value: protein / total,
        color: 'blue',
      },
      {
        text: intl.formatMessage({
          id: 'pageDietRecipeFat',
          defaultMessage: 'Fat',
          description: 'Page title for fat',
        }),
        value: fat / total,
        color: 'orange',
      },
    ];
  }, [nutritions, intl]);

  return (
    <Fragment>
      <HeaderGrid>
        <Heading tag={Tag.H4}>
          <FormattedMessage
            id="pageDietRecipeSectionTitleIngredients"
            defaultMessage="Ingredients"
            description="Page section title for ingredients"
          />
        </Heading>
        <HeaderCol>
          <SelectField
            register={register('numberOfServings')}
            options={Array.from(Array(12).keys()).map((count) => ({
              name: `${count + 1}`,
              value: `${count + 1}`,
            }))}
          />
        </HeaderCol>
      </HeaderGrid>
      {ingredients.map((item, i) => (
        <IngredientRow key={`ingredient-${i}`}>
          {getIngredient(item)}
        </IngredientRow>
      ))}
      <Gap />
      <Heading tag={Tag.H4}>
        <FormattedMessage
          id="pageDietRecipeSectionTitleNutritionalContent"
          defaultMessage="Energy content per serving"
          description="Page section title for energy content"
        />
      </Heading>
      <StaticCard padding>
        <NutritionalGrid>
          {nutritionList.map(({ text, value, color }) => (
            <ProgressGrid key={text}>
              <ProgressHeader>
                <span>{text}</span>
                <span>{roundDecimalNo(value)}%</span>
              </ProgressHeader>
              <ProgressBar progress={value} color={color} />
            </ProgressGrid>
          ))}
          <ProgressHeader>
            <span>
              <FormattedMessage
                id="pageDietRecipeEnergy"
                defaultMessage="Energy"
                description="Page section title for ingredients"
              />
            </span>
            <span>
              {roundDecimalNo(nutritions.energy / numberOfServings)} kcal
            </span>
          </ProgressHeader>
        </NutritionalGrid>
      </StaticCard>
      
    </Fragment>
  );
};

export default RecipeIngredients;
