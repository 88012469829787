import { useCallback, useEffect, useState } from 'react';
import { authSelector } from 'store';
import { getAsset } from 'services';
import { useAppSelector } from './redux';

export const useGetAssetImage = (
  assetId?: string | null
): [string | null | undefined, () => void] => {
  const { accessToken } = useAppSelector(authSelector);

  // State
  const [src, setSrc] = useState<string | null>();

  const fetchAsset = useCallback(async () => {
    try {
      const src = await getAsset(assetId, accessToken);
      setSrc(src);
    } catch {
      setSrc(null);
    }
  }, [assetId, accessToken]);

  // Fetch asset image
  useEffect(() => {
    if (assetId) {
      fetchAsset();
    }
  }, [fetchAsset, assetId]);

  return [src, fetchAsset];
};
