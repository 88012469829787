import React, { FC, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { SubmitHandler, useForm } from 'react-hook-form';

import Button from 'components/UI/Button/Button';
import TextField from 'components/UI/TextField/TextField';

import { ActionContainer } from 'styles';
import ToggleCard from 'components/UI/Cards/ToggleCard';

export type AliasForm = {
  alias: string;
  displayEmail: boolean;
};

type Props = {
  submitForm: (values: AliasForm) => void;
  withDisplayEmailSetting: boolean;
};

const UserAliasForm: FC<Props> = ({ submitForm, withDisplayEmailSetting }) => {
  const intl = useIntl();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<AliasForm>({
    defaultValues: {
      alias: '',
      displayEmail: false,
    },
  });

  // Submit
  const onSubmit: SubmitHandler<AliasForm> = useCallback(
    (values) => submitForm(values),
    [submitForm]
  );

  const displayEmail = watch('displayEmail');

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        label={intl.formatMessage({
          id: 'inputAliasLabel',
          defaultMessage: 'Alias',
          description: 'Label for alias input',
        })}
        placeholder={intl.formatMessage({
          id: 'inputAliasPlaceholder',
          defaultMessage: 'Enter alias',
          description: 'Placeholder for alias input',
        })}
        error={errors.alias}
        register={register('alias', {
          required: intl.formatMessage({
            id: 'inputErrorAliasRequired',
            defaultMessage: 'An alias is required',
            description: 'Input error for alias required',
          }),
          maxLength: {
            value: 35,
            message: intl.formatMessage({
              id: 'inputErrorAliasMaxLength',
              defaultMessage: 'An alias cannot be more than 35 characters',
              description: 'Input error for alias max length',
            }),
          },
        })}
      />
      {withDisplayEmailSetting && (
        <ToggleCard
          title={intl.formatMessage({
            id: 'settingAliasDisplayEmail',
            defaultMessage: 'Show my email along with alias',
            description: 'Title for alias display email toggle setting',
          })}
          isActive={displayEmail}
          onToggle={() => setValue('displayEmail', !displayEmail)}
        />
      )}
      <ActionContainer>
        <Button type="submit" background="blue">
          <FormattedMessage
            id="acceptButton"
            defaultMessage="Accept"
            description="Accept button text"
          />
        </Button>
      </ActionContainer>
    </form>
  );
};

export default UserAliasForm;
