import React, { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { ContentWidth } from 'styles';
import { useAppDispatch } from 'hooks/redux';
import { setLayoutHeader } from 'store';
import { IconType } from 'components/UI/Icon';

import UserSettingsList from 'components/fragments/UserSettingsList';

const Settings: FC = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();

  // Set header
  useEffect(() => {
    dispatch(
      setLayoutHeader({
        title: intl.formatMessage({
          id: 'backButton',
          defaultMessage: 'Back',
          description: 'Back button text',
        }),
        icon: IconType.Back,
      })
    );
  }, [dispatch, intl]);

  return (
    <ContentWidth isSurface>
      <UserSettingsList allSettings />
    </ContentWidth>
  );
};

export default Settings;
