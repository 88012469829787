import React, { FC, useEffect, Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ContentWidth } from 'styles';
import { useAppDispatch } from 'hooks/redux';
import {
  setLayoutHeader,
  useCreateHeadOfficeMutation,
  useGetCompanyStructureQuery,
} from 'store';

import Heading, { Tag } from 'components/UI/Heading';
import Loader from 'components/UI/Loader';

import * as routes from 'router/routes';

import { IconType } from 'components/UI/Icon';

import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { usePretendCompany } from 'hooks/usePretendCompany';
import CreateUpdateHeadofficeForm from 'components/forms/CreateUpdateHeadofficeForm';

const AdminOfficeCreate: FC = () => {
  const intl = useIntl();
  const companyId = usePretendCompany();
  const dispatch = useAppDispatch();

  // Hooks
  const navigate = useNavigate();
  const { data, isLoading } = useGetCompanyStructureQuery({ companyId });
  const [createHeadoffice, { isLoading: isCreating, isSuccess }] =
    useCreateHeadOfficeMutation();

  // Set header
  useEffect(() => {
    dispatch(
      setLayoutHeader({
        title: intl.formatMessage({
          id: 'pageAdminOrganisationTitle',
          defaultMessage: 'Organisation',
          description: 'Page title for admin organisation page',
        }),
        companyInHeader: true,
        inverted: false,
        icon: IconType.Back,
        link: routes.ADMIN_ORGANISATION,
      })
    );
  }, [dispatch, intl]);

  useEffect(() => {
    if (isSuccess) {
      toast.success(
        intl.formatMessage({
          id: 'createHeadofficeSuccess',
          defaultMessage: 'Head office created successfully',
          description: 'Head office create toast success message',
        })
      );
      navigate(routes.ADMIN_ORGANISATION);
    }
  }, [isSuccess, navigate, intl]);

  // Loading
  if (isLoading || isCreating) {
    return <Loader color="blue" padding />;
  }

  // No data
  if (!data) {
    return null;
  }

  return (
    <Fragment>
      <ContentWidth isSurface>
        <Heading tag={Tag.H2}>
          <FormattedMessage
            id="pageAdminHeadofficeCreateTitle"
            defaultMessage="Create head office"
            description="Heading for admin head office create page"
          />
        </Heading>
        <CreateUpdateHeadofficeForm
          submitForm={(form) =>
            createHeadoffice({
              description: form.headofficeName,
              companyId,
            })
          }
        />
      </ContentWidth>
    </Fragment>
  );
};

export default AdminOfficeCreate;
