import React, { FC, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { backendApi } from 'config';

import { ContentWidth } from 'styles';
import { useAppDispatch } from 'hooks/redux';
import {
  setLayoutHeader,
  useDisconnectExternalActivityProviderMutation,
  useGetUserQuery,
} from 'store';

import Icon, { IconType } from 'components/UI/Icon';
import Heading from 'components/UI/Heading';
import Button from 'components/UI/Button';
import Loader from 'components/UI/Loader';
import { ButtonContent, ButtonWrap } from './styles';
import * as routes from 'router/routes';
import { ExternalStepProvider } from 'models';
import ListMenu from 'components/UI/ListMenu';
import EmptyState from '../../../components/UI/EmptyState';

const WearableDeviceConnection: FC = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();

  // Hooks
  const { externalActivities, externalActivitiesProvider, refetch } =
    useGetUserQuery(undefined, {
      selectFromResult: (res) => ({
        externalActivities: res.data?.externalActivities,
        externalActivitiesProvider: res.data?.externalActivitiesProvider,
      }),
    });

  const providers = Object.values(ExternalStepProvider);
  const user = useGetUserQuery();

  const [
    removeDeviceConnection,
    { isSuccess: removeSuccess, isLoading: removeIsLoading },
  ] = useDisconnectExternalActivityProviderMutation();

  useEffect(() => {
    if (!removeIsLoading && removeSuccess) {
      refetch();
    }
  }, [removeSuccess, removeIsLoading, refetch]);

  // Set header
  useEffect(() => {
    dispatch(
      setLayoutHeader({
        title: intl.formatMessage({
          id: 'pageSettingsTitle',
          defaultMessage: 'Settings',
          description: 'Page title for settings',
        }),
        icon: IconType.Back,
        link: routes.SETTINGS,
      })
    );
  }, [dispatch, intl]);

  const content = useMemo(() => {
    if (removeIsLoading || externalActivities == null) {
      return <Loader color="blue" padding />;
    }
    if (externalActivitiesProvider) {
      const providerName =
        externalActivitiesProvider.charAt(0) +
        externalActivitiesProvider.slice(1).toLowerCase();
      return (
        <Button
          key={externalActivitiesProvider}
          color="white"
          background="error"
          onClick={() =>
            removeDeviceConnection({ provider: externalActivitiesProvider })
          }
        >
          <FormattedMessage
            id="deviceConnectionRemoveButtonTitle"
            defaultMessage="Remove {provider} device connection"
            description="Button title for removing wearable device connection"
            values={{ provider: providerName }}
          />
        </Button>
      );
    }
    return (
      <ButtonWrap>
        {providers.map((provider) => {
          let providerName =
            provider.charAt(0) + provider.slice(1).toLowerCase();
          return (
            <Button
              key={provider}
              color="white"
              background="blue"
              onClick={() =>
                window.open(
                  `${backendApi}/external-activity?userId=${user.data?.id}&provider=${provider}`
                )
              }
            >
              <ButtonContent>
                <FormattedMessage
                  id="deviceConnectionConnectButtonTitle"
                  defaultMessage="Connect {provider} device"
                  description="Button title for connecting wearable device"
                  values={{ provider: providerName }}
                />
                <Icon type={IconType.ExternalLink} color="white" />
              </ButtonContent>
            </Button>
          );
        })}
      </ButtonWrap>
    );
  }, [
    externalActivities,
    externalActivitiesProvider,
    user.data?.id,
    providers,
    removeDeviceConnection,
    removeIsLoading,
  ]);

  if (user.isLoading) {
    return <Loader color="blue" padding />;
  }

  if (!user.data) {
    return (
      <EmptyState iconType={IconType.Food} padding>
        <FormattedMessage
          id="pageProfileEmptyState"
          defaultMessage="Profile not found"
          description="Empty state for profile page"
        />
      </EmptyState>
    );
  }

  return (
    <ContentWidth isSurface>
      <Heading>
        <FormattedMessage
          id="pageWearableDeviceConnectionTitle"
          defaultMessage="Connect a watch"
          description="Page title for connecting external devices via untied"
        />
      </Heading>
      <p>
        <FormattedMessage
          id="pageWearableDeviceConnectionText"
          defaultMessage="Integrate your Wellr account with your Garmin or Fitbit device. This will allow you to automatically sync your device activity data with Wellr."
          description="Text for external device connection settings page"
        />
      </p>
      <ListMenu
        menu={[
          {
            id: 1,
            text: intl.formatMessage({
              id: 'articleConnectWatchTitle',
              defaultMessage: 'Read this before connecting your device',
              description:
                'Link title to how-to article about connecting watch',
            }),
            link: routes.INSPIRATION_ARTICLE + '/connect-your-watch-to-wellr',
            icon: IconType.Inspiration,
          },
        ]}
      />
      <br />
      {content}
    </ContentWidth>
  );
};

export default WearableDeviceConnection;
