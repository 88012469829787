import styled from 'styled-components';
import { breakpoints } from 'styles';

export const ButtonGrid = styled.div`
  display: flex;
  gap: 8px;

  @media (max-width: ${breakpoints.s}px) {
    flex-direction: column-reverse;
  }
`;

export const InviteList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
  padding-top: 0;
`;

export const Grid = styled.div`
  overflow: hidden;
  border-radius: 16px;
`;
