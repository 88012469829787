import {
  ConfirmResetPasswordValues,
  ContentQuery,
  GenerateMfa,
  InviteUserQuery,
  Login2FAValues,
  LoginValues,
  MfaValues,
  RegisterInviteUserValues,
  ResetPasswordValues,
  Token,
  TokenResponse,
  User,
  UserGreeting,
  UserLevel,
  UserProfileValues,
  RegistrationCodeValues,
  UserValues,
  WellrPoint,
  RegistrationCodeResponse,
  UpdateEmailValues,
} from 'models';
import {
  getFirstDateOfWeek,
  formatDate,
  getLastDateOfWeek,
  getDateTime,
} from 'utils/date';
import { baseApi } from '../baseApi';
import { endpoints, GetNotificationStateType } from './endpointDefinitions';

const userApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<TokenResponse, LoginValues>({
      query: (body) => ({
        url: '/user/login',
        method: 'POST',
        body,
      }),
    }),
    loginMfa: builder.mutation<Token, Login2FAValues>({
      query: (body) => ({
        url: '/user/mfa/login',
        method: 'POST',
        body,
      }),
    }),
    migrateUser: builder.mutation<TokenResponse, LoginValues>({
      query: (body) => ({
        url: '/user/migrate',
        method: 'POST',
        body,
      }),
    }),
    generateMfaCode: builder.query<GenerateMfa, void>({
      query: () => '/user/mfa/generate',
    }),
    toggleMfa: builder.mutation<GenerateMfa, MfaValues>({
      query: ({ code }) => ({
        url: `/user/mfa/toggle?twoFactorAuthCode=${code}`,
        method: 'POST',
      }),
      invalidatesTags: [{ type: 'User', id: 'USER_ME' }],
    }),
    resetPassword: builder.mutation<User, ResetPasswordValues>({
      query: (body) => ({
        url: '/user/password/forgotten',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'User', id: 'USER_LIST' }],
    }),
    resetPasswordMigrate: builder.mutation<void, ResetPasswordValues>({
      query: ({ email }) => ({
        url: '/user/migrate/reset',
        params: { email },
        method: 'POST',
      }),
      invalidatesTags: [{ type: 'User', id: 'USER_LIST' }],
    }),
    confirmResetPassword: builder.mutation<User, ConfirmResetPasswordValues>({
      query: ({ resetCode, password, verifyPassword }) => ({
        url: '/user/password/reset',
        method: 'POST',
        body: {
          resetCode,
          newPassword: {
            password,
            verifyPassword,
          },
        },
      }),
      invalidatesTags: [{ type: 'User', id: 'USER_LIST' }],
    }),
    createUser: builder.mutation<User, UserValues>({
      query: (body) => ({
        url: '/user',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'User', id: 'USER_LIST' }],
    }),
    getUsers: builder.query<User[], void>({
      query: () => '/users',
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'User', id } as const)),
              { type: 'User', id: 'USER_LIST' },
            ]
          : [{ type: 'User', id: 'USER_LIST' }],
    }),
    getUser: builder.query<User, void>({
      query: () => '/user/me',
      providesTags: [{ type: 'User', id: 'USER_ME' }],
    }),
    getUserRegistrationCode: builder.query<
      RegistrationCodeResponse | null,
      RegistrationCodeValues
    >({
      query: ({ email }) => `/user/registration-code/${email}`,
      providesTags: ['UserRegistrationCode'],
      transformResponse: (response: Array<RegistrationCodeResponse>) => {
        return response.length === 0
          ? null
          : {
              ...response[0],
              expires: getDateTime(new Date(response[0].expires)),
            };
      },
    }),
    getUserByEmail: builder.query<User, string>({
      query: (email) => `/user/${email}`,
      providesTags: (data) => [{ type: 'Membership', id: data?.membership.id }],
    }),
    updateUserEmail: builder.mutation<void, UpdateEmailValues>({
      query: (body) => ({
        url: '/user/user-email',
        method: 'PATCH',
        body,
      }),
    }),
    updateUser: builder.mutation<User, UserProfileValues>({
      query: (body) => ({
        url: '/user/me',
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [{ type: 'User', id: 'USER_ME' }],
    }),
    deleteUser: builder.mutation<void, void>({
      query: () => ({
        url: '/user/me',
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'User', id: 'USER_LIST' }],
    }),
    registerUserInvite: builder.mutation<void, RegisterInviteUserValues>({
      query: ({ email, inviteCode, language }) => ({
        url: `/user/invite/register?language=${language}`,
        method: 'POST',
        body: { email, inviteCode },
      }),
    }),
    finalizeUserInvite: builder.mutation<void, InviteUserQuery>({
      query: ({ body, secretCode }) => ({
        url: `/user/invite/finalize?secretCode=${secretCode}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'User', id: 'USER_LIST' }],
    }),
    deleteUserInvite: builder.mutation<void, InviteUserQuery>({
      query: ({ secretCode }) => ({
        url: `/user/invite/finalize?secretCode=${secretCode}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'User', id: 'USER_LIST' }],
    }),
    getWeeklyPoints: builder.query<WellrPoint[], void>({
      query: () =>
        `/user/me/points?from=${formatDate(
          getFirstDateOfWeek()
        )}T00:00:00Z&to=${formatDate(getLastDateOfWeek())}T23:59:59Z`,
      providesTags: [{ type: 'User', id: 'POINTS' }],
    }),
    updateLanguage: builder.mutation<void, string>({
      query: (language) => ({
        url: `/user/me/language?languageCode=${language}`,
        method: 'PATCH',
      }),
    }),
    updateLastActivity: builder.mutation<void, void>({
      query: () => ({
        url: '/user/me/activity/update',
        method: 'PATCH',
      }),
    }),
    getUserLevels: builder.query<UserLevel[], ContentQuery>({
      query: ({ language }) => `/user/levels?languageCode=${language}`,
    }),
    getUserGreeting: builder.query<UserGreeting[], ContentQuery>({
      query: ({ language }) => `/user/me/greeting?languageCode=${language}`,
      transformResponse: (response: UserGreeting | UserGreeting[]) => {
        // If the response is not an array, wrap it in an array
        if (!Array.isArray(response)) {
          return [response];
        }
        return response;
      },
    }),
    getNotificationState: builder.query<
      GetNotificationStateType['result'],
      GetNotificationStateType['queryArgs']
    >({
      query: () => ({
        url: endpoints.getNotificationState.path,
        method: endpoints.getNotificationState.method,
      }),
      providesTags: ['Notifications'],
    }),
  }),
});

export const {
  useLoginMutation,
  useLoginMfaMutation,
  useGenerateMfaCodeQuery,
  useToggleMfaMutation,
  useResetPasswordMutation,
  useConfirmResetPasswordMutation,
  useResetPasswordMigrateMutation,
  useCreateUserMutation,
  useGetUsersQuery,
  useGetUserQuery,
  useGetUserRegistrationCodeQuery,
  useUpdateUserEmailMutation,
  useLazyGetUserByEmailQuery,
  useGetUserByEmailQuery,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useRegisterUserInviteMutation,
  useFinalizeUserInviteMutation,
  useDeleteUserInviteMutation,
  useUpdateLanguageMutation,
  useGetWeeklyPointsQuery,
  useGetUserLevelsQuery,
  useGetUserGreetingQuery,
  useMigrateUserMutation,
  useGetNotificationStateQuery,
  useUpdateLastActivityMutation,
} = userApi;
