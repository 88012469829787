import React, { FC, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';

import Button from 'components/UI/Button/Button';
import TextField from 'components/UI/TextField/TextField';

import { ActionContainer } from 'styles';
import SelectField from 'components/UI/SelectField';
import { useGetCompanyStructureQuery } from 'store';
import { SelectOption } from 'models';
import { FieldGrid } from './styles';
import Loader from 'components/UI/Loader';
import { usePretendCompany } from 'hooks/usePretendCompany';

type Props = {
  submitForm: (data: CreateDepartmentFormData) => void;
  department?: {
    id: string;
    office: string | null | undefined;
    description: string | null | undefined;
  };
};

export type CreateDepartmentFormData = {
  office: string;
  departmentName: string;
};

const CreateUpdateDepartmentForm: FC<Props> = ({ submitForm, department }) => {
  const intl = useIntl();
  const companyId = usePretendCompany();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isDirty },
  } = useForm<CreateDepartmentFormData>({
    defaultValues: {
      office: department?.office ?? '',
      departmentName: department?.description ?? '',
    },
  });

  const selectedOffice = watch('office');

  const { data, isLoading } = useGetCompanyStructureQuery({ companyId });

  const officesOptions: SelectOption[] = useMemo(() => {
    if (!data) {
      return [];
    }
    const offices =
      data.offices?.map((office) => ({
        value: office.id,
        name: office.description ?? '',
      })) ?? [];

    const headOfficeOffices =
      data.headOffices
        ?.map((headOffice) =>
          (headOffice.offices ?? []).map((office) => ({
            value: office.id,
            name: `${office.description} - ${headOffice.description}` ?? '',
          }))
        )
        .flat() ?? [];

    return [...offices, ...headOfficeOffices];
  }, [data]);

  useEffect(() => {
    if (department) {
      setValue('departmentName', department?.description ?? '');
      setValue('office', department?.office ?? '');
    }
  }, [department, setValue]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <FieldGrid>
        <TextField
          label={intl.formatMessage({
            id: 'inputDepartmentNameLabel',
            defaultMessage: 'Department name',
            description: 'Label for department name input',
          })}
          placeholder={intl.formatMessage({
            id: 'inputDepartmentNamePlaceholder',
            defaultMessage: 'Enter department name',
            description: 'Placeholder for department name input',
          })}
          error={errors.departmentName}
          register={register('departmentName', {
            required: intl.formatMessage({
              id: 'inputErrorDepartmentNameRequired',
              defaultMessage: 'A department name is required',
              description: 'Input error for department name required',
            }),
          })}
        />
        <SelectField
          label={intl.formatMessage({
            id: 'inputOfficeLabel',
            defaultMessage: 'Office',
            description: 'Label for office input',
          })}
          register={register('office')}
          placeholder={intl.formatMessage({
            id: 'inputOfficePlaceholder',
            defaultMessage: 'No office',
            description: 'Placeholder for office input',
          })}
          defaultValue={selectedOffice}
          options={officesOptions}
          error={errors.office}
        />
      </FieldGrid>
      <ActionContainer>
        <Button type="submit" background="blue" disabled={!isDirty}>
          <FormattedMessage
            id="saveButton"
            defaultMessage="Save"
            description="Save button text"
          />
        </Button>
      </ActionContainer>
    </form>
  );
};

export default CreateUpdateDepartmentForm;
