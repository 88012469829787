import React, { FC, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-hot-toast';

import { useUpdateContestUserMutation } from 'store';

import Loader from 'components/UI/Loader/Loader';
import Icon, { IconType } from 'components/UI/Icon';
import IconButton from 'components/UI/IconButton';
import Heading, { Tag } from 'components/UI/Heading';
import SlideoutModal from 'components/UI/SlideoutModal';

import { Header, Body, SelectItem } from './styles';
import { useForm } from 'react-hook-form';
import SelectField from 'components/UI/SelectField';
import { SelectOption } from 'models';
import { ActionContainer } from 'styles';
import Button from 'components/UI/Button';
import RadioButton from 'components/UI/RadioButton';
import TextField from 'components/UI/TextField';

type UpdateCompetitionUserForm = {
  alias: string;
  teamId?: string;
  forceAccept?: boolean;
};

type Props = {
  contestUser: {
    alias: string | null;
    email: string | null;
    contestUserId: string;
    contestTeamId: string | null;
  } | null;
  teams: SelectOption[];
  isOpen: boolean;
  onClose: () => void;
};

const UpdateCompetitionParticipantModal: FC<Props> = ({
  contestUser,
  teams,
  isOpen,
  onClose,
}) => {
  const intl = useIntl();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm<UpdateCompetitionUserForm>();

  const [updateUser, updateUserResult] = useUpdateContestUserMutation();

  useEffect(() => {
    if (updateUserResult.isSuccess) {
      toast.success(
        intl.formatMessage({
          id: 'updateUserModalSuccess',
          defaultMessage: 'User update successful',
          description: 'User update success message',
        })
      );
      setValue('forceAccept', undefined);
      onClose();
    }
  }, [updateUserResult.isSuccess, intl, onClose, setValue]);

  const forceAccept = watch('forceAccept');
  const teamId = watch('teamId');
  const alias = watch('alias');

  useEffect(() => {
    setValue('teamId', contestUser?.contestTeamId ?? '');
    setValue('alias', contestUser?.alias ?? '');
    clearErrors();
  }, [contestUser, setValue, clearErrors]);

  // Content
  const content = useMemo(() => {
    if (updateUserResult.isLoading) {
      return <Loader padding color="blue" />;
    }
    const toggleRadio = (value: boolean) => {
      setValue('forceAccept', value !== forceAccept ? value : undefined);
    };
    const buttonEnabled =
      forceAccept !== undefined ||
      contestUser?.contestTeamId !== teamId ||
      contestUser?.alias !== alias;
    return (
      <form
        onSubmit={handleSubmit((form) =>
          updateUser({
            contestUserId: contestUser?.contestUserId as string,
            contestTeamId: form.teamId === '' ? undefined : form.teamId,
            forceAcceptOrUnaccept: form.forceAccept,
            alias: form.alias === '' ? null : form.alias,
          })
        )}
      >
        <TextField
          label={intl.formatMessage({
            id: 'inputAliasLabel',
            defaultMessage: 'Alias',
            description: 'Label for alias input',
          })}
          placeholder={intl.formatMessage({
            id: 'inputUserAliasPlaceholder',
            defaultMessage: 'Enter user alias',
            description: 'Placeholder for alias input',
          })}
          error={errors.alias}
          register={register('alias', {
            validate: (value) => {
              if (
                value === '' &&
                (contestUser?.alias != null || forceAccept === true)
              ) {
                return intl.formatMessage({
                  id: 'inputErrorAliasRequired',
                  defaultMessage: 'An alias is required',
                  description: 'Input error for alias required',
                });
              }
            },
            maxLength: {
              value: 35,
              message: intl.formatMessage({
                id: 'inputErrorAliasMaxLength',
                defaultMessage: 'An alias cannot be more than 35 characters',
                description: 'Input error for alias max length',
              }),
            },
          })}
        />
        <SelectField
          label={intl.formatMessage({
            id: 'inputTeamLabel',
            defaultMessage: 'Team',
            description: 'Label for team input',
          })}
          register={register('teamId')}
          placeholder={intl.formatMessage({
            id: 'inputTeamPlaceholder',
            defaultMessage: 'No team',
            description: 'Placeholder for team input',
          })}
          options={teams}
          error={errors.teamId}
        />
        <br />
        <SelectItem onClick={() => toggleRadio(true)}>
          <RadioButton name="custom-title" isChecked={forceAccept === true} />
          <span>
            <FormattedMessage
              id="inputUpdateCompetitionParticipantForceAcceptTrue"
              defaultMessage="Force accept competition"
              description="Radio button label enabling/disabling custom competition title"
            />
          </span>
        </SelectItem>
        <SelectItem onClick={() => toggleRadio(false)}>
          <RadioButton name="custom-title" isChecked={forceAccept === false} />
          <span>
            <FormattedMessage
              id="inputUpdateCompetitionParticipantForceAcceptFalse"
              defaultMessage="Force unaccept competition"
              description="Radio button label enabling/disabling custom competition title"
            />
          </span>
        </SelectItem>
        <ActionContainer>
          <Button type="submit" background="blue" disabled={!buttonEnabled}>
            <FormattedMessage
              id="updateButton"
              defaultMessage="Update"
              description="Update button text"
            />
          </Button>
        </ActionContainer>
      </form>
    );
  }, [
    updateUserResult.isLoading,
    forceAccept,
    contestUser?.contestTeamId,
    contestUser?.alias,
    contestUser?.contestUserId,
    teamId,
    alias,
    handleSubmit,
    intl,
    errors.alias,
    errors.teamId,
    register,
    teams,
    setValue,
    updateUser,
  ]);

  const onModalClose = () => {
    onClose();
    setValue('teamId', contestUser?.contestTeamId ?? '');
    setValue('forceAccept', undefined);
  };

  return (
    <SlideoutModal isOpen={isOpen} onClose={onModalClose}>
      <div>
        <Header>
          <Heading tag={Tag.H4}>
            {contestUser?.alias
              ? `${contestUser.alias} (${contestUser?.email})`
              : contestUser?.email ?? ''}
          </Heading>
          <IconButton onClick={onClose} padding>
            <Icon type={IconType.Close} />
          </IconButton>
        </Header>
        <Body>{content}</Body>
      </div>
    </SlideoutModal>
  );
};

export default UpdateCompetitionParticipantModal;
