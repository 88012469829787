import React, { FC, Fragment, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import * as routes from 'router/routes';

import { useCreateQuizMutation, useGetMyContestQuizesQuery } from 'store';
import { Contest, ContestQuiz, Language } from 'models';

import Heading, { Tag } from 'components/UI/Heading';

import { CardList } from './styles';

import Loader from 'components/UI/Loader';
import ContestQuizCard from 'components/cards/ContestQuizCard';
import { SmallGap } from '../AdminQuizResults/styles';

type Props = {
  data: ContestQuiz[];
  contestId: string;
  goalId: string;
  completed?: string[] | null;
  language: Language | null;
  contest: Contest;
};

const ContestQuizzes: FC<Props> = ({
  data,
  contestId,
  goalId,
  completed,
  language,
  contest
}) => {

  // Hooks
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();
  const [createQiuz, createResult] = useCreateQuizMutation();
  const quizes = useGetMyContestQuizesQuery({
    slugs: data.map((item) => item.slug),
    language: language?.languageCode,
  });

  // Actions
  const onQuizStartClick = useCallback(
    (slug: string, quizId: string, type: string) => async () => {
      await createQiuz({ language: language?.languageCode, contestId, slug });

      const state = { from: location, goalId, contestId, quizId, quizType: type };
      const path = `${routes.QUIZ}/${slug}`;

      navigate(path, { state });
    },
    [createQiuz, language?.languageCode, navigate, location, goalId, contestId]
  );

  // Contest quizzes
  const contestQuizContent = useMemo(() => {
    // Loading
    if (quizes.isLoading || createResult.isLoading) {
      return <Loader color="blue" padding />;
    }

    // No data
    if (!quizes.data) {
      return null;
    }

    const quizesFiltered = quizes.data.filter((quiz) =>
      contest.goals[0].contestQuizzes?.some((contestQuiz) => contestQuiz.slug === quiz.quizSlug)
    );


    return quizesFiltered.map((item) => {
      // Find the first contestQuiz that matches the condition
      const contestQuiz = contest.goals.find(goal =>
        goal.contestQuizzes?.find(contestQuiz => contestQuiz.slug === item.quizSlug)
      )?.contestQuizzes?.find(contestQuiz => contestQuiz.slug === item.quizSlug);

      // Now you can correctly access the properties of contestQuiz
      const quizId = contestQuiz?.id ?? '';
      const quizType = contestQuiz?.quizType ?? '';
      const isCompleted = completed?.includes(quizId) ?? false;


      return (
        <ContestQuizCard
          key={item.quizSlug}
          category={item.quizDefinition.healthCategory}
          title={item.quizDefinition.title}
          text={item.quizDefinition.summary}
          contestAwardedStepsOnComplete={contestQuiz?.contestAwardedStepsOnComplete ?? 0}
          completed={isCompleted}
          hasContestEnded={contest.completed ? true : false}
          onClick={onQuizStartClick(item.quizSlug, quizId, quizType)}
          buttonText={intl.formatMessage({
            id: 'takeTestButton',
            defaultMessage: 'Take test',
            description: 'Take test button text',
          })}
        />
      );
    },);
  }, [quizes.isLoading, quizes.data, createResult.isLoading, contest.goals, contest.completed, completed, onQuizStartClick, intl]);

  // No data
  if (!data.length) {
    return null;
  }

  return (
    <Fragment>

      <Heading tag={Tag.H4}>
        <FormattedMessage
          id="pageCompetitionOverviewContestTestsTitle"
          defaultMessage="Contest tests"
          description="Section title for contest tests"
        />
      </Heading>

      <CardList>
        {contestQuizContent}
      </CardList>

      <SmallGap />
    </Fragment>
  );
};

export default ContestQuizzes;
