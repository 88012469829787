import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { ContestTeamUser } from 'models';
import { sortArrayByProperty } from 'utils/array';

import StaticCard from 'components/UI/Cards/StaticCard';
import EmptyState from 'components/UI/EmptyState';
import { IconType } from 'components/UI/Icon';

import CompetitionUserListItem from './CompetitionUserListItem';
import { TeamList } from './styles';

type Props = {
  users?: ContestTeamUser[];
  userId?: string;
  captain?: string;
  onEditUser: () => void;
};

const CompetitionUserList: FC<Props> = ({
  users,
  userId,
  captain,
  onEditUser,
}) => {
  // Check if users exist
  if (!users?.length) {
    return (
      <EmptyState iconType={IconType.Users} padding>
        <FormattedMessage
          id="competitionTopListEmptyState"
          defaultMessage="No users found"
          description="Empty state for competition top list"
        />
      </EmptyState>
    );
  }

  return (
    <StaticCard>
      <TeamList>
        {sortArrayByProperty(users, 'alias', 'desc').map((user, i) => {
          return (
            <CompetitionUserListItem
              key={`user-${i + 1}`}
              user={user}
              userId={userId}
              captain={captain}
              isLast={users.length === i + 1}
              onEditUser={onEditUser}
            />
          );
        })}
      </TeamList>
    </StaticCard>
  );
};

export default CompetitionUserList;
