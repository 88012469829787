import React, { FC, useEffect, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { TabMenuItem } from 'models';
import { ContentWidth, Gap } from 'styles';
import { useAppDispatch } from 'hooks/redux';
import { setLayoutHeader } from 'store';
import * as routes from 'router/routes';
import TabMenu from 'components/UI/TabMenu';

const ExercisesCategoryTabs: FC = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();

  // Set header
  useEffect(() => {
    dispatch(
      setLayoutHeader({
        title: intl.formatMessage({
          id: 'pageExerciseTitle',
          defaultMessage: 'Exercise',
          description: 'Page title for exercise',
        }),
      })
    );
  }, [dispatch, intl]);

  // Tab menu
  const tabMenu: TabMenuItem[] = useMemo(() => {
    return [
      {
        id: 1,
        text: intl.formatMessage({
          id: 'tabExercisePrograms',
          defaultMessage: 'Programs',
          description: 'Tab item for exercise programs',
        }),
        link: routes.EXERCISE,
      },
      {
        id: 2,
        text: intl.formatMessage({
          id: 'tabExercises',
          defaultMessage: 'Exercises',
          description: 'Tab item for exercises',
        }),
        link: routes.EXERCISES,
      },
    ];
  }, [intl]);

  return (
    <ContentWidth isSurface>
      <TabMenu menuId="exercise-category" menu={tabMenu} />
      <Gap />
      <Outlet />
    </ContentWidth>
  );
};

export default ExercisesCategoryTabs;
