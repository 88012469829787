import { adminSelector, useGetUserQuery } from 'store';
import { useAppSelector } from './redux';

export const usePretendCompany = (skip = false) => {
  const { selectedCompany } = useAppSelector(adminSelector);
  const { userCompanyId } = useGetUserQuery(undefined, {
    selectFromResult: ({ data }) => ({
      userCompanyId: data?.membership.companyId,
    }),
    skip,
  });

  if (!skip && selectedCompany) {
    return selectedCompany.id !== userCompanyId
      ? selectedCompany.id
      : undefined;
  }
  return undefined;
};
