import { useCallback, useState } from 'react';

const useModal = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const onOpenModal = useCallback(() => setModalOpen(true), []);

  const onCloseModal = useCallback(() => setModalOpen(false), []);

  return {
    modalOpen,
    onOpenModal,
    onCloseModal,
  };
};

export default useModal;
