import { FC, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';

import Button from 'components/UI/Button/Button';
import TextField from 'components/UI/TextField/TextField';

import { ButtonContainer, FieldGrid } from './styles';
import { useMinMaxValidation } from 'hooks/useMinMaxValidation';
import SelectField from 'components/UI/SelectField';
import { useUseGetUmbrellaCompaniesQuery } from 'store';

type Props = {
  submitForm: (data: UpdateCompanyFormData) => void;
  company: UpdateCompanyFormData;
};

export type UpdateCompanyFormData = {
  description: string | null;
  umbrellaCompanyId: string | null;
  healthQuizMinResultsForStats: number | null;
  pulseQuizMinResultsForStats: number | null;
};

const UpdateCompanyForm: FC<Props> = ({ submitForm, company }) => {
  const intl = useIntl();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
  } = useForm<UpdateCompanyFormData>({
    defaultValues: company,
  });

  const umbrellaCompanies = useUseGetUmbrellaCompaniesQuery();

  const umbrellaCompanyOptions = useMemo(() => {
    return umbrellaCompanies.data?.map((company) => ({
      value: company.id,
      name: company.description ?? '-',
    }));
  }, [umbrellaCompanies?.data]);

  useEffect(() => {
    if (umbrellaCompanyOptions !== null) {
      setValue('umbrellaCompanyId', company.umbrellaCompanyId ?? '');
    }
  }, [company.umbrellaCompanyId, setValue, umbrellaCompanyOptions]);

  useEffect(() => {
    setValue('description', company.description);
    setValue(
      'healthQuizMinResultsForStats',
      company.healthQuizMinResultsForStats
    );
    setValue(
      'pulseQuizMinResultsForStats',
      company.pulseQuizMinResultsForStats
    );
  }, [company, setValue]);

  const numberValidation = useMinMaxValidation(5, 5000);

  const onSubmit = handleSubmit((data) => {
    submitForm({
      ...data,
      umbrellaCompanyId:
        data.umbrellaCompanyId === '' ? null : data.umbrellaCompanyId,
    });
  });

  return (
    <form onSubmit={onSubmit}>
      <FieldGrid>
        <TextField
          label={intl.formatMessage({
            id: 'inputCompanyDescriptionLabel',
            defaultMessage: 'Company description',
            description: 'Label for company description input',
          })}
          placeholder={intl.formatMessage({
            id: 'inputCompanyDescriptionPlaceholder',
            defaultMessage: 'Enter company description',
            description: 'Placeholder for company description input',
          })}
          error={errors.description}
          register={register('description')}
        />
        <SelectField
          label={intl.formatMessage({
            id: 'inputCompanyUmbrellaCompanyPlaceholder',
            defaultMessage: 'Umbrella company',
            description: 'Label for the umbrella company select field',
          })}
          options={umbrellaCompanyOptions ?? []}
          register={register('umbrellaCompanyId')}
          placeholder={intl.formatMessage({
            id: 'inputCompanyUmbrellaCompanyNoneOption',
            defaultMessage: 'No umbrella company',
            description: 'Placeholder for the umbrella company select field',
          })}
        />
        <br />
        <TextField
          label={intl.formatMessage({
            id: 'inputCompanyHealthQuizMinResultsLabel',
            defaultMessage: 'Minimum health quiz results to show statistics',
            description: 'Label for healthQuizMinResultsForStats input',
          })}
          error={errors.healthQuizMinResultsForStats}
          register={register('healthQuizMinResultsForStats', numberValidation)}
          type="number"
        />
        <TextField
          label={intl.formatMessage({
            id: 'inputCompanyPulseQuizMinResultsLabel',
            defaultMessage: 'Minimum pulse test results to show statistics',
            description: 'Label for pulseQuizMinResultsForStats input',
          })}
          error={errors.pulseQuizMinResultsForStats}
          register={register('pulseQuizMinResultsForStats', numberValidation)}
          type="number"
        />
      </FieldGrid>
      <ButtonContainer>
        <Button type="submit" background="blue" disabled={!isDirty}>
          <FormattedMessage
            id="saveButton"
            defaultMessage="Save"
            description="Save button text"
          />
        </Button>
      </ButtonContainer>
    </form>
  );
};

export default UpdateCompanyForm;
