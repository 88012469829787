import styled from 'styled-components';

type Props = {
  isViewed?: boolean
}

export const Card = styled.div<Props>`
  display: flex;
  padding-inline: 8px;
  flex-direction: column;
  justify-content: center;
  background: ${({ theme, isViewed = true }) => isViewed ? theme.colors.backgroundLight : theme.colors.backgroundDark};
  font-size: 16px;
  border-radius: 16px;
  cursor: pointer;
  overflow: hidden;
  min-height: 64px;
`;

export const CardHeading = styled.span`
  font-size: 16px;
  font-weight: 900;
`;

export const CardDescription = styled.div`
  font-size: 12px;
  fony-family: 'Mark-Medium';
  color: ${({ theme }) => theme.colors.grayText};
`;
