import React, { FC } from 'react';
import { ThemeColor } from 'styles';
import trophyGold from 'assets/vectors/gold-trophy.svg';
import trophySilver from 'assets/vectors/silver-trophy.svg';
import trophyBronze from 'assets/vectors/bronze-trophy.svg';
import { Badge, Container } from './styles';

type Props = {
  background?: ThemeColor;
  color?: ThemeColor;
  value?: number;
  toplist?: boolean;
};

const MAX_VALUE = 9;
const MAX_TOP_LIST_VALUE = 99;
const PADDING_RIGHT_STYLE = { paddingRight: 2 };

const formatBubbleValue = (value: number, toplist: boolean) => {
  if (!value || value <= 0) return '';
  if (value > MAX_VALUE && !toplist) return `${MAX_VALUE}+`;
  if (toplist && value > MAX_TOP_LIST_VALUE) return `${MAX_TOP_LIST_VALUE}+`;
  return `${value}`;
}

const getTrophyIcon = (value: number, toplist: boolean) => {
  if (!toplist) return undefined;
  switch (value) {
    case 1: return trophyGold;
    case 2: return trophySilver;
    case 3: return trophyBronze;
    default: return undefined;
  }
}

const Bubble: FC<Props> = ({
  background = 'black',
  color = 'white',
  value = 0,
  toplist = false,
}) => {
  const formattedValue = formatBubbleValue(value, toplist);
  const trophyIcon = getTrophyIcon(value, toplist);
  const shouldApplyPadding = value > MAX_VALUE && toplist;

  return (
    <Container
      background={background}
      color={color}
      value={formattedValue}
    >
      <span style={shouldApplyPadding ? PADDING_RIGHT_STYLE : {}}>
        {toplist && value <= 3 ? (
          <Badge src={trophyIcon} alt="points badge" />
        ) : (
          formattedValue
        )}
      </span>
    </Container>
  );
};

export default Bubble;