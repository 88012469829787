import styled from 'styled-components';
import { breakpoints } from 'styles';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: inherit;

  @media (max-width: ${breakpoints.s}px) {
    height: auto;
    margin-bottom: 96px;
  }
`;

export const Fields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
`;
