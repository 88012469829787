import styled from 'styled-components';
import { breakpoints } from 'styles';

export const HeroSliderWrap = styled.div`
  position: relative;
  margin: 0 auto;
  max-width: 680px;

  @media (max-width: ${breakpoints.l}px) {
    overflow-x: hidden;
    max-width: none;
    margin: 0;
  }
`;
