export function calculateBMI(weight: number, length: number): number {
  return weight / (length * length * 0.0001);
}

export function getHealthWheelValue(value: number, minValue: number): number {
  if (value > minValue) {
    return value;
  }
  if (value === 0) {
    return 10;
  }
  return minValue;
}
