import { FC, ReactNode } from 'react';
import { ThemeColor } from 'styles';

import Icon, { IconType } from 'components/UI/Icon';
import Heading, { Tag } from 'components/UI/Heading';

import {
  Card,
  BackgroundIcon,
  Content,
  ContentHeader,
  ContentBody,
} from './styles';

type Props = {
  title: string;
  description?: string;
  children: ReactNode;
  background?: ThemeColor;
  iconColor?: ThemeColor;
  icon?: IconType;
};

const HighlightCard: FC<Props> = ({
  children,
  title,
  description,
  background = 'blue',
  iconColor = 'blueDark',
  icon = IconType.Globe,
}) => {
  return (
    <Card background={background}>
      <Content>
        <ContentHeader>
          <Heading tag={Tag.H4}>{title}</Heading>
        </ContentHeader>
        <ContentBody>
          <Heading tag={Tag.H2}>{children}</Heading>
          {description && <p>{description}</p>}
        </ContentBody>
      </Content>
      <BackgroundIcon>
        <Icon type={icon} color={iconColor} />
      </BackgroundIcon>
    </Card>
  );
};

export default HighlightCard;
