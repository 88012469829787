import { MenuType } from 'models';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { layoutSelector, setActiveMenuTab } from 'store';
import { useAppSelector } from './redux';

export const useMenuType = () => {
  const dispatch = useDispatch();
  const { activeMenuTab: activeTab } = useAppSelector(layoutSelector);
  const setActiveTab = useCallback(
    (tab: MenuType) => {
      dispatch(setActiveMenuTab(tab));
    },
    [dispatch]
  );
  return { activeTab, setActiveTab };
};
