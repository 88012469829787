import { FC } from 'react';
import { Image } from 'models';

import { Container, ImageOverlay, ImageView } from './styles';

type Props = {
  image: Image | null;
};

const HeroImage: FC<Props> = ({ image }) => {
  if (!image) {
    return null;
  }
  return (
    <Container>
      <ImageView src={image.src} alt={image.alt} />
      <ImageOverlay />
    </Container>
  );
};

export default HeroImage;
