import React, { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { ContentImage, ContentText, LanguageCode } from 'models';

import { getImage } from 'utils/asset';
import { getDateDistance, isBefore } from 'utils/date';
import { useLocalizedText } from 'hooks/useLocalizedText';
import { Category } from 'styles';

import WellrCoins from 'components/UI/WellrCoins';
import HeroSmall from 'components/UI/Heros/HeroSmall';
import Heading from 'components/UI/Heading';

import { CategoryHeader, Byline } from './styles';

type Props = {
  title: ContentText | null;
  categoryTitle: string;
  image: ContentImage | null;
  wellrPoints: number;
  start: string;
  stop: string;
  noStopDate?: boolean;
  languageCode?: LanguageCode;
};

const CompetitionStatus: FC<Props> = ({
  title,
  categoryTitle,
  image,
  start,
  stop,
  noStopDate,
  wellrPoints,
  languageCode,
}) => {
  const intl = useIntl();
  const getText = useLocalizedText();

  // Description
  const description = useMemo(() => {
    const value = getDateDistance(start, languageCode);
    if (isBefore(start)) {
      return intl.formatMessage(
        {
          id: 'pageCompetitionStarts',
          defaultMessage: 'Starts {value}',
          description: 'Competitions page starts text',
        },
        { value }
      );
    }
    if (noStopDate) {
      return null;
    }
    if (isBefore(stop)) {
      return intl.formatMessage(
        {
          id: 'pageCompetitionEnds',
          defaultMessage: 'Ends {value}',
          description: 'Competitions page ends text',
        },
        { value: getDateDistance(stop, languageCode) }
      );
    }
    return intl.formatMessage(
      {
        id: 'pageCompetitionEnded',
        defaultMessage: 'Ended {value}',
        description: 'Competitions page ended text',
      },
      { value: getDateDistance(stop, languageCode) }
    );
  }, [start, stop, languageCode, noStopDate, intl]);

  return (
    <HeroSmall image={getImage(image)}>
      <CategoryHeader>
        <Category>{categoryTitle}</Category>
        <Heading>{getText(title)}</Heading>
        <Byline>
          <WellrCoins count={wellrPoints} right />
          <span>{description ? `· ${description}` : null}</span>
        </Byline>
      </CategoryHeader>
    </HeroSmall>
  );
};

export default CompetitionStatus;
