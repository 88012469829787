import React, { FC, Fragment, useCallback, useEffect, useMemo } from 'react';
import Loader from 'components/UI/Loader';
import Icon, { IconType } from 'components/UI/Icon';
import IconButton from 'components/UI/IconButton';
import Heading, { Tag } from 'components/UI/Heading';
import SlideoutModal from 'components/UI/SlideoutModal';
import Button from 'components/UI/Button/Button';
import { FormattedMessage } from 'react-intl';
import { ActionContainer } from 'styles';
import { Header, Body } from './styles';
import { useRemoveCompanyNotificationScheduleMutation } from 'store/notificationScheduleService/notificationScheduleService';

type Props = {
  id: string | undefined;
  notificationDataId: string | undefined;
  isOpen: boolean;
  onClose: () => void;
};

const DeleteScheduleModal: FC<Props> = ({ id, isOpen, onClose, notificationDataId }) => {
  // Hooks
  const [deleteSchedule, deleteResult] = useRemoveCompanyNotificationScheduleMutation();

  // Success
  useEffect(() => {
    if (deleteResult.isSuccess) {
      onClose();
    }
  }, [deleteResult, onClose]);

  // On delete
  const onDelete = useCallback(() => {
    if (id && notificationDataId) {
      deleteSchedule({companyId: id, notificationDataId: notificationDataId});
    }
  }, [deleteSchedule, id, notificationDataId]);

  const content = useMemo(() => {
    if (deleteResult.isLoading) {
      return <Loader padding color="blue" />;
    }
    return (
      <Fragment>
        <p>
          <FormattedMessage
            id="deleteScheduleNotificationModalText"
            defaultMessage="Are you sure you would like to delete this schedule?"
            description="Delete schedule modal text"
          />
        </p>
        <ActionContainer>
          <Button background="blue" onClick={onDelete}>
            <FormattedMessage
              id="confirmButton"
              defaultMessage="Confirm"
              description="Confirm button text"
            />
          </Button>
        </ActionContainer>
      </Fragment>
    );
  }, [deleteResult, onDelete]);

  return (
    <SlideoutModal isOpen={isOpen} onClose={onClose}>
      <div>
        <Header>
          <Heading tag={Tag.H3}>
            <FormattedMessage
              id="deleteScheduleNotificationModalTitle"
              defaultMessage="Delete schedule?"
              description="Delete schedule modal title"
            />
          </Heading>
          <IconButton onClick={onClose} padding>
            <Icon type={IconType.Close} />
          </IconButton>
        </Header>
        <Body>{content}</Body>
      </div>
    </SlideoutModal>
  );
};

export default DeleteScheduleModal;
