import {
  getEndpointURL,
  WellrEndpointDefinition,
  WellrEndpointPath,
} from 'utils/api';

/** Mark measure step */
const markMeasureStepPath: WellrEndpointPath =
  '/api/measure/me/step/{measureSlug}/{measureStepId}';
const markMeasureStepMethod = 'post';
type MarkMeasureStep = WellrEndpointDefinition<
  typeof markMeasureStepPath,
  typeof markMeasureStepMethod
>;

export type MarkMeasureStepType = {
  result: MarkMeasureStep['responseBody'];
  queryArgs: MarkMeasureStep['request']['params']['path'] &
    Required<MarkMeasureStep['request']['params']['query']>;
};

export const endpoints = {
  markMeasureStep: {
    path: (measureSlug: string, measureStepId: string) =>
      getEndpointURL(markMeasureStepPath, { measureSlug, measureStepId }),
    method: markMeasureStepMethod.toUpperCase(),
  },
};
