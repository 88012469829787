import { FC } from 'react';
import { ThemeColor } from 'styles';

import { Container, Bar } from './styles';

type Props = {
  progress: Array<{ progress: number; color?: ThemeColor | string }>;
};

const MultiProgressBar: FC<Props> = ({ progress }) => {
  return (
    <Container>
      {progress.map((item, index) => (
        <Bar
          key={index}
          initial={{ width: 0 }}
          animate={{ width: `${item.progress}%` }}
          transition={{ duration: 1 }}
          color={item.color ?? 'blue'}
        />
      ))}
    </Container>
  );
};

export default MultiProgressBar;
