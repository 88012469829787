import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import logoSrc from 'assets/vectors/logo-white.svg';
import { ROOT } from 'router/routes';

import Icon, { IconType } from 'components/UI/Icon';
import IconButton from 'components/UI/IconButton';

import { Logo, LogoLink } from './styles';

type Props = {
  subLevel?: boolean;
};

const CoverHeader: FC<Props> = ({ subLevel = false }) => {
  const navigate = useNavigate();

  if (subLevel) {
    return (
      <IconButton onClick={() => navigate(-1)}>
        <Icon type={IconType.Back} size={32} color="white" />
      </IconButton>
    );
  }

  return (
    <LogoLink to={ROOT}>
      <Logo src={logoSrc} alt="wellr-logo" />
    </LogoLink>
  );
};

export default CoverHeader;
