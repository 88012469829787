import styled from 'styled-components';
import { breakpoints } from 'styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.information};
  max-height: 256px;
  max-width: 680px;
  margin: 0 auto 48px;

  @media (max-width: ${breakpoints.l}px) {
    max-width: none;
    margin: 0 auto 48px;
    min-height: 512px;
    border-radius: 0;
  }

  @media (max-width: ${breakpoints.m}px) {
    margin-bottom: -24px;
  }
`;

export const Overlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
`;

export const ImageView = styled.img`
  width: 100%;
  min-height: 256px;
  object-fit: cover;
  user-drag: none;

  @media (max-width: ${breakpoints.l}px) {
    min-height: 512px;
  }
`;

export const CardContent = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 24px;
  padding: 16px;

  @media (max-width: ${breakpoints.l}px) {
    padding: 16px 16px 24px;
  }

  @media (max-width: ${breakpoints.m}px) {
    flex-direction: column;
    align-items: center;
    padding: 16px 16px 48px;
  }
`;

export const CardAction = styled.div`
  display: flex;

  @media (max-width: ${breakpoints.m}px) {

  Button {
    margin-bottom: 8px;
    border-radius: 16px;
      width: 100%;
    }
  }
`;

export const CardText = styled.div`
  flex: 1;
  color: ${({ theme }) => theme.colors.white};

  h2 {
    margin-bottom: 8px;
    }

    @media (max-width: ${breakpoints.m}px) {
      text-align: center;
  justify-content: center;
  }
`;

export const Text = styled.p`
  margin: 0;
`;
