import { motion } from 'framer-motion';
import styled from 'styled-components';
import { breakpoints } from 'styles';

export const QuizHeader = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 32px;

  @media (max-width: ${breakpoints.m}px) {
    flex-direction: column;
    gap: 16px;
  }
`;

export const QuizBody = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  height: calc(100vh - 72px);

  @media (max-width: ${breakpoints.m}px) {
    height: calc(100vh - 130px);
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const Content = styled.div`
  max-width: 440px;
  width: 100%;
  overflow-y: auto;
  z-index: 1;
  padding: 48px 24px;

  @media (max-width: ${breakpoints.m}px) {
    padding: 24px 0;
    margin: 0 auto;
  }
`;

export const QuestionContainer = styled(motion.div)`
  width: 100%;
`;

export const HeaderLeftCol = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 8px;

  @media (max-width: ${breakpoints.m}px) {
    justify-content: space-between;
  }
`;

export const HeaderCenterCol = styled.div`
  max-width: 800px;
  flex: 2;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const HeaderRightCol = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const HealthCategory = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
`;

type ProgressLineProps = {
  isActive: boolean;
};

export const ProgressLine = styled.div<ProgressLineProps>`
  background: ${({ theme }) => theme.colors.white};
  width: 100%;
  height: 2px;
  border-radius: 1px;
  opacity: ${({ isActive }) => (isActive ? 1 : 0.3)};
`;

export const NavButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  @media (min-width: ${breakpoints.m + 1}px) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 10%;
    margin-bottom: 0;
  }
`;

type NavIcon = {
  left?: boolean;
};

export const NavButton = styled.button<NavIcon>`
  display: flex;
  align-items: center;
  rotate: ${({ left }) => (left ? '180deg' : 'none')};
  border: 1px solid ${({ theme }) => theme.colors.white};
  border-radius: 16px;
  padding: 12px;
  outline: none;
  background: transparent;
  cursor: pointer;

  &:disabled {
    opacity: 40%;
    cursor: not-allowed;
  }
`;

export const NavText = styled.span`
  color: ${({ theme }) => theme.colors.white};
  padding: 0 4px;
`;

export const QuestionHeader = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
`;

export const QuestionText = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-size: 18px;
  text-align: center;
  margin-bottom: 40px;
`;

export const AlternativeGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 8px;
`;
