import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import { AuthState, Token } from 'models';
import * as storage from 'utils/storage';
import { baseApi } from '../baseApi';
import { AppDispatch } from 'store/store';

const initialAuthState: AuthState = {
  accessToken: null,
  refreshToken: null,
  isAuthenticated: false,
  initialized: false,
};

// Export slice
const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {
    initialize: (state) => {
      state.initialized = true;
    },
    login: (state, action: PayloadAction<Token>) => {
      state.isAuthenticated = true;
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;

      storage.setItem('session', action.payload);
    },
    refreshToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
      storage.setItem('session', {
        accessToken: action.payload,
        refreshToken: state.refreshToken,
      });
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.accessToken = null;

      storage.removeItem('session');
    },
  },
});

// Export actions
export const { login, logout, refreshToken, initialize } = authSlice.actions;

// Export reducer
export const authReducer = authSlice.reducer;

// Thunks
export function checkSession() {
  return async (dispatch: AppDispatch) => {
    const session = storage.getItem<Token>('session');

    if (session) {
      dispatch(login(session));
    }
    dispatch(initialize());
  };
}

export function logoutSession() {
  return (dispatch: Dispatch) => {
    dispatch(logout());
    dispatch(baseApi.util.resetApiState());
  };
}
