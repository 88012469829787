import styled from 'styled-components';

export const FieldGrid = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonContainer = styled.div`
  display: flex;
  button {
    width: 100%;
  }
`;
