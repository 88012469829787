import React, { FC, Fragment, useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { useDeleteUserMutation } from 'store';
import { ActionContainer } from 'styles';

import Loader from 'components/UI/Loader';
import Button from 'components/UI/Button';
import Icon, { IconType } from 'components/UI/Icon';
import IconButton from 'components/UI/IconButton';
import Heading, { Tag } from 'components/UI/Heading';
import SlideoutModal from 'components/UI/SlideoutModal';

import { Header, Body } from './styles';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const DeleteUserModal: FC<Props> = ({ isOpen, onClose }) => {
  // Hooks
  const [deleteUser, { isSuccess, isLoading }] = useDeleteUserMutation();

  // Success
  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess, onClose]);

  // Actions
  const onDelete = useCallback(async () => {
    await deleteUser();
  }, [deleteUser]);

  // Content
  const content = useMemo(() => {
    if (isLoading) {
      return <Loader padding color="blue" />;
    }
    return (
      <Fragment>
        <p>
          <FormattedMessage
            id="deleteUserModalDescription"
            defaultMessage="Are you sure you want to delete your user account? (This action is irreversable and can not be reverted afterwards)"
            description="Delete user modal description"
          />
        </p>
        <ActionContainer>
          <Button background="error" onClick={onDelete}>
            <FormattedMessage
              id="deleteButton"
              defaultMessage="Delete"
              description="Delete button"
            />
          </Button>
        </ActionContainer>
      </Fragment>
    );
  }, [isLoading, onDelete]);

  return (
    <SlideoutModal isOpen={isOpen} onClose={onClose}>
      <div>
        <Header>
          <Heading tag={Tag.H3}>
            <FormattedMessage
              id="deleteUserModalTitle"
              defaultMessage="Delete your account"
              description="Delete user modal title"
            />
          </Heading>
          <IconButton onClick={onClose} padding>
            <Icon type={IconType.Close} />
          </IconButton>
        </Header>
        <Body>{content}</Body>
      </div>
    </SlideoutModal>
  );
};

export default DeleteUserModal;
