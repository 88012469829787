import { components } from 'generated/api';
import { ContentText } from 'models';
import { useCallback } from 'react';
import { localizationSelector } from 'store';
import { useAppSelector } from './redux';

export const useLocalizedText = (): ((
  text: ContentText | null | undefined | components['schemas']['Languages']
) => string) => {
  const { language } = useAppSelector(localizationSelector);

  // Handle outside click
  const getText = useCallback(
    (
      text: ContentText | null | undefined | components['schemas']['Languages']
    ) => {
      if (!language || !text) {
        return '-';
      }
      const { languageCode } = language;
      return text[languageCode] || '-';
    },
    [language]
  );

  return getText;
};
