import styled from 'styled-components';

export const CategoryHeader = styled.div`
  h1 {
    margin: 0;
  }

  p {
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const ButtonGrid = styled.div`
  display: flex;
  justify-content: center;
`;
