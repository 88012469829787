import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { localizationSelector, setLanguageByCode } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

export const useLanguageFromQuery = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { language, languages } = useAppSelector(localizationSelector);
  const [languageLoaded, setLanguageLoaded] = useState(false);

  useEffect(() => {
    if (languages.length === 0) {
      return; // languages not loaded yet
    }
    const queryLanguage = searchParams.get('language');
    if (queryLanguage == null) {
      setLanguageLoaded(true);
      return;
    }
    if (queryLanguage !== language?.languageCode) {
      dispatch(setLanguageByCode(queryLanguage));
    }
    setLanguageLoaded(true);
  }, [searchParams, languages, language, dispatch]);

  return { languageLoaded };
};
