import { FC, useCallback, useMemo } from 'react';


import { Container, Text, Step } from './styles';
import { FormattedMessage } from 'react-intl';
import Icon, { IconType } from '../Icon';

type Props = {
  count?: number;
  text?: string;
  inverted?: boolean;
  right?: boolean;
  icon?: boolean;
};

const WellrSteps: FC<Props> = ({
  count,
  text,
  icon = true,
  right = false,
  inverted = false,
}) => {
  // Memoize points
  const steps = useMemo(() => {
    if (count) {
      return (
        <>
        +{count}{' '}
          <FormattedMessage
            id="activitiesUnitText"
            defaultMessage="Steps"
            description="Unit text for activities"
          />
        </>
      );
    }
    if (text) {
      return text;
    }
    return null;
  }, [count, text]);

  const renderCoin = useCallback((shouldRender: boolean) => {
    if (!shouldRender) {
      return null;
    }
    return (
      <Step>
        <Icon type={IconType.Footprints} color="grayText" />
      </Step>
    );
  }, []);

  return (
    <Container>
      {icon && renderCoin(right)}
      <Text inverted={inverted}>{steps}</Text>
      {icon && renderCoin(!right)}
    </Container>
  );
};

export default WellrSteps;
