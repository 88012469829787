import React, { FC, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import * as routes from 'router/routes';

import Heading, { Tag } from 'components/UI/Heading';
import BackgroundCover from 'components/UI/BackgroundCover';
import CoverHeader from 'components/UI/CoverHeader';

import {
  CoverContent,
  Description,
  breakpoints,
  ActionContainer,
} from 'styles';
import Button from 'components/UI/Button';
import { useWindowSize } from 'hooks/useWindowSize';
import QRCode from 'react-qr-code';
import { getAppStoreLink } from 'utils/link';
import { useLanguageFromQuery } from 'hooks/useLanguageFromQuery';
import { authSelector } from 'store';
import { useAppSelector } from 'hooks/redux';

const ConfirmRegisterInvite: FC = () => {
  // Hooks
  const { width } = useWindowSize();
  const isMobile = useMemo(() => width <= breakpoints.l, [width]);
  const { isAuthenticated } = useAppSelector(authSelector);
  const navigate = useNavigate();
  useLanguageFromQuery();

  const onContinueInWeb = () => {
    if (isAuthenticated) {
      navigate(routes.ROOT);
    } else {
      navigate(routes.LOGIN);
    }
  };

  const appRedirectUrl = `${window.location.origin}${routes.APP_REDIRECT}`;

  return (
    <BackgroundCover padding>
      <CoverHeader subLevel />
      <CoverContent>
        <Heading>
          <FormattedMessage
            id="pageRegisterCompletedHeading"
            defaultMessage="Welcome to Wellr!"
            description="Confirm register invite success message"
          />
        </Heading>
        <Description>
          <FormattedMessage
            id="pageRegisterCompletedText"
            defaultMessage="Your registration was successful.{NewLine} Now you can download and log in to the app or continue in the web browser."
            description="Confirm register invite page description"
            values={{ NewLine: <br /> }}
          />
        </Description>
        {isMobile ? (
          <ActionContainer>
            <Button
              color="black"
              background="yellow"
              onClick={() => {
                window.location.href = getAppStoreLink();
              }}
            >
              <FormattedMessage
                id="toAppButton"
                defaultMessage="To the app"
                description="To app button text"
              />
            </Button>
            <Button color="black" background="yellow" onClick={onContinueInWeb}>
              <FormattedMessage
                id="continueInWebButton"
                defaultMessage="Continue in the web browser"
                description="Continue in web browser button text"
              />
            </Button>
          </ActionContainer>
        ) : (
          <>
            <Button color="black" background="yellow" onClick={onContinueInWeb}>
              <FormattedMessage
                id="continueInWebButton"
                defaultMessage="Continue in the web browser"
                description="Continue in web browser button text"
              />
            </Button>
            <Heading tag={Tag.H4}>
              <FormattedMessage
                id="pageRegisterCompletedQRCodeHeading"
                defaultMessage="Scan the QR code to download the app"
                description="Confirm register invite QR code heading"
              />
            </Heading>
            <QRCode
              size={256}
              style={{ height: 'auto', maxWidth: '80%', width: '80%' }}
              value={appRedirectUrl}
              viewBox={`0 0 256 256`}
            />
          </>
        )}
      </CoverContent>
    </BackgroundCover>
  );
};

export default ConfirmRegisterInvite;
