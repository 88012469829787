import styled from 'styled-components';

type TeamProps = {
  isLast: boolean;
};

export const TeamItem = styled.div<TeamProps>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  border-bottom: ${({ theme, isLast }) =>
    !isLast ? `1px solid ${theme.colors.borderLight}` : 'none'};

  h4 {
    margin: 0;
  }
`;

export const TeamItemHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
`;

export const TeamItemHeaderCol = styled.div`
  display: flex;
`;

export const TeamItemGrid = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
`;

type MarkerProps = {
  isActive: boolean;
  isCurrent: boolean;
};

export const Marker = styled.div<MarkerProps>`
  background: ${({ theme, isActive }) =>
    theme.colors[isActive ? 'blue' : 'backgroundDark']};
  width: 7px;
  height: 7px;
  border-radius: 4px;
  border: 1px solid
    ${({ theme, isCurrent }) =>
      isCurrent ? theme.colors.black : theme.colors.backgroundDark};
`;

export const ImgContainer = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 99px;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.backgroundDark};
  border: 1px solid ${({ theme }) => theme.colors.backgroundDark};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
