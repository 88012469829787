import styled from 'styled-components';
import { breakpoints } from 'styles';

export const ButtonContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
`;

export const ButtonWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 260px;
  gap: 16px;
  @media (max-width: ${breakpoints.s}px) {
    width: 100%;
  }
`;
