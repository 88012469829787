import React, { FC, useCallback, useEffect } from 'react'; // Added React and useEffect
import { FormattedMessage, useIntl } from 'react-intl';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import { ConfirmResetPasswordValues } from 'models';

import Button from 'components/UI/Button/Button';
import TextField from 'components/UI/TextField/TextField';

import { ActionContainer } from 'styles';

type Props = {
  submitForm: (email: ConfirmResetPasswordValues) => void;
  resetCode: string | null;
};

const ConfirmResetPasswordForm: FC<Props> = ({ submitForm, resetCode }) => {
  const intl = useIntl();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm();

  // Watch
  const currentPassword = watch('password');


  useEffect(() => {
    if (resetCode) {
      // Use setValue to set the value of the reset code input field
      setValue('resetCode', resetCode);
    }
  }, [resetCode, setValue]);
  

  // Submit
  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    (values) => {
      const newPasswordValues: ConfirmResetPasswordValues = {
        resetCode: values.resetCode,
        password: values.password,
        verifyPassword: values.verifyPassword,
      };
      submitForm(newPasswordValues);
    },
    [submitForm]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        label={intl.formatMessage({
          id: 'inputResetCodeLabel',
          defaultMessage: 'Reset code',
          description: 'Label for reset code input',
        })}
        placeholder={intl.formatMessage({
          id: 'inputResetCodePlaceholder',
          defaultMessage: 'Enter reset code',
          description: 'Placeholder for reset code input',
        })}
        error={errors.resetCode}
        register={register('resetCode', {
          required: intl.formatMessage({
            id: 'inputErrorResetCodeRequired',
            defaultMessage: 'Reset code is required',
            description: 'Input error for reset code required',
          }),
        })}
      />
      <TextField
        label={intl.formatMessage({
          id: 'inputPasswordLabel',
          defaultMessage: 'Password',
          description: 'Label for password input',
        })}
        placeholder={intl.formatMessage({
          id: 'inputPasswordPlaceholder',
          defaultMessage: 'Enter password',
          description: 'Placeholder for password input',
        })}
        register={register('password', {
          required: intl.formatMessage({
            id: 'inputErrorPasswordRequired',
            defaultMessage: 'Password is required',
            description: 'Input error for password required',
          }),
        })}
        type="password"
        error={errors.password}
      />
      <TextField
        label={intl.formatMessage({
          id: 'inputVerifyPasswordLabel',
          defaultMessage: 'Verify password',
          description: 'Label for verify password input',
        })}
        placeholder={intl.formatMessage({
          id: 'inputPasswordPlaceholder',
          defaultMessage: 'Enter password',
          description: 'Placeholder for password input',
        })}
        register={register('verifyPassword', {
          required: intl.formatMessage({
            id: 'inputErrorPasswordRequired',
            defaultMessage: 'Password is required',
            description: 'Input error for password required',
          }),
          validate: (value) =>
            value === currentPassword ||
            intl.formatMessage({
              id: 'inputErrorPasswordNoMatch',
              defaultMessage: 'Passwords do not match',
              description: 'Input error for no match passwords',
            }),
        })}
        type="password"
        error={errors.verifyPassword}
      />
      <ActionContainer>
        <Button type="submit" color="black" background="yellow">
          <FormattedMessage
            id="resetPasswordButton"
            defaultMessage="Reset password"
            description="Reset password button text"
          />
        </Button>
      </ActionContainer>
    </form>
  );
};

export default ConfirmResetPasswordForm;
