import React, { FC, ReactNode, useMemo, useRef, useState } from 'react';
import { SelectOption } from 'models';

import {
  Container,
  SelectBase,
  ErrorMessage,
  InputMessage,
  Label,
  HelperText,
  SelectedList,
  SelectedItem,
} from './styles';
import IconButton from '../IconButton';
import Icon, { IconType } from '../Icon';

type Props = {
  name?: string;
  label?: string;
  placeholder?: string;
  options: SelectOption[];
  defaultValue?: string[];
  disabled?: boolean;
  required?: boolean;
  helperText?: string;
  action?: ReactNode;
  error?: any;
  narrow?: boolean;
  onSelectedItemsChange: (selectedItems: string[]) => void;
};

const SelectMultiField: FC<Props> = ({
  label,
  placeholder,
  defaultValue,
  helperText,
  disabled = false,
  required = false,
  options,
  error,
  narrow = false,
  onSelectedItemsChange,
}) => {
  const [selectedItems, setSelectedItems] = useState<string[]>(
    defaultValue || []
  );

  const selectRef = useRef<HTMLSelectElement>(null);

  const handleSelect = (value: string) => {
    if (!selectedItems.includes(value)) {
      const updatedItems = [...selectedItems, value];
      setSelectedItems(updatedItems);
      onSelectedItemsChange(updatedItems); // Invoke the callback
    }
  };

  const handleRemoveItem = (value: string, event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const updatedItems = selectedItems.filter(item => item !== value);
    setSelectedItems(updatedItems);
    onSelectedItemsChange(updatedItems); // Invoke the callback
    if (selectRef.current) {
      selectRef.current.selectedIndex = -1;
    }
  };

  // State
  const [active, setActive] = useState<boolean>(false);

  // Message
  const message = useMemo(() => {
    if (error) {
      return <ErrorMessage>{error.message}</ErrorMessage>;
    }
    if (helperText) {
      return <HelperText>{helperText}</HelperText>;
    }
    return null;
  }, [helperText, error]);

  return (
    <Container>
      {label && <Label>{label}</Label>}
      <SelectBase
        narrow={narrow}
        defaultValue=""
        error={Boolean(error)}
        disabled={disabled}
        onFocus={() => setActive(true)}
        onBlur={() => setActive(false)}
        active={active}
        required={required}
        onChange={(e) => handleSelect(e.target.value)}
        ref={selectRef}
      >
        {!placeholder && (
          <option value="" disabled>{placeholder}</option>
        )}
        {options.map(({ name, value }, i) => (
          <option key={`option-${value}-${i + 1}`} value={value}>
            {name}
          </option>
        ))}
      </SelectBase>
      <br />
      {selectedItems.length > 0 && (
        <SelectedList>
          {selectedItems.map((item, index) => (
            <SelectedItem key={index}>
              <IconButton onClick={(event) => handleRemoveItem(item, event)} padding>
                {options.find((option) => option.value === item)?.name}
                <Icon type={IconType.Close} />
              </IconButton>
            </SelectedItem>
          ))}
        </SelectedList>
      )}
      {message && <InputMessage>{message}</InputMessage>}
    </Container>
  );
};

export default SelectMultiField;
