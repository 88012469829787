import React, { FC, useEffect, Fragment, useMemo, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ContentWidth, Description, FlexContainer } from 'styles';
import {
  useGetCompanyPulseQuizQuery,
  useGetCompanyQuizDatesQuery,
  useLazyGetCompanyHealthQuizQuery,
} from 'store';

import Heading, { Tag } from 'components/UI/Heading';
import Loader from 'components/UI/Loader';

import { IconType } from 'components/UI/Icon';

import EmptyState from 'components/UI/EmptyState';
import { useLocalizedText } from 'hooks/useLocalizedText';
import SelectField from 'components/UI/SelectField/SelectField';
import { useForm } from 'react-hook-form';
import {
  CircleItem,
  CircleWrap,
  ColoredCircle,
  ColorHighlight,
  HealthCardWrapper,
  SmallGap,
} from './styles';
import { useTheme } from 'styled-components';
import { formatDate } from 'utils/date';
import CompanyResultFilter from 'components/forms/CompanyResultFilter';
import { usePretendCompany } from 'hooks/usePretendCompany';
import HealthBarChart from 'components/fragments/HealthBarChart';
import QuizQuestionStatistics from 'components/fragments/QuizQuestionStatistics';
import StaticCard from 'components/UI/Cards/StaticCard';
import AdminDetailedHealthCard from 'components/cards/AdminDetailedHealthCard';
import { useActiveLanguage } from 'hooks/useActiveLanguage';
import { usePageHeader } from 'hooks/usePageHeader';
import useQuizBaselineData from 'hooks/useQuizBaselineData';
import ToggleButton from 'components/UI/ToggleButton';

type QuizPeriodForm = {
  quizPeriod: string | null;
  quizPeriodCompare: string;
};

type Props = {
  id: string;
  quizType: 'health' | 'pulse';
};

const AdminQuizResults: FC<Props> = ({ id, quizType }) => {
  const intl = useIntl();
  const getText = useLocalizedText();

  const [enableBaseline, setEnableBaseline] = React.useState<boolean>(false);

  const [selectedResultIndex, setSelectedResultIndex] = React.useState<
    number | null
  >();
  const [selectedResultCompareIndex, setSelectedResultCompareIndex] =
    React.useState<number | null>();

  const { register, watch, setValue } = useForm<QuizPeriodForm>({
    defaultValues: {
      quizPeriod: null,
      quizPeriodCompare: '',
    },
  });

  const quizPeriod = watch('quizPeriod');
  const quizPeriodCompare = watch('quizPeriodCompare');

  // Hooks
  const language = useActiveLanguage();
  const companyId = usePretendCompany();

  const [getCompanyHealthQuiz, healthQuizResponse] =
    useLazyGetCompanyHealthQuizQuery();
  const [getCompanyHealthQuizCompare, healthQuizCompareResponse] =
    useLazyGetCompanyHealthQuizQuery();

  const pulseQuizResponse = useGetCompanyPulseQuizQuery(
    {
      id,
      language: language.languageCode,
      companyId,
    },
    { skip: quizType !== 'pulse' }
  );

  const quizDatesResponse = useGetCompanyQuizDatesQuery(
    { companyId },
    {
      skip: quizType !== 'health',
    }
  );

  useEffect(() => {
    if (quizPeriod === null && quizDatesResponse.isSuccess) {
      setValue(
        'quizPeriod',
        quizDatesResponse.data.length > 0 ? quizDatesResponse.data[0] : 'latest'
      );
    }
  }, [quizDatesResponse, quizPeriod, setValue]);

  useEffect(() => {
    if (quizType === 'health' && quizPeriod != null) {
      getCompanyHealthQuiz(
        {
          quizSlug: id,
          language: language.languageCode,
          date: quizPeriod === 'latest' ? undefined : quizPeriod,
          companyId,
        },
        true
      );
    }
  }, [getCompanyHealthQuiz, id, language, quizPeriod, quizType, companyId]);

  useEffect(() => {
    if (quizType === 'health' && quizPeriodCompare !== '') {
      getCompanyHealthQuizCompare(
        {
          quizSlug: id,
          language: language?.languageCode,
          date: quizPeriodCompare,
          companyId,
        },
        true
      );
    }
  }, [
    getCompanyHealthQuizCompare,
    id,
    language,
    quizPeriodCompare,
    quizType,
    companyId,
  ]);

  const theme = useTheme();

  const { data, isLoading, isFetching } =
    quizType === 'health' ? healthQuizResponse : pulseQuizResponse;

  const { isFetching: compareIsFetching } = healthQuizCompareResponse;

  const extractHealthQuizResults = useCallback(
    (data: typeof healthQuizResponse.data) =>
      data?.averageResults?.map(
        ({
          organizationEntityType,
          organizationEntityDescription,
          gender,
          role,
          age,
          averageAnswers,
          scorePercentageAverage,
          usersCompleted,
          usersTotal,
          usersCompletedQuizPercentage,
          averageAssessment,
          color,
          resultTitle,
        }) => ({
          organizationEntityType,
          organizationEntityDescription,
          gender,
          role,
          age,
          averageAnswers,
          scorePercentageAverage,
          usersCompleted,
          usersTotal,
          usersCompletedQuizPercentage,
          averageAssessment,
          color,
          resultTitle,
        })
      ) ?? [],
    [healthQuizResponse]
  );

  const extractPulseQuizResults = useCallback(
    (data: typeof pulseQuizResponse.data) =>
      data?.averageResults?.map(
        ({
          organizationEntityType,
          organizationEntityDescription,
          gender,
          role,
          age,
          averageAnswers,
          scorePercentageAverage,
          usersCompleted,
          usersTotal,
          usersCompletedQuizPercentage,
          averageAssessment,
        }) => ({
          organizationEntityType,
          organizationEntityDescription,
          gender,
          role,
          age,
          averageAnswers,
          scorePercentageAverage,
          usersCompleted,
          usersTotal,
          usersCompletedQuizPercentage,
          averageAssessment,
        })
      ) ?? [],
    [pulseQuizResponse]
  );

  const healthQuizResults = useMemo(() => {
    if (quizType !== 'health') {
      return null;
    }
    return extractHealthQuizResults(healthQuizResponse.data);
  }, [extractHealthQuizResults, healthQuizResponse.data, quizType]);

  const healthQuizCompareResults = useMemo(() => {
    if (quizType !== 'health') {
      return null;
    }
    if (quizPeriodCompare === '') {
      return [];
    }
    return extractHealthQuizResults(healthQuizCompareResponse.data);
  }, [
    extractHealthQuizResults,
    healthQuizCompareResponse.data,
    quizType,
    quizPeriodCompare,
  ]);

  const pulseQuizResults = useMemo(() => {
    if (quizType !== 'pulse') {
      return null;
    }
    return extractPulseQuizResults(pulseQuizResponse.data);
  }, [extractPulseQuizResults, pulseQuizResponse.data, quizType]);

  usePageHeader({
    title: intl.formatMessage({
      id: 'backButton',
      defaultMessage: 'Back',
      description: 'Back button text',
    }),
    inverted: false,
    icon: IconType.Back,
  });

  const selectedHealthQuizResult =
    healthQuizResults != null && selectedResultIndex != null
      ? healthQuizResults[selectedResultIndex]
      : null;

  const selectedPulseQuizResult =
    pulseQuizResults != null && selectedResultIndex != null
      ? pulseQuizResults[selectedResultIndex]
      : null;

  const selectedHealthQuizCompareResult =
    selectedResultCompareIndex != null && healthQuizCompareResults != null
      ? healthQuizCompareResults[selectedResultCompareIndex]
      : null;

  const baseline = useQuizBaselineData(
    enableBaseline ? id : undefined,
    selectedHealthQuizResult,
    false
  );

  const barChart = useMemo(() => {
    if (
      !selectedHealthQuizResult ||
      selectedHealthQuizResult.usersCompleted === 0 ||
      !Array.isArray(selectedHealthQuizResult.averageAssessment)
    ) {
      return null;
    }

    const dataset = (selectedHealthQuizResult.averageAssessment ?? []).map(
      (item) => ({
        ...item,
        resultTitle: item.title,
      })
    );
    const datasetCompare = selectedHealthQuizCompareResult
      ? selectedHealthQuizCompareResult.averageAssessment ?? []
      : null;

    return (
      <HealthBarChart
        data={dataset}
        compareData={datasetCompare}
        baselineData={
          enableBaseline && baseline.baselineChartData.length > 0
            ? baseline.baselineChartData
            : undefined
        }
      />
    );
  }, [
    baseline.baselineChartData,
    selectedHealthQuizCompareResult,
    selectedHealthQuizResult,
    enableBaseline,
  ]);

  const questionsData = useMemo(
    () =>
      (
        selectedHealthQuizResult || selectedPulseQuizResult
      )?.averageAnswers?.map(
        (
          {
            _key,
            _type,
            title,
            averagePoints,
            answerAlternativeStatistics,
            maxPoints,
            maxPointsDescription,
            minPoints,
            minPointsDescription,
            unhideByAnswerAlternative,
          },
          answerIndex
        ) => {
          const compareAnswer = selectedHealthQuizCompareResult?.averageAnswers
            ? selectedHealthQuizCompareResult?.averageAnswers[answerIndex]
            : null;
          const baselineAnswer = baseline.baselineResult?.averageAnswers
            ? baseline.baselineResult?.averageAnswers[answerIndex]
            : null;
          return {
            _key,
            _type,
            title: getText(title),
            averagePoints,
            maxPoints,
            maxPointsDescription: getText(maxPointsDescription),
            minPoints,
            minPointsDescription: getText(minPointsDescription),
            unhideByAnswerAlternative,
            compare: {
              averagePoints: compareAnswer?.averagePoints,
              maxPoints: compareAnswer?.maxPoints,
              maxPointsDescription: getText(
                compareAnswer?.maxPointsDescription
              ),
              minPoints: compareAnswer?.minPoints,
              minPointsDescription: getText(
                compareAnswer?.minPointsDescription
              ),
            },
            baselineCompare: {
              averagePoints: baselineAnswer?.averagePoints,
              maxPoints: baselineAnswer?.maxPoints,
              maxPointsDescription: getText(
                baselineAnswer?.maxPointsDescription
              ),
              minPoints: baselineAnswer?.minPoints,
              minPointsDescription: getText(
                baselineAnswer?.minPointsDescription
              ),
            },
            answerAlternativeStatistics:
              answerAlternativeStatistics?.map(
                ({ _key, title, percentageOfAnswers }, index) => {
                  return {
                    _key,
                    title: getText(title),
                    percentageOfAnswers,
                    compareValue: compareAnswer?.answerAlternativeStatistics
                      ? compareAnswer?.answerAlternativeStatistics[index]
                        ?.percentageOfAnswers
                      : null,
                    baselineCompareValue:
                      baselineAnswer?.answerAlternativeStatistics
                        ? baselineAnswer?.answerAlternativeStatistics[index]
                          ?.percentageOfAnswers
                        : null,
                  };
                }
              ) ?? [],
          };
        }
      ) ?? [],
    [
      selectedHealthQuizResult,
      selectedPulseQuizResult,
      selectedHealthQuizCompareResult?.averageAnswers,
      baseline.baselineResult?.averageAnswers,
      getText,
    ]
  );

  if (isLoading || quizDatesResponse.isLoading) {
    return <Loader color="blue" padding />;
  }

  const isFetchingResult = compareIsFetching || isFetching;

  if (!isFetchingResult && !data) {
    return (
      <EmptyState iconType={IconType.Tests} padding>
        <FormattedMessage
          id="pageAdminQuizDetailsNotFound"
          defaultMessage="A quiz with the provided id was not found"
          description="Not found text for admin quiz details page"
        />
      </EmptyState>
    );
  }

  const quizPeriodOptions = (quizDatesResponse.data ?? []).map((item) => ({
    value: item,
    name: formatDate(new Date(item)),
  }));

  const quizDef =
    data && 'quizDefinition' in data
      ? data.quizDefinition
      : data?.pulseQuizDefinition;

  const formattedQuizPeriod = formatDate(new Date(quizPeriod as string));
  const formattedQuizComparePeriod =
    quizPeriodCompare !== '' ? formatDate(new Date(quizPeriodCompare)) : '';

  return (
    <Fragment>
      <ContentWidth isSurface>
        <Heading tag={Tag.H2}>{getText(quizDef?.title)}</Heading>
        <Description>{getText(quizDef?.summary)}</Description>

        {!isFetchingResult &&
          (data?.averageResults == null || data.averageResults.length === 0) ? (
          <EmptyState iconType={IconType.Tests} padding>
            <FormattedMessage
              id="pageAdminQuizNoResults"
              defaultMessage="No results exists for this quiz"
              description="No result text for admin quiz details page"
            />
          </EmptyState>
        ) : (
          <>
            {quizType === 'pulse' && (
              <>
                <Heading tag={Tag.H3}>
                  <FormattedMessage
                    id="pageAdminQuizResultSelectTitle"
                    defaultMessage="Select result"
                    description="Title before the filtering options on the admin quiz results page"
                  />
                </Heading>
                <CompanyResultFilter
                  withAgeFilter={true}
                  results={pulseQuizResults ?? []}
                  resultCallback={setSelectedResultIndex}
                />
              </>
            )}
            {quizType === 'health' && (
              <>
                <Heading tag={Tag.H3}>
                  <FormattedMessage
                    id="pageAdminQuizResultSelectTitle"
                    defaultMessage="Select result"
                    description="Title before the filtering options on the admin quiz results page"
                  />
                </Heading>
                <ColorHighlight color="black">
                  <SelectField
                    label={intl.formatMessage({
                      id: 'selectQuizPeriodLabel',
                      defaultMessage: 'Select quiz period',
                      description: 'Label for selection quiz period',
                    })}
                    options={quizPeriodOptions}
                    register={register('quizPeriod')}
                  />
                  <br />
                  <CompanyResultFilter
                    withAgeFilter={true}
                    results={healthQuizResults ?? []}
                    resultCallback={setSelectedResultIndex}
                  />
                </ColorHighlight>
                <Heading tag={Tag.H3}>
                  <FormattedMessage
                    id="pageAdminQuizResultCompareFilterTitle"
                    defaultMessage="Select result to compare"
                    description="Title before the filtering options on the admin quiz results page"
                  />
                </Heading>
                <ColorHighlight color="purple">
                  <SelectField
                    label={intl.formatMessage({
                      id: 'selectQuizPeriodCompareLabel',
                      defaultMessage: 'Select quiz period',
                      description: 'Label for quiz period select compare date',
                    })}
                    options={quizPeriodOptions}
                    placeholder={intl.formatMessage({
                      id: 'noComparison',
                      defaultMessage: 'No comparison',
                      description: 'No comparison text',
                    })}
                    register={register('quizPeriodCompare')}
                  />
                  <br />
                  {quizPeriodCompare !== '' && (
                    <CompanyResultFilter
                      withAgeFilter={true}
                      results={healthQuizCompareResults ?? []}
                      resultCallback={setSelectedResultCompareIndex}
                    />
                  )}
                </ColorHighlight>
                <br />
              </>
            )}
            <Heading tag={Tag.H2}>
              <FormattedMessage
                id="pageAdminQuizResultsTitle"
                defaultMessage="Results"
                description="Title before the results on the admin quiz results page"
              />
            </Heading>
            {quizType === 'health' && (
              <>
                <ColorHighlight color="blue">
                  <FlexContainer gap={10} alignItems="center">
                    <Heading tag={Tag.H5}>
                      <FormattedMessage
                        id="pageAdminQuizResultsCompareBaselineTitle"
                        defaultMessage="Compare with Wellr's average"
                        description="Title for the baseline toggle on the admin quiz results page"
                      />
                    </Heading>
                    <ToggleButton
                      isActive={enableBaseline}
                      onToggle={setEnableBaseline}
                    />
                  </FlexContainer>
                </ColorHighlight>
                <br />
                <br />
              </>
            )}

            {isFetchingResult ? (
              <Loader color="blue" padding />
            ) : selectedHealthQuizResult != null ||
              selectedPulseQuizResult != null ? (
              <Fragment>
                {selectedHealthQuizResult != null && (
                  <HealthCardWrapper>
                    <div
                      style={{
                        width: selectedHealthQuizCompareResult ? '50%' : '100%',
                        border: `3px solid ${theme.colors.black}`,
                        borderRadius: '16px',
                      }}
                    >
                      <AdminDetailedHealthCard
                        date={formattedQuizPeriod}
                        results={selectedHealthQuizResult}
                        category={quizDef?.healthCategory}
                        display={{
                          title: selectedHealthQuizResult.resultTitle,
                          color: selectedHealthQuizResult.color ?? undefined,
                        }}
                        slug={quizDef?.slug}
                        showChart={false}
                        useFilteredBaseline={false}
                        showBaseline={enableBaseline}
                      />
                    </div>
                    {selectedHealthQuizCompareResult && (
                      <div
                        style={{
                          width: '50%',
                          border: `3px solid ${theme.colors.purple}`,
                          borderRadius: '16px',
                        }}
                      >
                        <AdminDetailedHealthCard
                          date={formattedQuizComparePeriod}
                          results={selectedHealthQuizCompareResult}
                          category={quizDef?.healthCategory}
                          display={{
                            title: selectedHealthQuizCompareResult.resultTitle,
                            color:
                              selectedHealthQuizCompareResult.color ??
                              undefined,
                          }}
                          slug={quizDef?.slug}
                          showChart={false}
                          useFilteredBaseline={false}
                        />
                      </div>
                    )}
                  </HealthCardWrapper>
                )}{' '}
                {selectedPulseQuizResult != null && (
                  <StaticCard padding>
                    <div style={{ marginBottom: '10px' }}>
                      <Heading tag={Tag.H5}>
                        {selectedPulseQuizResult.averageAssessment != null &&
                          getText(
                            selectedPulseQuizResult.averageAssessment
                              .resultTitle
                          )}
                      </Heading>
                      <div style={{ marginTop: '5px', fontSize: '15px' }}>
                        {selectedPulseQuizResult.averageAssessment != null &&
                          getText(
                            selectedPulseQuizResult.averageAssessment.resultText
                          )}
                      </div>
                    </div>
                  </StaticCard>
                )}
                {(quizPeriodCompare !== '' ||
                  (enableBaseline && baseline.baselineChartData.length > 0)) &&
                  questionsData.length > 0 && (
                    <CircleWrap>
                      <CircleItem>
                        <ColoredCircle color={theme.colors.black} />
                        <div>
                          {intl.formatMessage({
                            id: 'pageAdminQuizResultsTitle',
                            defaultMessage: 'Results',
                            description:
                              'Title before the results on the admin quiz results page',
                          }) + ` (${formattedQuizPeriod})`}
                        </div>
                      </CircleItem>
                      {quizPeriodCompare !== '' && (
                        <CircleItem>
                          <ColoredCircle color={theme.colors.purple} />
                          <div>
                            {intl.formatMessage({
                              id: 'pageAdminQuizResultsCompareTitle',
                              defaultMessage: 'Compare result',
                              description:
                                'compare results title on the admin quiz results page',
                            }) + ` (${formattedQuizComparePeriod})`}
                          </div>
                        </CircleItem>
                      )}
                      {enableBaseline &&
                        baseline.baselineChartData.length > 0 && (
                          <CircleItem>
                            <ColoredCircle color={theme.colors.blue} />
                            <div>
                              <FormattedMessage
                                id="pageAdminQuizResultsBaselineTitle"
                                defaultMessage="Wellr's average"
                                description="Title for the baseline on the admin quiz results page"
                              />
                            </div>
                          </CircleItem>
                        )}
                    </CircleWrap>
                  )}
                {barChart}
                <SmallGap />
                <Heading tag={Tag.H2}>
                  <FormattedMessage
                    id="pageAdminQuizResultsQuestionsTitle"
                    defaultMessage="Questions result"
                    description="Title before the questions on the admin quiz results page"
                  />
                </Heading>
                <div>
                  {selectedPulseQuizResult?.usersTotal &&
                    <FormattedMessage
                    id="pageAdminQuizResultsUsersCompleted"
                    defaultMessage="{completed} out of {total} users completed the quiz"
                    description="Text for the number of users that completed the quiz"
                    values={{
                      completed: selectedPulseQuizResult?.usersCompleted ?? 0,
                      total: selectedPulseQuizResult?.usersTotal ?? 0,
                    }}
                  />
                  }
                  <br />
                  <br />
                </div>
                <QuizQuestionStatistics
                  questions={questionsData}
                  showBaseline={enableBaseline}
                />
              </Fragment>
            ) : (
              <EmptyState iconType={IconType.Tests} padding>
                <FormattedMessage
                  id="pageAdminQuizResultsNotFound"
                  defaultMessage="There are no results available for the selected filters"
                  description="no results text for selected filter on admin quiz details page"
                />
              </EmptyState>
            )}
          </>
        )}
      </ContentWidth>
    </Fragment>
  );
};

export default AdminQuizResults;
