import React, { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { localizationSelector, useGetContestQuery } from 'store';

import { useAppSelector } from 'hooks/redux';

import { IconType } from 'components/UI/Icon';
import ChatModal from 'components/modals/ChatModal';
import EmptyState from 'components/UI/EmptyState';
import Loader from 'components/UI/Loader';
import { useGetUnreadChatRoomEntriesQuery } from 'store/chatService/chatService';

const Competition: FC = () => {
  const intl = useIntl();
  const RE_FETCH_TIMEOUT_MS = 1000 * 10
  const [lastGetContestFetch, setLastGetContestFetch] = useState(0);
  const { contestId } = useParams();
  const { language } = useAppSelector(localizationSelector);

  const { data, isLoading, isFetching, refetch, fulfilledTimeStamp } = useGetContestQuery(
    {
      contestId: contestId,
      language: language?.languageCode,
    },
    {
      refetchOnMountOrArgChange: 20,
      refetchOnFocus: lastGetContestFetch + RE_FETCH_TIMEOUT_MS < Date.now()
    }
  );

  // Hooks
  const { data: unreadContestMessages, refetch: refetchUnreadContestMessages } = useGetUnreadChatRoomEntriesQuery(
    { chatRoomId: contestId ?? '' },
    { skip: contestId === null || contestId === undefined }
  );

  const { data: unreadTeamMessages, refetch: refetchUnreadTeamMessages } = useGetUnreadChatRoomEntriesQuery(
    { chatRoomId: data?.me.team?.id ?? '' },
    { skip: data?.me.team?.id === null || data?.me.team?.id === undefined }
  );

  useEffect(() => {
    setLastGetContestFetch(Date.now())
  }, [fulfilledTimeStamp])

  // Chatbot
  const chatBot = useMemo(() => {
    if (!contestId || !data?.me.team) {
      return null;
    }
    const { id, name } = data.me.team;

    const refetchUnreadMessages = () => {
      refetchUnreadContestMessages();
      refetchUnreadTeamMessages();
    }

    return (
      <ChatModal
        unreadMessages={{
          contest: unreadContestMessages ?? 0,
          team: unreadTeamMessages ?? 0,
        }}
        onOpen={refetchUnreadMessages}
        rooms={[
          {
            title: intl.formatMessage({
              id: 'pageCompetitionChatRoomAll',
              defaultMessage: 'All',
              description: 'Competitions page chatroom all teams',
            }),
            chatId: contestId,
          },
          {
            title: name,
            chatId: id,
          },
        ]}
      />
    );
  }, [contestId, data?.me.team, unreadContestMessages, unreadTeamMessages, refetchUnreadContestMessages, refetchUnreadTeamMessages, intl]);

  // Loading
  if (isLoading) {
    return <Loader color="blue" padding />;
  }

  // No data
  if (!data) {
    return (
      <EmptyState iconType={IconType.Competition} padding>
        <FormattedMessage
          id="pageCompetitionEmptyState"
          defaultMessage="Competition not found"
          description="Empty state for competition"
        />
      </EmptyState>
    );
  }

  return (
    <Fragment>
      <Outlet context={{ contest: data, isFetching, refetch }} />
      {chatBot}
    </Fragment>
  );
};

export default Competition;
