import { Activity } from 'models';
import { IntlShape } from 'react-intl';

// Group array of activities by timestamp and remove duplicates
export function groupActivities(activities: Activity[]) {
  const grouped: Activity[] = [];
  activities.forEach((activity) => {
    const found = grouped.find((item) => item.timestamp === activity.timestamp);
    if (!found) {
      grouped.push(activity);
    }
  });
  return grouped;
}

export function getStepPercentage(total: number, left: number): number {
  const value = total - left;
  return (value * 100) / total;
}

export const getUnitTranslation = (
  unitSlug: 'step' | 'minute' | string,
  unitCount: number,
  intl: IntlShape
) => {
  switch (unitSlug) {
    case 'step':
      return intl.formatMessage(
        {
          id: 'numberSteps',
          defaultMessage: '{unitCount, plural, one {# step} other {# steps}}',
          description: 'Number of step(s)',
        },
        { unitCount }
      );
    case 'minute':
      return intl.formatMessage(
        {
          id: 'numberMinutes',
          defaultMessage:
            '{unitCount, plural, one {# minute} other {# minutes}}',
          description: 'Number of minute(s)',
        },
        { unitCount }
      );
    default:
      console.warn('No translation for unit slug found', unitSlug);
      return `${unitCount} ${unitSlug}`;
  }
};
