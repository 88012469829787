import { ContentSlug, ContentText } from "models/content/content";

export type Override<T1, T2> = Omit<T1, keyof T2> & T2;

export type Errors = {
  [name: string]: string[];
};

export interface APIError {
  message: string;
}

export type Image = {
  src: string;
  alt: string;
};

export type SelectOption = {
  name: string;
  value: string | number;
};

export type SelectOptionContent = {
  title: ContentText;
  slug: ContentSlug;
};

export type ErrorMessages = {
  [name: string]: {
    [language: string]: string;
  };
};

export type ErrorMessageResponse = {
  revision: number;
  messages: ErrorMessages;
};

export type WellrPoint = {
  date: string;
  points: number;
};

export enum Interval {
  None = 'Once',
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
};