import React, { FC, ReactNode } from 'react';
import { Autoplay, FreeMode, Navigation, Pagination } from 'swiper';

import { useWindowSize } from 'hooks/useWindowSize';
import { breakpoints } from 'styles';

import { Container, Slide, Slider, SliderNavs } from './styles';
import SliderNav from './SliderNav';

type Props = {
  id: string;
  children: ReactNode[];
  spaceBetween?: number;
  slidesPerView?: number;
  hideNav?: boolean;
  isHero?: boolean;
};

const DragSlider: FC<Props> = ({
  id,
  children,
  spaceBetween = 8,
  slidesPerView = 3,
  hideNav = false,
  isHero = false,
}) => {
  // Hooks
  const { width } = useWindowSize();
  const isLarge = width > breakpoints.m;
  const showNav = !hideNav && children.length > slidesPerView;

  return (
    <Container>
      <Slider
        freeMode={!isHero}
        navigation={{
          prevEl: `.prev-slide-btn-${id}`,
          nextEl: `.next-slide-btn-${id}`,
        }}
        slidesPerView={isLarge || isHero ? slidesPerView : 'auto'}
        spaceBetween={spaceBetween}
        autoplay={isHero ? { delay: 3000, stopOnLastSlide: true } : undefined}
        modules={isHero ? [Autoplay, Pagination] : [FreeMode, Navigation]}
        pagination={{
          enabled: isHero,
          clickable: true,
        }}
      >
        {children.map((item, i) => (
          <Slide key={i}>{item}</Slide>
        ))}
      </Slider>
      {!isHero && (
        <SliderNavs>
          {showNav ? (
            <SliderNav
              hideOnDisabled={isHero}
              hideOnMobile={!isHero}
              id={id}
              position="left"
            />
          ) : null}
          {showNav ? (
            <SliderNav
              hideOnDisabled={isHero}
              hideOnMobile={!isHero}
              id={id}
              position="right"
            />
          ) : null}
        </SliderNavs>
      )}
    </Container>
  );
};

export default DragSlider;
