import React, { FC, useEffect } from 'react';
import {
  Dialog,
  DialogBody,
  DialogButtonContainer,
  DialogButtonDivider,
  Overlay,
} from './styles';
import Button from 'components/UI/Button';
import { FormattedMessage } from 'react-intl';
import Loader from 'components/UI/Loader';

type Props = {
  isOpen: boolean;
  isLoading?: boolean;
  onConfirm: () => void;
  onAbort: () => void;
  children: React.ReactNode;
};

const ConfirmActionPopup: FC<Props> = ({
  isOpen,
  onConfirm,
  onAbort,
  children,
  isLoading = false,
}) => {
  // Freeze body
  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'unset';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);
  return (
    <>
      <Overlay open={isOpen} onClick={onAbort} />
      <Dialog open={isOpen}>
        {isOpen && (
          <>
            <DialogBody>{children}</DialogBody>
            {isLoading ? (
              <div style={{ margin: '10px 0' }}>
                <Loader color="blue" />
              </div>
            ) : (
              <DialogButtonContainer>
                <Button
                  onClick={onConfirm}
                  background="transparent"
                  color="blue"
                  disabled={isLoading}
                >
                  <FormattedMessage
                    id="confirmButton"
                    defaultMessage="Confirm"
                    description="Confirm button text"
                  />
                </Button>
                <DialogButtonDivider />
                <Button
                  onClick={onAbort}
                  background="transparent"
                  color="error"
                  disabled={isLoading}
                >
                  <FormattedMessage
                    id="cancelButton"
                    defaultMessage="Cancel"
                    description="Cancel button text"
                  />
                </Button>
              </DialogButtonContainer>
            )}
          </>
        )}
      </Dialog>
    </>
  );
};

export default ConfirmActionPopup;
