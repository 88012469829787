import { FC, useEffect, useMemo } from 'react';
import Loader from 'components/UI/Loader';
import IconButton from 'components/UI/IconButton';
import SlideoutModal from 'components/UI/SlideoutModal';
import Heading, { Tag } from 'components/UI/Heading';
import Icon, { IconType } from 'components/UI/Icon';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-hot-toast';
import { Header, Body } from './styles';
import SendQuizReminderForm from 'components/forms/SendQuizReminderForm';
import { useSendHealthQuizReminderMutation } from 'store';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  companyId: string;
};

const SendQuizReminderModal: FC<Props> = ({
  isOpen,
  onClose,
  companyId
}) => {

  // Hooks
  const intl = useIntl();
  const [sendQuizReminder, sendQuizReminderResult] = useSendHealthQuizReminderMutation();

  useEffect(() => {
    if (sendQuizReminderResult.isSuccess) {
      toast.success(
        intl.formatMessage({
          id: 'sendHealthQuizReminderSuccess',
          defaultMessage: 'Reminders have been sent.',
          description: '"health quiz reminder success text',
        })
      );
      onClose();
    }
  }, [sendQuizReminderResult, intl, onClose]);

  const content = useMemo(() => {
    if (sendQuizReminderResult.isLoading) {
      return <Loader padding color="blue" />;
    }

    return (
      <SendQuizReminderForm
        submitForm={sendQuizReminder}
        isOpen={isOpen}
        companyId={companyId}
      />
    );
  }, [companyId, isOpen, sendQuizReminder, sendQuizReminderResult.isLoading]);

  return (
    <SlideoutModal isOpen={isOpen} onClose={onClose}>
      <div>
        <Header>
          <Heading tag={Tag.H3}>
            <FormattedMessage
              id="adminHealthQuizReminderSectionTitle"
              defaultMessage="Health quiz reminder"
              description="Section title for health quiz reminder on admin health report page"
            />
          </Heading>
          <IconButton onClick={onClose} padding>
            <Icon type={IconType.Close} />
          </IconButton>
        </Header>
        <Body>
          {content}
        </Body>
      </div>
    </SlideoutModal>
  );
};

export default SendQuizReminderModal;
