import React, { FC, Fragment, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { localizationSelector } from 'store';
import { useAppSelector } from 'hooks/redux';
import { parseDate } from 'utils/date';
import IconButton from 'components/UI/IconButton/IconButton';
import Icon from 'components/UI/Icon/Icon';
import Heading, { Tag } from 'components/UI/Heading';
import { IconType } from 'components/UI/Icon';
import { List, Item, ItemText, Column } from './styles';
import { NotificationSchedule, NotificationType, RepeatEnum } from 'models/notificationSchedule/notificationSchedule';
import { getIntervalTranslation } from 'hooks/useInterval';
import DeleteScheduleModal from 'components/modals/DeleteScheduleModal';

type Props = {
  data: NotificationSchedule[];
  showWalkSteps?: boolean;
  refetch?: () => void;
};

const NotificatinoScheduleList: FC<Props> = ({ data, refetch }) => {
  const intl = useIntl();
  const { language } = useAppSelector(localizationSelector);

  // State
  const [deleteModal, setDeleteModal] = useState<{ companyId: string, notificationDataId: string } | null>(null);

  // Actions
  const onOpenDeleteModal = useCallback(
    (companyId: string, notificationDataId: string) => () => setDeleteModal({ companyId, notificationDataId }),
    []
  );
  const onCloseDeleteModal = useCallback(() => { setDeleteModal(null); refetch && refetch(); }, []);

  // Valid actitivies (avoid showing non available activites likes walking steps)
  const schedules = useMemo(() => {
    return data;
  }, [data]);

  // No data
  if (!schedules.length) {
    return null;
  }

  return (
    <Fragment>
      <List>
        {schedules.map((schedule, i) => {
          return (
            <Item
              key={`schedule-item-${i}`}
              isLast={schedules.length === i + 1}
            >
              <Column>
                <Heading tag={Tag.H4}>
                  {schedule.ctaLabel[language?.languageCode || 'en']}
                </Heading>
                <ItemText>
                  {intl.formatMessage({
                    id: 'NotificationSendingOptionsLabel',
                    defaultMessage: 'Interval',
                    description: 'Unit text for activities',
                  })}
                  {': '}
                  {getIntervalTranslation(schedule?.repeatInterval, intl)}{' '}
                  {schedule.type.toString() === NotificationType[1] ?
                    intl.formatMessage({
                      id: 'NotificationToAllEmployeesDescription',
                      defaultMessage: 'to all employees',
                      description: 'Unit text for activities',
                    }) : schedule.type.toString() === 'Department' ?
                      intl.formatMessage({
                        id: 'NotificationToDepartmentDescription',
                        defaultMessage: 'to department employees',
                        description: 'Unit text for department activities',
                      }) : schedule.type.toString() === 'Office' ?
                        intl.formatMessage({
                          id: 'NotificationToOfficeDescription',
                          defaultMessage: 'to office employees',
                          description: 'Unit text for office activities',
                        }) : schedule.type.toString() === 'HeadOffice' ?
                          intl.formatMessage({
                            id: 'NotificationToHeadOfficeDescription',
                            defaultMessage: 'to head office employees',
                            description: 'Unit text for head office activities',
                          }) : ''}
                  {' '}
                  {schedule.organizationDescription && `(${schedule.organizationDescription})`}
                  <br />
                  {schedule.repeatInterval.toString() === RepeatEnum[0] ? (
                    <div>
                      {intl.formatMessage({
                        id: 'plannedDispatchText',
                        defaultMessage: 'Planned dispatch',
                        description: 'Planned dispatch date for health card'
                      })}
                      {': '}
                      {parseDate(schedule.start)}
                    </div>
                  ) : (
                    <>
                      {
                        intl.formatMessage({
                          id: 'inputStartDateLabel',
                          defaultMessage: 'Start Date:',
                          description: 'Unit text for activities',
                        })
                      }
                      {': '}
                      {parseDate(schedule.start)}
                      {' -> '}
                      {intl.formatMessage({
                        id: 'inputEndDateLabel',
                        defaultMessage: 'Start Date:',
                        description: 'Unit text for activities',
                      })}
                      {': '}
                      {parseDate(schedule.stop)}
                    </>
                  )}
                </ItemText>
              </Column>
              {schedule.companyId && (
                <IconButton onClick={onOpenDeleteModal(schedule.companyId, schedule.notificationDataId)} padding>
                  <Icon type={IconType.Remove} />
                </IconButton>
              )}
            </Item>
          );
        })}
      </List>
      <DeleteScheduleModal
        id={deleteModal?.companyId}
        notificationDataId={deleteModal?.notificationDataId}
        isOpen={deleteModal !== null}
        onClose={onCloseDeleteModal}
      />
    </Fragment>
  );
};

export default NotificatinoScheduleList;
