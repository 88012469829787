import { FC, ReactNode, useMemo, useState } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { SelectOption } from 'models';

import {
  Container,
  SelectBase,
  ErrorMessage,
  InputMessage,
  Label,
  Header,
  HelperText,
} from './styles';

type Props = {
  name?: string;
  label?: string;
  placeholder?: string;
  options: SelectOption[];
  register?: UseFormRegisterReturn;
  defaultValue?: string;
  disabled?: boolean;
  required?: boolean;
  helperText?: string;
  action?: ReactNode;
  error?: any;
  narrow?: boolean;
};

const SelectField: FC<Props> = ({
  label,
  placeholder,
  register,
  defaultValue,
  helperText,
  disabled = false,
  required = false,
  action,
  options,
  error,
  narrow = false,
}) => {
  // State
  const [active, setActive] = useState<boolean>(false);

  // Message
  const message = useMemo(() => {
    if (error) {
      return <ErrorMessage>{error.message}</ErrorMessage>;
    }
    if (helperText) {
      return <HelperText>{helperText}</HelperText>;
    }
    return null;
  }, [helperText, error]);

  return (
    <Container>
      {label && (
        <Header>
          <Label>
            {label}
            {required && '*'}
          </Label>
          {action}
        </Header>
      )}
      <SelectBase
        narrow={narrow}
        {...register}
        defaultValue={defaultValue || ''}
        error={Boolean(error)}
        disabled={disabled}
        onFocus={() => setActive(true)}
        onBlur={() => setActive(false)}
        active={active}
      >
        {placeholder && (
          <option value="" disabled={required}>
            {placeholder}
          </option>
        )}
        {options.map(({ name, value }, i) => (
          <option key={`option-${value}-${i + 1}`} value={value}>
            {name}
          </option>
        ))}
      </SelectBase>
      {message && <InputMessage>{message}</InputMessage>}
    </Container>
  );
};

export default SelectField;
