import Heading, { Tag } from 'components/UI/Heading';
import Loader from 'components/UI/Loader';
import Table from 'components/UI/Table/Table';
import { useActiveLanguage } from 'hooks/useActiveLanguage';
import { useLocalizedText } from 'hooks/useLocalizedText';
import { usePageHeader } from 'hooks/usePageHeader';
import { usePretendCompany } from 'hooks/usePretendCompany';
import { FC, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useGetCompanyPulseQuizListQuery, useSendPulseQuizReminderMutation } from 'store';
import { ContentWidth, MediumGap } from 'styles';
import { formatReadableDateTimeInclYear } from 'utils/date';
import { CardList } from './styles';
import * as routes from 'router/routes';
import ListMenu from 'components/UI/ListMenu';
import { IconType } from 'components/UI/Icon';
import Button from 'components/UI/Button';
import toast from 'react-hot-toast';
import { useUserCompanyId } from 'hooks/useUserCompanyId';

const AdminPulseTests: FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const language = useActiveLanguage();
  const companyId = usePretendCompany();
  const idForCompanyAdmins = useUserCompanyId();
  const getText = useLocalizedText();
  usePageHeader({
    title: intl.formatMessage({
      id: 'menuAdminPulseTests',
      defaultMessage: 'Pulse tests',
      description: 'Menu item for admin pulse tests',
    }),
  });

  const [sendPulseQuizReminder, { isLoading: sendReminderLoading }] =
    useSendPulseQuizReminderMutation();

  const handleSendPulseQuizReminder = async () => {
    await sendPulseQuizReminder({ companyId: idForCompanyAdmins as string }).unwrap();
    toast.success(
      intl.formatMessage({
        id: 'sendHealthQuizReminderSuccess',
        defaultMessage: 'Reminder emails have been sent.',
        description: 'health quiz reminder success text',
      })
    );
  };

  const pulseQuizes = useGetCompanyPulseQuizListQuery({
    language: language.languageCode,
    companyId,
  });

  const pulseQuizResults = useMemo(() => {
    // Loading
    if (pulseQuizes.isLoading) {
      return <Loader color="blue" padding />;
    }

    // No data
    if (!pulseQuizes.data) {
      return null;
    }

    const tableData = pulseQuizes.data.map((quiz) => ({
      started: quiz.started,
      ended: quiz.ended,
      id: quiz.id,
      description: getText(quiz.description),
      title: getText(quiz.pulseQuizDefinition.title),
      startTime: formatReadableDateTimeInclYear(new Date(quiz.startTime)),
      endTime: formatReadableDateTimeInclYear(new Date(quiz.endTime)),
    }));
    const upcomingQuizes = tableData.filter((quiz) => !quiz.started);
    const ongoingQuizes = tableData.filter(
      (quiz) => quiz.started && !quiz.ended
    );
    const completedQuizes = tableData
      .filter((quiz) => quiz.ended)
      .sort((a, b) => new Date(b.endTime).getTime() - new Date(a.endTime).getTime());

    const columns = [
      {
        key: 'description' as const,
        title: intl.formatMessage({
          id: 'adminTestsPulseQuizDescriptionColumnHeader',
          defaultMessage: 'Description',
          description:
            'Label for the "description" column in the pulse quiz table',
        }),
      },
      {
        key: 'title' as const,
        title: intl.formatMessage({
          id: 'adminTestsPulseQuizTitleColumnHeader',
          defaultMessage: 'Title',
          description: 'Label for the "title" column in the pulse quiz table',
        }),
      },
      {
        key: 'startTime' as const,
        title: intl.formatMessage({
          id: 'adminTestsPulseQuizStartTimeColumnHeader',
          defaultMessage: 'Start time',
          description:
            'Label for the "Start time" column in the pulse quiz table',
        }),
      },
      {
        key: 'endTime' as const,
        title: intl.formatMessage({
          id: 'adminTestsPulseQuizEndTimeColumnHeader',
          defaultMessage: 'End time',
          description:
            'Label for the "End time" column in the pulse quiz table',
        }),
      },
    ];

    const onRowClick = (row: { id: string }) => {
      navigate(`${routes.ADMIN_PULSE_TESTS}/${row.id}`);
    };

    return (
      <>
        <Heading tag={Tag.H4}>
          <FormattedMessage
            id="adminUpcomingPulseTestsTitle"
            defaultMessage="Upcoming pulse tests"
            description="Upcoming pulse tests title in admin pulse tests page"
          />
        </Heading>
        {upcomingQuizes.length > 0 ? (
          <Table
            columns={columns}
            onRowClick={onRowClick}
            data={upcomingQuizes}
          />
        ) : (
          <FormattedMessage
            id="adminNoUpcomingPulseTests"
            defaultMessage="No upcoming pulse tests"
            description="No upcoming pulse tests text in admin pulse tests page"
          />
        )}
        <Heading tag={Tag.H4}>
          <FormattedMessage
            id="adminOngoingPulseTestsTitle"
            defaultMessage="Ongoing pulse tests"
            description="Ongoing pulse tests title in admin pulse tests page"
          />
        </Heading>
        {ongoingQuizes.length > 0 ? (
          <Table
            columns={columns}
            onRowClick={onRowClick}
            data={ongoingQuizes}
          />
        ) : (
          <FormattedMessage
            id="adminNoOngoingPulseTests"
            defaultMessage="No ongoing pulse tests"
            description="No ongoing pulse tests text in admin pulse tests page"
          />
        )}
        <Heading tag={Tag.H4}>
          <FormattedMessage
            id="adminCompletedPulseTestsTitle"
            defaultMessage="Completed pulse tests"
            description="Completed pulse tests title in admin pulse tests page"
          />
        </Heading>
        {completedQuizes.length > 0 ? (
          <Table
            columns={columns}
            onRowClick={onRowClick}
            data={completedQuizes}
          />
        ) : (
          <FormattedMessage
            id="adminNoCompletedPulseTests"
            defaultMessage="No completed pulse tests"
            description="No completed pulse tests text in admin pulse tests page"
          />
        )}
      </>
    );
  }, [pulseQuizes, getText, navigate, intl]);

  return (
    <ContentWidth isSurface>
      <ListMenu
        menu={[
          {
            id: 1,
            text: intl.formatMessage({
              id: 'pageAdminAddPulsetestButton',
              defaultMessage: 'Start new pulse test',
              description: 'Button label to add a new pulse quiz',
            }),
            icon: IconType.Send,
            link: routes.ADMIN_PULSE_TESTS + '/create',
          },
        ]}
      />
      <MediumGap />
      <Heading tag={Tag.H4}>
        <FormattedMessage
          id="adminPulseQuizReminderSectionTitle"
          defaultMessage="Pulse quiz reminder"
          description="Section title for pulse quiz reminder on admin pulse report page"
        />
      </Heading>
      <p>
        <FormattedMessage
          id="adminPulseQuizeReminderDescription"
          defaultMessage="Send reminder email to users that have pulse quizzes yet to be completed."
          description="Description for pulse quiz reminder on admin pulse report page"
        />
      </p>
      {sendReminderLoading ? (
        <Loader color="blue" />
      ) : (
        <Button
          color="white"
          background="blue"
          onClick={handleSendPulseQuizReminder}
        >
          <FormattedMessage
            id="sendPulseQuizReminderButtonLabel"
            defaultMessage="Send reminder"
            description="Send pulse quiz reminder button label"
          />
        </Button>
      )}
      <MediumGap />
      <CardList>{pulseQuizResults}</CardList>
    </ContentWidth>
  );
};

export default AdminPulseTests;
