import styled from 'styled-components';
import { breakpoints } from 'styles';

export const Grid = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (max-width: ${breakpoints.s}px) {
    flex-direction: column;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin-bottom: 12px;
`;

export const HeaderCol = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const DisabledTitle = styled.span`
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
`;

export const ProgressGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
`;

export const ProgressItem = styled.div``;

export const TestDate = styled.p`
  color: ${({ theme }) => theme.colors.grayText};
  font-size: 14px;
  margin: 12px 0 0;
`;

export const Column = styled.div`
  flex: 1;
  margin: 16px;

  h3 {
    margin: 0;
  }

  p {
    color: ${({ theme }) => theme.colors.grayText};
    margin: 8px 0 0;
  }
`;
