import React, { FC, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';

import Button from 'components/UI/Button/Button';

import { ActionContainer } from 'styles';
import SelectField from 'components/UI/SelectField';
import { localizationSelector, useGetCompanyStructureQuery } from 'store';
import { SelectOption } from 'models';
import { FieldGrid, InfoText } from './styles';
import Loader from 'components/UI/Loader';
import { usePretendCompany } from 'hooks/usePretendCompany';
import TextAreaField from 'components/UI/TextAreaField';
import { useAppSelector } from 'hooks/redux';
import RadioButton from 'components/UI/RadioButton';
import { SelectItem } from '../ChallengeForm/styles';
import FlagIcon from 'components/UI/FlagIcon';

export type SubmitCallback = (data: AddUsersFormData) => void;

type Props = {
  submitForm: SubmitCallback;
};

export type AddUsersFormData = {
  department: string;
  language: string | null;
  emails: string;
};

const AddUsersForm: FC<Props> = ({ submitForm }) => {
  const intl = useIntl();
  const companyId = usePretendCompany();
  const { languages, language } = useAppSelector(localizationSelector);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isDirty },
  } = useForm<AddUsersFormData>({
    defaultValues: {
      department: '',
      language: language?.languageCode,
      emails: '',
    },
  });

  const selectedDepartment = watch('department');
  const selectedLanguage = watch('language');

  const { data, isLoading } = useGetCompanyStructureQuery({ companyId });

  const departmentOptions: SelectOption[] = useMemo(() => {
    let departments =
      data?.departments?.map(({ id, description }) => ({
        value: id,
        name: description ?? '',
      })) ?? [];

    data?.headOffices?.forEach((headOffice) => {
      headOffice.offices?.forEach((office) => {
        office.departments?.forEach(({ id, description }) => {
          departments.push({
            value: id,
            name: `${description} (${headOffice.description} / ${office.description})`,
          });
        });
      });
    });

    data?.offices?.forEach((office) => {
      office.departments?.forEach(({ id, description }) => {
        departments.push({
          value: id,
          name: `${description} (${office.description})`,
        });
      });
    });

    //sort departments alphabetically
    departments.sort((a, b) => a.name.localeCompare(b.name));

    return departments;
  }, [data?.departments, data?.headOffices, data?.offices]);

  const languageSelect = useMemo(() => {
    return (
      <>
        <label>
          <FormattedMessage
            id="addUserLanguageSelect"
            defaultMessage="Språk"
            description="Label for language select on admin add user page"
          />
        </label>
        {languages.map((lang) => (
          <SelectItem
            key={lang.languageCode}
            onClick={() => setValue('language', lang.languageCode)}
          >
            <RadioButton
              name="language"
              isChecked={selectedLanguage === lang.languageCode}
            />
            <FlagIcon countryCode={lang.countryCode} />
            <span>{lang.name}</span>
          </SelectItem>
        ))}
      </>
    );
  }, [languages, selectedLanguage, setValue]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <FieldGrid>
        <SelectField
          label={intl.formatMessage({
            id: 'inputDepartmentSelectLabel',
            defaultMessage: 'Department',
            description: 'Label for department select on admin add user page',
          })}
          register={register('department')}
          placeholder={intl.formatMessage({
            id: 'inputDepartmentPlaceholder',
            defaultMessage: 'No department',
            description: 'Placeholder for department input',
          })}
          defaultValue={selectedDepartment}
          options={departmentOptions}
          error={errors.department}
        />
        {languageSelect}
        <TextAreaField
          helperText={intl.formatMessage({
            id: 'inputUserEmailsHelperText',
            defaultMessage:
              'Ange en e-postadress per rad om du vill lägga till flera användare.',
            description: 'Helper text for user emails input',
          })}
          label={intl.formatMessage({
            id: 'inputUserEmailsLabel',
            defaultMessage: 'Email',
            description: 'Label for user emails input',
          })}
          error={errors.emails}
          register={register('emails', {
            required: intl.formatMessage({
              id: 'inputErrorUserEmailsRequired',
              defaultMessage: 'A user emails is required',
              description: 'Input error for user emails required',
            }),
          })}
          onChange={(e) => setValue('emails', e.target.value)}
        />
      </FieldGrid>
      <ActionContainer>
        <Button type="submit" background="blue" disabled={!isDirty}>
          <FormattedMessage
            id="addButton"
            defaultMessage="Add"
            description="Add button text"
          />
        </Button>
      </ActionContainer>
      <InfoText>
        <FormattedMessage
          id="addUserFormInfoText"
          defaultMessage="An activation email will be sent to each email address where the user will choose a password and provide more information about themselves."
          description="Info text below add user form informing about activation email"
        />
      </InfoText>
    </form>
  );
};

export default AddUsersForm;
