import styled from 'styled-components';

export const Card = styled.div<{ inverted: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${({ theme, inverted }) =>
    inverted ? theme.colors.white : theme.colors.backgroundLight};
  font-size: 16px;
  border-radius: 16px;
  cursor: pointer;
  overflow: hidden;
  min-height: 64px;
`;
