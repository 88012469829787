import React, { FC, Fragment, useCallback, useMemo } from 'react';

import { getImage } from 'utils/asset';
import { useAppSelector } from 'hooks/redux';
import { useGetAssetImage } from 'hooks/useGetAssetImage';
import { useLocalizedText } from 'hooks/useLocalizedText';
import { localizationSelector, useGetCompanyCommunicationQuery } from 'store';

import DragSlider from 'components/UI/DragSlider';
import ImageCard from 'components/UI/Cards/ImageCard';
import { Gap } from 'styles';

import { Logo } from './styles';
import { linkIsInternal } from 'utils/link';
import { useNavigate } from 'react-router-dom';

type Props = {
  adminContentOnly?: boolean;
};

const CompanyCommunication: FC<Props> = ({ adminContentOnly = false }) => {
  const getText = useLocalizedText();
  const { language } = useAppSelector(localizationSelector);
  const navigate = useNavigate();

  // Hooks
  const { data } = useGetCompanyCommunicationQuery({
    language: language?.languageCode,
  });

  const [companyLogoSrc] = useGetAssetImage(data?.company?.logoAssetId);
  const [umbrellaLogoSrc] = useGetAssetImage(
    data?.umbrellaCompany?.logoAssetId
  );

  // Handle click
  const onClick = useCallback(
    (link?: string) => () => {
      if (!link) {
        console.error('No link available', { link });
        return;
      }
      if (linkIsInternal(link)) {
        const url = new URL(link);
        navigate(url.pathname + url.search);
      } else {
        window.open(link);
      }
    },
    [navigate]
  );

  // Company
  const companyContent = useMemo(() => {
    if (
      adminContentOnly ||
      !data?.company ||
      !data.company.companyCommunicationContent.length
    ) {
      return null;
    }
    return (
      <Fragment>
        {companyLogoSrc && (
          <Logo src={companyLogoSrc} alt={data.company.logoAssetId} />
        )}
        <DragSlider id="company" slidesPerView={3}>
          {data.company.companyCommunicationContent.map((item, i) => (
            <ImageCard
              key={`communication-${i + 1}`}
              title={getText(item.title)}
              image={getImage(item.image)}
              onClick={onClick(getText(item.buttonLink))}
              size="large"
            />
          ))}
        </DragSlider>
        <Gap />
      </Fragment>
    );
  }, [onClick, getText, companyLogoSrc, data, adminContentOnly]);

  // Umbrella
  const umbrellaContent = useMemo(() => {
    if (!data?.umbrellaCompany) {
      return null;
    }
    const contentData = data.umbrellaCompany.companyCommunicationContent;
    const content = adminContentOnly
      ? contentData.filter(({ displayToCompanyAdmin }) => displayToCompanyAdmin)
      : contentData.filter(({ displayToUser }) => displayToUser);
    if (!content.length) {
      return null;
    }
    return (
      <Fragment>
        {umbrellaLogoSrc && (
          <Logo src={umbrellaLogoSrc} alt={data.umbrellaCompany.logoAssetId} />
        )}
        <DragSlider id="company" slidesPerView={3}>
          {content.map((item, i) => (
            <ImageCard
              key={`communication-${i + 1}`}
              title={getText(item.title)}
              image={getImage(item.image)}
              onClick={onClick(getText(item.buttonLink))}
              size="large"
            />
          ))}
        </DragSlider>
        <Gap />
      </Fragment>
    );
  }, [onClick, getText, umbrellaLogoSrc, data, adminContentOnly]);

  // No data
  if (!data) {
    return null;
  }

  return (
    <Fragment>
      {companyContent}
      {umbrellaContent}
    </Fragment>
  );
};

export default CompanyCommunication;
