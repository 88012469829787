import React, { FC, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { ContestGoal, ContestTeam } from 'models';

import StaticCard from 'components/UI/Cards/StaticCard';
import Button from 'components/UI/Button';

import CompetitionTeams from './CompetitionTeams';

import { Actions, Grid, MapGoal } from './styles';
import ContestMap from '../ContestMap';

type Props = {
  goals: ContestGoal[];
  teams: ContestTeam[];
  team: ContestTeam | null;
  totalAmount: number;
  onClick?: () => void;
};

const CompetitionStatus: FC<Props> = ({
  goals,
  teams,
  team,
  onClick,
  totalAmount,
}) => {
  const mapTeams = useMemo(() => {
    const myTeamInTop = team && teams.some((item) => item.id === team.id);
    return team && !myTeamInTop ? [...teams, team] : teams;
  }, [teams, team]);

  return (
    <StaticCard>
      <Grid>
        <ContestMap
          useRankColors={true}
          goals={goals}
          participants={mapTeams}
          myParticipantId={team?.id}
        />
        <MapGoal>
          <FormattedMessage
            id="contestMapGoal"
            defaultMessage="Step goal: {totalAmount}"
            description="Contest status text for map step goal"
            values={{ totalAmount: totalAmount }}
          />
        </MapGoal>
        <CompetitionTeams goals={goals} teams={teams} team={team} />
        {onClick && (
          <Actions>
            <Button color="blue" background="transparent" onClick={onClick}>
              <FormattedMessage
                id="showAllTeamsButton"
                defaultMessage="Show all teams"
                description="Competition status all teams button"
              />
            </Button>
          </Actions>
        )}
      </Grid>
    </StaticCard>
  );
};

export default CompetitionStatus;
