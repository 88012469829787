import { IconType } from 'components/UI/Icon';
import { NotificationableMenuItem } from 'models';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import * as routes from 'router/routes';
import useNotifications from './useNotifications';

const useMenuItems = (): Record<string, NotificationableMenuItem> => {
  const intl = useIntl();
  const {
    challenge,
    competition,
    activeMeasure,
    healthQuiz,
    pulseQuiz,
    proposedMeasure,
  } = useNotifications();
  return useMemo(
    () => ({
      overview: {
        id: 'overview',
        text: intl.formatMessage({
          id: 'menuOverview',
          defaultMessage: 'Overview',
          description: 'Menu item for overview',
        }),
        link: routes.OVERVIEW,
        icon: IconType.Home,
        hasNotification: false,
      },
      myHealth: {
        id: 'myHealth',
        text: intl.formatMessage({
          id: 'menuMyHealth',
          defaultMessage: 'My health',
          description: 'Menu item for my health',
        }),
        link: routes.MY_HEALTH,
        icon: IconType.Health,
        hasNotification:
          activeMeasure || healthQuiz || pulseQuiz || proposedMeasure,
      },
      excercies: {
        id: 'excercies',
        text: intl.formatMessage({
          id: 'menuExercise',
          defaultMessage: 'Exercise',
          description: 'Menu item for exercise',
        }),
        link: routes.EXERCISE,
        icon: IconType.Exercise,
        hasNotification: false,
      },
      compete: {
        id: 'compete',
        text: intl.formatMessage({
          id: 'menuCompete',
          defaultMessage: 'Compete',
          description: 'Menu item for compete',
        }),
        link: routes.COMPETE,
        icon: IconType.Competition,
        hasNotification: competition || challenge,
      },
      diet: {
        id: 'diet',
        text: intl.formatMessage({
          id: 'menuDiet',
          defaultMessage: 'Diet',
          description: 'Menu item for diet',
        }),
        link: routes.DIET,
        icon: IconType.Food,
        hasNotification: false,
      },
      inspiration: {
        id: 'inspiration',
        text: intl.formatMessage({
          id: 'menuInspiration',
          defaultMessage: 'Inspiration',
          description: 'Menu item for inspiration',
        }),
        link: routes.INSPIRATION,
        icon: IconType.Inspiration,
        hasNotification: false,
      },
      favorites: {
        id: 'favorites',
        text: intl.formatMessage({
          id: 'menuFavourites',
          defaultMessage: 'Favourites',
          description: 'Menu item for favourites',
        }),
        link: routes.FAVORITES,
        icon: IconType.FavoritesOutline,
        hasNotification: false,
      },
      search: {
        id: 'search',
        text: intl.formatMessage({
          id: 'menuSearch',
          defaultMessage: 'Search',
          description: 'Menu item for search',
        }),
        link: routes.SEARCH,
        icon: IconType.Search,
        hasNotification: false,
      },
      profile: {
        id: 'profile',
        text: intl.formatMessage({
          id: 'menuProfile',
          defaultMessage: 'Profile',
          description: 'Menu item for profile',
        }),
        link: routes.PROFILE,
        icon: IconType.User,
        hasNotification: false,
      },
    }),
    [
      activeMeasure,
      challenge,
      competition,
      healthQuiz,
      intl,
      proposedMeasure,
      pulseQuiz,
    ]
  );
};

export const useHeaderMenu = () => {
  const menuItems = useMenuItems();

  return useMemo(
    () => [
      menuItems.overview,
      menuItems.myHealth,
      menuItems.excercies,
      menuItems.compete,
      menuItems.diet,
      menuItems.inspiration,
      menuItems.favorites,
      menuItems.search,
      menuItems.profile,
    ],
    [menuItems]
  );
};

export const useBottomMenu = () => {
  const menuItems = useMenuItems();

  return useMemo(
    () => [
      menuItems.overview,
      menuItems.myHealth,
      menuItems.compete,
      menuItems.search,
    ],
    [menuItems]
  );
};
