import { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { layoutSelector, setActiveExpandable } from 'store';
import Heading, { Tag } from 'components/UI/Heading';
import Icon, { IconType } from 'components/UI/Icon';
import IconButton from 'components/UI/IconButton';
import StaticCard from 'components/UI/Cards/StaticCard';
import {
  Grid,
  Column,
  ArrowContainer,
  ExpandableArea,
  CardDescription,
  ExpandableCardTitle,
  EditContainer,
} from './styles';
import CardCheckbox from 'components/UI/CardCheckbox';

type Props = {
  id: string;
  title: string | ReactNode;
  children: ReactNode;
  defaultIsOpen?: boolean;
  description?: string;
  hasChildren?: boolean;
  onEdit?: (id: string) => void;
  onCheck?: (id: string, isChecked: boolean) => void;
  hideCheckbox?: boolean;
  isChecked?: boolean;
  allowMulti?: boolean;
  narrow?: boolean;
};

const ExpandableCard: FC<Props> = ({
  id,
  title,
  children,
  defaultIsOpen = false,
  description,
  hasChildren,
  onEdit,
  onCheck,
  isChecked,
  allowMulti = false,
  narrow = false,
  hideCheckbox = false,
}) => {
  const dispatch = useAppDispatch();
  const { activeExpandable } = useAppSelector(layoutSelector);
  const [isOpenMulti, setIsOpenMulti] = useState<boolean>(defaultIsOpen);

  // Is open
  const isOpen = useMemo(() => {
    if (allowMulti) {
      return isOpenMulti;
    }
    return (
      activeExpandable === id ||
      (hasChildren && activeExpandable?.startsWith(id))
    );
  }, [allowMulti, activeExpandable, id, hasChildren, isOpenMulti]);

  // Onclick
  const onClick = useCallback(() => {
    if (allowMulti) {
      setIsOpenMulti((prev) => !prev);
      return;
    }
    let newId: string;
    if (activeExpandable !== id) {
      if (hasChildren && activeExpandable?.startsWith(id)) {
        newId = '';
      } else {
        newId = id;
      }
    } else if (id.includes('.')) {
      // id containing dot indicates that it has a parent that we want to reset the active expandable to
      newId = id.split('.').slice(0, -1).join('.');
    } else {
      newId = '';
    }
    dispatch(setActiveExpandable(newId));
  }, [allowMulti, activeExpandable, id, dispatch, hasChildren]);

  return (
    <StaticCard narrow={narrow}>
      <Grid>
        {!hideCheckbox && onCheck && (
          <Grid onClick={() => onCheck(id, isChecked === true)}>
            <Column narrow={narrow}>
              <div style={{ paddingLeft: '5px' }}>
                <CardCheckbox
                  name={`checkbox_${id}`}
                  isChecked={isChecked === true}
                />
              </div>
            </Column>
          </Grid>
        )}
        <Grid justify="space-between" grow onClick={onClick}>
          <Column narrow={narrow}>
            <Heading tag={description ? Tag.H5 : Tag.H4}>
              {onEdit ? (
                <ExpandableCardTitle>
                  <span>{title}</span>
                  <EditContainer
                    onClick={(e) => {
                      e.stopPropagation();
                      onEdit(id);
                    }}
                  >
                    <Icon type={IconType.Edit} />
                  </EditContainer>
                </ExpandableCardTitle>
              ) : (
                title
              )}
            </Heading>
            {description != null && (
              <CardDescription>{description}</CardDescription>
            )}
          </Column>
          <Column narrow={narrow}>
            <IconButton>
              <ArrowContainer
                $defaultIsOpen={defaultIsOpen}
                animate={isOpen ? 'open' : 'closed'}
                transition={{ duration: 0.1 }}
                variants={{
                  open: {
                    transform: 'rotate(270deg)',
                  },
                  closed: {
                    transform: 'rotate(90deg)',
                  },
                }}
              >
                <Icon type={IconType.Arrow} />
              </ArrowContainer>
            </IconButton>
          </Column>
        </Grid>
      </Grid>
      <ExpandableArea
        $defaultIsOpen={defaultIsOpen}
        animate={isOpen ? 'open' : 'closed'}
        transition={{ duration: 0.1 }}
        variants={{
          open: {
            display: 'block',
            height: 'auto',
            visibility: 'visible',
            opacity: 1,
          },
          closed: {
            display: 'none',
            height: 0,
            visibility: 'hidden',
            opacity: 0,
          },
        }}
      >
        {children}
      </ExpandableArea>
    </StaticCard>
  );
};

export default ExpandableCard;