import { Membership } from "models";

const processHeadOffice = (headOffice: any, excludeAnonymousAndNull: boolean, memberships: any[]) => {
  headOffice.offices?.forEach((office: any) => {
    office.departments?.forEach((department: any) => {
      const filteredMemberships = filterMemberships(department.memberships, excludeAnonymousAndNull);
      memberships.push(...filteredMemberships);
    });
  });
};

export const getHeadOfficeMemberships = (
  headOffices: any | any[],
  excludeAnonymousAndNull: boolean = true
): any[] => {
  const memberships: any[] = [];

  if (Array.isArray(headOffices)) {
    headOffices.forEach((headOffice: any) => {
      processHeadOffice(headOffice, excludeAnonymousAndNull, memberships);
    });
  } else {
    processHeadOffice(headOffices, excludeAnonymousAndNull, memberships);
  }

  return memberships;
};

export const getOfficeMemberships = (
  offices: any | any[],
  excludeAnonymousAndNull: boolean = true
): Membership[] => {
  const memberships: any[] = [];

  const processOffice = (office: any) => {
    office.departments?.forEach((department: { memberships: any[]; }) => {
      const filteredMemberships = filterMemberships(department.memberships, excludeAnonymousAndNull);
      memberships.push(...filteredMemberships);
    });
  };

  if (Array.isArray(offices)) {
    offices.forEach((office) => {
      processOffice(office);
    });
  } else {
    processOffice(offices);
  }

  return memberships;
};

const filterMemberships = (memberships: Membership[], excludeAnonymous: boolean): Membership[] => {
  if (!Array.isArray(memberships)) {
    return [];
  }
  if (!excludeAnonymous) {
    return memberships?.filter(member => member.firstName?.toLowerCase() !== 'anonymous') ?? [];
  }
  return memberships.filter(membership =>
    (membership.firstName && membership.firstName.toLowerCase() !== 'anonymous') &&
    (membership.lastName && membership.lastName.toLowerCase() !== 'anonymous')
  );
};

export const flattenData = (data: any) => {
  const rows: {
    description: string;
    headOfficeDescription?: string;
    officeDescription?: string;
    departmentDescription?: string;
    email: string;
    isRegistered: boolean;
  }[] = [];

  const addMemberships = (memberships: Membership[], parentInfo: { description: string; headOfficeDescription?: string; officeDescription?: string; departmentDescription?: string }) => {
    memberships.forEach((membership) => {
      rows.push({
        ...parentInfo,
        email: membership.email,
        isRegistered: membership.firstName && membership.lastName && membership.firstName.toLowerCase() !== 'anonymous' ? true : false,
      });
    });
  };

  const processDepartments = (departments: any[], parentInfo: { description: string; headOfficeDescription?: string; officeDescription?: string }) => {
    departments.forEach((department) => {
      const departmentInfo = {
        ...parentInfo,
        departmentDescription: department.description,
      };
      if (department.memberships) {
        addMemberships(department.memberships, departmentInfo);
      }
    });
  };

  const processOffices = (offices: any[], parentInfo: { description: string; headOfficeDescription?: string }) => {
    offices.forEach((office) => {
      const officeInfo = {
        ...parentInfo,
        officeDescription: office.description,
      };
      if (office.departments) {
        processDepartments(office.departments, officeInfo);
      }
    });
  };

  const processHeadOffices = (headOffices: any[], parentInfo: { description: string }) => {
    headOffices.forEach((headOffice) => {
      const headOfficeInfo = {
        ...parentInfo,
        headOfficeDescription: headOffice.description,
      };
      if (headOffice.offices) {
        processOffices(headOffice.offices, headOfficeInfo);
      }
    });
  };

  const parentInfo = {
    description: data.description,
  };

  if (data.headOffices) {
    processHeadOffices(data.headOffices, parentInfo);
  }

  if (data.offices) {
    processOffices(data.offices, parentInfo);
  }

  if (data.departments) {
    processDepartments(data.departments, parentInfo);
  }

  if (data.memberships) {
    addMemberships(data.memberships, parentInfo);
  }

  return rows;
};