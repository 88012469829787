import React, { FC, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { isBefore, isAfter } from 'utils/date';
import { sortArrayByProperty } from 'utils/array';
import { useGetWeeklyActivitiesQuery } from 'store';

import StaticCard from 'components/UI/Cards/StaticCard';
import Loader from 'components/UI/Loader';
import ActivityList from '../ActivityList/ActivityList';
import Heading, { Tag } from 'components/UI/Heading';
import { Gap } from 'styles';

type Props = {
  startDate?: string;
  endDate?: string;
  allowedActivities?: string[];
  allowAllActivities?: boolean;
};

const LastActivities: FC<Props> = ({
  startDate,
  endDate,
  allowedActivities,
  allowAllActivities = true,
}) => {
  const { data, isLoading } = useGetWeeklyActivitiesQuery();

  // Activities
  const activities = useMemo(() => {
    if (!data) {
      return [];
    }
    const startDateDate = new Date(startDate ?? '');
    return data.filter((item) => {
      if (endDate && isAfter(endDate)) {
        return false;
      }
      if (startDate && isBefore(startDate)) {
        return false;
      }
      if (!allowAllActivities && allowedActivities) {
        return allowedActivities.includes(item.value.activitySlug);
      }
      const activityDate = new Date(item.timestamp);
      return activityDate >= startDateDate;
    });
  }, [data, startDate, endDate, allowedActivities, allowAllActivities]);

  // Loading
  if (isLoading) {
    return <Loader color="blue" padding />;
  }

  if (activities.length === 0) {
    return null;
  }

  return (
    <>
      <Heading tag={Tag.H4}>
        <FormattedMessage
          id="pageCompetitionLatestActivityTitle"
          defaultMessage="My latest registered activities"
          description="Title for my latest activity section on competition page"
        />
      </Heading>
      <StaticCard>
        <Heading tag={Tag.H6} style={{ padding: "16px", paddingBottom: "0px" }}>
          <FormattedMessage
            id="pageCompetitionLatestActivitySubTitle"
            defaultMessage="You can only count activities from the time you accepted the competition."
            description="SubTitle for my latest activity section on competition page"
          />
        </Heading>
        <ActivityList
          data={sortArrayByProperty(activities, 'timestamp', 'desc').slice(
            0,
            5
          )}
        />
      </StaticCard>
      <Gap />
    </>
  );
};

export default LastActivities;
