import React, { FC, useEffect, Fragment, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ContentWidth } from 'styles';
import { useAppDispatch } from 'hooks/redux';
import {
  setLayoutHeader,
  useGetAdminOverviewQuery,
  useGetBaselineHealthScoreQuery,
  useGetCompanyReportQuery,
} from 'store';

import HighlightCard from 'components/UI/Cards/HighlightCard';
import Heading, { Tag } from 'components/UI/Heading';
import Loader from 'components/UI/Loader';

import { CardList, HeadingContainer, HeroCol, HeroGrid, StatsCardList } from './styles';
import StatisticsCard from 'components/UI/Cards/StatisticsCard/StatisticsCard';
import ListMenu from 'components/UI/ListMenu';
import * as routes from 'router/routes';
import CompetitionCard from 'components/cards/CompetitionCard';
import { getImage } from 'utils/asset';
import { IconType } from 'components/UI/Icon';
import { useLocalizedText } from 'hooks/useLocalizedText';

import userBadge from 'assets/vectors/user-badge.svg';
import coinBadge from 'assets/vectors/wellr-badge.svg';
import starBadge from 'assets/vectors/star-badge.svg';
import HeroSmall from 'components/UI/Heros/HeroSmall';
import { usePretendCompany } from 'hooks/usePretendCompany';
import CompanyCommunication from 'components/fragments/CompanyCommunication';
import Badge from 'components/UI/Badge';
import { BadgeContainer } from 'pages/MyHealth/Tests/styles';
import HeadingWithInfo from 'components/UI/HeadingWithInfo';
import ContentModal from 'components/modals/ContentModal';
import CompanyContentMonthly from 'components/fragments/CompanyContentMonthly';
import { useUserCompanyId } from 'hooks/useUserCompanyId';
import { SmallGap } from 'components/forms/CompanyResultFilter/styles';

const AdminOverview: FC = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  // Hooks
  const companyId = usePretendCompany();
  const userCompanyId = useUserCompanyId();

  const getText = useLocalizedText();
  const { data, isFetching } = useGetAdminOverviewQuery({
    companyId,
  });
  const { data: reportData, isFetching: reportIsFetching } =
    useGetCompanyReportQuery({ companyId });
  const { data: wellrBaselineHealthScore } = useGetBaselineHealthScoreQuery(undefined);

  // Set header
  useEffect(() => {
    dispatch(
      setLayoutHeader({
        title: intl.formatMessage({
          id: 'pageAdminOverviewTitle',
          defaultMessage: 'Overview',
          description: 'Page title for admin overview page',
        }),
        inverted: true,
        companyInHeader: true,
      })
    );
  }, [dispatch, intl]);

  const renderDiff = useCallback(() => {
    if (data?.healthIndex === null
      || data?.healthIndex === undefined
      || wellrBaselineHealthScore === undefined
      || wellrBaselineHealthScore === null) {
      return null;
    }

    const healthIndex: number = data.healthIndex;
    const wellrBaseLine: number = wellrBaselineHealthScore;
    const healthIndexImprovement = healthIndex - wellrBaseLine;
    const isImprovementPositive: boolean = healthIndexImprovement >= 0;

    if (healthIndexImprovement === 0) {
      return null;
    }

    return (
      <BadgeContainer>
        <Badge
          color="black"
          background={isImprovementPositive ? 'success' : 'error'}
        >{`${healthIndexImprovement.toFixed(1)}%`}</Badge>
      </BadgeContainer>
    );
  }, [data?.healthIndex, wellrBaselineHealthScore]);

  // State
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  // On close
  const onClose = useCallback(() => setModalOpen(false), []);
  const onOpen = useCallback(() => setModalOpen(true), []);

  // Loading
  if (isFetching || reportIsFetching) {
    return <Loader color="blue" padding />;
  }

  // No data
  if (!data || !reportData) {
    return null;
  }

  const stepsLastSevenDays = data.stepsLastSevenDays ?? 0;
  const totalSteps = data.totalSteps ?? 0;

  const { totalMemberships, averageHealthScore, totalPoints } =
    reportData.organizationEntityStatistics;

  return (
    <Fragment>
      <HeroSmall background="purple" illustrationColor="purpleDark">
        <HeroGrid>
          <HeroCol>
            <img src={userBadge} alt="user badge" />
            <Heading tag={Tag.H2}>{totalMemberships}</Heading>
            <p>
              <FormattedMessage
                id="pageAdminReportUsers"
                defaultMessage="Users"
                description="Admin report page users count"
              />
            </p>
          </HeroCol>
          <HeroCol>
            <img src={coinBadge} alt="points badge" />
            <Heading tag={Tag.H2}>{totalPoints}</Heading>
            <p>
              <FormattedMessage
                id="pageAdminReportPoints"
                defaultMessage="Wellr points"
                description="Admin report page points count"
              />
            </p>
          </HeroCol>
          <HeroCol>
            <img src={starBadge} alt="points badge" />
            <HeadingContainer style={{ position: 'relative' }}>
              <Heading tag={Tag.H2}>{averageHealthScore} %</Heading>
              {renderDiff()}
            </HeadingContainer>
            <HeadingWithInfo
              tag={Tag.H6}
              onClickInfo={onOpen}
              center
              color="white"
            >
              <FormattedMessage
                id="pageAdminReportHealthIndex"
                defaultMessage="Health index"
                description="Admin report page health index"
              />
            </HeadingWithInfo>
          </HeroCol>
        </HeroGrid>
      </HeroSmall>
      <ContentWidth isSurface>
        <CardList>
          <HighlightCard
            title={intl.formatMessage({
              id: 'pageAdminOverviewPinkCardTitle',
              defaultMessage: 'Steps/employee last week',
              description: 'Card title for pink card on admin overview page',
            })}
            background="pink"
            icon={IconType.Footprints}
            iconColor="pinkDark"
            description={intl.formatMessage(
              {
                id: 'pageAdminOverviewPinkCardDescription',
                defaultMessage: 'Total: {totalSteps}',
                description:
                  'Card description for pink card on admin overview page',
              },
              { totalSteps }
            )}
          >
            {stepsLastSevenDays}
          </HighlightCard>
        </CardList>
        <StatsCardList>
          <StatisticsCard
            title={data.completedContests ?? 0}
            icon={IconType.CheckeredFlag}
            description={intl.formatMessage({
              id: 'pageAdminOverviewCompletedCompetitionsTitle',
              defaultMessage: 'Completed competitions',
              description:
                'completed competitions card title on admin overview page',
            })}
          />
          <StatisticsCard
            title={data.completedChallenges ?? 0}
            icon={IconType.Flag}
            description={intl.formatMessage({
              id: 'pageAdminOverviewCompletedChallengesTitle',
              defaultMessage: 'Completed challenges',
              description:
                'completed challenges card title on admin overview page',
            })}
          />
          <StatisticsCard
            title={data.totalHealthplans ?? 0}
            icon={IconType.Tests}
            description={intl.formatMessage({
              id: 'pageAdminOverviewAmountHealthPlansTitle',
              defaultMessage: 'Amount Health plans',
              description: 'Amount health plans card title on admin overview page',
            })}
          />
          <StatisticsCard
            title={reportData.organizationEntityStatistics.totalHealthQuizes ?? 0}
            icon={IconType.Health}
            description={intl.formatMessage({
              id: 'pageAdminOverviewAmountHealthQuizTitle',
              defaultMessage: 'Amount Health quizzes',
              description: 'Amount health quizzes card title on admin overview page',
            })}
          />
        </StatsCardList>
        <ListMenu
          menu={[
            {
              id: 1,
              text: intl.formatMessage({
                id: 'menuHealthReport',
                defaultMessage: 'Health report',
                description: 'Menu item for health report',
              }),
              link: routes.ADMIN_HEALTH_REPORT,
            },
          ]}
        />
        <SmallGap />
        <CompanyCommunication adminContentOnly />
        <Heading tag={Tag.H4}>
          <FormattedMessage
            id="pageCompanyOverviewHealthFocusCalender"
            defaultMessage="Hälsofokus"
            description="Page section title for content log"
          />
        </Heading>
        <CompanyContentMonthly companyId={companyId ?? userCompanyId ?? ''} />
        <SmallGap />
        {data.activeContests?.length !== undefined && data.activeContests.length > 0 &&
          <Heading tag={Tag.H4}>
            <FormattedMessage
              id="pageCompeteActiveCompetitionsTitle"
              defaultMessage="Active competitions"
              description="Section title for active competitions"
            />
          </Heading>
        }
        <CardList>
          {data.activeContests?.map((item, i) => (
            <CompetitionCard
              key={`${item.id}-${i}`}
              title={getText(item.title)}
              image={getImage(item.image)}
              wellrPoints={item.wellrPoints}
              link={`/admin/competitions/${item.id}`}
              accepted={item.accepted}
              type={item.type}
            />
          ))}
        </CardList>

        <ContentModal
          isOpen={modalOpen}
          onClose={onClose}
          title={<FormattedMessage
            id="pageMyHealthHealthIndexTitle"
            defaultMessage="Health index"
            description="health index title"
          />}
          children={
            <FormattedMessage
              id="pageHealthIndexInfoText"
              defaultMessage="Health index info text"
              description="Company overview health index info text"
            />} />
      </ContentWidth>
    </Fragment>
  );
};

export default AdminOverview;
