import { FC } from 'react';
import { FlexContainer } from 'styles';
import ButtonWithIcon from '../ButtonWithIcon';
import Heading, { Tag } from '../Heading/Heading';
import { IconType } from '../Icon';

type Props = {
  title: string;
  addTitle: string;
  onAdd: () => void;
  addDisabled?: boolean;
  icon?: IconType;
};

const HeadingWithAdd: FC<Props> = ({
  onAdd,
  addDisabled = false,
  addTitle,
  title,
  icon,
}) => {
  return (
    <FlexContainer justifyContent="space-between" alignItems="center">
      <Heading tag={Tag.H4}>{title}</Heading>
      <div>
        <ButtonWithIcon
          icon={icon ? icon : IconType.PlusCircle}
          color="blue"
          background="transparent"
          onClick={onAdd}
          disabled={addDisabled}
        >
          {addTitle}
        </ButtonWithIcon>
      </div>
    </FlexContainer>
  );
};

export default HeadingWithAdd;
