// RegExp
export const regExp = {
  email:
    /^[-!#$%&'*+/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-?\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/,
  allCaps: /^[A-Z]+$/,
};

// Validate
export const isEmail = (email: string) => regExp.email.test(email);
export const isAllCaps = (text: string) => regExp.allCaps.test(text);
export const firstChar = (text: string) => text.charAt(0).toUpperCase();
export const firstChars = (text: string, count: number) => text.slice(0, count);
export const capitalize = (text: string) => firstChar(text) + text.slice(1);
