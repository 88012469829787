import React, { FC, useEffect, Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ContentWidth, Gap } from 'styles';
import { useAppDispatch } from 'hooks/redux';
import { setLayoutHeader, useGetCompanyCompetitionsQuery } from 'store';

import Heading, { Tag } from 'components/UI/Heading';
import Loader from 'components/UI/Loader';

import { CardList, HeroGrid, HeroCol, HeroActions } from './styles';
import ListMenu from 'components/UI/ListMenu';
import * as routes from 'router/routes';
import CompetitionCard from 'components/cards/CompetitionCard';
import { getImage } from 'utils/asset';
import { useLocalizedText } from 'hooks/useLocalizedText';
import HeroSmall from 'components/UI/Heros/HeroSmall';
import Button from 'components/UI/Button';
import EmptyState from 'components/UI/EmptyState';
import { IconType } from 'components/UI/Icon';
import { useNavigate } from 'react-router-dom';
import { usePretendCompany } from 'hooks/usePretendCompany';

const AdminCompetitions: FC = () => {
  const intl = useIntl();
  const companyId = usePretendCompany();
  const dispatch = useAppDispatch();

  // Hooks
  const getText = useLocalizedText();
  const { data, isLoading } = useGetCompanyCompetitionsQuery({ companyId });
  const navigate = useNavigate();

  // Set header
  useEffect(() => {
    dispatch(
      setLayoutHeader({
        title: intl.formatMessage({
          id: 'pageAdminCompetitionsTitle',
          defaultMessage: 'Competitions',
          description: 'Page title for admin competitions page',
        }),
        companyInHeader: true,
        inverted: true,
      })
    );
  }, [dispatch, intl]);

  // Loading
  if (isLoading) {
    return <Loader color="blue" padding />;
  }

  // No data
  if (!data) {
    return null;
  }

  return (
    <Fragment>
      <HeroSmall background="purple" illustrationColor="purpleDark">
        <HeroGrid>
          <HeroCol>
            <p>
              <FormattedMessage
                id="pageAdminCompetitionsHeroTitle"
                defaultMessage="Completed competitions"
                description="Hero title on admin competitions page"
              />
            </p>
            <Heading>{data.completedContests?.length ?? 0}</Heading>
          </HeroCol>
          <HeroActions>
            <Button
              color="white"
              border="white"
              background="transparent"
              onClick={() => navigate(`${routes.ADMIN_COMPETITIONS}/available`)}
            >
              <FormattedMessage
                id="pageAdminOverviewStartNewCompetitionButton"
                defaultMessage="Start new competition"
                description="Start competition button on admin overview page"
              />
            </Button>
          </HeroActions>
        </HeroGrid>
      </HeroSmall>
      <ContentWidth isSurface>
        <Heading tag={Tag.H4}>
          <FormattedMessage
            id="pageCompeteActiveCompetitionsTitle"
            defaultMessage="Active competitions"
            description="Section title for active competitions"
          />
        </Heading>
        <CardList>
          {!data.activeContests?.length && (
            <EmptyState iconType={IconType.Flag} padding>
              <FormattedMessage
                id="activeCompetitionsEmptyState"
                defaultMessage="No active competitions"
                description="Empty state for active competitions"
              />
            </EmptyState>
          )}
          {data.activeContests?.map((item, i) => (
            <CompetitionCard
              key={`${item.id}-${i}`}
              title={getText(item.title)}
              image={getImage(item.image)}
              wellrPoints={item.wellrPoints}
              link={`${routes.ADMIN_COMPETITIONS}/${item.id}`}
              accepted={item.accepted}
              type={item.type}
            />
          ))}
        </CardList>
        <Gap />
        <Heading tag={Tag.H4}>
          <FormattedMessage
            id="pageAdminCompetitionsUpcomingCompetitionsTitle"
            defaultMessage="Upcoming competitions"
            description="Section title for upcoming competitions"
          />
        </Heading>
        <CardList>
          {!data.futureContests?.length && (
            <EmptyState iconType={IconType.Flag} padding>
              <FormattedMessage
                id="upcomingCompetitionsEmptyState"
                defaultMessage="No upcoming competitions"
                description="Empty state for upcoming competitions"
              />
            </EmptyState>
          )}
          {data.futureContests?.map((item, i) => (
            <CompetitionCard
              key={`${item.id}-${i}`}
              title={getText(item.title)}
              image={getImage(item.image)}
              wellrPoints={item.wellrPoints}
              link={`${routes.ADMIN_COMPETITIONS}/${item.id}`}
              accepted={item.accepted}
              type={item.type}
            />
          ))}
        </CardList>
        <Gap />
        <ListMenu
          menu={[
            {
              id: 1,
              text: intl.formatMessage({
                id: 'pageAdminCompetitionsCompletedCompetitionsLink',
                defaultMessage: 'Completed competitions',
                description: 'Link to completed competitions page',
              }),
              link: `${routes.ADMIN_COMPETITIONS}/completed`,
            },
          ]}
        />
      </ContentWidth>
    </Fragment>
  );
};

export default AdminCompetitions;
