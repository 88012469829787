import { FC, ReactNode } from 'react';

import Icon, { IconType } from 'components/UI/Icon';
import Heading, { Tag } from 'components/UI/Heading';

import { Container } from './styles';

type Props = {
  children: ReactNode;
  iconType?: IconType;
  padding?: boolean;
  inverted?: boolean;
  compact?: boolean;
  align?: 'center' | 'left' | 'right';
};

const EmptyState: FC<Props> = ({
  children,
  iconType,
  padding = false,
  inverted = false,
  compact = false,
  align = 'center',
}) => {
  return (
    <Container
      padding={padding}
      inverted={inverted}
      compact={compact}
      align={align}
    >
      {iconType != null && (
        <Icon type={iconType} size={compact ? 32 : 64} color={'pinkDark'} />
      )}
      <Heading tag={compact ? Tag.H5 : Tag.H3}>{children}</Heading>
    </Container>
  );
};

export default EmptyState;
