import styled from 'styled-components';

export const CardDescription = styled.div`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.grayText};
  margin-top: 16px;
`;

export const CardTitle = styled.h2`
  font-size: 26px;
  color: ${({ theme }) => theme.colors.black};
  margin: 0;
`;

export const CardWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;
