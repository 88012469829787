import React, { FC } from 'react';
import {
  Dialog,
  DialogBody,
  DialogButtonContainer,
  DialogButtonDivider,
  Overlay,
} from './styles';
import Button from 'components/UI/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import { getUnitTranslation } from 'utils/activity';

type Props = {
  isOpen: boolean;
  activityUnitSlug: 'step' | 'minute' | string;
  numberOfUnits: number;
  onAbort: () => void;
};

const ConfirmActivityPopup: FC<Props> = ({
  isOpen,
  numberOfUnits,
  activityUnitSlug,
  onAbort,
}) => {
  const intl = useIntl();
  return (
    <>
      <Overlay open={isOpen} />
      <Dialog open={isOpen}>
        {isOpen && (
          <>
            <DialogBody>
              <FormattedMessage
                id="confirmActivityPopupText"
                defaultMessage="You are about to register an activity that is {unitText}."
                description="Confirm activity popup text"
                values={{
                  unitText: getUnitTranslation(
                    activityUnitSlug,
                    numberOfUnits,
                    intl
                  ),
                }}
              />
            </DialogBody>
            <DialogButtonContainer>
              <Button type="submit" background="transparent" color="blue">
                <FormattedMessage
                  id="confirmButton"
                  defaultMessage="Confirm"
                  description="Confirm button text"
                />
              </Button>
              <DialogButtonDivider />
              <Button onClick={onAbort} background="transparent" color="error">
                <FormattedMessage
                  id="cancelButton"
                  defaultMessage="Cancel"
                  description="Cancel button text"
                />
              </Button>
            </DialogButtonContainer>
          </>
        )}
      </Dialog>
    </>
  );
};

export default ConfirmActivityPopup;
