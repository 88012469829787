import { FC, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { ColGrid, ContentWidth } from 'styles';

import { usePageHeader } from 'hooks/usePageHeader';
import { useGetCompaniesQuery, useGetUsageStatsQuery } from 'store';
import {
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryLine,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory';
import {
  TimeframeButtons,
} from './styles';
import Loader from 'components/UI/Loader';
import { format } from 'date-fns';
import SelectField from 'components/UI/SelectField';
import { useForm } from 'react-hook-form';
import { useTheme } from 'styled-components';
import TextField from 'components/UI/TextField';
import { dateStringToISOString } from 'utils/date';
import Button from 'components/UI/Button';
import { groupByTimeFrame } from 'utils/statitics/groupByTimeFrame';
import { AggregatedData } from 'models/statitics/statitics';

const AdminUsageStats: FC = () => {
  const intl = useIntl();
  const theme = useTheme();

  const companiesResult = useGetCompaniesQuery();

  // Set header
  usePageHeader({
    title: intl.formatMessage({
      id: 'menuUsageStats',
      defaultMessage: 'Usage statistics',
      description: 'Menu item for usage stats',
    }),
  });

  const companyOptions = useMemo(() => {
    if (companiesResult.data == null) {
      return [];
    }
    return companiesResult.data.map((company) => ({
      value: company.id,
      name: company.description ?? '-',
    }));
  }, [companiesResult.data]);

  const dataTypeOptions = useMemo(() => {
    return [
      {
        value: 'activeUsers' as const,
        name: intl.formatMessage({
          id: 'activeUsers',
          defaultMessage: 'Active users',
          description: 'Active users',
        }),
      },
      {
        value: 'averageHealthScore' as const,
        name: intl.formatMessage({
          id: 'averageHealthScore',
          defaultMessage: 'Average health score',
          description: 'Average health score',
        }),
      },
      {
        value: 'gdprUsers' as const,
        name: intl.formatMessage({
          id: 'gdprUsers',
          defaultMessage: 'GDPR users',
          description: 'GDPR users',
        }),
      },
      {
        value: 'noPendingHealthQuizUsers' as const,
        name: intl.formatMessage({
          id: 'noPendingHealthQuizUsers',
          defaultMessage: 'No pending health quiz users',
          description: 'No pending health quiz users',
        }),
      },
      {
        value: 'totalUsers' as const,
        name: intl.formatMessage({
          id: 'totalUsers',
          defaultMessage: 'Total users',
          description: 'Total users',
        }),
      },
      {
        value: 'totalWellrPoints' as const,
        name: intl.formatMessage({
          id: 'totalWellrPoints',
          defaultMessage: 'Total Wellr points',
          description: 'Total Wellr points',
        }),
      },
    ];
  }, [intl]);

  const {
    register,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      dataType: dataTypeOptions[0].value,
      companyId: '',
      fromDate: '',
      toDate: '',
    },
  });

  const selectedDataType = watch('dataType');
  const selectedCompany = watch('companyId');
  const [selectedTimeFrame, setSelectedTimeFrame] = useState<'day' | 'week' | 'month'>('day');
  const [activeButton, setActiveButton] = useState('day');
  const [selectedFromDate, selectedToDate] = watch(['fromDate', 'toDate']);

  const handleDayClick = () => setSelectedTimeFrame('day');
  const handleWeekClick = () => setSelectedTimeFrame('week');
  const handleMonthClick = () => setSelectedTimeFrame('month');


  const { data, isLoading } = useGetUsageStatsQuery(
    {
      organizationEntityId: selectedCompany, // '15425314-f704-465b-bdb5-366c2818b3d8',
      from: selectedFromDate ? dateStringToISOString(selectedFromDate) : '', //'2024-01-01T09:10:52.308Z',
      to: selectedToDate ? dateStringToISOString(selectedToDate) : '', //'2024-01-09T09:10:52.308Z',
    },
    {
      skip:
        selectedCompany === '' ||
        selectedFromDate === '' ||
        selectedToDate === '',
    }
  );

  const [timeframeData, setTimeframedData] = useState<AggregatedData[]>([]);

  useEffect(() => {
    if (data) {
      const groupedData = groupByTimeFrame(data, selectedTimeFrame);
      setTimeframedData(groupedData);
    }
  }, [data, selectedTimeFrame]);


  const yAxisRange: [number, number] | undefined = useMemo(() => {
    if (data == null) {
      return [0, 0];
    }
    const yValues = data.map((d) => d[selectedDataType] ?? 0);
    const yMin = Math.min(...yValues);
    const yMax = Math.max(...yValues);

    if (yMin === yMax) {
      return [0, yMax * 2];
    }

    const buffer = (yMax - yMin) * 0.1; // 10% buffer

    return [Math.max(0, yMin - buffer), yMax + buffer];
  }, [data, selectedDataType]);

  const chartFontSize = 11;

  return (
    <ContentWidth isSurface>
      <SelectField
        label={intl.formatMessage({
          id: 'inputCompanySelectLabel',
          defaultMessage: 'Company',
          description: 'Label for company select',
        })}
        placeholder={intl.formatMessage({
          id: 'inputCompanySelectPlaceholder',
          defaultMessage: 'Select company',
          description: 'Placeholder for company select',
        })}
        register={register('companyId')}
        options={companyOptions}
      />
      <br />
      <ColGrid columns={2}>
        <TextField
          label={intl.formatMessage({
            id: 'inputFromDateLabel',
            defaultMessage: 'From date',
            description: 'Label for from date input',
          })}
          placeholder={intl.formatMessage({
            id: 'inputFromDatePlaceholder',
            defaultMessage: 'Enter from date',
            description: 'Placeholder for from date input',
          })}
          type="datetime-local"
          error={errors.fromDate}
          register={register('fromDate')}
        />
        <TextField
          label={intl.formatMessage({
            id: 'inputToDateLabel',
            defaultMessage: 'To date',
            description: 'Label for to date input',
          })}
          placeholder={intl.formatMessage({
            id: 'inputToDatePlaceholder',
            defaultMessage: 'Enter to date',
            description: 'Placeholder for to date input',
          })}
          type="datetime-local"
          error={errors.toDate}
          register={register('toDate')}
        />
      </ColGrid>
      <SelectField
        label={intl.formatMessage({
          id: 'inputDataTypeSelectLabel',
          defaultMessage: 'Data point',
          description: 'Label for department select on admin add user page',
        })}
        register={register('dataType')}
        options={dataTypeOptions}
      />
      {isLoading && <Loader color="blue" padding />}
      <TimeframeButtons>
        <Button
          color={activeButton === 'day' ? 'white' : 'black'}
          border="black"
          background={activeButton === 'day' ? 'blue' : 'transparent'}
          onClick={() => {
            handleDayClick();
            setActiveButton('day');
          }}
        >
          Dagvis
        </Button>
        <Button
          color={activeButton === 'week' ? 'white' : 'black'}
          border="black"
          background={activeButton === 'week' ? 'blue' : 'transparent'}
          onClick={() => {
            handleWeekClick();
            setActiveButton('week');
          }}
        >
          Veckovis
        </Button>
        <Button
          color={activeButton === 'month' ? 'white' : 'black'}
          border="black"
          background={activeButton === 'month' ? 'blue' : 'transparent'}
          onClick={() => {
            handleMonthClick();
            setActiveButton('month');
          }}
        >
          Månadsvis
        </Button>
      </TimeframeButtons>
      {data != null && data.length > 0 && (
        <VictoryChart containerComponent={<VictoryVoronoiContainer />}>
          <VictoryAxis
            scale="time"
            tickValues={
              selectedTimeFrame !== 'day'
                ? timeframeData.map((d) => d.periodStart)
                : undefined
            }
            tickFormat={(x: number | Date) => {
              switch (selectedTimeFrame) {
                case 'week':
                  return format(x, 'ww ');
                case 'month':
                  return format(x, 'MMM yy');
                default:
                  return format(x, 'd / MMM');
              }
            }
            }
            tickLabelComponent={<VictoryLabel />}
            style={{
              tickLabels: {
                fontSize: chartFontSize,
              },
            }}
          />
          <VictoryAxis
            dependentAxis
            domain={yAxisRange}
            style={{
              tickLabels: {
                fontSize: chartFontSize,
              },
            }}
          />
          <VictoryLine
            style={{
              data: { stroke: theme.colors.blue, strokeWidth: 3 },
              parent: { border: '1px solid red' },
            }}
            data={timeframeData.map((d) => ({
              x: d.periodStart,
              y: d[selectedDataType] ?? 0,
            }))}
            labels={({ datum }) => {
              const dateFormat = selectedTimeFrame === 'month' ? 'yyyy-MM' : 'yyyy-MM-dd';
              return ` ${format(datum.x, dateFormat)}: ${(datum.y as number).toLocaleString()}`;
            }}
            labelComponent={
              <VictoryTooltip
                style={{ fontSize: chartFontSize, fill: theme.colors.white }}
                flyoutStyle={{
                  fill: theme.colors.blue,
                  stroke: theme.colors.borderDark,
                  color: theme.colors.white,
                }}
              />
            }
          />
        </VictoryChart>
      )}
    </ContentWidth>
  );
};

export default AdminUsageStats;
