import styled, { DefaultTheme } from 'styled-components';
import { ThemeColor } from 'styles';

export type ButtonSize = 'large' | 'small';
export type ButtonColor = ThemeColor;
export type ButtonBgColor = ThemeColor | 'transparent';

type ButtonProps = {
  theme: DefaultTheme;
  color: ButtonColor;
  background: ButtonBgColor;
  size: ButtonSize;
  border?: ButtonColor;
  marginRight?: boolean;
  backgroundOpacity?: number;
};

// Background Color
const getBackgroundColor = ({ theme, background }: ButtonProps) => {
  if (background === 'transparent') {
    return 'transparent';
  }
  return theme.colors[background];
};
// Helper function to convert hex to RGBA
const hexToRgba = (hex: string, opacity: number) => {
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

const getBackgroundColorWithOpacity = (props: ButtonProps) => {
  const backgroundColor = getBackgroundColor(props);
  const opacity = props.backgroundOpacity || 1;
  return hexToRgba(backgroundColor, opacity);
};
// Background hover color
const getBackgroundHoverColor = ({ theme, background }: ButtonProps) => {
  if (background === 'transparent') {
    return 'transparent';
  }
  if (background === 'yellow') {
    return theme.colors.yellowDark;
  }
  if (background === 'purple') {
    return theme.colors.purpleDark;
  }
  if (background === 'pink') {
    return theme.colors.pinkDark;
  }
  if (background === 'teal') {
    return theme.colors.tealDark;
  }
  if (background === 'orange') {
    return theme.colors.orangeDark;
  }
  return theme.colors[background];
};

// Button
export const ButtonBase = styled.button<ButtonProps>`
  flex: 1;
  color: ${({ theme, color }) => theme.colors[color]};
  background-color: ${({ backgroundOpacity }) =>
    backgroundOpacity && backgroundOpacity !== 1
      ? getBackgroundColorWithOpacity
      : getBackgroundColor};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 16px;
  font-weight: bold;
  line-height: 16px;
  margin-right: ${({ marginRight, size }) => {
    if (!marginRight) {
      return '0';
    }
    return size === 'small' ? '8px' : '16px';
  }};
  padding: ${({ size }) => (size === 'small' ? '8px 16px' : '16px')};
  opacity: ${({ disabled }) => (disabled ? '0.6' : '1')};
  border-radius: ${({ size }) => (size === 'small' ? '8px' : '16px')};
  border: ${({ border, theme }) =>
    border ? `1px solid ${theme.colors[border]}` : 'none'};
  outline: none;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  &:hover {
    background-color: ${(props) => {
    if (props.disabled) {
      return getBackgroundColor(props);
    }
    return getBackgroundHoverColor(props);
  }};
  }
`;
