import { TimedChallengeResultInDto } from 'models/timedChallengeResult/timedChallengeResult';
import { baseApi } from '../baseApi';

const timedChallengeResultApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    createMyTimedChallengeResult: builder.mutation<void, TimedChallengeResultInDto>({
      query: (timedChallengeResult) => ({
        url: '/timed-challenge/result/me',
        method: 'POST',
        body: timedChallengeResult,
      }),
    }),
    getMyTimedChallengeResult: builder.query<TimedChallengeResultInDto, void>({
      query: () => ({
        url: '/timed-challenge/result',
        method: 'GET',
      }),
    }),
    getIsMyDailyChallengeCompleted: builder.query<boolean, { timedChallengeId: string }>({
      query: ({ timedChallengeId }) => ({
        url: '/timed-challenge/result/me/daily/completed?timedChallengeId=' + timedChallengeId,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useCreateMyTimedChallengeResultMutation,
  useGetMyTimedChallengeResultQuery,
  useGetIsMyDailyChallengeCompletedQuery
} = timedChallengeResultApi;