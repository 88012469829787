import React, { FC, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from 'hooks/redux';
import { localizationSelector, useGetAvailableQuizesQuery } from 'store';

import {
  CategoryCircle,
  HealthCategory,
  CategoryHeader,
  TwoColGridReport,
  HeadingTitle,
  DateSubTitle,
  HeadingContainer,
} from './styles';
import EmptyState from 'components/UI/EmptyState';
import { CategoryIcon, } from 'models';
import Loader from 'components/UI/Loader';
import { getCategoryIcon } from 'utils/asset';
import { IconType } from 'components/UI/Icon';

import { useLocalizedText } from 'hooks/useLocalizedText';
import { formatDate } from 'utils/date';


const MyHealthReport: FC = () => {
  const getText = useLocalizedText();

  // Hooks
  const { language } = useAppSelector(localizationSelector);
  const quizes = useGetAvailableQuizesQuery({
    language: language?.languageCode,
    inclDeletedResults: true,
  });

  const renderIcon = useCallback(
    (icon: CategoryIcon, isActive: boolean = false) => {
      const img = getCategoryIcon(icon, isActive);
      return img ? <img src={img.src} alt={img.alt} /> : null;
    },
    []
  );

  // Health category
  const healthCategory = useMemo(() => {
    // Loading
    if (quizes.data === undefined) {
      return <Loader color="blue" padding />;
    }

    return (
      [...(quizes.data ?? [])]
        .sort((a, b) => {
          const resultA = a.quizResults[0]?.percentage ?? 0;
          const resultB = b.quizResults[0]?.percentage ?? 0;
          return resultB - resultA;
        })
        .map((item) => {
          const result = item.quizResults[0];
          if (!result) return null;

          const resultText = result.assessment.resultText![language?.languageCode ?? 'en'];
          const resultTitle = result.assessment.resultTitle![language?.languageCode ?? 'en'];
          const titleQuiz = getText(item.quizDefinition.title);

          return (
            <HealthCategory key={item.quizSlug}>
              <CategoryHeader>
                <CategoryCircle
                  color={result.assessment.color?.hex ?? ''}
                  isDisabled={false}>
                  {renderIcon(item.quizDefinition.healthCategory!.icon, true)}
                </CategoryCircle>
                <HeadingContainer>
                  <HeadingTitle>{titleQuiz} - <DateSubTitle>{formatDate(new Date(result.created))}</DateSubTitle></HeadingTitle>
                  <HeadingTitle>{resultTitle}</HeadingTitle>

                </HeadingContainer>
              </CategoryHeader>
              <div style={{width: '100%'}}>
                {resultText}
              </div>
            </HealthCategory>
          );
        })
    )
  }, [quizes.data, language?.languageCode, getText, renderIcon]);

  const content = useMemo(() => {
    if (quizes.isLoading) {
      return <Loader padding color="blue"  />;
    }

    if (!quizes.data) {
      return (
        <EmptyState iconType={IconType.Health} padding >
          <FormattedMessage
            id="pageQuizResultEmptyState"
            defaultMessage="Quiz result not found"
            description="Empty state for quiz result" />
        </EmptyState>
      );
    }

    return (
      <TwoColGridReport>
        {healthCategory}
      </TwoColGridReport>
    );
  }, [healthCategory, quizes.data, quizes.isLoading]);

  return content;
}

export default MyHealthReport;
