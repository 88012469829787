import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { localizationSelector, useGetStepGoalQuery, useGetWeeklyActivitiesQuery } from 'store';
import { useAppSelector } from 'hooks/redux';
import { getShortDateDay, getPastDateByDays, formatDate } from 'utils/date';
import { sortArrayByProperty } from 'utils/array';

import ActivityList from 'components/fragments/ActivityList';
import RegisterActivityModal from 'components/modals/RegisterActivityModal';
import StaticCard from 'components/UI/Cards/StaticCard';
import Loader from 'components/UI/Loader';
import Button from 'components/UI/Button';

import { Grid, WeekdayGrid, DayCircle, Action, Separator } from './styles';
import ActivitiesHeader from '../ActivitiesHeader';

type Props = {
  showLatest?: boolean;
  onActivitiesChange?: (activities: any) => void;
};

const ActivitiesWeekly: FC<Props> = ({ showLatest, onActivitiesChange }) => {
  const { language } = useAppSelector(localizationSelector);
  const RE_FETCH_TIMEOUT_MS = 1000 * 10
  const [lastWeeklyActivitiesFetch, setLastWeeklyActivitiesFetch] = useState(0);
  const [lastGetStepGoalFetch, setLastGetStepGoalFetch] = useState(0);

  // Hooks
  const activities = useGetWeeklyActivitiesQuery(undefined, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: lastWeeklyActivitiesFetch + RE_FETCH_TIMEOUT_MS < Date.now(),
  });
  const { dailyStepGoal, isLoading, fulfilledTimeStamp } = useGetStepGoalQuery(undefined, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: lastGetStepGoalFetch + RE_FETCH_TIMEOUT_MS < Date.now(),
    selectFromResult: (response) => ({
      dailyStepGoal: response.data?.dailyStepGoal,
      isLoading: response.isLoading,
      fulfilledTimeStamp: response.fulfilledTimeStamp
    }),
  });

  useEffect(() => {
    setLastWeeklyActivitiesFetch(Date.now());
    if (onActivitiesChange) {
      onActivitiesChange(activities.data);
    }
  }, [activities.fulfilledTimeStamp, onActivitiesChange]);

  useEffect(() => {
    setLastWeeklyActivitiesFetch(Date.now())
  }, [activities.fulfilledTimeStamp])

  useEffect(() => {
    const payload = () => {
      activities.refetch();
    };
    window.addEventListener('canUpdateSteps', payload);
    return () => window.removeEventListener('canUpdateSteps', payload);
  });

  // State
  const [active, setActive] = useState<number>(new Date().getDay());
  const [activeDate, setActiveDate] = useState<Date>(new Date());
  const [registerModalOpen, setRegisterModalOpen] = useState<boolean>(false);

  const onRefresh = useCallback(() => {
    activities.refetch();
  }, [activities]);

  // On set active
  const onSetActive = useCallback((i: number, date: Date) => () => {
    setActive(i);
    setActiveDate(date);
  }, []);

  // Open / close modal
  const onOpenModal = useCallback(() => setRegisterModalOpen(true), []);
  const onCloseModal = useCallback(() => setRegisterModalOpen(false), []);

  // Days
  const days = useMemo(() => {
    return [
      getPastDateByDays(6),
      getPastDateByDays(5),
      getPastDateByDays(4),
      getPastDateByDays(3),
      getPastDateByDays(2),
      getPastDateByDays(1),
      formatDate(),
    ];
  }, []);

  const activeDayActivities = useMemo(
    () =>
      (activities.data ?? []).filter(
        (activity) => new Date(activity.timestamp).getDay() === active
      ),
    [active, activities.data]
  );


  // Get step count
  const getStepCount = useCallback(
    (day: number) => {
      if (!activities.data) {
        return 0;
      }

      return activities.data.reduce((sum, { timestamp, value }) => {
        if (new Date(timestamp).getDay() === day) {
          return sum + value.stepsAwarded;
        }
        return sum;
      }, 0);
    },
    [activities.data]
  );

  // Get step count
  const getShortDay = useCallback(
    (date: string) => getShortDateDay(new Date(date), language?.languageCode),
    [language]
  );

  // Last activities
  const lastActivities = useMemo(() => {
    if (!showLatest || activeDayActivities.length === 0) {
      return null;
    }
    const sortedActivities = sortArrayByProperty(
      activeDayActivities,
      'timestamp',
      'desc'
    );
    return (
      <>
        <Separator />
        <ActivityList showWalkSteps data={sortedActivities} />
      </>
    );
  }, [showLatest, activeDayActivities]);

  // Loading
  if (activities.isLoading || isLoading) {
    return <Loader color="blue" padding />;
  }

  // No data
  if (!activities.data) {
    return null;
  }

  const setTimeToCurrent = (date: Date): Date => {
    const newDate = new Date(date);
    const now = new Date();
    newDate.setHours(now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds());
    return newDate;
  };

  return (
    <StaticCard>
      <Grid>
        <WeekdayGrid>
          {days.map((day) => (
            <DayCircle
              key={day}
              isActive={new Date(day).getDay() === active}
              awardedSteps={getStepCount(new Date(day).getDay()) > 0}
              onClick={onSetActive(new Date(day).getDay(), new Date(day))}
              isProgressComplete={getStepCount(new Date(day).getDay()) >= (dailyStepGoal ?? 0) && (dailyStepGoal ?? 0) > 0}
            >
              {getShortDay(day)}
            </DayCircle>
          ))}
        </WeekdayGrid>
        {activities.isFetching ? (
          <Loader color="blue" padding />
        ) : (
          <>
            <ActivitiesHeader
              activitiesData={activeDayActivities}
              refetch={onRefresh}
            />
            {lastActivities}
          </>
        )}
        <Action>
          <Button color="blue" background="transparent" onClick={onOpenModal}>
            <FormattedMessage
              id="registerActivityButton"
              defaultMessage="Register activity"
              description="Register activity button"
            />
          </Button>
        </Action>
      </Grid>
      <RegisterActivityModal
        date={setTimeToCurrent(activeDate)}
        isOpen={registerModalOpen}
        onClose={onCloseModal}
      />
    </StaticCard>
  );
};

export default ActivitiesWeekly;
