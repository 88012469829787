import { FC, Fragment, useMemo } from 'react';
import { useIntl } from 'react-intl';

import Button from 'components/UI/Button';
import Heading, { Tag } from 'components/UI/Heading';
import StaticCard from 'components/UI/Cards/StaticCard';
import WellrCoins from 'components/UI/WellrCoins';
import Icon, { IconType } from 'components/UI/Icon';
import IconButton from 'components/UI/IconButton';
import DropdownMenu from 'components/UI/DropdownMenu';

import { Divider, MediaDisplay } from 'styles';
import { useLocalizedText } from 'hooks/useLocalizedText';
import { ContentText, HealthCategory, QuizResult } from 'models';

import {
  Grid,
  Column,
  Action,
  Header,
  HeaderCol,
  ActionColumn,
  Description,
  DisabledTitle,
} from './styles';

type Props = {
  category: HealthCategory | null;
  text: ContentText | null;
  wellrPoints: number;
  results?: QuizResult[];
  buttonText?: string;
  buttonActiveText?: string;
  isActive?: boolean;
  onClick?: () => void;
  onRemove?: () => void;
  onActiveClick?: () => void;
};

const MeasureCard: FC<Props> = ({
  category,
  text,
  wellrPoints,
  onActiveClick,
  onClick,
  onRemove,
  buttonText,
  buttonActiveText,
  isActive = false,
}) => {
  const intl = useIntl();
  const getText = useLocalizedText();

  // Dropdown menu
  const menu = useMemo(() => {
    if (!onRemove) {
      return null;
    }
    return (
      <DropdownMenu
        menu={[
          {
            id: 1,
            color: 'error',
            text: intl.formatMessage({
              id: 'menuRemoveMeasure',
              defaultMessage: 'Remove health plan',
              description: 'Dropdown menu item for remove measure',
            }),
            onClick: onRemove,
          },
        ]}
      >
        <IconButton>
          <Icon type={IconType.More} />
        </IconButton>
      </DropdownMenu>
    );
  }, [onRemove, intl]);

  // Content for card
  const content = useMemo(() => {
    if (isActive) {
      return <Heading tag={Tag.H3}>{getText(text)}</Heading>;
    }
    return <Description>{getText(text)}</Description>;
  }, [text, isActive, getText]);

  // Actions
  const actions = useMemo(() => {
    if (isActive) {
      return (
        <Fragment>
          <Divider />
          <Action>
            <Button
              color="blue"
              background="transparent"
              onClick={onActiveClick}
            >
              {buttonActiveText}
            </Button>
          </Action>
        </Fragment>
      );
    }
    return (
      <ActionColumn>
        <MediaDisplay breakpoint="s" isLarger>
          <WellrCoins count={wellrPoints} />
        </MediaDisplay>
        <MediaDisplay breakpoint="s" isLarger>
          {menu}
        </MediaDisplay>
        <Button background="blue" onClick={onClick}>
          {buttonText}
        </Button>
      </ActionColumn>
    );
  }, [
    onClick,
    onActiveClick,
    menu,
    isActive,
    wellrPoints,
    buttonText,
    buttonActiveText,
  ]);

  // Category
  const healthCategory = useMemo(() => {
    if (!category) {
      return null;
    }
    if (isActive) {
      return <DisabledTitle>{getText(category.title)}</DisabledTitle>;
    }
    return <Heading tag={Tag.H3}>{getText(category.title)}</Heading>;
  }, [category, isActive, getText]);

  // Wellr points
  const points = useMemo(() => {
    if (isActive) {
      return <WellrCoins count={wellrPoints} />;
    }
    return (
      <MediaDisplay breakpoint="s">
        <WellrCoins count={wellrPoints} />
      </MediaDisplay>
    );
  }, [isActive, wellrPoints]);

  return (
    <StaticCard>
      <Grid isActive={isActive}>
        <Column>
          <Header>
            <HeaderCol>
              <Icon type={IconType.Exercise} />
              {healthCategory}
            </HeaderCol>
            <HeaderCol>
              {points}
              {isActive ? (
                menu
              ) : (
                <MediaDisplay breakpoint="s">{menu}</MediaDisplay>
              )}
            </HeaderCol>
          </Header>
          {content}
        </Column>
        {actions}
      </Grid>
    </StaticCard>
  );
};

export default MeasureCard;
