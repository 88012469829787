import styled from 'styled-components';
import { breakpoints } from 'styles';

export const Grid = styled.div<{ clickable: boolean }>`
  display: flex;
  justify-content: space-between;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
  border: 1px solid ${({ theme }) => theme.colors.backgroundDark};
  border-radius: 16px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

  @media (max-width: ${breakpoints.s}px) {
    flex-direction: column;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin-bottom: 12px;
`;

export const HeaderCol = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const DisabledTitle = styled.span`
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
`;

export const Progress = styled.div`
  margin-top: 24px;
`;

export const TestData = styled.p`
  color: ${({ theme }) => theme.colors.grayText};
  font-size: 14px;
  margin: 12px 0 0;
`;

export const Column = styled.div`
  flex: 1;
  margin: 16px;

  h3 {
    margin: 0;
  }
`;

export const BaselineIconWrapper = styled.div<{ baselinePercentage: number }>`
  cursor: pointer;
  position: relative;
  top: -4px;
  left: ${({ baselinePercentage }) => `calc(${baselinePercentage}% - 8px)`};
`;
