import { FC, ReactNode } from 'react';
import Heading, { Tag } from 'components/UI/Heading';

import { Container, Description } from './styles';

type Props = {
  description: string;
  children: ReactNode;
};

const DataItem: FC<Props> = ({ description, children }) => {
  return (
    <Container>
      <Description>{description}</Description>
      <Heading tag={Tag.H5}>{children || '–'}</Heading>
    </Container>
  );
};

export default DataItem;
