import styled from 'styled-components';
import { breakpoints } from 'styles';

export const Grid = styled.div`
  overflow: hidden;
  border-radius: 16px;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin: 8px;
`;

export const MapGoal = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 4px;
  padding: 16px 16px;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.grayText};
  @media (max-width: ${breakpoints.s}px) {
    justify-content: center;
  }
`;
