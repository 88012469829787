import styled from 'styled-components';

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
`;

export const CardHeading = styled.span`
  font-size: 16px;
  font-weight: 900;
`;

export const CardDescription = styled.div`
  font-size: 12px;
  fony-family: 'Mark-Medium';
  color: ${({ theme }) => theme.colors.grayText};
`;

export const IconContainer = styled.div`
  cursor: pointer;
`;
