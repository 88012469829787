import Icon, { IconType } from 'components/UI/Icon';
import { FC, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  localizationSelector,
  setLayoutHeader,
  useGetContestActivityStatsQuery,
  useGetContestHealthchoiceStatsQuery,
  useGetContestQuizResultsQuery,
  useInspectContestQuery,
} from 'store';
import { ContentWidth, Gap, MediumGap } from 'styles';
import * as routes from 'router/routes';
import { useAppSelector } from 'hooks/redux';
import Loader from 'components/UI/Loader';
import TopList from 'components/fragments/TopList';
import TopListProgressItem from 'components/fragments/TopListProgressItem';
import { useLocalizedText } from 'hooks/useLocalizedText';
import { QuizTransition, TopListInfo, TopListInfoText } from './styles';
import QuizQuestionStatistics from 'components/fragments/QuizQuestionStatistics';
import HeadingWithAdd from 'components/UI/HeadingWithAdd';

const AdminCompetitionStatistics: FC = () => {

  const { slug } = useParams();
  const RE_FETCH_TIMEOUT_MS = 1000 * 10;
  const [isQuizShown, setIsQuizShown] = useState<boolean>(false);
  const [lastContestInspectFetch, setLastContestInspectFetch] = useState(0);
  useState<number | null>();

  // Hooks
  const intl = useIntl();
  const dispatch = useDispatch();
  const { language } = useAppSelector(localizationSelector);
  const getText = useLocalizedText();

  const contestInspect = useInspectContestQuery(
    {
      contestId: slug as string,
    },
    {
      refetchOnMountOrArgChange: 20,
      refetchOnFocus: lastContestInspectFetch + RE_FETCH_TIMEOUT_MS < Date.now()
    }
  );

  const healthChoiceStats = useGetContestHealthchoiceStatsQuery({
    contestId: slug as string,
  });

  const activityStats = useGetContestActivityStatsQuery({
    contestId: slug as string,
    language: language?.languageCode as string,
  });

  const contestQuizStatsArray = useGetContestQuizResultsQuery({
    contestId: slug as string,
    language: language?.languageCode as string,
  });

  useEffect(() => {
    dispatch(
      setLayoutHeader({
        title: intl.formatMessage({
          id: 'pageAdminCompetitionTitle',
          defaultMessage: 'Competition',
          description: 'Page title for admin competition page',
        }),
        companyInHeader: false,
        inverted: false,
        icon: IconType.Back,
        link: routes.ADMIN_COMPETITIONS + '/' + slug,
      })
    );
  }, [dispatch, intl, slug]);

  useEffect(() => {
    setLastContestInspectFetch(Date.now());
  }, [contestInspect.fulfilledTimeStamp]);


  const accumulatedSteps = useMemo(() => {
    if (activityStats.data == null) {
      return 0;
    }
    return [
      ...(activityStats.data.minutesToplist ?? []),
      ...(activityStats.data.stepsToplist ?? []),
    ].reduce((acc, item) => acc + item.stepsAwarded, 0);
  }, [activityStats.data]);

  const isLoading =
    activityStats.isLoading ||
    contestInspect.isLoading ||
    healthChoiceStats.isLoading;


  const questionsData = useMemo(
    () =>
      (contestQuizStatsArray.data ?? []).flatMap(contestQuizStats =>
        contestQuizStats.averageAnswers?.map(
          ({
            _key,
            _type,
            title,
            averagePoints,
            answerAlternativeStatistics,
            answers,
            maxPoints,
            maxPointsDescription,
            minPoints,
            minPointsDescription,
            unhideByAnswerAlternative,
          }) => {
            return {
              _key,
              _type,
              title: getText(title),
              averagePoints,
              answers,
              maxPoints,
              maxPointsDescription: getText(maxPointsDescription),
              minPoints,
              minPointsDescription: getText(minPointsDescription),
              unhideByAnswerAlternative,
              compare: {
                averagePoints: null,
                maxPoints: null,
                maxPointsDescription: getText(null),
                minPoints: null,
                minPointsDescription: getText(null),
              },
              baselineCompare: {
                averagePoints: null,
                maxPoints: null,
                maxPointsDescription: getText(null),
                minPoints: null,
                minPointsDescription: getText(null),
              },
              answerAlternativeStatistics:
                answerAlternativeStatistics?.map(
                  ({ _key, title, percentageOfAnswers }) => {
                    return {
                      _key,
                      title: getText(title),
                      percentageOfAnswers,
                      compareValue: null,
                      baselineCompareValue: null,
                    };
                  }
                ) ?? [],
            };
          }
        ) ?? []
      ),
    [contestQuizStatsArray.data, getText]
  );
  const quizQuestionStatistics = useMemo(() => {
    if (contestQuizStatsArray.isLoading) {
      return <Loader color='blue' padding />;
    }

    if (contestQuizStatsArray.data == null) {
      return null;
    }

    return (
      <div style={{ padding: 0, margin: 0 }}>
        <HeadingWithAdd
          icon={isQuizShown ? IconType.UpArrowCircle : IconType.DownArrowCircle}
          title={intl.formatMessage({
            id: "pageAdminQuizResultsQuestionsTitle",
            defaultMessage: "Questions result",
            description: "Title before the questions on the admin quiz results page"
          })}
          addTitle={intl.formatMessage({
            id: 'competitionStatisticsShowQuizResultButton',
            defaultMessage: 'Show quiz result',
            description: 'Button to show quiz result',
          })}
          onAdd={() => setIsQuizShown(prev => !prev)}
        />
        <QuizTransition isShown={isQuizShown}>
          <QuizQuestionStatistics
            questions={questionsData}
            showBaseline={false}
            contestQuiz={true}
          />
        </QuizTransition>
      </div>
    );
  }, [contestQuizStatsArray.isLoading, contestQuizStatsArray.data, isQuizShown, intl, questionsData]);

  const activityStatsContent = useMemo(() => {
    if (isLoading) {
      return <Loader color='blue' padding />;
    }
    else if (activityStats.data == null || contestInspect.data == null) {
      return null;
    }
    const { minutesToplist, stepsToplist } = activityStats.data;

    return (
      <>
        <TopList
          headerTitle={intl.formatMessage({
            id: 'adminContestActivityToplistTitle',
            defaultMessage: 'Activity step distribution',
            description:
              'Activity toplist title for admin contest activity statistics page',
          })}
          headerButtons={[
            {
              title: intl.formatMessage({
                id: 'adminContestActivityToplistMinutes',
                defaultMessage: 'Minutes',
                description:
                  'Minutes button for admin contest activity statistics page',
              }),
              topList: minutesToplist ?? [],
              renderTopListItem: (item, index) => {
                return (
                  <TopListProgressItem
                    key={index}
                    email={item.units + ' minuter'}
                    alias={item.title ?? '?'}
                    profilePictureAssetId={null}
                    showResults
                    totalSteps={item.stepsAwarded}
                    progress={(item.stepsAwarded / accumulatedSteps) * 100}
                    isLast={(minutesToplist ?? []).length === index + 1}
                  />
                );
              },
            },
            {
              title: intl.formatMessage({
                id: 'adminContestActivityToplistSteps',
                defaultMessage: 'Steps',
                description:
                  'Steps button for admin contest activity statistics page',
              }),
              topList: stepsToplist ?? [],
              renderTopListItem: (item, index) => {
                return (
                  <TopListProgressItem
                    key={index}
                    email={null}
                    alias={
                      item.title !== null && item.title !== ''
                        ? item.title
                        : item.slug ?? '?'
                    }
                    profilePictureAssetId={null}
                    showResults
                    totalSteps={item.stepsAwarded}
                    progress={(item.stepsAwarded / accumulatedSteps) * 100}
                    isLast={(minutesToplist ?? []).length === index + 1}
                  />
                );
              },
            },
          ]}
        />
        <TopListInfo>
          <Icon type={IconType.Info} color="grayText" />
          <TopListInfoText>
            <FormattedMessage
              id="adminContestActivityToplistInfo"
              defaultMessage="The percentage indicates the proportion of all reported steps that the activity accounts for"
              description="Info text for admin contest activity statistics page"
            />
          </TopListInfoText>
        </TopListInfo>
      </>
    );
  }, [accumulatedSteps,
    activityStats.data,
    contestInspect.data,
    intl,
    isLoading]);

  const healthchoiceStatsContent = useMemo(() => {
    if (healthChoiceStats.isLoading || contestInspect.isLoading) {
      return <Loader />;
    }
    if (healthChoiceStats.data == null || contestInspect.data == null) {
      return null;
    }
    const {
      healthChoiceCountPerUser,
      healthChoiceCountPerTeam,
      healthChoiceCount,
    } = healthChoiceStats.data;

    return (
      <>
        <TopList
          headerTitle={intl.formatMessage({
            id: 'adminContestHealthChoicesToplist',
            defaultMessage: 'Completed health choices toplist',
            description:
              'Health choices toplist title for admin contest activity statistics page',
          })}
          headerButtons={[
            {
              title: intl.formatMessage({
                id: 'adminContestHealthChoicesToplistUsers',
                defaultMessage: 'Users',
                description:
                  'Button title for users in health choices toplist for admin contest activity statistics page',
              }),
              topList: (healthChoiceCountPerUser ?? []).map((user, index) => ({
                key: user.contestUserId,
                email: user.email,
                alias: user.alias ?? '?',
                profilePictureAssetId: null,
                showResults: true,
                totalSteps: user.completedHealthChoices,
                progress: null,
                totalText: user.completedHealthChoices.toString(),
                isLast: (healthChoiceCountPerUser ?? []).length === index + 1,
              })),
              renderTopListItem: (topListProps) => {
                return <TopListProgressItem {...topListProps} />;
              },
            },
            {
              title: intl.formatMessage({
                id: 'adminContestHealthChoicesToplistTeams',
                defaultMessage: 'Teams',
                description:
                  'Button title for teams in health choices toplist for admin contest activity statistics page',
              }),
              topList: (healthChoiceCountPerTeam ?? []).map((team, index) => ({
                key: team.contestTeamId,
                email: null,
                alias: team.teamName ?? '?',
                profilePictureAssetId: null,
                showResults: true,
                totalSteps: team.completedHealthChoices,
                progress: null,
                totalText: team.completedHealthChoices.toString(),
                isLast: (healthChoiceCountPerTeam ?? []).length === index + 1,
              })),
              renderTopListItem: (topListProps) => {
                return <TopListProgressItem {...topListProps} />;
              },
            },
          ]}
        />
        <Gap />
        <TopList
          headerTitle={intl.formatMessage({
            id: 'adminContestHealthChoicesCompletedToplist',
            defaultMessage: 'Most completed health choices',
            description:
              'Most completed Health choices title for admin contest activity statistics page',
          })}
          topList={healthChoiceCount ?? []}
          renderTopListItem={(item, index) => {
            return (
              <TopListProgressItem
                key={item.healthChoiceId}
                email={null}
                alias={getText(item.description)}
                profilePictureAssetId={null}
                showResults
                totalText={item.completedHealthChoices.toString()}
                totalSteps={0}
                progress={null}
                isLast={(healthChoiceCount ?? []).length === index + 1}
              />
            );
          }}
        />
      </>
    );
  }, [
    contestInspect.data,
    contestInspect.isLoading,
    getText,
    healthChoiceStats.data,
    healthChoiceStats.isLoading,
    intl,
  ]);

  return (
    <ContentWidth isSurface>
      {questionsData.length > 0 && quizQuestionStatistics}
      <Gap />
      {activityStatsContent}
      <Gap />
      {healthchoiceStatsContent}
      <MediumGap />
    </ContentWidth>
  );
};

export default AdminCompetitionStatistics;
