import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { ActionContainer } from 'styles';
import { useGenderOptionsForStatistics } from 'hooks/useGenders';
import { QuizReminderCommand } from 'models';
import { Fields, Form } from './styles';
import { SelectItem } from '../ChallengeForm/styles';

import useOrganizationEntityOptions, { EntityType } from 'hooks/useOrganizationEntityOptions';
import Button from 'components/UI/Button/Button';
import SelectField from 'components/UI/SelectField';
import RadioButton from 'components/UI/RadioButton';
import useEntityOptions from 'hooks/useEntityOptions';
import { useGetAvailableQuizesQuery } from 'store';
import { useActiveLanguage } from 'hooks/useActiveLanguage';

type Props = {
  submitForm: (data: QuizReminderCommand) => void;
  isOpen: boolean;
  companyId: string;
};

const SendQuizReminderForm: FC<Props> = ({ submitForm, isOpen, companyId }) => {

  // States
  const [selectedNotifyOption, setSelectedNotifyOption] = useState('All');

  // Hooks
  const intl = useIntl();
  const genders = useGenderOptionsForStatistics();
  const language = useActiveLanguage();
  const quizes = useGetAvailableQuizesQuery({
    language: language?.languageCode,
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<{
    companyId: string;
    organizationId: undefined;
    gender: string;
    preferredNotificationMethod: string;
    organizationEntityType: EntityType;
    healthQuizSlug: string | undefined;
    healthQuizName: string | undefined
  }>({
    defaultValues: {
      companyId: companyId,
      organizationId: undefined,
      gender: 'All',
      preferredNotificationMethod: selectedNotifyOption,
      organizationEntityType: EntityType.Company,
      healthQuizSlug: undefined,
      healthQuizName: undefined,
    },
  });

  // Watch
  const entityType = watch('organizationEntityType');
  const healthQuizSlug = watch('healthQuizSlug');

  useEffect(() => {
    setValue('preferredNotificationMethod', selectedNotifyOption);
  }, [selectedNotifyOption, setValue]);

  // when healthQuizSlug is set change healthQuizName
  useEffect(() => {
    const quiz = quizes.data?.find((q) => q.quizSlug === healthQuizSlug);
    if (quiz) {
      setValue('healthQuizName', quiz.quizDefinition.healthCategory?.title?.[language?.languageCode] ?? quiz.quizDefinition.healthCategory?.title?.en ?? '');
    }
  }, [healthQuizSlug, quizes.data, language?.languageCode, setValue]);

  // Submit
  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    (values) => {
      const {
        companyId,
        organizationId,
        organizationEntityType,
        gender,
        healthQuizSlug,
        healthQuizName,
        preferredNotificationMethod,

      } = values;
      submitForm({
        companyId,
        organizationId,
        organizationEntityType,
        gender,
        healthQuizSlug,
        healthQuizName,
        preferredNotificationMethod,
      });
    },
    [submitForm]
  );

  const quizOptions = useMemo(() => {
    if (quizes.isLoading) {
      return [];
    }

    const options = quizes.data?.map((quiz) => ({
      value: quiz.quizSlug,
      name: quiz.quizDefinition.healthCategory?.title?.[language?.languageCode] ?? quiz.quizDefinition.healthCategory?.title?.en ?? '',
    })) || [];

    return [
      {
        value: '',
        name: intl.formatMessage({
          id: 'allHealthQuizzesOption',
          defaultMessage: 'All health quizzes',
          description: 'All health quizzes option label',
        }),
      },
      ...options,
    ];
  }, [quizes.isLoading, quizes.data, language?.languageCode, intl]);

  const organizationEntityOptions = useOrganizationEntityOptions(companyId);
  const { entityOptions, entityTypeOptions } = useEntityOptions(entityType, organizationEntityOptions);


  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <Fields>
          <SelectField
            label={intl.formatMessage({
              id: 'organizationEntityTypeLabel',
              defaultMessage: 'Test recipients',
              description: 'Label for the organization entity select field',
            })}
            options={entityTypeOptions}
            register={register('organizationEntityType')}
          />
          {entityOptions != null && (
            <>
              <SelectField
                label={intl.formatMessage({
                  id: 'organizationEntityOptionLabel',
                  defaultMessage: 'Test recipients entity',
                  description:
                    'Label for the organization entity option select field',
                })}
                placeholder={intl.formatMessage({
                  id: 'organizationEntityOptionPlaceholder',
                  defaultMessage: 'Select entity',
                  description:
                    'Placeholder for the organization entity option select field',
                })}
                options={entityOptions}
                register={register('organizationId')}
                error={errors.organizationId}
              />
            </>
          )}
          <SelectField
            label={intl.formatMessage({
              id: 'inputGenderLabel',
              defaultMessage: 'Gender',
              description: 'Label for gender input',
            })}
            register={register('gender', {
              required: intl.formatMessage({
                id: 'inputErrorFieldRequired',
                defaultMessage: 'Field is is required',
                description: 'Input error for field required',
              }),
            })}
            options={genders}
            error={errors.gender}
          />
          <SelectField
            label={intl.formatMessage({
              id: 'menuTests',
              defaultMessage: 'Quiz',
              description: 'Label for quiz input',
            })}
            register={register('healthQuizSlug')}
            options={quizOptions}
            error={errors.healthQuizSlug}
          />
        </Fields>
        <SelectItem onClick={() => setSelectedNotifyOption('All')}>
          <RadioButton name="custom-title" isChecked={selectedNotifyOption === 'All'} />
          <span>
            <FormattedMessage
              id="NotificationAppAndEmailDispatchOption"
              defaultMessage="Wellr notis och email"
              description="Radio button label enabling/disabling custom competition title"
            />
          </span>
        </SelectItem>
        <SelectItem onClick={() => setSelectedNotifyOption('Phone')}>
          <RadioButton name="custom-title" isChecked={selectedNotifyOption === 'Phone'} />
          <span>
            <FormattedMessage
              id="NotificationOnlyAppDispatchOption"
              defaultMessage="Wellr notis"
              description="Radio button label enabling/disabling custom competition title"
            />
          </span>
        </SelectItem>
      </div>
      <ActionContainer>
        <Button type="submit" background="blue">
          <FormattedMessage
            id="sendPulseQuizReminderButtonLabel"
            defaultMessage="Send reminder"
            description="Send pulse quiz reminder button label"
          />
        </Button>
      </ActionContainer>
    </Form>
  );
};

export default SendQuizReminderForm;
