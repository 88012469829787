import { FC, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ContentImage, Reference, ReferenceType } from 'models';
import { getRefLink } from 'utils/link';
import { ChallengeType } from 'models/timedChallengeResult/timedChallengeResult';
import { useCreateMyTimedChallengeResultMutation, useGetIsMyDailyChallengeCompletedQuery } from 'store/timedChallengeService/timedChallengeResultService';

import TimedChallengeCard from 'components/UI/Cards/TimedChallengeCard';
import { useLocalStorage } from 'hooks/useLocalStorage';

type Props = {
  id: string;
  title: string;
  wellrPoints: number;
  challengetType: ChallengeType;
  description?: string;
  reference?: Reference;
  progress?: number;
  amountToComplete?: number;
  membershipId: string;
  userId: string;
  image?: ContentImage | null;
};

const ChallengeCard: FC<Props> = ({
  title,
  id,
  challengetType,
  wellrPoints,
  description,
  reference,
  progress,
  amountToComplete,
  membershipId,
  image,
  userId
}) => {

  // Hooks
  const navigate = useNavigate();
  const [createMyResult] = useCreateMyTimedChallengeResultMutation();
  const [, setUnlocked] = useLocalStorage<boolean>('unlocked', false);
  const { data: isMyDailyChallengeCompletedQuery, refetch } = useGetIsMyDailyChallengeCompletedQuery({ timedChallengeId: id }, {
    refetchOnMountOrArgChange: true,
  });

  const isActivity = challengetType === ChallengeType.Activity;

  // Function to handle result creation
  const handleCreateMyResult = useCallback(async () => {
    if (progress && progress >= 100 && !isMyDailyChallengeCompletedQuery) {
      await createMyResult({
        wellrPoints: wellrPoints,
        timedChallengeId: id,
        userId: userId,
        membershipId: membershipId,
      });

      await setUnlocked(false);
    }
  }, [progress, isMyDailyChallengeCompletedQuery, createMyResult, wellrPoints, id, userId, membershipId, setUnlocked]);

  // Actions
  const onClickContent = useCallback(
    (type: ReferenceType, slug: string) => async () => {
      if (type && slug) {
        if (!isMyDailyChallengeCompletedQuery) {
          await setUnlocked(true);
        }
        navigate(getRefLink(type, slug));
      }
    },
    [isMyDailyChallengeCompletedQuery, navigate, setUnlocked]
  );

  const onClickComplete = useCallback(
    async () => {
      if (!isMyDailyChallengeCompletedQuery) {
        await createMyResult({
          wellrPoints: wellrPoints,
          timedChallengeId: id,
          userId: userId,
          membershipId: membershipId,
        });
      }
      await setUnlocked(false);
      await refetch();
    },
    [createMyResult, id, isMyDailyChallengeCompletedQuery, membershipId, refetch, setUnlocked, userId, wellrPoints]
  );

  // Effect to handle result creation on progress change
  useEffect(() => {
    handleCreateMyResult();
  }, [handleCreateMyResult]);

  return (
    <>
      {isMyDailyChallengeCompletedQuery !== undefined && (
        <TimedChallengeCard
          image={image ?? reference?.image}
          title={title}
          wellrPoints={wellrPoints}
          challengetType={challengetType}
          description={description}
          reference={reference}
          progress={progress}
          contentGoal={!isActivity}
          amountToComplete={amountToComplete}
          goalCompleted={isMyDailyChallengeCompletedQuery ?? false}
          onClick={onClickContent}
          onClickComplete={onClickComplete}
        />
      )}
    </>
  );
};

export default ChallengeCard;