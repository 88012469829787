import React, { FC, Fragment, useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { useCreateContestTeamAdminMutation } from 'store';

import Loader from 'components/UI/Loader';
import Icon, { IconType } from 'components/UI/Icon';
import IconButton from 'components/UI/IconButton';
import Heading, { Tag } from 'components/UI/Heading';
import SlideoutModal from 'components/UI/SlideoutModal';
import CreateTeamForm from 'components/forms/CreateTeamForm';

import { Header, Body } from './styles';

type Props = {
  contestId: string;
  isOpen: boolean;
  onClose: () => void;
};

const CreateTeamAdminModal: FC<Props> = ({ contestId, isOpen, onClose }) => {
  // Hooks
  const [createTeam, createResult] = useCreateContestTeamAdminMutation();

  // Success
  useEffect(() => {
    if (createResult.isSuccess) {
      onClose();
    }
  }, [createResult, onClose]);

  // Actions
  const onCreate = useCallback(
    async (teamName: string) => {
      if (contestId) {
        await createTeam({ contestId, teamName });
      }
    },
    [createTeam, contestId]
  );

  // Content
  const content = useMemo(() => {
    if (createResult.isLoading) {
      return <Loader padding color="blue" />;
    }
    return (
      <Fragment>
        <p>
          <FormattedMessage
            id="createCompetitionTeamModalDescription"
            defaultMessage="Enter a team name in order to create your own team."
            description="Create competition team modal description"
          />
        </p>
        <CreateTeamForm submitForm={onCreate} />
      </Fragment>
    );
  }, [createResult, onCreate]);

  return (
    <SlideoutModal isOpen={isOpen} onClose={onClose}>
      <div>
        <Header>
          <Heading tag={Tag.H3}>
            <FormattedMessage
              id="createCompetitionTeamModalTitle"
              defaultMessage="Create a team"
              description="Create competition team modal title"
            />
          </Heading>
          <IconButton onClick={onClose} padding>
            <Icon type={IconType.Close} />
          </IconButton>
        </Header>
        <Body>{content}</Body>
      </div>
    </SlideoutModal>
  );
};

export default CreateTeamAdminModal;