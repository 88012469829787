import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 8px 16px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};

  h3 {
    margin: 0;
  }
`;

export const Body = styled.div`
  height: calc(100vh - 112px);
  overflow-y: auto;
  padding: 20px;
`;

export const CardList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
`;

export const CardGrid = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CardCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 16px;

  h3 {
    margin: 0;
  }
`;

export const CardAction = styled.div`
  display: flex;
  align-items: center;
  margin: 16px;
`;

export const CardActionEmpty = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.grayText};
`;

export const ErrorMessage = styled.p`
  font-size: 14px;
  margin-top: 8px;
  text-align: left;
  margin: 0;
  color: ${({ theme }) => theme.colors.error};
`;
