import { FC, ReactNode, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import Heading, { Tag } from 'components/UI/Heading';
import StaticCard from 'components/UI/Cards/StaticCard';
import Icon, { IconType } from 'components/UI/Icon';

import { MediumGap, ThemeColor } from 'styles';
import { useLocalizedText } from 'hooks/useLocalizedText';
import { ContentSlug, ContentText, HealthCategory } from 'models';

import {
  Grid,
  Column,
  Header,
  HeaderCol,
  DisabledTitle,
  Progress,
  TestData,
} from './styles';
import { useNavigate } from 'react-router-dom';
import { getCategoryIcon } from 'utils/asset';
import { useGetHealthQuizInfoQuery } from 'store';
import { usePretendCompany } from 'hooks/usePretendCompany';
import { useUserCompanyId } from 'hooks/useUserCompanyId';
import QuizReferenceCard from '../QuizReferenceCard';
import HeadingWithInfo from 'components/UI/HeadingWithInfo';
import { HealthCardChartWrap } from 'pages/AdminHealthReport/styles';
import HealthBarChart from 'components/fragments/HealthBarChart';
import { GetFilteredHealthquizResultsType } from 'store/adminService/endpointDefinitions';
import ProgressBarWithAverage from 'components/UI/ProgressBarWithAverage';
import Loader from 'components/UI/Loader';
import { components } from 'generated/api';
import useQuizBaselineData from 'hooks/useQuizBaselineData';

type TestStatus = {
  text: string;
  color: ThemeColor | string;
};

type Props = {
  slug: ContentSlug | undefined | components['schemas']['Slug'];
  category?: HealthCategory | null | components['schemas']['HealthCategoryDto'];
  results: Pick<
    | GetFilteredHealthquizResultsType['result'][number]['averageResult']
    | components['schemas']['QuizStatisticsResultDisplayDto'],
    | 'scorePercentageAverage'
    | 'usersCompleted'
    | 'usersTotal'
    | 'usersCompletedQuizPercentage'
    | 'averageAssessment'
    | 'resultTitle'
    | 'age'
    | 'gender'
    | 'role'
  >;
  children?: ReactNode;
  display: {
    title?: ContentText | null | components['schemas']['Languages'];
    color?: { hex: string } | components['schemas']['Color'];
  };
  date?: string;
  link?: string;
  onClickInfo?: (e: React.MouseEvent<HTMLDivElement>) => void;
  showChart?: boolean;
  useFilteredBaseline?: boolean;
  showBaseline?: boolean;
};

const AdminDetailedHealthCard: FC<Props> = ({
  slug,
  category,
  results,
  date,
  link,
  display,
  onClickInfo,
  showChart = true,
  useFilteredBaseline = true,
  showBaseline = true,
}) => {
  const getText = useLocalizedText();
  const {
    scorePercentageAverage,
    usersCompleted,
    usersTotal,
    usersCompletedQuizPercentage,
    averageAssessment,
    resultTitle,
  } = results;

  const { baselineChartData, baselinePercentage, isLoading } =
    useQuizBaselineData(
      showBaseline ? slug?.current ?? undefined : undefined,
      results,
      useFilteredBaseline
    );

  const navigate = useNavigate();

  const pretendCompanyId = usePretendCompany();
  const userCompanyId = useUserCompanyId();

  const { quizInfo } = useGetHealthQuizInfoQuery(
    {
      companyId: (pretendCompanyId || userCompanyId) as string,
    },
    {
      selectFromResult: (response) => ({
        quizInfo: response.data?.find((q) => q.quizSlug === slug?.current),
      }),
    }
  );

  const quizInfoReference = useMemo(() => {
    if (
      baselinePercentage == null ||
      scorePercentageAverage == null ||
      quizInfo == null
    ) {
      return null;
    }
    /*
    return {
      slug: 'monkey',
      healthQuizReferenceType: 'external' as const,
    }; */
    if (baselinePercentage > scorePercentageAverage) {
      return quizInfo.referenceFromQuiz;
    }
  }, [baselinePercentage, quizInfo, scorePercentageAverage]);

  // Get status
  const { color, text } = useMemo<TestStatus>(() => {
    return {
      text: getText(display.title),
      color: display.color?.hex ?? '',
    };
  }, [display, getText]);

  // Category
  const healthCategory = useMemo(() => {
    if (!category) {
      return null;
    }
    return <DisabledTitle>{getText(category.title)}</DisabledTitle>;
  }, [category, getText]);

  const categoryIcon = useMemo(
    () => (category?.icon ? getCategoryIcon(category.icon, false) : null),
    [category?.icon]
  );

  return (
    <StaticCard>
      <Grid
        clickable={link != null}
        onClick={link ? () => navigate(link) : undefined}
      >
        <Column>
          <Header>
            <HeaderCol>
              {categoryIcon && (
                <img src={categoryIcon.src} alt={categoryIcon.alt} />
              )}
              {healthCategory}
            </HeaderCol>
            {link && <Icon type={IconType.Arrow} />}
          </Header>
          {date && <i>{date}</i>}
          <TestData>
            <FormattedMessage
              id="adminHealthCardUsersTested"
              defaultMessage="Number of users completed: {usersCompleted} of {usersTotal} ({usersCompletedQuizPercentage}%)"
              description="Last tested date for health card"
              values={{
                usersTotal,
                usersCompleted,
                usersCompletedQuizPercentage,
              }}
            />
          </TestData>
          <Progress>
            <Header>
              <Heading tag={Tag.H3}>{text}</Heading>
              <span>{scorePercentageAverage || 0}%</span>
            </Header>
            <ProgressBarWithAverage
              progress={scorePercentageAverage ?? 0}
              color={color}
              average={showBaseline ? baselinePercentage : null}
              averageTitle={`Wellr's genomsnittsresultat = ${baselinePercentage}%`}
            />
            {showBaseline && (
              <div
                style={{ width: '100%', maxWidth: '305px', marginTop: '15px' }}
              >
                {quizInfoReference?.healthQuizReferenceType != null &&
                  quizInfoReference?.slug != null && (
                    <QuizReferenceCard
                      referenceFromQuiz={{
                        slug: quizInfoReference.slug,
                        healthQuizReferenceType:
                          quizInfoReference.healthQuizReferenceType,
                      }}
                    />
                  )}
              </div>
            )}
            {isLoading && <Loader color="blue" size={24} />}
            {showChart && resultTitle != null && !isLoading && (
              <>
                <MediumGap />
                <HeadingWithInfo
                  tag={Tag.H5}
                  onClickInfo={(e) => {
                    e.stopPropagation();
                    onClickInfo?.(e);
                  }}
                >
                  <FormattedMessage
                    id="adminHealthReportUsersInHealthCategoriesTitle"
                    defaultMessage="Users in health categories"
                    description="Chart title for users in health categories on admin health report page"
                  />
                </HeadingWithInfo>
                <HealthCardChartWrap>
                  <HealthBarChart
                    data={
                      averageAssessment?.map((bar) => ({
                        color: bar.color,
                        percentage: bar.percentage,
                        resultTitle: bar.title,
                      })) ?? []
                    }
                    baselineData={showBaseline ? baselineChartData : undefined}
                  />
                </HealthCardChartWrap>
              </>
            )}
          </Progress>
        </Column>
      </Grid>
    </StaticCard>
  );
};

export default AdminDetailedHealthCard;
