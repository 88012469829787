import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { ActionContainer } from 'styles';
import { localizationSelector, useGetGdprQuery } from 'store';
import { useAppSelector } from 'hooks/redux';
import { useLocalizedText } from 'hooks/useLocalizedText';

import RichText from 'components/fragments/RichText';
import BackgroundCover from 'components/UI/BackgroundCover';
import Icon, { IconType } from 'components/UI/Icon';
import IconButton from 'components/UI/IconButton';
import Heading, { Tag } from 'components/UI/Heading';
import Modal from 'components/UI/Modal';
import Button from 'components/UI/Button';

import { Header, Body, Content } from './styles';

type Props = {
  isOpen: boolean;
  onDeny: () => void;
  onClose: () => void;
  migratingUser?: boolean;
};

const GdprModal: FC<Props> = ({
  isOpen,
  onClose,
  onDeny,
  migratingUser = false,
}) => {
  const getText = useLocalizedText();
  const { language } = useAppSelector(localizationSelector);

  // Hooks
  const { data } = useGetGdprQuery({
    language: language?.languageCode,
    migratingUser,
  });

  // No data
  if (!data) {
    return null;
  }

  const [{ title, content }] = data;

  return (
    <Modal isOpen={isOpen}>
      <BackgroundCover>
        <Header>
          <Heading tag={Tag.H3}>
            <FormattedMessage
              id="gdprModalTitle"
              defaultMessage="Confirm GDPR"
              description="Gdpr modal title"
            />
          </Heading>
          <IconButton onClick={onClose} padding>
            <Icon type={IconType.Close} color="white" />
          </IconButton>
        </Header>
        <Body>
          <Content>
            <Heading>{getText(title)}</Heading>
            {content && <RichText>{content}</RichText>}
            <ActionContainer>
              <Button type="submit" color="black" background="yellow">
                <FormattedMessage
                  id="acceptButton"
                  defaultMessage="Accept"
                  description="Accept button text"
                />
              </Button>
              <Button color="white" background="transparent" onClick={onDeny}>
                <FormattedMessage
                  id="denyButton"
                  defaultMessage="Deny"
                  description="Deny button text"
                />
              </Button>
            </ActionContainer>
          </Content>
        </Body>
      </BackgroundCover>
    </Modal>
  );
};

export default GdprModal;
