import React, { FC, useCallback, useEffect, useState } from 'react';

import { getChatAttachment } from 'services';
import Loader from 'components/UI/Loader';

import { MessageImage } from './styles';
import { useAppSelector } from 'hooks/redux';
import { authSelector } from 'store';

type Props = {
  attachmentId: string;
  chatEntryId: string;
  alt: string;
};

const ChatMessageItemImage: FC<Props> = ({
  attachmentId,
  chatEntryId,
  alt,
}) => {
  const { accessToken } = useAppSelector(authSelector);

  // State
  const [src, setSrc] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Fetch attachment
  const fetchAsset = useCallback(async () => {
    setIsLoading(true);
    try {
      const src = await getChatAttachment(
        attachmentId,
        chatEntryId,
        accessToken
      );
      setSrc(src);
    } catch {
      setSrc(null);
    }
    setIsLoading(false);
  }, [attachmentId, chatEntryId, accessToken]);

  // Fetch asset image
  useEffect(() => {
    if (attachmentId) {
      fetchAsset();
    }
  }, [fetchAsset, attachmentId]);

  if (isLoading) {
    return <Loader color="white" size={24} />;
  }

  if (!src) {
    return null;
  }

  return <MessageImage src={src} alt={alt} />;
};

export default ChatMessageItemImage;
