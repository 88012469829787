import { FC, useCallback, useMemo, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import * as routes from 'router/routes';
import { BreakfastLunchDinner, ContentText, DietContext } from 'models';
import { useLocalizedText } from 'hooks/useLocalizedText';
import { firstChars } from 'utils/string';

import Icon, { IconType } from 'components/UI/Icon';
import ExpandableCard from 'components/UI/Cards/ExpandableCard';
import Button from 'components/UI/Button';

import {
  CardRow,
  DayContainer,
  Content,
  Text,
  CardContainer,
  CheckboxContainer,
} from './styles';
import CardCheckbox from 'components/UI/CardCheckbox';
import {
  useCompleteDietRecipeMutation,
  useGetCompletedDietRecipesQuery,
  useUncompleteDietRecipeMutation,
} from 'store';

enum Type {
  Breakfast,
  Lunch,
  Dinner,
}

type Props = {
  id: string;
  weekKey: string;
  title: ContentText;
  days: BreakfastLunchDinner[];
};

const DietPlanDay: FC<Props> = ({ id, title, days, weekKey }) => {
  const navigate = useNavigate();
  const getText = useLocalizedText();

  const { slug } = useOutletContext<DietContext>();

  // State
  const [activeType, setActiveType] = useState<Type>(Type.Breakfast);

  // Check days have meal
  const { hasBreakfast, hasLunch, hasDinner } = useMemo(
    () => ({
      hasBreakfast: days.some((day) => day.breakfast),
      hasLunch: days.some((day) => day.lunch),
      hasDinner: days.some((day) => day.dinner),
    }),
    [days]
  );

  // Hooks
  const [completeDietRecipe] = useCompleteDietRecipeMutation();
  const [unCompleteDietRecipe] = useUncompleteDietRecipeMutation();
  const completedDietRecipes = useGetCompletedDietRecipesQuery({
    dietSlug: slug as string,
  });

  const completedDietRecipeIds = useMemo(() => {
    if (completedDietRecipes.data?.weeks == null) {
      return [];
    }
    const currentWeek = completedDietRecipes.data.weeks.find(
      (item) => item.key === weekKey
    );
    if (!currentWeek) {
      return [];
    }
    const keyToCheck =
      activeType === Type.Breakfast
        ? 'breakfastCompleted'
        : activeType === Type.Lunch
        ? 'lunchCompleted'
        : 'dinnerCompleted';
    const completedDays: string[] = [];
    currentWeek.days?.forEach((day) => {
      if (day[keyToCheck]) {
        completedDays.push(day.daySlug ?? '');
      }
    });
    return completedDays;
  }, [activeType, completedDietRecipes.data?.weeks, weekKey]);

  // Callbacks
  const getColor = useCallback(
    (type: Type) => (activeType === type ? 'white' : 'blue'),
    [activeType]
  );
  const getBackground = useCallback(
    (type: Type) => (activeType === type ? 'blue' : 'transparent'),
    [activeType]
  );
  const getBorder = useCallback(
    (type: Type) => (activeType === type ? 'white' : 'borderDark'),
    [activeType]
  );
  const getMeal = useCallback(
    ({ breakfast, lunch, dinner }: BreakfastLunchDinner) => {
      switch (activeType) {
        case Type.Breakfast:
          return breakfast;
        case Type.Lunch:
          return lunch;
        case Type.Dinner:
          return dinner;
      }
    },
    [activeType]
  );

  const onToggleRecipe = useCallback(
    (item: BreakfastLunchDinner, isChecked: boolean) => () => {
      (isChecked ? unCompleteDietRecipe : completeDietRecipe)({
        dietSlug: slug as string,
        weekKey: weekKey as string,
        daySlug: item.day.slug.current,
        meal:
          activeType === Type.Breakfast
            ? 'breakfast'
            : activeType === Type.Lunch
            ? 'lunch'
            : 'dinner',
      });
    },
    [activeType, completeDietRecipe, slug, unCompleteDietRecipe, weekKey]
  );

  return (
    <ExpandableCard id={id} title={getText(title)}>
      <CardRow divider>
        {hasBreakfast && (
          <Button
            color={getColor(Type.Breakfast)}
            background={getBackground(Type.Breakfast)}
            border={getBorder(Type.Breakfast)}
            onClick={() => setActiveType(Type.Breakfast)}
            size="small"
            marginRight
          >
            <FormattedMessage
              id="tabDietPlanBreakfastButton"
              defaultMessage="Breakfast"
              description="Breakfast button diet plan"
            />
          </Button>
        )}
        {hasLunch && (
          <Button
            color={getColor(Type.Lunch)}
            background={getBackground(Type.Lunch)}
            border={getBorder(Type.Lunch)}
            onClick={() => setActiveType(Type.Lunch)}
            size="small"
            marginRight
          >
            <FormattedMessage
              id="tabDietPlanLunchButton"
              defaultMessage="Lunch"
              description="Lunch button diet plan"
            />
          </Button>
        )}
        {hasDinner && (
          <Button
            color={getColor(Type.Dinner)}
            background={getBackground(Type.Dinner)}
            border={getBorder(Type.Dinner)}
            onClick={() => setActiveType(Type.Dinner)}
            size="small"
          >
            <FormattedMessage
              id="tabDietPlanDinnerButton"
              defaultMessage="Dinner"
              description="Dinner button diet plan"
            />
          </Button>
        )}
      </CardRow>
      {days.map((item, i) => {
        const meal = getMeal(item);
        if (!meal) {
          return null;
        }
        const isChecked = completedDietRecipeIds.includes(
          item.day.slug.current
        );
        return (
          <CardRow key={i} divider={days.length !== i + 1}>
            <CardContainer>
              <CheckboxContainer onClick={onToggleRecipe(item, isChecked)}>
                <CardCheckbox
                  name={`checkbox_${meal.slug}`}
                  isChecked={isChecked}
                />
              </CheckboxContainer>
              <Content
                onClick={() =>
                  navigate(`${routes.RECIPE}/${meal.slug.current}`)
                }
              >
                <DayContainer>
                  {firstChars(getText(item.day.title).trim(), 3)}
                </DayContainer>
                <Text>{getText(meal.title)}</Text>
                <Icon type={IconType.Arrow} color="black" />
              </Content>
            </CardContainer>
          </CardRow>
        );
      })}
    </ExpandableCard>
  );
};

export default DietPlanDay;
