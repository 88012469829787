import React, { FC, useEffect, Fragment, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ContentWidth, Gap } from 'styles';
import { useAppDispatch } from 'hooks/redux';
import {
  setLayoutHeader,
  useDeleteDepartmentMutation,
  useGetCompanyDepartmentQuery,
  useUpdateDepartmentMutation,
} from 'store';

import Heading, { Tag } from 'components/UI/Heading';
import Loader from 'components/UI/Loader';

import * as routes from 'router/routes';

import Icon, { IconType } from 'components/UI/Icon';

import { useNavigate, useParams } from 'react-router-dom';
import Table from 'components/UI/Table/Table';
import { formatReadableDateTime } from 'utils/date';
import CreateDepartmentForm from 'components/forms/CreateUpdateDepartmentForm';
import { toast } from 'react-hot-toast';
import { usePretendCompany } from 'hooks/usePretendCompany';
import IconButton from 'components/UI/IconButton';
import ConfirmActionPopup from 'components/popups/ConfirmActionPopup';
import useModal from 'hooks/useModal';
import { SmallGap } from 'components/forms/CompanyResultFilter/styles';

const AdminDepartmentView: FC = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { departmentId } = useParams();
  const companyId = usePretendCompany();

  // Hooks
  const navigate = useNavigate();
  const { data, isLoading } = useGetCompanyDepartmentQuery(
    {
      departmentId: departmentId as string,
      companyId,
    },
    { skip: !departmentId }
  );
  const [
    updateDepartment,
    { isLoading: isUpdating, isSuccess: updateSuccess },
  ] = useUpdateDepartmentMutation();
  const [deleteDepartment] = useDeleteDepartmentMutation();

  useEffect(() => {
    if (updateSuccess) {
      toast.success(
        intl.formatMessage({
          id: 'departmentUpdatedSuccessMessage',
          defaultMessage: 'Department successfully updated',
          description:
            'Toast message displayed when department is successfully updated',
        })
      );
    }
  }, [updateSuccess, intl]);

  // Set header
  useEffect(() => {
    dispatch(
      setLayoutHeader({
        title: intl.formatMessage({
          id: 'pageAdminOrganisationTitle',
          defaultMessage: 'Organisation',
          description: 'Page title for admin organisation page',
        }),
        companyInHeader: true,
        inverted: false,
        icon: IconType.Back,
        link: routes.ADMIN_ORGANISATION,
      })
    );
  }, [dispatch, intl]);

  const onDeleteDepartment = useCallback(async () => {
    deleteDepartment({ departmentId: departmentId as string, companyId });
    navigate(routes.ADMIN_ORGANISATION);
  }, [deleteDepartment, departmentId, companyId, navigate]);

  const {
    modalOpen: confirmDeleteOpen,
    onCloseModal: onCloseConfirmDelete,
    onOpenModal: onOpenConfirmDelete,
  } = useModal();

  // Loading
  if (isLoading || isUpdating) {
    return <Loader color="blue" padding />;
  }

  // No data
  if (!data || !departmentId) {
    return null;
  }

  let hasUnregisteredUser = false;

  const tableData =
    data.memberships?.filter(membership => membership.user.firstName?.toLowerCase() !== 'anonymous' && membership.user.lastName?.toLowerCase() !== 'anonymous').map((membership) => {
      const isUnregistered = !membership.user.firstName || !membership.user.lastName;
      if (isUnregistered) {
        hasUnregisteredUser = true;
      }

      return {
        data: membership,
        id: membership.id,
        name: isUnregistered
          ? intl.formatMessage({
            id: 'adminUnregisteredUserNameValue',
            description: 'Name for unregistered user',
            defaultMessage: 'Not Registered',
          })
          : `${membership.user.firstName} ${membership.user.lastName}`,
        email: membership.user ? membership.user.email : '-',
        lastActivity: membership.lastActivity
          ? formatReadableDateTime(new Date(membership.lastActivity))
          : '-',
        points: membership.points,
      };
    }) ?? [];
  return (
    <Fragment>
      <ContentWidth isSurface>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Heading tag={Tag.H2}>
            <FormattedMessage
              id="pageDepartmentViewTitle"
              defaultMessage="Manage department"
              description="Page heading for view/edit department page"
            />
          </Heading>
          <IconButton onClick={onOpenConfirmDelete}>
            <Icon type={IconType.Trash} size={28} color="error" />
          </IconButton>
          <ConfirmActionPopup
            isOpen={confirmDeleteOpen}
            onConfirm={onDeleteDepartment}
            onAbort={onCloseConfirmDelete}
          >
            <>
              <Heading tag={Tag.H4}>
                <FormattedMessage
                  id="deleteDepartmentConfirmHeading"
                  defaultMessage="Delete department"
                  description="Heading text in the delete department popup"
                />
              </Heading>
              <FormattedMessage
                id="deleteDepartmentConfirmBody"
                defaultMessage="Are you sure you want to delete this department?"
                description="Body text in the delete department popup"
              />
            </>
          </ConfirmActionPopup>
        </div>
        <CreateDepartmentForm
          submitForm={(formData) =>
            updateDepartment({
              departmentId: departmentId,
              description: formData.departmentName,
              officeId: formData.office === '' ? null : formData.office,
              companyId,
            })
          }
          department={{
            id: departmentId,
            description: data.description,
            office: data.officeId,
          }}
        />
        <Gap />
        <Heading tag={Tag.H4}>
          <FormattedMessage
            id="tableDepartmentMembersTitle"
            defaultMessage="Department members"
            description="Table heading for table listing department members"
          />
        </Heading>
        {hasUnregisteredUser &&
          <i style={{ fontSize: '0.8rem' }}>
            <FormattedMessage
              id="tableDepartmentMembersUnregisteredMessage"
              defaultMessage="Unregistered users have not confirmed the invitation email."
              description="Table message for unregistered department members"
            />
          </i>
        }
        <SmallGap />
        <Table
          columns={[
            {
              key: 'name',
              title: intl.formatMessage({
                id: 'tableColumnHeaderMembershipName',
                description: 'Table column header for member full name',
                defaultMessage: 'Name',
              }),
            },
            {
              key: 'email',
              title: intl.formatMessage({
                id: 'tableColumnHeaderMembershipEmail',
                description: 'Table column header for member email',
                defaultMessage: 'Email',
              }),
            },
            {
              key: 'points',
              title: intl.formatMessage({
                id: 'tableColumnHeaderPoints',
                description: 'Table column header for member wellr points',
                defaultMessage: 'Wellr Points',
              }),
            },
          ]}
          data={tableData}
          onRowClick={(row) => {
            if (row.data.user.userId) {
              navigate(
                `${routes.ADMIN_ORGANISATION}/user/${row.data.user.userId}`
              );
            }
          }}
        />
      </ContentWidth>
    </Fragment>
  );
};

export default AdminDepartmentView;
