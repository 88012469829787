import { useEffect, useState } from 'react';
import { breakpoints } from 'styles';
import { useWindowSize } from './useWindowSize';

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);
  const { width } = useWindowSize();

  useEffect(() => {
    setIsMobile(width <= breakpoints.l);
  }, [width]);

  return isMobile;
};

export default useIsMobile;
