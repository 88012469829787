import { FC, ReactNode } from 'react';
import { ThemeColor } from 'styles';

import Heading, { Tag } from 'components/UI/Heading';

import {
  Card,
  Content,
  ContentHeader,
  ContentBody,
} from './styles';

type Props = {
  title?: string;
  children: ReactNode;
  background?: ThemeColor;
  iconColor?: ThemeColor;
  textColor?: ThemeColor;
};

const ReviewCard: FC<Props> = ({
  children,
  title,
  background = 'blue',
  iconColor = 'blueDark',
  textColor = 'white',
}) => {
  return (
    <Card background={background}>
      <Content textColor={textColor}>
        <ContentHeader>
          <Heading tag={Tag.H4}>{title}</Heading>
        </ContentHeader>
        <ContentBody>
          <div style={{
            display: 'inline-block',
            whiteSpace: 'pre-wrap'
          }}>
            {children}
          </div>
        </ContentBody>
      </Content>
    </Card>
  );
};

export default ReviewCard;
