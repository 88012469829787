import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import { SelectOption } from 'models';
import { getDateTime } from 'utils/date';
import { ActionContainer } from 'styles';
import { localizationSelector, useGetArticleCategoryListQuery } from 'store';

import Button from 'components/UI/Button/Button';
import TextField from 'components/UI/TextField/TextField';
import SelectField from 'components/UI/SelectField';

import { Fields, Form } from './styles';
import { SelectItem } from '../ChallengeForm/styles';
import RadioButton from 'components/UI/RadioButton';
import { useIntervalOptions } from 'hooks/useInterval';
import { useGetArticleCategoryQuery } from 'store';
import { useAppSelector } from 'hooks/redux';
import { NotificationScheduleOrganizationIn } from 'models/notificationSchedule/notificationSchedule';
import useOrganizationEntityOptions, { EntityType } from 'hooks/useOrganizationEntityOptions';
import { usePretendCompany } from 'hooks/usePretendCompany';

type Props = {
  submitForm: (data: NotificationScheduleOrganizationIn) => void;
  isOpen: boolean;
  companyId: string;
  redirectUrl: string;
  date: Date;
};


const RegisterContentOrganizationForm: FC<Props> = ({ date, submitForm, isOpen, companyId, redirectUrl }) => {
  const intl = useIntl();
  const [startDate, setStartDate] = useState<string>(getDateTime(new Date(date.getTime() + 1000 * 60 * 5)));
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      companyId: companyId,
      organizationId: undefined,
      start: startDate,
      stop: getDateTime(),
      repeatInterval: 'None',
      contentSlug: '',
      preferredNotificationMethod: 'All',
      redirectLink: redirectUrl,
      categorySlug: '',
      entityType: EntityType.Company,
    },
  });

  // States
  const [selectedNotifyOption, setSelectedNotifyOption] = useState('All');

  // Watch
  const contentSlug: string = watch('contentSlug');
  const selectedCategorySlug = watch('categorySlug');
  const entityType = watch('entityType');
  const intervalOption = watch('repeatInterval');

  useEffect(() => {
    setValue('start', startDate);
    const stopDate = new Date(startDate);

    if (intervalOption === 'Daily') {
      stopDate.setDate(date.getDate() + 7);
      setValue('stop', getDateTime(stopDate));
    } else if (intervalOption === 'Weekly') {
      stopDate.setDate(stopDate.getDate() + 4 * 7);
      setValue('stop', getDateTime(stopDate));
    } else if (intervalOption === 'Monthly') {
      stopDate.setMonth(stopDate.getMonth() + 3);
      setValue('stop', getDateTime(stopDate));
    } else {
      setValue('start', startDate);
    }
  }, [date, intervalOption, setValue, startDate]);
  useEffect(() => {
    setValue('preferredNotificationMethod', selectedNotifyOption);
  }, [selectedNotifyOption]);

  // Hooks
  const pretendCompanyId = usePretendCompany();
  const intervalOptions = useIntervalOptions();
  const { language } = useAppSelector(localizationSelector);
  const { data: categoryData } = useGetArticleCategoryListQuery({
    language: language?.languageCode,
  });

  const { data: articlesInCategory } = useGetArticleCategoryQuery({
    language: language?.languageCode,
    slug: selectedCategorySlug,
  });

  // Set first article when category changes
  useEffect(() => {
    if (selectedCategorySlug && articlesInCategory) {
      const firstArticle = articlesInCategory.articles?.[0];
      if (firstArticle) {
        setValue('contentSlug', firstArticle.slug.current);
      }
    }
  }, [selectedCategorySlug, articlesInCategory]);


  // Submit
  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    (values) => {
      const {
        companyId,
        organizationId,
        start,
        stop,
        repeatInterval,
        contentSlug,
        preferredNotificationMethod,
        redirectLink,
      } = values;
      const toUTCString = (localDatetime: string | number | Date) => {
        const date = new Date(localDatetime);
        return date.toISOString();
      };

      const startUTC = toUTCString(start);
      let stopUTC = toUTCString(stop);

      if (repeatInterval === 'None') {
        const startDate = new Date(start);
        const stopDate = new Date(startDate);
        stopDate.setDate(startDate.getDate() + 1);
        stopUTC = toUTCString(stopDate);
      }

      submitForm({
        companyId,
        organizationId,
        start: startUTC,
        stop: stopUTC,
        repeatInterval,
        contentSlug,
        preferredNotificationMethod,
        redirectLink,
      });
    },
    [submitForm]
  );

  // Activity options
  const articleOptions: SelectOption[] = useMemo(() => {
    if (articlesInCategory !== undefined)
      return articlesInCategory.articles.map((item) => ({
        value: item.slug.current,
        name: item.title['sv'],
      }));
    return [];
  }, [articlesInCategory]);

  const categoryOptions: SelectOption[] = useMemo(() => {
    if (categoryData !== undefined && categoryData[0]?.title !== undefined)
      return categoryData
        .slice()
        .sort((a, b) => (a.title!['sv'] || "").localeCompare(b.title!['sv'] || ""))
        .map((item) => ({
          value: item.slug.current,
          name: item.title!['sv'],
        }));
    return [];
  }, [categoryData]);

  useEffect(() => {
    setValue('organizationId', undefined);
  }, [entityType, setValue]);

  useEffect(() => {
    const currentContentSlug = articleOptions.find(
      ({ value }) => value === contentSlug
    );
    if (currentContentSlug === undefined) {
      setValue(
        'contentSlug',
        articleOptions.length > 0
          ? articleOptions[0].value.toString()
          : ''
      );
    } else {
      setValue('contentSlug', currentContentSlug.value.toString());
    }
  }, []);

  const organizationEntityOptions =
    useOrganizationEntityOptions(pretendCompanyId);
  const entityOptions = useMemo(() => {
    switch (entityType) {
      case EntityType.HeadOffice:
        return organizationEntityOptions.headOffices;
      case EntityType.Office:
        return organizationEntityOptions.offices;
      case EntityType.Department:
        return organizationEntityOptions.departments;
      default:
        return null;
    }
  }, [entityType, organizationEntityOptions]);

  const entityTypeOptions = useMemo(() => {
    return [
      {
        name: intl.formatMessage({
          id: 'everyoneInCompanyLabel',
          defaultMessage: 'Everyone in the company',
          description: 'Label for everyone option',
        }),
        value: EntityType.Company,
      },
      {
        name: intl.formatMessage({
          id: 'everyoneInHeadOfficeLabel',
          defaultMessage: 'Everyone within a specific head office',
          description: 'Label for everyone in head office option',
        }),
        value: EntityType.HeadOffice,
      },
      {
        name: intl.formatMessage({
          id: 'everyoneInOfficeLabel',
          defaultMessage: 'Everyone within a specific office',
          description: 'Label for everyone in office option',
        }),
        value: EntityType.Office,
      },
      {
        name: intl.formatMessage({
          id: 'everyoneInDepartmentLabel',
          defaultMessage: 'Everyone within a specific department',
          description: 'Label for everyone in department option',
        }),
        value: EntityType.Department,
      },
    ];
  }, [intl]);
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <Fields>
          <SelectField
            label={intl.formatMessage({
              id: 'NotificationSendingOptionsLabel',
              defaultMessage: 'Dispatch options',
              description: 'Unit text for activities',
            })}
            register={register('repeatInterval', {
              required: intl.formatMessage({
                id: 'inputErrorFieldRequired',
                defaultMessage: 'Field is is required',
                description: 'Input error for field required',
              }),
            })}
            options={intervalOptions}
            error={errors.contentSlug}
          />
        </Fields>
        <TextField
          type="datetime-local"
          label={intl.formatMessage({
            id: 'inputStartDateLabel',
            defaultMessage: 'Start date',
            description: 'Label for start date input'
          })}
          placeholder={intl.formatMessage({
            id: 'inputStartDateLabel',
            defaultMessage: 'Start date',
            description: 'Label for start date input'
          })}
          error={errors.start}
          register={register('start', {
            required: intl.formatMessage({
              id: 'inputErrorFieldRequired',
              defaultMessage: 'Field is is required',
              description: 'Input error for field required',
            }),
          })}
          defaultValue={startDate}
        />
        {intervalOption !== 'None' &&
          <TextField
            type="datetime-local"
            label={intl.formatMessage({
              id: 'inputStopDateLabel',
              defaultMessage: 'Stop date',
              description: 'Label for stop date input',
            })}
            placeholder={intl.formatMessage({
              id: 'inputStopDateLabel',
              defaultMessage: 'Stop date',
              description: 'Label for stop date input',
            })}
            error={errors.stop}
            register={register('stop', {
              required: intl.formatMessage({
                id: 'inputErrorFieldRequired',
                defaultMessage: 'Field is is required',
                description: 'Input error for field required',
              }),
            })}
          />
        }
        <Fields>
          <SelectField
            label={intl.formatMessage({
              id: 'organizationEntityTypeLabel',
              defaultMessage: 'Test recipients',
              description: 'Label for the organization entity select field',
            })}
            options={entityTypeOptions}
            register={register('entityType')}
          />
          {entityOptions != null && (
            <>
              <SelectField
                label={intl.formatMessage({
                  id: 'organizationEntityOptionLabel',
                  defaultMessage: 'Test recipients entity',
                  description:
                    'Label for the organization entity option select field',
                })}
                placeholder={intl.formatMessage({
                  id: 'organizationEntityOptionPlaceholder',
                  defaultMessage: 'Select entity',
                  description:
                    'Placeholder for the organization entity option select field',
                })}
                options={entityOptions}
                register={register('organizationId')}
                error={errors.organizationId}
              />
            </>
          )}
          <SelectField
            register={register('categorySlug', {
              required: intl.formatMessage({
                id: 'inputErrorFieldRequired',
                defaultMessage: 'Field is is required',
                description: 'Input error for field required',
              })
            },)}
            label={intl.formatMessage({
              id: 'inputCategoryLabel',
              defaultMessage: 'Categories',
              description: 'Label for category input',
            })}
            options={categoryOptions}
            defaultValue={''}
            error={errors.categorySlug}
          />
          <SelectField
            label={intl.formatMessage({
              id: 'inputArticleLabel',
              defaultMessage: 'Article',
              description: 'Label for activity input',
            })}
            register={register('contentSlug', {
              required: intl.formatMessage({
                id: 'inputErrorFieldRequired',
                defaultMessage: 'Field is is required',
                description: 'Input error for field required',
              }),
            })}
            options={articleOptions}
            error={errors.contentSlug}
          />
        </Fields>
        <SelectItem onClick={() => setSelectedNotifyOption('All')}>
          <RadioButton name="custom-title" isChecked={selectedNotifyOption === 'All'} />
          <span>
            <FormattedMessage
              id="NotificationAppAndEmailDispatchOption"
              defaultMessage="Wellr notis och email"
              description="Radio button label enabling/disabling custom competition title"
            />
          </span>
        </SelectItem>
        <SelectItem onClick={() => setSelectedNotifyOption('Phone')}>
          <RadioButton name="custom-title" isChecked={selectedNotifyOption === 'Phone'} />
          <span>
            <FormattedMessage
              id="NotificationOnlyAppDispatchOption"
              defaultMessage="Wellr notis"
              description="Radio button label enabling/disabling custom competition title"
            />
          </span>
        </SelectItem>
      </div>
      <ActionContainer>
        <Button type="submit" background="blue">
          <FormattedMessage
            id="registerActivityButton"
            defaultMessage="Register activity"
            description="Register activity button"
          />
        </Button>
      </ActionContainer>
    </Form>
  );
};

export default RegisterContentOrganizationForm;
