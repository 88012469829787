import React, { FC } from 'react';

import { CompletedGoal, ContestGoal } from 'models';
import { useGetAssetImage } from 'hooks/useGetAssetImage';

import Heading, { Tag } from 'components/UI/Heading';

import {
  Marker,
  TeamItem,
  TeamItemGrid,
  TeamItemHeader,
  ImgContainer,
  Img,
} from './styles';
import { isWithinInterval } from 'date-fns';
import Icon, { IconType } from 'components/UI/Icon';

type Props = {
  alias: string;
  email: string | null;
  profilePictureAssetId: string | null;
  goals: ContestGoal[];
  completedGoals: CompletedGoal[];
  completedCheckpoints: CompletedGoal[];
  isLast: boolean;
};

const TopListGoalItem: FC<Props> = ({
  alias,
  email,
  profilePictureAssetId,
  goals,
  completedGoals,
  completedCheckpoints,
  isLast,
}) => {
  // Hooks
  const [imageSrc] = useGetAssetImage(profilePictureAssetId);
  return (
    <TeamItem isLast={isLast}>
      <TeamItemHeader>
        <ImgContainer>
          {imageSrc ? (
            <Img src={imageSrc} alt={alias} />
          ) : (
            <Icon type={IconType.User} color="grayText" size={20} />
          )}
        </ImgContainer>
        <Heading tag={Tag.H4}>
          {alias}
          {email != null ? ` - ${email}` : ''}
        </Heading>
      </TeamItemHeader>
      <TeamItemGrid>
        {goals.map((goal) => {
          if (!goal.subGoals.length) {
            return (
              <Marker
                key={goal.id}
                isActive={completedGoals.some((item) => item.id === goal.id)}
                isCurrent={isWithinInterval(new Date(), {
                  start: new Date(goal.start),
                  end: new Date(goal.stop),
                })}
              />
            );
          }
          return goal.subGoals.map((subGoal) => (
            <Marker
              key={subGoal.id}
              isActive={completedCheckpoints.some(
                (item) => item.id === subGoal.id
              )}
              isCurrent={isWithinInterval(new Date(), {
                start: new Date(goal.start),
                end: new Date(goal.stop),
              })}
            />
          ));
        })}
      </TeamItemGrid>
    </TeamItem>
  );
};

export default TopListGoalItem;
