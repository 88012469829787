import React, { FC, Fragment, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { useLocalizedText } from 'hooks/useLocalizedText';
import { useCompleteGoalMutation } from 'store';
import { CompletedGoal, ContestGoal } from 'models';
import { Gap } from 'styles';

import Loader from 'components/UI/Loader';
import Button from 'components/UI/Button';
import StaticCard from 'components/UI/Cards/StaticCard';
import Heading, { Tag } from 'components/UI/Heading';

import { List, Item, ButtonGrid, Text, ButtonContainer } from './styles';

type Props = {
  goal: ContestGoal;
  contestId: string;
  completed: CompletedGoal[];
  isFetching: boolean;
};

const HealthGoals: FC<Props> = ({ goal, contestId, completed, isFetching }) => {
  const getText = useLocalizedText();

  // Hooks
  const [completeGoal, result] = useCompleteGoalMutation();

  // Complete
  const onComplete = useCallback(
    () => completeGoal({ contestId, goalId: goal.id }),
    [completeGoal, contestId, goal]
  );

  // Loading
  if (result.isLoading || isFetching) {
    return <Loader color="blue" padding />;
  }

  // Complete
  const isComplete = completed.some((item) => item.id === goal.id);

  if (!goal.healthChoices.length) {
    if (isComplete) {
      return null;
    }
    return (
      <Fragment>
        <Heading tag={Tag.H4}>{getText(goal.description)}</Heading>
        <ButtonContainer>
          <Button background="blue" onClick={onComplete}>
            <FormattedMessage
              id="doneButton"
              defaultMessage="Done"
              description="Done button text"
            />
          </Button>
        </ButtonContainer>
        <Gap />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Heading tag={Tag.H4}>{getText(goal.description)}</Heading>
      <StaticCard>
        <List>
          {goal.healthChoices.map((item, i) => (
            <Item
              key={`health-goal-item-${i + 1}`}
              isLast={goal.healthChoices.length === i + 1}
            >
              <Heading tag={Tag.H4}>{getText(item.title)}</Heading>
            </Item>
          ))}
        </List>
        <ButtonGrid>
          {isComplete ? (
            <Text>
              <FormattedMessage
                id="healthGoalCompleteText"
                defaultMessage="Well done! You have completed today's health goal."
                description="Complete text for todays health goals"
              />
            </Text>
          ) : (
            <Button background="blue" onClick={onComplete}>
              <FormattedMessage
                id="doneButton"
                defaultMessage="Done"
                description="Done button text"
              />
            </Button>
          )}
        </ButtonGrid>
      </StaticCard>
      <Gap />
    </Fragment>
  );
};

export default HealthGoals;
