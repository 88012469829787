import styled, { css } from 'styled-components';

type Props = {
  disabled?: boolean;
  error?: boolean;
  active?: boolean;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TextAreaBase = styled.textarea<Props>`
  border: 2px solid
    ${({ theme, error, active }) => {
      if (error) {
        return theme.colors.error;
      }
      if (active) {
        return theme.colors.blue;
      }
      return theme.colors.borderLight;
    }};
  padding: 16px;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 16px;
  border-radius: 8px;
  outline: none;
  -webkit-appearance: none;
  flex: 1;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;

export const Label = styled.label`
  font-size: 14px;
`;

export const InputMessage = styled.div`
  margin-bottom: 16px;
`;

const Message = css`
  font-size: 14px;
  margin-top: 8px;
  text-align: left;
  margin: 8px 0 0;
`;

export const ErrorMessage = styled.p<Props>`
  ${Message};
  color: ${({ theme }) => theme.colors.error};
`;

export const HelperText = styled.p`
  ${Message};
`;
