import { FC, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  useAddCompanyCommunicationSlugMutation,
  useAddUmbrellaCompanyCommunicationSlugMutation,
} from 'store';

import Loader from 'components/UI/Loader/Loader';
import Icon, { IconType } from 'components/UI/Icon';
import IconButton from 'components/UI/IconButton';
import Heading, { Tag } from 'components/UI/Heading';
import SlideoutModal from 'components/UI/SlideoutModal';

import { Header, Body } from './styles';
import { useForm } from 'react-hook-form';
import { FlexContainer } from 'styles';
import Button from 'components/UI/Button';
import TextField from 'components/UI/TextField';

type Props = {
  id: string;
  type: 'umbrellaCompany' | 'company';
  isOpen: boolean;
  onClose: () => void;
};

const AddCompanyCommunicationModal: FC<Props> = ({
  isOpen,
  onClose,
  id,
  type,
}) => {
  const intl = useIntl();

  const [addUmbrellaCompanyCommunication, addUmbrellaCompanyResult] =
    useAddUmbrellaCompanyCommunicationSlugMutation();

  const [addCompanyCommunication, addCompanyResult] =
    useAddCompanyCommunicationSlugMutation();

  const addResult =
    type === 'umbrellaCompany' ? addUmbrellaCompanyResult : addCompanyResult;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ slug: string }>();

  // Content
  const content = useMemo(() => {
    if (addResult.isLoading) {
      return <Loader padding color="blue" />;
    }
    return (
      <form
        onSubmit={handleSubmit(async (formData) => {
          await (type === 'umbrellaCompany'
            ? addUmbrellaCompanyCommunication({
                slug: formData.slug,
                umbrellaCompanyId: id,
              })
            : addCompanyCommunication({
                slug: formData.slug,
                companyId: id,
              })
          ).unwrap();
          onClose();
        })}
      >
        <TextField
          label={intl.formatMessage({
            id: 'inputCompanyCommunicationSlugLabel',
            defaultMessage: 'Company communication slug',
            description: 'Label for company communication slug input',
          })}
          placeholder={intl.formatMessage({
            id: 'inputCompanyCommunicationSlugPlaceholder',
            defaultMessage: 'Enter company communication slug',
            description: 'Placeholder for company communication slug input',
          })}
          error={errors.slug}
          register={register('slug', {
            required: intl.formatMessage({
              id: 'inputErrorFieldRequired',
              defaultMessage: 'Field is is required',
              description: 'Input error for field required',
            }),
          })}
        />
        <FlexContainer>
          <Button type="submit" background="blue">
            <FormattedMessage
              id="saveButton"
              defaultMessage="Save"
              description="Save button text"
            />
          </Button>
        </FlexContainer>
      </form>
    );
  }, [
    addResult.isLoading,
    handleSubmit,
    intl,
    errors.slug,
    register,
    type,
    addUmbrellaCompanyCommunication,
    id,
    addCompanyCommunication,
    onClose,
  ]);

  const onModalClose = () => {
    onClose();
  };

  return (
    <SlideoutModal isOpen={isOpen} onClose={onModalClose}>
      <div>
        <Header>
          <Heading tag={Tag.H4}>
            <FormattedMessage
              id="addCompanyCommunicationModalTitle"
              defaultMessage="Add company communication"
              description="Modal title for adding company communication"
            />
          </Heading>
          <IconButton onClick={onClose} padding>
            <Icon type={IconType.Close} />
          </IconButton>
        </Header>
        <Body>{content}</Body>
      </div>
    </SlideoutModal>
  );
};

export default AddCompanyCommunicationModal;
