import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { defaultLanguage } from 'assets/data';
import * as storage from 'utils/storage';
import { ErrorMessages, Language, LocalizationState } from 'models';
import { RootState } from '../store';

export const initialLocalizationState: LocalizationState = {
  language: null,
  languages: [],
  errors: null,
};

// Export slice
const localizationSlice = createSlice({
  name: 'localization',
  initialState: initialLocalizationState,
  reducers: {
    setLanguage: (state, action: PayloadAction<Language>) => {
      storage.setItem('languageCode', action.payload.languageCode);
      state.language = action.payload;
    },
    setLanguageByCode: (state, action: PayloadAction<string | undefined>) => {
      let matchingLanguage = state.languages.find(
        (language) => language.languageCode === action.payload
      );
      if (!matchingLanguage) {
        matchingLanguage =
          state.languages.find((language) => language.languageCode === 'en') ??
          defaultLanguage;
      }
      storage.setItem('languageCode', matchingLanguage.languageCode);
      state.language = matchingLanguage;
    },
    setLanguages: (state, action: PayloadAction<Language[]>) => {
      state.languages = action.payload;
    },
    setErrorLocales: (state, action: PayloadAction<ErrorMessages>) => {
      state.errors = action.payload;
    },
  },
});

// Export actions
export const { setLanguage, setLanguages, setErrorLocales, setLanguageByCode } =
  localizationSlice.actions;

// Export reducer
export const localizationReducer = localizationSlice.reducer;

// Export selectors
export const localizationSelector = (state: RootState) => state.localization;
