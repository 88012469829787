import { AgeTypesInQuizStatistics } from 'models';
import { useMemo } from 'react';
import { IntlShape, useIntl } from 'react-intl';

type AgeOptionsForStatistics = Array<{
  name: string;
  value: AgeTypesInQuizStatistics;
}>;

const getAgeTypeTranslation = (
  ageType: AgeTypesInQuizStatistics,
  intl: IntlShape
): string => {
  switch (ageType) {
    case 'Young':
      return intl.formatMessage({
        id: 'ageSelectLabelYoung',
        defaultMessage: '34 and younger',
        description: 'Select label for Young age',
      });
    case 'Medium':
      return intl.formatMessage({
        id: 'ageSelectLabelMedium',
        defaultMessage: '35 - 49',
        description: 'Select label for Medium age',
      });
    case 'Old':
      return intl.formatMessage({
        id: 'ageSelectLabelOld',
        defaultMessage: '50 and older',
        description: 'Select label for Old age',
      });
    case 'All':
      return intl.formatMessage({
        id: 'ageSelectLabelAll',
        defaultMessage: 'All',
        description: 'Select label for all ages',
      });
  }
};

export const useAgeOptionsForStatistics = (): AgeOptionsForStatistics => {
  const intl = useIntl();
  return useMemo(
    () => [
      {
        name: getAgeTypeTranslation('All', intl),
        value: 'All',
      },
      {
        name: getAgeTypeTranslation('Young', intl),
        value: 'Young',
      },
      {
        name: getAgeTypeTranslation('Medium', intl),
        value: 'Medium',
      },
      {
        name: getAgeTypeTranslation('Old', intl),
        value: 'Old',
      },
    ],
    [intl]
  );
};
