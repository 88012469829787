import { FC, useEffect } from 'react';
import { useAppDispatch } from 'hooks/redux';

import { setErrorLocales, useGetErrorMessagesQuery } from 'store';

const GlobalData: FC = () => {
  const dispatch = useAppDispatch();

  // Hooks
  const errors = useGetErrorMessagesQuery();

  // Set error messages from API
  useEffect(() => {
    if (errors.data) {
      dispatch(setErrorLocales(errors.data.messages));
    }
  }, [dispatch, errors]);

  return null;
};

export default GlobalData;
