import styled from 'styled-components';
import { Link } from 'react-router-dom';

// Styled components
export const NavBar = styled.nav`
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.backgroundLight};
  padding: 15px 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.1);
  z-index: 96;
`;

export const NavBarPlaceholder = styled.div`
  padding-top: 65px; // Prevent content from being hidden by the navbar
`;

export const Title = styled.span<{ active: boolean }>`
  color: ${({ theme, color, active }) =>
    active ? theme.colors.blue : theme.colors.black};
  font-size: 14px;
  font-weight: 400;
  margin-top: 2px;
`;

export const MenuLink = styled(Link)<{ active: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: ${({ active }) => (active ? 1 : 0.75)};
`;

export const Notification = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 100%;
  position: absolute;
  top: -1px;
  right: -1px;
  background-color: ${({ theme }) => theme.colors.error};
`;
