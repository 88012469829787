import Icon, { IconType } from 'components/UI/Icon';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import ClickableCard from '../ClickableCard';
import { CardContainer, CardDescription, CardHeading } from './styles';

type Props = {
  title: string;
  description?: string;
} & ({ onClick: () => void } | { to: string });

const NavCard: FC<Props> = (props) => {
  const navigate = useNavigate();

  const onClick = () => {
    if ('onClick' in props) {
      props.onClick();
    } else {
      navigate(props.to);
    }
  };

  return (
    <ClickableCard onClick={onClick}>
      <CardContainer>
        <div>
          <CardHeading>{props.title}</CardHeading>
          {props.description != null && (
            <CardDescription>{props.description}</CardDescription>
          )}
        </div>
        <Icon type={IconType.Arrow} />
      </CardContainer>
    </ClickableCard>
  );
};

export default NavCard;
