import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 8px 16px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};

  h3 {
    margin: 0;
  }
`;

export const Body = styled.div`
  height: calc(100vh - 112px);
  overflow-y: auto;
`;

export const LevelItem = styled.div<{ active: boolean }>`
  display: flex;
  justify-content: space-between;
  background: ${({ active, theme }) =>
    active ? theme.colors.backgroundLight : 'none'};
  gap: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
  padding: 16px 20px;

  h4 {
    margin: 0 0 8px;
  }
`;

export const Level = styled.span`
  color: ${({ theme }) => theme.colors.grayText};
  font-size: 14px;
`;

export const LevelWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0 0 4px;
`;

export const LevelAction = styled.p`
  margin: 0;
`;

export const LevelLeftCol = styled.div`
  flex: 1;
`;

export const LevelRightCol = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
