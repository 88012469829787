import React, { FC, Fragment, useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  useRemoveNotificationDeviceMutation
} from '../../../store/notificationService/notificationService';
import { ActionContainer } from 'styles';

import Loader from 'components/UI/Loader';
import Button from 'components/UI/Button';
import Icon, { IconType } from 'components/UI/Icon';
import IconButton from 'components/UI/IconButton';
import Heading, { Tag } from 'components/UI/Heading';
import SlideoutModal from 'components/UI/SlideoutModal';

import { Header, Body } from './styles';
import { RemovePushNotificationDeviceDto } from '../../../models/notification/notification';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  removeDeviceDto: RemovePushNotificationDeviceDto
};

const DeleteNotificationDeviceModal: FC<Props> = ({ isOpen, onClose, removeDeviceDto}) => {
  // Hooks
  const [removeDevice, { isSuccess, isLoading }] = useRemoveNotificationDeviceMutation();

  // Success
  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess, onClose]);

  // Actions
  const onDelete = useCallback(async () => {
    console.log("modal :", removeDeviceDto);
    await removeDevice(removeDeviceDto);
  }, [removeDevice]);

  // Content
  const content = useMemo(() => {
    if (isLoading) {
      return <Loader padding color="blue" />;
    }
    return (
      <Fragment>
        <p>
          <FormattedMessage
            id="deleteNotificationDeviceModalDescription"
            defaultMessage="Are you sure you want to stop this device receiving notifications? (Reactivating notifications can be done from that device)"
            description="Delete notification device description"
          />
        </p>
        <ActionContainer>
          <Button background="error" onClick={onDelete}>
            <FormattedMessage
              id="deleteButton"
              defaultMessage="Delete"
              description="Delete button"
            />
          </Button>
        </ActionContainer>
      </Fragment>
    );
  }, [isLoading, onDelete]);

  return (
    <SlideoutModal isOpen={isOpen} onClose={onClose}>
      <div>
        <Header>
          <Heading tag={Tag.H3}>
            <FormattedMessage
              id="deleteNotificationDeviceModalTitle"
              defaultMessage="Disable notifications"
              description="Delete notification device modal title"
            />
          </Heading>
          <IconButton onClick={onClose} padding>
            <Icon type={IconType.Close} />
          </IconButton>
        </Header>
        <Body>{content}</Body>
      </div>
    </SlideoutModal>
  );
};

export default DeleteNotificationDeviceModal;
