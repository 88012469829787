import React, { FC, Fragment, useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { useEditContestUserAliasMutation } from 'store';

import Loader from 'components/UI/Loader';
import Icon, { IconType } from 'components/UI/Icon';
import IconButton from 'components/UI/IconButton';
import Heading, { Tag } from 'components/UI/Heading';
import SlideoutModal from 'components/UI/SlideoutModal';
import UserAliasForm from 'components/forms/UserAliasForm';

import { Header, Body } from './styles';
import { AliasForm } from 'components/forms/UserAliasForm/UserAliasForm';

type Props = {
  contestId: string;
  isOpen: boolean;
  onClose: () => void;
};

const EditAliasModal: FC<Props> = ({ contestId, isOpen, onClose }) => {
  // Hooks
  const [editAlias, editResult] = useEditContestUserAliasMutation();

  // Success
  useEffect(() => {
    if (editResult.isSuccess) {
      onClose();
    }
  }, [editResult, onClose]);

  // Actions
  const onEditAlias = useCallback(
    async (form: AliasForm) => {
      await editAlias({
        contestId,
        alias: form.alias,
      });
    },
    [editAlias, contestId]
  );

  // Content
  const content = useMemo(() => {
    if (editResult.isLoading) {
      return <Loader padding color="blue" />;
    }
    return (
      <Fragment>
        <p>
          <FormattedMessage
            id="editCompetitionUserAliasModalDescription"
            defaultMessage="Change your alias by entering a new one below"
            description="Edit competition user alias modal description"
          />
        </p>
        <UserAliasForm
          withDisplayEmailSetting={false}
          submitForm={onEditAlias}
        />
      </Fragment>
    );
  }, [editResult, onEditAlias]);

  return (
    <SlideoutModal isOpen={isOpen} onClose={onClose}>
      <div>
        <Header>
          <Heading tag={Tag.H3}>
            <FormattedMessage
              id="editCompetitionUserAliasModalTitle"
              defaultMessage="Change alias"
              description="Edit competition user alias modal title"
            />
          </Heading>
          <IconButton onClick={onClose} padding>
            <Icon type={IconType.Close} />
          </IconButton>
        </Header>
        <Body>{content}</Body>
      </div>
    </SlideoutModal>
  );
};

export default EditAliasModal;
