import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Grid = styled.div<{
  justify?: string;
  grow?: boolean;
  onClick?: unknown;
}>`
  display: flex;
  justify-content: ${({ justify }) => justify};
  flex-grow: ${({ grow }) => (grow ? 1 : 0)};
  align-items: center;
  cursor: ${({ onClick }) => (onClick != null ? 'pointer' : 'default')};
`;

export const Column = styled.div<{ narrow: boolean }>`
  margin: ${({ narrow }) => (narrow ? '12px 16px' : '20px 16px')};

  h4 {
    margin: 0;
  }
`;

type ExpandableAreaProps = {
  $defaultIsOpen: boolean;
};

export const ArrowContainer = styled(motion.div)<ExpandableAreaProps>`
  display: flex;
  transform: ${({ $defaultIsOpen }) =>
    $defaultIsOpen ? 'rotate(270deg)' : 'rotate(90deg)'};
`;

export const ExpandableArea = styled(motion.div)<ExpandableAreaProps>`
  position: relative;
  height: ${({ $defaultIsOpen }) => ($defaultIsOpen ? 'auto' : '0')};
  opacity: ${({ $defaultIsOpen }) => ($defaultIsOpen ? '1' : '0')};
`;

export const CardDescription = styled.div`
  font-size: 12px;
  fony-family: 'Mark-Medium';
  color: ${({ theme }) => theme.colors.grayText};
  margin-top: 4px;
`;

export const EditContainer = styled.div`
  cursor: pointer;
`;

export const ExpandableCardTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;
