import styled from 'styled-components';

export const TopListInfo = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  margin-top: 4px;
`;

export const TopListInfoText = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.grayText};
`;
export const QuizTransition = styled.div<{ isShown: boolean }>`
  opacity: ${({ isShown }) => (isShown ? 1 : 0)};
  height: ${({ isShown }) => (isShown ? '100%' : '0')};
  overflow: hidden;
  transition: opacity 0.5s ease, max-height 0.5s ease;
`;