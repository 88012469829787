import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';

import Button from 'components/UI/Button/Button';
import TextField from 'components/UI/TextField/TextField';

import { ActionContainer } from 'styles';
import { FieldGrid } from './styles';
import ConfirmActionPopup from 'components/popups/ConfirmActionPopup';
import Heading, { Tag } from 'components/UI/Heading';

type Props = {
  submitForm: (data: CreateHeadofficeFormData) => void;
  onDelete?: () => void;
  headOffice?: {
    id: string;
    description: string | null | undefined;
  };
};

export type CreateHeadofficeFormData = {
  headofficeName: string;
};

const CreateUpdateOfficeForm: FC<Props> = ({
  submitForm,
  headOffice,
  onDelete,
}) => {
  const intl = useIntl();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
  } = useForm<CreateHeadofficeFormData>({
    defaultValues: {
      headofficeName: headOffice?.description ?? '',
    },
  });
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  useEffect(() => {
    if (headOffice) {
      setValue('headofficeName', headOffice?.description ?? '');
    }
  }, [headOffice, setValue]);

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <FieldGrid>
        <TextField
          label={intl.formatMessage({
            id: 'inputHeadofficeNameLabel',
            defaultMessage: 'Head office name',
            description: 'Label for head office name input',
          })}
          placeholder={intl.formatMessage({
            id: 'inputHeadofficeNamePlaceholder',
            defaultMessage: 'Enter head office name',
            description: 'Placeholder for head office name input',
          })}
          error={errors.headofficeName}
          register={register('headofficeName', {
            required: intl.formatMessage({
              id: 'inputErrorHeadofficeNameRequired',
              defaultMessage: 'A head office name is required',
              description: 'Input error for head office name required',
            }),
          })}
        />
      </FieldGrid>
      <ActionContainer>
        <Button type="submit" background="blue" disabled={!isDirty}>
          <FormattedMessage
            id="saveButton"
            defaultMessage="Save"
            description="Save button text"
          />
        </Button>
        {headOffice && onDelete && (
          <>
            <Button
              color="error"
              background="transparent"
              border="error"
              onClick={() => setShowConfirmDelete(true)}
            >
              <FormattedMessage
                id="deleteHeadOfficeButton"
                defaultMessage="Delete"
                description="Delete head office button"
              />
            </Button>
            <ConfirmActionPopup
              isOpen={showConfirmDelete}
              onConfirm={onDelete}
              onAbort={() => setShowConfirmDelete(false)}
            >
              <>
                <Heading tag={Tag.H4}>
                  <FormattedMessage
                    id="deleteHeadofficeConfirmHeading"
                    defaultMessage="Delete head office"
                    description="Heading text in the delete head office popup"
                  />
                </Heading>
                <FormattedMessage
                  id="deleteHeadofficeConfirmBody"
                  defaultMessage="Are you sure you want to delete this head office?"
                  description="Body text in the delete head office popup"
                />
              </>
            </ConfirmActionPopup>
          </>
        )}
      </ActionContainer>
    </form>
  );
};

export default CreateUpdateOfficeForm;
