import AdminQuizResults from 'components/fragments/AdminQuizResults/AdminQuizResults';
import Heading, { Tag } from 'components/UI/Heading';
import { IconType } from 'components/UI/Icon';
import Loader from 'components/UI/Loader';
import { useActiveLanguage } from 'hooks/useActiveLanguage';
import { useLocalizedText } from 'hooks/useLocalizedText';
import { usePageHeader } from 'hooks/usePageHeader';
import { usePretendCompany } from 'hooks/usePretendCompany';
import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useGetCompanyPulseQuizQuery } from 'store';
import { ContentWidth, Description } from 'styles';
import { isBefore, isAfter, formatDateTime } from 'utils/date';

const AdminPulseTestResults: FC = () => {
  const { id } = useParams<{
    id: string;
  }>();

  const intl = useIntl();
  const getText = useLocalizedText();
  const language = useActiveLanguage();
  const companyId = usePretendCompany();

  const { data, isLoading } = useGetCompanyPulseQuizQuery(
    {
      id: id as string,
      language: language.languageCode,
      companyId,
    },
    { skip: id == null }
  );

  usePageHeader({
    title: intl.formatMessage({
      id: 'backButton',
      defaultMessage: 'Back',
      description: 'Back button text',
    }),
    icon: IconType.Back,
  });

  if (id == null) {
    return null;
  }

  if (data == null || isLoading) {
    return <Loader color="blue" padding />;
  }

  if (data.endTime != null && isAfter(data.endTime)) {
    return <AdminQuizResults id={id} quizType={'pulse'} />;
  }

  const isNotStarted = data.startTime != null && isBefore(data.startTime);

  return (
    <ContentWidth isSurface>
      <Heading tag={Tag.H2}>{getText(data.pulseQuizDefinition?.title)}</Heading>
      <Description>{getText(data.pulseQuizDefinition?.summary)}</Description>
      <Heading tag={Tag.H3}>
        <FormattedMessage
          id="pageAdminQuizResultsTitle"
          defaultMessage="Results"
          description="Title before the results on the admin quiz results page"
        />
      </Heading>
      {isNotStarted ? (
        <FormattedMessage
          id="pulseQuizNotStarted"
          defaultMessage="This pulse test has not yet started. The test starts at {startTime}. Results will be available once the test has ended."
          description="Pulse quiz not started text"
          values={{
            startTime: formatDateTime(new Date(data.startTime ?? '')),
          }}
        />
      ) : (
        <FormattedMessage
          id="pulseQuizOngoing"
          defaultMessage="This pulse test is currently ongoing with {submittedCount} of {userCount} users having completed the test so far. You will be able to see results for the test once the test has ended. The test ends at {endTime}."
          description="Pulse quiz ongoing text"
          values={{
            submittedCount: data.submittedCount,
            userCount: data.userCount,
            endTime: formatDateTime(new Date(data.endTime ?? '')),
          }}
        />
      )}
    </ContentWidth>
  );
};

export default AdminPulseTestResults;
