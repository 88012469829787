import { useGetNotificationStateQuery } from 'store';

const useNotifications = () => {
  const { data } = useGetNotificationStateQuery(undefined, {});
  return {
    healthQuiz: data?.healthQuiz === true,
    pulseQuiz: data?.pulseQuiz === true,
    activeMeasure: data?.activeMeasure === true,
    proposedMeasure: data?.proposedMeasure === true,
    competition: data?.competition === true,
    challenge: data?.challenge === true,
  };
};

export default useNotifications;
