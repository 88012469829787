import { useEffect } from 'react';
import {
  authSelector,
  localizationSelector,
  setLanguageByCode,
  setLanguages,
  useGetLanguagesQuery,
  useGetUserQuery,
} from 'store';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import * as storage from 'utils/storage';

/**
 * This hook is used to initialize the site language according to the following rules:
 * 1. If the user is authenticated, the language is set to the user's language as stored in backend.
 * 2. If the user is not authenticated, the language is set to the language stored in local storage.
 * 3. If the language is not stored in local storage (first time user), the language is set to the browser's language.
 */
export const useLanguageInitializer = () => {
  const dispatch = useAppDispatch();
  const { language, languages } = useAppSelector(localizationSelector);
  const { isAuthenticated, initialized } = useAppSelector(authSelector);

  const languageList = useGetLanguagesQuery();

  // Set languages from API
  useEffect(() => {
    if (languageList.data) {
      dispatch(setLanguages(languageList.data));
    }
  }, [dispatch, languageList]);

  const userResponse = useGetUserQuery(undefined, {
    skip: !isAuthenticated,
    selectFromResult: (result) => ({
      language: result.data?.language,
      isLoading: result.isLoading,
    }),
  });

  useEffect(() => {
    if (language) {
      return;
    }
    if (initialized && languages.length) {
      let languageCode: string | undefined;
      if (isAuthenticated) {
        if (userResponse.isLoading) {
          return;
        }
        languageCode = userResponse.language;
      } else {
        const storedLanguageCode = storage.getItem<string>('languageCode');
        if (storedLanguageCode) {
          languageCode = storedLanguageCode;
        } else {
          languageCode = navigator.language.split(/[-_]/)[0];
        }
      }
      dispatch(setLanguageByCode(languageCode));
    }
  }, [
    dispatch,
    initialized,
    isAuthenticated,
    language,
    languages.length,
    userResponse.isLoading,
    userResponse.language,
  ]);
};
