import Icon, { IconType } from 'components/UI/Icon';
import React, { FC } from 'react';
import { FlexContainer } from 'styles';
import StaticCard from '../StaticCard';
import {
  CardContainer,
  CardDescription,
  CardHeading,
  IconContainer,
} from './styles';
import { FormattedMessage } from 'react-intl';

type Props = {
  currentPage: number
  pageSize: number
  qtyNotifications: number
  onBack: () => void
  onForward: () => void
};

const PaginationControlCard: FC<Props> = (props) => {
  const startNr = 1 + ((props.currentPage - 1) * props.pageSize)
  const endNr = Math.min(((props.currentPage) * props.pageSize), props.qtyNotifications)
  const enableBack = props.currentPage > 1
  const enableForward = endNr < props.qtyNotifications

  return (
    <StaticCard>
      <CardContainer>
        <FlexContainer gap={8} justifyContent={'center'} style={{ width: '100%' }} >
          {(
            <IconContainer
              onClick={() => {if (enableBack) props.onBack()}}
              style={{minWidth: '24px'}}>
              <Icon type={IconType.ArrowLeft} color={enableBack ? "black" : "borderLight"}/>
            </IconContainer>
          )}
          <div style={{textAlign: 'center'}}>
            <CardHeading style={{}}>
              <FormattedMessage
                id="NotificationPaginationPageTitel"
                defaultMessage="Page"
                description="Title representing page in pagination"
              />
              {' '}
              {props.currentPage}
            </CardHeading>
            {(
              <CardDescription>{startNr} - {endNr} / {props.qtyNotifications}</CardDescription>
            )}
          </div>
          {(
            <IconContainer onClick={() => {if (enableForward) props.onForward()}}>
              <Icon type={IconType.Arrow} color={enableForward ? "black" : "borderLight"}/>
            </IconContainer>
          )}
        </FlexContainer>
      </CardContainer>
    </StaticCard>
  );
};

export default PaginationControlCard;
