import AddCompanyCommunicationModal from 'components/modals/AddCompanyCommunicationModal';
import ActionCard from 'components/UI/Cards/ActionCard';
import EmptyState from 'components/UI/EmptyState';
import HeadingWithAdd from 'components/UI/HeadingWithAdd';
import useModal from 'hooks/useModal';
import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  useDeleteCompanyCommunicationSlugMutation,
  useDeleteUmbrellaCompanyCommunicationSlugMutation,
  useGetCompanyCommunicationSlugsQuery,
  useGetUmbrellaCompanyCommunicationSlugsQuery,
} from 'store';
import { FlexContainer } from 'styles';

type Props = {
  id: string;
  type: 'umbrellaCompany' | 'company';
};

const CompanyCommunicationManager: FC<Props> = ({ id, type }) => {
  const intl = useIntl();
  const umbrellaCompanyCommunicationResult =
    useGetUmbrellaCompanyCommunicationSlugsQuery(
      {
        umbrellaCompanyId: id,
      },
      { skip: type !== 'umbrellaCompany' }
    );

  const companyCommunicationResult = useGetCompanyCommunicationSlugsQuery(
    {
      companyId: id,
    },
    { skip: type !== 'company' }
  );

  const result =
    type === 'umbrellaCompany'
      ? umbrellaCompanyCommunicationResult
      : companyCommunicationResult;

  const { modalOpen, onCloseModal, onOpenModal } = useModal();

  const [deleteUmbrellaCompanyCommunication] =
    useDeleteUmbrellaCompanyCommunicationSlugMutation();

  const [deleteCompanyCommunication] =
    useDeleteCompanyCommunicationSlugMutation();

  if (result.data == null) {
    return null;
  }

  return (
    <>
      <HeadingWithAdd
        title={intl.formatMessage({
          id: 'adminUmbrellaCompanyCommunicationsTitle',
          defaultMessage: 'Company communications',
          description: 'Umbrella company communications heading title',
        })}
        addTitle={intl.formatMessage({
          id: 'adminUmbrellaCompanyAddCommunication',
          defaultMessage: 'Add communication',
          description: 'Add communication button text',
        })}
        onAdd={onOpenModal}
      />
      {result.data.length > 0 ? (
        <>
          <FlexContainer gap={12} flexDirection="column">
            {result.data.map((slug) => (
              <ActionCard
                title={slug}
                key={slug}
                onDelete={() =>
                  type === 'umbrellaCompany'
                    ? deleteUmbrellaCompanyCommunication({
                        umbrellaCompanyId: id,
                        slug,
                      })
                    : deleteCompanyCommunication({
                        companyId: id,
                        slug,
                      })
                }
              />
            ))}
          </FlexContainer>
          <br />
        </>
      ) : (
        <EmptyState align="left" compact>
          <FormattedMessage
            id="adminUmbrellaCompanyCommunicationsEmptyState"
            defaultMessage="No commmunications exists for this company."
            description="Umbrella company communications empty state"
          />
        </EmptyState>
      )}
      <AddCompanyCommunicationModal
        isOpen={modalOpen}
        onClose={onCloseModal}
        id={id}
        type={type}
      />
    </>
  );
};

export default CompanyCommunicationManager;
