import styled from 'styled-components';
import { motion } from 'framer-motion';
import { breakpoints } from 'styles';

export const Overlay = styled(motion.div)`
  position: fixed;
  z-index: 99;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;

  @media (min-width: ${breakpoints.l}px) {
    display: none !important;
  }
`;
