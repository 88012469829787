import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import { isSameDay } from 'utils/date';
import { sortArrayByProperty } from 'utils/array';

import StaticCard from 'components/UI/Cards/StaticCard';
import Loader from 'components/UI/Loader';
import Button from 'components/UI/Button';

import { Grid, Action, CalendarGrid } from './styles';
import RegisterContentOrganizationModal from 'components/modals/RegisterContentOrganizationModal';
import { useGetActiveOrganizationNotificationSchedulesQuery } from 'store/notificationScheduleService/notificationScheduleService';
import { groupNotificationSchedules } from 'utils/notificationSchedule';
import NotificationScheduleList from '../NotificationScheduleList';
import { useAppSelector } from 'hooks/redux';
import { localizationSelector } from 'store';

type Props = {
  companyId: string;
};

const CompanyContentMonthly: FC<Props> = ({ companyId }) => {

  // State
  const [date, setActive] = useState<Date>(new Date());
  const [registerModalOpen, setRegisterModalOpen] = useState<boolean>(false);
  const [shouldUpdateCalendar, setShouldUpdateCalendar] = useState(false);

  // Hooks
  const { language } = useAppSelector(localizationSelector);
  const schedules = useGetActiveOrganizationNotificationSchedulesQuery({
    organizationId: companyId,
  });
  const triggerCalendarUpdate = () => {
    setShouldUpdateCalendar((prev) => !prev); // Toggle to trigger useEffect
  };

  // Open / close modal
  const onOpenModal = useCallback(() => setRegisterModalOpen(true), []);
  const onCloseModal = useCallback(() => setRegisterModalOpen(false), []);

  const setTimeToPlusFiveMinutes = (date: Date): Date => {
    const newDate = new Date(date);
    const currentDate = new Date();
    newDate.setHours(currentDate.getHours());
    newDate.setMinutes(currentDate.getMinutes());
    return newDate;
  };

  // Schedules dates
  const scheduleDates = useMemo(() => {
    const { data } = schedules;
    if (!data) {
      return [];
    }
    return groupNotificationSchedules(data).map((item) => new Date(item.creationAndSendDateOfNotification));
  }, [schedules]);

  // Calendar update
  useEffect(() => {
    schedules.refetch();
  }, [shouldUpdateCalendar]);

  const daySchedules = useMemo(() => {
    if (!schedules.data) {
      return null;
    }
    const sortedSchedules = sortArrayByProperty(
      schedules.data,
      'creationAndSendDateOfNotification',
      'desc'
    );
    const filteredSchedules = sortedSchedules.filter((item) =>
      isSameDay(new Date(item.creationAndSendDateOfNotification), date)
    );

    return <NotificationScheduleList refetch={schedules.refetch} data={filteredSchedules} />;
  }, [date, schedules]);

  // Loading
  if (schedules.isLoading) {
    return <Loader color="blue" padding />;
  }

  // No data
  if (!schedules.data) {
    return null;
  }

  return (
    <StaticCard>
      <Grid>
        <CalendarGrid>
          <Calendar
            locale={language?.languageCode}
            value={date}
            onActiveStartDateChange={({ activeStartDate }) =>
              setActive(setTimeToPlusFiveMinutes(activeStartDate as Date))
            }
            onChange={(value) => setActive(setTimeToPlusFiveMinutes(value as Date))}
            tileClassName={({ date, view }) => {
              if (
                view === 'month' &&
                scheduleDates.some((scheduleDate) =>
                  isSameDay(scheduleDate, date)
                )
              ) {
                return 'activeCalendarDay';
              }
              return '';
            }}
          />
        </CalendarGrid>
        {daySchedules}
        <Action>
          <Button color="blue" background="transparent" onClick={onOpenModal}>
            <FormattedMessage
              id="registerHealthFocusButton"
              defaultMessage="Registrera hälsofokus"
              description="Register activity button"
            />
          </Button>
        </Action>
      </Grid>
      <RegisterContentOrganizationModal
        date={date}
        onSuccessfulSubmit={triggerCalendarUpdate}
        isOpen={registerModalOpen}
        onClose={onCloseModal}
        companyId={companyId}
      />
    </StaticCard>
  );
};

export default CompanyContentMonthly;
