import React, {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
  FormEvent,
  ChangeEvent,
  useRef,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Icon, { IconType } from 'components/UI/Icon';
import DropdownMenu from 'components/UI/DropdownMenu';
import IconButton from 'components/UI/IconButton';
import Heading, { Tag } from 'components/UI/Heading';
import SlideoutModal from 'components/UI/SlideoutModal';
import EmptyState from 'components/UI/EmptyState';
import Loader from 'components/UI/Loader';

import { uploadChatAttachment } from 'services';
import { groupArrayByProperty } from 'utils/array';
import { formatReadableDateTime, getStartOfDay } from 'utils/date';
import { useAppSelector } from 'hooks/redux';
import { authSelector } from 'store';
import { ChatMessage, ChatRoom } from 'models';
import * as config from 'config';

import ChatMessageItem from './ChatMessageItem';
import {
  ChatButton,
  MessageGrid,
  MessageInput,
  ChatBody,
  ChatHeader,
  ChatInputGrid,
  MessageDate,
  ChatContainer,
  IconContainer,
  DropdownContent,
  DropdownWrapper,
  Input,
  ReplyMessageBody,
  ReplyChatMessage,
} from './styles';
import Bubble from 'components/UI/Bubble';
import { usePutSeenMessageCountMutation } from 'store/chatService/chatService';

type Props = {
  rooms: ChatRoom[];
  unreadMessages: {
    contest?: number;
    team?: number;
  };
  onOpen?: () => void;
};


const ChatModal: FC<Props> = ({ rooms, unreadMessages, onOpen }) => {
  const { contest: unreadContestMessages, team: unreadTeamMessages } = unreadMessages;
  const { accessToken } = useAppSelector(authSelector);
  const [putSeenMessageCount] = usePutSeenMessageCountMutation();
  const intl = useIntl();

  // Refs
  const bottomRef = useRef<HTMLDivElement>(null);
  const inputFileRef = useRef<HTMLInputElement>(null);

  // State
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [replyMessage, setReplyMessage] = useState<ChatMessage | null>(null);
  const [activeChatRoom, setActiveChatRoom] = useState<ChatRoom | null>(
    rooms.length ? rooms[0] : null
  );
  const [message, setMessage] = useState<string>('');
  const [messages, setMessages] = useState<ChatMessage[]>([]);

  // Disabled
  const isDisabled = useMemo(() => message.length === 0, [message]);

  useEffect(() => {
    const updateSeenMessageCount = async () => {
      if (isOpen && activeChatRoom) {
        await putSeenMessageCount({
          chatRoomId: activeChatRoom.chatId,
        });
        onOpen?.();
      }
    };

    updateSeenMessageCount();
  }, [isOpen, activeChatRoom, putSeenMessageCount, onOpen]);


  // Init
  const ws = useMemo(() => {
    if (!isOpen || !activeChatRoom) {
      return null;
    }
    return new WebSocket(
      `${config.webSocketUrl}/${JSON.stringify({
        messageCategory: 'chat',
        argument: activeChatRoom.chatId,
        accessToken,
      })}`
    );
  }, [isOpen, activeChatRoom, accessToken]);

  // Listen
  useEffect(() => {
    if (!ws) {
      return;
    }
    ws.onmessage = (event: MessageEvent) => {
      const data = JSON.parse(event.data);
      switch (data.messageAction) {
        case 'CreateEntry':
          setMessages((prev) => [...prev, data.chatEntryDto]);
          break;
        case 'DeleteEntry':
          setMessages((prev) =>
            prev.filter((item) => item.id !== data.chatEntryId)
          );
          break;
        default:
          if (Array.isArray(data)) {
            setMessages(data);
            setIsLoading(false);
          }
      }
    };
    ws.onclose = () => setIsLoading(true);
    return () => ws.close();
  }, [ws]);

  // Scroll to bottom
  useEffect(() => {
    if (messages.length) {
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  // Open / close
  const onToggle = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  // Handle change
  const onChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => setMessage(event.target.value),
    []
  );

  // Handle input
  const onInput = useCallback((e: FormEvent<HTMLTextAreaElement>) => {
    e.currentTarget.style.height = '4px';
    e.currentTarget.style.height = e.currentTarget.scrollHeight + 'px';
  }, []);

  // Send message
  const onSend = useCallback(() => {
    const payload = {
      messageCategory: 'chat',
      messageAction: 'createEntry',
      payload: {
        message,
        replyto: replyMessage ? {
          id: replyMessage.id,
          userId: replyMessage.userId,
          message: replyMessage.chatMessage,
          userAlias: replyMessage.userAlias,
        } : null,
      },
    };

    ws?.send(JSON.stringify(payload));
    setMessage('');
    setReplyMessage(null);
  }, [ws, message, replyMessage]);

  // Upload attachment
  const onFileChangeCapture = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const { files } = e.target;
      if (files && activeChatRoom) {
        await uploadChatAttachment(
          activeChatRoom.chatId,
          files[0],
          accessToken
        );
      }
    },
    [activeChatRoom, accessToken]
  );

  // On upload attachment
  const onUpload = useCallback(() => inputFileRef.current?.click(), []);

  // Remove message
  const onRemove = useCallback(
    (chatEntryId: string) => {
      ws?.send(
        JSON.stringify({
          messageCategory: 'chat',
          messageAction: 'deleteEntry',
          payload: { chatEntryId },
        })
      );
    },
    [ws]
  );
  // Reply
  const onReply = useCallback(
    (chatEntryId: string) => {
      const messageToReply = messages.find((msg) => msg.id === chatEntryId);
      if (messageToReply) {
        setReplyMessage(messageToReply);
      }
    },
    [messages]
  );

  // Content
  const content = useMemo(() => {
    if (isLoading) {
      return <Loader color="blue" padding />;
    }
    if (!messages.length) {
      return (
        <EmptyState iconType={IconType.Chat} padding>
          <FormattedMessage
            id="chatModalEmptyState"
            defaultMessage="No messages found"
            description="Empty state for chat modal"
          />
        </EmptyState>
      );
    }
    return Object.entries(
      groupArrayByProperty(
        messages.map((item) => ({
          ...item,
          day: getStartOfDay(item.created),
        })),
        'day'
      )
    ).map(([key, values]) => (
      <div key={key}>
        {values.length && (
          <MessageDate>
            {formatReadableDateTime(new Date(values[0].created))}
          </MessageDate>
        )}
        {values.map((item) => (
          <ChatMessageItem key={item.id} message={item} onRemove={onRemove} onReply={onReply} />
        ))}
      </div>
    ));
  }, [onRemove, onReply, isLoading, messages]);

  return (
    <Fragment>
      <ChatButton onClick={onToggle}>
        <Icon type={IconType.Chat} size={32} color="white" />
        <div style={{ position: 'absolute', top: 5, right: 48 }}>
          <Bubble color={'white'} background={'pinkDark'} value={(unreadContestMessages || 0) + (unreadTeamMessages || 0)} />
        </div>

      </ChatButton>
      <SlideoutModal isOpen={isOpen} onClose={onToggle}>
        <ChatContainer>
          <ChatHeader>
            <Heading tag={Tag.H3}>
              <FormattedMessage
                id="chatModalTitle"
                defaultMessage="Chat"
                description="Title for chat modal"
              />
            </Heading>
            <IconButton onClick={onToggle} padding>
              <Icon type={IconType.Close} />
            </IconButton>
          </ChatHeader>
          <ChatBody>
            <MessageGrid>
              {content}
              <div ref={bottomRef} />
            </MessageGrid>
          </ChatBody>
            {replyMessage && (
              <ReplyMessageBody>
                <MessageGrid>
                  {intl.formatMessage({
                    id: 'chatReplyingMessage',
                    defaultMessage: 'Replying',
                    description: 'Replying message in chat',
                  })}
                  <b> {replyMessage.userAlias}</b>
                  <br />
                  <ReplyChatMessage>
                    {replyMessage.chatMessage}
                  </ReplyChatMessage>
                </MessageGrid>
                <IconButton onClick={() => setReplyMessage(null)} padding>
                  <div style={{ paddingRight: '2px' }}>
                    <Icon type={IconType.Close} />
                  </div>
                </IconButton>
              </ReplyMessageBody>
            )}
          <ChatInputGrid>
            {activeChatRoom && rooms.length > 1 ? (
              <DropdownWrapper>
                <Bubble color={'white'} background={'pinkDark'} value={(unreadContestMessages || 0) + (unreadTeamMessages || 0)} />
                <DropdownMenu
                  menu={rooms.map((item, i) => ({
                    id: i + 1,
                    text: item.title + ' ' + (i === 0 ? `(${unreadContestMessages})` : `(${unreadTeamMessages})`),
                    onClick: () => setActiveChatRoom(item),
                  }))}
                  direction="up"
                  align="left"
                >
                  <DropdownContent>
                    <p>{activeChatRoom.title}</p>
                    <IconContainer>
                      <Icon type={IconType.Arrow} color="black" />
                    </IconContainer>
                  </DropdownContent>
                </DropdownMenu>
              </DropdownWrapper>
            ) : null}
            <IconButton onClick={onUpload}>
              <Icon type={IconType.Upload} />
            </IconButton>
            <Input
              type="file"
              ref={inputFileRef}
              onChangeCapture={onFileChangeCapture}
            />
            <MessageInput
              placeholder="Aa"
              onInput={onInput}
              onChange={onChange}
              value={message}
            />
            <IconButton onClick={onSend} disabled={isDisabled}>
              <Icon
                type={IconType.Send}
                color={isDisabled ? 'grayText' : 'blue'}
              />
            </IconButton>
          </ChatInputGrid>
        </ChatContainer>
      </SlideoutModal>
    </Fragment>
  );
};

export default ChatModal;
