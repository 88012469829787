import React, { FC, Fragment, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { localizationSelector, useGetActivityTypesQuery } from 'store';
import { Activity } from 'models';
import { useAppSelector } from 'hooks/redux';
import { parseDate } from 'utils/date';

import DeleteActivityModal from 'components/modals/DeleteActivityModal';
import IconButton from 'components/UI/IconButton/IconButton';
import Icon from 'components/UI/Icon/Icon';
import Heading, { Tag } from 'components/UI/Heading';
import { IconType } from 'components/UI/Icon';

import { List, Item, ItemText, Column } from './styles';

type Props = {
  data: Activity[];
  showWalkSteps?: boolean;
};

const ActivityList: FC<Props> = ({ data, showWalkSteps }) => {
  const intl = useIntl();
  const { language } = useAppSelector(localizationSelector);

  // Hooks
  const types = useGetActivityTypesQuery({
    language: language?.languageCode,
  });

  // State
  const [deleteModal, setDeleteModal] = useState<string | null>(null);

  // Actions
  const onOpenDeleteModal = useCallback(
    (id: string) => () => setDeleteModal(id),
    []
  );
  const onCloseDeleteModal = useCallback(() => setDeleteModal(null), []);

  // Valid actitivies (avoid showing non available activites likes walking steps)
  const activities = useMemo(() => {
    const available = data.filter((item) => item.value.availableForClient);
    const notAvailable = data.filter((item) => !item.value.availableForClient);
    const result = notAvailable.length
      ? notAvailable.reduce((result, item) => {
          return {
            id: 'walk-steps',
            timestamp: item.timestamp,
            value: {
              ...item.value,
              numberOfUnits:
                result.value.numberOfUnits + item.value.numberOfUnits,
              stepsAwarded: result.value.stepsAwarded + item.value.stepsAwarded,
            },
          };
        })
      : null;
    return [...(result && showWalkSteps ? [result] : []), ...available];
  }, [data, showWalkSteps]);

  // No data
  if (!activities.length || !types.data?.length) {
    return null;
  }

  return (
    <Fragment>
      <List>
        {activities.map((activity, i) => {
          const foundType = types.data?.find(
            (type) => type.slug === activity.value.activitySlug
          );
          const foundUnit = foundType?.allowedUnits.find(
            (item) => item.unit.slug === activity.value.activityUnitSlug
          );
          const { numberOfUnits, stepsAwarded } = activity.value;

          return (
            <Item
              key={`activity-item-${i}`}
              isLast={activities.length === i + 1}
            >
              <Column>
                <Heading tag={Tag.H4}>
                  {foundType?.title ||
                    intl.formatMessage({
                      id: 'activityWalkSteps',
                      defaultMessage: 'Automatically reported steps',
                      description:
                        'Activity walk-steps translation (steps automatically reported by mobile)',
                    })}
                </Heading>
                <ItemText>
                  {numberOfUnits}{' '}
                  {numberOfUnits > 1
                    ? foundUnit?.unit.titlePlural
                    : foundUnit?.unit.titleSingular}{' '}
                  - {stepsAwarded}{' '}
                  {intl.formatMessage({
                    id: 'activitiesUnitText',
                    defaultMessage: 'steps',
                    description: 'Unit text for activities',
                  })}{' '}
                  - {parseDate(activity.timestamp)}
                </ItemText>
              </Column>
              {activity.id !== 'walk-steps' && (
                <IconButton onClick={onOpenDeleteModal(activity.id)} padding>
                  <Icon type={IconType.Remove} />
                </IconButton>
              )}
            </Item>
          );
        })}
      </List>
      <DeleteActivityModal
        id={deleteModal}
        isOpen={deleteModal !== null}
        onClose={onCloseDeleteModal}
      />
    </Fragment>
  );
};

export default ActivityList;
