import React, { FC, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import {
  localizationSelector,
  setLayoutHeader,
  useGetChallengeCategoryQuery,
  useGetChallengeTagsQuery,
} from 'store';

import { filterArrayByTags } from 'utils/array';
import { Category, ContentWidth } from 'styles';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useLocalizedText } from 'hooks/useLocalizedText';
import { getImage } from 'utils/asset';
import * as routes from 'router/routes';

import Loader from 'components/UI/Loader';
import Heading from 'components/UI/Heading';
import EmptyState from 'components/UI/EmptyState';
import { IconType } from 'components/UI/Icon';

import { CardList, CategoryHeader } from './styles';
import { useTagFilters } from 'hooks/useTagFilters';
import CompetitionCard from 'components/cards/CompetitionCard';

const ChallengesCategory: FC = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const getText = useLocalizedText();
  const { slug } = useParams();
  const { language } = useAppSelector(localizationSelector);

  // Set header
  useEffect(() => {
    if (slug) {
      dispatch(
        setLayoutHeader({
          title: intl.formatMessage({
            id: 'tabChallenges',
            defaultMessage: 'Challenges',
            description: 'Tab item for challenges',
          }),
          icon: IconType.Back,
          link: routes.COMPETE_AVAILABLE_CHALLENGES,
          favorite: {
            reference: slug,
            type: 'challengeCategory',
          },
        })
      );
    }
  }, [dispatch, intl, slug]);


  // Hooks
  const { data, isLoading } = useGetChallengeCategoryQuery({
    slug,
    language: language?.languageCode,
  });
  const tags = useGetChallengeTagsQuery({ language: language?.languageCode });

  // Extract tags from challenges
  const challengeTags = useMemo(() => {
    if (!data || !data.challenges) return [];
    return data.challenges.flatMap(challenge =>
      (challenge.tags?.filter(tag => tag && tag.slug).map(tag => tag.slug) || [])
    );
  }, [data]);

  // Filter tags that are found in challenges
  const filteredTags = useMemo(() => {
    if (!tags.data) return [];
    return tags.data.filter(tag => challengeTags.includes(tag.slug));
  }, [tags.data, challengeTags]);

  const [filters, filterModal] = useTagFilters('challenge', filteredTags);

  if (isLoading) {
    return <Loader color="blue" padding />;
  }

  // No data
  if (!data) {
    return (
      <EmptyState iconType={IconType.Muscle} padding>
        <FormattedMessage
          id="pageChallengesCategoryEmptyState"
          defaultMessage="Challenge category not found"
          description="Empty state for challenge category"
        />
      </EmptyState>
    );
  }

  const {
    challengeCategory: [category],
    challenges,
  } = data;

  return (
    <ContentWidth isSurface>
      <CategoryHeader>
        <Category>
          <FormattedMessage
            id="pageChallengesCategoryTitle"
            defaultMessage="Challenge"
            description="Page title for challenges category"
          />
        </Category>
        <Heading>{getText(category.title)}</Heading>
      </CategoryHeader>
      {filterModal}
      <CardList>
        {challenges
          .filter((item) => filterArrayByTags(filters, item.tags))
          .map(({ _id, title, image, difficulty, wellrPoints, slug }) => {
            return (
              <CompetitionCard
                key={_id}
                title={getText(title)}
                image={getImage(image)}
                difficulty={difficulty}
                wellrPoints={wellrPoints}
                link={`${routes.COMPETE_AVAILABLE_CHALLENGES}/${slug.current}`}
                type={"Challenge"}
              />
            );
          })}
      </CardList>
    </ContentWidth>
  );
};

export default ChallengesCategory;
