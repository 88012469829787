import { motion } from 'framer-motion';
import styled from 'styled-components';
import { breakpoints } from 'styles';

export const QuizHeader = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 32px;

  @media (max-width: ${breakpoints.m}px) {
    gap: 16px;
  }
`;

export const QuizBody = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  height: calc(100vh - 72px);

  @media (max-width: ${breakpoints.m}px) {
    height: calc(100vh - 106px);
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  max-width: 440px;
  width: 100%;
  overflow-y: auto;
  padding: 48px 0;

  @media (max-width: ${breakpoints.m}px) {
    align-items: center;
  }
`;

export const HeaderLeftCol = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 8px;

  @media (max-width: ${breakpoints.m}px) {
    justify-content: space-between;
  }
`;

export const HeaderRightCol = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const HealthCategory = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
`;

export const ResultContainer = styled(motion.div)`
  width: 100%;
  text-align: center;
  margin-bottom: 96px;
`;

export const ResultHeader = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
`;

export const ResultText = styled.p`
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  margin-bottom: 40px;
`;

export const ResultActions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;
