import toast from 'react-hot-toast';
import {
  isRejectedWithValue,
  Middleware,
  MiddlewareAPI,
} from '@reduxjs/toolkit';
import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import { backendApi } from 'config';
import { APIError, LocalizationState } from 'models';
import { logoutSession, refreshToken, RootState } from 'store';
import { defaultLanguage } from 'assets/data';
import { Mutex } from 'async-mutex';
import { FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';

export const defaultHeaders = {
  'Content-Type': 'application/json',
};

/**
 * Validate response for api services
 */
export async function validateResponse(response: Response): Promise<any> {
  const { status, ok } = response;

  // Empty body
  if (status === 204) {
    return null;
  }

  // Json
  const json = await response.json();
  if (!ok) {
    throw json;
  }
  return json;
}

/**
 * RTK Query headers with token
 */
export const prepareHeaders: FetchBaseQueryArgs['prepareHeaders'] = (
  headers,
  api
) => {
  const state = api.getState() as RootState;
  const token = state.auth.accessToken;

  if (token && api.endpoint !== 'getRefreshToken') {
    headers.set('Authorization', `Bearer ${token}`);
  }

  return headers;
};

/**
 * RTK Base Query with headers
 */
export const baseQuery = fetchBaseQuery({
  baseUrl: backendApi,
  credentials: 'include',
  prepareHeaders,
});

function isApiErrorWithMessage(error: unknown): error is { message: string } {
  return (
    typeof error === 'object' &&
    error != null &&
    'message' in error &&
    typeof (error as any).message === 'string'
  );
}

const tokenRefreshMutex = new Mutex();

/**
 * Extended base query to handle token expiration.
 * Use a mutex to prevent multiple parallel token refreshes.
 */
export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await tokenRefreshMutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  if (
    result.error &&
    result.error.status === 401 &&
    isApiErrorWithMessage(result.error.data) &&
    result.error.data.message === 'TOKEN_EXPIRED'
  ) {
    if (!tokenRefreshMutex.isLocked()) {
      const release = await tokenRefreshMutex.acquire();
      try {
        const state = api.getState() as RootState;
        // try to get a new token
        const { data: token } = await baseQuery(
          `/user/refresh-token?refreshToken=${state.auth.refreshToken}`,
          { ...api, endpoint: 'getRefreshToken' },
          {}
        );
        if (typeof token === 'string') {
          // store the new token
          api.dispatch(refreshToken(token));
          // retry the initial query
          result = await baseQuery(args, api, extraOptions);
        } else {
          logoutSession()(api.dispatch);
        }
      } finally {
        release();
      }
    } else {
      // wait for the mutex to be unlocked and retry the initial query
      await tokenRefreshMutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

/**
 * Find localized error message
 */
export function findLocalizedError(
  message: string,
  localization: LocalizationState
): string {
  const { language, errors } = localization;

  const foundKey = errors ? errors[message] : null;

  // Return message if key not found
  if (!foundKey) {
    return message;
  }

  // Find localized message
  const foundMessage =
    foundKey[language?.languageCode || defaultLanguage.languageCode];

  // Return message if localized message not found
  if (!foundMessage) {
    return message;
  }

  return foundMessage;
}

/**
 * Handle errors
 */
export const rtkQueryError: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      const state = api.getState() as RootState;
      const { data, status } = action.payload;

      // Show error message
      const error = data as APIError;
      if (error) {
        toast.error(findLocalizedError(error.message, state.localization));
      }

      // Handle unauthorized requests
      if (status === 401) {
        logoutSession()(api.dispatch);
      }
    }
    return next(action);
  };
