import React, { FC, Fragment } from 'react';
import { useOutletContext } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { DietContext } from 'models';

import DietPlanDay from 'components/fragments/DietPlanDay';
import Heading, { Tag } from 'components/UI/Heading';
import EmptyState from 'components/UI/EmptyState';
import { IconType } from 'components/UI/Icon';

import { CardList } from './styles';

const DietCategoryPlan: FC = () => {
  const { data, slug } = useOutletContext<DietContext>();

  const [{ dietPlanWeeks }] = data.diet;

  // Empty state
  if (!dietPlanWeeks || !dietPlanWeeks.length) {
    return (
      <EmptyState iconType={IconType.Food} padding>
        <FormattedMessage
          id="tabDietPlanEmptyState"
          defaultMessage="Diet plan not found"
          description="Empty state for diet plan"
        />
      </EmptyState>
    );
  }

  return (
    <Fragment>
      <Heading tag={Tag.H4}>
        <FormattedMessage
          id="tabDietPlan"
          defaultMessage="Diet plan"
          description="Tab item for diet plan"
        />
      </Heading>
      <CardList>
        {dietPlanWeeks.map(({ title, breakfastLunchDinner, key }, i) => {
          if (!breakfastLunchDinner) {
            return null;
          }
          const id = `${slug}-diet-plan-day-${i + 1}`;
          return (
            <DietPlanDay
              id={id}
              weekKey={key}
              key={key}
              days={breakfastLunchDinner}
              title={title}
            />
          );
        })}
      </CardList>
    </Fragment>
  );
};

export default DietCategoryPlan;
