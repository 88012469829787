import { FC } from 'react';
import { ThemeColor } from 'styles';

import { Container, Bar } from './styles';

type Props = {
  progress: number | null;
  color?: ThemeColor | string;
};

const ProgressBar: FC<Props> = ({ progress, color = 'blue' }) => {
  return (
    <Container>
      <Bar
        initial={{ width: 0 }}
        animate={{ width: `${progress ?? 0}%` }}
        transition={{ duration: 1 }}
        color={color}
      />
    </Container>
  );
};

export default ProgressBar;
