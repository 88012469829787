import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { IntlProvider, ReactIntlErrorCode } from 'react-intl';
import { type OnErrorFn } from '@formatjs/intl';
import { Toaster } from 'react-hot-toast';

import { useQuery } from 'hooks/useQuery';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import GlobalFonts from 'assets/fonts';
import languages from 'assets/languages';
import GlobalStyles, { defaultTheme } from 'styles';
import { defaultLanguage } from 'assets/data';
import { LanguageCode } from 'models';
import GlobalData from 'GlobalData';
import Router from 'router';

import { login, checkSession, localizationSelector } from 'store';
import { useLanguageInitializer } from 'hooks/useLanguageInitializer';
import Loader from 'components/UI/Loader';

const intlErrorHandler: OnErrorFn = (e) => {
  if (e.code === ReactIntlErrorCode.MISSING_TRANSLATION) {
    console.log(`Missing translation: ${e.descriptor?.id}`);
  } else {
    console.error(e);
  }
};

function App() {
  const dispatch = useAppDispatch();
  const { language } = useAppSelector(localizationSelector);
  const [accessToken, refreshToken] = useQuery('accessToken', 'refreshToken');

  // Initialize language
  useLanguageInitializer();

  // Login with tokens from app
  useEffect(() => {
    if (accessToken && refreshToken) {
      dispatch(login({ accessToken, refreshToken }));
    }
  }, [dispatch, accessToken, refreshToken]);

  // Get locale
  useEffect(() => {
    dispatch(checkSession());
  }, [dispatch]);

  // Check locale
  if (!language) {
    return (
      <ThemeProvider theme={defaultTheme}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <GlobalStyles />
          <Loader color="blue" />
        </div>
      </ThemeProvider>
    );
  }

  return (
    <IntlProvider
      locale={language.languageCode}
      defaultLocale={defaultLanguage.languageCode}
      messages={languages[language.languageCode as LanguageCode]}
      onError={intlErrorHandler}
    >
      <ThemeProvider theme={defaultTheme}>
        <GlobalFonts />
        <GlobalStyles />
        <GlobalData />
        <Router />
        <Toaster
          toastOptions={{
            position: 'top-center',
            duration: 5000,
            success: {
              iconTheme: {
                primary: defaultTheme.colors.success,
                secondary: defaultTheme.colors.white,
              },
            },
            error: {
              iconTheme: {
                primary: defaultTheme.colors.error,
                secondary: defaultTheme.colors.white,
              },
            },
          }}
        />
      </ThemeProvider>
    </IntlProvider>
  );
}

export default App;
