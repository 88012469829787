import { useMemo } from "react";
import { EntityType } from "./useOrganizationEntityOptions";
import { useIntl } from "react-intl";

type OrganizationEntityOptions = {
  departments: any[] | undefined;
  offices: any[] | undefined;
  headOffices: any[] | undefined;
  isLoading: boolean;
};

const useEntityOptions = (
  entityType: EntityType,
  organizationEntityOptions: OrganizationEntityOptions
) => {
  const intl = useIntl();

  const entityOptions = useMemo(() => {
    switch (entityType) {
      case EntityType.HeadOffice:
        return organizationEntityOptions.headOffices;
      case EntityType.Office:
        return organizationEntityOptions.offices;
      case EntityType.Department:
        return organizationEntityOptions.departments;
      default:
        return null;
    }
  }, [entityType, organizationEntityOptions]);

  const entityTypeOptions = useMemo(() => {
    return [
      {
        name: intl.formatMessage({
          id: 'everyoneInCompanyLabel',
          defaultMessage: 'Everyone in the company',
          description: 'Label for everyone option',
        }),
        value: EntityType.Company,
      },
      {
        name: intl.formatMessage({
          id: 'everyoneInHeadOfficeLabel',
          defaultMessage: 'Everyone within a specific head office',
          description: 'Label for everyone in head office option',
        }),
        value: EntityType.HeadOffice,
      },
      {
        name: intl.formatMessage({
          id: 'everyoneInOfficeLabel',
          defaultMessage: 'Everyone within a specific office',
          description: 'Label for everyone in office option',
        }),
        value: EntityType.Office,
      },
      {
        name: intl.formatMessage({
          id: 'everyoneInDepartmentLabel',
          defaultMessage: 'Everyone within a specific department',
          description: 'Label for everyone in department option',
        }),
        value: EntityType.Department,
      },
    ];
  }, [intl]);

  return { entityOptions, entityTypeOptions };
};

export default useEntityOptions;