import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { ContestTeamUser } from 'models';
import { useGetAssetImage } from 'hooks/useGetAssetImage';

import IconButton from 'components/UI/IconButton';
import Heading, { Tag } from 'components/UI/Heading';
import Icon, { IconType } from 'components/UI/Icon';

import { Item, ItemCol, ImgContainer, Img } from './styles';

type Props = {
  user: ContestTeamUser;
  isLast: boolean;
  userId?: string;
  captain?: string;
  onEditUser: () => void;
};

const CompetitionUserListItem: FC<Props> = ({
  user,
  userId,
  captain,
  isLast,
  onEditUser,
}) => {
  // Hooks
  const [imageSrc] = useGetAssetImage(user.profilePictureAssetId);

  const isMe = user.id === userId;
  const isCaptain = user.id === captain;

  return (
    <Item isLast={isLast}>
      <ItemCol>
        {
          <ImgContainer>
            {imageSrc && <Img src={imageSrc} alt={user.alias} />}
          </ImgContainer>
        }
        <Heading tag={Tag.H4}>
          {user.alias}{' '}
          {isCaptain && (
            <FormattedMessage
              id="competitionTopListCaptain"
              defaultMessage="(Captain)"
              description="Captain in competition top list"
            />
          )}
        </Heading>
      </ItemCol>
      <ItemCol>
        {isMe && (
          <IconButton padding onClick={onEditUser}>
            <Icon type={IconType.Edit} />
          </IconButton>
        )}
      </ItemCol>
    </Item>
  );
};

export default CompetitionUserListItem;
