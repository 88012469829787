import styled from 'styled-components';

type CardProps = {
  size: 'large' | 'small';
};

export const CardContent = styled.div<CardProps>`
  display: flex;
  flex-direction: column;
  max-width: ${({ size }) => (size === 'large' ? '336px' : '226px')};
  max-height: ${({ size }) => (size === 'large' ? '280px' : '210px')};
`;

export const CardImage = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
  position: relative;
`;

export const Badge = styled.div`
  position: absolute;
  border-radius: 16px;
  backdrop-filter: blur(2px);
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => `${theme.colors.black}33`};
  padding: 6px 8px;
  margin: 8px;
`;

export const ImageView = styled.img<CardProps>`
  width: 100%;
  height: ${({ size }) => (size === 'large' ? '200px' : '150px')};
  object-fit: cover;
  user-drag: none;
`;

export const CardText = styled.div`
  padding: 16px;
  color: ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.backgroundLight};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
  }
`;

export const CardAction = styled.div`
  padding: 16px;
`;

export const TextBottom = styled.p`
  color: ${({ theme }) => theme.colors.grayText};
  font-size: 14px;
  margin: 8px 0 0;
`;
