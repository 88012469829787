import React, { FC, Fragment, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { useAnswerContestTeamInviteMutation } from 'store';
import { ContestTeamInvite } from 'models';
import { Gap } from 'styles';

import Loader from 'components/UI/Loader';
import Button from 'components/UI/Button';
import StaticCard from 'components/UI/Cards/StaticCard';
import Heading, { Tag } from 'components/UI/Heading';
import EmptyState from 'components/UI/EmptyState/EmptyState';
import { IconType } from 'components/UI/Icon';

import { List, Item, Column, Byline } from './styles';

type Props = {
  data: ContestTeamInvite[];
  contestId: string;
};

const CompetitionTeamInvites: FC<Props> = ({ data, contestId }) => {
  // Hooks
  const [answerInvite, result] = useAnswerContestTeamInviteMutation();

  // Answer
  const onAnswer = useCallback(
    (inviteId: string, accept: boolean) => () => {
      answerInvite({
        contestId,
        inviteId,
        accept,
      });
    },
    [answerInvite, contestId]
  );

  // Loading
  if (result.isLoading) {
    return <Loader color="blue" padding />;
  }

  // No data
  if (!data.length) {
    return (
      <EmptyState iconType={IconType.Users} padding>
        <FormattedMessage
          id="competitionTeamInvitesEmptyState"
          defaultMessage="No team invitations found"
          description="Empty state for competition team invitations"
        />
      </EmptyState>
    );
  }

  return (
    <Fragment>
      <Heading tag={Tag.H4}>
        <FormattedMessage
          id="competitionTeamInvitesTitle"
          defaultMessage="Team invitations"
          description="Title for competition team invites"
        />
      </Heading>
      <StaticCard>
        <List>
          {data.map((item, i) => {
            return (
              <Item key={`team-invite-${i}`} isLast={data.length === i + 1}>
                <Column>
                  <Heading tag={Tag.H4}>{item.teamName}</Heading>
                  <Byline>
                    <FormattedMessage
                      id="competitionTeamInvitesByline"
                      defaultMessage="Invited by {captainName} ({ captainEmail })"
                      description="Byline for competition team invites"
                      values={{
                        captainName: item.captainName,
                        captainEmail: item.captainEmail,
                      }}
                    />
                  </Byline>
                </Column>
                <div>
                  <Button
                    background="blue"
                    onClick={onAnswer(item.inviteId, true)}
                  >
                    <FormattedMessage
                      id="confirmButton"
                      defaultMessage="Confirm"
                      description="Confirm button text"
                    />
                  </Button>
                </div>
              </Item>
            );
          })}
        </List>
      </StaticCard>
      <Gap />
    </Fragment>
  );
};

export default CompetitionTeamInvites;
