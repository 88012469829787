import styled from 'styled-components';

type MessageProps = {
  isMine?: boolean;
  isReply?: boolean;
};

export const MessageAuthor = styled.p<MessageProps>`
  color: ${({ theme, isReply }) => theme.colors.grayText};
  font-size: 12px;
    margin: ${({ isReply }) => (isReply ? '0' : '8px 0')};

`;

export const MessageRow = styled.div<MessageProps>`
  display: flex;
  flex-direction: column;
  align-items: ${({ isMine }) => (isMine ? 'flex-end' : 'flex-start')};
  margin: 8px 0;
  `;

  export const Message = styled.div<MessageProps>`
  background: ${({ theme, isMine, isReply }) =>
    isReply ? theme.colors['backgroundDark'] : theme.colors[isMine ? 'blue' : 'backgroundLight']};
  color: ${({ theme, isMine, isReply }) =>
    isReply ? theme.colors['grayText'] : theme.colors[isMine ? 'white' : 'black']};
  border-radius: 12px;
  padding: 8px 12px;
  justify-content: flex-end;
  cursor: ${({ isMine, isReply }) => (isMine && !isReply ? 'pointer' : 'initial')};
`;
