import { FC } from 'react';
import { FlexContainer } from 'styles';
import Button, { Props as ButtonProps } from '../Button/Button';
import Icon, { IconType } from '../Icon';

type Props = ButtonProps & {
  icon: IconType;
};

const ButtonWithIcon: FC<Props> = ({ children, icon, ...rest }) => {
  return (
    <Button {...rest}>
      <FlexContainer
        gap={10}
        alignItems="center"
        justifyContent="center"
        cursor="pointer"
      >
        <div>{children}</div>
        <div
          style={{
            height: '0px',
            overflow: 'visible',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Icon type={icon} color={rest.color} />
        </div>
      </FlexContainer>
    </Button>
  );
};

export default ButtonWithIcon;
