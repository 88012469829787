import { useIntl } from 'react-intl';

const useBaselineResultText = (
  value: number | null,
  baselineValue: number | null
) => {
  const intl = useIntl();
  if (baselineValue == null || value == null) {
    return null;
  }

  if (baselineValue > value) {
    return intl.formatMessage(
      {
        id: 'healthReportBaselineResultWorse',
        defaultMessage:
          'The result ({value}%) is worse than the Wellr average ({baselineValue}%)',
        description:
          'Text for when the result is worse than the Wellr baseline average',
      },
      { value, baselineValue }
    );
  } else if (baselineValue < value) {
    return intl.formatMessage(
      {
        id: 'healthReportBaselineResultBetter',
        defaultMessage:
          'The result ({value}%) is better than the Wellr average ({baselineValue}%)',
        description:
          'Text for when the result is better than the Wellr baseline average',
      },
      { value, baselineValue }
    );
  } else {
    return intl.formatMessage(
      {
        id: 'healthReportBaselineResultEqual',
        defaultMessage:
          'The result ({value}%) is equal to the Wellr average ({baselineValue}%)',
        description:
          'Text for when the result is equal to the Wellr baseline average',
      },
      { value, baselineValue }
    );
  }
};

export default useBaselineResultText;
