import { FC, useRef, ReactElement, useEffect, useCallback } from 'react';
import { useDetectOutsideClick } from 'hooks/useDetectOutsideClick';
import { DropdownMenuItem } from 'models';

import arrowTop from 'assets/vectors/arrow-top.svg';

import { MenuContainer, Menu, MenuItemInner, MenuItem, Arrow } from './styles';

type Props = {
  children: ReactElement;
  menu: DropdownMenuItem[];
  onClick?: (active: boolean) => void;
  direction?: 'up' | 'down';
  align?: 'left' | 'right';
};

const DropdownMenu: FC<Props> = ({
  onClick,
  children,
  menu,
  direction = 'down',
  align = 'right',
}) => {
  // Ref
  const dropdownRef = useRef(null);

  // Hooks
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);

  // Active status
  useEffect(() => {
    if (onClick) {
      onClick(isActive);
    }
  }, [isActive, onClick]);

  // Menu click
  const onClickMenu = useCallback(
    () => setIsActive(!isActive),
    [setIsActive, isActive]
  );

  return (
    <MenuContainer ref={dropdownRef}>
      <MenuItem onClick={onClickMenu}>{children}</MenuItem>
      <Menu isActive={isActive} direction={direction} align={align}>
        <ul>
          {menu.map(({ id, text, color, onClick }) => (
            <li key={id}>
              <MenuItemInner
                color={color}
                onClick={() => {
                  if (onClick) {
                    onClick();
                  }
                  onClickMenu();
                }}
              >
                {text}
              </MenuItemInner>
            </li>
          ))}
        </ul>
        <Arrow src={arrowTop} alt="arrow" direction={direction} align={align} />
      </Menu>
    </MenuContainer>
  );
};

export default DropdownMenu;
