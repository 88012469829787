import React, { FC, useCallback, useMemo } from 'react';
import { PortableText, PortableTextComponents } from '@portabletext/react';

import { localizationSelector } from 'store';
import { useAppSelector } from 'hooks/redux';
import { useLocalizedText } from 'hooks/useLocalizedText';
import { ContentBody } from 'models';
import { getImage } from 'utils/asset';
import * as routes from 'router/routes';

import ListMenu from 'components/UI/ListMenu';
import { IconType } from 'components/UI/Icon';

import { Video, Img, Container } from './styles';
import { ApiContentDefinition } from 'utils/api';

type MenuData = {
  text: string;
  link: string;
  icon: IconType;
};

type Props = {
  children: ContentBody | ApiContentDefinition;
  noLanguage?: boolean;
};

const RichText: FC<Props> = ({ children, noLanguage }) => {
  const getText = useLocalizedText();
  const { language } = useAppSelector(localizationSelector);

  // Render menu
  const renderMenu = useCallback(
    (item: MenuData) => (
      <Container>
        <ListMenu
          menu={[
            {
              id: 1,
              text: item.text,
              link: item.link,
              icon: item.icon,
            },
          ]}
        />
      </Container>
    ),
    []
  );

  const components = useMemo<PortableTextComponents>(
    () => ({
      types: {
        image: ({ value }) => {
          const image = getImage(value);

          if (!image) {
            return null;
          }

          return <Img src={image.src} alt={image.alt} />;
        },
        movieUrlReference: ({ value }) => {
          return <Video key={value._key} src={value.url} allowFullScreen />;
        },
        exercise: ({ value }) => {
          return renderMenu({
            text: getText(value.title),
            link: `${routes.EXERCISES}/${value.slug}`,
            icon: IconType.Exercise,
          });
        },
        exerciseProgram: ({ value }) => {
          return renderMenu({
            text: getText(value.title),
            link: `${routes.EXERCISE_PROGRAM}/${value.slug}`,
            icon: IconType.Exercise,
          });
        },
        article: ({ value }) => {
          return renderMenu({
            text: getText(value.title),
            link: `${routes.INSPIRATION_ARTICLE}/${value.slug}`,
            icon: IconType.Inspiration,
          });
        },
        recipe: ({ value }) => {
          return renderMenu({
            text: getText(value.title),
            link: `${routes.RECIPE}/${value.slug}`,
            icon: IconType.Food,
          });
        },
        diet: ({ value }) => {
          return renderMenu({
            text: getText(value.title),
            link: `${routes.DIET_CATEGORY}/${value.slug}`,
            icon: IconType.Food,
          });
        },
        exerciseDescriptionPair: ({ value }) => {
          if (!value.exercise) {
            return null;
          }
          const { title, slug } = value.exercise;
          return renderMenu({
            text: value.title
              ? `${getText(value.title)} - ${getText(title)}`
              : getText(title),
            link: `${routes.EXERCISES}/${slug.current}`,
            icon: IconType.Exercise,
          });
        },
        contest: ({ value }) => {
          return renderMenu({
            text: getText(value.title),
            link: `${routes.COMPETE_AVAILABLE_CHALLENGES}/${value.slug}`,
            icon: IconType.Flag,
          });
        },
      },
    }),
    [renderMenu, getText]
  );

  if (!language) {
    return null;
  }

  return (
    <PortableText
      //@ts-ignore
      value={noLanguage ? children : children[language.languageCode]}
      components={components}
    />
  );
};

export default RichText;
