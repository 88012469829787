import React, { FC, Fragment, useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { useLeaveContestTeamMutation } from 'store';
import { ActionContainer } from 'styles';

import Loader from 'components/UI/Loader';
import Button from 'components/UI/Button';
import Icon, { IconType } from 'components/UI/Icon';
import IconButton from 'components/UI/IconButton';
import Heading, { Tag } from 'components/UI/Heading';
import SlideoutModal from 'components/UI/SlideoutModal';

import { Header, Body } from './styles';

type Props = {
  contestId: string;
  isOpen: boolean;
  onClose: () => void;
};

const LeaveTeamModal: FC<Props> = ({ contestId, isOpen, onClose }) => {
  // Hooks
  const [leaveTeam, leaveResult] = useLeaveContestTeamMutation();

  // Success
  useEffect(() => {
    if (leaveResult.isSuccess) {
      onClose();
    }
  }, [leaveResult, onClose]);

  // Actions
  const onLeave = useCallback(async () => {
    await leaveTeam({ contestId });
  }, [leaveTeam, contestId]);

  // Content
  const content = useMemo(() => {
    if (leaveResult.isLoading) {
      return <Loader padding color="blue" />;
    }
    return (
      <Fragment>
        <p>
          <FormattedMessage
            id="leaveCompetitionTeamModalDescription"
            defaultMessage="Are you sure you want to leave the team?"
            description="Leave competition team modal description"
          />
        </p>
        <ActionContainer>
          <Button background="error" onClick={onLeave}>
            <FormattedMessage
              id="leaveTeamButton"
              defaultMessage="Leave team"
              description="Leave team button"
            />
          </Button>
        </ActionContainer>
      </Fragment>
    );
  }, [leaveResult, onLeave]);

  return (
    <SlideoutModal isOpen={isOpen} onClose={onClose}>
      <div>
        <Header>
          <Heading tag={Tag.H3}>
            <FormattedMessage
              id="leaveCompetitionTeamModalTitle"
              defaultMessage="Leave team"
              description="Leave competition team modal title"
            />
          </Heading>
          <IconButton onClick={onClose} padding>
            <Icon type={IconType.Close} />
          </IconButton>
        </Header>
        <Body>{content}</Body>
      </div>
    </SlideoutModal>
  );
};

export default LeaveTeamModal;
