import { FC, ReactNode, MouseEvent } from 'react';
import { ButtonBase } from './styles';

type Props = {
  children: ReactNode;
  padding?: boolean;
  disabled?: boolean;
  title?: string;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
};

const IconButton: FC<Props> = ({
  onClick,
  children,
  title,
  padding = false,
  disabled = false,
}) => {
  return (
    <ButtonBase
      type="button"
      onClick={onClick}
      padding={padding}
      disabled={disabled}
      title={title}
    >
      {children}
    </ButtonBase>
  );
};

export default IconButton;
