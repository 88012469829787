import { FC, useEffect } from 'react';
import { getAppStoreLink } from 'utils/link';

const AppStoreRedirect: FC = () => {
  useEffect(() => {
    window.location.href = getAppStoreLink();
  }, []);

  return null;
};

export default AppStoreRedirect;
