import styled from 'styled-components';

export const Grid = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 16px;

  h3 {
    margin: 0;
  }
`;
