import styled from 'styled-components';

export const Container = styled.div<{ type: 'grey' | 'default' }>`
  display: flex;
  width: ${({ type }) => (type === 'grey' ? '20px' : '16px')};
  height: ${({ type }) => (type === 'grey' ? '20px' : '16px')};
  border-radius: 50%;
  background: ${({ theme, type }) =>
    type === 'grey' ? theme.colors.backgroundDark : theme.colors.blue};
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;

export const CheckIcon = styled.svg`
  fill: none;
  stroke: ${({ theme }) => theme.colors.white};
  stroke-width: 2px;
  padding: 3px;
  width: 24px;
  height: 24px;
`;
