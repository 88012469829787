import React, { FC, useEffect, Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ContentWidth } from 'styles';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  localizationSelector,
  setLayoutHeader,
  useGetAvailableCompanyCompetitionsQuery,
} from 'store';

import Heading, { Tag } from 'components/UI/Heading';
import Loader from 'components/UI/Loader';

import { CardList } from './styles';
import * as routes from 'router/routes';
import CompetitionCard from 'components/cards/CompetitionCard';
import { getImage } from 'utils/asset';
import { useLocalizedText } from 'hooks/useLocalizedText';
import EmptyState from 'components/UI/EmptyState';
import { IconType } from 'components/UI/Icon';

const AdminCompetitions: FC = () => {
  const intl = useIntl();
  const { language } = useAppSelector(localizationSelector);
  const dispatch = useAppDispatch();

  // Hooks
  const getText = useLocalizedText();
  const { data, isLoading } = useGetAvailableCompanyCompetitionsQuery({
    language: language?.languageCode,
  });

  // Set header
  useEffect(() => {
    dispatch(
      setLayoutHeader({
        title: intl.formatMessage({
          id: 'pageAdminCompetitionsTitle',
          defaultMessage: 'Competitions',
          description: 'Page title for admin competitions page',
        }),
        icon: IconType.Back,
        link: routes.ADMIN_COMPETITIONS,
      })
    );
  }, [dispatch, intl]);

  // Loading
  if (isLoading) {
    return <Loader color="blue" padding />;
  }

  // No data
  if (!data) {
    return null;
  }

  return (
    <ContentWidth isSurface>
      <Fragment>
        <Heading tag={Tag.H4}>
          <FormattedMessage
            id="pageCompeteAvailableCompetitionsTitle"
            defaultMessage="Available competitions"
            description="Section title for available competitions"
          />
        </Heading>
        <CardList>
          {!data?.length && (
            <EmptyState iconType={IconType.Flag} padding>
              <FormattedMessage
                id="availableCompetitionsEmptyState"
                defaultMessage="No available competitions"
                description="Empty state for available competitions"
              />
            </EmptyState>
          )}
          {data.map((item, i) => (
            <CompetitionCard
              key={item.slug}
              title={getText(item.title)}
              image={getImage(item.image)}
              wellrPoints={item.wellrPoints}
              link={`${routes.ADMIN_COMPETITIONS}/available/${item.slug}`}
              accepted={false}
              type={item.type}
            />
          ))}
        </CardList>
      </Fragment>
    </ContentWidth>
  );
};

export default AdminCompetitions;
