import React, { FC, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import DropdownMenu from 'components/UI/DropdownMenu';

import { ChatAttachment, ChatMessage } from 'models/chat/chat';

import { Message, MessageRow, MessageAuthor } from './styles';
import ChatMessageItemImage from './ChatMessageItemImage';
import Icon, { IconType } from 'components/UI/Icon';

type Props = {
  message: ChatMessage;
  onRemove: (id: string) => void;
  onReply: (id: string) => void;
};

const ChatMessageItem: FC<Props> = ({ message, onRemove, onReply }) => {
  const intl = useIntl();

  const renderAttachments = useCallback(
    (attachment: ChatAttachment) => (
      <ChatMessageItemImage
        key={attachment.id}
        attachmentId={attachment.id}
        chatEntryId={message.id}
        alt={attachment.fileName}
      />
    ),
    [message]
  );

  // Message with or without button
  const messageItem = useMemo(() => {
    if (message.isMine) {
      return (
        <DropdownMenu
          menu={[
            {
              id: 1,
              text: intl.formatMessage({
                id: 'chatModalRemoveMessage',
                defaultMessage: 'Remove message',
                description: 'Action for remove message in chat modal',
              }),
              color: 'error',
              onClick: () => onRemove(message.id),
            },
          ]}
        >
          <Message isMine>
            {message.chatMessage}
            {message.chatAttachments.map(renderAttachments)}
          </Message>
        </DropdownMenu>
      );
    }
    return (
      <DropdownMenu
        align='left'
        menu={[
          {
            id: 2,
            text: intl.formatMessage({
              id: 'chatModalReplyMessage',
              defaultMessage: 'Reply',
              description: 'Action for reply to message in chat modal',
            }),
            color: 'blue',
            onClick: () => onReply(message.id),

          }
        ]}
      >
        <Message>
          {message.chatMessage}
          {message.chatAttachments.map(renderAttachments)}
        </Message>
      </DropdownMenu>
    );
  }, [message.isMine, message.chatMessage, message.chatAttachments, message.id, intl, renderAttachments, onRemove, onReply]);

  return (
    <MessageRow isMine={message.isMine}>
      {message.replyTo ?
        ''
        :
        <>
          <MessageAuthor>{message.userAlias}</MessageAuthor>
          {messageItem}
        </>
      }
      {message.replyTo && (
        <>
          <MessageAuthor>
            <Icon type={IconType.ArrowUpLeft} size={12} />
            {message.replyTo.isMine ? (
              `${message.userAlias}
              ${intl.formatMessage({
                id: 'chatModalHasRepliedToMe',
                defaultMessage: 'has replied to me',
                description: 'Indicates someone else replied to a message in chat modal',
              })}`
            ) :  message.isMine ? (
              `${intl.formatMessage({
                id: 'chatModalIHaveRepliedTo',
                defaultMessage: 'I have replied to',
                description: 'Indicates the user replied to someone else in chat modal',
              })} ${message.replyTo.userAlias}`
            ) : (
              `${message.userAlias}
              ${intl.formatMessage({
                id: 'chatModalHasRepliedTo',
                defaultMessage: 'has replied to',
                description: 'Reply to message in chat modal',
              })} ${message.replyTo.userAlias}`
            )}
          </MessageAuthor>
          <Message isReply>
            <MessageAuthor isReply>{message.replyTo.userAlias}</MessageAuthor>
            {message.replyTo.message}
            <div style={{ position: 'relative', top: '1rem', right: '-13px'}}>
              {messageItem}
            </div>
          </Message>
        </>)}
    </MessageRow>
  );
};

export default ChatMessageItem;
