import { FC, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';

import Button from 'components/UI/Button/Button';
import { ActionContainer } from 'styles';
import { useUseGetUmbrellaCompanyQuery } from 'store';
import { FieldGrid } from './styles';
import Loader from 'components/UI/Loader';
import SelectField from 'components/UI/SelectField';

type Props = {
  submitForm: (data: GetUmbrellaFormData) => void;
  umbrellaCompanyId?: string;
};

export type GetUmbrellaFormData = {
  umbrellaId: string[];
};

const UpdateUserUmbrellaAdminForm: FC<Props> = ({ submitForm, umbrellaCompanyId }) => {
  const intl = useIntl();
  const { handleSubmit, register, formState: { errors } } = useForm<GetUmbrellaFormData>({
    defaultValues: {
      umbrellaId: [],
    },
  });

  const { data: umbrellaCompanyData, isLoading } = useUseGetUmbrellaCompanyQuery({ umbrellaCompanyId: umbrellaCompanyId || '' });
  const onSubmit = (formData: GetUmbrellaFormData) => {
    submitForm({ umbrellaId: formData.umbrellaId }); // Pass the entire formData object to submitForm function
  };
  const options = useMemo(() => [
    {
      value: '',
      name: '',
    },
    {
      value: umbrellaCompanyData?.id?.toString() || '',
      name: umbrellaCompanyData?.description || '',
    }
  ], [umbrellaCompanyData]);

  if (isLoading) {
    return <Loader />;
  }


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FieldGrid>
        <SelectField
          label={intl.formatMessage({
            id: 'inputUmbrellaLabel',
            defaultMessage: 'umbrella',
            description: 'Label for umbrella input',
          })}
          options={options}
          placeholder=''
          defaultValue=''
          register={register('umbrellaId')}
          error={errors.umbrellaId}
        />
      </FieldGrid>
      <ActionContainer>
        <Button type="submit" background="blue">
          <FormattedMessage
            id="saveButton"
            defaultMessage="Save"
            description="Save button text"
          />
        </Button>
      </ActionContainer>
    </form>
  );
};

export default UpdateUserUmbrellaAdminForm;
