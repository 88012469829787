import React, { FC, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { useOutletContext } from 'react-router-dom';

import { getImage } from 'utils/asset';
import * as routes from 'router/routes';
import { DietContext } from 'models';
import { useLocalizedText } from 'hooks/useLocalizedText';

import Heading, { Tag } from 'components/UI/Heading';
import CategoryCard from 'components/UI/Cards/CategoryCard';

import { CardList } from './styles';

const DietCategoryRecipes: FC = () => {
  const getText = useLocalizedText();
  const {
    data: { recepies },
  } = useOutletContext<DietContext>();

  return (
    <Fragment>
      <Heading tag={Tag.H4}>
        <FormattedMessage
          id="pageDietSectionTitleRecipes"
          defaultMessage="Recipes"
          description="Page section title for recipes"
        />
      </Heading>
      <CardList>
        {recepies.map(({ _id, title, image, slug }) => {
          return (
            <CategoryCard
              key={_id}
              title={getText(title)}
              link={`${routes.RECIPE}/${slug.current}`}
              image={getImage(image)}
            />
          );
        })}
      </CardList>
    </Fragment>
  );
};

export default DietCategoryRecipes;
