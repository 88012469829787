import styled from 'styled-components';

type Props = {
  padding: boolean;
  inverted: boolean;
  align: 'left' | 'center' | 'right';
  compact: boolean;
};

export const Container = styled.div<Props>`
  text-align: ${({ align }) => align};
  padding: ${({ padding }) => (padding ? '48px 24px' : '0')};
  color: ${({ theme, inverted }) =>
    theme.colors[inverted ? 'white' : 'grayText']};
`;
