import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { LayoutGroup } from 'framer-motion';

import { ContentWidth, ThemeColor } from 'styles';
import * as routes from 'router/routes';
import { capitalize } from 'utils/string';
import { useGetAssetImage } from 'hooks/useGetAssetImage';
import {
  DropdownMenuItem,
  FavoriteBody,
  Language,
  MenuItem,
  MenuType,
  NotificationableMenuItem,
  Reminder,
  TabMenuItem,
} from 'models';

import FlagIcon from 'components/UI/FlagIcon';
import Icon, { IconType } from 'components/UI/Icon';
import Heading, { Tag } from 'components/UI/Heading';
import IconButton from 'components/UI/IconButton';
import DropdownMenu from 'components/UI/DropdownMenu';
import ContextMenu from 'components/UI/ContextMenu';
import TabMenu from 'components/UI/TabMenu';

import NavOverlay from './NavOverlay';
import {
  Container,
  LargeHeader,
  LargeTitle,
  SmallHeader,
  Menu,
  LogoLink,
  MenuLink,
  NavItem,
  Nav,
  SmallNav,
  SmallLogo,
  SmallContainer,
  SmallFooter,
  SmallFooterItem,
  MenuBorder,
  BackButton,
  ButtonGrid,
  LangText,
  LangItem,
  LargeTitleGrid,
  IconGrid,
  CompanyLogo,
  HeadingWithLogo,
  Notification,
  IconPadding,
  MenuButton,
} from './styles';
import { useGetCompanyCommunicationQuery } from 'store';
import CompanySelector from 'components/fragments/CompanySelector/CompanySelector';
import { usePretendCompany } from 'hooks/usePretendCompany';
import { useHeaderMenu } from 'hooks/useMenu';
import { useMenuType } from 'hooks/useMenuType';
import {
  notificationSelector,
  useGetNewNotificationsCountQuery
} from '../../../store/notificationService/notificationService';
import Bubble from '../../UI/Bubble';
import { useAppSelector } from '../../../hooks/redux';
import UmbrellaCompanySelector from 'components/fragments/UmbrellaCompanySelector';
import RegisterContentModal from 'components/modals/RegisterContentModal';

type Props = {
  onSetLanguage: (language: Language) => void;
  onSetFavorite: () => void;
  onLogout: () => void;
  languages: Language[];
  language: Language;
  pageTitle: string;
  companyInHeader: boolean;
  icon: IconType | null;
  reminder: Reminder | null;
  link: string | null;
  isWellrAdmin: boolean;
  isCompanyAdmin: boolean;
  isOrganizationAdmin: boolean;
  favorite: FavoriteBody | null;
  isFavorised?: boolean;
  inverted?: boolean;
};

const Header: FC<Props> = ({
  onSetLanguage,
  onSetFavorite,
  onLogout,
  pageTitle,
  companyInHeader,
  icon,
  reminder,
  link,
  languages,
  language,
  favorite,
  isOrganizationAdmin,
  isWellrAdmin,
  isCompanyAdmin,
  isFavorised = false,
  inverted = false,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const RE_FETCH_TIMEOUT_MS = 1000 * 10

  // Store
  const { activeTab, setActiveTab } = useMenuType();

  // State
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isLangMenuOpen, setIsLangMenuOpen] = useState<boolean>(false);
  const [lastNewNotificationCount, setLastNewNotificationCount] = useState(0);
  const { data, refetch, fulfilledTimeStamp } = useGetNewNotificationsCountQuery(undefined, {
    refetchOnFocus: lastNewNotificationCount + RE_FETCH_TIMEOUT_MS < Date.now(),
    pollingInterval: 1000 * 60 * 5,
  });
  const { notificationUpdateTime } = useAppSelector(notificationSelector)
  const [registerModalOpen, setRegisterModalOpen] = useState<boolean>(false);

  const onOpenModal = useCallback(() => setRegisterModalOpen(true), []);
  const onCloseModal = useCallback(() => setRegisterModalOpen(false), []);

  // Toogles
  const toggleMenu = useCallback(
    () => setIsMenuOpen(!isMenuOpen),
    [isMenuOpen]
  );
  const toggleLangMenu = useCallback(
    () => setIsLangMenuOpen(!isLangMenuOpen),
    [isLangMenuOpen]
  );

  useEffect(() => {
    setLastNewNotificationCount(Date.now())
  }, [fulfilledTimeStamp])

  useEffect(() => {
    const payload = (event: any) => {
      console.log('Fetching new notification count')
      refetch()
    }
    window.addEventListener('canUpdateNotificationCount', payload);
    return () => window.removeEventListener('canUpdateNotificationCount', payload);
  });

  useEffect(() => {
    refetch()
  }, [refetch, notificationUpdateTime]);

  useEffect(() => {
    setLastNewNotificationCount(Date.now())
  }, [fulfilledTimeStamp])

  useEffect(() => {
    refetch()
  }, [refetch, notificationUpdateTime]);

  const wellrAdminMenu: NotificationableMenuItem[] = useMemo(() => {
    return [
      {
        id: 1,
        text: intl.formatMessage({
          id: 'menuUsers',
          defaultMessage: 'Users',
          description: 'Menu item for users',
        }),
        link: routes.ADMIN_USERS,
        icon: IconType.Users,
        requiresWellrAdmin: true,
        hasNotification: false,
      },
      {
        id: 'usage-stats',
        text: intl.formatMessage({
          id: 'menuUsageStats',
          defaultMessage: 'Usage statistics',
          description: 'Menu item for usage stats',
        }),
        link: routes.ADMIN_USER_STATS,
        icon: IconType.BarChart,
        requiresWellrAdmin: true,
        hasNotification: false,
      },
      {
        id: 2,
        text: intl.formatMessage({
          id: 'menuCompanies',
          defaultMessage: 'Companies',
          description: 'Menu item for companies',
        }),
        link: routes.ADMIN_COMPANIES,
        icon: IconType.Briefcase,
        requiresWellrAdmin: true,
        hasNotification: false,
      },
      {
        id: 3,
        text: intl.formatMessage({
          id: 'menuUmbrellaCompanies',
          defaultMessage: 'Umbrella companies',
          description: 'Menu item for umbrella companies',
        }),
        link: routes.ADMIN_UMBRELLA_COMPANIES,
        icon: IconType.Umbrella,
        requiresWellrAdmin: true,
        hasNotification: false,
      },
    ];
  }, [intl]);

  // Admin menu
  const adminMenu: NotificationableMenuItem[] = useMemo(() => {
    const menu = [
      {
        id: 1,
        text: intl.formatMessage({
          id: 'menuOverview',
          defaultMessage: 'Overview',
          description: 'Menu item for overview',
        }),
        link: routes.ADMIN_OVERVIEW,
        icon: IconType.Home,
        requiresWellrAdmin: false,
        hasNotification: false,
      },
      {
        id: 2,
        text: intl.formatMessage({
          id: 'menuHealthReport',
          defaultMessage: 'Health report',
          description: 'Menu item for health report',
        }),
        link: routes.ADMIN_HEALTH_REPORT,
        icon: IconType.HealthReport,
        requiresWellrAdmin: false,
        hasNotification: false,
      },
      {
        id: 'admin-pulse-tests',
        text: intl.formatMessage({
          id: 'menuAdminPulseTests',
          defaultMessage: 'Pulse tests',
          description: 'Menu item for admin pulse tests',
        }),
        link: routes.ADMIN_PULSE_TESTS,
        icon: IconType.Pulse,
        requiresWellrAdmin: false,
        hasNotification: false,
      },
      {
        id: 3,
        text: intl.formatMessage({
          id: 'menuCompetitions',
          defaultMessage: 'Competitions',
          description: 'Menu item for competitions',
        }),
        link: routes.ADMIN_COMPETITIONS,
        icon: IconType.Competition,
        requiresWellrAdmin: false,
        hasNotification: false,
      },
      {
        id: 4,
        text: intl.formatMessage({
          id: 'menuOrganisation',
          defaultMessage: 'Organisation',
          description: 'Menu item for organisation',
        }),
        link: routes.ADMIN_ORGANISATION,
        icon: IconType.Departments,
        requiresWellrAdmin: false,
        hasNotification: false,
      },
      {
        id: 5,
        text: intl.formatMessage({
          id: 'menuCompanyUsers',
          defaultMessage: 'Users',
          description: 'Company admin menu item for users',
        }),
        link: routes.ADMIN_COMPANY_USERS,
        icon: IconType.Users,
        requiresWellrAdmin: false,
        hasNotification: false,
      },
      {
        id: 6,
        text: intl.formatMessage({
          id: 'menuInspiration',
          defaultMessage: 'Inspiration',
          description: 'Menu item for inspiration',
        }),
        link: routes.INSPIRATION,
        icon: IconType.Inspiration,
        hasNotification: false,
      }
    ];
    if (!isWellrAdmin) {
      return menu.filter((item) => !item.requiresWellrAdmin);
    }
    return menu;
  }, [intl, isWellrAdmin]);

  // Main menu
  const mainMenu: NotificationableMenuItem[] = useHeaderMenu();

  const isWellrView = activeTab === MenuType.Regular;
  const isCompanyAdminView = activeTab === MenuType.CompanyAdmin;
  const isWellrAdminView = activeTab === MenuType.WellrAdmin;


  // Dropdown menu
  const dropDownMenu: DropdownMenuItem[] = useMemo(() => {
    return [
      ...(isCompanyAdmin || isWellrAdmin
        ? [
          {
            id: 1,
            text: intl.formatMessage(
              {
                id: 'menuSwitchAdmin',
                defaultMessage: 'Switch to {name}',
                description:
                  'Menu item for switch between Admin and Wellr menu',
              },
              {
                name:
                  isCompanyAdminView || isWellrAdminView ? 'Wellr' : 'Admin',
              }
            ),
            onClick: () => {
              if (isCompanyAdminView || isWellrAdminView) {
                setActiveTab(MenuType.Regular);
                navigate(routes.OVERVIEW);
              } else {
                setActiveTab(MenuType.CompanyAdmin);
                navigate(routes.ADMIN_OVERVIEW);
              }
            },
          },
        ]
        : []),
      ...(isWellrAdmin
        ? [
          {
            id: 2,
            text: intl.formatMessage(
              {
                id: 'menuSwitchAdminType',
                defaultMessage: 'Switch to {name}',
                description:
                  'Menu item for switch between Admin and Wellr Admin menu',
              },
              {
                name:
                  isCompanyAdminView || isWellrView ? 'Wellr Admin' : 'Admin',
              }
            ),
            onClick: () => {
              if (isCompanyAdminView || isWellrView) {
                setActiveTab(MenuType.WellrAdmin);
                navigate(routes.ADMIN_USERS);
              } else {
                setActiveTab(MenuType.CompanyAdmin);
                navigate(routes.ADMIN_OVERVIEW);
              }
            },
          },
        ]
        : []),
      {
        id: 3,
        text: intl.formatMessage({
          id: 'menuSupport',
          defaultMessage: 'Support',
          description: 'Menu item for support',
        }),
        onClick: () => navigate(`${routes.INSPIRATION_ARTICLE}/faq`),
      },
      {
        id: 4,
        text: intl.formatMessage({
          id: 'menuLogout',
          defaultMessage: 'Logout',
          description: 'Menu item for logout',
        }),
        color: 'error',
        onClick: onLogout,
      },
    ];
  }, [
    isCompanyAdmin,
    isWellrAdmin,
    intl,
    isCompanyAdminView,
    isWellrAdminView,
    isWellrView,
    onLogout,
    setActiveTab,
    navigate,
  ]);

  // Language menu
  const languageMenu: DropdownMenuItem[] = useMemo(() => {
    return languages.map((item, i) => ({
      id: i,
      text: (
        <LangItem>
          <FlagIcon countryCode={item.countryCode} />
          <LangText>{item.name}</LangText>
        </LangItem>
      ),
      onClick: () => onSetLanguage(item),
    }));
  }, [onSetLanguage, languages]);

  // Small footer menu
  const footerMenu: MenuItem[] = useMemo(() => {
    return [
      {
        id: 1,
        text: intl.formatMessage({
          id: 'menuSupport',
          defaultMessage: 'Support',
          description: 'Menu item for support',
        }),
        onClick: () => {
          navigate(`${routes.INSPIRATION_ARTICLE}/faq`);
          toggleMenu();
        },
      },
      {
        id: 2,
        text: intl.formatMessage({
          id: 'menuLogout',
          defaultMessage: 'Logout',
          description: 'Menu item for logout',
        }),
        color: 'error',
        onClick: onLogout,
      },
    ];
  }, [onLogout, navigate, toggleMenu, intl]);

  // Menu
  const menu = useMemo(() => {
    switch (activeTab) {
      case MenuType.CompanyAdmin:
        return adminMenu;
      case MenuType.WellrAdmin:
        return wellrAdminMenu;
      default:
        return mainMenu;
    }
  }, [activeTab, adminMenu, wellrAdminMenu, mainMenu]);

  // Tab menu
  const tabMenu: TabMenuItem[] = useMemo(() => {
    const items: TabMenuItem[] = [
      {
        id: MenuType.Regular,
        text: intl.formatMessage({
          id: 'tabWellr',
          defaultMessage: 'Wellr',
          description: 'Tab item for Wellr',
        }),
      },
      {
        id: MenuType.CompanyAdmin,
        text: intl.formatMessage({
          id: 'tabAdmin',
          defaultMessage: 'Admin',
          description: 'Tab item for admin',
        }),
        color: 'purple',
      },
    ];
    if (isWellrAdmin) {
      items.push({
        id: MenuType.WellrAdmin,
        text: intl.formatMessage({
          id: 'tabWellrAdmin',
          defaultMessage: 'Wellr-admin',
          description: 'Tab item for wellr admin',
        }),
        color: 'black',
      });
    }
    return items;
  }, [intl, isWellrAdmin]);

  // Click
  const onClick = useCallback(() => {
    if (link) {
      return navigate(link);
    }
    if (icon === IconType.Back) {
      return navigate(-1);
    }
    return null;
  }, [link, icon, navigate]);

  const companyId = usePretendCompany(!companyInHeader);

  const { companyLogoAssetId, companyLogoLoaded } =
    useGetCompanyCommunicationQuery(
      {
        language: language?.languageCode,
        companyId,
      },
      {
        skip: !companyInHeader,
        selectFromResult: (response) => ({
          companyLogoLoaded: response.isSuccess || response.isError,
          companyLogoAssetId:
            response?.data?.company?.logoAssetId ??
            response?.data?.umbrellaCompany?.logoAssetId,
        }),
      }
    );

  const [companyLogoSrc] = useGetAssetImage(companyLogoAssetId);

  // Small screen title
  const smallTitle = useMemo(() => {
    if (icon) {
      return (
        <IconButton onClick={onClick}>
          <Icon type={icon} color={inverted ? 'white' : 'black'} />
        </IconButton>
      );
    }
    if (
      companyInHeader &&
      (!companyLogoLoaded ||
        (companyLogoAssetId != null && companyLogoSrc === undefined))
    ) {
      // Prevent flickering
      return null;
    }
    if (companyInHeader && companyLogoSrc) {
      return <CompanyLogo src={companyLogoSrc} alt="company logo" />;
    }
    return <Heading>{pageTitle}</Heading>;
  }, [
    icon,
    companyInHeader,
    companyLogoLoaded,
    companyLogoAssetId,
    companyLogoSrc,
    pageTitle,
    onClick,
    inverted,
  ]);

  // Large screen title
  const largeTitle = useMemo(() => {
    if (icon === IconType.Back) {
      return (
        <ButtonGrid>
          <BackButton onClick={onClick}>
            <IconButton>
              <Icon type={icon} />
            </IconButton>
            <Heading tag={Tag.H5}>{pageTitle}</Heading>
          </BackButton>
        </ButtonGrid>
      );
    }
    if (companyInHeader && companyLogoSrc) {
      return (
        <HeadingWithLogo>
          <Heading>{pageTitle}</Heading>
          <CompanyLogo src={companyLogoSrc} alt="company logo" />
        </HeadingWithLogo>
      );
    }
    return <Heading>{pageTitle}</Heading>;
  }, [onClick, pageTitle, icon, companyInHeader, companyLogoSrc]);

  // Get Favorite color
  const favoriteColor = useMemo(() => {
    if (isFavorised) {
      return inverted ? 'white' : 'blue';
    }
    return inverted ? 'white' : 'black';
  }, [inverted, isFavorised]);

  //Get Reminder color
  const reminderColor = useMemo(() => {
    return inverted ? 'white' : 'black';
  }, [inverted]);

  // Reminder small
  const smallReminder = useMemo(() => {
    if (!reminder) {
      return null;
    }
    return (
      <NavItem
        onClick={onOpenModal}>
        <Icon type={IconType.Reminder}
          color={reminderColor} />
      </NavItem>
    );
  }, [onOpenModal, reminder, reminderColor]);

  // Favorite small
  const smallFavorite = useMemo(() => {
    if (!favorite) {
      return null;
    }
    return (
      <NavItem onClick={onSetFavorite}>
        <Icon
          type={IconType[isFavorised ? 'Favorites' : 'FavoritesOutline']}
          color={favoriteColor}
        />
      </NavItem>
    );
  }, [favorite, isFavorised, favoriteColor, onSetFavorite]);

  // Large reminder
  const largeReminder = useMemo(() => {
    if (!reminder) {
      return null;
    }
    return (
      <IconPadding>
        <IconButton onClick={() => setRegisterModalOpen(true)}>
          <Icon
            type={IconType.Reminder}
            color={'black'}
          />
        </IconButton>
      </IconPadding>
    );
  }, [reminder]);

  // Large favorite
  const largeFavorite = useMemo(() => {
    if (!favorite) {
      return null;
    }
    return (
      <IconPadding style={{ paddingRight: 0 }}>
        <IconButton onClick={onSetFavorite}>
          <Icon
            type={IconType[isFavorised ? 'Favorites' : 'FavoritesOutline']}
            color={isFavorised ? 'blue' : 'black'}
          />
        </IconButton>
      </IconPadding>

    );
  }, [favorite, isFavorised, onSetFavorite]);

  const notifications = useMemo(() => {
    return (
      <IconButton onClick={() => navigate(routes.NOTIFICATIONS)}>
        <Icon
          type={IconType.Globe}
          color={data?.count ? 'silver' : 'borderLight'}
        />
        <Bubble color={'white'} background={'pinkDark'} value={data?.count} />
      </IconButton>
    );
  }, [navigate, data])

  // Large icon
  const largeIcon = useMemo(() => {
    if (!icon || icon === IconType.Back) {
      return null;
    }
    return (
      <IconButton onClick={onClick}>
        <Icon type={icon} />
      </IconButton>
    );
  }, [onClick, icon]);

  const menuTheme: { color: ThemeColor; illustrationColor: ThemeColor } =
    useMemo(() => {
      if (activeTab === MenuType.CompanyAdmin) {
        return {
          color: 'purple',
          illustrationColor: 'purpleDark',
        };
      } else if (activeTab === MenuType.WellrAdmin) {
        return {
          color: 'black',
          illustrationColor: 'blackDark',
        };
      }
      return {
        color: 'blue',
        illustrationColor: 'blueDark',
      };
    }, [activeTab]);

  return (
    <Container>
      <LargeHeader>
        <LogoLink to={routes.ROOT}>
          <svg
            width="88"
            height="34"
            viewBox="0 0 88 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M85.0796 16.7482C83.797 16.7482 82.8136 17.1723 82.1616 18.1474V17.0237H79.4575V27.2952H82.247V21.6985C82.247 20.1721 83.145 19.3876 84.4276 19.3876C84.9942 19.3876 85.6569 19.5148 86.1165 19.7162L86.5333 16.9707C86.1485 16.8329 85.6782 16.7482 85.0796 16.7482ZM74.0701 27.2951H76.8598V12.031H74.0701V27.2951ZM68.6827 27.2951H71.4725V12.031H68.6827V27.2951ZM61.6617 19.1332C62.8695 19.1332 63.6605 19.8646 63.9171 21.1896H59.3316C59.5775 19.9071 60.3363 19.1332 61.6617 19.1332ZM61.683 16.7482C58.5835 16.7482 56.4457 18.9637 56.4457 22.186C56.4457 25.4085 58.6582 27.5602 61.822 27.5602C63.5751 27.5602 65.0501 27.0726 66.2366 26.0021L64.6011 24.2212C63.8423 24.8573 62.8802 25.2071 61.9825 25.2071C60.6677 25.2071 59.5988 24.5604 59.321 23.0765H66.6641C66.6963 22.8326 66.7175 22.4723 66.7175 22.1437C66.7175 18.9424 64.6653 16.7482 61.683 16.7482ZM50.7111 24.2318L48.3597 17.0237H45.4201L43.1008 24.2001L40.8883 17.0237H37.9167L41.4225 27.2951H44.5865L46.8631 20.3521L49.1611 27.2951H52.3464L55.8524 17.0237H52.9343L50.7111 24.2318ZM7.24318 32.1411C6.93882 32.1411 6.63198 32.0961 6.33232 32.0035C5.25207 31.6705 4.44423 30.7748 4.23075 29.673L1.52244 15.7135C1.20248 14.063 2.29189 12.4681 3.95586 12.1506C5.62032 11.8333 7.22832 12.9136 7.54853 14.5636L9.17387 22.9427L14.1824 18.0516C15.2341 17.0242 16.8743 16.8855 18.0866 17.7212L25.1231 22.5725L22.4581 4.96141C22.2067 3.2997 23.3613 1.75022 25.0371 1.50069C26.7137 1.25165 28.2752 2.39663 28.5268 4.05835L32.2473 28.6467C32.429 29.8479 31.8731 31.0413 30.8329 31.6825C29.7925 32.3235 28.4676 32.2901 27.4617 31.5966L16.6742 24.1589L9.3965 31.2662C8.81378 31.835 8.0369 32.1411 7.24318 32.1411Z"
              fill="#1A1A1A"
            />
          </svg>
        </LogoLink>
        <Menu>
          <LayoutGroup>
            {menu.map(
              ({
                id,
                link = '',
                text,
                icon = IconType.Home,
                hasNotification,
              }) => {
                const isActive = pathname.includes(link);
                return (
                  <MenuLink key={id} to={link} active={isActive ? 'true' : ''}>
                    <span style={{ position: 'relative' }}>
                      <Icon
                        type={icon}
                        color={isActive ? 'blue' : 'grayText'}
                      />
                      {hasNotification && <Notification />}
                    </span>
                    {text}
                    {isActive && (
                      <MenuBorder
                        initial={false}
                        layoutId="main-menu"
                        transition={{ duration: 0.2 }}
                      />
                    )}
                  </MenuLink>
                );
              }
            )}
          </LayoutGroup>
        </Menu>
        <Nav>
          {isOrganizationAdmin && isCompanyAdminView && !isWellrAdmin && (
            <NavItem>
              <UmbrellaCompanySelector />
            </NavItem>
          )}
          {isWellrAdmin && isCompanyAdminView && (
            <NavItem>
              <CompanySelector />
            </NavItem>
          )}
          <NavItem>
            {notifications}
          </NavItem>
          <NavItem>
            <DropdownMenu menu={languageMenu}>
              <FlagIcon countryCode={language.countryCode} />
            </DropdownMenu>
          </NavItem>
          <NavItem>
            <DropdownMenu menu={dropDownMenu}>
              <MenuButton>
                <Icon type={IconType.Menu} color="grayText" />
              </MenuButton>
            </DropdownMenu>
          </NavItem>
        </Nav>
      </LargeHeader>
      <LargeTitle>
        <ContentWidth>
          <LargeTitleGrid>
            {largeTitle}
            <IconGrid>
              {largeIcon}
              {largeReminder}
              {largeFavorite}
            </IconGrid>
          </LargeTitleGrid>
        </ContentWidth>
      </LargeTitle>
      <SmallHeader inverted={inverted}>
        <SmallNav>{smallTitle}</SmallNav>
        <SmallNav right>
          {smallReminder}
          {smallFavorite}
          <NavItem>
            {notifications}
          </NavItem>
          <NavItem onClick={toggleMenu}>
            <MenuButton>
              <Icon
                type={IconType.Menu}
                color={inverted ? 'white' : 'black'}
                size={32}
              />
            </MenuButton>
          </NavItem>
        </SmallNav>
      </SmallHeader>
      <NavOverlay
        isOpen={isMenuOpen}
        color={menuTheme.color}
        illustrationColor={menuTheme.illustrationColor}
      >
        <SmallHeader>
          <SmallNav>
            <SmallLogo
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.66555 29.212C7.40216 29.212 7.13662 29.1731 6.8773 29.093C5.94246 28.8048 5.24337 28.0297 5.05863 27.0762L2.71489 14.9958C2.43799 13.5675 3.38076 12.1873 4.82074 11.9125C6.26115 11.6379 7.65269 12.5728 7.9298 14.0007L9.33634 21.2518L13.6706 17.0191C14.5808 16.1301 16.0002 16.01 17.0493 16.7333L23.1386 20.9315L20.8324 5.69106C20.6149 4.25303 21.614 2.91213 23.0643 2.69619C24.5152 2.48068 25.8664 3.47153 26.0842 4.90956L29.3038 26.188C29.4611 27.2275 28.98 28.2603 28.0799 28.8152C27.1795 29.3699 26.0329 29.341 25.1624 28.7408L15.8271 22.3043L9.52901 28.455C9.02473 28.9472 8.35243 29.212 7.66555 29.212Z"
                fill="white"
              />
            </SmallLogo>
          </SmallNav>
          <SmallNav right>
            <NavItem onClick={toggleMenu}>
              <Icon type={IconType.Close} color="white" size={32} />
            </NavItem>
          </SmallNav>
        </SmallHeader>
        {(isWellrAdmin || isCompanyAdmin) && (
          <SmallContainer>
            <TabMenu
              menuId="admin"
              menu={tabMenu}
              activeTab={activeTab}
              setActiveTab={(tab) => setActiveTab(tab as MenuType)}
              inverted
            />
          </SmallContainer>
        )}
        <Menu>
          {menu.map(({ id, text, link = '', icon = IconType.Home }) => {
            return (
              <MenuLink key={id} to={link} onClick={toggleMenu}>
                <Icon type={icon} color="white" />
                {text}
              </MenuLink>
            );
          })}
        </Menu>
        {isOrganizationAdmin && isCompanyAdminView && !isWellrAdmin && (
          <SmallContainer>
            <UmbrellaCompanySelector />
          </SmallContainer>
        )}
        {isWellrAdmin && isCompanyAdminView && (
          <SmallContainer>
            <CompanySelector />
          </SmallContainer>
        )}
        <SmallFooter>
          <SmallFooterItem onClick={toggleLangMenu}>
            <FlagIcon countryCode={language.countryCode} />
            {capitalize(language.name)}
          </SmallFooterItem>
          {footerMenu.map(({ id, text, icon, onClick }, i) => {
            return (
              <SmallFooterItem key={id} onClick={onClick} borders={i === 0}>
                {icon && <Icon type={icon} color="white" />}
                {text}
              </SmallFooterItem>
            );
          })}
        </SmallFooter>
        <ContextMenu
          menu={languageMenu}
          isOpen={isLangMenuOpen}
          onClose={toggleLangMenu}
          title={intl.formatMessage({
            id: 'menuLanguagesTitle',
            defaultMessage: 'Languages',
            description: 'Languages menu title',
          })}
        />
      </NavOverlay>
      <RegisterContentModal
        isOpen={registerModalOpen}
        onClose={onCloseModal}
        slug={reminder?.slug}
        redirectUrl={reminder?.redirectUrl ?? ''}
      />
    </Container>
  );
};

export default Header;
