import React, { FC, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-hot-toast';

import {
  useDeleteHeadOfficeMutation,
  useUpdateHeadOfficeMutation,
} from 'store';

import Loader from 'components/UI/Loader/Loader';
import Icon, { IconType } from 'components/UI/Icon';
import IconButton from 'components/UI/IconButton';
import Heading, { Tag } from 'components/UI/Heading';
import SlideoutModal from 'components/UI/SlideoutModal';

import { Header, Body } from './styles';
import { usePretendCompany } from 'hooks/usePretendCompany';
import CreateUpdateHeadofficeForm from 'components/forms/CreateUpdateHeadofficeForm';

type Props = {
  headoffice: {
    description: string;
    id: string;
  } | null;
  isOpen: boolean;
  onClose: () => void;
};

const UpdateHeadofficeModal: FC<Props> = ({ headoffice, isOpen, onClose }) => {
  const intl = useIntl();
  const companyId = usePretendCompany();

  const [updateHeadoffice, updateHeadofficeResult] =
    useUpdateHeadOfficeMutation();

  const [deleteHeadOffice, deleteHeadofficeResult] =
    useDeleteHeadOfficeMutation();

  useEffect(() => {
    if (updateHeadofficeResult.isSuccess) {
      toast.success(
        intl.formatMessage({
          id: 'updateHeadofficeModalSuccess',
          defaultMessage: 'Head office update successful',
          description: 'Update head office success message',
        })
      );
      onClose();
    }
  }, [updateHeadofficeResult.isSuccess, intl, onClose]);

  useEffect(() => {
    if (deleteHeadofficeResult.isSuccess) {
      toast.success(
        intl.formatMessage({
          id: 'deleteHeadofficeModalSuccess',
          defaultMessage: 'Head office successfully deleted',
          description: 'Delete head office success message',
        })
      );
      onClose();
    }
  }, [
    updateHeadofficeResult.isSuccess,
    intl,
    onClose,
    deleteHeadofficeResult.isSuccess,
  ]);

  // Content
  const content = useMemo(() => {
    if (updateHeadofficeResult.isLoading) {
      return <Loader padding color="blue" />;
    }
    if (!headoffice) {
      return null;
    }
    return (
      <CreateUpdateHeadofficeForm
        headOffice={headoffice}
        submitForm={(form) =>
          updateHeadoffice({
            headOfficeId: headoffice?.id ?? '',
            description: form.headofficeName,
            companyId,
          })
        }
        onDelete={() => {
          deleteHeadOffice({
            headOfficeId: headoffice?.id ?? '',
            companyId,
          });
        }}
      />
    );
  }, [
    companyId,
    deleteHeadOffice,
    headoffice,
    updateHeadoffice,
    updateHeadofficeResult.isLoading,
  ]);

  const onModalClose = () => {
    onClose();
  };

  return (
    <SlideoutModal isOpen={isOpen} onClose={onModalClose}>
      <div>
        <Header>
          <Heading tag={Tag.H4}>
            <FormattedMessage
              id="updateHeadOfficeModalTitle"
              defaultMessage="Update head office"
              description="Modal title for update head office"
            />
          </Heading>
          <IconButton onClick={onClose} padding>
            <Icon type={IconType.Close} />
          </IconButton>
        </Header>
        <Body>{content}</Body>
      </div>
    </SlideoutModal>
  );
};

export default UpdateHeadofficeModal;
