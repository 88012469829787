import React, { FC } from 'react';

import Icon, { IconType } from 'components/UI/Icon';

import {
  BorderedList,
  CardContainer,
  CardDescription,
  EditContainer,
} from './styles';
import ExpandableCard from 'components/UI/Cards/ExpandableCard';

type ExpandableCardListProps = {
  id: string;
  title: string;
  items: Array<{
    id: string | number;
    title: string;
    description: string;
  }>;
  onEdit?: (id: any) => void;
  onEditItem?: (itemId: any) => void;
};

const ExpandableCardList: FC<ExpandableCardListProps> = ({
  id,
  title,
  items,
  onEdit,
  onEditItem,
}) => (
  <ExpandableCard id={id} onEdit={onEdit} title={title}>
    <BorderedList>
      {items.map(({ id: itemId, title, description }, index) => (
        <div key={itemId}>
          <CardContainer>
            <div>
              <div>{title}</div>
              <CardDescription>{description}</CardDescription>
            </div>
            <EditContainer onClick={() => onEditItem?.(itemId)}>
              <Icon type={IconType.Edit} />
            </EditContainer>
          </CardContainer>
        </div>
      ))}
    </BorderedList>
  </ExpandableCard>
);

export default ExpandableCardList;
