import { FC, useEffect, Fragment } from 'react';
import { useIntl } from 'react-intl';

import { ContentWidth } from 'styles';
import { useAppDispatch } from 'hooks/redux';
import { setLayoutHeader, useUseGetUmbrellaCompaniesQuery } from 'store';

import Loader from 'components/UI/Loader/Loader';

import * as routes from 'router/routes';
import NavCard from 'components/UI/Cards/NavCard';

const AdminUmbrellaCompanies: FC = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  // Hooks
  const { data, isLoading } = useUseGetUmbrellaCompaniesQuery();

  // Set header
  useEffect(() => {
    dispatch(
      setLayoutHeader({
        title: intl.formatMessage({
          id: 'pageAdminUmbrellaCompaniesTitle',
          defaultMessage: 'Umbrella Companies',
          description: 'Page title for admin umbrella companies',
        }),
        inverted: false,
      })
    );
  }, [dispatch, intl]);

  if (isLoading) {
    return <Loader color="blue" padding />;
  }

  return (
    <Fragment>
      <ContentWidth isSurface>
        {(data ?? []).map((company) => (
          <NavCard
            key={company.id}
            title={company.description ?? ''}
            description={intl.formatMessage(
              {
                id: 'umbrellaCompanyDescription',
                defaultMessage:
                  '{count, plural, one {# company} other {# companies}}',
                description: 'Umbrella Company description',
              },
              { count: company.companies?.length ?? 0 }
            )}
            to={`${routes.ADMIN_UMBRELLA_COMPANIES}/${company.id}`}
          />
        ))}
      </ContentWidth>
    </Fragment>
  );
};

export default AdminUmbrellaCompanies;
