import React, { FC, useMemo } from 'react';
import Heading, { Tag } from '../../UI/Heading';
import Button from '../../UI/Button';
import { NotificationContainer, Action, Body, Header, ImageContainer, Img } from './styles';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import RichText from '../RichText';
import { useAppSelector } from 'hooks/redux';
import { localizationSelector, useCreateQuizMutation } from 'store';



type Props = {
  title: string
  body: string
  ctaLabel: string | null
  link: string | null
  imageUrl: string | null
}

const NotificationFragment: FC<Props> = ({ title, body, link, ctaLabel, imageUrl }) => {
  const [isRichText] = React.useState(body.startsWith('['));

  // Hooks
  const intl = useIntl();
  const { language } = useAppSelector(localizationSelector);
  const [createQiuz] = useCreateQuizMutation();

  const navigate = useNavigate();

  const onClick = async () => {
    if (link?.includes('evaluation-of-the-activity-challenge')) {
      const url = new URL(link);
      const pathnameParts = url.pathname.split('/');
      const contestId = pathnameParts.pop(); // Get contestId
      const slug = pathnameParts.slice(-1)[0]; // Get the slug
      const redirect = new URL(`${url.origin}${pathnameParts.join('/')}`) // Reconstruct URL without contestId

      const state = { contestId: contestId };
      await createQiuz({ language: language?.languageCode, contestId, slug });
      navigate(redirect.pathname, { state });
    }
    else if (link) {
      const url = new URL(link);

      if (link.includes('quiz')) {
        const slug = url.pathname.split('/').pop();
        await createQiuz({ language: language?.languageCode, slug });
      }

      navigate(url.pathname);
    }
  }

  const defaultCtaLabel = () => {
    return intl.formatMessage({
      id: 'defaultCtaLabel',
      defaultMessage: 'Go',
      description: 'Default label for Cta button in notification',
    })
  }

  const parts = body.split(/<b>|<\/b>/);

  const RichTextComponent = ({ text }: { text: { [x: string]: any; } }) => <RichText>{text}</RichText>;
  const PartsMapComponent = ({ parts }: any) => (
    <div>
      {parts.map((part: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined, index: number) =>
        index % 2 === 0 ? part : <b>{part}</b>
      )}
    </div>
  );

  const actions = useMemo(() => {
    if (link == null || !link) {
      return null;
    }
    return (
      <Action>
        <Button background="blue" onClick={onClick}>
          {ctaLabel ?? defaultCtaLabel()}
        </Button>
      </Action>
    );
  }, [onClick, ctaLabel]);

  const image = useMemo(() => {
    if (!imageUrl) {
      return null
    }
    return (
      <ImageContainer>
        <Img src={imageUrl} alt={""} />
      </ImageContainer>
    )

  }, [imageUrl])

  return (
    <NotificationContainer>
      {image}
      <Header>
        <Heading tag={Tag.H3}>{title}</Heading>
      </Header>
      <Body>
        {isRichText ? (
          <RichTextComponent text={{ [language?.languageCode || 'en']: JSON.parse(body) }} />
        ) : (
          <PartsMapComponent parts={parts} />
        )}
      </Body>
      {actions}
    </NotificationContainer>
  )
}

export default NotificationFragment;
