import React, { FC, Fragment, useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { useDeleteActivityMutation } from 'store';
import { ActionContainer } from 'styles';

import Loader from 'components/UI/Loader';
import Icon, { IconType } from 'components/UI/Icon';
import IconButton from 'components/UI/IconButton';
import Heading, { Tag } from 'components/UI/Heading';
import SlideoutModal from 'components/UI/SlideoutModal';
import Button from 'components/UI/Button/Button';

import { Header, Body } from './styles';

type Props = {
  id: string | null;
  isOpen: boolean;
  onClose: () => void;
};

const DeleteActivityModal: FC<Props> = ({ id, isOpen, onClose }) => {
  // Hooks
  const [deleteActivity, deleteResult] = useDeleteActivityMutation();

  // Success
  useEffect(() => {
    if (deleteResult.isSuccess) {
      onClose();
    }
  }, [deleteResult, onClose]);

  // On delete
  const onDelete = useCallback(() => {
    if (id) {
      deleteActivity(id);
    }
  }, [deleteActivity, id]);

  const content = useMemo(() => {
    if (deleteResult.isLoading) {
      return <Loader padding color="blue" />;
    }
    return (
      <Fragment>
        <p>
          <FormattedMessage
            id="deleteActivityModalText"
            defaultMessage="Are you sure you would like to delete this activity?"
            description="Delete activity modal text"
          />
        </p>
        <ActionContainer>
          <Button background="blue" onClick={onDelete}>
            <FormattedMessage
              id="confirmButton"
              defaultMessage="Confirm"
              description="Confirm button text"
            />
          </Button>
        </ActionContainer>
      </Fragment>
    );
  }, [deleteResult, onDelete]);

  return (
    <SlideoutModal isOpen={isOpen} onClose={onClose}>
      <div>
        <Header>
          <Heading tag={Tag.H3}>
            <FormattedMessage
              id="deleteActivityModalTitle"
              defaultMessage="Delete activity?"
              description="Delete activity modal title"
            />
          </Heading>
          <IconButton onClick={onClose} padding>
            <Icon type={IconType.Close} />
          </IconButton>
        </Header>
        <Body>{content}</Body>
      </div>
    </SlideoutModal>
  );
};

export default DeleteActivityModal;
