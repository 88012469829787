import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { LayoutGroup } from 'framer-motion';

import { TabMenuItem } from 'models';
import { ThemeColor } from 'styles';

import {
  Tabs,
  TabItem,
  TabText,
  TabContainer,
  TabNotification,
} from './styles';

type Props<T extends TabMenuItem> = {
  menuId: string;
  menu: T[];
  activeTab?: T['id'];
  setActiveTab?: (tab: T['id']) => void;
  inverted?: boolean;
  includeSubPaths?: boolean;
};

const TabMenu = <T extends TabMenuItem>({
  menuId,
  menu,
  activeTab,
  setActiveTab,
  includeSubPaths = false,
  inverted = false,
}: Props<T>) => {
  const theme = useTheme();
  const { hash, pathname } = useLocation();
  const navigate = useNavigate();

  // Active path
  const activePath = useMemo(() => {
    if (hash) {
      return pathname + hash;
    }
    return pathname;
  }, [hash, pathname]);

  // Check active
  const checkActive = useCallback(
    (id: number | string, link?: string) => {
      if (activeTab) {
        return activeTab === id;
      }
      if (!link) {
        return false;
      }
      if (includeSubPaths) {
        return activePath.includes(link);
      }
      return activePath === link;
    },
    [activePath, activeTab, includeSubPaths]
  );

  // Handle action
  const onAction = useCallback(
    (id: number | string, link: string) => {
      if (link) {
        navigate(link);
      }
      if (setActiveTab) {
        setActiveTab(id);
      }
    },
    [navigate, setActiveTab]
  );

  // Color
  const getColor = useCallback(
    (isActive: boolean, color?: ThemeColor) => {
      if (color && isActive) {
        return theme.colors[color];
      }
      if (inverted && isActive) {
        return theme.colors.blue;
      }
      if (inverted) {
        return theme.colors.white;
      }
      if (isActive) {
        return theme.colors.white;
      }
      return theme.colors.black;
    },
    [theme, inverted]
  );

  // Background color
  const getBgColor = useCallback(
    (isActive: boolean, color?: ThemeColor) => {
      if (isActive && color) {
        return theme.colors[color];
      }
      if (isActive && inverted) {
        return theme.colors.white;
      }
      if (isActive) {
        return theme.colors.black;
      }
      return 'rgba(255, 255, 255, 0)';
    },
    [theme, inverted]
  );

  return (
    <LayoutGroup>
      <Tabs inverted={inverted ? 1 : 0}>
        {menu.map(
          ({
            id,
            text,
            link = '',
            color,
            backgroundColor,
            hasNotification = false,
          }) => {
            const isActive = checkActive(id, link);
            return (
              <TabItem
                key={id}
                onClick={() => onAction(id, link)}
                initial={false}
                transition={{ duration: 0.4 }}
                animate={{
                  color: getColor(isActive, color),
                }}
              >
                <TabText>
                  <span>{text}</span>
                  {hasNotification && <TabNotification />}
                </TabText>
                {isActive && (
                  <TabContainer
                    initial={false}
                    layoutId={menuId}
                    transition={{ duration: 0.4 }}
                    animate={{
                      backgroundColor: getBgColor(isActive, backgroundColor),
                    }}
                  />
                )}
              </TabItem>
            );
          }
        )}
      </Tabs>
    </LayoutGroup>
  );
};

export default TabMenu;
