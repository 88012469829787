import styled from 'styled-components';
import { motion } from 'framer-motion';

type Props = {
  isActive: boolean;
  isLocked: boolean;
};

type HandleProps = {
  $isLocked: boolean;
}
export const ToggleSwitch = styled.div<Props>`
  width: 48px;
  height: 24px;
  background: ${({ theme, isActive , isLocked}) =>
    theme.colors[isActive && !isLocked ? 'blue' : 'borderDark']};
  display: flex;
  justify-content: ${({ isActive }) => (isActive ? 'flex-end' : 'flex-start')};
  align-items: center;
  border-radius: 24px;
  cursor: pointer;
`;

export const ToggleHandle = styled(motion.div)<HandleProps>`
  background: ${({ theme, $isLocked}) => ($isLocked ? theme.colors.pink : theme.colors.white)};
  width: 20px;
  height: 20px;
  border-radius: 10px;
  margin: 2px;
`;
