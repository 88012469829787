import React, { FC, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-hot-toast';

import {
  localizationSelector,
  useGetActivityTypesQuery,
  useUpdateContestDarkModeMutation,
} from 'store';
import { useAppSelector } from 'hooks/redux';

import Loader from 'components/UI/Loader';
import Icon, { IconType } from 'components/UI/Icon';
import IconButton from 'components/UI/IconButton';
import Heading, { Tag } from 'components/UI/Heading';
import SlideoutModal from 'components/UI/SlideoutModal';

import { Header, Body } from './styles';
import { useForm } from 'react-hook-form';
import TextField from 'components/UI/TextField';
import { ActionContainer } from 'styles';
import Button from 'components/UI/Button';
import { getDateTime } from 'utils/date';

type DarkModeForm = {
  start: string;
  stop: string;
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
  contestId: string;
} & DarkModeForm;

const AdminDarkModeModal: FC<Props> = ({
  isOpen,
  onClose,
  contestId,
  start,
  stop,
}) => {
  const intl = useIntl();
  const { language } = useAppSelector(localizationSelector);
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<DarkModeForm>();

  // Hooks
  const [updateDarkMode, updateResult] = useUpdateContestDarkModeMutation();

  // Activity types
  const types = useGetActivityTypesQuery({
    language: language?.languageCode,
  });

  // Success
  useEffect(() => {
    if (updateResult.isSuccess) {
      toast.success(
        intl.formatMessage({
          id: 'modalSaveSuccess',
          defaultMessage: 'Saved',
          description: 'Toast message when saving modal is successful',
        })
      );
      onClose();
    }
  }, [updateResult, intl, onClose]);

  useEffect(() => {
    setValue('start', start === '' ? '' : getDateTime(new Date(start)));
    setValue('stop', stop === '' ? '' : getDateTime(new Date(stop)));
  }, [start, stop, setValue]);

  // Content
  const content = useMemo(() => {
    if (updateResult.isLoading || types.isLoading) {
      return <Loader padding color="blue" />;
    }
    return (
      <form
        onSubmit={handleSubmit((form) =>
          updateDarkMode({
            contestId,
            start: form.start === '' ? null : form.start,
            stop: form.stop === '' ? null : form.stop,
          })
        )}
      >
        <TextField
          label={intl.formatMessage({
            id: 'inputStartDateLabel',
            defaultMessage: 'Start date',
            description: 'Label for start date input',
          })}
          placeholder={intl.formatMessage({
            id: 'inputStartDatePlaceholder',
            defaultMessage: 'Enter start date',
            description: 'Placeholder for start date input',
          })}
          type="datetime-local"
          error={errors.start}
          register={register('start', {
            validate: (startDate) => {
              const stopDate = watch('stop');
              if (startDate === '' && stopDate !== '') {
                return intl.formatMessage({
                  id: 'inputErrorFieldRequired',
                  defaultMessage: 'Field is is required',
                  description: 'Input error for field required',
                });
              }
            },
          })}
        />
        <TextField
          label={intl.formatMessage({
            id: 'inputStopDateLabel',
            defaultMessage: 'Stop date',
            description: 'Label for stop date input',
          })}
          placeholder={intl.formatMessage({
            id: 'inputStopDatePlaceholder',
            defaultMessage: 'Enter stop date',
            description: 'Placeholder for stop date input',
          })}
          type="datetime-local"
          error={errors.stop}
          register={register('stop', {
            validate: (stopDate) => {
              const startDate = watch('start');
              if (stopDate === '' && startDate !== '') {
                return intl.formatMessage({
                  id: 'inputErrorFieldRequired',
                  defaultMessage: 'Field is is required',
                  description: 'Input error for field required',
                });
              } else if (
                stopDate !== '' &&
                startDate !== '' &&
                startDate >= stopDate
              ) {
                return intl.formatMessage({
                  id: 'inputStartDateGreaterThanStopDateError',
                  defaultMessage: 'Stop date must be after start date',
                  description: 'Input error for field invalid',
                });
              }
            },
          })}
        />
        <ActionContainer>
          <Button type="submit" background="blue">
            <FormattedMessage
              id="saveButton"
              defaultMessage="Save"
              description="Save button text"
            />
          </Button>
        </ActionContainer>
      </form>
    );
  }, [
    updateResult.isLoading,
    types.isLoading,
    handleSubmit,
    intl,
    errors.start,
    errors.stop,
    register,
    updateDarkMode,
    contestId,
    watch,
  ]);

  return (
    <SlideoutModal isOpen={isOpen} onClose={onClose}>
      <div>
        <Header>
          <Heading tag={Tag.H3}>
            <FormattedMessage
              id="adminDarkModeModalTitle"
              defaultMessage="Dark mode configuration"
              description="Dark mode configuration modal title"
            />
          </Heading>
          <IconButton onClick={onClose} padding>
            <Icon type={IconType.Close} />
          </IconButton>
        </Header>
        <Body>{content}</Body>
      </div>
    </SlideoutModal>
  );
};

export default AdminDarkModeModal;
