import { Interval } from 'models';
import { RepeatEnum } from 'models/notificationSchedule/notificationSchedule';
import { useMemo } from 'react';
import { useIntl, IntlShape } from 'react-intl';

type IntervalOptions = Array<{
  name: string;
  value: "None" | "Daily" | "Weekly" | "Monthly";
}>;

export const getIntervalTranslation = (interval: Interval | RepeatEnum, intl: IntlShape): string => {
  switch (interval) {
    case Interval.None:
      return intl.formatMessage({
        id: 'intervalSelectLabelOnce',
        defaultMessage: 'Once',
        description: 'Select label for once interval',
      });
    case Interval.Daily:
      return intl.formatMessage({
        id: 'intervalSelectLabelDaily',
        defaultMessage: 'Daily',
        description: 'Select label for daily interval',
      });
    case Interval.Weekly:
      return intl.formatMessage({
        id: 'intervalSelectLabelWeekly',
        defaultMessage: 'Weekly',
        description: 'Select label for weekly interval',
      });
    case Interval.Monthly:
      return intl.formatMessage({
        id: 'intervalSelectLabelMonthly',
        defaultMessage: 'Monthly',
        description: 'Select label for monthly interval',
      });
  }
  return intl.formatMessage({
    id: 'intervalSelectLabelOnce',
    defaultMessage: 'Once',
    description: 'Select label for once interval',
  });
};


export const useIntervalOptions = (): IntervalOptions => {
  const intl = useIntl();
  return useMemo(
    () => [
      {
        name: getIntervalTranslation(Interval.None, intl),
        value: "None",
      },
      {
        name: getIntervalTranslation(Interval.Daily, intl),
        value: "Daily",
      },
      {
        name: getIntervalTranslation(Interval.Weekly, intl),
        value: "Weekly",
      },
      {
        name: getIntervalTranslation(Interval.Monthly, intl),
        value: "Monthly",
      },
    ],
    [intl]
  );
};

