import React, { FC, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Navigate } from 'react-router-dom';
import toast from 'react-hot-toast';

import { useFinalizeUserInviteMutation } from 'store';
import * as routes from 'router/routes';

import ConfirmRegisterInviteForm from 'components/forms/ConfirmRegisterInviteForm';
import Heading from 'components/UI/Heading';
import BackgroundCover from 'components/UI/BackgroundCover';
import Loader from 'components/UI/Loader';
import CoverHeader from 'components/UI/CoverHeader';

import { CoverContent, Description } from 'styles';
import { useLanguageFromQuery } from 'hooks/useLanguageFromQuery';

const ConfirmRegisterInvite: FC = () => {
  // Hooks
  const intl = useIntl();
  const [finalizeUserInvite, { isLoading, isSuccess }] =
    useFinalizeUserInviteMutation();
  const [deleteUserInvite] = useFinalizeUserInviteMutation();
  const { languageLoaded } = useLanguageFromQuery();

  useEffect(() => {
    if (isSuccess) {
      toast.success(
        intl.formatMessage({
          id: 'confirmRegisterInviteSuccess',
          defaultMessage: 'Invite registration was successful',
          description: 'Confirm register invite success message"',
        })
      );
    }
  }, [intl, isSuccess]);

  const content = useMemo(() => {
    if (isLoading) {
      return <Loader padding />;
    }
    return (
      <ConfirmRegisterInviteForm
        submitForm={finalizeUserInvite}
        deny={deleteUserInvite}
      />
    );
  }, [finalizeUserInvite, deleteUserInvite, isLoading]);

  // Redirect to login
  if (isSuccess) {
    return <Navigate to={routes.REGISTER_COMPLETE} />;
  }

  // Prevent language flickering
  if (!languageLoaded) {
    return <Loader padding />;
  }

  return (
    <BackgroundCover padding>
      <CoverHeader subLevel />
      <CoverContent>
        <Heading>
          <FormattedMessage
            id="confirmRegisterInviteTitle"
            defaultMessage="Confirm registration"
            description="Confirm register invite page title"
          />
        </Heading>
        <Description>
          <FormattedMessage
            id="confirmRegisterInviteDescription"
            defaultMessage="Almost done! You just need to enter some more information about yourself."
            description="Confirm register invite page description"
          />
        </Description>
        {content}
      </CoverContent>
    </BackgroundCover>
  );
};

export default ConfirmRegisterInvite;
