import styled from 'styled-components';
import { breakpoints } from 'styles';

export const ButtonGrid = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 32px;

  @media (max-width: ${breakpoints.s}px) {
    flex-direction: column-reverse;
  }
`;

export const UserStepsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
`;

export const StepText = styled.span`
  color: ${({ theme }) => theme.colors.grayText};
  font-size: 14px;
`;

export const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;

  h4 {
    margin: 0;
    width: 100%;

    @media (max-width: ${breakpoints.s}px) {
      margin: 16px 0 8px;
    }
  }

  @media (max-width: ${breakpoints.s}px) {
    flex-direction: column;
  }
`;

export const SmallButtonGrid = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
`;

export const CardList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
`;

export const CardItem = styled.div`
  margin: 16px;

  h4 {
    margin: 0;
  }
`;

type CategoryProps = {
  isActive: boolean;
  isDisabled: boolean;
  onClick?: () => void;
};

export const CategoryCircle = styled.div.attrs((props: CategoryProps) => ({
  onClick: props.onClick,
})) <CategoryProps>`
  display: flex;
  width: 2.25rem;
  height: 2.25rem;
  flex-wrap: wrap;

  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.borderLight};
  border-radius: 100%;
  cursor: pointer;
  flex-shrink: 0;

    @media (max-width: 375px) {
    width: 2rem;
    height: 2rem;
  }

  ${({ isActive, isDisabled, theme }) => {
    if (isDisabled) {
      return `
        opacity: 0.5;
        pointer-events: none;
      `;
    }
    if (!isActive) {
      return `
        pointer-events: none;
        background-color: ${theme.colors.blueDark};
        border: 1px solid ${theme.colors.blueDark};
      `;
    }
    else {
      return `
        background-color: ${theme.colors.borderLight};
        border: 1px solid ${theme.colors.borderLight};
      `;
    }
  }}
`;