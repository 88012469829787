import React, { FC, ReactNode } from 'react';

import { Card, CardDescription, CardHeading } from './styles';

type Props = {
  title?: string
  description?: string
  onClick?: () => void;
  isViewed?: boolean
};

const NotificationCard: FC<Props> = ({ onClick, isViewed, title, description }) => {
  return (
    <Card role="button" onClick={onClick} isViewed={isViewed}>
      <div>
        <CardHeading style={{}}>
          {title}
        </CardHeading>
        {(
          <CardDescription>{description}</CardDescription>
        )}
      </div>
    </Card>
  );
};

export default NotificationCard;
