import styled from 'styled-components';

export const Overlay = styled.div<{ open: boolean }>`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: ${({ theme }) => theme.colors.black};
  opacity: ${({ open }) => (open ? 0.5 : 0)};
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  transition: opacity 0.15s ease-in-out;
  z-index: 100;
  `;

export const Dialog = styled.dialog`
  position: fixed;
  width: 17em;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  border-radius: 16px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border: 2px solid ${({ theme }) => theme.colors.yellowDark};
  text-align: center;
  padding: 0;
  padding-bottom: 2rem;
  z-index: 110;
`;

export const MilestoneIcon = styled.img`
  margin-top: 2.25rem;
  width: 100px;
  height: 100px;
  `;

export const ButtonContainer = styled.div`
  margin-top: 1rem;
  button {
    border-radius: 0.5em;
    width: 80%;
    border: 1px solid ${({ theme }) => theme.colors.yellowDark};
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
    background: linear-gradient(to bottom, ${({ theme }) => theme.colors.yellowDark}, ${({ theme }) => theme.colors.yellowDark});
    transition: all 0.3s ease;
    &:active {
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
      transform: translateY(1px);
    }
  }
`;

export const DialogBody = styled.div`
  padding: 0 1rem 1rem 1rem;
  `;

export const DialogButtonContainer = styled.div`
  display: flex;
  border-top: 1px solid ${({ theme }) => theme.colors.borderLight};
  `;

export const DialogButtonDivider = styled.div`
  width: 1px;
  background: ${({ theme }) => theme.colors.borderLight};
`;
