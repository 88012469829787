import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import * as routes from 'router/routes';
import CategoryCard from 'components/UI/Cards/CategoryCard';
import Loader from 'components/UI/Loader';

import { localizationSelector, useGetExerciseCategoryListQuery } from 'store';
import { useLocalizedText } from 'hooks/useLocalizedText';
import { useAppSelector } from 'hooks/redux';
import { getImage } from 'utils/asset';

import { CardList } from './styles';

const Exercises: FC = () => {
  const intl = useIntl();
  const getText = useLocalizedText();
  const { language } = useAppSelector(localizationSelector);

  // Hooks
  const { data, isLoading } = useGetExerciseCategoryListQuery({
    language: language?.languageCode,
  });

  if (isLoading) {
    return <Loader color="blue" padding />;
  }

  // No data
  if (!data || !data.length) {
    return null;
  }

  return (
    <CardList>
      {data.map(({ slug, title, image, numberOfExercises }, i) => {
        return (
          <CategoryCard
            key={`exercise-${i}`}
            title={getText(title)}
            byline={intl.formatMessage(
              {
                id: 'pageExerciseNoByline',
                defaultMessage:
                  '{count, plural, =0 {no exercises} one {# exercise} other {# exercises}}',
                description: 'No exercises byline for exercises',
              },
              {
                count: numberOfExercises,
              }
            )}
            link={`${routes.EXERCISES_CATEGORY}/${slug.current}`}
            image={getImage(image)}
          />
        );
      })}
    </CardList>
  );
};

export default Exercises;
