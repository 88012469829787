import { useGetUserQuery } from 'store';

export const useUserCompanyId = () => {
  const { userCompanyId } = useGetUserQuery(undefined, {
    selectFromResult: ({ data }) => ({
      userCompanyId: data?.membership.companyId,
    }),
  });
  return userCompanyId;
};
