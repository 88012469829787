import { FC, Fragment, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';

import { ContestUser, UserMembership } from 'models';

import Heading, { Tag } from 'components/UI/Heading';
import RadioButton from 'components/UI/RadioButton';
import Button from 'components/UI/Button';
import TextField from 'components/UI/TextField';

import { ActionContainer } from 'styles';
import { Grid, SelectItem } from './styles';
import ToggleCard from 'components/UI/Cards/ToggleCard';
import MemberSelectFromStructure from 'components/fragments/MemberSelectFromStructure';
import {
  SelectedMembershipsContext,
  useMembershipState,
} from 'context/useSelectedMembershipContext';

type Props = {
  onStart: (
    start: string,
    userId: string,
    users: Partial<ContestUser>[],
    displayCreatorsEmail: boolean
  ) => void;
  membership: UserMembership;
};

const ChallengeForm: FC<Props> = ({ onStart, membership }) => {
  const intl = useIntl();

  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      displayCreatorsEmail: false,
      challengeSelf: true,
      filterUsers: '',
      start: '',
      alias: '',
    },
  });

  // Watch
  const [challengeSelf, filterUsers, start, alias, displayCreatorsEmail] =
    watch([
      'challengeSelf',
      'filterUsers',
      'start',
      'alias',
      'displayCreatorsEmail',
    ]);

  // Hooks
  const { selectedMemberships, toggleSelectMembership } = useMembershipState();

  // Submit
  const onChallenge = useCallback(() => {
    const { userId, id: membershipId } = membership;
    const membershipIds = [
      { membershipId, alias },
      ...selectedMemberships.map((membershipId) => ({ membershipId })),
    ];
    onStart(start, userId, membershipIds, displayCreatorsEmail);
  }, [
    membership,
    alias,
    selectedMemberships,
    onStart,
    start,
    displayCreatorsEmail,
  ]);

  // On select participants
  const onSelect = useCallback(
    (value: boolean) => () => setValue('challengeSelf', value),
    [setValue]
  );

  // Content
  const content = useMemo(() => {
    if (challengeSelf) {
      return null;
    }

    return (
      <Grid>
        <TextField
          label={intl.formatMessage({
            id: 'inputFilterUsersLabel',
            defaultMessage: 'Search users',
            description: 'Label for filter users input',
          })}
          placeholder={intl.formatMessage({
            id: 'inputFilterUsersPlaceholder',
            defaultMessage: 'Search for user name or email',
            description: 'Placeholder for filter users input',
          })}
          register={register('filterUsers')}
          blurOnEnter
        />
        <SelectedMembershipsContext.Provider
          value={{
            selected: selectedMemberships,
            onToggle: toggleSelectMembership,
          }}
        >
          <MemberSelectFromStructure filter={filterUsers} />
        </SelectedMembershipsContext.Provider>
      </Grid>
    );
  }, [
    challengeSelf,
    intl,
    register,
    selectedMemberships,
    toggleSelectMembership,
    filterUsers,
  ]);

  // Actions
  const actions = useMemo(() => {
    if (!challengeSelf && selectedMemberships.length === 0) {
      return null;
    }
    return (
      <Fragment>
        <ActionContainer>
          <Button background="blue" onClick={onChallenge}>
            {selectedMemberships.length > 0 ? (
              <FormattedMessage
                id="challengeUsersButton"
                defaultMessage="Challenge!"
                description="Challenge users button text"
              />
            ) : (
              <FormattedMessage
                id="startChallengeButton"
                defaultMessage="Start challenge"
                description="Start challenge button text"
              />
            )}
          </Button>
        </ActionContainer>
      </Fragment>
    );
  }, [challengeSelf, selectedMemberships.length, onChallenge]);

  return (
    <Fragment>
      <Heading tag={Tag.H4}>
        <FormattedMessage
          id="challengeSettingsTitle"
          defaultMessage="Settings"
          description="Challenge settings title"
        />
      </Heading>
      <TextField
        label={intl.formatMessage({
          id: 'inputStartDateLabel',
          defaultMessage: 'Start date',
          description: 'Label for start date input',
        })}
        placeholder={intl.formatMessage({
          id: 'inputStartDatePlaceholder',
          defaultMessage: 'Enter start date',
          description: 'Placeholder for start date input',
        })}
        type="datetime-local"
        error={errors.start}
        register={register('start', {
          required: intl.formatMessage({
            id: 'inputErrorStartDateRequired',
            defaultMessage: 'Start date is required',
            description: 'Input error for start date required',
          }),
        })}
      />
      <TextField
        label={intl.formatMessage({
          id: 'inputAliasLabel',
          defaultMessage: 'Alias',
          description: 'Label for alias input',
        })}
        placeholder={intl.formatMessage({
          id: 'inputAliasPlaceholder',
          defaultMessage: 'Enter alias',
          description: 'Placeholder for alias input',
        })}
        error={errors.alias}
        register={register('alias', {
          required: intl.formatMessage({
            id: 'inputErrorAliasRequired',
            defaultMessage: 'An alias is required',
            description: 'Input error for alias required',
          }),
          maxLength: {
            value: 35,
            message: intl.formatMessage({
              id: 'inputErrorAliasMaxLength',
              defaultMessage: 'An alias cannot be more than 35 characters',
              description: 'Input error for alias max length',
            }),
          },
        })}
      />
      <ToggleCard
        title={intl.formatMessage({
          id: 'settingAliasDisplayEmail',
          defaultMessage: 'Show my email along with alias',
          description: 'Title for alias display email toggle setting',
        })}
        isActive={displayCreatorsEmail}
        onToggle={() => setValue('displayCreatorsEmail', !displayCreatorsEmail)}
      />
      <br />
      <Heading tag={Tag.H4}>
        <FormattedMessage
          id="challengeParticipantsTitle"
          defaultMessage="Participants"
          description="Challenge participants title"
        />
      </Heading>
      <p>
        <FormattedMessage
          id="challengeParticipantsDescription"
          defaultMessage="Choose whether to take on the challenge on your own or challenge your colleagues"
          description="Challenge participants description"
        />
      </p>
      <Grid>
        <SelectItem onClick={onSelect(true)}>
          <RadioButton name="challenge-yourself" isChecked={challengeSelf} />
          <span>
            <FormattedMessage
              id="challengeParticipantsYourself"
              defaultMessage="Challenge yourself"
              description="Challenge yourself"
            />
          </span>
        </SelectItem>
        <SelectItem onClick={onSelect(false)}>
          <RadioButton name="challenge-colleagues" isChecked={!challengeSelf} />
          <span>
            <FormattedMessage
              id="challengeParticipantsColleagues"
              defaultMessage="Challenge your colleagues"
              description="Challenge your colleagues"
            />
          </span>
        </SelectItem>
      </Grid>
      {content}
      {actions}
    </Fragment>
  );
};

export default ChallengeForm;
