import SelectField from 'components/UI/SelectField';
import { useAppDispatch } from 'hooks/redux';
import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { setActiveCompany, useGetCompaniesInUmbrellaCompanyQuery, useGetUserQuery } from 'store';
import * as storage from 'utils/storage';

type Company = { id: string; description: string };

const UmbrellaCompanySelector: FC = () => {
  const dispatch = useAppDispatch();
  const { register, watch, setValue } = useForm<{
    companyId: string | null | undefined;
  }>({
    defaultValues: {
      companyId: undefined,
    },
  });
  const { data: userData } = useGetUserQuery();
  const { data } = useGetCompaniesInUmbrellaCompanyQuery(undefined);
  const companyId = watch('companyId');

  useEffect(() => {
    if (data && userData && companyId === undefined) {
      const company = storage.getItem<Company>('admin_active_company');
      let initialCompany: Company | null = null;
      if (company) {
        initialCompany = company;
      } else {
        const userCompany = data?.companies?.find(
          (c) => c.id === userData.membership.companyId
        );
        if (userCompany) {
          initialCompany = {
            id: userCompany.id,
            description: userCompany.description ?? '?',
          };
        }
      }
      setValue('companyId', initialCompany ? initialCompany.id : null);
    }
  }, [data, userData, companyId, setValue]);

  useEffect(() => {
    if (companyId) {
      const company = data?.companies?.find((c) => c.id === companyId);
      if (company) {
        storage.setItem('admin_active_company', company);
        dispatch(
          setActiveCompany({
            id: company.id,
            description: company.description ?? '?',
          })
        );
      }
    }
  }, [companyId, data, dispatch]);

  return (
    <>
      {companyId !== undefined && (
        <SelectField
          narrow
          options={
            data?.companies?.map((company) => ({
              name: company.description ?? '?',
              value: company.id,
            })) ?? []
          }
          register={register('companyId')}
        />
      )}
    </>
  );
};

export default UmbrellaCompanySelector;