import React, { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams, Navigate, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import { MediaDisplay } from 'styles';
import { ContestQuizComplete, QuizQuestion, QuizQuestionActive } from 'models';
import {
  useGetQuizQuery,
  useAnswerQuizQuestionMutation,
  localizationSelector,
  useSubmitQuizMutation,
  useCompleteContestQuizMutation,
} from 'store';
import { useQuery } from 'hooks/useQuery';
import { useAppSelector } from 'hooks/redux';
import { useLocalizedText } from 'hooks/useLocalizedText';
import { getActiveQuestionById } from 'utils/quiz';
import { addOrRemoveFromArray } from 'utils/array';
import { getCategoryIcon } from 'utils/asset';
import * as routes from 'router/routes';

import IconButton from 'components/UI/IconButton';
import SliderField from 'components/UI/SliderField';
import QuizAlternativeCard from 'components/cards/QuizAlternativeCard';
import BackgroundCover from 'components/UI/BackgroundCover';
import EmptyState from 'components/UI/EmptyState';
import Icon, { IconType } from 'components/UI/Icon';
import Loader from 'components/UI/Loader';

import {
  AlternativeGrid,
  ProgressLine,
  QuizHeader,
  QuizBody,
  QuestionContainer,
  QuestionHeader,
  QuestionText,
  HeaderCenterCol,
  HeaderLeftCol,
  HeaderRightCol,
  NavButtons,
  NavButton,
  NavText,
  Content,
  HealthCategory,
} from './styles';
import TextAreaField from 'components/UI/TextAreaField';

const Quiz: FC = () => {
  // Hooks
  const navigate = useNavigate();
  const getText = useLocalizedText();
  const { slug } = useParams();
  const [questionId, redirect] = useQuery('questionId', 'redirect');
  const { language } = useAppSelector(localizationSelector);
  const quiz = useGetQuizQuery({ slug, language: language?.languageCode });
  const [answerQuestion, answerResult] = useAnswerQuizQuestionMutation();
  const [submitQuiz, submitResult] = useSubmitQuizMutation();
  const [contestQuiz, setContestQuiz] = useState<ContestQuizComplete>();
  const [completeContestQuiz] = useCompleteContestQuizMutation();
  const [textAreaValue, setTextAreaValue] = useState('');
  const location = useLocation();
  const { from, goalId, contestId, quizId, quizType } = location.state || {};

  // Data
  const [answerSubmitted, setAnswerSubmitted] = useState(false);

  const { data, isLoading } = useMemo(() => {
    return {
      data: answerResult.data || quiz.data || null,
      isLoading: submitResult.isLoading || quiz.isLoading,
      isError: submitResult.isError || answerResult.isError || quiz.isError,
      error: submitResult.error || answerResult.error || quiz.error,
    };
  }, [submitResult, answerResult, quiz]);

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextAreaValue(e.target.value);
  };

  // Active data
  const activeData = useMemo<QuizQuestionActive | null>(() => {
    if (!quiz.data) {
      return null;
    }
    return getActiveQuestionById(quiz.data.progress.questions, questionId);
  }, [quiz.data, questionId]);

  // being set when from contest quizzes
  useEffect(() => {
    if (quizType) {
      setContestQuiz({
        from: from,
        goalId: goalId,
        contestId: contestId,
        quizId: quizId,
        quizType: quizType
      });
    }
  }, [contestId, from, goalId, location.state, quizId, quizType]);

  useEffect(() => {
    if (activeData == null) {
      return;
    }
    setAnswerSubmitted(activeData.question.answered);
  }, [activeData]);

  const defaultResponse = useMemo(() => {
    if (activeData?.question.type === 'sliderQuestion') {
      const { minPoints, maxPoints } = activeData.question;
      return Math.round((maxPoints.value - minPoints.value) / 2);
    }
    return undefined;
  }, [activeData]);

  // Callbacks
  const onSetAnswer = useCallback(
    (
      question: QuizQuestion,
      points: number,
      alternativeId?: string,
      onSubmitted?: () => void,
      input?: string,
    ) => {
      const { id, type, multipleAnswersAllowed } = question;
      if (type === 'alternativeQuestion' && alternativeId) {
        answerQuestion({
          slug,
          answer: {
            questionId: id,
            alternativeIds: multipleAnswersAllowed
              ? addOrRemoveFromArray(question.answers, alternativeId)
              : [alternativeId],
            points,
          },
          language: language?.languageCode,
        }).then(onSubmitted);
      }
      if (type === 'sliderQuestion') {
        answerQuestion({
          slug,
          answer: {
            questionId: id,
            points,
          },
          language: language?.languageCode,
        }).then(onSubmitted);
      }
      if (type === 'inputQuestion') {
        answerQuestion({
          slug,
          answer: {
            questionId: id,
            points,
            input,
          },
          language: language?.languageCode,
        }).then(onSubmitted);
      }

      setAnswerSubmitted(true);
    }, [answerQuestion, slug, language]
  );

  // Set question
  const onSetQuestion = useCallback(
    (id?: string | null) => {
      if (id) {
        const redirectPath = redirect ? `&redirect=${redirect}` : '';
        navigate(`${routes.QUIZ}/${slug}?questionId=${id}${redirectPath}`);
      }
    },
    [navigate, slug, redirect]
  );

  // Previous question
  const onSetPrev = useCallback(
    () => onSetQuestion(activeData?.prevId),
    [onSetQuestion, activeData]
  );

  // Next question
  const onSetNext = useCallback(async () => {
    if (
      defaultResponse !== undefined &&
      !answerSubmitted &&
      activeData?.question.type === 'sliderQuestion'
    ) {
      const answerCallback = !activeData?.nextId
        ? () => {
          if (contestQuiz) {
            completeContestQuiz({
              contestId: contestQuiz.contestId,
              goalId: contestQuiz.goalId,
              contestQuizId: contestQuiz.quizId,
            });
          }
          submitQuiz({ slug });
        }
        : undefined;
      onSetAnswer(
        activeData.question,
        defaultResponse,
        undefined,
        answerCallback
      );
      if (!answerCallback) {
        onSetQuestion(activeData?.nextId);
        return;
      }
    } else if (!activeData?.nextId) {

      if (contestQuiz) {
        await completeContestQuiz({
          contestId: contestQuiz.contestId,
          goalId: contestQuiz.goalId,
          contestQuizId: contestQuiz.quizId,
        });
      }
      if (textAreaValue.length > 0 && activeData?.question.type === 'inputQuestion') {
        await onSetAnswer(activeData.question, 0, undefined, undefined, textAreaValue);
        return setTimeout(() => submitQuiz({ slug }), 1000);
      }

      return submitQuiz({ slug });
    }
    onSetQuestion(activeData?.nextId);
  }, [
    textAreaValue,
    activeData?.question,
    activeData?.nextId,
    answerSubmitted,
    defaultResponse,
    onSetAnswer,
    onSetQuestion,
    slug,
    submitQuiz
  ]);

  // Close quiz
  const onClose = useCallback(
    () => navigate(contestQuiz ? contestQuiz.from : redirect || routes.TESTS),
    [contestQuiz, navigate, redirect]
  );

  // Active question
  const activeQuestion = useMemo(() => {
    if (!activeData || !data) {
      return null;
    }
    const { count, question } = activeData;
    const { minPoints, maxPoints } = question;
    return (
      <div>
        <QuestionHeader>
          <FormattedMessage
            id="pageQuizQuestionCount"
            defaultMessage="Question {count}/{total}"
            description="Question count for quiz"
            values={{
              count,
              total: data.progress.questions.length,
            }}
          />
        </QuestionHeader>
        <QuestionText>{getText(question.text)}</QuestionText>
        {question.type === 'alternativeQuestion' ? (
          <AlternativeGrid>
            {question.alternatives.map((alt) => (
              <QuizAlternativeCard
                key={alt.id}
                text={getText(alt.text)}
                isActive={question.answers.includes(alt.id)}
                hasMultipleAnswers={question.multipleAnswersAllowed}
                onClick={() => onSetAnswer(question, alt.points, alt.id)}
              />
            ))}
          </AlternativeGrid>
        ) : null}
        {question.type === 'sliderQuestion' ? (
          <SliderField
            minValue={minPoints.value}
            maxValue={maxPoints.value}
            minText={getText(minPoints.description)}
            maxText={getText(maxPoints.description)}
            onChange={(points) => onSetAnswer(question, points)}
            defaultValue={defaultResponse}
          />
        ) : null}
        {question.type === 'inputQuestion' ? (
          <TextAreaField
            onChange={handleTextAreaChange}
          />
        ) : null}
      </div>
    );
  }, [data, activeData, getText, onSetAnswer, defaultResponse]);

  // Health category
  const healthCategory = useMemo(() => {
    if (!data) {
      return null;
    }
    const { healthCategory } = data.quizDefinition;
    if (!healthCategory) {
      return null;
    }
    const { title, icon } = healthCategory;
    const img = getCategoryIcon(icon, true);
    return (
      <Fragment>
        {img && <img src={img.src} alt={img.alt} color="white" />}
        {getText(title)}
      </Fragment>
    );
  }, [getText, data]);

  // Progress bar
  const progressBar = useMemo(() => {
    if (!data || !activeData) {
      return null;
    }
    const { index } = activeData;
    const { noQuestions } = data.progress;
    return Array.from(Array(noQuestions).keys()).map((i) => (
      <ProgressLine key={i} isActive={i === index} />
    ));
  }, [data, activeData]);

  // Content
  const content = useMemo(() => {
    // Loading
    if (isLoading) {
      return <Loader padding />;
    }

    // No data
    if (!data) {
      return (
        <EmptyState iconType={IconType.Health} padding inverted>
          <FormattedMessage
            id="pageQuizEmptyState"
            defaultMessage="Quiz not found"
            description="Empty state for quiz"
          />
        </EmptyState>
      );
    }

    return (
      <AnimatePresence mode="wait">
        <QuestionContainer
          key={questionId}
          transition={{ duration: 0.2 }}
          initial={{ y: 32, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -32, opacity: 0 }}
        >
          {activeQuestion}
        </QuestionContainer>
      </AnimatePresence>
    );
  }, [data, isLoading, questionId, activeQuestion]);

  // Left nav
  const leftNav = useMemo(() => {
    if (!activeData?.prevId) {
      return null;
    }
    return (
      <NavButton left onClick={onSetPrev}>
        <Icon type={IconType.Arrow} color="white" />
      </NavButton>
    );
  }, [onSetPrev, activeData]);

  // Right nav
  const rightNav = useMemo(() => {
    if (isLoading || !activeData || answerResult.isLoading) {
      return null;
    }
    let questionData = activeData;

    if (
      answerResult.data &&
      answerResult.originalArgs?.answer?.questionId === activeData.question.id
    ) {
      questionData = getActiveQuestionById(
        answerResult.data.progress.questions,
        questionId
      );
    }

    if (
      questionData.question.type !== 'inputQuestion' &&
      questionData.question.type !== 'sliderQuestion' &&
      !questionData.question.answered
    ) {
      return null;
    }
    return (
      <NavButton
        onClick={onSetNext}
        disabled={!questionData.question && defaultResponse === undefined}
      >
        {questionData.nextId ? (
          <Icon type={IconType.Arrow} color="white" />
        ) : (
          <NavText>
            <FormattedMessage
              id="pageQuizShowResults"
              defaultMessage="Show results"
              description="Show results button for quiz"
            />
          </NavText>
        )}
      </NavButton>
    );
  }, [
    isLoading,
    activeData,
    answerResult.isLoading,
    answerResult.data,
    answerResult.originalArgs?.answer?.questionId,
    onSetNext,
    defaultResponse,
    questionId,
  ]);

  // Redirect to results
  if (submitResult.isSuccess) {
    return (
      <Navigate
        to={`${routes.QUIZ}/${slug}/results`}
        state={contestQuiz && { ContestQuizComplete: contestQuiz }}
      />
    );
  }

  return (
    <BackgroundCover padding>
      <QuizHeader>
        <HeaderLeftCol>
          <HealthCategory>{healthCategory}</HealthCategory>
          <MediaDisplay breakpoint="m">
            <IconButton onClick={onClose} padding>
              <Icon type={IconType.Close} color="white" />
            </IconButton>
          </MediaDisplay>
        </HeaderLeftCol>
        <HeaderCenterCol>{progressBar}</HeaderCenterCol>
        <HeaderRightCol>
          <MediaDisplay breakpoint="m" isLarger>
            <IconButton onClick={onClose} padding>
              <Icon type={IconType.Close} color="white" />
            </IconButton>
          </MediaDisplay>
        </HeaderRightCol>
      </QuizHeader>
      <QuizBody>
        <Content>{content}</Content>
        <NavButtons>
          <div>{leftNav}</div>
          {rightNav}
        </NavButtons>
      </QuizBody>
    </BackgroundCover>
  );
};

export default Quiz;
