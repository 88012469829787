import {
  getEndpointURL,
  WellrEndpointDefinition,
  WellrEndpointPath,
} from 'utils/api';

/** Get company invite codes */
const getCompanyInviteCodesPath: WellrEndpointPath =
  '/api/company/company/invite/{companyId}';
const getCompanyInviteCodesMethod = 'get';
type GetCompanyInviteCodes = WellrEndpointDefinition<
  typeof getCompanyInviteCodesPath,
  typeof getCompanyInviteCodesMethod
>;

export type GetCompanyInviteCodesType = {
  result: GetCompanyInviteCodes['responseBody'];
  queryArgs: Required<GetCompanyInviteCodes['request']['params']['path']>;
};

/** Create invite code for company */
const createCompanyInviteCodesPath: WellrEndpointPath =
  '/api/company/company/invite';
const createCompanyInviteCodesMethod = 'post';
type CreateCompanyInviteCodes = WellrEndpointDefinition<
  typeof createCompanyInviteCodesPath,
  typeof createCompanyInviteCodesMethod
>;

export type CreateCompanyInviteCodesType = {
  result: CreateCompanyInviteCodes['responseBody'];
  queryArgs: CreateCompanyInviteCodes['request']['body'];
};

/** Create invite code for company */
const updateCompanyInviteCodesPath: WellrEndpointPath =
  '/api/company/company/invite';
const updateCompanyInviteCodesMethod = 'patch';
type UpdateCompanyInviteCodes = WellrEndpointDefinition<
  typeof updateCompanyInviteCodesPath,
  typeof updateCompanyInviteCodesMethod
>;

export type UpdateCompanyInviteCodesType = {
  result: UpdateCompanyInviteCodes['responseBody'];
  queryArgs: UpdateCompanyInviteCodes['request']['body'];
};

export const endpoints = {
  companyInvites: {
    get: {
      path: (companyId: string) =>
        getEndpointURL(getCompanyInviteCodesPath, { companyId }),
      method: getCompanyInviteCodesMethod.toUpperCase(),
    },
    create: {
      path: getEndpointURL(createCompanyInviteCodesPath),
      method: createCompanyInviteCodesMethod.toUpperCase(),
    },
    update: {
      path: getEndpointURL(updateCompanyInviteCodesPath),
      method: updateCompanyInviteCodesMethod.toUpperCase(),
    },
  },
};
