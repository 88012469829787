import { SelectItem } from 'components/forms/ChallengeForm/styles';
import ExpandableCard from 'components/UI/Cards/ExpandableCard';
import Checkbox from 'components/UI/Checkbox';
import Loader from 'components/UI/Loader';
import { useMembershipsContext } from 'context/useSelectedMembershipContext';
import { useDebounce } from 'hooks/useDebounce';
import { FC, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useGetInvitableMembershipsWithStructureQuery } from 'store';
import { GetInvitableMembershipsWithStructureType } from 'store/membershipService/endpointDefinitions';
import { CardsWrapper, CheckboxContentList, NestedCard } from './styles';

type MembershipStructureData =
  GetInvitableMembershipsWithStructureType['result'];

function departmentHasNoMemberships(
  department: NonNullable<MembershipStructureData['departments']>[number]
) {
  return (department.memberships ?? []).length === 0;
}

function officeHasNoMemberships(
  office: NonNullable<MembershipStructureData['offices']>[number]
) {
  return (office.departments ?? []).every(departmentHasNoMemberships);
}

function headOfficeHasNoMemberships(
  headOffice: NonNullable<MembershipStructureData['headOffices']>[number]
) {
  return (headOffice.offices ?? []).every(officeHasNoMemberships);
}

const MembershipsList: FC<{
  memberships: MembershipStructureData['memberships'];
}> = ({ memberships }) => {
  const { selected, onToggle } = useMembershipsContext();

  return (
    <CheckboxContentList>
      {memberships?.map((membership) => (
        <div key={membership.email}>
          <SelectItem
            key={membership.membershipId}
            onClick={() => onToggle(membership.membershipId)}
          >
            <Checkbox
              name={membership.membershipId}
              isChecked={selected.includes(membership.membershipId)}
            />
            <span>
              {membership.firstName} {membership.lastName}
            </span>
          </SelectItem>
        </div>
      ))}
    </CheckboxContentList>
  );
};

const DepartmentsList: FC<{
  departments: MembershipStructureData['departments'];
}> = ({ departments }) => {
  const { selected, onToggle } = useMembershipsContext();
  const [checkedStates, setCheckedStates] = useState<{ [key: string]: boolean }>({});

  const handleCheck = (
    departmentId: string,
    checked: string,
    departmentMembershipIds: string[],
    selectedMembershipsInDepartment: string[],
    hasSelectedMembership: boolean,
    isAllMembershipsSelected: boolean
  ): void => {
    setCheckedStates((prev) => ({
      ...prev,
      [departmentId]: !prev[departmentId],
    }));

    if (hasSelectedMembership) {
      onToggle(isAllMembershipsSelected ? departmentMembershipIds : selectedMembershipsInDepartment);
    } else if (checked) {
      onToggle(departmentMembershipIds);
    }
  };

  return (
    <>
      {departments?.map((department) => {
        if (departmentHasNoMemberships(department)) {
          return null;
        }

        const departmentMembershipIds = department.memberships?.map(membership => membership.membershipId) || [];
        const selectedMembershipsInDepartment = selected.filter((id) => departmentMembershipIds.includes(id));
        const hasSelectedMembership = selectedMembershipsInDepartment.length > 0;
        const isAllMembershipsSelected = departmentMembershipIds.every(id => selected.includes(id));
        const isChecked = checkedStates[department.id] || false;

        return (
          <ExpandableCard
            title={department.description}
            id={department.id}
            key={department.id}
            allowMulti
            defaultIsOpen
            narrow
            isChecked={isChecked}
            hideCheckbox={departmentMembershipIds.length < 2}
            onCheck={(checked) => handleCheck(
              department.id,
              checked,
              departmentMembershipIds,
              selectedMembershipsInDepartment,
              hasSelectedMembership,
              isAllMembershipsSelected
            )}
          >
            <MembershipsList memberships={department.memberships} />
          </ExpandableCard>
        );
      })}
    </>
  );
};

const OfficesList: FC<{
  offices: MembershipStructureData['offices'];
}> = ({ offices }) => {
  return (
    <>
      {offices?.map((office) => {
        if (officeHasNoMemberships(office)) {
          return null;
        }
        return (
          <ExpandableCard
            title={office.description}
            id={office.id}
            key={office.id}
            allowMulti
            defaultIsOpen
            narrow
          >
            <NestedCard>
              <DepartmentsList departments={office.departments} />
            </NestedCard>
          </ExpandableCard>
        );
      })}
    </>
  );
};

const HeadOfficesList: FC<{
  headOffices: MembershipStructureData['headOffices'];
}> = ({ headOffices }) => {
  return (
    <>
      {headOffices?.map((headOffice) => {
        if (headOfficeHasNoMemberships(headOffice)) {
          return null;
        }
        return (
          <ExpandableCard
            title={headOffice.description}
            id={headOffice.id}
            key={headOffice.id}
            allowMulti
            defaultIsOpen
            narrow
          >
            <NestedCard>
              <OfficesList offices={headOffice.offices} />
            </NestedCard>
          </ExpandableCard>
        );
      })}
    </>
  );
};

function filterMemberships(
  memberships: MembershipStructureData['memberships'],
  search: string
) {
  return (
    memberships?.filter(({ email, firstName, lastName }) =>
      [
        email ?? '',
        firstName ?? '',
        lastName ?? '',
        `${firstName} ${lastName}`,
      ].some((value) => value.toLowerCase().includes(search))
    ) ?? []
  );
}

type Props = {
  filter?: string;
  debounceTime?: number;
};

const MemberSelectFromStructure: FC<Props> = ({
  filter: filterRaw,
  debounceTime = 250,
}) => {
  const structureData = useGetInvitableMembershipsWithStructureQuery();
  const intl = useIntl();

  const filter = useDebounce(filterRaw, debounceTime); // Adjust the debounce delay as needed

  const companyStructure = useMemo(() => {
    if (!structureData.data) {
      return null;
    }
    const search = !filter || filter === '' ? null : filter.toLowerCase();

    const { headOffices, offices, departments, memberships } =
      structureData.data;

    // Only return headoffices, offices and departments with members
    return {
      headOffices: headOffices?.map((ho) => ({
        ...ho,
        offices:
          ho.offices?.map((o) => ({
            ...o,
            departments:
              o.departments?.map((d) =>
                search === null
                  ? d
                  : {
                    ...d,
                    memberships: filterMemberships(d.memberships, search),
                  }
              ) ?? [],
          })) ?? [],
      })),
      offices: offices?.map((o) => ({
        ...o,
        departments:
          o.departments?.map((d) =>
            search === null
              ? d
              : {
                ...d,
                memberships: filterMemberships(d.memberships, search),
              }
          ) ?? [],
      })),
      departments: departments?.map((d) =>
        search === null
          ? d
          : {
            ...d,
            memberships: filterMemberships(d.memberships, search),
          }
      ),
      memberships:
        (search === null
          ? memberships
          : filterMemberships(memberships, search)) ?? [],
    };
  }, [structureData.data, filter]);

  if (structureData.isLoading) {
    return <Loader color="blue" padding />;
  }

  if (companyStructure === null) {
    return null;
  }

  return (
    <>
      <CardsWrapper>
        <HeadOfficesList headOffices={companyStructure.headOffices} />
        <OfficesList offices={companyStructure.offices} />
        <DepartmentsList departments={companyStructure.departments} />
        {companyStructure.memberships.length > 0 && (
          <ExpandableCard
            title={intl.formatMessage({
              id: 'pageAdminOrphanMembersCardTitle',
              defaultMessage: 'Members without a department',
              description:
                'Card heading for expandable card with members without a department',
            })}
            id={'memberships'}
            allowMulti
            defaultIsOpen
            narrow
          >
            <MembershipsList memberships={companyStructure.memberships} />
          </ExpandableCard>
        )}
      </CardsWrapper>
    </>
  );
};

export default MemberSelectFromStructure;
