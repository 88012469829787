import styled from 'styled-components';


export const PulseHeader = styled.div`
  display: flex;
  align-items: center;

`;

export const RoundedCard = styled.div`
  background-color: rgb(235,242,245);
  border-radius: 15px;
  padding: 1.1rem;
  padding-top: 0rem;
  margin-top: 1rem
`;