import { FC, useState } from 'react';

import { MenuItem } from 'models';
import Icon, { IconType } from 'components/UI/Icon';

import { Content, Menu, MenuLink } from './styles';

type Props = {
  menu: MenuItem[];
};

const ListMenu: FC<Props> = ({ menu }) => {
  const [hoverId, setHoverId] = useState<number | string | null>(null);
  return (
    <Menu>
      {menu.map(({ id, icon, text, link = '' }) => (
        <MenuLink
          key={id}
          role="button"
          to={link}
          onMouseEnter={() => setHoverId(id)}
          onMouseLeave={() => setHoverId(null)}
        >
          <Content>
            {icon && (
              <Icon
                type={icon}
                color="blue"
                hoverColor="orange"
                isHovering={hoverId === id}
              />
            )}
            {text}
          </Content>
          <Icon
            type={IconType.Arrow}
            color="blue"
            hoverColor="orange"
            isHovering={hoverId === id}
          />
        </MenuLink>
      ))}
    </Menu>
  );
};

export default ListMenu;
