import styled from 'styled-components';
import { breakpoints, defaultTheme } from 'styles';

export const HeroGrid = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 120px;

  @media (max-width: ${breakpoints.m}px) {
    margin: 32px 0 16px;
    gap: 56px;
  }

  @media (max-width: ${breakpoints.s}px) {
    gap: 16px;
  }
`;

type printerProps = {
  onClick: () => void;
}

export const PrinterIcon = styled.div<printerProps>`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
`;

export const Svg = styled.img`
  height: 20px;
`;

export const HeroCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 96px;
    height: 96px;
  }

  h2 {
    margin: 16px 0 4px;
  }

  p {
    color: ${({ theme }) => theme.colors.white};
    font-size: 14px;
    font-weight: 500;
    margin: 0;
  }
`;

export const HealthIcon = styled.img`
  margin-bottom: -8px;
`;

interface ContentWidthProps {
  noMargins?: boolean;
  isSurface?: boolean;
}

export const OutletWidth = styled.div<ContentWidthProps>`
position: relative;
  background-color: black;
  max-width: 640px;
  margin: ${({ noMargins }) => (noMargins ? '0 auto' : '32px auto')};

  background: ${({ isSurface }) =>
    isSurface ? defaultTheme.colors.backgroundLight : 'none'};
  border-radius: ${({ isSurface }) => (isSurface ? '24px' : '0')};
  padding: ${({ isSurface }) => (isSurface ? '24px 24px 24px 28px' : '0')};

  @media (max-width: ${breakpoints.m}px) {
    overflow-x: hidden;
    max-width: none;
    padding: ${({ isSurface }) => (isSurface ? '16px' : '0')};
    margin: 0;
  }
`;


export const ProfileHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
  width: 100%;

  @media (max-width: ${breakpoints.s}px) {
    flex-direction: column;
    gap: 16px;
  }

  h1 {
    margin: 0;
  }
`;

export const ProfileHeaderData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
  padding: 8px 0;

  @media (max-width: ${breakpoints.s}px) {
    padding: 0;
    align-items: center;
    gap: 16px;
  }

  h1 {
    font-size: 22px;
  }
`;

export const ProfileHeaderDataGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  text-align: left;

  @media (max-width: ${breakpoints.s}px) {
    text-align: center;
  }
`;

export const ProfileImage = styled.div`
  position: relative;
  width: 120px;
  height: 120px;
  min-height: 120px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.backgroundLight};
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${breakpoints.s}px) {
    width: 64px;
    height: 64px;
    min-height: 64px;
  }
  `;
export const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

