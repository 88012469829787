import styled from 'styled-components';

export const Container = styled.div<{ center: boolean }>`
  width: 100%;
  display: flex;
  justify-content: ${({ center }) => (center ? 'center' : 'flex-start')};
  > h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    width: auto;
    display: flex;
  }
`;

export const IconButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 16px;
`;

export const HeadingContent = styled.span`
  display: flex;
  align-items: center;
  gap: 5px;
`;
