import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ContentWidth, FlexContainer, Gap } from 'styles';
import { useAppDispatch } from 'hooks/redux';
import { setLayoutHeader } from 'store';

import { IconType } from 'components/UI/Icon';
import EmptyState from 'components/UI/EmptyState';
import Heading, { Tag } from 'components/UI/Heading';
import Loader from 'components/UI/Loader';

import {
  useGetNotificationPermissionQuery,
  useRemoveNotificationDeviceMutation, useSetNotificationDeviceIsActiveMutation,
  useUpdateSourcePermissionMutation
} from '../../../store/notificationService/notificationService';
import {
  NotificationCategory, NotificationSource,
  RemovePushNotificationDeviceDto, SetNotificationDeviceIsActiveDto, UpdateSourcePermissionDto
} from '../../../models/notification/notification';
import DeleteNotificationDeviceModal from '../../../components/modals/DeleteNotificationDeviceModal';
import StaticCard from '../../../components/UI/Cards/StaticCard';
import { Container } from '../MFASettings/styles';
import ToggleCard from '../../../components/UI/Cards/ToggleCard';
import NotificationDeviceCard from '../../../components/UI/Cards/NotificationDeviceCard';
import HeadingWithInfo from 'components/UI/HeadingWithInfo';
import useModal from 'hooks/useModal';
import ContentModal from 'components/modals/ContentModal';

const NotificationSettings: FC = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();

  // Hooks
  const { isLoading, data, refetch } = useGetNotificationPermissionQuery();
  const [removeNotificationDevice] = useRemoveNotificationDeviceMutation();
  const [updateSourcePermission, updateSourcePermissionStatus] = useUpdateSourcePermissionMutation();
  const [setNotificationDeviceIsActive, setNotificationDeviceIsActiveStatus] = useSetNotificationDeviceIsActiveMutation();

  // State
  const [selectedDevice, setSelectedDevice] = useState<RemovePushNotificationDeviceDto | null>(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const {
    modalOpen: settingsInfoModalOpen,
    onCloseModal: onCloseSettingsInfoModal,
    onOpenModal: onOpenSettingsInfoModal } = useModal();

  // Set header
  useEffect(() => {
    dispatch(
      setLayoutHeader({
        title: intl.formatMessage({
          id: 'pageSettingsTitle',
          defaultMessage: 'Settings',
          description: 'Page title for settings',
        }),
        icon: IconType.Back,
      })
    );
  }, [dispatch, intl]);

  // Open / close delete modal
  const onDeleteDevice = useCallback((device: RemovePushNotificationDeviceDto) => {
    setSelectedDevice(device)
    setDeleteModalOpen(true)
  }, []);
  const onCloseDeleteDevice = useCallback(() => {
    setDeleteModalOpen(false);
    setSelectedDevice(null);
    refetch();
  }, []);
  const onUpdateSourcePermission = useCallback(async (dto: UpdateSourcePermissionDto) => {
    await updateSourcePermission(dto);
    refetch();
  }, [updateSourcePermission, data]);

  const onSetNotificationDeviceIsActive = useCallback(async (dto: SetNotificationDeviceIsActiveDto) => {
    await setNotificationDeviceIsActive(dto);
    refetch();
  }, [setNotificationDeviceIsActive, data])

  const getDescriptionText = (accepts: boolean): string => accepts ? `${intl.formatMessage({
    id: 'notificationDeviceCardDescriptionAccepted',
    defaultMessage: 'Accepts notifications',
    description: 'description for notification device adjudicated accepted',
  })}` : `${intl.formatMessage({
    id: 'notificationDeviceCardDescriptionDeclined',
    defaultMessage: 'Notifications declined',
    description: 'description for notification device adjudicated declined',
  })}`

  // Notification devices
  const devices = useMemo(() => {
    if (isLoading) {
      return <Loader padding color="blue" />;
    }
    if (!data) {
      return (
        <EmptyState iconType={IconType.User} padding>
          <FormattedMessage
            id="pageNotificationSettingsEmptyState"
            defaultMessage="Unable to get notifications data"
            description="Empty state for user notification settings"
          />
        </EmptyState>
      );
    }
    return (
      <StaticCard padding>
        <Container>
          <Heading tag={Tag.H3}>
            <FormattedMessage
              id="PushnotificationdevicessTitle"
              defaultMessage="Push notification devices"
              description="Edit push notification devices"
            />
          </Heading>
          <FlexContainer gap={12} flexDirection="column">
            {data.userData?.devices?.map((device) => (
              <NotificationDeviceCard
                title={device.model}
                key={device.deviceId}
                onDelete={() => onDeleteDevice({ deviceId: device.deviceId })}
                onToggle={() => onSetNotificationDeviceIsActive({ deviceId: device.deviceId, isActive: !device.isActive })}
                isActive={device.isActive}
                description={getDescriptionText(device.isActive)}
              />
            ))}
          </FlexContainer>
        </Container>
      </StaticCard>
    );
  }, [removeNotificationDevice, data, isLoading, onDeleteDevice, setNotificationDeviceIsActiveStatus.isLoading, intl]);

  const permissions = useMemo(() => {
    if (isLoading) {
      return <Loader padding color="blue" />;
    }
    if (!data) {
      return null;
    }

    // @Todo: interface this properly
    const items: any = {}
    Object.entries(data.defaultPermissions).map(([key, value]) => {
      if (key === 'System') {
        return null;
      }
      const values: any[] = []
      const category = key as unknown as NotificationCategory
      value.forEach(v => {
        const userPermission = data.userData?.permissions[category]?.find(x => x.source === v)
        values.push(userPermission ?? { source: v, enabled: true, updated: undefined })
      })
      items[key] = values
    })

    const formatDate = (date: string): string => {
      const parts = date.split('T')[0].split('-')
      const year = parts[0].substring(2)
      const month = parts[1]
      const day = parts[2]
      return `${year + month + day}`
    }

    const updatedText = (date: string): string => {
      if (date === undefined) return ''
      return `(${intl.formatMessage({
        id: 'permissionsUpdatedPrefix',
        defaultMessage: 'Updated',
        description: 'Prefix text for permission updated',
      })} ${formatDate(date)})`
    }

    const sourceText = (source: NotificationSource) => {
      const string = source as unknown as string
      switch (string) {
        case "Platform":
          return {
            title: intl.formatMessage({
              id: 'sourcePlatformTitle',
              defaultMessage: 'Platform',
              description: 'Notification source, Platform',
            })
          }
        case "App":
          return {
            title: intl.formatMessage({
              id: 'sourceAppTitle',
              defaultMessage: 'App',
              description: 'Notification source, App',
            })
          }
        case "Email":
          return {
            title: intl.formatMessage({
              id: 'sourceEmailTitle',
              defaultMessage: 'Email',
              description: 'Notification source, Email',
            })
          }
      }
    }

    const sources = (key: string, stuff: any) => {
      let elementList = []
      for (const s of stuff) {
        if (s.source === 'Platform') {
          continue;
        }
        elementList.push(<ToggleCard
          title={sourceText(s.source)?.title + (s.updated ? (' ' + updatedText(s.updated)) : '')}
          key={key + '-' + s.source}
          onToggle={(e) => {
            return onUpdateSourcePermission({
              category: key as unknown as NotificationCategory, isActive: e, source: s.source
            })
          }}
          isActive={s.enabled}
          isLocked={s.source === 'Platform'}
        />)
      }
      return elementList
    }

    const getElementInfoList = (key: string) => {
      switch (key) {
        case 'Contest':
          return (
            <div key={key}>
              <Heading tag={Tag.H4}>{getCategoryText(key)}</Heading>
              {intl.formatMessage({
                id: 'contestNotificationDescription',
                defaultMessage: 'With these notifications, you stay updated on competitions, including standings, new challenges, results, and messages in competition chats. These notifications help you track your performance and stay engaged in competition activities. If you choose to disable these notifications, you may miss important updates and the opportunity to fully participate in the competitions.',
                description: 'Description for contest notifications'
              })}
            </div>
          );
        case 'Quiz':
          return (
            <div key={key}>
              <Heading tag={Tag.H4}>{getCategoryText(key)}</Heading>
              {intl.formatMessage({
                id: 'quizNotificationDescription',
                defaultMessage: 'Here you will receive notifications about tests, including recommended tests. These may also contain information about surveys and tests sent out by the company to improve the work environment and gather your feedback. If you choose to disable these notifications, you risk missing out on valuable feedback and development opportunities that can affect both you and the workplace.',
                description: 'Description for quiz notifications'
              })}
            </div>
          );
        case 'Health':
          return (
            <div key={key}>
              <Heading tag={Tag.H4}>{getCategoryText(key)}</Heading>
              {intl.formatMessage({
                id: 'healthNotificationDescription',
                defaultMessage: 'These notifications keep you updated on health and well-being, including new health plans and personalized recommendations. You may also receive tailored or recommended material from your company. These notifications help you access new resources and opportunities to improve your health. If you disable these notifications, you may miss out on important updates and recommendations related to your health.',
                description: 'Description for health notifications'
              })}
            </div>
          );
        default:
          return (
            <div key={key}>
              <Heading tag={Tag.H4}>{getCategoryText(key)}</Heading>
            </div>
          );
      }
    };


    return (
      <StaticCard padding>
        <Container>
          <HeadingWithInfo
            tag={Tag.H3}
            onClickInfo={onOpenSettingsInfoModal}>
            <FormattedMessage
              id="NotificationPermissionsTitle"
              defaultMessage="Permissions"
              description="Edit permissions for platforms"
            />
          </HeadingWithInfo>
          <FlexContainer gap={6} flexDirection="column">
            {
              Object.entries(items).map(([key, stuff], idx) => {
                let elementList = (
                  <Heading tag={Tag.H4} key={key}>
                    {getCategoryText(key)}
                    {sources(key, stuff)}
                  </Heading>
                )
                return elementList
              })
            }
          </FlexContainer>
        </Container>
        <ContentModal
          isOpen={settingsInfoModalOpen}
          onClose={onCloseSettingsInfoModal}
          title={<FormattedMessage
            id="NotificationPermissionsTitle"
            defaultMessage="Permissions"
            description="Edit permissions for platforms"
          />} >
          <FlexContainer gap={6} flexDirection="column">
            {
              Object.entries(items).map(([key]) => {
                return getElementInfoList (key);
              })
            }
          </FlexContainer>
        </ContentModal>
      </StaticCard>
    );
  }, [isLoading, data, onOpenSettingsInfoModal, settingsInfoModalOpen, onCloseSettingsInfoModal, intl, onUpdateSourcePermission]);

  return (
    <ContentWidth isSurface>
      <Heading>
        <FormattedMessage
          id="pageNotificationSettingsTitle"
          defaultMessage="Edit notifications"
          description="Page title for notification settings"
        />
      </Heading>
      {devices}
      <Gap />
      {permissions}
      {
        deleteModalOpen && selectedDevice &&
        <DeleteNotificationDeviceModal isOpen={deleteModalOpen} onClose={onCloseDeleteDevice} removeDeviceDto={selectedDevice!} />
      }
    </ContentWidth>
  );
};

export default NotificationSettings;

function getCategoryText(category: string) {
  switch (category) {
    case "System":
      return <FormattedMessage
        id="categorySystemTitle"
        defaultMessage="System"
        description="Notification category, System"
      />
    case "Contest":
      return <FormattedMessage
        id="categoryContestTitle"
        defaultMessage="Contest"
        description="Notification category, Contest"
      />
    case "Quiz":
      return <FormattedMessage
        id="categoryQuizTitle"
        defaultMessage="Quiz"
        description="Notification category, Quiz"
      />
    case "Health":
      return <FormattedMessage
        id="categoryHealthTitle"
        defaultMessage="Health"
        description="Notification category, Health"
      />
  }
}
