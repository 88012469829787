import { baseApi } from '../baseApi';
import {
  CreateCompanyInviteCodesType,
  endpoints,
  GetCompanyInviteCodesType,
  UpdateCompanyInviteCodesType,
} from './endpointDefinitions';

const wellrAdminApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCompanyInviteCodes: builder.query<
      GetCompanyInviteCodesType['result'],
      GetCompanyInviteCodesType['queryArgs']
    >({
      query: ({ companyId }) => ({
        url: endpoints.companyInvites.get.path(companyId),
        method: endpoints.companyInvites.get.method,
      }),
      providesTags: ['CompanyInviteCodes'],
    }),
    createCompanyInviteCode: builder.mutation<
      CreateCompanyInviteCodesType['result'],
      CreateCompanyInviteCodesType['queryArgs']
    >({
      query: (body) => ({
        url: endpoints.companyInvites.create.path,
        method: endpoints.companyInvites.create.method,
        body,
      }),
      invalidatesTags: ['CompanyInviteCodes'],
    }),
    updateCompanyInviteCode: builder.mutation<
      UpdateCompanyInviteCodesType['result'],
      UpdateCompanyInviteCodesType['queryArgs']
    >({
      query: (body) => ({
        url: endpoints.companyInvites.update.path,
        method: endpoints.companyInvites.update.method,
        body,
      }),
      invalidatesTags: ['CompanyInviteCodes'],
    }),
  }),
});

export const {
  useGetCompanyInviteCodesQuery,
  useCreateCompanyInviteCodeMutation,
  useUpdateCompanyInviteCodeMutation,
} = wellrAdminApi;
