import styled from 'styled-components';
import { breakpoints } from 'styles';

export const ButtonGrid = styled.div`
  display: flex;
  gap: 8px;

  @media (max-width: ${breakpoints.s}px) {
    flex-direction: column-reverse;
  }
`;
