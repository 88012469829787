import styled from 'styled-components';

type RowProps = {
  onClick?: () => void;
  divider?: boolean;
};

export const CardRow = styled.div<RowProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: ${({ divider, theme }) =>
    divider ? `1px solid ${theme.colors.borderLight}` : 'none'};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  gap: 12px;
  flex-grow: 1;
  cursor: pointer;
`;

export const DayContainer = styled.div`
  width: 44px;
  height: 22px;
  color: ${({ theme }) => theme.colors.blue};
  background: transparent;
  border: 2px solid #cdd3d6;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
  border-radius: 10px;
`;

export const Text = styled.p`
  margin: 0;
  flex: 1;
`;

export const CardContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const CheckboxContainer = styled.div`
  margin-right: 20px;
  display: flex;
  align-items: center;
`;
