import styled from 'styled-components';
import { breakpoints } from 'styles';

export const Container = styled.div`
  margin-bottom: 16px;
`;

export const Img = styled.img`
  width: 100%;
  border-radius: 16px;
  margin: 20px 0;
`;

export const Video = styled.iframe`
  width: 100%;
  height: 400px;
  border: none;
  margin: 20px 0;
  allowFullScreen: true;

  @media (max-width: ${breakpoints.m}px) {
    height: 320px;
  }

  @media (max-width: ${breakpoints.s}px) {
    height: 280px;
  }
`;
