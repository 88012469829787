import ExpandableCard from 'components/UI/Cards/ExpandableCard';
import Loader from 'components/UI/Loader';
import RadioButton from 'components/UI/RadioButton';
import { useDepartmentContext } from 'context/useSelectedDepartmentContext';
import { useDebounce } from 'hooks/useDebounce';
import { FC, useMemo } from 'react';
import { useGetCompanyStructureQuery } from 'store';
import { GetInvitableMembershipsWithStructureType } from 'store/membershipService/endpointDefinitions';
import {
  CardsWrapper,
  CheckboxContentList,
  NestedCard,
  SelectItem,
} from './styles';

type MembershipStructureData =
  GetInvitableMembershipsWithStructureType['result'];

function officeHasNoDepartments(
  office: NonNullable<MembershipStructureData['offices']>[number]
) {
  return (office.departments ?? []).length === 0;
}

function headOfficeHasNoDepartments(
  headOffice: NonNullable<MembershipStructureData['headOffices']>[number]
) {
  return (headOffice.offices ?? []).every(officeHasNoDepartments);
}

const DepartmentsList: FC<{
  departments: MembershipStructureData['departments'];
}> = ({ departments }) => {
  const { selected, onToggle } = useDepartmentContext();
  return (
    <CheckboxContentList>
      {departments?.map((department) => (
        <div key={department.id}>
          <SelectItem
            key={department.id}
            onClick={() => onToggle(department.id)}
          >
            <RadioButton
              name={department.id}
              isChecked={selected === department.id}
            />
            <span>{department.description}</span>
          </SelectItem>
        </div>
      ))}
    </CheckboxContentList>
  );
};

const OfficesList: FC<{
  offices: MembershipStructureData['offices'];
}> = ({ offices }) => {
  return (
    <>
      {offices?.map((office) => {
        if (officeHasNoDepartments(office)) {
          return null;
        }
        return (
          <ExpandableCard
            title={office.description}
            id={office.id}
            key={office.id}
            allowMulti
            defaultIsOpen
            narrow
          >
            <NestedCard>
              <DepartmentsList departments={office.departments} />
            </NestedCard>
          </ExpandableCard>
        );
      })}
    </>
  );
};

const HeadOfficesList: FC<{
  headOffices: MembershipStructureData['headOffices'];
}> = ({ headOffices }) => {
  return (
    <>
      {headOffices?.map((headOffice) => {
        if (headOfficeHasNoDepartments(headOffice)) {
          return null;
        }
        return (
          <ExpandableCard
            title={headOffice.description}
            id={headOffice.id}
            key={headOffice.id}
            allowMulti
            defaultIsOpen
            narrow
          >
            <NestedCard>
              <OfficesList offices={headOffice.offices} />
            </NestedCard>
          </ExpandableCard>
        );
      })}
    </>
  );
};

function filterDepartments(
  departments: MembershipStructureData['departments'],
  search: string
) {
  return (
    departments?.filter(({ description }) =>
      [description ?? ''].some((value) => value.toLowerCase().includes(search))
    ) ?? []
  );
}

type Props = {
  filter?: string;
  debounceTime?: number;
  companyId: string;
};

const DepartmentSelectFromStructure: FC<Props> = ({
  filter: filterRaw,
  debounceTime = 250,
  companyId,
}) => {
  const structureData = useGetCompanyStructureQuery({ companyId });

  const filter = useDebounce(filterRaw, debounceTime); // Adjust the debounce delay as needed

  const companyStructure = useMemo(() => {
    if (!structureData.data) {
      return null;
    }
    const search = !filter || filter === '' ? null : filter.toLowerCase();

    const { headOffices, offices, departments } = structureData.data;

    // Only return headoffices and offices with departments
    // and only return departments that match the search
    return {
      headOffices: headOffices?.map((ho) => ({
        ...ho,
        offices:
          ho.offices?.map((o) => ({
            ...o,
            departments:
              search === null
                ? o.departments
                : filterDepartments(o.departments, search),
          })) ?? [],
      })),
      offices: offices?.map((o) => ({
        ...o,
        departments:
          search === null
            ? o.departments
            : filterDepartments(o.departments, search),
      })),
      departments:
        search === null ? departments : filterDepartments(departments, search),
    };
  }, [structureData.data, filter]);

  if (structureData.isLoading) {
    return <Loader color="blue" padding />;
  }

  if (companyStructure === null) {
    return null;
  }

  return (
    <>
      <CardsWrapper>
        <HeadOfficesList headOffices={companyStructure.headOffices} />
        <OfficesList offices={companyStructure.offices} />
        <div style={{ paddingTop: '15px' }}>
          <DepartmentsList departments={companyStructure.departments} />
        </div>
      </CardsWrapper>
    </>
  );
};

export default DepartmentSelectFromStructure;
