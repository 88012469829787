import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { breakpoints } from 'styles';

export const Container = styled.div`
  position: relative;
`;

export const LargeHeader = styled.header`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 16px;
  background-color: ${({ theme }) => theme.colors.backgroundLight};
  min-height: 80px;

  @media (max-width: ${breakpoints.l}px) {
    display: none;
  }
`;

export const LargeTitle = styled.div`
  @media (max-width: ${breakpoints.l}px) {
    display: none;
  }
`;

export const LargeTitleGrid = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonGrid = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const IconPadding = styled.div`
  padding: 0 16px;
`;

export const BackButton = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
`;

export const LogoLink = styled(Link)`
  color: ${({ theme }) => theme.colors.black};
  padding: 24px;
`;

export const Menu = styled.div`
  display: flex;
  justify-content: center;
  padding: 0;

  @media (max-width: ${breakpoints.l}px) {
    flex-direction: column;
    padding: 24px;
  }
`;

type MenuItemProps = {
  active?: string;
};

export const MenuLink = styled(Link) <MenuItemProps>`
  position: relative;
  margin: 0 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  line-height: 12px;
  white-space: nowrap;
  font-size: 12px;
  color: ${({ theme, active }) =>
    active ? theme.colors.blue : theme.colors.grayText};

  @media (max-width: ${breakpoints.xl}px) {
    margin: 0 12px;
  }

  @media (max-width: ${breakpoints.l}px) {
    flex-direction: row;
    font-size: 18px;
    padding: 12px 0;
    justify-content: flex-start;
    color: ${({ theme }) => theme.colors.white};
    width: 100%;
    gap: 16px;
  }
`;

export const MenuBorder = styled(motion.div)`
  position: absolute;
  background: ${({ theme }) => theme.colors.blue};
  width: 100%;
  height: 2px;
  bottom: 0;
`;

export const Nav = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 20px;
  @media (max-width: ${breakpoints.xl}px) {
    padding: 0 14px;
  }
`;

type SmallHeaderProps = {
  inverted?: boolean;
};

export const SmallHeader = styled.header<SmallHeaderProps>`
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 16px;
  min-height: 80px;

  ${({ inverted }) =>
    inverted &&
    css`
      color: ${({ theme }) => theme.colors.white};
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 99;
    `};

  @media (min-width: ${breakpoints.l + 1}px) {
    display: none;
  }
`;

type NavProps = {
  right?: boolean;
};

export const SmallNav = styled.div<NavProps>`
  display: flex;
  justify-content: ${({ right }) => (right ? 'flex-end' : 'flex-start')};
  align-items: center;
  margin-left: ${({ right }) => (right ? '0' : '16px')};

  h2 {
    margin: 0 0 5px;
  }
`;

type NavItemProps = {
  disabled?: boolean;
};

export const NavItem = styled.div<NavItemProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 24px 12px;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  @media (max-width: ${breakpoints.l}px) {
    padding: 24px 16px;
  }
`;

export const SmallLogo = styled.svg`
  margin-bottom: 5px;
`;

export const SmallContainer = styled.div`
  padding: 0 20px;
`;

export const SmallFooter = styled.ul`
  position: fixed;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  list-style-type: none;
  padding: 0;
  margin: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 64px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
`;

type SmallFooterItemProps = {
  borders?: boolean;
};

export const SmallFooterItem = styled.li<SmallFooterItemProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  line-height: 18px;
  gap: 8px;
  cursor: pointer;
  border-left: ${({ borders }) =>
    borders ? '1px solid rgba(255, 255, 255, 0.2)' : 'none'};
  border-right: ${({ borders }) =>
    borders ? '1px solid  rgba(255, 255, 255, 0.2)' : 'none'};
`;

export const LangItem = styled.div`
  display: flex;
  align-items: center;
`;

export const IconGrid = styled.div`
  display: flex;
  align-items: center;
`;

export const LangText = styled.span`
  margin-left: 8px;
`;

export const HeadingWithLogo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const CompanyLogo = styled.img`
  width: auto;
  height: 28px;
`;

export const Notification = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 100%;
  position: absolute;
  top: -1px;
  right: -1px;
  background-color: ${({ theme }) => theme.colors.error};
`;

export const MenuButton = styled.button`
  background-color: transparent;
  all: unset;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;
