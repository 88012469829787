import React, { FC, useEffect, Fragment, useMemo, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CSVLink } from "react-csv";

import { ContentWidth } from 'styles';
import { useAppDispatch } from 'hooks/redux';
import { setLayoutHeader, useGetCompanyStructureQuery, useResendInvitationMutation } from 'store';

import Heading, { Tag } from 'components/UI/Heading';
import Loader from 'components/UI/Loader';

import * as routes from 'router/routes';

import {
  BorderedList,
  ButtonContainer,
  CardContainer,
  CardDescription,
  CardHeading,
  CardList,
  SectionSpacing,
} from './styles';
import Icon, { IconType } from 'components/UI/Icon';
import ExpandableCard from 'components/UI/Cards/ExpandableCard';
import ClickableCard from 'components/UI/Cards/ClickableCard';
import { useNavigate } from 'react-router-dom';
import { usePretendCompany } from 'hooks/usePretendCompany';
import ListMenu from 'components/UI/ListMenu';
import UpdateHeadofficeModal from 'components/modals/UpdateHeadofficeModal';
import UpdateOfficeModal from 'components/modals/UpdateOfficeModal';
import toast from 'react-hot-toast';
import Button from 'components/UI/Button';
import { flattenData, getHeadOfficeMemberships, getOfficeMemberships } from 'utils/adminOrganisation';

type DepartmentCardProps = {
  id: string;
  description?: string | null;
  memberships?: any[] | null;
};
const DepartmentCard: FC<DepartmentCardProps> = ({
  id,
  description,
  memberships,
}) => {
  const navigate = useNavigate();

  const filteredCount = memberships?.filter(member => member.firstName && member.lastName && member.firstName.toLowerCase() !== 'anonymous').length ?? 0;
  const totalCount = memberships?.filter(member => member.firstName?.toLowerCase() !== 'anonymous').length ?? 0;

  return (
    <div>
      <ClickableCard
        onClick={() => navigate(`${routes.ADMIN_ORGANISATION}/${id}`)}
      >
        <CardContainer>
          <div>
            <CardHeading>{description}</CardHeading>
            <CardDescription>
              {totalCount > 0 &&
                <>
                  <span>
                    <FormattedMessage
                      id="coworkersActiveOf"
                      defaultMessage=" Active of "
                      description="Active coworkers of"
                      values={{ count: filteredCount }}
                    />
                  </span>

                  <span>{totalCount}</span>
                </>
              }
            </CardDescription>
          </div>
          <Icon type={IconType.Arrow} />
        </CardContainer>
      </ClickableCard>
    </div>
  );
};

const AdminDepartments: FC = () => {
  const intl = useIntl();
  const companyId = usePretendCompany();
  const dispatch = useAppDispatch();

  // Hooks
  const { data, isLoading } = useGetCompanyStructureQuery({ companyId });
  const navigate = useNavigate();
  const [resendInvitation, { isSuccess }] = useResendInvitationMutation();
  // State
  const [selectedHeadoffice, setSelectedHeadoffice] = React.useState<null | {
    id: string;
    description: string;
  }>(null);

  const [selectedOffice, setSelectedOffice] = React.useState<null | {
    id: string;
    description: string;
    headOffice: string | null;
  }>(null);

  // Actions
  const handleResendInvitation = useCallback(async () => {
    await resendInvitation({ companyId: companyId ?? '' });
  }, [companyId, resendInvitation]);

  useEffect(() => {
    if (isSuccess) {
      toast.success(
        intl.formatMessage({
          id: 'sendInvitationSuccess',
          defaultMessage: 'Department created successfully',
          description: 'Department create toast success message',
        })
      );
    }
  }, [isSuccess]);

  // Set header
  useEffect(() => {
    dispatch(
      setLayoutHeader({
        title: intl.formatMessage({
          id: 'pageAdminOrganisationTitle',
          defaultMessage: 'Organisation',
          description: 'Page title for admin organisation page',
        }),
        companyInHeader: true,
        inverted: false,
      })
    );
  }, [dispatch, intl]);

  const onCloseModal = useCallback(() => {
    setSelectedHeadoffice(null);
    setSelectedOffice(null);
  }, []);

  const headOfficesList = useMemo(() => {
    if (!data?.headOffices?.length) {
      return null;
    }

    return (
      <>
        <Heading tag={Tag.H3}>
          <FormattedMessage
            id="pageAdminHeadOfficesTitle"
            defaultMessage="Head offices"
            description="Section title for list of head offices"
          />
        </Heading>
        <CardList>
          {data.headOffices.map((headOffice) => (
            <ExpandableCard
              key={headOffice.id}
              id={headOffice.id}
              title={headOffice.description ?? ''}
              description={`${intl.formatMessage(
                {
                  id: 'numberOffices',
                  defaultMessage:
                    '{unitCount, plural, one {# office} other {# offices}}',
                  description: 'number of office(s)',
                },
                { unitCount: headOffice.offices?.length ?? 0 }
              )}
            ${getHeadOfficeMemberships(headOffice, false).length > 0 ? (
                  `& ${intl.formatMessage(
                    {
                      id: "coworkersActiveOf",
                      defaultMessage: " Active of ",
                      description: "Active coworkers of",
                    },
                    { count: getHeadOfficeMemberships(headOffice).length }
                  )} ${getHeadOfficeMemberships(headOffice, false).length}`
                ) : ''}`
              }
              onEdit={() =>
                setSelectedHeadoffice({
                  id: headOffice.id,
                  description: headOffice.description ?? '',
                })
              }
              hasChildren
            >
              <BorderedList>
                {headOffice.offices?.map((office) => (
                  <div key={office.id}>
                    <ExpandableCard
                      key={office.id}
                      id={`${headOffice.id}.${office.id}`}
                      title={office.description ?? ''}
                      description={intl.formatMessage(
                        {
                          id: 'numberDepartments',
                          defaultMessage:
                            '{unitCount, plural, one {# department} other {# departments}}',
                          description: 'number of department(s)',
                        },
                        { unitCount: office.departments?.length ?? 0 }
                      )}
                      onEdit={() =>
                        setSelectedOffice({
                          id: office.id,
                          description: office.description ?? '',
                          headOffice: headOffice.id,
                        })
                      }
                    >
                      <BorderedList>
                        {office.departments?.map(
                          ({ id, description, memberships }) => (
                            <DepartmentCard
                              key={id}
                              id={id}
                              description={description}
                              memberships={memberships}
                            />
                          )
                        )}
                      </BorderedList>
                    </ExpandableCard>
                  </div>
                ))}
              </BorderedList>
            </ExpandableCard>
          ))}
        </CardList>
        <UpdateHeadofficeModal
          isOpen={selectedHeadoffice != null}
          onClose={onCloseModal}
          headoffice={selectedHeadoffice}
        />
      </>
    );
  }, [data?.headOffices, intl, onCloseModal, selectedHeadoffice]);

  const officesList = useMemo(() => {
    if (!data?.offices?.length) {
      return null;
    }

    return (
      <>
        <Heading tag={Tag.H4}>
          <FormattedMessage
            id="pageAdminOfficesTitle"
            defaultMessage="Offices"
            description="Section title for list of offices"
          />
        </Heading>
        <CardList>
          {data.offices.map((office) => (
            <ExpandableCard
              key={office.id}
              id={office.id}
              title={office.description ?? ''}
              description={`${intl.formatMessage(
                {
                  id: 'numberDepartments',
                  defaultMessage:
                    '{unitCount, plural, one {# department} other {# departments}}',
                  description: 'number of department(s)',
                },
                { unitCount: office.departments?.length ?? 0 }
              )}
            ${getOfficeMemberships(office, false).length > 0 ? (
                  `& ${intl.formatMessage(
                    {
                      id: "coworkersActiveOf",
                      defaultMessage: " Active of ",
                      description: "Active coworkers of",
                    },
                    { count: getOfficeMemberships(office).length }
                  )} ${getOfficeMemberships(office, false).length}`
                ) : ''}`
              }
              onEdit={() =>
                setSelectedOffice({
                  id: office.id,
                  description: office.description ?? '',
                  headOffice: null,
                })
              }
            >
              <BorderedList>
                {office.departments?.map(({ id, description, memberships }) => (
                  <DepartmentCard
                    key={id}
                    id={id}
                    description={description}
                    memberships={memberships}
                  />
                ))}
              </BorderedList>
            </ExpandableCard>
          ))}
        </CardList>
      </>
    );
  }, [data?.offices, intl]);

  const departmentsList = useMemo(() => {
    if (!data?.departments?.length) {
      return null;
    }
    return (
      <>
        <Heading tag={Tag.H4}>
          <FormattedMessage
            id="pageAdminDepartmentsListTitle"
            defaultMessage="Departments"
            description="Section title for list of departments"
          />
        </Heading>
        <CardList>
          {data.departments.map(({ id, description, memberships }) => (
            <DepartmentCard
              key={id}
              id={id}
              description={description}
              memberships={memberships}
            />
          ))}
        </CardList>
      </>
    );
  }, [data?.departments]);

  const orphanMembers = useMemo(() => {
    if (!data?.memberships?.filter(member => member.firstName !== 'anonymous').length) {
      return null;
    }
    return (
      <>
        <Heading tag={Tag.H4}>
          <FormattedMessage
            id="pageAdminOrphanMembersSectionTitle"
            defaultMessage="Members"
            description="Section title for list of members"
          />
        </Heading>
        <CardList>
          <ExpandableCard
            id={'orphan-members'}
            title={intl.formatMessage({
              id: 'pageAdminOrphanMembersCardTitle',
              defaultMessage: 'Members without a department',
              description:
                'Card heading for expandable card with members without a department',
            })}
            description={intl.formatMessage(
              {
                id: 'numberMemberships',
                defaultMessage:
                  '{unitCount, plural, one {# member} other {# members}}',
                description: 'number of member(s)',
              },
              { unitCount: data.memberships?.filter(member => member.firstName?.toLowerCase() !== 'anonymous').length ?? 0 })} // TODO: This is a temporary fix to filter out anonymous users
          >
            <BorderedList>
              {data.memberships
                .filter(member => member.firstName?.toLowerCase() !== 'anonymous') // TODO: This is a temporary fix to filter out anonymous users
                .map((member) => (
                  <div key={member.userId}>
                    <ClickableCard
                      onClick={() =>
                        navigate(
                          `${routes.ADMIN_ORGANISATION}/user/${member.userId}`
                        )
                      }
                    >
                      <CardContainer>
                        <div>
                          <CardHeading>
                            {member.firstName} {member.lastName}
                          </CardHeading>
                          <CardDescription>{member.email}</CardDescription>
                        </div>
                        <Icon type={IconType.Arrow} />
                      </CardContainer>
                    </ClickableCard>
                  </div>
                ))}
            </BorderedList>
          </ExpandableCard>
        </CardList>
      </>
    );
  }, [data?.memberships, intl, navigate]);

  // Loading
  if (isLoading) {
    return <Loader color="blue" padding />;
  }

  // No data
  if (!data) {
    return null;
  }

  const flattendData = flattenData(data);

  return (
    <Fragment>
      <ContentWidth isSurface>
        <ListMenu
          menu={[
            {
              id: 1,
              text: intl.formatMessage({
                id: 'pageAdminHeadofficeAddButton',
                defaultMessage: 'Add new head office',
                description: 'Button to create a new head office',
              }),
              link: routes.ADMIN_ORGANISATION + '/create-headoffice',
            },
            {
              id: 2,
              text: intl.formatMessage({
                id: 'pageAdminOfficeAddButton',
                defaultMessage: 'Add new office',
                description: 'Button to create a new office',
              }),
              link: routes.ADMIN_ORGANISATION + '/create-office',
            },
            {
              id: 3,
              text: intl.formatMessage({
                id: 'pageAdminDepartmentAddButton',
                defaultMessage: 'Add new department',
                description: 'Button to create a new department',
              }),
              link: routes.ADMIN_ORGANISATION + '/create',
            }
          ]}
        />
        <ButtonContainer>
          <Button background="blue" onClick={handleResendInvitation}>
            <FormattedMessage
              id="pageAdminResendInvitationToUnRegisteredButton"
              defaultMessage="Resend invitation"
              description="Button to resend invitation"
            />
          </Button>
          <>
            <CSVLink data={flattendData} filename="organisation.csv">
              <Button background="blue" >
                <FormattedMessage
                  id="pageAdminPrintOrganisationInExcelButton"
                  defaultMessage="Print organisation in excel"
                  description="Button to resend invitation"
                />
              </Button>
            </CSVLink>
          </>
        </ButtonContainer>
        <SectionSpacing />
        {headOfficesList}
        {headOfficesList !== null && <SectionSpacing />}
        {officesList}
        {officesList !== null && <SectionSpacing />}
        {departmentsList}
        {departmentsList !== null && <SectionSpacing />}
        {orphanMembers}
      </ContentWidth>
      <UpdateOfficeModal
        isOpen={selectedOffice != null}
        onClose={onCloseModal}
        office={selectedOffice}
      />
    </Fragment>
  );
};

export default AdminDepartments;
