import { Favorite, FavoriteBody, FavoriteExists, FavoriteQuery } from 'models';
import { baseApi } from '../baseApi';

const favoriteApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getFavorites: builder.query<Favorite[], FavoriteQuery>({
      query: ({ language }) => `/favorite/me?language=${language}`,
      providesTags: ['Favorite'],
    }),
    checkFavoriteExists: builder.query<FavoriteExists, FavoriteQuery>({
      query: ({ type, reference }) =>
        `/favorite/me/exists/${type}/${reference}`,
    }),
    addFavorite: builder.mutation<void, FavoriteBody>({
      query: (body) => ({
        url: '/favorite/me',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Favorite'],
    }),
    deleteFavorite: builder.mutation<void, FavoriteBody>({
      query: (body) => ({
        url: '/favorite/me',
        method: 'DELETE',
        body,
      }),
      invalidatesTags: ['Favorite'],
    }),
  }),
});

export const {
  useCheckFavoriteExistsQuery,
  useGetFavoritesQuery,
  useAddFavoriteMutation,
  useDeleteFavoriteMutation,
} = favoriteApi;
