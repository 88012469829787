import { PulseHeader, RoundedCard } from './styles'; // Import the RoundedCard component
import Button from 'components/UI/Button';
import Icon, { IconType } from 'components/UI/Icon';
import RadioButton from 'components/UI/RadioButton';
import SelectField from 'components/UI/SelectField';
import TextField from 'components/UI/TextField';
import { useAppSelector } from 'hooks/redux';
import { useLocalizedText } from 'hooks/useLocalizedText';
import useOrganizationEntityOptions, {
  EntityType,
} from 'hooks/useOrganizationEntityOptions';
import { usePageHeader } from 'hooks/usePageHeader';
import { usePretendCompany } from 'hooks/usePretendCompany';
import { useUserCompanyId } from 'hooks/useUserCompanyId';
import { FC, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import {
  localizationSelector,
  useGetStartablePulseQuizesQuery,
  useStartPulseQuizMutation,
} from 'store';
import { ContentWidth, FlexContainer, ActionContainer } from 'styles';
import { dateStringToISOString } from 'utils/date';

type StartPulseQuizForm = Parameters<
  ReturnType<typeof useStartPulseQuizMutation>[0]
>[0] & { entityType: EntityType; customDescription: boolean };

const AdminPulseTestCreate: FC = () => {
  const intl = useIntl();
  const getText = useLocalizedText();
  const pretendCompanyId = usePretendCompany();
  const userCompanyId = useUserCompanyId();
  const navigate = useNavigate();
  usePageHeader({
    title: intl.formatMessage({
      id: 'backButton',
      defaultMessage: 'Back',
      description: 'Back button text',
    }),
    icon: IconType.Back,
  });
  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<StartPulseQuizForm>({
    defaultValues: {
      displayAssessmentOnSubmit: false,
      customDescription: false,
      entityType: EntityType.Company,
    },
  });

  const entityType = watch('entityType');
  const organizationEntityOptions =
    useOrganizationEntityOptions(pretendCompanyId);
  const entityOptions = useMemo(() => {
    switch (entityType) {
      case EntityType.HeadOffice:
        return organizationEntityOptions.headOffices;
      case EntityType.Office:
        return organizationEntityOptions.offices;
      case EntityType.Department:
        return organizationEntityOptions.departments;
      default:
        return null;
    }
  }, [entityType, organizationEntityOptions]);

  useEffect(() => {
    setValue('organizationEntityId', undefined);
  }, [entityType, setValue]);

  const [customDescription, displayAssessmentOnSubmit] = watch([
    'customDescription',
    'displayAssessmentOnSubmit',
  ]);

  const { data } = useGetStartablePulseQuizesQuery();
  const [startPulseQuiz] = useStartPulseQuizMutation();

  const pulseQuizOptions = useMemo(() => {
    return (
      data?.map((quiz) => ({
        name: getText(quiz.title),
        value: quiz.slug.current ?? '-',
        summary: getText(quiz.summary),
        questions: quiz.questions.map((question) => getText(question.title)),
      })) || []
    );
  }, [data, getText]);

  // Watch the slug field
  const selectedSlug = watch('slug');

  // Find the selected pulse test
  const selectedPulseTest = useMemo(() => {
    return pulseQuizOptions.find((quiz) => quiz.value === selectedSlug);
  }, [pulseQuizOptions, selectedSlug]);


  const entityTypeOptions = useMemo(() => {
    return [
      {
        name: intl.formatMessage({
          id: 'everyoneInCompanyLabel',
          defaultMessage: 'Everyone in the company',
          description: 'Label for everyone option',
        }),
        value: EntityType.Company,
      },
      {
        name: intl.formatMessage({
          id: 'everyoneInHeadOfficeLabel',
          defaultMessage: 'Everyone within a specific head office',
          description: 'Label for everyone in head office option',
        }),
        value: EntityType.HeadOffice,
      },
      {
        name: intl.formatMessage({
          id: 'everyoneInOfficeLabel',
          defaultMessage: 'Everyone within a specific office',
          description: 'Label for everyone in office option',
        }),
        value: EntityType.Office,
      },
      {
        name: intl.formatMessage({
          id: 'everyoneInDepartmentLabel',
          defaultMessage: 'Everyone within a specific department',
          description: 'Label for everyone in department option',
        }),
        value: EntityType.Department,
      },
    ];
  }, [intl]);

  const { languages } = useAppSelector(localizationSelector);

  const onSubmit = async (data: StartPulseQuizForm) => {
    const companyId =
      pretendCompanyId == null ? userCompanyId : pretendCompanyId;
    await startPulseQuiz({
      slug: data.slug,
      greetingSlug: undefined,
      description: data.description,
      companyId,
      organizationEntityId:
        entityType === EntityType.Company
          ? companyId
          : data.organizationEntityId,
      startDate: dateStringToISOString(data.startDate as string),
      endDate: dateStringToISOString(data.endDate as string),
      displayAssessmentOnSubmit: data.displayAssessmentOnSubmit,
    }).unwrap();

    toast.success(
      intl.formatMessage({
        id: 'createPulseQuizSuccess',
        defaultMessage: 'Pulse quiz created successfully',
        description: 'Pulse quiz create toast success message',
      })
    );

    navigate(-1);
  };

  return (
    <ContentWidth isSurface>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SelectField
          label={intl.formatMessage({
            id: 'pulseQuizLabel',
            defaultMessage: 'Pulse test',
            description: 'Label for the pulse quiz select field',
          })}
          placeholder={intl.formatMessage({
            id: 'pulseQuizPlaceholder',
            defaultMessage: 'Select pulse test',
            description: 'Placeholder for the pulse quiz select field',
          })}
          error={errors.slug}
          options={pulseQuizOptions}
          register={register('slug', {
            required: intl.formatMessage({
              id: 'inputErrorPulseQuizRequired',
              defaultMessage: 'You need to select a pulse test',
              description: 'Input error for pulse quiz required',
            }),
          })}
        />
        <br />
        <SelectField
          label={intl.formatMessage({
            id: 'organizationEntityTypeLabel',
            defaultMessage: 'Test recipients',
            description: 'Label for the organization entity select field',
          })}
          options={entityTypeOptions}
          register={register('entityType')}
        />
        {entityOptions != null && (
          <>
            <br />
            <SelectField
              label={intl.formatMessage({
                id: 'organizationEntityOptionLabel',
                defaultMessage: 'Test recipients entity',
                description:
                  'Label for the organization entity option select field',
              })}
              placeholder={intl.formatMessage({
                id: 'organizationEntityOptionPlaceholder',
                defaultMessage: 'Select entity',
                description:
                  'Placeholder for the organization entity option select field',
              })}
              options={entityOptions}
              register={register('organizationEntityId')}
            />
          </>
        )}
        <br />
        <FlexContainer
          gap={8}
          alignItems={'center'}
          onClick={() =>
            setValue('displayAssessmentOnSubmit', !displayAssessmentOnSubmit)
          }
        >
          <RadioButton
            name="display-assessment-on-submit"
            isChecked={displayAssessmentOnSubmit === true}
          />
          <span>
            <FormattedMessage
              id="inputRadioButtonDisplayAssessmentLabel"
              defaultMessage="Display assessment on submit"
              description="Radio button label enabling/disabling display assessment on submit"
            />
          </span>
        </FlexContainer>
        <br />
        <FlexContainer
          gap={8}
          alignItems={'center'}
          onClick={() => setValue('customDescription', !customDescription)}
        >
          <RadioButton
            name="custom-description"
            isChecked={customDescription}
          />
          <span>
            <FormattedMessage
              id="inputRadioButtonSetCustomDescriptionLabel"
              defaultMessage="Set a custom description"
              description="Radio button label enabling/disabling setting custom description"
            />
          </span>
        </FlexContainer>
        {customDescription ? (
          <>
            <br />
            {languages.map((language, index) => (
              <TextField
                key={language.languageCode}
                label={
                  index === 0
                    ? intl.formatMessage({
                      id: 'inputPulseQuizDescriptionLabel',
                      defaultMessage: 'Description',
                      description: 'Label for pulse quiz description input',
                    })
                    : undefined
                }
                placeholder={intl.formatMessage({
                  id: 'inputPulseQuizDescriptionPlaceholder',
                  defaultMessage: 'Localized description',
                  description: 'Placeholder for pulse quiz description input',
                })}
                type="text"
                error={errors.description}
                fieldCountryCode={language.countryCode}
                register={register(`description.${language.languageCode}`)}
              />
            ))}
          </>
        ) : (
          <br />
        )}
        <TextField
          label={intl.formatMessage({
            id: 'inputStartDateLabel',
            defaultMessage: 'Start date',
            description: 'Label for start date input',
          })}
          placeholder={intl.formatMessage({
            id: 'inputStartDatePlaceholder',
            defaultMessage: 'Enter start date',
            description: 'Placeholder for start date input',
          })}
          type="datetime-local"
          error={errors.startDate}
          register={register('startDate', {
            required: intl.formatMessage({
              id: 'inputErrorStartDateRequired',
              defaultMessage: 'Start date is required',
              description: 'Input error for start date required',
            }),
          })}
        />
        <TextField
          label={intl.formatMessage({
            id: 'inputEndDateLabel',
            defaultMessage: 'End date',
            description: 'Label for end date input',
          })}
          placeholder={intl.formatMessage({
            id: 'inputEndDatePlaceholder',
            defaultMessage: 'Enter end date',
            description: 'Placeholder for end date input',
          })}
          type="datetime-local"
          error={errors.endDate}
          register={register('endDate', {
            required: intl.formatMessage({
              id: 'inputErrorEndDateRequired',
              defaultMessage: 'End date is required',
              description: 'Input error for end date required',
            }),
          })}
        />
        <ActionContainer>
          <Button type="submit" background="blue">
            <FormattedMessage
              id="startPulseQuizButton"
              defaultMessage="Start pulse test"
              description="Start pulse quiz button text"
            />
          </Button>
        </ActionContainer>
      </form>

      {selectedPulseTest && (
        <RoundedCard>
          <PulseHeader>
            <div style={{ paddingRight: '0.3rem' }}>
              <Icon type={IconType.Pulse} />
            </div>
            <h3>{selectedPulseTest.name}</h3>

          </PulseHeader>
          <p style={{ padding: '0px', margin: '0' }}>{selectedPulseTest.summary}</p>
          <div>
            <h4>{intl.formatMessage({
              id: 'questionsHeader',
              defaultMessage: 'Questions',
              description: 'Header for the questions list',
            })}</h4>
            <ul style={{ paddingLeft: '20px' }}>
              {selectedPulseTest.questions.map((question, index) => (
                <li key={index} style={{ marginBottom: '10px' }}>
                  {question}
                </li>
              ))}
            </ul>
          </div>
        </RoundedCard>
      )}
    </ContentWidth>

  );
};

export default AdminPulseTestCreate;
