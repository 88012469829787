import Checkbox from 'components/UI/Checkbox';
import TextField from 'components/UI/TextField';
import {
  SelectedDepartmentContext,
  useDepartmentState,
} from 'context/useSelectedDepartmentContext';
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { FlexContainer } from 'styles';
import DepartmentSelectFromStructure from '../DepartmentSelectFromStructure';

type Props = {
  onChange: (departmentId: string | null) => void;
  companyId: string;
};

type DepartmentSelectForm = {
  enableDepartments: boolean;
  departmentSearch: string;
};

const DepartmentSelect: FC<Props> = ({ onChange, companyId }) => {
  const intl = useIntl();
  const { selectedDepartment, toggleSelectDepartment } = useDepartmentState();

  const { register, watch, setValue } = useForm<DepartmentSelectForm>({
    defaultValues: { departmentSearch: '', enableDepartments: false },
  });

  const departmentSearch = watch('departmentSearch');
  const enableDepartments = watch('enableDepartments');

  useEffect(() => {
    if (!enableDepartments || selectedDepartment == null) {
      onChange(null);
      return;
    }
    onChange(selectedDepartment);
  }, [enableDepartments, selectedDepartment, onChange]);

  return (
    <>
      <FlexContainer
        gap={8}
        alignItems="center"
        onClick={() => setValue('enableDepartments', !enableDepartments)}
      >
        <Checkbox name="enableDepartments" isChecked={enableDepartments} />
        <FormattedMessage
          id="inviteToSpecificDepartment"
          defaultMessage="Invite to a specific department"
          description="Invite to a specific department"
        />
      </FlexContainer>
      <SelectedDepartmentContext.Provider
        value={{
          selected: selectedDepartment,
          onToggle: toggleSelectDepartment,
        }}
      >
        {enableDepartments && (
          <>
            <br />
            <TextField
              label={intl.formatMessage({
                id: 'inputFilterDepartmentsLabel',
                defaultMessage: 'Search departments',
                description: 'Label for filter departments input',
              })}
              placeholder={intl.formatMessage({
                id: 'inputFilterDepartmentsPlaceholder',
                defaultMessage: 'Search for department name',
                description: 'Placeholder for filter departments input',
              })}
              register={register('departmentSearch')}
              blurOnEnter
            />
            <DepartmentSelectFromStructure
              filter={departmentSearch}
              companyId={companyId}
            />
          </>
        )}
      </SelectedDepartmentContext.Provider>
    </>
  );
};

export default DepartmentSelect;
