import React, { FC, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-hot-toast';

import {
  useAddContestUserMutation,
  useGetAddableContestUsersQuery,
} from 'store';

import Loader from 'components/UI/Loader/Loader';
import Icon, { IconType } from 'components/UI/Icon';
import IconButton from 'components/UI/IconButton';
import Heading, { Tag } from 'components/UI/Heading';
import SlideoutModal from 'components/UI/SlideoutModal';

import { Header, Body } from './styles';
import { useForm } from 'react-hook-form';
import SelectField from 'components/UI/SelectField';
import { SelectOption } from 'models';
import { ActionContainer } from 'styles';
import Button from 'components/UI/Button';

type AddCompetitionUserForm = {
  userId?: string;
  teamId?: string;
  forceAccept?: boolean;
};

type Props = {
  contestId: string;
  teams: SelectOption[];
  isOpen: boolean;
  onClose: () => void;
};

const AddCompetitionParticipantModal: FC<Props> = ({
  contestId,
  teams,
  isOpen,
  onClose,
}) => {
  const intl = useIntl();
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm<AddCompetitionUserForm>();

  const [addContestUser, addContestUserResult] = useAddContestUserMutation();
  const { data: addableUsers, isLoading: addableUsersIsLoading } =
    useGetAddableContestUsersQuery(contestId, { skip: !isOpen });

  const addableUserOptions: SelectOption[] = useMemo(() => {
    if (!addableUsers) {
      return [];
    }
    return addableUsers.map((user) => ({
      name: user.email ?? '',
      value: user.membershipId,
    }));
  }, [addableUsers]);

  const selectedUserId = watch('userId');

  useEffect(() => {
    if (addContestUserResult.isSuccess) {
      toast.success(
        intl.formatMessage({
          id: 'AddContestUserModalSuccess',
          defaultMessage: 'User has been added to the contest',
          description: 'User add contest success message',
        })
      );
      onClose();
    }
  }, [addContestUserResult.isSuccess, intl, onClose, setValue]);

  // Content
  const content = useMemo(() => {
    if (
      !addableUsers ||
      addContestUserResult.isLoading ||
      addableUsersIsLoading
    ) {
      return <Loader padding color="blue" />;
    }
    if (addableUsers?.length === 0) {
      return (
        <FormattedMessage
          id="addContestUserModalNoUsers"
          defaultMessage="No more users can be added to this contest"
          description="No addable users available message"
        />
      );
    }
    const buttonEnabled = selectedUserId != null && selectedUserId !== '';
    return (
      <form
        onSubmit={handleSubmit((form) =>
          addContestUser({
            contestId,
            membershipId: form.userId as string,
            teamId: form.teamId === '' ? undefined : form.teamId,
          })
        )}
      >
        <SelectField
          label={intl.formatMessage({
            id: 'inputContestUserLabel',
            defaultMessage: 'User',
            description: 'Label for contest user input',
          })}
          placeholder={intl.formatMessage({
            id: 'inputSelectUserPlaceholder',
            defaultMessage: 'Select user',
            description: 'Placeholder for user select input',
          })}
          register={register('userId')}
          options={addableUserOptions}
          error={errors.userId}
          required
        />
        <br />
        <SelectField
          label={intl.formatMessage({
            id: 'inputTeamLabel',
            defaultMessage: 'Team',
            description: 'Label for team input',
          })}
          register={register('teamId')}
          placeholder={intl.formatMessage({
            id: 'inputTeamPlaceholder',
            defaultMessage: 'No team',
            description: 'Placeholder for team input',
          })}
          options={teams}
          error={errors.teamId}
        />
        <ActionContainer>
          <Button type="submit" background="blue" disabled={!buttonEnabled}>
            <FormattedMessage
              id="addButton"
              defaultMessage="Add"
              description="Add button text"
            />
          </Button>
        </ActionContainer>
      </form>
    );
  }, [
    addableUsers,
    addContestUserResult.isLoading,
    addableUsersIsLoading,
    selectedUserId,
    handleSubmit,
    intl,
    register,
    addableUserOptions,
    errors.userId,
    errors.teamId,
    teams,
    addContestUser,
    contestId,
  ]);

  const onModalClose = () => {
    onClose();
    reset();
  };

  return (
    <SlideoutModal isOpen={isOpen} onClose={onModalClose}>
      <div>
        <Header>
          <Heading tag={Tag.H4}>
            <FormattedMessage
              id="addContestUserModalTitle"
              defaultMessage="Add user to contest"
              description="Add contest user modal title"
            />
          </Heading>
          <IconButton onClick={onClose} padding>
            <Icon type={IconType.Close} />
          </IconButton>
        </Header>
        <Body>{content}</Body>
      </div>
    </SlideoutModal>
  );
};

export default AddCompetitionParticipantModal;
