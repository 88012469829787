import { FC } from 'react';

import Heading, { Tag } from 'components/UI/Heading';
import Icon, { IconType } from 'components/UI/Icon';
import ClickableCard from 'components/UI/Cards/ClickableCard';

import {
  Grid,
  Column,
  Description,
  Img,
  Container,
  ImageContainer,
  IconContainer,
  Byline,
  Wrapper,
} from './styles';
import { useGetHealthQuizInfoContentQuery } from 'store';
import { useActiveLanguage } from 'hooks/useActiveLanguage';
import { getAssetUrl } from 'utils/asset';

type Props = {
  referenceFromQuiz: {
    healthQuizReferenceType: 'article' | 'external';
    slug: string;
  };
};

const QuizReferenceCard: FC<Props> = ({
  referenceFromQuiz: { healthQuizReferenceType, slug },
}) => {
  const { languageCode } = useActiveLanguage();

  const { data } = useGetHealthQuizInfoContentQuery({
    slug,
    language: languageCode,
    type: healthQuizReferenceType,
  });

  const image = data?.image ? getAssetUrl(data.image) : null;
  const byline = null;

  return (
    <Wrapper>
      <ClickableCard
        inverted
        onClick={(e) => {
          e.stopPropagation();
          if (data?.externalLink != null) {
            window.open(data.externalLink);
          }
        }}
      >
        <Grid>
          <Container>
            {image && (
              <ImageContainer filter={0}>
                <Img src={image} />
              </ImageContainer>
            )}
            <Column>
              <Heading tag={Tag.H5}>{data?.title}</Heading>
              <Byline>{byline && <Description>{byline}</Description>}</Byline>
            </Column>
          </Container>
          <IconContainer>
            <Icon type={IconType.ExternalLink} />
          </IconContainer>
        </Grid>
      </ClickableCard>
    </Wrapper>
  );
};

export default QuizReferenceCard;
