import styled from 'styled-components';

export const Overlay = styled.div<{ open: boolean }>`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: ${({ theme }) => theme.colors.black};
  opacity: ${({ open }) => (open ? 0.5 : 0)};
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  transition: opacity 0.15s ease-in-out;
  z-index: 100;
`;

export const Dialog = styled.dialog`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  border-radius: 16px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-width: 0;
  text-align: center;
  padding: 0;
  z-index: 110;
`;

export const DialogBody = styled.div`
  padding: 16px;
`;

export const DialogButtonContainer = styled.div`
  display: flex;
  border-top: 1px solid ${({ theme }) => theme.colors.borderLight};
`;

export const DialogButtonDivider = styled.div`
  width: 1px;
  background: ${({ theme }) => theme.colors.borderLight};
`;
