import { FC, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate } from 'react-router-dom';

import { useRegisterUserInviteMutation } from 'store';
import * as routes from 'router/routes';

import Heading from 'components/UI/Heading';
import BackgroundCover from 'components/UI/BackgroundCover';
import RegisterInviteForm from 'components/forms/RegisterInviteForm';
import CoverHeader from 'components/UI/CoverHeader';
import Loader from 'components/UI/Loader';

import { CoverContent, Description } from 'styles';

const RegisterInvite: FC = () => {
  // Hooks
  const [registerUserInvite, { isLoading, isSuccess }] =
    useRegisterUserInviteMutation();

  // Content
  const content = useMemo(() => {
    if (isLoading) {
      return <Loader padding />;
    }
    return <RegisterInviteForm submitForm={registerUserInvite} />;
  }, [registerUserInvite, isLoading]);

  // Success
  if (isSuccess) {
    return <Navigate to={routes.REGISTER_INVITE_SUCCESS} />;
  }

  return (
    <BackgroundCover padding>
      <CoverHeader subLevel />
      <CoverContent>
        <Heading>
          <FormattedMessage
            id="registerInviteTitle"
            defaultMessage="Sign up for Wellr"
            description="Register invite page title"
          />
        </Heading>
        <Description>
          <FormattedMessage
            id="registerInviteDescription"
            defaultMessage="Here you can sign up for Wellr. Enter your work email address (if you have one) and the invitation code you received from your employer."
            description="Register invite page description"
          />
        </Description>
        {content}
      </CoverContent>
    </BackgroundCover>
  );
};

export default RegisterInvite;
