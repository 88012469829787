import styled from 'styled-components';
import { breakpoints } from 'styles';


type CategoryProps = {
  color: string;
  isDisabled: boolean;
};

export const CategoryCircle = styled.div<CategoryProps>`
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.borderLight};
  border-radius: 100%;

  ${({ color }) => {
    if (color) {
      return `
        background-color: ${color};
        border: 1px solid ${color};
      `;
    }
  }}
`;

export const HeadingTitle = styled.h5`
  margin: 0;
  font-size: 16px;
  font-weight: bold;
`;

export const DateSubTitle = styled.span`
  color: ${({ theme }) => theme.colors.grayText};
  font-size: 12px;
`;

export const HeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0 8px;
`;

export const HealthContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HealthCategory = styled.div`
  gap: 8px;
`;

export const CategoryHeader = styled.div`
  display: flex;

  gap: 8px;
  padding: 0 0 8px;
  p {
    color: ${({ theme }) => theme.colors.white};
    font-weight: bold;
    margin: 0 0 8px;
  }
`;

export const HealthContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

export const CategoryContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
`;

export const TwoColGridReport = styled.div`
display: grid;
grid-template-columns: repeat(2, 1fr);

gap: 16px;

@media (max-width: ${breakpoints.m}px) {
  grid-template-columns: repeat(1, 1fr);
  }
`;

export const Grid = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DisabledTitle = styled.span`
  color: ${({ theme }) => theme.colors.grayText};
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
`;

export const Description = styled.span`
  color: ${({ theme }) => theme.colors.grayText};
  font-size: 12px;
`;


export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 72px;
  padding: 8px 0 8px 16px;

  h5 {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;


export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 8px;
`;

type ImageProps = {
  filter?: number;
};

export const ImageContainer = styled.div<ImageProps>`
  position: relative;
  width: 72px;
  height: 72px;
  min-width: 72px;

  ${({ theme, filter }) => {
    if (filter) {
      return `
        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          mix-blend-mode: multiply;
          background: ${theme.colors.black};
          opacity: 0.4;
        }
      `;
    }
  }};
`;

export const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;


