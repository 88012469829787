import { GendersInQuizStatistics, UserGender } from 'models';
import { useMemo } from 'react';
import { IntlShape, useIntl } from 'react-intl';

type GenderOptionsForStatistics = Array<{
  name: string;
  value: GendersInQuizStatistics;
}>;

type UserGenderOptions = Array<{
  name: string;
  value: UserGender;
}>;

const getGenderTranslation = (gender: UserGender, intl: IntlShape): string => {
  switch (gender) {
    case UserGender.Unknown:
      return intl.formatMessage({
        id: 'genderSelectLabelUnknown',
        defaultMessage: 'Unknown',
        description: 'Select label for unknown gender',
      });
    case UserGender.Male:
      return intl.formatMessage({
        id: 'genderSelectLabelMale',
        defaultMessage: 'Male',
        description: 'Select label for male gender',
      });
    case UserGender.Female:
      return intl.formatMessage({
        id: 'genderSelectLabelFemale',
        defaultMessage: 'Female',
        description: 'Select label for female gender',
      });
    case UserGender.NonBinary:
      return intl.formatMessage({
        id: 'genderSelectLabelNonBinary',
        defaultMessage: 'Non-binary',
        description: 'Select label for non-binary gender',
      });
  }
};

export const useGenderOptionsForStatistics = (): GenderOptionsForStatistics => {
  const intl = useIntl();
  return useMemo(
    () => [
      {
        name: intl.formatMessage({
          id: 'genderSelectLabelAll',
          defaultMessage: 'All',
          description: 'Select label for all genders',
        }),
        value: GendersInQuizStatistics.All,
      },
      {
        name: getGenderTranslation(UserGender.Unknown, intl),
        value: GendersInQuizStatistics.Unknown,
      },
      {
        name: getGenderTranslation(UserGender.Male, intl),
        value: GendersInQuizStatistics.Male,
      },
      {
        name: getGenderTranslation(UserGender.Female, intl),
        value: GendersInQuizStatistics.Female,
      },
      {
        name: getGenderTranslation(UserGender.NonBinary, intl),
        value: GendersInQuizStatistics.NonBinary,
      },
    ],
    [intl]
  );
};

export const useUserGenders = (): UserGenderOptions => {
  const intl = useIntl();
  return useMemo(
    () =>
      Object.values(UserGender).map((value) => ({
        name: getGenderTranslation(value, intl),
        value,
      })),
    [intl]
  );
};
