import React, { FC, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-hot-toast';


import Loader from 'components/UI/Loader/Loader';
import Icon, { IconType } from 'components/UI/Icon';
import IconButton from 'components/UI/IconButton';
import Heading, { Tag } from 'components/UI/Heading';
import SlideoutModal from 'components/UI/SlideoutModal';

import { Header, Body } from './styles';
import { useCreateUserAccessRightsMutation } from 'store/accessRightsService/accessRightsService';
import UpdateUserUmbrellaAdminForm from 'components/forms/UpdateUserUmbrellaAdminForm';

type Props = {
  membershipId: string;
  isOpen: boolean;
  umbrellaCompanyId: string;
  onClose: () => void;

};

const UpdateUserUmbrellaAdminModal: FC<Props> = ({
  membershipId,
  isOpen,
  onClose,
  umbrellaCompanyId,
}) => {
  const intl = useIntl();

  const [createdUserAccesssRights, createdUserAccesssRightsResult] = useCreateUserAccessRightsMutation();

  useEffect(() => {
    if (createdUserAccesssRightsResult.isSuccess) {
      toast.success(
        intl.formatMessage({
          id: 'updateUserModalSuccess',
          defaultMessage: 'User update successful',
          description: 'User update success message',
        })
      );
      onClose();
    }
  }, [createdUserAccesssRightsResult, intl, onClose]);

  const content = useMemo(() => {
    if (createdUserAccesssRightsResult.isLoading) {
      return <Loader padding color="blue" />;
    }
    return (
      <UpdateUserUmbrellaAdminForm
        umbrellaCompanyId={umbrellaCompanyId}
        submitForm={(data) => {
          createdUserAccesssRights({
            requestAccessRight: 4, // 1 for department, 2 for office, 3 for head office
            membershipId: membershipId,
            organizationIds: data.umbrellaId.length > 0 ? [data.umbrellaId.toString()] : [],
          })
        }}
      />
    );
  }, [membershipId, createdUserAccesssRights, createdUserAccesssRightsResult.isLoading]);

  return (
    <SlideoutModal isOpen={isOpen} onClose={onClose}>
      <div>
        <Header>
          <Heading tag={Tag.H3}>
            <FormattedMessage
              id="manageUserUmbrellaModalAdminTitle"
              defaultMessage="Choose admin rights for umbrella"
              description="Change admin rights for head umbrella admin modal title"
            />
          </Heading>
          <IconButton onClick={onClose} padding>
            <Icon type={IconType.Close} />
          </IconButton>
        </Header>
        <Body>{content}</Body>
      </div>
    </SlideoutModal>
  );
};

export default UpdateUserUmbrellaAdminModal;
