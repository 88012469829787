import styled from 'styled-components';
import { breakpoints } from 'styles';

const cornerOffset = 42;

type CategoryProps = {
  isActive: boolean;
  isDisabled: boolean;
};

export const CategoryCircle = styled.div<CategoryProps>`
  position: absolute;
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.borderLight};
  border-radius: 50%;
  cursor: pointer;

  ${({ isActive, isDisabled, theme }) => {
    if (isDisabled) {
      return `
        opacity: 0.5;
        pointer-events: none;
      `;
    }
    if (isActive) {
      return `
        background-color: ${theme.colors.blue};
        border: 1px solid ${theme.colors.blue};
      `;
    }
  }}

  &:nth-child(7) {
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  &:nth-child(8) {
    top: ${cornerOffset}px;
    right: ${cornerOffset}px;
  }
  &:nth-child(9) {
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
  }
  &:nth-child(2) {
    bottom: ${cornerOffset}px;
    right: ${cornerOffset}px;
  }
  &:nth-child(3) {
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  &:nth-child(4) {
    bottom: ${cornerOffset}px;
    left: ${cornerOffset}px;
  }
  &:nth-child(5) {
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }
  &:nth-child(6) {
    top: ${cornerOffset}px;
    left: ${cornerOffset}px;
  }
`;

export const ChartGrid = styled.div`
display: grid;
grid-template-columns: repeat(2, 1fr);
margin-bottom: 24px;

@media (max-width: ${breakpoints.m}px) {
  grid-template-columns: repeat(1, 1fr);
}
`;
// export const ChartGrid = styled.div`
//   display:flex;
//   justify-content: center;
//   margin-bottom: 24px;
// `;

export const ChartContainer = styled.div`
  position: relative;
  padding: 24px;
  order: 1;

  @media (min-width: ${breakpoints.m + 1}px) {
    order: 2;
  }
`;
// export const ChartContainer = styled.div`
//   position: relative;
//   padding: 24px;
// `;

export const CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 24px;
  order: 2;

  @media (min-width: ${breakpoints.m + 1}px) {
    order: 1;
    margin-bottom: 24px;
  }
`;

export const Category = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const CategoryTitle = styled.span`
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
`;

export const AssessmentGrid = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: center;

  @media (max-width: ${breakpoints.m}px) {
    align-items: center;

    button {
      width: 100%;
    }
  }
`;
