import styled from 'styled-components';
import { breakpoints } from 'styles';

export const ProfileHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
  width: 100%;

  @media (max-width: ${breakpoints.s}px) {
    flex-direction: column;
    gap: 16px;
  }

  h1 {
    margin: 0;
  }
`;

export const ProfileHeaderData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
  padding: 8px 0;

  @media (max-width: ${breakpoints.s}px) {
    padding: 0;
    align-items: center;
    gap: 16px;
  }

  h1 {
    font-size: 22px;
  }
`;

export const ProfileHeaderDataGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  text-align: left;

  @media (max-width: ${breakpoints.s}px) {
    text-align: center;
  }
`;

  export const ProfileImage = styled.div` 
  position: relative;
  width: 120px;
  height: 120px;
  min-height: 120px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.backgroundLight};
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${breakpoints.s}px) {
    width: 64px;
    height: 64px;
    min-height: 64px;
  }
  `;
export const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
