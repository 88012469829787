import React, { FC, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import Button from 'components/UI/Button/Button';
import TextField from 'components/UI/TextField/TextField';

import { ActionContainer } from 'styles';

type Props = {
  submitForm: (data: string) => void;
};

const CreateTeamForm: FC<Props> = ({ submitForm }) => {
  const intl = useIntl();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // Submit
  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    ({ teamName }) => submitForm(teamName),
    [submitForm]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        label={intl.formatMessage({
          id: 'inputTeamNameLabel',
          defaultMessage: 'Team name',
          description: 'Label for team name input',
        })}
        placeholder={intl.formatMessage({
          id: 'inputTeamNamePlaceholder',
          defaultMessage: 'Enter team name',
          description: 'Placeholder for team name input',
        })}
        error={errors.teamName}
        register={register('teamName', {
          required: intl.formatMessage({
            id: 'inputErrorTeamNameRequired',
            defaultMessage: 'An team name is required',
            description: 'Input error for team name required',
          }),
          maxLength: {
            value: 35,
            message: intl.formatMessage({
              id: 'inputErrorTeamNameMaxLength',
              defaultMessage: 'A team name cannot be more than 35 characters',
              description: 'Input error for team name max length',
            }),
          },
        })}
      />
      <ActionContainer>
        <Button type="submit" background="blue">
          <FormattedMessage
            id="createButton"
            defaultMessage="Create"
            description="Create button text"
          />
        </Button>
      </ActionContainer>
    </form>
  );
};

export default CreateTeamForm;
