import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Step = styled.div`
  display: flex;
  width: 20px;
  height: 20px;

  img {
    padding: 4px;
  }
`;

type Props = {
  inverted: boolean;
};

export const Text = styled.span<Props>`
  color: ${({ theme, inverted }) =>
    theme.colors[inverted ? 'white' : 'grayText']};
  font-size: 12px;

  margin-right: 0px;
`;
