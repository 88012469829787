import styled from 'styled-components';
import { ThemeColor } from 'styles';

type CardProps = {
  background: ThemeColor;
};

export const Card = styled.div<CardProps>`
  position: relative;
  overflow: hidden;
  background: ${({ background, theme }) => theme.colors[background]};
  border-radius: 16px;
  font-size: 16px;
  padding: 24px;
`;

export const Content = styled.div`
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 200px;
  color: ${({ theme }) => theme.colors.white};
`;

export const ContentHeader = styled.div`
  h4 {
    margin: 0;
  }
`;

export const ContentBody = styled.div`
  display: flex;
  flex-direction: column;

  h2 {
    font-size: 48px;
    margin: 0 0 8px;
  }

  p {
    margin: 0;
  }
`;

export const BackgroundIcon = styled.div`
  position: absolute;
  top: 24px;
  right: -24px;
  bottom: 24px;

  svg {
    width: 100%;
    height: 100%;
  }
`;
