import styled from 'styled-components';
import { breakpoints, ThemeColor } from 'styles';

type Props = {
  background: ThemeColor;
};

export const Container = styled.div<Props>`
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  background: ${({ background, theme }) => theme.colors[background]};
  max-width: 680px;
  max-height: 256px;
  margin: 0 auto 48px;

  @media (max-width: ${breakpoints.l}px) {
    max-width: none;
    margin: 0 auto 48px;
    border-radius: 0;
  }

  @media (max-width: ${breakpoints.m}px) {
    max-height: 380px;
    margin-bottom: -24px;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  position: relative;
  min-height: 256px;

  @media (max-width: ${breakpoints.l}px) {
    min-height: 512px;
  }
`;

export const NoImageWrapper = styled.div`
  min-height: 256px;

  @media (max-width: ${breakpoints.l}px) {
    min-height: 512px;
  }
`;

export const ImageView = styled.img`
  width: 100%;
  object-fit: cover;
  user-drag: none;
  min-height: 256px;

  @media (max-width: ${breakpoints.l}px) {
    min-height: 300px;
  }
`;

export const Content = styled.div`
  color: ${({ theme }) => theme.colors.white};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  padding: 32px 24px;

  @media (max-width: ${breakpoints.l}px) {
    padding: 64px 24px 32px;
    margin-bottom: 24px;
  }
`;

export const Illustration = styled.svg`
  position: absolute;
  width: 140%;
  height: 140%;
  top: -40%;
  left: -20%;

  @media (min-width: ${breakpoints.s}px) {
    width: 200%;
    height: 200%;
    top: -70%;
    left: -50%;
  }
`;

export const ImageOverlay = styled.div`
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));

  @media (max-width: ${breakpoints.l}px) {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }
`;
