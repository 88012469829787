import ConfirmActionPopup from 'components/popups/ConfirmActionPopup';
import Heading, { Tag } from 'components/UI/Heading'
import Icon, { IconType } from 'components/UI/Icon';
import IconButton from 'components/UI/IconButton';
import Loader from 'components/UI/Loader';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { getIntervalTranslation } from 'hooks/useInterval';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl'
import { localizationSelector, setLayoutHeader } from 'store';
import { useGetMyActiveNotificationSchedulesQuery, useRemoveNotificationScheduleMutation } from 'store/notificationScheduleService/notificationScheduleService';
import { ContentWidth } from 'styles'
import { Column, Item, ItemText, List, Row } from './styles';
import { parseDate } from 'utils/date';
import { NotificationType, RepeatEnum } from 'models/notificationSchedule/notificationSchedule';
import EmptyState from 'components/UI/EmptyState';
import StaticCard from 'components/UI/Cards/StaticCard';

const NotificationSchedulesSettings = () => {
  const dispatch = useAppDispatch();
  const { language } = useAppSelector(localizationSelector);

  const intl = useIntl();
  const { data: notificationSchedules, isLoading, refetch } = useGetMyActiveNotificationSchedulesQuery();
  const [deleteSchedule] = useRemoveNotificationScheduleMutation();

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [scheduleToDelete, setScheduleToDelete] = useState<string | null>(null);

  // Set header
  useEffect(() => {
    dispatch(
      setLayoutHeader({
        title: intl.formatMessage({
          id: 'pageSettingsTitle',
          defaultMessage: 'Settings',
          description: 'Page title for settings',
        }),
        icon: IconType.Back,
      })
    );
  }, [dispatch, intl]);

  const schedules = useMemo(() => {
    // Loading
    if (isLoading) {
      return <Loader color="blue" padding />;
    }

    if (!notificationSchedules) {
      return (
        <EmptyState iconType={IconType.Chat} padding>
          <FormattedMessage
            id='pageMyNotificationSchedulesEmptyState'
            defaultMessage='No notification schedules found'
            description='Empty state for notification schedules'
          />
        </EmptyState>
      );
    }
    return (
      <List>
        {notificationSchedules.length === 0 ? (
          <Row>
            {intl.formatMessage({
              id: 'noRemindersText',
              defaultMessage: 'No reminders',
              description: 'No reminders text'
            })
            }
            <Icon type={IconType.Reminder} />
          </Row>
        ) : (

          notificationSchedules.map((schedule, i) => {
            return (
              <Item
                key={`${schedule.id}`}
                isLast={notificationSchedules.length === i + 1}
              >
                <Column>
                  <Heading tag={Tag.H4}>
                    {schedule.ctaLabel[language?.languageCode || 'en']}
                  </Heading>
                  <ItemText>
                    {schedule.type.toString() === NotificationType[0] ?
                      intl.formatMessage({
                        id: 'NotificationCreatedByMeAdminLabel',
                        defaultMessage: 'Created by me',
                        description: 'Card description for notification schedules'
                      })
                      :
                      intl.formatMessage({
                        id: 'NotificationCreatedByCompanyAdminLabel',
                        defaultMessage: 'Created by company admin',
                        description: 'Card description for notification schedules'
                      })
                    }
                    < br />
                    {
                      intl.formatMessage({
                        id: 'NotificationSendingOptionsLabel',
                        defaultMessage: 'Interval',
                        description: 'Unit text for activities',
                      })
                    }
                    {': '}
                    {getIntervalTranslation(schedule?.repeatInterval, intl)}{' '}
                    {schedule.type.toString() === NotificationType[0] ?
                      intl.formatMessage({
                        id: 'NotificationToMyselfDescription',
                        defaultMessage: 'to myself',
                        description: 'to myself text',
                      }) : schedule.type.toString() === NotificationType[1] ?
                        intl.formatMessage({
                          id: 'NotificationToAllEmployeesDescription',
                          defaultMessage: 'to all employees',
                          description: 'Unit text for activities',
                        }) : schedule.type.toString() === NotificationType[2] ?
                          intl.formatMessage({
                            id: 'NotificationToDepartmentDescription',
                            defaultMessage: 'to department employees',
                            description: '',
                          }) : schedule.type.toString() === NotificationType[3] ?
                            intl.formatMessage({
                              id: 'NotificationToOfficeDescription',
                              defaultMessage: 'to office employees',
                              description: 'to office employees',
                            }) :
                            intl.formatMessage({
                              id: 'NotificationToHeadOfficeDescription',
                              defaultMessage: 'to head office employees',
                              description: 'to head office employees',
                            })
                    }
                    {' '}
                    {schedule.organizationDescription && `(${schedule.organizationDescription})`}
                    <br />
                    {schedule.repeatInterval.toString() === RepeatEnum[0] ? (
                      <div>
                        {intl.formatMessage({
                          id: 'plannedDispatchText',
                          defaultMessage: 'Planned dispatch',
                          description: 'Planned dispatch date for health card'
                        })}
                        {': '}
                        {parseDate(schedule.start)}
                      </div>
                    ) : (
                      <>
                        {
                          intl.formatMessage({
                            id: 'inputStartDateLabel',
                            defaultMessage: 'Start Date:',
                            description: 'Unit text for activities',
                          })
                        }
                        {': '}
                        {parseDate(schedule.start)}
                        {' -> '}
                        {intl.formatMessage({
                          id: 'inputEndDateLabel',
                          defaultMessage: 'Start Date:',
                          description: 'Unit text for activities',
                        })}
                        {': '}
                        {parseDate(schedule.stop)}
                      </>
                    )}
                  </ItemText>
                </Column>
                {schedule && (
                  <IconButton onClick={() => {
                    setScheduleToDelete(schedule.id);
                    setShowConfirmDelete(true);
                  }} padding>
                    <Icon type={IconType.Remove} />
                  </IconButton>
                )}
              </Item>
            );
          }))
        }
      </List>
    )
  }, [intl, isLoading, language?.languageCode, notificationSchedules]);

  return (
    <ContentWidth isSurface>
      <Heading>
        <FormattedMessage
          id='pageMyRemindersTitle'
          defaultMessage='My Reminders'
          description='Page title for reminders settings'
        />
      </Heading>
      <StaticCard padding>
        {schedules}
      </StaticCard>
      <ConfirmActionPopup
        isOpen={showConfirmDelete}
        onConfirm={async () => {
          if (scheduleToDelete) {
            await deleteSchedule({ id: scheduleToDelete });
            setShowConfirmDelete(false);
            refetch();
          }
        }}
        onAbort={() => setShowConfirmDelete(false)}
      >
        <>
          <Heading tag={Tag.H4}>
            <FormattedMessage
              id="deleteReminderConfirmHeading"
              defaultMessage="Delete reminder"
              description="Heading text in the delete reminder popup"
            />
          </Heading>
          <FormattedMessage
            id="deleteReminderConfirmBody"
            defaultMessage="Are you sure you want to delete this reminder?"
            description="Body text in the delete reminder popup"
          />
        </>
      </ConfirmActionPopup>
    </ContentWidth>
  )
}

export default NotificationSchedulesSettings