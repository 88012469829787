import styled from 'styled-components';
import ReactSlider from 'react-slider';
import { ThemeColor } from 'styles';

type Props = {
  color: ThemeColor;
};


export const Slider = styled(ReactSlider)<Props>`
  height: 24px;
  display: flex;
  align-items: center;

  .slider-track {
    height: 8px;
    background: ${({ color, theme }) => theme.colors[color]};
    border-radius: 4px;
    opacity: 0.4;
  }

  .slider-thumb {
    width: 24px;
    height: 24px;
    background: ${({ color, theme }) => theme.colors[color]};
    border-radius: 12px;
    cursor: pointer;
  }
  
  &.disabled .slider-thumb {
    cursor: default;
  }
`;

export const TextGrid = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  gap: 16px;
`;

type TextColProps = {
  alignRight?: boolean;
};

export const TextCol = styled.div<TextColProps>`
  flex: 1;
  text-align: ${({ alignRight }) => (alignRight ? 'right' : 'left')};
`;
