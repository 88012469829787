import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { UserLevel } from 'models';
import { useLocalizedText } from 'hooks/useLocalizedText';

import SlideoutModal from 'components/UI/SlideoutModal';
import Icon, { IconType } from 'components/UI/Icon';
import IconButton from 'components/UI/IconButton';
import Heading, { Tag } from 'components/UI/Heading';

import {
  Header,
  Body,
  Level,
  LevelItem,
  LevelAction,
  LevelLeftCol,
  LevelRightCol,
  LevelWrap,
} from './styles';
import WellrCoins from 'components/UI/WellrCoins';
import useLevelIcon from 'hooks/useLevelIcon';
import useUserLevel from 'hooks/useUserLevel';

type LevelProps = {
  level: UserLevel;
  userLevel: number;
};

const LevelContainer: FC<LevelProps> = ({ level, userLevel }) => {
  const getText = useLocalizedText();
  const isHigherLevel = level.levelNumber > userLevel;
  const iconSize = 30;
  const levelIcon = useLevelIcon(
    isHigherLevel ? null : level.levelNumber,
    iconSize
  );
  return (
    <LevelItem active={level.levelNumber === userLevel}>
      <LevelLeftCol>
        <LevelWrap>
          <Level>
            <FormattedMessage
              id="levelsModalLevelText"
              defaultMessage="Level {levelNumber}"
              description="Levels modal level text"
              values={{ levelNumber: level.levelNumber }}
            />
          </Level>
          {levelIcon && (
            <img
              src={levelIcon.src}
              alt={levelIcon.alt}
              width={iconSize}
              height={iconSize}
            />
          )}
        </LevelWrap>
        <Heading tag={Tag.H4}>{getText(level.title)}</Heading>
        {!isHigherLevel && <LevelAction>{getText(level.action)}</LevelAction>}
      </LevelLeftCol>
      <LevelRightCol>
        <WellrCoins text={`${level.pointsMin} WP - ${level.pointsMax} WP`} />
      </LevelRightCol>
    </LevelItem>
  );
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
  levels?: UserLevel[];
};

const LevelsModal: FC<Props> = ({ isOpen, onClose, levels }) => {
  const userLevel = useUserLevel();

  return (
    <SlideoutModal isOpen={isOpen} onClose={onClose}>
      <div>
        <Header>
          <Heading tag={Tag.H3}>
            <FormattedMessage
              id="levelsModalTitle"
              defaultMessage="Levels and achievements"
              description="Levels modal title"
            />
          </Heading>
          <IconButton onClick={onClose} padding>
            <Icon type={IconType.Close} />
          </IconButton>
        </Header>
        <Body>
          {userLevel != null &&
            levels?.map((level) => (
              <LevelContainer
                key={level.levelNumber}
                level={level}
                userLevel={userLevel}
              />
            ))}
        </Body>
      </div>
    </SlideoutModal>
  );
};

export default LevelsModal;
