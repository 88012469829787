import {
  Article,
  ArticleCategory,
  ArticleCategoryItem,
  ContentQuery,
  ContentResponse,
  DietCategory,
  DietCategoryItem,
  Exercise,
  ExerciseCategory,
  ExerciseCategoryItem,
  ExerciseProgram,
  ExerciseProgramCategory,
  ExerciseProgramCategoryItem,
  GDPR,
  LatestArticle,
  Recipe,
  StartPage,
  FilterTag,
  ArticleTitles,
} from 'models';
import { baseApi } from '../baseApi';
import {
  CompleteDietRecipeType,
  CompleteExerciseDescriptionPairType,
  CompleteExerciseProgramStepType,
  DeleteExerciseDescriptionPairType,
  DeleteExerciseProgramStepType,
  endpoints,
  GetCompletedDietcRecipesType,
  GetCompletedExerciseEntitiesType,
  SearchContentType,
  UncompleteDietRecipeType,
} from './endpointsDefinitions';

const contentApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getStartPage: builder.query<StartPage[], ContentQuery>({
      query: ({ language }) => `/content/start-page?language=${language}`,
      transformResponse: (res: ContentResponse) => res.result,
    }),
    getArticleCategoryList: builder.query<ArticleCategoryItem[], ContentQuery>({
      query: ({ language }) =>
        `/content/article-categories?language=${language}`,
      transformResponse: (res: ContentResponse) => res.result,
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ _id }) => ({ type: 'Content', id: _id } as const)
              ),
              { type: 'Content', id: 'ARTICLE_CATEGORIES' },
            ]
          : [{ type: 'Content', id: 'ARTICLE_CATEGORIES' }],
    }),
    getArticleCategory: builder.query<ArticleCategory, ContentQuery>({
      query: ({ slug, language }) =>
        `/content/articles/${slug}?language=${language}`,
      transformResponse: (res: ContentResponse) => res.result,
    }),
    getLatestArticles: builder.query<LatestArticle[], ContentQuery>({
      query: ({ limit, language }) =>
        `/content/articles/latest?language=${language}&numberOfArticles=${limit}`,
      transformResponse: (res: ContentResponse) => res.result,
    }),
    getArticle: builder.query<Article, ContentQuery>({
      query: ({ slug, language }) =>
        `/content/article/${slug}?language=${language}`,
    }),
    getArticleTitles: builder.query<ArticleTitles[], ContentQuery>({
      query: ({ language }) => `/content/articles/titles?language=${language}`,
    }),
    getDietCategoryList: builder.query<DietCategoryItem[], ContentQuery>({
      query: ({ language }) => `/content/diets?language=${language}`,
      transformResponse: (res: ContentResponse) => res.result,
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ _id }) => ({ type: 'Content', id: _id } as const)
              ),
              { type: 'Content', id: 'DIET_CATEGORIES' },
            ]
          : [{ type: 'Content', id: 'DIET_CATEGORIES' }],
    }),
    getDietCategory: builder.query<DietCategory, ContentQuery>({
      query: ({ slug, language }) =>
        `/content/diet/${slug}?language=${language}`,
      transformResponse: (res: ContentResponse) => res.result,
    }),
    getRecipe: builder.query<Recipe[], ContentQuery>({
      query: ({ slug, language }) =>
        `/content/recipe/${slug}?language=${language}`,
      transformResponse: (res: ContentResponse) => res.result,
    }),
    getGdpr: builder.query<
      GDPR[],
      { language?: string | null; migratingUser: boolean }
    >({
      query: ({ language, migratingUser }) => {
        const gdprPath = migratingUser ? 'gdpr-migrated' : 'gdpr';
        return `/content/${gdprPath}?language=${language}`;
      },
      transformResponse: (res: ContentResponse) => res.result,
    }),
    getExerciseCategoryList: builder.query<
      ExerciseCategoryItem[],
      ContentQuery
    >({
      query: ({ language }) =>
        `/content/exercise-categories?language=${language}`,
      transformResponse: (res: ContentResponse) => res.result,
      providesTags: [{ type: 'Content', id: 'EXERCISE_CATEGORIES' }],
    }),
    getExerciseCategory: builder.query<ExerciseCategory, ContentQuery>({
      query: ({ slug, language }) =>
        `/content/exercises/${slug}?language=${language}`,
      transformResponse: (res: ContentResponse) => res.result,
    }),
    getExerciseProgramCategoryList: builder.query<
      ExerciseProgramCategoryItem[],
      ContentQuery
    >({
      query: ({ language }) =>
        `/content/exercise-program-categories?language=${language}`,
      transformResponse: (res: ContentResponse) => res.result,
      providesTags: [{ type: 'Content', id: 'EXERCISE_PROGRAM_CATEGORIES' }],
    }),
    getExerciseProgramCategory: builder.query<
      ExerciseProgramCategory,
      ContentQuery
    >({
      query: ({ slug, language }) =>
        `/content/exercise-programs/${slug}?language=${language}`,
      transformResponse: (res: ContentResponse) => res.result,
    }),
    getExercise: builder.query<Exercise, ContentQuery>({
      query: ({ slug, language }) =>
        `/content/exercise/${slug}?language=${language}`,
    }),
    getExerciseProgram: builder.query<ExerciseProgram, ContentQuery>({
      query: ({ slug, language }) =>
        `/content/exercise-program/${slug}?language=${language}`,
    }),
    getExerciseTags: builder.query<FilterTag[], ContentQuery>({
      query: ({ language }) => `/content/exercise/tags?language=${language}`,
    }),
    completeExerciseDescriptionPair: builder.mutation<
      CompleteExerciseDescriptionPairType['result'],
      CompleteExerciseDescriptionPairType['queryArgs']
    >({
      query: ({ exerciseProgramSlug, exerciseKey }) => ({
        url: endpoints.completeExerciseDescriptionPair.path(
          exerciseProgramSlug
        ),
        method: endpoints.completeExerciseDescriptionPair.method,
        params: { exerciseKey },
      }),
      invalidatesTags: (_result, _error, { exerciseProgramSlug }) => {
        return [{ type: 'ExerciseProgram', id: exerciseProgramSlug }];
      },
    }),
    deleteExerciseDescriptionPair: builder.mutation<
      DeleteExerciseDescriptionPairType['result'],
      DeleteExerciseDescriptionPairType['queryArgs']
    >({
      query: ({ exerciseProgramSlug, exerciseKey }) => ({
        url: endpoints.deleteExerciseDescriptionPair.path(exerciseProgramSlug),
        method: endpoints.deleteExerciseDescriptionPair.method,
        params: { exerciseKey },
      }),
      invalidatesTags: (_result, _error, { exerciseProgramSlug }) => {
        return [{ type: 'ExerciseProgram', id: exerciseProgramSlug }];
      },
    }),
    completeExerciseProgramStep: builder.mutation<
      CompleteExerciseProgramStepType['result'],
      CompleteExerciseProgramStepType['queryArgs']
    >({
      query: ({ exerciseProgramSlug, stepKey }) => ({
        url: endpoints.completeExerciseProgramStep.path(exerciseProgramSlug),
        method: endpoints.completeExerciseProgramStep.method,
        params: { stepKey },
      }),
      invalidatesTags: (_result, _error, { exerciseProgramSlug }) => {
        return [{ type: 'ExerciseProgram', id: exerciseProgramSlug }];
      },
      async onQueryStarted(
        { exerciseProgramSlug, stepKey },
        { dispatch, queryFulfilled }
      ) {
        // Optimistic update the cache to avoid laggy checkbox in UI
        const patchResult = dispatch(
          contentApi.util.updateQueryData(
            'getCompletedExerciseEntities',
            { exerciseProgramSlug },
            (draft) => {
              draft.completedSteps = [
                ...(draft.completedSteps ?? []),
                { key: stepKey },
              ];
            }
          )
        );
        try {
          await queryFulfilled;
        } catch (error) {
          // Revert optimistic update if the mutation fails
          patchResult.undo();
        }
      },
    }),
    deleteExerciseProgramStep: builder.mutation<
      DeleteExerciseProgramStepType['result'],
      DeleteExerciseProgramStepType['queryArgs']
    >({
      query: ({ exerciseProgramSlug, stepKey }) => ({
        url: endpoints.deleteExerciseProgramStep.path(exerciseProgramSlug),
        method: endpoints.deleteExerciseProgramStep.method,
        params: { stepKey },
      }),
      invalidatesTags: (_result, _error, { exerciseProgramSlug }) => {
        return [{ type: 'ExerciseProgram', id: exerciseProgramSlug }];
      },
      async onQueryStarted(
        { exerciseProgramSlug, stepKey },
        { dispatch, queryFulfilled }
      ) {
        // Optimistic update the cache to avoid laggy checkbox in UI
        const patchResult = dispatch(
          contentApi.util.updateQueryData(
            'getCompletedExerciseEntities',
            { exerciseProgramSlug },
            (draft) => {
              draft.completedSteps = draft.completedSteps?.filter(
                (step) => step.key !== stepKey
              );
            }
          )
        );
        try {
          await queryFulfilled;
        } catch (error) {
          // Revert optimistic update if the mutation fails
          patchResult.undo();
        }
      },
    }),
    getCompletedExerciseEntities: builder.query<
      GetCompletedExerciseEntitiesType['result'],
      GetCompletedExerciseEntitiesType['queryArgs']
    >({
      query: ({ exerciseProgramSlug }) => ({
        url: endpoints.getCompletedExerciseEntities.path(exerciseProgramSlug),
        method: endpoints.getCompletedExerciseEntities.method,
      }),
      providesTags: (result, error, { exerciseProgramSlug }) => {
        return [
          { type: 'ExerciseProgram', id: 'EXERCISE_PROGRAMS_ALL' },
          { type: 'ExerciseProgram', id: exerciseProgramSlug },
        ];
      },
    }),
    completeDietRecipe: builder.mutation<
      CompleteDietRecipeType['result'],
      CompleteDietRecipeType['queryArgs']
    >({
      query: ({ dietSlug, ...rest }) => ({
        url: endpoints.completeDietRecipe.path(dietSlug),
        method: endpoints.completeDietRecipe.method,
        params: rest,
      }),
      invalidatesTags: (_result, _error, { dietSlug }) => {
        return [{ type: 'DietRecipe', id: dietSlug }];
      },
      async onQueryStarted(
        { dietSlug, weekKey, daySlug, meal },
        { dispatch, queryFulfilled }
      ) {
        // Optimistic update the cache to avoid laggy checkbox in UI
        const patchResult = dispatch(
          contentApi.util.updateQueryData(
            'getCompletedDietRecipes',
            { dietSlug },
            (completedDietRecipes) => {
              let week = completedDietRecipes.weeks?.find(
                (week) => week.key === weekKey
              );
              if (!week) {
                week = { key: weekKey, days: [] };
                completedDietRecipes.weeks = [
                  week,
                  ...(completedDietRecipes.weeks ?? []),
                ];
              }

              let day = week.days?.find((day) => day.daySlug === daySlug);
              if (!day) {
                day = {
                  daySlug,
                  breakfastCompleted: false,
                  lunchCompleted: false,
                  dinnerCompleted: false,
                };
                week.days = [day, ...(week.days ?? [])];
              }

              if (meal === 'breakfast') {
                day.breakfastCompleted = true;
              } else if (meal === 'lunch') {
                day.lunchCompleted = true;
              } else if (meal === 'dinner') {
                day.dinnerCompleted = true;
              }
            }
          )
        );

        try {
          await queryFulfilled;
        } catch (error) {
          // Revert optimistic update if the mutation fails
          patchResult.undo();
        }
      },
    }),
    uncompleteDietRecipe: builder.mutation<
      UncompleteDietRecipeType['result'],
      UncompleteDietRecipeType['queryArgs']
    >({
      query: ({ dietSlug, ...rest }) => ({
        url: endpoints.uncompleteDietRecipe.path(dietSlug),
        method: endpoints.uncompleteDietRecipe.method,
        params: rest,
      }),
      invalidatesTags: (_result, _error, { dietSlug }) => {
        return [{ type: 'DietRecipe', id: dietSlug }];
      },
      async onQueryStarted(
        { dietSlug, weekKey, daySlug, meal },
        { dispatch, queryFulfilled }
      ) {
        // Optimistic update of the cache to avoid laggy checkbox in UI
        const patchResult = dispatch(
          contentApi.util.updateQueryData(
            'getCompletedDietRecipes',
            { dietSlug },
            (completedDietRecipes) => {
              const targetWeek = completedDietRecipes.weeks?.find(
                (week) => week.key === weekKey
              );
              if (targetWeek) {
                const targetDay = targetWeek.days?.find(
                  (day) => day.daySlug === daySlug
                );
                if (targetDay) {
                  if (meal === 'breakfast') {
                    targetDay.breakfastCompleted = false;
                  } else if (meal === 'dinner') {
                    targetDay.dinnerCompleted = false;
                  } else if (meal === 'lunch') {
                    targetDay.lunchCompleted = false;
                  }
                }
              }
            }
          )
        );
        try {
          await queryFulfilled;
        } catch (error) {
          // Revert optimistic update if the mutation fails
          patchResult.undo();
        }
      },
    }),
    getCompletedDietRecipes: builder.query<
      GetCompletedDietcRecipesType['result'],
      GetCompletedDietcRecipesType['queryArgs']
    >({
      query: ({ dietSlug }) => ({
        url: endpoints.getCompletedDietRecipes.path(dietSlug),
        method: endpoints.getCompletedDietRecipes.method,
      }),
      providesTags: (_result, _error, { dietSlug }) => {
        return [
          { type: 'DietRecipe', id: 'DIET_RECIPES_ALL' },
          { type: 'DietRecipe', id: dietSlug },
        ];
      },
    }),
    searchContent: builder.query<
      SearchContentType['result'],
      SearchContentType['queryArgs']
    >({
      query: (params) => ({
        url: endpoints.searchContent.path,
        method: endpoints.searchContent.method,
        params: {
          searchString: params?.searchString,
          language: params?.language,
        },
      }),
    }),
  }),
});

export const {
  useGetStartPageQuery,
  useGetArticleCategoryListQuery,
  useGetArticleCategoryQuery,
  useGetLatestArticlesQuery,
  useGetArticleQuery,
  useGetArticleTitlesQuery,
  useGetDietCategoryListQuery,
  useGetDietCategoryQuery,
  useGetRecipeQuery,
  useGetGdprQuery,
  useGetExerciseCategoryListQuery,
  useGetExerciseProgramCategoryListQuery,
  useGetExerciseCategoryQuery,
  useGetExerciseProgramCategoryQuery,
  useGetExerciseQuery,
  useGetExerciseProgramQuery,
  useGetExerciseTagsQuery,
  useCompleteExerciseDescriptionPairMutation,
  useDeleteExerciseDescriptionPairMutation,
  useCompleteExerciseProgramStepMutation,
  useDeleteExerciseProgramStepMutation,
  useGetCompletedExerciseEntitiesQuery,
  useCompleteDietRecipeMutation,
  useUncompleteDietRecipeMutation,
  useGetCompletedDietRecipesQuery,
  useLazySearchContentQuery,
} = contentApi;
