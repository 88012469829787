import React, {
  FC,
  Fragment,
  useCallback,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  localizationSelector,
  setLayoutHeader,
  useAnswerContestInviteMutation,
  useGetContestPreviewQuery,
} from 'store';

import * as routes from 'router/routes';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { ActionContainer, ContentWidth, Gap } from 'styles';

import { IconType } from 'components/UI/Icon';
import Heading, { Tag } from 'components/UI/Heading/Heading';
import Button from 'components/UI/Button/Button';
import Loader from 'components/UI/Loader';
import EmptyState from 'components/UI/EmptyState';

import RichText from 'components/fragments/RichText/RichText';
import ContestHeader from 'components/fragments/ContestHeader';
import ContentModal from 'components/modals/ContentModal';
import UserAliasForm from 'components/forms/UserAliasForm';

import { ButtonGrid } from './styles';
import { AliasForm } from 'components/forms/UserAliasForm/UserAliasForm';

const Invitation: FC = () => {
  const intl = useIntl();
  const { contestId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { language } = useAppSelector(localizationSelector);

  // Hooks
  const [answerInvite, answerResult] = useAnswerContestInviteMutation();
  const { data, isLoading } = useGetContestPreviewQuery({
    contestId,
    language: language?.languageCode,
  });

  // State
  const [acceptModalOpen, setAcceptModalOpen] = useState<boolean>(false);
  const [declineModalOpen, setDeclineModalOpen] = useState<boolean>(false);

  // Set header
  useEffect(() => {
    dispatch(
      setLayoutHeader({
        title: intl.formatMessage({
          id: 'pageCompeteTitle',
          defaultMessage: 'Compete',
          description: 'Compete page title',
        }),
        inverted: true,
        icon: IconType.Back,
        link: routes.COMPETE,
      })
    );
  }, [dispatch, intl]);

  // Actions
  const onAccept = useCallback(
    async (form: AliasForm) => {
      if (data && contestId) {
        const contestPath =
          data.type === 'Competition' ? 'competitions' : 'challenges';
        await answerInvite({
          contestId,
          accept: true,
          alias: form.alias,
          displayEmail: form.displayEmail,
        });
        navigate(`${routes.COMPETE}/${contestPath}/${contestId}`);
      }
    },
    [answerInvite, navigate, contestId, data]
  );
  const onDecline = useCallback(async () => {
    if (contestId) {
      await answerInvite({ contestId, accept: false, alias: '' });
    }
    navigate(routes.COMPETE);
  }, [answerInvite, navigate, contestId]);
  const onOpenAcceptModal = useCallback(() => setAcceptModalOpen(true), []);
  const onCloseAcceptModal = useCallback(() => setAcceptModalOpen(false), []);
  const onOpenDeclineModal = useCallback(() => setDeclineModalOpen(true), []);
  const onCloseDeclineModal = useCallback(() => setDeclineModalOpen(false), []);

  // Accept modal content
  const acceptModalContent = useMemo(() => {
    if (answerResult.isLoading) {
      return <Loader color="blue" padding />;
    }
    return (
      <Fragment>
        <p>
          <FormattedMessage
            id="pageInvitationAcceptDescription"
            defaultMessage="Enter an alias in order to accept the invitation."
            description="Invitation accept description"
          />
        </p>
        <UserAliasForm withDisplayEmailSetting submitForm={onAccept} />
      </Fragment>
    );
  }, [onAccept, answerResult]);

  // Decline modal content
  const declineModalContent = useMemo(() => {
    if (answerResult.isLoading) {
      return <Loader color="blue" padding />;
    }
    return (
      <Fragment>
        <p>
          <FormattedMessage
            id="pageInvitationDeclineDescription"
            defaultMessage="Are you sure you would like to decline this invitation?"
            description="Invitation decline description"
          />
        </p>
        <ActionContainer>
          <Button background="error" onClick={onDecline}>
            <FormattedMessage
              id="declineButton"
              defaultMessage="Decline"
              description="Decline button"
            />
          </Button>
        </ActionContainer>
      </Fragment>
    );
  }, [onDecline, answerResult]);

  // Loading
  if (isLoading) {
    return <Loader color="blue" padding />;
  }

  // No data
  if (!data) {
    return (
      <EmptyState iconType={IconType.Competition} padding>
        <FormattedMessage
          id="pageCompetePreviewEmptyState"
          defaultMessage="Preview not found"
          description="Empty state for preview"
        />
      </EmptyState>
    );
  }

  const {
    title,
    type,
    description,
    wellrPoints,
    start,
    stop,
    image,
    invitedBy,
  } = data;

  return (
    <Fragment>
      <ContestHeader
        title={title}
        categoryTitle={intl.formatMessage({
          id: 'pageInvitationTitle',
          defaultMessage: 'Invitation',
          description: 'Invitation title',
        })}
        image={image}
        start={start}
        stop={stop}
        wellrPoints={wellrPoints}
        languageCode={language?.languageCode}
      />
      <ContentWidth isSurface>
        <ButtonGrid>
          <Button
            border="error"
            background="transparent"
            color="error"
            onClick={onOpenDeclineModal}
          >
            <FormattedMessage
              id="declineButton"
              defaultMessage="Decline"
              description="Decline button"
            />
          </Button>
          <Button background="blue" onClick={onOpenAcceptModal}>
            <FormattedMessage
              id="acceptButton"
              defaultMessage="Accept"
              description="Accept button text"
            />
          </Button>
        </ButtonGrid>
        <p>
          <FormattedMessage
            id="pageInvitationInvitedBy"
            defaultMessage="You have been invited by {invitedBy}."
            description="Invitation invited by text"
            values={{ invitedBy }}
          />
        </p>
        <Gap />
        <Heading tag={Tag.H4}>
          {type === 'Competition' ? (
            <FormattedMessage
              id="competitionAboutButton"
              defaultMessage="About the competition"
              description="Competition about button"
            />
          ) : (
            <FormattedMessage
              id="challengeAboutButton"
              defaultMessage="About the challenge"
              description="Challenge about button"
            />
          )}
        </Heading>
        {description && <RichText>{description}</RichText>}
      </ContentWidth>
      <ContentModal
        title={intl.formatMessage({
          id: 'pageInvitationAcceptTitle',
          defaultMessage: 'Accept invitation',
          description: 'Invitation page accept title',
        })}
        isOpen={acceptModalOpen}
        onClose={onCloseAcceptModal}
      >
        {acceptModalContent}
      </ContentModal>
      <ContentModal
        title={intl.formatMessage({
          id: 'pageInvitationDeclineTitle',
          defaultMessage: 'Decline invitation',
          description: 'Invitation page decline title',
        })}
        isOpen={declineModalOpen}
        onClose={onCloseDeclineModal}
      >
        {declineModalContent}
      </ContentModal>
    </Fragment>
  );
};

export default Invitation;
