import { FC, useEffect, useState } from 'react';

import { ThemeColor } from 'styles';
import { Slider, TextGrid, TextCol } from './styles';

type Props = {
  color?: ThemeColor;
  minValue?: number;
  maxValue?: number;
  defaultValue?: number;
  minText?: string;
  maxText?: string;
  minDescription?: string;
  onChange?: (value: number) => void;
  disabled?: boolean;
};

const SliderField: FC<Props> = ({
  onChange,
  minValue,
  maxValue,
  defaultValue,
  minText,
  maxText,
  color = 'white',
  disabled = false,
}) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <div>
      <Slider
        className={disabled ? 'disabled' : ''}
        color={color}
        thumbClassName="slider-thumb"
        trackClassName="slider-track"
        onAfterChange={(value) => {
          setValue(value as number);
          onChange?.(value as number);
        }}
        min={minValue}
        max={maxValue}
        value={value}
        disabled={disabled}
      />
      {minText || maxText ? (
        <TextGrid>
          <TextCol>{minText}</TextCol>
          <TextCol alignRight>{maxText}</TextCol>
        </TextGrid>
      ) : null}
    </div>
  );
};

export default SliderField;