import React, { FC, ReactNode } from 'react';

import Icon, { IconType } from 'components/UI/Icon';
import IconButton from 'components/UI/IconButton';
import Heading, { Tag } from 'components/UI/Heading';
import SlideoutModal from 'components/UI/SlideoutModal';

import { Header, Body, ModalContent } from './styles';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  title: string | ReactNode;
  children: ReactNode;
};

const ContentModal: FC<Props> = ({ title, children, isOpen, onClose }) => {
  return (
    <SlideoutModal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <Header>
          <Heading tag={Tag.H3}>{title}</Heading>
          <IconButton onClick={onClose} padding>
            <Icon type={IconType.Close} />
          </IconButton>
        </Header>
        <Body>{children}</Body>
      </ModalContent>
    </SlideoutModal>
  );
};

export default ContentModal;
