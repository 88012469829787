import {
  getEndpointURL,
  WellrEndpointDefinition,
  WellrEndpointPath,
} from 'utils/api';

/** Get pulse quizes that can be started */
const getNotificationStatePath: WellrEndpointPath = '/api/user/active-events';
const getNotificationStateMethod = 'get';
type GetNotificationState = WellrEndpointDefinition<
  typeof getNotificationStatePath,
  typeof getNotificationStateMethod
>;

export type GetNotificationStateType = {
  result: GetNotificationState['responseBody'];
  queryArgs: void;
};

export const endpoints = {
  getNotificationState: {
    path: getEndpointURL(getNotificationStatePath),
    method: getNotificationStateMethod,
  },
};
