import styled from 'styled-components';
import { ThemeColor } from 'styles';

export const SmallGap = styled.div`
  height: 20px;
`;

export const HealthCardWrapper = styled.div`
  display: flex;
  gap: 15px;
`;

export const CircleWrap = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 35px;
  justify-content: center;
`;

export const CircleItem = styled.div`
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
`;

export const ColoredCircle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
`;

export const ColorHighlight = styled.div<{ color: ThemeColor }>`
  border-left: 8px solid ${(props) => props.theme.colors[props.color]};
  padding-left: 10px;
`;
