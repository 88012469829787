import { FC } from 'react';
import { Container, CheckIcon } from './styles';

type Props = {
  type?: 'default' | 'grey';
};

const CheckBadge: FC<Props> = ({ type = 'default' }) => {
  return (
    <Container type={type}>
      <CheckIcon viewBox="0 0 24 24">
        <path d="M9 16.17L5.53 12.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41l4.18 4.18c.39.39 1.02.39 1.41 0L20.29 7.71c.39-.39.39-1.02 0-1.41-.39-.39-1.02-.39-1.41 0L9 16.17z" />
      </CheckIcon>
    </Container>
  );
};

export default CheckBadge;
