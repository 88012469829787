import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';

import Button from 'components/UI/Button/Button';
import { ActionContainer } from 'styles';
import { useGetCompanyStructureQuery } from 'store';
import { SelectOption } from 'models';
import { FieldGrid } from './styles';
import Loader from 'components/UI/Loader';
import { usePretendCompany } from 'hooks/usePretendCompany';
import SelectMultiField from 'components/UI/SelectMultiField';

type Props = {
  submitForm: (data: CreateOfficeFormData) => void;
};

export type CreateOfficeFormData = {
  officeIds: string[];
};

const UpdateUserOfficeAdminForm: FC<Props> = ({ submitForm }) => {
  const intl = useIntl();
  const companyId = usePretendCompany();
  const { handleSubmit, setValue, formState: { errors } } = useForm<CreateOfficeFormData>({
    defaultValues: {
      officeIds: [],
    },
  });
  const { data, isLoading } = useGetCompanyStructureQuery({ companyId });

  const handleSelectedItemsChange = (selectedItems: string[]) => {
    setValue('officeIds', selectedItems);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <FieldGrid>
        <SelectMultiField
          label={intl.formatMessage({
            id: 'inputOfficeLabel',
            defaultMessage: 'offices',
            description: 'Label for office input',
          })}
          options={(data?.offices || []).map(({ id, description }) => ({
            value: id,
            name: description || '',
          })) as SelectOption[]}
          defaultValue={[]}
          error={errors.officeIds}
          onSelectedItemsChange={handleSelectedItemsChange}
        />
      </FieldGrid>
      <ActionContainer>
        <Button type="submit" background="blue">
          <FormattedMessage
            id="saveButton"
            defaultMessage="Save"
            description="Save button text"
          />
        </Button>
      </ActionContainer>
    </form>
  );
};

export default UpdateUserOfficeAdminForm;
