import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { ContestGoal, ContestTeamUser, ContestUserOut } from 'models';

import StaticCard from 'components/UI/Cards/StaticCard';
import EmptyState from 'components/UI/EmptyState';
import { IconType } from 'components/UI/Icon';

import TopListGoalItem from 'components/fragments/TopListGoalItem';
import TopListProgressItem from 'components/fragments/TopListProgressItem';

import { TeamList } from './styles';

type Props = {
  individualResults: boolean;
  list?: ContestTeamUser[];
  goals: ContestGoal[];
  users: ContestUserOut[];
  userId: string;
};

const ChallengeTopList: FC<Props> = ({
  list,
  goals,
  users,
  userId,
  individualResults,
}) => {
  // Check if list exist
  if (!list?.length) {
    return (
      <EmptyState iconType={IconType.Users} padding>
        <FormattedMessage
          id="competitionTopListEmptyState"
          defaultMessage="No users found"
          description="Empty state for competition top list"
        />
      </EmptyState>
    );
  }

  const [firstGoal] = goals;

  // Sort the list based on the number of completedCheckpoints
  const sortedList = [...list].sort((a, b) => {
    const aUser = users.find((user) => user.id === a.id);
    const bUser = users.find((user) => user.id === b.id);

    const aCompletedGoals = aUser ? aUser.completedCheckpoints.length : 0;
    const bCompletedGoals = bUser ? bUser.completedCheckpoints.length : 0;

    return bCompletedGoals - aCompletedGoals;
  });

  return (
    <StaticCard>
      <TeamList>
        {sortedList.map((item, i) => {
          const user = users.find((user) => user.id === item.id);
          if (!user) {
            return null;
          }
          let totalSteps: number;
          let progress: number | null;
          const { id, alias, profilePictureAssetId } = item;
          const isMe = id === userId;
          const showResults = individualResults || isMe;

          if (showResults) {
            const cut = user.total / firstGoal.amount;
            progress = !isNaN(cut) && isFinite(cut) ? cut * 100 : 0;
            totalSteps = item.total;
          } else {
            progress = null;
            totalSteps = 0;
          }

          const { completedGoals, completedCheckpoints } = user;
          const key = id;
          const isLast = list.length === i + 1;

          if (firstGoal.type === 'A_b') {
            return (
              <TopListProgressItem
                key={key}
                isLast={isLast}
                email={user.email}
                alias={alias}
                profilePictureAssetId={profilePictureAssetId}
                showResults={showResults}
                progress={progress}
                progressColor={isMe ? 'orangeDark' : 'blue'}
                totalSteps={totalSteps}
              />
            );
          }

          return (
            <TopListGoalItem
              key={key}
              isLast={isLast}
              email={user.email}
              alias={alias}
              profilePictureAssetId={profilePictureAssetId}
              goals={goals}
              completedGoals={completedGoals}
              completedCheckpoints={completedCheckpoints}
            />
          );
        })}
      </TeamList>
    </StaticCard>
  );
};

export default ChallengeTopList;
