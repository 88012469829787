import React, { FC } from 'react';
import { DropdownMenuItem } from 'models';

import Heading, { Tag } from '../Heading';
import IconButton from '../IconButton';
import Icon, { IconType } from '../Icon';

import {
  Header,
  Menu,
  MenuListItem,
  Wrapper,
  Background,
  Container,
} from './styles';

type Props = {
  isOpen: boolean;
  title: string;
  menu: DropdownMenuItem[];
  onClose: () => void;
};

const ContextMenu: FC<Props> = ({ onClose, isOpen, title, menu }) => {
  return (
    <Wrapper>
      <Background
        initial={false}
        variants={{
          open: {
            opacity: 0.5,
            display: 'block',
          },
          closed: {
            opacity: 0,
            display: 'none',
          },
        }}
        transition={{ type: 'tween' }}
        animate={isOpen ? 'open' : 'closed'}
      />
      <Container
        initial={false}
        variants={{
          open: {
            bottom: 0,
          },
          closed: {
            bottom: '-100%',
          },
        }}
        transition={{ type: 'tween' }}
        animate={isOpen ? 'open' : 'closed'}
      >
        <Header>
          <Heading tag={Tag.H3}>{title}</Heading>
          <IconButton onClick={onClose} padding>
            <Icon type={IconType.Close} />
          </IconButton>
        </Header>
        <Menu>
          {menu.map(({ id, text, onClick }) => (
            <MenuListItem
              key={id}
              onClick={() => {
                onClick && onClick();
                onClose();
              }}
            >
              {text}
            </MenuListItem>
          ))}
        </Menu>
      </Container>
    </Wrapper>
  );
};

export default ContextMenu;
