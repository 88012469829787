import { createContext, useContext, useState } from 'react';

export const SelectedDepartmentContext = createContext<{
  selected: string;
  onToggle: (membershipId: string) => void;
}>({
  selected: '',
  onToggle: () => {},
});

export const useDepartmentContext = () => useContext(SelectedDepartmentContext);

export const useDepartmentState = () => {
  const [selectedDepartment, setSelectedDepartment] = useState<string>('');
  const toggleSelectDepartment = (departmentId: string) => {
    console.log('ontoggle', departmentId);
    setSelectedDepartment(departmentId);
  };
  return { selectedDepartment, toggleSelectDepartment };
};
