import { FC, Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ContentWidth, FlexContainer, MediumGap } from 'styles';
import { useUseGetUmbrellaCompanyQuery } from 'store';

import Heading, { Tag } from 'components/UI/Heading';
import Loader from 'components/UI/Loader';

import { IconType } from 'components/UI/Icon';

import * as routes from 'router/routes';
import { useParams } from 'react-router-dom';
import EmptyState from 'components/UI/EmptyState';
import { usePageHeader } from 'hooks/usePageHeader';
import NavCard from 'components/UI/Cards/NavCard';
import CompanyCommunicationManager from 'components/fragments/CompanyCommunicationManager';

const AdminUmbrellaCompanyDetails: FC = () => {
  const intl = useIntl();
  const { umbrellaCompanyId } = useParams();

  const umbrellaCompanyResult = useUseGetUmbrellaCompanyQuery(
    { umbrellaCompanyId: umbrellaCompanyId as string },
    { skip: !umbrellaCompanyId }
  );

  usePageHeader({
    title: intl.formatMessage({
      id: 'pageAdminUmbrellaCompaniesTitle',
      defaultMessage: 'Umbrella Companies',
      description: 'Page title for admin umbrella companies',
    }),
    inverted: true,
    icon: IconType.Back,
  });

  if (umbrellaCompanyResult.isLoading) {
    return <Loader color="blue" padding />;
  }

  if (!umbrellaCompanyResult.data) {
    return (
      <EmptyState iconType={IconType.User} padding>
        <FormattedMessage
          id="pageAdminCompanyDetailsNotFound"
          defaultMessage="A Company with the provided id was not found"
          description="Not found text for admin company details page"
        />
      </EmptyState>
    );
  }

  return (
    <Fragment>
      <ContentWidth isSurface>
        <FlexContainer alignItems="center" justifyContent="space-between">
          <Heading tag={Tag.H2}>
            {umbrellaCompanyResult.data.description}
          </Heading>
        </FlexContainer>
        <Heading tag={Tag.H4}>
          <FormattedMessage
            id="adminUmbrellaCompanyCompaniesTitle"
            defaultMessage="Companies"
            description="Umbrella company companies heading title"
          />
        </Heading>
        <FlexContainer gap={12} flexDirection="column">
          {(umbrellaCompanyResult.data.companies ?? []).map((company) => (
            <NavCard
              key={company.description}
              title={company.description ?? ''}
              to={`${routes.ADMIN_COMPANIES}/${company.id}`}
            />
          ))}
        </FlexContainer>
        <MediumGap />
        <CompanyCommunicationManager
          id={umbrellaCompanyId as string}
          type="umbrellaCompany"
        />
      </ContentWidth>
    </Fragment>
  );
};

export default AdminUmbrellaCompanyDetails;
