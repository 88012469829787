import { FC, Fragment, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  useCompleteExerciseProgramStepMutation,
  useDeleteExerciseProgramStepMutation,
  useGetCompletedExerciseEntitiesQuery,
} from 'store';

import { ExerciseDescriptionPair, ExerciseProgramStep } from 'models';
import { Gap } from 'styles';
import { useLocalizedText } from 'hooks/useLocalizedText';
import { getImage } from 'utils/asset';
import * as routes from 'router/routes';

import RichText from 'components/fragments/RichText';
import ExpandableCard from 'components/UI/Cards/ExpandableCard';
import Heading, { Tag } from 'components/UI/Heading';
import Loader from 'components/UI/Loader';
import CategoryCard from 'components/UI/Cards/CategoryCard';

import { CardList, CardItem } from './styles';

type Props = {
  slug: string;
  exerciseDescriptionPairs: ExerciseDescriptionPair[];
  exerciseProgramSteps: ExerciseProgramStep[];
};

const ExcerciseProgramProgress: FC<Props> = ({
  slug,
  exerciseDescriptionPairs,
  exerciseProgramSteps,
}) => {
  const getText = useLocalizedText();

  const { data: completedExerciesData, isLoading } =
    useGetCompletedExerciseEntitiesQuery({
      exerciseProgramSlug: slug as string,
    });

  const [checkProgramStep] = useCompleteExerciseProgramStepMutation();
  const [uncheckProgramStep] = useDeleteExerciseProgramStepMutation();

  const checkedPairs = useMemo(() => {
    if (completedExerciesData?.completedExercises != null) {
      return completedExerciesData.completedExercises.map((pair) => pair.key);
    }
    return [];
  }, [completedExerciesData?.completedExercises]);

  const checkedSteps = useMemo(() => {
    if (completedExerciesData?.completedSteps != null) {
      return completedExerciesData.completedSteps.map((step) => step.key);
    }
    return [];
  }, [completedExerciesData?.completedSteps]);

  const toggleStep = useCallback(
    (stepKey: string, isChecked: boolean) => {
      if (isChecked) {
        uncheckProgramStep({ exerciseProgramSlug: slug as string, stepKey });
      } else {
        checkProgramStep({ exerciseProgramSlug: slug as string, stepKey });
      }
    },
    [checkProgramStep, slug, uncheckProgramStep]
  );

  if (isLoading) {
    return <Loader color="blue" padding />;
  }

  if (!completedExerciesData) {
    return null;
  }

  return (
    <>
      {exerciseDescriptionPairs.length > 0 && (
        <Fragment>
          <Heading tag={Tag.H4}>
            <FormattedMessage
              id="pageExerciseProgramSectionTitleExercises"
              defaultMessage="Exercises"
              description="Page section title for exercises in exercise program"
            />
          </Heading>
          <CardList>
            {exerciseDescriptionPairs.map((pair, i) => {
              if (!pair.exercise) {
                return null;
              }
              const { title, image, slug: exerciseSlug } = pair.exercise;
              return (
                <CategoryCard
                  key={`exercise-${i}`}
                  title={`${getText(pair.title)} - ${getText(title)}`}
                  link={`${routes.EXERCISES}/${exerciseSlug.current}`}
                  linkOptions={{
                    state: {
                      exerciseKey: pair.key,
                      exerciseProgramSlug: slug,
                    },
                  }}
                  image={getImage(image)}
                  isCompleted={checkedPairs.includes(pair.key)}
                />
              );
            })}
          </CardList>
          <Gap />
        </Fragment>
      )}
      {exerciseProgramSteps.length > 0 && (
        <Fragment>
          <Heading tag={Tag.H4}>
            <FormattedMessage
              id="pageExerciseProgramSectionTitleProgram"
              defaultMessage="Program"
              description="Page section title for program in exercise program"
            />
          </Heading>
          <CardList>
            {exerciseProgramSteps.map((step) => {
              return (
                <ExpandableCard
                  key={step.key}
                  id={step.key}
                  title={getText(step.title)}
                  onCheck={toggleStep}
                  isChecked={checkedSteps.includes(step.key)}
                >
                  <CardItem>
                    <RichText>{step.content}</RichText>
                  </CardItem>
                </ExpandableCard>
              );
            })}
          </CardList>
        </Fragment>
      )}
    </>
  );
};

export default ExcerciseProgramProgress;
