import { QuizAssessmentLevel, QuizQuestion, QuizQuestionActive } from 'models';
import { IntlShape } from 'react-intl';
import { ThemeColor } from 'styles';

// Get active question by id
export function getActiveQuestionById(
  questions: QuizQuestion[],
  questionId: string | null
): QuizQuestionActive {
  const found = questions.findIndex((q) => q.id === questionId);
  const index = found > 0 ? found : 0;
  return {
    index,
    count: index + 1,
    prevId: index > 0 ? questions[index - 1].id : null,
    nextId: index + 1 >= questions.length ? null : questions[index + 1].id,
    question: questions[index],
  };
}

export function getQuizResultColor(level: QuizAssessmentLevel): ThemeColor {
  switch (level) {
    case 'good':
      return 'successDark';
    case 'approved':
      return 'success';
    case 'changeNeeded':
      return 'caution';
    case 'riskGroup':
      return 'error';
    default:
      return 'blue';
  }
}

export function getQuizResultText(
  level: QuizAssessmentLevel | undefined,
  intl: IntlShape
): string {
  switch (level) {
    case 'good':
      return intl.formatMessage({
        id: 'QuizResultGood',
        defaultMessage: 'Good',
        description: 'Text to display when quiz result is of value "good"',
      });
    case 'approved':
      return intl.formatMessage({
        id: 'QuizResultApproved',
        defaultMessage: 'OK',
        description: 'Text to display when quiz result is of value "approved"',
      });
    case 'changeNeeded':
      return intl.formatMessage({
        id: 'QuizResultChangeNeeded',
        defaultMessage: 'Room for\nimprovements',
        description:
          'Text to display when quiz result is of value "changeNeeded"',
      });
    case 'riskGroup':
      return intl.formatMessage({
        id: 'QuizResultRiskGroup',
        defaultMessage: 'Risk group',
        description: 'Text to display when quiz result is of value "riskGroup"',
      });
    default:
      return level ?? '?';
  }
}

export function getAssessmentLevel(scorePercentage: number): QuizAssessmentLevel {
  if (scorePercentage >= 75) {
    return 'good';
  } else if (scorePercentage >= 60) {
    return 'approved';
  } else if (scorePercentage >= 25) {
    return 'changeNeeded';
  } else {
    return 'riskGroup';
  }
}