import styled from 'styled-components';

export const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
`;

export const TableElement = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-radius: 16px;
  overflow: hidden;
  border: ${({ theme }) => `1px solid ${theme.colors.borderLight}`};
  background-color: ${({ theme }) => theme.colors.backgroundLight};
`;

export const TableHeader = styled.thead``;

export const TableHeaderCell = styled.th`
  text-align: left;
  padding: 12px;
  font-family: ${({ theme }) => theme.fontFamily};
  color: ${({ theme }) => theme.colors.black};
  font-size: 14px;
`;

export const TableBody = styled.tbody``;

export const TableRow = styled.tr<{ clickable?: boolean }>`
  background-color: ${({ theme }) => theme.colors.white};
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
  border-bottom: 1px solid ${({ theme }) => theme.colors.backgroundLight};
  transition: 0.2s ease-in-out;
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.backgroundLight};
  }
`;

export const TableCell = styled.td`
  padding: 16px;
  font-size: 12px;
  font-family: ${({ theme }) => theme.fontFamily};
`;
