import styled from 'styled-components';

export const List = styled.div`
  display: flex;
  flex-direction: column;
`;

type ActivityProps = {
  isLast: boolean;
};

export const Item = styled.div<ActivityProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 16px;
  border-bottom: ${({ theme, isLast }) =>
    !isLast ? `1px solid ${theme.colors.borderLight}` : 'none'};

  h4 {
    margin: 0;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ItemText = styled.div`
  color: ${({ theme }) => theme.colors.grayText};
  font-size: 14px;
`;

export const CardList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
`;