import styled from 'styled-components';
import { motion } from 'framer-motion';
import { ThemeColor } from 'styles';

export const Container = styled.div`
  height: 8px;
  background: ${({ theme }) => theme.colors.backgroundDark};
  border-radius: 4px;
  overflow: hidden;
  display: flex;
`;

type BarProps = {
  color: ThemeColor | string;
};

export const Bar = styled(motion.div)<BarProps>`
  background: ${({ color, theme }) =>
    color.startsWith('#') ? color : theme.colors[color as ThemeColor]};
  height: 8px;
`;
