import { useLocalizedText } from 'hooks/useLocalizedText';
import { FC, useCallback, useMemo } from 'react';
import { useTheme } from 'styled-components';
import {
  VictoryAxis,
  VictoryBar,
  VictoryBarProps,
  VictoryChart,
  VictoryContainer,
  VictoryGroup,
} from 'victory';
import { components } from 'generated/api';

export type HealthBarChartData = {
  /** Format: double */
  percentage?: number;
  color?: components['schemas']['Color'];
  resultTitle?: null | components['schemas']['Languages'];
};

type Props = {
  data: HealthBarChartData[];
  compareData?: HealthBarChartData[] | null;
  baselineData?: HealthBarChartData[] | null;
  fontSize?: number;
};

const HealthBarChart: FC<Props> = ({
  data,
  compareData,
  baselineData,
  fontSize = 12,
}) => {
  const theme = useTheme();

  const axisStyle = useMemo(
    () => ({
      tickLabels: {
        fill: theme.colors.grayText,
        fontSize: fontSize,
        padding: 5,
      },
      axis: {
        stroke: theme.colors.borderLight,
      },
    }),
    [fontSize, theme.colors.borderLight, theme.colors.grayText]
  );

  const getBarChartProps = useCallback(
    (
      data: Array<{
        percentage?: HealthBarChartData['percentage'];
        color?: HealthBarChartData['color'];
      }>,
      labelColor: string
    ): VictoryBarProps => ({
      style: {
        data: {
          fill: (chart) => chart.datum.color.hex,
          overflow: 'hidden',
        },
        labels: {
          fill: labelColor,
          fontSize: fontSize,
          angle: -45,
          textAnchor: 'start',
          verticalAnchor: 'middle',
          fontWeight: 'bold',
        },
      },
      labels: ({ datum }) => parseFloat(datum.y.toFixed(1)) + '%',
      cornerRadius: 4,
      data: data.map((item, i) => ({
        color: item.color,
        x: i + 1.05,
        y: item.percentage ?? 0,
      })),
    }),
    [fontSize]
  );

  const bars = useMemo(() => {
    if (
      (compareData == null || compareData.length === 0) &&
      (baselineData == null || baselineData.length === 0)
    ) {
      return <VictoryBar {...getBarChartProps(data, theme.colors.black)} />;
    }
    return (
      <VictoryGroup offset={27}>
        <VictoryBar {...getBarChartProps(data, theme.colors.black)} />
        {compareData != null && compareData.length > 0 && (
          <VictoryBar {...getBarChartProps(compareData, theme.colors.purple)} />
        )}
        {baselineData != null && baselineData.length > 0 && (
          <VictoryBar {...getBarChartProps(baselineData, theme.colors.blue)} />
        )}
      </VictoryGroup>
    );
  }, [
    compareData,
    baselineData,
    getBarChartProps,
    data,
    theme.colors.black,
    theme.colors.purple,
    theme.colors.blue,
  ]);

  const getText = useLocalizedText();

  const chartKey = useMemo(() => {
    // Force re-render chart when we toggle baseline/comparison bars. This is to prevent layout bug and get a cleaner animation.
    if (baselineData != null && compareData != null) {
      return 'all';
    } else if (baselineData != null) {
      return 'baseline';
    } else if (compareData != null) {
      return 'compare';
    }
    return 'single';
  }, [baselineData, compareData]);

  return (
    <>
      <VictoryChart
        domainPadding={{ x: 32 }}
        style={{ parent: { minHeight: 230 } }}
        containerComponent={
          <VictoryContainer
            style={{
              touchAction: 'auto',
            }}
          />
        }
        key={chartKey}
      >
        <VictoryAxis
          dependentAxis
          style={{
            tickLabels: {
              fontSize: fontSize,
              fill: theme.colors.grayText,
            },
            axis: {
              stroke: theme.colors.borderLight,
            },
          }}
        />
        <VictoryAxis
          tickValues={data.map((_, i) => i + 1)}
          tickFormat={data.map((item) =>
            getText(item.resultTitle).split(' ').join('\n')
          )}
          style={axisStyle}
          fixLabelOverlap={true}
        />
        {bars}
      </VictoryChart>
    </>
  );
};

export default HealthBarChart;
