import styled from 'styled-components';
import { breakpoints } from 'styles';

export const CategoryHeader = styled.div`
  h1 {
    margin: 0;
  }
`;

export const CardList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  margin-top: 40px;

  @media (max-width: ${breakpoints.s}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
