import { useWindowSize } from 'hooks/useWindowSize';
import { FC } from 'react';
import { breakpoints, ThemeColor } from 'styles';
import { Circle, Group, Line, Path, Rect } from './styles';

export enum IconType {
  Search,
  Back,
  Briefcase,
  Home,
  Food,
  Health,
  HealthReport,
  HealthIndex,
  Exercise,
  Competition,
  Info,
  Inspiration,
  Favorites,
  FavoritesOutline,
  Tests,
  Users,
  User,
  Menu,
  Close,
  Departments,
  Goals,
  Arrow,
  More,
  Settings,
  Muscle,
  Globe,
  Chat,
  Send,
  Filter,
  Remove,
  Upload,
  Edit,
  Flag,
  CheckeredFlag,
  Footprints,
  ExternalLink,
  Trash,
  BarChart,
  DownArrowCircle,
  UpArrowCircle,
  PlusCircle,
  CheckCircle,
  Umbrella,
  ArrowUpRight,
  Pulse,
  Refresh,
  AlertCircle,
  ArrowLeft,
  Triangle,
  MapSettings,
  HealthPlan,
  ArrowUpLeft,
  Reminder,
  LockClosed,
  LockOpen,
  Circle
}

type Props = {
  type: IconType;
  color?: ThemeColor;
  size?: number;
  hoverColor?: ThemeColor;
  isHovering?: boolean;
};

// Render icon
function renderIcon(color: ThemeColor) {
  return {
    [IconType.Footprints]: (
      <>
        <Path
          d="M13.674 14.902c.12-.494.21-.995.267-1.5l3.668.544c-.21 2.85-1.045 4.668-2.809 4.238-1.864-.332-1.494-1.628-1.126-3.282zM16.06 1.214c2.77.352 3.605 4.422 3.083 7.066-.14.7-1.396 3.983-1.574 4.66l-3.64-.54C12.472 5.797 13.77 1.189 16.06 1.214zM9.2 22.721c-1.768.429-2.606-1.39-2.809-4.237l3.661-.544c.057.505.146 1.006.268 1.5.376 1.647.746 2.947-1.12 3.281zM10.073 16.938l-3.64.54c-.18-.678-1.434-3.962-1.574-4.66-.53-2.646.312-6.715 3.08-7.07 2.293-.015 3.589 4.584 2.134 11.19z"
          fill={color}
        />
      </>
    ),
    [IconType.CheckeredFlag]: (
      <>
        <Path
          d="M4 22v-7M20 11s-1 1-4 1-5-2-8-2-4 1-4 1m16-4s-1 1-4 1-5-2-8-2-4 1-4 1m0 8s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1v12z"
          stroke={color}
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M8 2v12M12 3v12m4-11v11.5"
          stroke={color}
          strokeWidth={2}
          strokeLinecap="round"
        />
      </>
    ),
    [IconType.HealthPlan]: (
      <>
        <Path
          style={{ transform: 'translateX(4px)' }}
          d="M11.3486 2.16666L11.4664 2.5H11.82H13C13.663 2.5 14.2989 2.76339 14.7678 3.23223C15.2366 3.70107 15.5 4.33696 15.5 5V17C15.5 17.663 15.2366 18.2989 14.7678 18.7678C14.2989 19.2366 13.663 19.5 13 19.5H3C2.33696 19.5 1.70107 19.2366 1.23223 18.7678C0.763392 18.2989 0.5 17.663 0.5 17V5C0.5 4.33696 0.763392 3.70107 1.23223 3.23223C1.70107 2.76339 2.33696 2.5 3 2.5H4.18H4.53356L4.65141 2.16666C4.82333 1.68037 5.14146 1.25916 5.56218 0.96078C5.98272 0.662526 6.48518 0.501587 7.00074 0.5H8.99926C9.51482 0.501587 10.0173 0.662526 10.4378 0.96078C10.8585 1.25916 11.1767 1.68037 11.3486 2.16666ZM10 4.5H10.5V4V3C10.5 2.60217 10.342 2.22064 10.0607 1.93934C9.77936 1.65804 9.39783 1.5 9 1.5H7C6.60218 1.5 6.22064 1.65804 5.93934 1.93934C5.65804 2.22064 5.5 2.60217 5.5 3V4V4.5H6H10ZM4.5 4V3.5H4H3C2.60217 3.5 2.22064 3.65804 1.93934 3.93934C1.65804 4.22064 1.5 4.60218 1.5 5V17C1.5 17.3978 1.65804 17.7794 1.93934 18.0607C2.22065 18.342 2.60218 18.5 3 18.5H13C13.3978 18.5 13.7794 18.342 14.0607 18.0607C14.342 17.7794 14.5 17.3978 14.5 17V5C14.5 4.60218 14.342 4.22064 14.0607 3.93934C13.7794 3.65804 13.3978 3.5 13 3.5H12H11.5V4V5C11.5 5.13261 11.4473 5.25978 11.3536 5.35355C11.2598 5.44732 11.1326 5.5 11 5.5H5C4.86739 5.5 4.74022 5.44732 4.64645 5.35355C4.55268 5.25978 4.5 5.13261 4.5 5V4Z"
          stroke={color}
          strokeWidth={1}
          strokeLinecap="round"
        />
      </>
    ),
    [IconType.Reminder]: (
      <>
        <Path d="M9.00195 17H5.60636C4.34793 17 3.71872 17 3.58633 16.9023C3.4376 16.7925 3.40126 16.7277 3.38515 16.5436C3.37082 16.3797 3.75646 15.7486 4.52776 14.4866C5.32411 13.1835 6.00031 11.2862 6.00031 8.6C6.00031 7.11479 6.63245 5.69041 7.75766 4.6402C8.88288 3.59 10.409 3 12.0003 3C13.5916 3 15.1177 3.59 16.2429 4.6402C17.3682 5.69041 18.0003 7.11479 18.0003 8.6C18.0003 11.2862 18.6765 13.1835 19.4729 14.4866C20.2441 15.7486 20.6298 16.3797 20.6155 16.5436C20.5994 16.7277 20.563 16.7925 20.4143 16.9023C20.2819 17 19.6527 17 18.3943 17H15.0003M9.00195 17L9.00031 18C9.00031 19.6569 10.3435 21 12.0003 21C13.6572 21 15.0003 19.6569 15.0003 18V17M9.00195 17H15.0003"
          stroke={color}
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round" />
      </>
    ),
    [IconType.Flag]: (
      <>
        <Path
          d="M4 22v-7M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1v12z"
          stroke={color}
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ),
    [IconType.Home]: (
      <>
        <Path
          d="M3 9L12 2L21 9V20C21 20.5304 20.7893 21.0391 20.4142 21.4142C20.0391 21.7893 19.5304 22 19 22H5C4.46957 22 3.96086 21.7893 3.58579 21.4142C3.21071 21.0391 3 20.5304 3 20V9Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M9 22V12H15V22"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ),
    [IconType.Briefcase]: (
      <>
        <Path
          d="M20 7H4a2 2 0 00-2 2v10a2 2 0 002 2h16a2 2 0 002-2V9a2 2 0 00-2-2z"
          stroke={color}
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M16 21V5a2 2 0 00-2-2h-4a2 2 0 00-2 2v16"
          stroke={color}
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ),
    [IconType.Menu]: (
      <>
        <Path
          d="M21 6L3 6.00001"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M21 12L9 12"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M21 18L3 18"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ),
    [IconType.Close]: (
      <>
        <Path
          d="M18 6L6 18"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M6 6L18 18"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ),
    [IconType.HealthReport]: (
      <>
        <Path
          d="M21.2099 15.89C20.5737 17.3944 19.5787 18.7202 18.3118 19.7513C17.0449 20.7823 15.5447 21.4874 13.9424 21.8047C12.34 22.1221 10.6843 22.0421 9.12006 21.5718C7.55578 21.1014 6.13054 20.255 4.96893 19.1066C3.80733 17.9582 2.94473 16.5427 2.45655 14.9839C1.96837 13.4251 1.86948 11.7704 2.16851 10.1646C2.46755 8.55874 3.15541 7.05058 4.17196 5.77199C5.18851 4.49339 6.5028 3.48327 7.99992 2.82996"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2V12H22Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ),
    [IconType.Health]: (
      <>
        <Path
          d="M22 12C22 13.3132 21.7413 14.6136 21.2388 15.8268C20.7362 17.0401 19.9997 18.1425 19.0711 19.0711C18.1425 19.9997 17.0401 20.7362 15.8268 21.2388C14.6136 21.7413 13.3132 22 12 22L12 12H22Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M12 22C10.6868 22 9.38642 21.7413 8.17316 21.2388C6.95991 20.7362 5.85752 19.9997 4.92893 19.0711C4.00035 18.1425 3.26375 17.0401 2.7612 15.8268C2.25866 14.6136 2 13.3132 2 12L12 12L12 22Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M2 8C2 6.4087 2.63214 4.88258 3.75736 3.75736C4.88258 2.63214 6.4087 2 8 2L8 8L2 8Z"
          fill={color}
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M12 2C13.3132 2 14.6136 2.25866 15.8268 2.76121C17.0401 3.26375 18.1425 4.00035 19.0711 4.92893C19.9997 5.85752 20.7363 6.95991 21.2388 8.17317C21.7413 9.38643 22 10.6868 22 12L12 12L12 2Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ),
    [IconType.Exercise]: (
      <>
        <Path
          d="M19 21V17C19 16.4477 18.5523 16 18 16H12.3205C12.112 16 11.9088 16.0651 11.7392 16.1863L5 21"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <Path
          d="M12 16V10"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <Circle
          cx="12"
          cy="5"
          r="2"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M4 10H20"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </>
    ),
    [IconType.Competition]: (
      <>
        <Path
          d="M4 22V15"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M4 15C4 15 5 14 8 14C11 14 13 16 16 16C19 16 20 15 20 15V3C20 3 19 4 16 4C13 4 11 2 8 2C5 2 4 3 4 3V15Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ),
    [IconType.Food]: (
      <>
        <Path
          d="M3 14H21V15C21 18.3137 18.3137 21 15 21H9C5.68629 21 3 18.3137 3 15V14Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M9.23217 10.5981L9.73217 9.73204C10.2845 8.77545 9.9567 7.55227 9.00012 6.99999V6.99999C8.04353 6.4477 7.71578 5.22452 8.26807 4.26793L8.76807 3.40191"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <Path
          d="M15.2322 10.5981L15.7322 9.73204C16.2845 8.77545 15.9567 7.55227 15.0001 6.99999V6.99999C14.0435 6.4477 13.7158 5.22452 14.2681 4.26793L14.7681 3.40191"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </>
    ),
    [IconType.Info]: (
      <>
        <Path
          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M12 16V12"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M12 8H12.01"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ),
    [IconType.Inspiration]: (
      <>
        <Path
          d="M12 21V23"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M18.36 18.36L19.78 19.78"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M4.21997 19.78L5.63997 18.36"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M21 12H23"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M1 12H3"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M18.36 5.64L19.78 4.22"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M4.21997 4.22L5.63997 5.64"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M12 1V3"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ),
    [IconType.FavoritesOutline]: (
      <Path
        d="M20.84 4.61C20.3292 4.09901 19.7228 3.69365 19.0554 3.41709C18.3879 3.14052 17.6725 2.99818 16.95 2.99818C16.2275 2.99818 15.5121 3.14052 14.8446 3.41709C14.1772 3.69365 13.5708 4.09901 13.06 4.61L12 5.67L10.94 4.61C9.9083 3.57831 8.50903 2.99871 7.05 2.99871C5.59096 2.99871 4.19169 3.57831 3.16 4.61C2.1283 5.64169 1.54871 7.04097 1.54871 8.5C1.54871 9.95903 2.1283 11.3583 3.16 12.39L4.22 13.45L12 21.23L19.78 13.45L20.84 12.39C21.351 11.8792 21.7563 11.2728 22.0329 10.6054C22.3095 9.9379 22.4518 9.22249 22.4518 8.5C22.4518 7.77751 22.3095 7.06211 22.0329 6.39465C21.7563 5.72719 21.351 5.12076 20.84 4.61V4.61Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    ),
    [IconType.Favorites]: (
      <Path
        d="M20.84 4.61C20.3292 4.09901 19.7228 3.69365 19.0554 3.41709C18.3879 3.14052 17.6725 2.99818 16.95 2.99818C16.2275 2.99818 15.5121 3.14052 14.8446 3.41709C14.1772 3.69365 13.5708 4.09901 13.06 4.61L12 5.67L10.94 4.61C9.9083 3.57831 8.50903 2.99871 7.05 2.99871C5.59096 2.99871 4.19169 3.57831 3.16 4.61C2.1283 5.64169 1.54871 7.04097 1.54871 8.5C1.54871 9.95903 2.1283 11.3583 3.16 12.39L4.22 13.45L12 21.23L19.78 13.45L20.84 12.39C21.351 11.8792 21.7563 11.2728 22.0329 10.6054C22.3095 9.9379 22.4518 9.22249 22.4518 8.5C22.4518 7.77751 22.3095 7.06211 22.0329 6.39465C21.7563 5.72719 21.351 5.12076 20.84 4.61V4.61Z"
        fill={color}
      />
    ),
    [IconType.Users]: (
      <>
        <Path
          d="M23 21V19C22.9993 18.1137 22.7044 17.2528 22.1614 16.5523C21.6184 15.8519 20.8581 15.3516 20 15.13"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M17 21V19C17 17.9391 16.5786 16.9217 15.8284 16.1716C15.0783 15.4214 14.0609 15 13 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M16 3.13C16.8604 3.35031 17.623 3.85071 18.1676 4.55232C18.7122 5.25392 19.0078 6.11683 19.0078 7.005C19.0078 7.89318 18.7122 8.75608 18.1676 9.45769C17.623 10.1593 16.8604 10.6597 16 10.88"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M9 11C11.2091 11 13 9.20914 13 7C13 4.79086 11.2091 3 9 3C6.79086 3 5 4.79086 5 7C5 9.20914 6.79086 11 9 11Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ),
    [IconType.User]: (
      <>
        <Path
          d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ),
    [IconType.Back]: (
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <Path
          d="m16 20-8-8 8-8"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    ),
    [IconType.Search]: (
      <g fill="none">
        <Path
          d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          data-testid="icon-search"
        />
        <Path
          d="M21 21L16.65 16.65"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    ),
    [IconType.Tests]: (
      <>
        <Path
          d="M16 4H18C18.5304 4 19.0391 4.21071 19.4142 4.58579C19.7893 4.96086 20 5.46957 20 6V20C20 20.5304 19.7893 21.0391 19.4142 21.4142C19.0391 21.7893 18.5304 22 18 22H6C5.46957 22 4.96086 21.7893 4.58579 21.4142C4.21071 21.0391 4 20.5304 4 20V6C4 5.46957 4.21071 4.96086 4.58579 4.58579C4.96086 4.21071 5.46957 4 6 4H8"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M15 2H9C8.44772 2 8 2.44772 8 3V5C8 5.55228 8.44772 6 9 6H15C15.5523 6 16 5.55228 16 5V3C16 2.44772 15.5523 2 15 2Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ),
    [IconType.Departments]: (
      <>
        <Rect
          x="8"
          y="3"
          width="8"
          height="6"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Rect
          x="2"
          y="15"
          width="8"
          height="6"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Rect
          x="14"
          y="15"
          width="8"
          height="6"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M12 9V12H6V15"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M12 9V12H18V15"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ),
    [IconType.Goals]: (
      <>
        <Path
          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ),
    [IconType.Arrow]: (
      <Path
        d="M9 18L15 12L9 6"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    ),
    [IconType.More]: (
      <>
        <Path
          d="M12 7.5C12.8284 7.5 13.5 6.82843 13.5 6C13.5 5.17157 12.8284 4.5 12 4.5C11.1716 4.5 10.5 5.17157 10.5 6C10.5 6.82843 11.1716 7.5 12 7.5Z"
          fill={color}
        />
        <Path
          d="M12 19.5C12.8284 19.5 13.5 18.8284 13.5 18C13.5 17.1716 12.8284 16.5 12 16.5C11.1716 16.5 10.5 17.1716 10.5 18C10.5 18.8284 11.1716 19.5 12 19.5Z"
          fill={color}
        />
        <Path
          d="M12 13.5C12.8284 13.5 13.5 12.8284 13.5 12C13.5 11.1716 12.8284 10.5 12 10.5C11.1716 10.5 10.5 11.1716 10.5 12C10.5 12.8284 11.1716 13.5 12 13.5Z"
          fill={color}
        />
      </>
    ),
    [IconType.Settings]: (
      <>
        <Group
          clip-path="url(#a)"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
          <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a1.998 1.998 0 0 1 0 2.83 1.998 1.998 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-4 0v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a1.998 1.998 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 1 1 0-4h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 1 1 2.83-2.83l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 1 1 4 0v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 3.417 1.415 2 2 0 0 1-.587 1.415l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 0 4h-.09a1.65 1.65 0 0 0-1.51 1v0Z" />
        </Group>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0h24v24H0z" />
          </clipPath>
        </defs>
      </>
    ),
    [IconType.MapSettings]: (
      <>
        <Group
          clip-path="url(#a)"
          stroke={color}
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
          <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a1.998 1.998 0 0 1 0 2.83 1.998 1.998 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-4 0v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a1.998 1.998 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 1 1 0-4h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 1 1 2.83-2.83l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 1 1 4 0v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 3.417 1.415 2 2 0 0 1-.587 1.415l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 0 4h-.09a1.65 1.65 0 0 0-1.51 1v0Z" />
        </Group>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0h24v24H0z" />
          </clipPath>
        </defs>
      </>
    ),
    [IconType.Muscle]: (
      <Path
        d="M3.87273 23.3904C3.96581 23.3952 4.0577 23.4135 4.14546 23.4449C6.19877 23.8039 8.27912 23.9862 10.3636 23.9899C12.4176 24.06 14.4677 23.7648 16.4182 23.1179C17.1086 22.8476 17.7254 22.4181 18.2182 21.8645C18.3214 21.7655 18.4571 21.7074 18.6 21.701H18.7636C20.4744 22.1458 22.2324 22.3836 24 22.4095V12.3165C23.5571 12.0911 23.1019 11.891 22.6364 11.717C22.0038 11.5027 21.3407 11.3923 20.6727 11.39C19.7857 11.3823 18.9083 11.5742 18.1057 11.9516C17.303 12.3289 16.5958 12.8821 16.0364 13.5699C15.9699 13.6476 15.8855 13.708 15.7905 13.746C15.6955 13.784 15.5927 13.7984 15.4909 13.7879C14.2547 13.456 12.9408 13.5719 11.7818 14.1149C10.5212 14.7189 9.34715 15.4887 8.29091 16.4038C8.21308 16.4599 8.12412 16.4986 8.03002 16.5174C7.93592 16.5363 7.83889 16.5347 7.74545 16.5128C7.6574 16.4752 7.58081 16.4152 7.52336 16.3386C7.46591 16.2621 7.42964 16.1718 7.41818 16.0768C7.02259 12.3228 6.42169 8.59321 5.61818 4.90479C5.59378 4.8292 5.59107 4.74828 5.61035 4.67122C5.62963 4.59416 5.67013 4.52404 5.72727 4.46881C5.77229 4.40799 5.82948 4.3572 5.89522 4.31967C5.96095 4.28214 6.03379 4.25869 6.10909 4.25082C6.68006 4.21345 7.24622 4.12221 7.8 3.97833C8.34114 3.78667 8.78944 3.39719 9.05455 2.88838C9.15747 2.66768 9.2153 2.42869 9.22467 2.1854C9.23403 1.9421 9.19474 1.69938 9.10909 1.47144C8.9455 1.0397 8.65448 0.667813 8.27459 0.405042C7.89469 0.142271 7.44386 0.00101867 6.98182 0C6.72416 0.00951612 6.46821 0.0460488 6.21818 0.108996C4.96364 0.544978 2.34545 2.72489 2.18182 3.86934L2.12727 4.08733C0.818182 13.0795 -1.73395e-07 18.9652 -1.73395e-07 19.8372C-1.73395e-07 21.81 1.74546 23.3904 3.87273 23.3904Z"
        fill={color}
      />
    ),
    [IconType.HealthIndex]: (
      <Path
        d="M17.3333 2.00001C16.2985 1.99826 15.2776 2.23424 14.3517 2.68917C13.4258 3.1441 12.6206 3.80544 12 4.62058C11.1595 3.51976 9.98865 2.707 8.65308 2.29729C7.31751 1.88759 5.88487 1.90168 4.55789 2.33759C3.2309 2.7735 2.07677 3.60914 1.25881 4.72627C0.440848 5.8434 0.00047277 7.18544 0 8.56251C0.00707802 9.60633 0.192235 10.6416 0.547743 11.625H5.30208L6.81597 9.38965C6.89714 9.2698 7.00711 9.17152 7.13613 9.10354C7.26515 9.03557 7.40922 9.00001 7.55556 9.00001C7.70189 9.00001 7.84596 9.03557 7.97498 9.10354C8.104 9.17152 8.21397 9.2698 8.29514 9.38965L11.1111 13.5476L12.1493 12.0147C12.2305 11.8948 12.3404 11.7965 12.4695 11.7285C12.5985 11.6606 12.7426 11.625 12.8889 11.625H15.5556C15.7913 11.625 16.0174 11.7172 16.1841 11.8813C16.3508 12.0454 16.4444 12.2679 16.4444 12.5C16.4444 12.7321 16.3508 12.9546 16.1841 13.1187C16.0174 13.2828 15.7913 13.375 15.5556 13.375H13.3646L11.8507 15.6104C11.7695 15.7302 11.6596 15.8285 11.5305 15.8965C11.4015 15.9644 11.2574 16 11.1111 16C10.9648 16 10.8207 15.9644 10.6917 15.8965C10.5627 15.8285 10.4527 15.7302 10.3715 15.6104L7.55556 11.4525L6.51736 12.9854C6.4362 13.1052 6.32622 13.2035 6.1972 13.2715C6.06819 13.3394 5.92411 13.375 5.77778 13.375H1.33154C4.29275 18.823 11.1953 22.6843 11.5656 22.8884C11.6984 22.9616 11.8479 23 12 23C12.1521 23 12.3016 22.9616 12.4344 22.8884C14.4746 21.7121 16.3895 20.3368 18.1502 18.7832C22.0318 15.3443 24 11.9055 24 8.56251C23.998 6.82263 23.295 5.15458 22.0452 3.9243C20.7954 2.69402 19.1008 2.00198 17.3333 2.00001V2.00001Z"
        fill={color}
      />
    ),
    [IconType.Globe]: (
      <Path
        d="M23.2904 7.9562C23.2904 7.9562 23.2904 7.9562 23.2904 7.89603C22.4432 5.58074 20.906 3.58178 18.8867 2.16956C16.8673 0.757335 14.4634 0 12 0C9.53661 0 7.13266 0.757335 5.11335 2.16956C3.09403 3.58178 1.5568 5.58074 0.709596 7.89603C0.709596 7.89603 0.709596 7.89603 0.709596 7.9562C-0.236532 10.5689 -0.236532 13.4311 0.709596 16.0438C0.709596 16.0438 0.709596 16.0438 0.709596 16.104C1.5568 18.4193 3.09403 20.4182 5.11335 21.8304C7.13266 23.2427 9.53661 24 12 24C14.4634 24 16.8673 23.2427 18.8867 21.8304C20.906 20.4182 22.4432 18.4193 23.2904 16.104C23.2904 16.104 23.2904 16.104 23.2904 16.0438C24.2365 13.4311 24.2365 10.5689 23.2904 7.9562V7.9562ZM2.66949 14.407C2.25253 12.8295 2.25253 11.1705 2.66949 9.59298H4.90592C4.71358 11.1919 4.71358 12.8081 4.90592 14.407H2.66949ZM3.65544 16.814H5.33878C5.621 17.8874 6.0244 18.9251 6.54117 19.9071C5.36199 19.103 4.37627 18.0465 3.65544 16.814ZM5.33878 7.18596H3.65544C4.3659 5.95727 5.33901 4.90105 6.5051 4.09293C6.00076 5.0765 5.60949 6.11413 5.33878 7.18596V7.18596ZM10.7736 21.267C9.29675 20.1823 8.25956 18.6015 7.85177 16.814H10.7736V21.267ZM10.7736 14.407H7.33474C7.11037 12.8102 7.11037 11.1898 7.33474 9.59298H10.7736V14.407ZM10.7736 7.18596H7.85177C8.25956 5.39846 9.29675 3.81771 10.7736 2.73297V7.18596ZM20.2965 7.18596H18.6131C18.3309 6.11262 17.9275 5.07493 17.4107 4.09293C18.5899 4.89696 19.5756 5.95349 20.2965 7.18596ZM13.1783 2.73297C14.6552 3.81771 15.6924 5.39846 16.1001 7.18596H13.1783V2.73297ZM13.1783 21.267V16.814H16.1001C15.6924 18.6015 14.6552 20.1823 13.1783 21.267V21.267ZM16.6172 14.407H13.1783V9.59298H16.6172C16.8415 11.1898 16.8415 12.8102 16.6172 14.407ZM17.4468 19.9071C17.9636 18.9251 18.367 17.8874 18.6492 16.814H20.3325C19.6117 18.0465 18.626 19.103 17.4468 19.9071ZM21.2824 14.407H19.046C19.1438 13.6084 19.192 12.8046 19.1903 12C19.1916 11.1955 19.1434 10.3916 19.046 9.59298H21.2824C21.6994 11.1705 21.6994 12.8295 21.2824 14.407Z"
        fill={color}
      />
    ),
    [IconType.Chat]: (
      <>
        <Path
          d="M21 15C21 15.5304 20.7893 16.0391 20.4142 16.4142C20.0391 16.7893 19.5304 17 19 17H7L3 21V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H19C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V15Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Line
          x1="6.75"
          y1="8"
          x2="17.25"
          y2="8"
          stroke={color}
          strokeWidth="2"
        />
        <Line
          x1="6.75"
          y1="11.75"
          x2="14.25"
          y2="11.75"
          stroke={color}
          strokeWidth="2"
        />
      </>
    ),
    [IconType.Send]: (
      <>
        <Path
          d="M22 2L11 13"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M22 2L15 22L11 13L2 9L22 2Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ),
    [IconType.Filter]: (
      <>
        <Path
          d="M3.5 8H6.5"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <Path
          d="M21.5 16L18.5 16"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <Path
          d="M10.5 8H21.5"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <Path
          d="M14.5 16L3.5 16"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <Circle
          cx="8.5"
          cy="8"
          r="2"
          fill="white"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <Circle
          cx="16.5"
          cy="16"
          r="2"
          transform="rotate(180 16.5 16)"
          fill="white"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </>
    ),
    [IconType.Remove]: (
      <>
        <Path
          d="M3 6H5H21"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6H19Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ),
    [IconType.Upload]: (
      <>
        <Path
          d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M17 8L12 3L7 8"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M12 3V15"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ),
    [IconType.Edit]: (
      <>
        <Path
          d="M16.1038 4.66848C16.3158 4.45654 16.5674 4.28843 16.8443 4.17373C17.1212 4.05903 17.418 4 17.7177 4C18.0174 4 18.3142 4.05903 18.5911 4.17373C18.868 4.28843 19.1196 4.45654 19.3315 4.66848C19.5435 4.88041 19.7116 5.13201 19.8263 5.40891C19.941 5.68582 20 5.9826 20 6.28232C20 6.58204 19.941 6.87882 19.8263 7.15573C19.7116 7.43263 19.5435 7.68423 19.3315 7.89617L8.43807 18.7896L4 20L5.21038 15.5619L16.1038 4.66848Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M14.291 6.24884L17.7512 9.70906"
          stroke={color}
          strokeWidth="2"
        />
      </>
    ),
    [IconType.ExternalLink]: (
      <>
        <Path
          d="M18 13v6a2 2 0 01-2 2H5a2 2 0 01-2-2V8a2 2 0 012-2h6M15 3h6v6M10 14L21 3"
          stroke={color}
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ),
    [IconType.Trash]: (
      <>
        <Path
          d="M3 6H5H21"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6H19Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M14 11V17"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M10 11V17"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ),
    [IconType.BarChart]: (
      <>
        <Path
          d="M6 20V16"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M12 20V10"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M18 20V4"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ),
    [IconType.PlusCircle]: (
      <>
        <Path
          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M12 8V16"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M8 12H16"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ),
    [IconType.UpArrowCircle]: (
      <>
        <Path
          d="M12 8V16"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M16 12L12 8L8 12"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M12 16V8"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Circle
          cx="12"
          cy="12"
          r="10"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ),
    [IconType.DownArrowCircle]: (
      <>
        <Path
          d="M12 8V16"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M16 12L12 16L8 12"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M12 16V8"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Circle
          cx="12"
          cy="12"
          r="10"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ),
    [IconType.Circle]: (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    ),
    [IconType.CheckCircle]: (
      <>
        <Path
          d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M22 4L12 14.01L9 11.01"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ),
    [IconType.Umbrella]: (
      <>
        <Path
          d="M18 19C18 19.7956 17.6839 20.5587 17.1213 21.1213C16.5587 21.6839 15.7956 22 15 22C14.2044 22 13.4413 21.6839 12.8787 21.1213C12.3161 20.5587 12 19.7956 12 19V12M23 12C22.7388 9.26357 21.467 6.72275 19.433 4.87366C17.399 3.02458 14.7489 2 12 2C9.25114 2 6.60096 3.02458 4.56697 4.87366C2.53297 6.72275 1.2612 9.26357 1 12H23Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ),
    [IconType.ArrowUpRight]: (
      <>
        <Path
          d="M7 17L17 7"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M7 7H17V17"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ),
    [IconType.ArrowUpLeft]: (
      <>
        <Path
          d="M17 17L7 7"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M17 7H7V17"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ),
    [IconType.Pulse]: (
      <>
        <Path
          d="M22 12H18L15 21L9 3L6 12H2"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ),
    [IconType.Refresh]: (
      <>
        <Path
          d="M23 4V10H17"
          stroke={color}
          strokeWidth="3.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M20.49 15C19.84 16.8399 18.6096 18.4187 16.9842 19.4985C15.3588 20.5783 13.4265 21.1006 11.4784 20.9866C9.53038 20.8726 7.67216 20.1286 6.18376 18.8667C4.69536 17.6047 3.65743 15.8932 3.22637 13.9901C2.79531 12.0869 2.99448 10.0952 3.79386 8.31507C4.59325 6.53495 5.94954 5.06286 7.65836 4.12064C9.36717 3.17841 11.3359 2.8171 13.268 3.09114C15.2 3.36518 16.9906 4.25974 18.37 5.64L23 10"
          stroke={color}
          strokeWidth="3.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ),
    [IconType.AlertCircle]: (
      <>
        <Path
          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M12 16H12.01"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M12 8V12"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ),
    [IconType.Triangle]: (
      <>
        <Path
          d="M10.29 3.86L1.82002 18C1.64539 18.3024 1.55299 18.6453 1.55201 18.9945C1.55103 19.3437 1.64151 19.6871 1.81445 19.9905C1.98738 20.2939 2.23675 20.5467 2.53773 20.7239C2.83871 20.901 3.18082 20.9962 3.53002 21H20.47C20.8192 20.9962 21.1613 20.901 21.4623 20.7239C21.7633 20.5467 22.0127 20.2939 22.1856 19.9905C22.3585 19.6871 22.449 19.3437 22.448 18.9945C22.4471 18.6453 22.3547 18.3024 22.18 18L13.71 3.86C13.5318 3.56611 13.2807 3.32312 12.9812 3.15448C12.6817 2.98585 12.3438 2.89726 12 2.89726C11.6563 2.89726 11.3184 2.98585 11.0188 3.15448C10.7193 3.32312 10.4683 3.56611 10.29 3.86V3.86Z"
          stroke={color}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ),
    [IconType.ArrowLeft]: (
      <Path
        d="M15 18L9 12L15 6"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    ),
    [IconType.LockClosed]: (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 11H5C3.89543 11 3 11.8954 3 13V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V13C21 11.8954 20.1046 11 19 11Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M7 11V7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V11" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    ),

    [IconType.LockOpen]: (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 11H5C3.89543 11 3 11.8954 3 13V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V13C21 11.8954 20.1046 11 19 11Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M7 11V7C6.99876 5.76005 7.45828 4.56387 8.28938 3.64367C9.12047 2.72347 10.2638 2.1449 11.4975 2.02029C12.7312 1.89568 13.9671 2.2339 14.9655 2.96931C15.9638 3.70472 16.6533 4.78485 16.9 6" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    )
  };
}

const Icon: FC<Props> = ({
  type,
  color = 'black',
  size = 24,
  isHovering = false,
  hoverColor,
}) => {
  const { width } = useWindowSize();
  const isDesktop = width >= breakpoints.l;
  return (
    <svg data-testid="svg-icon" width={size} height={size} viewBox="0 0 24 24">
      {
        renderIcon(
          isDesktop && isHovering && hoverColor != null ? hoverColor : color
        )[type]
      }
    </svg>
  );
};

export default Icon;
