import styled from 'styled-components';
import { breakpoints } from 'styles';

export const CardList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const HeroGrid = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 48px;
`;

export const HeroCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  h1 {
    font-size: 48px;
    margin: 0;
  }

  p {
    color: ${({ theme }) => theme.colors.white};
    font-size: 14px;
    font-weight: 500;
    margin: 32px 0 0;
  }
`;

export const HeroActions = styled.div`
  display: flex;
  gap: 8px;

  @media (max-width: ${breakpoints.l}px) {
    flex-direction: column;
  }
`;
