import styled from 'styled-components';
import { ThemeColor } from 'styles';

type CardProps = {
  background: ThemeColor;
};

type ContentProps = {
  textColor: ThemeColor;
};
export const Card = styled.div<CardProps>`
  position: relative;
  overflow: hidden;
  background: ${({ background, theme }) => theme.colors[background]};
  border-radius: 16px;
  font-size: 16px;
  padding: 24px;
  margin-top: 2em;
`;

export const Content = styled.div<ContentProps>`
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 200px;
  color: ${({ textColor, theme }) => theme.colors[textColor]};
`;

export const ContentHeader = styled.div`
  h4 {
    margin: 0;
  }
`;

export const ContentBody = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  height: 100%; /* Ensure it takes the full height of the card */

  /* Hide scrollbar */
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  h2 {
    font-size: 48px;
    margin: 0 0 8px;
  }

  p {
    margin: 0;
  }
`;

export const BackgroundIcon = styled.div`
  position: absolute;
  top: 24px;
  right: -24px;
  bottom: 24px;

  svg {
    width: 100%;
    height: 100%;
  }
`;
