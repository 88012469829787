import { StepGoal, StepGoalQuery } from 'models';
import { getTimezoneOffset } from 'utils/date';
import { baseApi } from '../baseApi';

const goalApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getStepGoal: builder.query<StepGoal, void>({
      query: () =>
        `/goal/daily-steps?minutesOffsetFromUtc=${getTimezoneOffset()}`,
      providesTags: ['StepGoal'],
    }),
    updateStepGoal: builder.mutation<void, StepGoalQuery>({
      query: ({ dailyStepGoal }) => ({
        url: `/goal/daily-steps?dailyStepGoal=${dailyStepGoal}`,
        method: 'PATCH',
      }),
      invalidatesTags: ['StepGoal'],
    }),
    deleteStepGoal: builder.mutation<void, void>({
      query: () => ({
        url: '/goal/daily-steps',
        method: 'DELETE',
      }),
      invalidatesTags: ['StepGoal'],
    }),
  }),
});

export const {
  useGetStepGoalQuery,
  useUpdateStepGoalMutation,
  useDeleteStepGoalMutation,
} = goalApi;
