import React, { FC, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';

import Button from 'components/UI/Button/Button';
import TextField from 'components/UI/TextField/TextField';

import { ActionContainer } from 'styles';
import SelectField from 'components/UI/SelectField';
import { useGetCompanyStructureQuery } from 'store';
import { SelectOption } from 'models';
import { FieldGrid } from './styles';
import Loader from 'components/UI/Loader';
import { usePretendCompany } from 'hooks/usePretendCompany';
import ConfirmActionPopup from 'components/popups/ConfirmActionPopup';
import Heading, { Tag } from 'components/UI/Heading';

type Props = {
  submitForm: (data: CreateOfficeFormData) => void;
  onDelete?: () => void;
  office?: {
    id: string;
    headOffice: string | null | undefined;
    description: string | null | undefined;
  };
};

export type CreateOfficeFormData = {
  headOffice: string;
  officeName: string;
};

const CreateUpdateOfficeForm: FC<Props> = ({
  submitForm,
  office,
  onDelete,
}) => {
  const intl = useIntl();
  const companyId = usePretendCompany();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isDirty },
  } = useForm<CreateOfficeFormData>({
    defaultValues: {
      headOffice: office?.headOffice ?? '',
      officeName: office?.description ?? '',
    },
  });

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const selectedHeadoffice = watch('headOffice');

  const { data, isLoading } = useGetCompanyStructureQuery({ companyId });

  const headOfficesOptions: SelectOption[] = useMemo(() => {
    if (!data) {
      return [];
    }
    return (
      data.headOffices?.map((headOffice) => ({
        value: headOffice.id,
        name: headOffice.description ?? '',
      })) ?? []
    );
  }, [data]);

  useEffect(() => {
    if (office) {
      setValue('officeName', office?.description ?? '');
      setValue('headOffice', office?.headOffice ?? '');
    }
  }, [office, setValue]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <FieldGrid>
        <TextField
          label={intl.formatMessage({
            id: 'inputOfficeNameLabel',
            defaultMessage: 'Office name',
            description: 'Label for office name input',
          })}
          placeholder={intl.formatMessage({
            id: 'inputOfficeNamePlaceholder',
            defaultMessage: 'Enter office name',
            description: 'Placeholder for office name input',
          })}
          error={errors.officeName}
          register={register('officeName', {
            required: intl.formatMessage({
              id: 'inputErrorOfficeNameRequired',
              defaultMessage: 'A office name is required',
              description: 'Input error for office name required',
            }),
          })}
        />
        <SelectField
          label={intl.formatMessage({
            id: 'inputHeadofficeLabel',
            defaultMessage: 'Headoffice',
            description: 'Label for headoffice input',
          })}
          register={register('headOffice')}
          placeholder={intl.formatMessage({
            id: 'inputHeadofficePlaceholder',
            defaultMessage: 'No head office',
            description: 'Placeholder for office input',
          })}
          defaultValue={selectedHeadoffice}
          options={headOfficesOptions}
          error={errors.headOffice}
        />
      </FieldGrid>
      <ActionContainer>
        <Button type="submit" background="blue" disabled={!isDirty}>
          <FormattedMessage
            id="saveButton"
            defaultMessage="Save"
            description="Save button text"
          />
        </Button>
        {office && onDelete && (
          <>
            <Button
              color="error"
              background="transparent"
              border="error"
              onClick={() => setShowConfirmDelete(true)}
            >
              <FormattedMessage
                id="deleteButton"
                defaultMessage="Delete"
                description="Delete button"
              />
            </Button>
            <ConfirmActionPopup
              isOpen={showConfirmDelete}
              onConfirm={onDelete}
              onAbort={() => setShowConfirmDelete(false)}
            >
              <>
                <Heading tag={Tag.H4}>
                  <FormattedMessage
                    id="deleteOfficeConfirmHeading"
                    defaultMessage="Delete office"
                    description="Heading text in the delete office popup"
                  />
                </Heading>
                <FormattedMessage
                  id="deleteOfficeConfirmBody"
                  defaultMessage="Are you sure you want to delete this office?"
                  description="Body text in the delete office popup"
                />
              </>
            </ConfirmActionPopup>
          </>
        )}
      </ActionContainer>
    </form>
  );
};

export default CreateUpdateOfficeForm;
