import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-hot-toast';

import { ContentWidth } from 'styles';
import { removeProfileImage, uploadAsset } from 'services';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useGetAssetImage } from 'hooks/useGetAssetImage';
import {
  authSelector,
  logoutSession,
  setLayoutHeader,
  useGetUserQuery,
  useUpdateUserMutation,
} from 'store';

import EditProfileForm from 'components/forms/EditProfileForm';
import Icon, { IconType } from 'components/UI/Icon';
import EmptyState from 'components/UI/EmptyState';
import Heading from 'components/UI/Heading';
import Button from 'components/UI/Button';
import Loader from 'components/UI/Loader';

import { Img, Input, ProfileContainer, ProfileImage } from './styles';
import DeleteUserModal from 'components/modals/DeleteUserModal';

const ProfileSettings: FC = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { accessToken } = useAppSelector(authSelector);

  // Refs
  const inputFileRef = useRef<HTMLInputElement>(null);

  // Hooks
  const { isLoading, data, refetch } = useGetUserQuery();
  const [updateUser, updateResult] = useUpdateUserMutation();
  const [imageSrc, fetchAsset] = useGetAssetImage(data?.profilePictureAssetId);

  // State
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  // Set header
  useEffect(() => {
    dispatch(
      setLayoutHeader({
        title: intl.formatMessage({
          id: 'pageSettingsTitle',
          defaultMessage: 'Settings',
          description: 'Page title for settings',
        }),
        icon: IconType.Back,
      })
    );
  }, [dispatch, intl]);

  useEffect(() => {
    if (updateResult.isSuccess) {
      toast.success(
        intl.formatMessage({
          id: 'pageProfileSettingsSuccess',
          defaultMessage: 'Updated profile successfully',
          description: 'Profile settings update success message"',
        })
      );
    }
  }, [intl, updateResult]);

  // Remove profile image
  const onRemoveImage = useCallback(async () => {
    if (inputFileRef.current) {
      inputFileRef.current.value = '';
    }
    await removeProfileImage(accessToken);
    await refetch();
    fetchAsset();
  }, [refetch, fetchAsset, accessToken]);

  // Upload logo
  const onFileChangeCapture = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const { files } = e.target;
      if (files) {
        await uploadAsset(files[0], 'ProfilePicture', accessToken);
        await refetch();
        fetchAsset();
      }
    },
    [refetch, fetchAsset, accessToken]
  );

  // Open / close delete modal
  const onDeleteUser = useCallback(() => setDeleteModalOpen(true), []);
  const onCloseDeleteUser = useCallback(() => {
    setDeleteModalOpen(false);
    dispatch(logoutSession());
  }, [dispatch]);

  // Click image
  const onClickImage = useCallback(() => inputFileRef.current?.click(), []);

  // Content
  const content = useMemo(() => {
    if (isLoading || updateResult.isLoading) {
      return <Loader padding color="blue" />;
    }
    if (!data) {
      return (
        <EmptyState iconType={IconType.User} padding>
          <FormattedMessage
            id="pageProfileSettingsEmptyState"
            defaultMessage="Unable to get user data"
            description="Empty state for user profile settings"
          />
        </EmptyState>
      );
    }
    return (
      <EditProfileForm
        submitForm={updateUser}
        onDeleteUser={onDeleteUser}
        data={updateResult.data || data}
      />
    );
  }, [updateUser, onDeleteUser, data, isLoading, updateResult]);

  return (
    <ContentWidth isSurface>
      <Heading>
        <FormattedMessage
          id="pageProfileSettingsTitle"
          defaultMessage="Edit profile"
          description="Page title for profile settings"
        />
      </Heading>
      <ProfileContainer>
        <ProfileImage onClick={onClickImage}>
          {imageSrc && <Img src={imageSrc} alt={data?.firstName} />}
          <Icon type={IconType.User} color="grayText" size={32} />
          <Input
            type="file"
            ref={inputFileRef}
            onChangeCapture={onFileChangeCapture}
          />
        </ProfileImage>
        {imageSrc && (
          <Button
            size="small"
            color="error"
            background="transparent"
            border="backgroundDark"
            onClick={onRemoveImage}
          >
            <FormattedMessage
              id="removeProfileImageButton"
              defaultMessage="Delete profile picture"
              description="Remove profile image button text"
            />
          </Button>
        )}
      </ProfileContainer>
      {content}
      <DeleteUserModal isOpen={deleteModalOpen} onClose={onCloseDeleteUser} />
    </ContentWidth>
  );
};

export default ProfileSettings;
