import React, { FC, useEffect, Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ContentWidth } from 'styles';
import { useAppDispatch } from 'hooks/redux';
import {
  setLayoutHeader,
  useAddUserMutation,
  useGetCompanyStructureQuery,
} from 'store';

import Heading, { Tag } from 'components/UI/Heading';
import Loader from 'components/UI/Loader';

import * as routes from 'router/routes';

import { IconType } from 'components/UI/Icon';

import AddUsersForm from 'components/forms/AddUsersForm';
import toast from 'react-hot-toast';
import { usePretendCompany } from 'hooks/usePretendCompany';
import Button from 'components/UI/Button';
import { SubmitCallback } from 'components/forms/AddUsersForm/AddUsersForm';
import { ErrorEmail } from './styles';

const AdminUserCreate: FC = () => {
  const intl = useIntl();
  const companyId = usePretendCompany();
  const dispatch = useAppDispatch();

  const [showError, setShowError] = React.useState(false);

  // Hooks
  const { data, isLoading } = useGetCompanyStructureQuery({ companyId });
  const [addUser, { isLoading: isCreating, data: addUserData }] =
    useAddUserMutation();

  // Set header
  useEffect(() => {
    dispatch(
      setLayoutHeader({
        title: intl.formatMessage({
          id: 'pageAdminUsersTitle',
          defaultMessage: 'Users',
          description: 'Page title for admin users',
        }),
        icon: IconType.Back,
        link: routes.ADMIN_COMPANY_USERS,
      })
    );
  }, [dispatch, intl]);

  const handleSubmit: SubmitCallback = async (form) => {
    const response = await addUser({
      emails: form.emails
        .split('\n')
        .map((line) => line.trim())
        .filter((line) => line !== ''),
      departmentId: form.department !== '' ? form.department : undefined,
      language: form.language as string,
      companyId,
    });
    if ('data' in response) {
      if (!response.data || response.data.registeredEmailAddresses.length > 0) {
        toast.success(
          intl.formatMessage({
            id: 'createUserSuccess',
            defaultMessage: 'User(s) created successfully',
            description: 'User add toast success message',
          })
        );
      }
      if (
        response.data &&
        (response.data.notValidEmailAddresses.length > 0 ||
          response.data.alreadyRegisteredEmailAddresses.length > 0)
      ) {
        setShowError(true);
      }
    }
  };

  // Loading
  if (isLoading || isCreating) {
    return <Loader color="blue" padding />;
  }

  // No data
  if (!data) {
    return null;
  }

  if (addUserData && showError) {
    return (
      <ContentWidth isSurface>
        <Heading tag={Tag.H4}>
          <FormattedMessage
            id="pageAdminUserAddResultTitle"
            defaultMessage="Some users could not be added"
            description="Heading for admin user create error feedback"
          />
        </Heading>
        {addUserData.alreadyRegisteredEmailAddresses.length > 0 && (
          <>
            <FormattedMessage
              tagName="p"
              id="pageAdminUserAddAlreadyRegisteredTitle"
              defaultMessage="Already registered:"
              description="Title for already registered error feedback on admin user create page"
            />
            <div>
              {addUserData.alreadyRegisteredEmailAddresses.map((email) => (
                <ErrorEmail>{email}</ErrorEmail>
              ))}
            </div>
            <br />
          </>
        )}
        {addUserData.notValidEmailAddresses.length > 0 && (
          <>
            <FormattedMessage
              tagName="p"
              id="pageAdminUserAddBadEmailTitle"
              defaultMessage="Invalid email:"
              description="Title for bad email error feedback on admin user create page"
            />
            <div>
              {addUserData.notValidEmailAddresses.map((email) => (
                <ErrorEmail>{email}</ErrorEmail>
              ))}
            </div>
            <br />
          </>
        )}
        <Button
          color="white"
          background="blue"
          onClick={() => setShowError(false)}
        >
          <FormattedMessage
            id="backButton"
            defaultMessage="Back"
            description="Back button text"
          />
        </Button>
      </ContentWidth>
    );
  }

  return (
    <Fragment>
      <ContentWidth isSurface>
        <Heading tag={Tag.H2}>
          <FormattedMessage
            id="pageAdminUserCreateTitle"
            defaultMessage="Add new user"
            description="Heading for admin user create page"
          />
        </Heading>
        <AddUsersForm submitForm={handleSubmit} />
      </ContentWidth>
    </Fragment>
  );
};

export default AdminUserCreate;
