import { baseApi } from '../baseApi';
import { WellrEndpointDefinition, WellrEndpointPath } from 'utils/api';


const getUnreadChatRoomEntriesPath: WellrEndpointPath =
  '/api/chat/unread/{chatRoomId}';
const getUnreadChatRoomEntriesMethod = 'get';

const putSeenMessageCountPath: WellrEndpointPath =
  '/api/chat/seen-message-count/{chatRoomId}';
const putSeenMessageCountMethod = 'put';

type PutSeenMessageCountEndpoint = WellrEndpointDefinition<
  typeof putSeenMessageCountPath,
  typeof putSeenMessageCountMethod
>;

type GetUnreadChatRoomEntriesEndpoint = WellrEndpointDefinition<
  typeof getUnreadChatRoomEntriesPath,
  typeof getUnreadChatRoomEntriesMethod
>;



const chatApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getUnreadChatRoomEntries: builder.query<GetUnreadChatRoomEntriesEndpoint['responseBody'], GetUnreadChatRoomEntriesEndpoint['request']['params']['path']>({
      query: ({ chatRoomId }) => ({
        url: `/chat/unread/${chatRoomId}`,
        method: getUnreadChatRoomEntriesMethod,
      })
    }),
    putSeenMessageCount: builder.mutation<PutSeenMessageCountEndpoint['responseBody'], PutSeenMessageCountEndpoint['request']['params']['path']>({
      query: ({ chatRoomId }) => ({
        url: `/chat/seen-message-count/${chatRoomId}`,
        method: putSeenMessageCountMethod,
      })
    }),
  }),
});


export const {
  useGetUnreadChatRoomEntriesQuery,
  usePutSeenMessageCountMutation,
} = chatApi;
