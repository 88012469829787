import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useGetAssetImage } from 'hooks/useGetAssetImage';
import coinBadge from 'assets/vectors/wellr-badge.svg';
import Heading, { Tag } from 'components/UI/Heading';
import ProgressBar from 'components/UI/ProgressBar';

import {
  Item,
  ItemText,
  ItemGrid,
  ItemProgress,
  ItemHeader,
  ItemHeaderCol,
  ImgContainer,
  Img,
  Badge,
  TotalStepsContainer,
} from './styles';
import { ThemeColor } from 'styles';
import Icon, { IconType } from 'components/UI/Icon';
import Bubble from 'components/UI/Bubble';

type Props = {
  alias: string;
  email: string | null;
  profilePictureAssetId: string | null;
  showResults?: boolean;
  totalSteps: number;
  progress: number | null;
  progressColor?: ThemeColor;
  toplistPosition?: number;
  totalText?: string;
  isLast: boolean;
  points?: boolean;
  page?: number;
};


const TopListProgressItem: FC<Props> = ({
  alias,
  email,
  profilePictureAssetId,
  showResults = true,
  totalSteps,
  progress,
  progressColor = 'blue',
  totalText = null,
  isLast,
  points,
  toplistPosition,
  page
}) => {
  // Hooks
  const [imageSrc] = useGetAssetImage(profilePictureAssetId);
  const progressRounded =
    progress == null
      ? null
      : progress > 99
        ? Math.floor(progress)
        : Math.round(progress);

  const [backgroundColorItem, setBackgroundColorItem] = useState<ThemeColor>();
  const [imageBorderColor, setImageBorderColor] = useState<ThemeColor>();
  const [progressColorState, setProgressColor] = useState<ThemeColor>(progressColor);
  const [toplistPositionState, setToplistPosition] = useState<number | undefined>(toplistPosition);


  useEffect(() => {
    const firstPosition = ((page ?? 1) - 1) * 10 + 1;
    setBackgroundColorItem(undefined);
    setImageBorderColor(undefined);
    setProgressColor(progressColor);

    setToplistPosition(firstPosition + (toplistPosition ?? 0) - 1);

    if (page === 1) {
      if (toplistPosition === 1) {
        setProgressColor('yellow');
        setImageBorderColor('yellow');
        setBackgroundColorItem('goldBright');
      } else if (toplistPosition === 2) {
        setProgressColor('grayText');
        setBackgroundColorItem('silver');
      } else if (toplistPosition === 3) {
        setProgressColor('bronze');
        setImageBorderColor('bronzeDark');
        setBackgroundColorItem('bronzeBright');
      }
    } else {
      setBackgroundColorItem(undefined);
      setProgressColor(progressColor);
    }

  }, [page, toplistPosition, progressColor, imageBorderColor, points]);


  return (
    <Item
      color={backgroundColorItem}
      isLast={isLast}
    >
      <ItemHeader>
        <ItemHeaderCol style={{ overflow: 'visible' }}>
          <ImgContainer color={imageBorderColor} style={{ position: 'relative', overflow: 'visible' }} >
            <div style={{ position: 'absolute', top: 5, right: 37 }}>
              <Bubble color={'white'} background={'orange'} value={toplistPositionState} toplist={true} />
            </div>
            {imageSrc ? (
              <Img src={imageSrc} alt={alias} />
            ) : (
              <Icon type={IconType.User} color="grayText" size={20} />
            )}
          </ImgContainer>
          <Heading
            tag={Tag.H4}
            style={{
              overflow: 'hidden',
              flex: 1,
            }}
          >
            {alias}
            {email != null ? ` - ${email}` : ''}
          </Heading>
          {showResults && (
            <ItemText>
              {totalText ? (
                totalText
              ) : (
                <>
                  {points ? (
                    <TotalStepsContainer>
                      <FormattedMessage
                        id="activitiesPointsText"
                        defaultMessage="points"
                        description="Unit text for activities"
                        values={{ points: totalSteps }}
                      />
                      <Badge src={coinBadge} alt="points badge" />
                    </TotalStepsContainer>
                  ) : (

                    <TotalStepsContainer>

                      {totalSteps}{' '}
                      <FormattedMessage
                        id="activitiesUnitText"
                        defaultMessage="Steps"
                        description="Unit text for activities"
                      />
                      <Icon type={IconType.Footprints} color="grayText" />
                    </TotalStepsContainer>
                  )}
                </>
              )}
            </ItemText>
          )}
        </ItemHeaderCol>
      </ItemHeader>
      {showResults && progressRounded != null && (
        <ItemGrid>
          <ItemText>{`${progressRounded}%`}</ItemText>
          <ItemProgress>
            <ProgressBar progress={progressRounded} color={progressColorState} />
          </ItemProgress>
        </ItemGrid>
      )}
    </Item>
  );
};

export default TopListProgressItem;
