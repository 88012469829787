import { TimedChallenge } from 'models/timedChallengeResult/timedChallengeResult';
import { baseApi } from '../baseApi';

const timedChallengeApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getDailyTimedChallenge: builder.query<TimedChallenge, void>({
      query: () => ({
        url: '/timed-challenge/daily',
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetDailyTimedChallengeQuery
} = timedChallengeApi ;