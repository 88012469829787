import { FilterTag } from 'models';
import { ReactNode, useCallback, useMemo } from 'react';

import FilterModal from 'components/modals/FilterModal';
import { layoutSelector, setActiveFilters } from 'store';
import { useAppSelector } from './redux';
import { useDispatch } from 'react-redux';

export const useTagFilters = (
  id: string,
  tags?: FilterTag[]
): [string[], ReactNode] => {
  const dispatch = useDispatch();
  const { activeFilters } = useAppSelector(layoutSelector);

  // Handle outside click
  const onSubmit = useCallback(
    (filters: string[]) => {
      dispatch(setActiveFilters({ id, filters }));
    },
    [dispatch, id]
  );

  // Filters
  const active = useMemo(() => {
    if (activeFilters?.id === id) {
      return activeFilters.filters;
    }
    return [];
  }, [activeFilters, id]);

  return [
    active,
    <FilterModal
      tags={tags}
      defaultFilters={active}
      submitFilters={onSubmit}
    />,
  ];
};
