import React, { FC, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import Button from 'components/UI/Button/Button';
import TextField from 'components/UI/TextField/TextField';

import { ButtonWrapper } from './styles';

type Props = {
  expirationDate?: string;
  submitForm: (expirationDate: string) => void;
  onDelete: () => void;
  disabled?: boolean;
};

const UpdateUserRegistrationCodeForm: FC<Props> = ({
  onDelete,
  disabled,
  submitForm,
  expirationDate,
}) => {
  const intl = useIntl();
  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm();

  useEffect(() => {
    if (expirationDate != null) {
      setValue('expirationDate', expirationDate);
    }
  }, [expirationDate, setValue]);

  const onSubmit: SubmitHandler<FieldValues> = ({ expirationDate }) =>
    submitForm(expirationDate);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        type="datetime-local"
        label={intl.formatMessage({
          id: 'inputExpirationDateLabel',
          defaultMessage: 'Expiration date',
          description: 'Label for registration code expiration date input',
        })}
        error={errors.expirationDate}
        register={register('expirationDate', {
          required: intl.formatMessage({
            id: 'inputErrorFieldRequired',
            defaultMessage: 'Field is is required',
            description: 'Input error for field required',
          }),
        })}
      />
      <ButtonWrapper>
        <Button type="submit" background="blue" disabled={!isDirty || disabled}>
          <FormattedMessage
            id="saveButton"
            defaultMessage="Save"
            description="Save button text"
          />
        </Button>
        <Button
          color="error"
          background="transparent"
          border="error"
          onClick={onDelete}
        >
          <FormattedMessage
            id="deleteRegistrationCodeButton"
            defaultMessage="Delete registration code"
            description="Delete registration code button"
          />
        </Button>
      </ButtonWrapper>
    </form>
  );
};

export default UpdateUserRegistrationCodeForm;
