import React, {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import toast from 'react-hot-toast';

import {
  useSearchInvitableTeamMembersMutation,
  useSendContestTeamInviteMutation,
} from 'store';
import { ContestTeam, ContestTeamInvitableUser } from 'models';
import { ActionContainer, Category } from 'styles';

import Loader from 'components/UI/Loader';
import Icon, { IconType } from 'components/UI/Icon';
import IconButton from 'components/UI/IconButton';
import Heading, { Tag } from 'components/UI/Heading';
import SlideoutModal from 'components/UI/SlideoutModal';
import SearchField from 'components/UI/SearchField';
import StaticCard from 'components/UI/Cards/StaticCard';
import Button from 'components/UI/Button';
import EmptyState from 'components/UI/EmptyState';

import {
  Header,
  Body,
  CardGrid,
  CardCol,
  CardList,
  CardAction,
  CardActionEmpty,
  ErrorMessage,
} from './styles';

type Props = {
  contestId: string;
  team: ContestTeam;
  maxUsers: number;
  isOpen: boolean;
  onClose: () => void;
};

const InviteTeamMembersModal: FC<Props> = ({
  contestId,
  team,
  maxUsers,
  isOpen,
  onClose,
}) => {
  const intl = useIntl();

  // Hooks
  const [searchMembers, searchResult] = useSearchInvitableTeamMembersMutation();
  const [sendInvite, sendResult] = useSendContestTeamInviteMutation();

  // State
  const [inviteUsers, setInviteUsers] = useState<ContestTeamInvitableUser[]>(
    []
  );

  // Success
  useEffect(() => {
    if (sendResult.isSuccess) {
      onClose();
      toast.success(
        intl.formatMessage({
          id: 'inviteTeamMembersModalSuccess',
          defaultMessage: 'Invitations have successfully been sent',
          description: 'Success messag  for invite team members modal',
        })
      );
    }
  }, [sendResult, intl, onClose]);

  // Send action
  const onSend = useCallback(async () => {
    await sendInvite({
      contestId,
      teamId: team.id,
      membershipIds: inviteUsers.map((user) => user.membershipId),
    });
    setInviteUsers([]);
  }, [sendInvite, contestId, team, inviteUsers]);

  // Search action
  const onSearch = useCallback(
    async (searchTerm: string) => {
      await searchMembers({ contestId, body: { searchTerm } });
    },
    [searchMembers, contestId]
  );

  // Render button
  const renderButton = useCallback(
    (user: ContestTeamInvitableUser) => {
      if (team.pendingInvites.some((item) => item.id === user.id)) {
        return (
          <CardActionEmpty>
            <FormattedMessage
              id="inviteTeamMembersModalCardInvited"
              defaultMessage="Invited"
              description="Invited user in invite team members modal card"
            />
          </CardActionEmpty>
        );
      }
      if (inviteUsers.some((item) => item.membershipId === user.membershipId)) {
        return (
          <Button
            color="error"
            size="small"
            background="transparent"
            border="error"
            onClick={() =>
              setInviteUsers((prev) =>
                prev.filter((item) => item.membershipId !== user.membershipId)
              )
            }
          >
            <FormattedMessage
              id="removeButton"
              defaultMessage="Remove"
              description="Remove button text"
            />
          </Button>
        );
      }
      if (inviteUsers.length >= maxUsers) {
        return null;
      }
      return (
        <Button
          color="blue"
          size="small"
          background="transparent"
          border="borderDark"
          onClick={() => setInviteUsers((prev) => [...prev, user])}
        >
          <FormattedMessage
            id="addButton"
            defaultMessage="Add"
            description="Add button text"
          />
        </Button>
      );
    },
    [team, inviteUsers, maxUsers]
  );

  // Search results
  const searchContent = useMemo(() => {
    if (searchResult.isLoading) {
      return <Loader padding color="blue" />;
    }
    if (!searchResult.data) {
      return null;
    }

    const { invitableUsers } = searchResult.data;
    if (!invitableUsers.length) {
      return (
        <EmptyState iconType={IconType.Users} padding>
          <FormattedMessage
            id="inviteTeamMembersModalEmptyState"
            defaultMessage="No members found"
            description="Empty state for invite team members modal"
          />
        </EmptyState>
      );
    }
    return (
      <CardList>
        {invitableUsers.map((user) => (
          <StaticCard key={user.membershipId}>
            <CardGrid>
              <CardCol>
                <Heading tag={Tag.H3}>{user.alias}</Heading>
                <Category>{user.email}</Category>
              </CardCol>
              <CardAction>{renderButton(user)}</CardAction>
            </CardGrid>
          </StaticCard>
        ))}
      </CardList>
    );
  }, [renderButton, searchResult]);

  // Content
  const content = useMemo(() => {
    if (team.users && team.users.length >= maxUsers) {
      return (
        <EmptyState iconType={IconType.Users} padding>
          <FormattedMessage
            id="inviteTeamMembersModalMaxInvited"
            defaultMessage="You have reached the maximum number of invited members for your team."
            description="Empty state for invite team members modal"
          />
        </EmptyState>
      );
    }
    if (sendResult.isLoading) {
      return <Loader padding color="blue" />;
    }
    return (
      <Fragment>
        <p>
          <FormattedMessage
            id="inviteTeamMembersModalDescription"
            defaultMessage="Search for a user email to invite to your team."
            description="Invite team members modal description"
          />
        </p>
        <SearchField
          placeholder={intl.formatMessage({
            id: 'inputSearchEmailPlaceholder',
            defaultMessage: 'Search for an email',
            description: 'Placeholder for search email input',
          })}
          onSearch={onSearch}
          registerOptions={{
            required: intl.formatMessage({
              id: 'inputErrorSearchEmailRequired',
              defaultMessage: 'A search term for an email is required',
              description: 'Input error for search email required',
            }),
            minLength: {
              value: 3,
              message: intl.formatMessage({
                id: 'inputErrorSearchEmailMinLength',
                defaultMessage:
                  'Enter at least 3 characters when searching for an email.',
                description: 'Input error for search email min length',
              }),
            },
          }}
        />
        {searchContent}
        {inviteUsers.length >= maxUsers && (
          <ErrorMessage>
            <FormattedMessage
              id="inviteTeamMembersModalMaxInvited"
              defaultMessage="You have reached the maximum number of invited members for your team."
              description="Empty state for invite team members modal"
            />
          </ErrorMessage>
        )}
        {inviteUsers.length > 0 && (
          <ActionContainer>
            <Button
              color="blue"
              background="transparent"
              border="borderDark"
              onClick={() => setInviteUsers([])}
            >
              <FormattedMessage
                id="clearButton"
                defaultMessage="Clear"
                description="Clear button text"
              />
            </Button>
            <Button background="blue" onClick={onSend}>
              <FormattedMessage
                id="inviteMembersButton"
                defaultMessage="Invite {count, plural, one {# member} other {# members}}"
                description="Invite members button text"
                values={{ count: inviteUsers.length }}
              />
            </Button>
          </ActionContainer>
        )}
      </Fragment>
    );
  }, [
    onSend,
    onSearch,
    sendResult,
    searchContent,
    inviteUsers,
    team,
    maxUsers,
    intl,
  ]);

  return (
    <SlideoutModal isOpen={isOpen} onClose={onClose}>
      <div>
        <Header>
          <Heading tag={Tag.H3}>
            <FormattedMessage
              id="inviteTeamMembersModalTitle"
              defaultMessage="Invite team members"
              description="Invite team members modal title"
            />
          </Heading>
          <IconButton onClick={onClose} padding>
            <Icon type={IconType.Close} />
          </IconButton>
        </Header>
        <Body>{content}</Body>
      </div>
    </SlideoutModal>
  );
};

export default InviteTeamMembersModal;
