import { FC } from 'react';

import Heading, { Tag } from 'components/UI/Heading';
import StaticCard from 'components/UI/Cards/StaticCard';
import ToggleButton from 'components/UI/ToggleButton';

import { Grid, Column } from './styles';

type Props = {
  title: string;
  isActive: boolean;
  isLocked?: boolean;
  onToggle: (active: boolean) => void;
};

const ToggleCard: FC<Props> = ({ title, isActive, isLocked = false, onToggle }) => {
  return (
    <StaticCard>
      <Grid>
        <Column>
          <Heading tag={Tag.H5}>{title}</Heading>
        </Column>
        <Column>
          <ToggleButton isActive={isActive} isLocked={isLocked} onToggle={onToggle} />
        </Column>
      </Grid>
    </StaticCard>
  );
};

export default ToggleCard;
