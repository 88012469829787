import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { StepGoal } from 'models';
import { useDeleteStepGoalMutation, useUpdateStepGoalMutation } from 'store';

import Loader from 'components/UI/Loader';
import Icon, { IconType } from 'components/UI/Icon';
import IconButton from 'components/UI/IconButton';
import Heading, { Tag } from 'components/UI/Heading';
import SlideoutModal from 'components/UI/SlideoutModal';
import StepGoalForm from 'components/forms/StepGoalForm';

import { Header, Body } from './styles';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  dailyStepGoal: StepGoal['dailyStepGoal'];
};

const StepGoalModal: FC<Props> = ({ isOpen, onClose, dailyStepGoal }) => {
  // Hooks
  const [updateStepGoal, updateResult] = useUpdateStepGoalMutation();
  const [deleteStepGoal, deleteResult] = useDeleteStepGoalMutation();

  // Success
  useEffect(() => {
    if (updateResult.isSuccess || deleteResult.isSuccess) {
      onClose();
    }
  }, [updateResult, deleteResult, onClose]);

  // Update
  const onUpdate = useCallback(
    (dailyStepGoal: number) => {
      updateStepGoal({ dailyStepGoal });
    },
    [updateStepGoal]
  );

  // Content
  const content = useMemo(() => {
    if (updateResult.isLoading) {
      return <Loader padding color="blue" />;
    }

    return (
      <StepGoalForm
        goal={dailyStepGoal}
        deleteGoal={deleteStepGoal}
        submitForm={onUpdate}
      />
    );
  }, [dailyStepGoal, updateResult, onUpdate, deleteStepGoal]);

  return (
    <SlideoutModal isOpen={isOpen} onClose={onClose}>
      <div>
        <Header>
          <Heading tag={Tag.H3}>
            <FormattedMessage
              id="stepGoalModalTitle"
              defaultMessage="Daily step goal"
              description="Step goal modal title"
            />
          </Heading>
          <IconButton onClick={onClose} padding>
            <Icon type={IconType.Close} />
          </IconButton>
        </Header>
        <Body>{content}</Body>
      </div>
    </SlideoutModal>
  );
};

export default StepGoalModal;
