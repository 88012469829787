import React, { FC, ReactNode, useEffect } from 'react';
import { Variants } from 'framer-motion';

import BackgroundCover from 'components/UI/BackgroundCover';
import { ThemeColor } from 'styles';

import { Overlay } from './styles';

type Props = {
  isOpen: boolean;
  children?: ReactNode;
  color?: ThemeColor;
  illustrationColor?: ThemeColor;
};

const variants: Variants = {
  open: {
    opacity: 1,
    display: 'block',
  },
  closed: {
    opacity: 0,
    display: 'none',
  },
};

const NavOverlay: FC<Props> = ({
  children,
  isOpen,
  color,
  illustrationColor,
}) => {
  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'unset';
  }, [isOpen]);

  return (
    <Overlay
      initial={false}
      transition={{ type: 'tween' }}
      animate={isOpen ? 'open' : 'closed'}
      variants={variants}
    >
      <BackgroundCover color={color} illustrationColor={illustrationColor}>
        {children}
      </BackgroundCover>
    </Overlay>
  );
};

export default NavOverlay;
