import React, { FC, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import { regExp } from 'utils/string';
import { ResetPasswordValues } from 'models';

import Button from 'components/UI/Button/Button';
import TextField from 'components/UI/TextField/TextField';

import { ActionContainer } from 'styles';

type Props = {
  submitForm: (email: ResetPasswordValues) => void;
};

const ResetPasswordForm: FC<Props> = ({ submitForm }) => {
  const intl = useIntl();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // Submit
  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    (values) => {
      const resetPasswordValues: ResetPasswordValues = {
        email: values.email,
      };
      submitForm(resetPasswordValues);
    },
    [submitForm]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        label={intl.formatMessage({
          id: 'inputEmailLabel',
          defaultMessage: 'Email',
          description: 'Label for email input',
        })}
        placeholder={intl.formatMessage({
          id: 'inputEmailPlaceholder',
          defaultMessage: 'Enter email',
          description: 'Placeholder for email input',
        })}
        error={errors.email}
        register={register('email', {
          required: intl.formatMessage({
            id: 'inputErrorEmailRequired',
            defaultMessage: 'Email is required',
            description: 'Input error for email required',
          }),
          pattern: {
            value: regExp.email,
            message: intl.formatMessage({
              id: 'inputErrorEmailInvalid',
              defaultMessage: 'Please enter a valid email',
              description: 'Input error for email invalid',
            }),
          },
        })}
      />
      <ActionContainer>
        <Button type="submit" color="black" background="yellow">
          <FormattedMessage
            id="resetPasswordButton"
            defaultMessage="Reset password"
            description="Reset password button text"
          />
        </Button>
      </ActionContainer>
    </form>
  );
};

export default ResetPasswordForm;
