import React, { FC, Fragment, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import * as routes from 'router/routes';
import { useAppSelector } from 'hooks/redux';
import {
  localizationSelector,
  useCreateMeasureMutation,
  useDeleteActiveMeasureMutation,
  useDeleteProposedMeasureMutation,
  useGetActiveMeasuresQuery,
  useGetCompletedMeasuresQuery,
  useGetProposedMeasuresQuery,
} from 'store';
import Loader from 'components/UI/Loader';
import EmptyState from 'components/UI/EmptyState';
import Heading, { Tag } from 'components/UI/Heading';
import MeasureCard from 'components/cards/MeasureCard';

import { CardList } from './styles';

const Measures: FC = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const { language } = useAppSelector(localizationSelector);
  const { data: activeData, isLoading: activeIsLoading } =
    useGetActiveMeasuresQuery({
      language: language?.languageCode,
    });
  const proposed = useGetProposedMeasuresQuery({
    language: language?.languageCode,
  });
  const { data: completedData, isLoading: completedIsLoading } =
    useGetCompletedMeasuresQuery({
      language: language?.languageCode,
    });
  const [createMeasure] = useCreateMeasureMutation();
  const [deleteActiveMeasure] = useDeleteActiveMeasureMutation();
  const [deleteProposedMeasure] = useDeleteProposedMeasureMutation();

  // Click measure
  const onClick = useCallback(
    (slug: string, isProposed: boolean = false) =>
      async () => {
        if (isProposed) {
          await createMeasure({ slug });
        }
        navigate(`${routes.MEASURES}/${slug}`);
      },
    [navigate, createMeasure]
  );

  // Remove active measure
  const onActiveRemove = useCallback(
    (slug: string) => () => deleteActiveMeasure({ slug }),
    [deleteActiveMeasure]
  );

  // Remove proposed measure
  const onProposedRemove = useCallback(
    (slug: string) => () => deleteProposedMeasure({ slug }),
    [deleteProposedMeasure]
  );

  // Active measures
  const activeMeasures = useMemo(() => {
    // Loading
    if (activeIsLoading) {
      return <Loader color="blue" padding />;
    }

    // No data
    if (!activeData?.length) {
      return (
        <EmptyState padding>
          <FormattedMessage
            id="pageMeasuresActiveEmptyState"
            defaultMessage="No active health plans"
            description="Empty state for active measures"
          />
        </EmptyState>
      );
    }

    return activeData.map((item) => (
      <MeasureCard
        key={item.slug}
        category={item.healthCategory}
        text={item.title}
        wellrPoints={item.wellrPoints}
        onRemove={onActiveRemove(item.slug)}
        onActiveClick={onClick(item.slug)}
        buttonActiveText={intl.formatMessage({
          id: 'continueButton',
          defaultMessage: 'Continue',
          description: 'Continue button text',
        })}
        isActive
      />
    ));
  }, [onClick, onActiveRemove, activeData, activeIsLoading, intl]);

  // Proposed measures
  const proposedMeasures = useMemo(() => {
    // Loading
    if (proposed.isLoading) {
      return <Loader color="blue" padding />;
    }

    // No data
    if (!proposed.data?.length) {
      return (
        <EmptyState padding>
          <FormattedMessage
            id="pageMeasuresProposedEmptyState"
            defaultMessage="No proposed health plans"
            description="Empty state for proposed measures"
          />
        </EmptyState>
      );
    }

    return proposed.data.map((item) => (
      <MeasureCard
        key={item.slug}
        category={item.healthCategory}
        text={item.title}
        wellrPoints={item.wellrPoints}
        onClick={onClick(item.slug, true)}
        onRemove={onProposedRemove(item.slug)}
        buttonText={intl.formatMessage({
          id: 'startProgramButton',
          defaultMessage: 'Start program',
          description: 'Start program button text',
        })}
        buttonActiveText={intl.formatMessage({
          id: 'continueButton',
          defaultMessage: 'Continue',
          description: 'Continue button text',
        })}
      />
    ));
  }, [onClick, onProposedRemove, proposed, intl]);

  // Completed measures
  const completedMeasures = useMemo(() => {
    // Loading
    if (completedIsLoading) {
      return <Loader color="blue" padding />;
    }

    // No data
    if (!completedData?.length) {
      return (
        <EmptyState padding>
          <FormattedMessage
            id="pageMeasuresCompletedEmptyState"
            defaultMessage="No completed health plans"
            description="Empty state for completed measures"
          />
        </EmptyState>
      );
    }

    return completedData.map((item) => (
      <MeasureCard
        key={item.slug + item.completed}
        category={item.healthCategory}
        text={item.title}
        wellrPoints={item.wellrPoints}
        onActiveClick={onClick(item.slug, true)}
        buttonActiveText={intl.formatMessage({
          id: 'menuRedoMeasure',
          defaultMessage: 'Redo the health plan',
          description: 'Dropdown menu item for redo measure',
        })}
        isActive
      />
    ));
  }, [onClick, completedData, completedIsLoading, intl]);

  return (
    <Fragment>
      <Heading tag={Tag.H4}>
        <FormattedMessage
          id="pageMeasuresActiveTitle"
          defaultMessage="Active health plans"
          description="Section title for active measures"
        />
      </Heading>
      <CardList>{activeMeasures}</CardList>
      <Heading tag={Tag.H4}>
        <FormattedMessage
          id="pageMeasuresProposedTitle"
          defaultMessage="Proposed health plans"
          description="Section title for proposed measures"
        />
      </Heading>
      <CardList>{proposedMeasures}</CardList>
      <Heading tag={Tag.H4}>
        <FormattedMessage
          id="pageMeasuresCompletedTitle"
          defaultMessage="Completed health plans"
          description="Section title for completed measures"
        />
      </Heading>
      <CardList>{completedMeasures}</CardList>
    </Fragment>
  );
};

export default Measures;
