import React, { FC, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import toast from 'react-hot-toast';

import {
  useResetPasswordMigrateMutation,
  useResetPasswordMutation,
} from 'store';

import Heading from 'components/UI/Heading';
import BackgroundCover from 'components/UI/BackgroundCover';
import Loader from 'components/UI/Loader';
import CoverHeader from 'components/UI/CoverHeader';
import ResetPasswordForm from 'components/forms/ResetPasswordForm';

import { CoverContent, Description } from 'styles';
import GdprModal from 'components/modals/GdprModal';

const ResetPassword: FC = () => {
  // Hooks
  const intl = useIntl();
  const [
    resetPassword,
    { isLoading, isSuccess, isError, error, originalArgs },
  ] = useResetPasswordMutation();

  const [resetPasswordMigrate, resetMigrationResult] =
    useResetPasswordMigrateMutation();

  const [isGdprOpen, setIsGdprOpen] = useState<boolean>(false);

  useEffect(() => {
    if (isSuccess || resetMigrationResult.isSuccess) {
      toast.success(
        intl.formatMessage({
          id: 'resetPasswordSuccess',
          defaultMessage: 'A password reset email has been sent to your inbox',
          description: 'Reset password success message"',
        })
      );
    }
  }, [intl, isSuccess, resetMigrationResult.isSuccess]);

  const emailIsMigratable =
    isError && error != null && 'status' in error && error.status === 303;

  useEffect(() => {
    if (emailIsMigratable) {
      setIsGdprOpen(true);
    }
  }, [emailIsMigratable, error, isError]);

  const content = useMemo(() => {
    if (isLoading || resetMigrationResult.isLoading) {
      return <Loader padding />;
    }
    return <ResetPasswordForm submitForm={resetPassword} />;
  }, [resetPassword, isLoading, resetMigrationResult.isLoading]);

  return (
    <BackgroundCover padding>
      <CoverHeader subLevel />
      <CoverContent>
        <Heading>
          <FormattedMessage
            id="resetPasswordTitle"
            defaultMessage="Reset your password"
            description="Reset password page title"
          />
        </Heading>
        <Description>
          <FormattedMessage
            id="resetPasswordDescription"
            defaultMessage="Enter your email address below to reset your password."
            description="Reset password page description"
          />
        </Description>
        {content}
      </CoverContent>
      {emailIsMigratable && (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            resetPasswordMigrate({
              email: originalArgs?.email as string,
            });
            setIsGdprOpen(false);
          }}
        >
          <GdprModal
            isOpen={isGdprOpen}
            onClose={() => setIsGdprOpen(false)}
            onDeny={() => setIsGdprOpen(false)}
            migratingUser={true}
          />
        </form>
      )}
    </BackgroundCover>
  );
};

export default ResetPassword;
