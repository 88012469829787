import React, { FC, useEffect, useMemo } from 'react';
import BackgroundCover from 'components/UI/BackgroundCover';

import { CoverContent } from 'styles';
import { useQuery } from 'hooks/useQuery';
import { useConnectExternalActivityMutation } from 'store';
import Loader from 'components/UI/Loader';
import toast from 'react-hot-toast';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import * as routes from 'router/routes';
import Button from 'components/UI/Button';
import { ContentContainer, ErrorMessage } from './styles';

const ConfirmRegisterInvite: FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  // Hooks
  const [activityId] = useQuery('pendingExternalActivityConnectionId');
  const [connectActivity, { isSuccess, isLoading, isUninitialized, isError }] =
    useConnectExternalActivityMutation();

  useEffect(() => {
    if (activityId && isUninitialized) {
      connectActivity({ id: activityId });
    }
  }, [activityId, connectActivity, isUninitialized]);

  useEffect(() => {
    if (isSuccess) {
      toast.success(
        intl.formatMessage({
          id: 'externalDeviceConnectSuccess',
          defaultMessage: 'Device successfully connected',
          description:
            'Toast success text when successfully connected to a wearable device',
        })
      );
      navigate(routes.SETTINGS_WEARABLE_DEVICE, { replace: true });
    }
  }, [isSuccess, intl, navigate]);

  const content = useMemo(() => {
    if (isLoading || isSuccess) {
      return <Loader />;
    }
    if (isError) {
      return (
        <ContentContainer>
          <ErrorMessage>
            <FormattedMessage
              id="externalActivityConnectError"
              defaultMessage="Failed to connect to wearable device."
              description="Error text when connecting to wearable device fails."
            />
          </ErrorMessage>
          <Button
            onClick={() =>
              navigate(routes.SETTINGS_WEARABLE_DEVICE, { replace: true })
            }
            color="black"
            background="yellow"
          >
            <FormattedMessage
              id="toSettingsButton"
              defaultMessage="Back to device connection settings"
              description="Register invite button text"
            />
          </Button>
        </ContentContainer>
      );
    }
    return '...';
  }, [isError, isLoading, isSuccess, navigate]);

  return (
    <BackgroundCover padding>
      <CoverContent>{content}</CoverContent>
    </BackgroundCover>
  );
};

export default ConfirmRegisterInvite;
