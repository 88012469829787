import styled from 'styled-components';

export const MapSettingsContainer = styled.div`
  margin-top: 11px;
  margin-right: 10px;
  background-color: #fff;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
`;
