import React, { FC, useEffect, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';

import {
  useDeleteRegistrationCodeMutation,
  useUpdateRegistrationCodeExpirationMutation,
} from 'store';

import Loader from 'components/UI/Loader/Loader';
import Icon, { IconType } from 'components/UI/Icon';
import IconButton from 'components/UI/IconButton';
import Heading, { Tag } from 'components/UI/Heading';
import SlideoutModal from 'components/UI/SlideoutModal';

import { Header, Body } from './styles';
import UpdateUserRegistrationCodeForm from 'components/forms/UpdateUserRegistrationCodeForm';

type Props = {
  data: {
    id: string;
    resetCode: string;
    expires: string;
  } | null;
  isOpen: boolean;
  onClose: () => void;
};

const UpdateUserRegistrationCodeModal: FC<Props> = ({
  data,
  isOpen,
  onClose,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const [updateRegistrationCode, updateRegistrationCodeResult] =
    useUpdateRegistrationCodeExpirationMutation();
  const [deleteRegistrationCode, deleteRegistrationCodeResult] =
    useDeleteRegistrationCodeMutation();

  // Update registration code
  const onUpdateRegistrationCode = useCallback(
    (expires: string) => {
      updateRegistrationCode({
        id: data?.id as string,
        expires,
      });
    },
    [data?.id, updateRegistrationCode]
  );

  useEffect(() => {
    if (updateRegistrationCodeResult.isSuccess) {
      toast.success(
        intl.formatMessage({
          id: 'updateUserModalSuccess',
          defaultMessage: 'User update successful',
          description: 'User update success message',
        })
      );
      onClose();
    }
  }, [updateRegistrationCodeResult, intl, onClose, navigate]);

  useEffect(() => {
    if (deleteRegistrationCodeResult.isSuccess) {
      toast.success(
        intl.formatMessage({
          id: 'updateUserModalSuccess',
          defaultMessage: 'User update successful',
          description: 'User update success message',
        })
      );
      onClose();
    }
  }, [deleteRegistrationCodeResult, intl, onClose, navigate]);

  // Content
  const content = useMemo(() => {
    if (
      updateRegistrationCodeResult.isLoading ||
      deleteRegistrationCodeResult.isLoading
    ) {
      return <Loader padding color="blue" />;
    }
    return (
      <UpdateUserRegistrationCodeForm
        expirationDate={data?.expires}
        submitForm={onUpdateRegistrationCode}
        onDelete={() => deleteRegistrationCode({ id: data?.id as string })}
      />
    );
  }, [
    data?.expires,
    data?.id,
    deleteRegistrationCode,
    onUpdateRegistrationCode,
    updateRegistrationCodeResult.isLoading,
    deleteRegistrationCodeResult.isLoading,
  ]);

  return (
    <SlideoutModal isOpen={isOpen} onClose={onClose}>
      <div>
        <Header>
          <Heading tag={Tag.H3}>
            <FormattedMessage
              id="manageUserRegistrationCodeModalTitle"
              defaultMessage="Manage registration code"
              description="Manage registration code modal title"
            />
          </Heading>
          <IconButton onClick={onClose} padding>
            <Icon type={IconType.Close} />
          </IconButton>
        </Header>
        <Body>{content}</Body>
      </div>
    </SlideoutModal>
  );
};

export default UpdateUserRegistrationCodeModal;
