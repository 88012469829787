import React, { FC, Fragment } from 'react';
import { useOutletContext } from 'react-router-dom';

import { RecipeContext } from 'models';
import RichText from 'components/fragments/RichText';

const RecipeGuide: FC = () => {
  const { data } = useOutletContext<RecipeContext>();

  return (
    <Fragment>{data.content && <RichText>{data.content}</RichText>}</Fragment>
  );
};

export default RecipeGuide;
