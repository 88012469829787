import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import { regExp } from 'utils/string';
import { RegisterInviteUserValues } from 'models';

import Button from 'components/UI/Button/Button';
import TextField from 'components/UI/TextField/TextField';

import { ActionContainer } from 'styles';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { localizationSelector, setLanguageByCode } from 'store';
import SelectField from 'components/UI/SelectField';

type Props = {
  submitForm: (data: RegisterInviteUserValues) => void;
};

const RegisterInviteForm: FC<Props> = ({ submitForm }) => {
  const intl = useIntl();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();
  const dispatch = useAppDispatch();

  // Submit
  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    ({ email, inviteCode, language }) =>
      submitForm({ email, inviteCode, language }),
    [submitForm]
  );

  const selectedLanguage = watch('language');

  const { languages, language } = useAppSelector(localizationSelector);

  const languageOptions = useMemo(
    () =>
      languages.map((item) => ({
        value: item.languageCode,
        name: item.name,
      })),
    [languages]
  );

  useEffect(() => {
    // Initiate select field to current language
    if (languageOptions.length && language?.languageCode != null) {
      setValue('language', language.languageCode);
    }
  }, [language?.languageCode, languageOptions, setValue]);

  useEffect(() => {
    if (
      selectedLanguage != null &&
      selectedLanguage !== language?.languageCode
    ) {
      // Update language on site when user changes it in the dropdown
      dispatch(setLanguageByCode(selectedLanguage));
    }
  }, [dispatch, selectedLanguage, language?.languageCode]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <SelectField
        label={intl.formatMessage({
          id: 'inputLanguageLabel',
          defaultMessage: 'Language',
          description: 'Label for language input',
        })}
        register={register('language')}
        options={languageOptions}
        error={errors.language}
      />
      <div style={{ height: '16px' }} />
      <TextField
        label={intl.formatMessage({
          id: 'inputEmailLabel',
          defaultMessage: 'Email',
          description: 'Label for email input',
        })}
        placeholder={intl.formatMessage({
          id: 'inputEmailPlaceholder',
          defaultMessage: 'Enter email',
          description: 'Placeholder for email input',
        })}
        error={errors.email}
        register={register('email', {
          required: intl.formatMessage({
            id: 'inputErrorEmailRequired',
            defaultMessage: 'Email is required',
            description: 'Input error for email required',
          }),
          pattern: {
            value: regExp.email,
            message: intl.formatMessage({
              id: 'inputErrorEmailInvalid',
              defaultMessage: 'Please enter a valid email',
              description: 'Input error for email invalid',
            }),
          },
        })}
      />
      <TextField
        label={intl.formatMessage({
          id: 'inputInviteCodeLabel',
          defaultMessage: 'Invite code',
          description: 'Label for invite code input',
        })}
        placeholder={intl.formatMessage({
          id: 'inputInviteCodePlaceholder',
          defaultMessage: 'Enter invite code',
          description: 'Placeholder for invite code input',
        })}
        register={register('inviteCode', {
          required: intl.formatMessage({
            id: 'inputErrorInviteCodeRequired',
            defaultMessage: 'Invite code is required',
            description: 'Input error for invite code required',
          }),
        })}
        error={errors.inviteCode}
      />
      <ActionContainer>
        <Button type="submit" color="black" background="yellow">
          <FormattedMessage
            id="registerButton"
            defaultMessage="Send"
            description="Register button text"
          />
        </Button>
      </ActionContainer>
    </form>
  );
};

export default RegisterInviteForm;
