import React, { FC, Fragment, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useGetAssetImage } from 'hooks/useGetAssetImage';
import { useAppDispatch } from 'hooks/redux';
import { setLayoutHeader, useGetUserQuery } from 'store';
import { calculateBMI } from 'utils/health';
import { calculateAge } from 'utils/date';
import * as routes from 'router/routes';

import Loader from 'components/UI/Loader';
import Icon, { IconType } from 'components/UI/Icon';
import Heading from 'components/UI/Heading';
import EmptyState from 'components/UI/EmptyState';
import HeroSmall from 'components/UI/Heros/HeroSmall';
import DataItem from 'components/UI/DataItem';

import {
  Img,
  ProfileHeader,
  ProfileHeaderData,
  ProfileHeaderDataGrid,
  ProfileImage,
} from './styles';
import UserSettingsList from 'components/fragments/UserSettingsList';

const Profile: FC = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  // Hooks
  const user = useGetUserQuery();

  // Hooks
  const [profileImage] = useGetAssetImage(user.data?.profilePictureAssetId);

  // Set header
  useEffect(() => {
    dispatch(
      setLayoutHeader({
        title: intl.formatMessage({
          id: 'profileTitle',
          defaultMessage: 'My profile',
          description: 'Title for my profile page',
        }),
        link: routes.SETTINGS,
        icon: IconType.Settings,
        inverted: true,
      })
    );
  }, [dispatch, intl]);

  // Loading
  if (user.isLoading) {
    return <Loader color="blue" padding />;
  }

  // No data
  if (!user.data) {
    return (
      <EmptyState iconType={IconType.Food} padding>
        <FormattedMessage
          id="pageProfileEmptyState"
          defaultMessage="Profile not found"
          description="Empty state for profile page"
        />
      </EmptyState>
    );
  }

  const { firstName, lastName, dateOfBirth, weightKg, lengthCm } = user.data;

  return (
    <Fragment>
      <HeroSmall>
        <ProfileHeader>
          <ProfileImage>
            {profileImage ?
              (
                <Img src={profileImage} alt={firstName} />
              )
              : (
                <Icon type={IconType.User} color="grayText" size={32} />
              )}
          </ProfileImage>
          <ProfileHeaderData>
            <Heading>
              {firstName} {lastName}
            </Heading>
            <ProfileHeaderDataGrid>
              <DataItem
                description={intl.formatMessage({
                  id: 'pageProfileAgeDescription',
                  defaultMessage: 'Age',
                  description: 'Profile page age description',
                })}
              >
                {dateOfBirth ? `${calculateAge(dateOfBirth)}` : null}
              </DataItem>
              <DataItem
                description={intl.formatMessage({
                  id: 'pageProfileLengthDescription',
                  defaultMessage: 'Length',
                  description: 'Profile page length description',
                })}
              >
                {lengthCm ? (
                  <FormattedMessage
                    id="pageProfileLengthValue"
                    defaultMessage="{value} cm"
                    description="Profile page length value"
                    values={{ value: lengthCm }}
                  />
                ) : null}
              </DataItem>
              <DataItem
                description={intl.formatMessage({
                  id: 'pageProfileWeightDescription',
                  defaultMessage: 'Weight',
                  description: 'Profile page weight description',
                })}
              >
                {weightKg ? (
                  <FormattedMessage
                    id="pageProfileWeightValue"
                    defaultMessage="{value} kg"
                    description="Profile page weight value"
                    values={{ value: weightKg }}
                  />
                ) : null}
              </DataItem>
              <DataItem
                description={intl.formatMessage({
                  id: 'pageProfileBMIDescription',
                  defaultMessage: 'BMI',
                  description: 'Profile page BMI description',
                })}
              >
                {weightKg && lengthCm
                  ? calculateBMI(weightKg, lengthCm).toFixed(1)
                  : null}
              </DataItem>
            </ProfileHeaderDataGrid>
          </ProfileHeaderData>
        </ProfileHeader>
      </HeroSmall>
      <UserSettingsList />
    </Fragment>
  );
};

export default Profile;
