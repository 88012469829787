import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import Button from 'components/UI/Button/Button';
import TextField from 'components/UI/TextField/TextField';

import { regExp } from 'utils/string';
import { ButtonWrapper } from './styles';

type Props = {
  submitForm: (email: string) => void;
  disabled?: boolean;
};

const UpdateUserEmailForm: FC<Props> = ({ disabled, submitForm }) => {
  const intl = useIntl();
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm();

  const onSubmit: SubmitHandler<FieldValues> = ({ email }) => submitForm(email);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        disabled={disabled}
        label={intl.formatMessage({
          id: 'inputEmailLabel',
          defaultMessage: 'Email',
          description: 'Label for email input',
        })}
        placeholder={intl.formatMessage({
          id: 'inputEmailPlaceholder',
          defaultMessage: 'Enter email',
          description: 'Placeholder for email input',
        })}
        error={errors.email}
        register={register('email', {
          required: intl.formatMessage({
            id: 'inputErrorEmailRequired',
            defaultMessage: 'Email is required',
            description: 'Input error for email required',
          }),
          pattern: {
            value: regExp.email,
            message: intl.formatMessage({
              id: 'inputErrorEmailInvalid',
              defaultMessage: 'Please enter a valid email',
              description: 'Input error for email invalid',
            }),
          },
        })}
      />
      <ButtonWrapper>
        <Button type="submit" background="blue" disabled={!isDirty || disabled}>
          <FormattedMessage
            id="confirmButton"
            defaultMessage="Confirm"
            description="Confirm button text"
          />
        </Button>
      </ButtonWrapper>
    </form>
  );
};

export default UpdateUserEmailForm;
