import { SelectOption } from 'models';
import { useMemo } from 'react';
import { useGetCompanyStructureQuery } from 'store';

export enum EntityType {
  Company = 'Company',
  HeadOffice = 'HeadOffice',
  Office = 'Office',
  Department = 'Department',
}

const useOrganizationEntityOptions = (companyId: string | undefined) => {
  const { data, isLoading } = useGetCompanyStructureQuery({ companyId });

  const organizationEntityOptions = useMemo(() => {
    if (!data) {
      return null;
    }

    const offices: SelectOption[] = [];
    const headOffices: SelectOption[] = [];
    const departments: SelectOption[] = (data.departments ?? []).map(
      ({ id, description }) => ({
        value: id,
        name: description ?? '',
      })
    );

    (data.headOffices ?? []).forEach((headOffice) => {
      headOffices.push({
        value: headOffice.id,
        name: headOffice.description ?? '',
      });
      (headOffice.offices ?? []).forEach((office) => {
        offices.push({
          value: office.id,
          name: `${office.description} (${headOffice.description})`,
        });
        (office.departments ?? []).forEach((department) => {
          departments.push({
            value: department.id,
            name: `${department.description} - ${office.description} (${headOffice.description})`,
          });
        });
      });
    });

    (data.offices ?? []).forEach((office) => {
      offices.push({
        value: office.id,
        name: office.description ?? '',
      });
      (office.departments ?? []).forEach((department) => {
        departments.push({
          value: department.id,
          name: `${department.description} - ${office.description}`,
        });
      });
    });

    return {
      departments,
      offices,
      headOffices,
    };
  }, [data]);

  return {
    departments: organizationEntityOptions?.departments,
    offices: organizationEntityOptions?.offices,
    headOffices: organizationEntityOptions?.headOffices,
    isLoading,
  };
};

export default useOrganizationEntityOptions;
