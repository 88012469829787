import styled from 'styled-components';
import { breakpoints, ThemeColor } from 'styles';

type MenuProps = {
  isActive: boolean;
  direction: 'up' | 'down';
  align: 'left' | 'right';
};

export const MenuContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
`;

export const MenuItem = styled.div`
  display: flex;
`;

export const Menu = styled.nav<MenuProps>`
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.borderLight};
  border-radius: 8px;
  position: absolute;
  top: ${({ direction }) => (direction === 'down' ? '42px' : 'unset')};
  bottom: ${({ direction }) => (direction === 'up' ? '42px' : 'unset')};
  left: ${({ align }) => (align === 'left' ? '-12px' : 'unset')};
  right: ${({ align }) => (align === 'right' ? '-12px' : 'unset')};
  width: 100%;
  max-width: 200px;
  min-width: 150px;
  text-align: left;
  z-index: 99;
  opacity: ${({ isActive }) => (isActive ? '1' : '0')};
  visibility: ${({ isActive }) => (isActive ? 'visible' : 'hidden')};
  transform: ${({ isActive, direction }) => {
    if (isActive) {
      return 'translateY(0)';
    }
    return `translateY(${direction === 'up' ? '20px' : '-20px'})`;
  }};
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
  padding: 8px 0;

  @media (max-width: ${breakpoints.s}px) {
    width: auto;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
`;

type ArrowProps = {
  direction: 'up' | 'down';
  align: 'left' | 'right';
};

export const Arrow = styled.img<ArrowProps>`
  position: absolute;
  right: ${({ align }) => (align === 'right' ? '16px' : 'unset')};
  left: ${({ align }) => (align === 'left' ? '16px' : 'unset')};
  top: ${({ direction }) => (direction === 'down' ? '-8px' : 'unset')};
  bottom: ${({ direction }) => (direction === 'up' ? '-8px' : 'unset')};
  transform: rotate(${({ direction }) => (direction === 'up' ? 180 : 0)}deg);
`;

type MenuItemProps = {
  color?: ThemeColor;
};

export const MenuItemInner = styled.div<MenuItemProps>`
  padding: 8px 16px;
  cursor: pointer;
  font-size: 14px;
  color: ${({ theme, color }) =>
    color ? theme.colors[color] : theme.colors.black};
  font-weight: 500;
`;
