import { FC, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import Heading, { Tag } from 'components/UI/Heading';
import ProgressBar from 'components/UI/ProgressBar';
import StaticCard from 'components/UI/Cards/StaticCard';
import Icon, { IconType } from 'components/UI/Icon';

import { parseDate } from 'utils/date';
import { ThemeColor } from 'styles';
import { HealthCategory, QuizAssessmentRef, QuizResult } from 'models';
import { useLocalizedText } from 'hooks/useLocalizedText';
import { getQuizResultColor } from 'utils/quiz';

import {
  Grid,
  Column,
  Header,
  HeaderCol,
  ProgressGrid,
  DisabledTitle,
  ProgressItem,
  TestDate,
} from './styles';

type TestStatus = {
  text: string;
  color: ThemeColor;
};

type Props = {
  category: HealthCategory | null;
  results?: QuizResult[];
};

const TestHistoryCard: FC<Props> = ({ category, results = [] }) => {
  const getText = useLocalizedText();

  // Get status
  const getStatus = useCallback(
    (ref: QuizAssessmentRef | undefined): TestStatus => {
      return {
        text: ref ? getText(ref.title) : '-',
        color: ref ? getQuizResultColor(ref.level) : 'error',
      };
    },
    [getText]
  );

  if (!results.length) {
    return null;
  }

  return (
    <StaticCard>
      <Grid>
        <Column>
          <Header>
            <HeaderCol>
              <Icon type={IconType.Exercise} />
              {category && (
                <DisabledTitle>{getText(category.title)}</DisabledTitle>
              )}
            </HeaderCol>
            <HeaderCol />
          </Header>
          <ProgressGrid>
            {results.map(({ percentage, created, assessment }, i) => {
              const { text, color } = getStatus(assessment.assessmentReference);
              return (
                <ProgressItem key={`progress-${i}`}>
                  <Header>
                    <Heading tag={Tag.H3}>{text}</Heading>
                    <span>{percentage}%</span>
                  </Header>
                  <ProgressBar progress={percentage} color={color} />
                  <TestDate>
                    <FormattedMessage
                      id="lastTestedDate"
                      defaultMessage="Last tested: {date}"
                      description="Last tested date for health card"
                      values={{
                        date: parseDate(created),
                      }}
                    />
                  </TestDate>
                </ProgressItem>
              );
            })}
          </ProgressGrid>
        </Column>
      </Grid>
    </StaticCard>
  );
};

export default TestHistoryCard;
