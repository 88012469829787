import { type SelectOption } from 'models';

export function removeElementFromArray(array: string[], item: string) {
  const index = array.indexOf(item);
  array.splice(index, 1);
  return array;
}

export function addOrRemoveFromArray(array: string[], item: string) {
  const index = array.indexOf(item);
  if (index !== -1) {
    return array.filter((value) => value !== item);
  }
  return [...array, item];
}

export function sortArrayByProperty<T>(
  array: T[],
  property: keyof T,
  order: 'asc' | 'desc' = 'asc'
) {
  return [...array].sort((a, b) => {
    const aProp = a[property];
    const bProp = b[property];

    if (aProp < bProp) {
      return order === 'asc' ? -1 : 1;
    }
    if (aProp > bProp) {
      return order === 'asc' ? 1 : -1;
    }
    return 0;
  });
}

interface ArrayGroup<T> {
  [key: string]: T[];
}

export function groupArrayByProperty<T>(
  array: T[],
  property: keyof T
): ArrayGroup<T> {
  return array.reduce((groups: ArrayGroup<T>, item: T) => {
    const key = String(item[property]);
    if (!groups[key]) {
      groups[key] = [];
    }
    groups[key].push(item);
    return groups;
  }, {});
}

export function filterArrayByTags(
  filters: string[],
  tags: Array<{ slug: string | null }> | null
) {
  if (!filters.length) {
    return true;
  }
  if (!tags) {
    return false;
  }
  return tags.some((tag) => tag && tag.slug && filters.includes(tag.slug));
}

/**
 * Return a non-null unique array that is alphabetically sorted in a SelectOption structure
 */
export function selectOptionsParser(arr: Array<string | null>): SelectOption[] {
  return Array.from(new Set(arr))
    .sort()
    .filter((k): k is string => k != null)
    .map((k) => ({
      name: k,
      value: k,
    }));
}
