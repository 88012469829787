import { FC } from 'react';

import { Image } from 'models';
import { Category } from 'styles';

import Heading, { Tag } from 'components/UI/Heading';
import ClickableCard from 'components/UI/Cards/ClickableCard/ClickableCard';

import {
  CardContent,
  CardImage,
  CardText,
  ImageView,
  Badge,
  TextBottom,
} from './styles';
import { useAppSelector } from 'hooks/redux';
import { localizationSelector } from 'store';

type Props = {
  onClick?: () => void;
  category?: string | null;
  title: string | null;
  byline?: string | null;
  image?: Image | null;
  size?: 'large' | 'small';
};

const ImageCard: FC<Props> = ({
  onClick,
  category,
  title,
  byline,
  image,
  size = 'large',
}) => {
  const { language } = useAppSelector(localizationSelector);

const categoryMapping = {
  sv: 'kosthållning',
  da: 'kost',
  no: 'kosthold',
  en: 'diet',
};

if (language && category === '-') {
  category = categoryMapping[language.languageCode];
}
  return (
    <ClickableCard onClick={onClick}>
      <CardContent size={size}>
        <CardImage>
          {size === 'small' && category && <Badge>{category}</Badge>}
          {image && <ImageView size={size} src={image.src} alt={image.alt} />}
        </CardImage>
        <CardText>
          {size === 'large' && category && <Category>{category}</Category>}
          <Heading tag={Tag.H5}>{title}</Heading>
          {size === 'small' && <TextBottom>{byline}</TextBottom>}
        </CardText>
      </CardContent>
    </ClickableCard>
  );
};

export default ImageCard;
