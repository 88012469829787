import { ErrorMessageResponse, Language } from 'models';
import { baseApi } from '../baseApi';

const maintenanceApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getErrorMessages: builder.query<ErrorMessageResponse, void>({
      query: () => '/maintenance/error-messages',
    }),
    getLanguages: builder.query<Language[], void>({
      query: () => '/maintenance/languages',
    }),
  }),
});

export const { useGetErrorMessagesQuery, useGetLanguagesQuery } =
  maintenanceApi;
