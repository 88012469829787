import styled from 'styled-components';

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
`;

export const CardHeading = styled.span`
  font-size: 16px;
  font-weight: 900;
`;

type Props = {
  isActive?: boolean
}

export const CardDescription = styled.div<Props>`
  font-size: 12px;
  fony-family: 'Mark-Medium';
  color: ${({ theme, isActive }) => isActive ? theme.colors.blueDark : theme.colors.pinkDark};
`;

export const IconContainer = styled.div`
  cursor: pointer;
  margin-block: auto;
`;
