import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};
  padding: 16px;
  font-size: 16px;
  border-radius: 16px;
  gap: 16px;
  cursor: pointer;
  user-select: none;
`;
