import React, { FC, Fragment, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { useLocalizedText } from 'hooks/useLocalizedText';
import { useCompleteHealthChoiceMutation } from 'store';
import { HealthChoice } from 'models';
import { Gap } from 'styles';

import Button from 'components/UI/Button';
import StaticCard from 'components/UI/Cards/StaticCard';
import Heading, { Tag } from 'components/UI/Heading';

import { List, Item, ItemText, Column } from './styles';
import CheckBadge from 'components/UI/CheckBadge';
import { toast } from 'react-hot-toast';

type Props = {
  data: HealthChoice[];
  contestId: string;
  goalId: string;
  completed?: string[] | null;
  disabled?: boolean;
};

const HealthChoices: FC<Props> = ({
  data,
  contestId,
  goalId,
  completed,
  disabled,
}) => {
  const getText = useLocalizedText();
  const [checkedHealthChoices, setCheckedHealthChoices] = React.useState<
    string[]
  >([]);

  // Hooks
  const [completeHealthChoice] = useCompleteHealthChoiceMutation();

  // Complete
  const onComplete = useCallback(
    (healthChoiceId: string) => async () => {
      try {
        setCheckedHealthChoices((prev) => [...prev, healthChoiceId]); // Optimistic update
        await completeHealthChoice({
          contestId,
          goalId,
          healthChoiceId,
        }).unwrap();
      } catch (e) {
        // Rollback optimstic update on error
        setCheckedHealthChoices((prev) =>
          prev.filter((id) => id !== healthChoiceId)
        );

        // Show error feedback to user
        if (
          e != null &&
          typeof e === 'object' &&
          'data' in e &&
          e.data != null
        ) {
          return; // global toast handler will show the error provided by API to user, do nothing.
        }
        const status =
          e != null && typeof e === 'object' && 'status' in e
            ? e.status
            : 'unknown';
        toast.error(
          `Unexpected error occured, status: ${status}. Please try again and contact support if the error persists.`
        );
      }
    },
    [completeHealthChoice, contestId, goalId]
  );

  // No data
  if (!data.length) {
    return null;
  }

  return (
    <Fragment>
      <Heading tag={Tag.H4}>
        <FormattedMessage
          id="healthChoiceTitle"
          defaultMessage="Health choices"
          description="Title for health choices"
        />
      </Heading>
      <StaticCard>
        <List>
          {data.map((item, i) => {
            return (
              <Item key={`health-choise-${i}`} isLast={data.length === i + 1}>
                <Column>
                  <Heading tag={Tag.H4}>{getText(item.title)}</Heading>
                  <ItemText>
                    <FormattedMessage
                      id="healthChoiceBonus"
                      defaultMessage="{bonus} steps"
                      description="Bonus text for health choices"
                      values={{ bonus: item.bonus }}
                    />
                  </ItemText>
                </Column>
                <div>
                  {!completed?.includes(item.id) &&
                  !checkedHealthChoices.includes(item.id) ? (
                    <Button
                      background="blue"
                      onClick={onComplete(item.id)}
                      disabled={disabled}
                    >
                      <FormattedMessage
                        id="doneButton"
                        defaultMessage="Done"
                        description="Done button text"
                      />
                    </Button>
                  ) : (
                    <CheckBadge type="grey" />
                  )}
                </div>
              </Item>
            );
          })}
        </List>
      </StaticCard>
      <Gap />
    </Fragment>
  );
};

export default HealthChoices;
