import ContentModal from 'components/modals/ContentModal';
import HeadingWithAdd from 'components/UI/HeadingWithAdd';
import Loader from 'components/UI/Loader';
import Table from 'components/UI/Table/Table';
import TextField from 'components/UI/TextField';
import useModal from 'hooks/useModal';
import { FC, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  useCreateCompanyInviteCodeMutation,
  useGetCompanyInviteCodesQuery,
  useUpdateCompanyInviteCodeMutation,
} from 'store';
import { dateStringToISOString, formatDateTime } from 'utils/date';
import { useAppSelector } from 'hooks/redux';
import { localizationSelector } from 'store';
import SelectField from 'components/UI/SelectField';
import { LanguageCode } from 'models';
import Button from 'components/UI/Button';
import { ActionContainer } from 'styles';
import DepartmentSelect from '../DepartmentSelect';

type Props = {
  companyId: string;
};

type InvitationCodeForm = {
  maxRegisteredUsers: number;
  expires: string;
  departmentId: string | null;
  inviteCode: string;
  language: LanguageCode;
  departmentSearch: string;
};

const CompanyInviteCodes: FC<Props> = ({ companyId }) => {
  const intl = useIntl();
  const { data, isLoading } = useGetCompanyInviteCodesQuery({ companyId });

  const tableColumns = useMemo(
    () => [
      {
        key: 'inviteCode' as const,
        title: intl.formatMessage({
          id: 'tableColumnHeaderInviteCode',
          defaultMessage: 'Invitation code',
          description: 'Table column header for InviteCode',
        }),
      },
      {
        key: 'expires' as const,
        title: intl.formatMessage({
          id: 'tableColumnHeaderExpires',
          defaultMessage: 'Expires',
          description: 'Table column header for expires',
        }),
      },
      {
        key: 'departmentDescription' as const,
        title: intl.formatMessage({
          id: 'tableColumnHeaderDepartmentDescription',
          defaultMessage: 'Department',
          description: 'Table column header for register count',
        }),
      },
      {
        key: 'registerCount' as const,
        title: intl.formatMessage({
          id: 'tableColumnHeaderRegisterCount',
          defaultMessage: 'Registered users',
          description: 'Table column header for register count',
        }),
      },
    ],
    [intl]
  );

  const tableData = useMemo(() => {
    return (
      data?.map(
        ({
          id,
          expires,
          inviteCode,
          maxRegisteredUsers,
          registeredUsers,
          departmentDescription,
          language,
        }) => ({
          id,
          expires: formatDateTime(new Date(expires)),
          inviteCode,
          registeredUsers,
          maxRegisteredUsers,
          registerCount: `${registeredUsers}/${maxRegisteredUsers}`,
          departmentDescription: departmentDescription ?? '-',
          language,
        })
      ) ?? []
    );
  }, [data]);

  const [selectedRow, setSelectedRow] =
    useState<Required<(typeof tableData)[number]>>();
  const {
    modalOpen: invitationCodeModalOpen,
    onCloseModal: onCloseInvitationCodeModal,
    onOpenModal: onOpenInvitationCodeModal,
  } = useModal();

  const { languages, language } = useAppSelector(localizationSelector);

  const languageOptions = useMemo(
    () =>
      languages.map((item) => ({
        value: item.languageCode,
        name: item.name,
      })),
    [languages]
  );

  const {
    register,
    setValue,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm<InvitationCodeForm>({
    defaultValues: {
      expires: '',
      inviteCode: '',
      maxRegisteredUsers: 100,
      departmentId: null,
      language: language?.languageCode,
    },
  });

  const isEditing = selectedRow != null;

  const [updateInvitationCode, { isLoading: updateIsLoading }] =
    useUpdateCompanyInviteCodeMutation();

  const [createInvitationCode, { isLoading: createIsLoading }] =
    useCreateCompanyInviteCodeMutation();

  const onSubmit = async (data: InvitationCodeForm) => {
    const sharedArgs = {
      maxRegisteredUsers: data.maxRegisteredUsers,
      expires: dateStringToISOString(data.expires),
      inviteCode: data.inviteCode,
      language: data.language,
    };
    if (isEditing) {
      await updateInvitationCode({
        ...sharedArgs,
        id: selectedRow.id,
      }).unwrap();
    } else {
      await createInvitationCode({
        ...sharedArgs,
        companyId,
        departmentId: data.departmentId,
      }).unwrap();
    }
    onCloseInvitationCodeModal();
    setSelectedRow(undefined);
  };

  return (
    <>
      <HeadingWithAdd
        title={intl.formatMessage({
          id: 'adminCompanyInviteCodesTitle',
          defaultMessage: 'Company invitation codes',
          description: 'Company invite codes heading title',
        })}
        addTitle={intl.formatMessage({
          id: 'adminCompanyInviteCodesAddTitle',
          defaultMessage: 'Create invitation code',
          description: 'Add invite code button title',
        })}
        onAdd={() => {
          setSelectedRow(undefined);
          reset({
            maxRegisteredUsers: 100,
            expires: '',
            inviteCode: '',
            language: language?.languageCode,
          });
          onOpenInvitationCodeModal();
        }}
        addDisabled={false}
      />
      {isLoading ? (
        <Loader color="blue" padding />
      ) : (
        <Table
          emptyText={intl.formatMessage({
            id: 'adminCompanyInviteCodesEmpty',
            defaultMessage: 'No invitation codes exists for this company.',
            description: 'Company invite codes empty state',
          })}
          columns={tableColumns}
          data={tableData}
          onRowClick={(row) => {
            reset({
              maxRegisteredUsers: row.maxRegisteredUsers,
              expires: row.expires,
              inviteCode: row.inviteCode ?? '',
              language: row.language,
            });
            setSelectedRow(row);
            onOpenInvitationCodeModal();
          }}
        />
      )}
      <ContentModal
        isOpen={invitationCodeModalOpen}
        onClose={onCloseInvitationCodeModal}
        title={
          isEditing
            ? intl.formatMessage({
                id: 'adminCompanyInviteCodesEditModalTitle',
                defaultMessage: 'Edit invitation code',
                description: 'Company invite codes edit modal heading title',
              })
            : intl.formatMessage({
                id: 'adminCompanyInviteCodesCreateModalTitle',
                defaultMessage: 'Create invitation code',
                description: 'Company invite codes create modal heading title',
              })
        }
      >
        {updateIsLoading || createIsLoading ? (
          <Loader color="blue" padding />
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              label={intl.formatMessage({
                id: 'inputInvitationCodeMaxRegisteredUsersLabel',
                defaultMessage: 'Maximum users allowed to use this code',
                description: 'Label for maxRegisteredUsers input',
              })}
              error={errors.maxRegisteredUsers}
              register={register('maxRegisteredUsers', {
                required: true,
                valueAsNumber: true,
              })}
              type="number"
            />
            <TextField
              label={intl.formatMessage({
                id: 'inputInvitationCodeInviteCodeLabel',
                defaultMessage: 'Code',
                description: 'Label for inviteCode input',
              })}
              error={errors.maxRegisteredUsers}
              register={register('inviteCode', {
                required: true,
                minLength: 1,
              })}
              placeholder="XXXXXX"
            />
            <TextField
              label={intl.formatMessage({
                id: 'inputInvitationCodeExpiresLabel',
                defaultMessage: 'Expires',
                description: 'Label for expires input',
              })}
              error={errors.expires}
              register={register('expires', {
                required: true,
              })}
              type="datetime-local"
            />
            <SelectField
              label={intl.formatMessage({
                id: 'inputLanguageLabel',
                defaultMessage: 'Language',
                description: 'Label for language input',
              })}
              register={register('language')}
              options={languageOptions}
              error={errors.language}
            />
            {!isEditing && (
              <>
                <br />
                <DepartmentSelect
                  companyId={companyId}
                  onChange={(departmentId) => {
                    setValue('departmentId', departmentId);
                  }}
                />
              </>
            )}
            <ActionContainer>
              <Button type="submit" background="blue">
                <FormattedMessage
                  id="saveButton"
                  defaultMessage="Save"
                  description="Save button text"
                />
              </Button>
            </ActionContainer>
          </form>
        )}
      </ContentModal>
    </>
  );
};

export default CompanyInviteCodes;
