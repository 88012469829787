import { ContentImage, ContentText, Reference } from "models/content/content";

export type TimedChallenge = {
  id: string;
  slug: string;
  type: string;
  title: ContentText;
  description: ContentText | null;
  wellrPoints: number;
  allowedActivities: string[];
  allowAllActivities: boolean;
  reference: Reference[];
  image: ContentImage | null;
  challengeType: ChallengeType;
  amountToComplete: number;
  unit: ActivitySlugUnit;
}

export type TimedChallengeResult = {
  id: string;
  wellrPoints: number;
  timedChallengeId: string;
  userId: string;
  membershipId: string;
  completed: string;
}

export type TimedChallengeResultInDto = {
  timedChallengeId: string;
  wellrPoints: number;
  userId: string;
  membershipId: string;
}

export type ActivitySlugUnit = {
  slug: string;
}

export enum ChallengeType {
  Activity = 'activity',
  Content = 'content',
}