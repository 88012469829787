import styled from 'styled-components';

export const CategoryHeader = styled.div`
  h1 {
    margin: 0;
  }

  p {
    color: ${({ theme }) => theme.colors.white};
  }

  span {
    color: ${({ theme }) => theme.colors.white};
    font-size: 14px;
  }
`;

export const Byline = styled.div`
  display: flex;
  align-items: center;
  margin: 16px 0 0;
`;

export const CardHeading = styled.span`
  font-size: 14px;
  font-weight: 900;
  text-transform: uppercase;
`;

export const TeamsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const DarkModeContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DarkModeCol = styled.div`
  display: flex;
  gap: 4px;
`;

export const ContestInfoContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  margin-bottom 48px;
`;

export const ContestInfoItem = styled.div``;
