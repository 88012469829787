import React, { FC, useEffect, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';

import { useUpdateUserEmailMutation } from 'store';
import * as routes from 'router/routes';
import { UpdateEmailValues, User } from 'models';
import UpdateUserEmailForm from 'components/forms/UpdateUserEmailForm';

import Loader from 'components/UI/Loader/Loader';
import Icon, { IconType } from 'components/UI/Icon';
import IconButton from 'components/UI/IconButton';
import Heading, { Tag } from 'components/UI/Heading';
import SlideoutModal from 'components/UI/SlideoutModal';
import { useLocation } from 'react-router-dom';


import { Header, Body } from './styles';

type Props = {
  user: User;
  isOpen: boolean;
  onClose: () => void;
};

const UpdateUserEmailModal: FC<Props> = ({ user, isOpen, onClose }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();

  const [updateEmail, updateEmailResult] = useUpdateUserEmailMutation();

  // Update email
  const onUpdateEmail = useCallback(
    (newEmail: string) => {
      updateEmail({
        currentEmail: user.email,
        newEmail,
      });
    },
    [updateEmail, user.email]
  );

  useEffect(() => {
    if (updateEmailResult.isSuccess) {
      toast.success(
        intl.formatMessage({
          id: 'updateUserModalSuccess',
          defaultMessage: 'User update successful',
          description: 'User update success message',
        })
      );
      onClose();

      if (location.pathname.includes('admin/users')) {
        const { newEmail } = updateEmailResult.originalArgs as UpdateEmailValues;
        navigate(`${routes.ADMIN_USERS}/${newEmail}`, {
          replace: true,
        });
      } else {
        window.location.href = '/admin/departments';
      }
    }
  }, [updateEmailResult, intl, onClose, navigate, location.pathname]);

  // Content
  const content = useMemo(() => {
    if (updateEmailResult.isLoading) {
      return <Loader padding color="blue" />;
    }
    return <UpdateUserEmailForm submitForm={onUpdateEmail} />;
  }, [onUpdateEmail, updateEmailResult]);

  return (
    <SlideoutModal isOpen={isOpen} onClose={onClose}>
      <div>
        <Header>
          <Heading tag={Tag.H3}>
            <FormattedMessage
              id="manageUserModalTitle"
              defaultMessage="Change email"
              description="Change email modal title"
            />
          </Heading>
          <IconButton onClick={onClose} padding>
            <Icon type={IconType.Close} />
          </IconButton>
        </Header>
        <Body>{content}</Body>
      </div>
    </SlideoutModal>
  );
};

export default UpdateUserEmailModal;
