import styled from 'styled-components';

export const NestedCard = styled.div`
  padding-left: 25px;
  margin-top: -6px;
`;

export const CheckboxContentList = styled.div`
  margin-top: 2px;
  padding-left: 30px;
`;

export const CardsWrapper = styled.div`
  background: ${({ theme }) => theme.colors.backgroundLight};
  border-radius: 16px;
  > * {
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
    padding-bottom: 8px;
    &:last-child {
      border-bottom: none;
    }
  }
`;
