import React, { FC, useEffect, useMemo } from 'react';
import Loader from 'components/UI/Loader';
import IconButton from 'components/UI/IconButton';
import SlideoutModal from 'components/UI/SlideoutModal';
import Heading, { Tag } from 'components/UI/Heading';
import Icon, { IconType } from 'components/UI/Icon';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-hot-toast';
import { Header, Body } from './styles';
import { useAddPersonalNotificationScheduleMutation } from 'store/notificationScheduleService/notificationScheduleService';
import RegisterContentForm from 'components/forms/RegisterContentForm';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  slug?: string | null;
  redirectUrl?: string;
};

const RegisterContentModal: FC<Props> = ({
  isOpen,
  onClose,
  slug,
  redirectUrl
}) => {
  const intl = useIntl();

  // Hooks
  const [registerContent, registerResult] = useAddPersonalNotificationScheduleMutation();

  useEffect(() => {
    if (registerResult.isSuccess) {
      toast.success(
        intl.formatMessage({
          id: 'RegisterHealthFocusModalSuccess',
          defaultMessage: 'Health focus registration successful',
          description: 'Register health focus modal success',
        })
      );
      onClose();
    }
  }, [registerResult, intl, onClose]);

  const content = useMemo(() => {
    if (registerResult.isLoading ) {
      return <Loader padding color="blue" />;
    }

    return (
      <RegisterContentForm
        submitForm={registerContent}
        isOpen={isOpen}
        slug={slug}
        redirectUrl={redirectUrl}
      />
    );
  }, [isOpen, redirectUrl, registerContent, registerResult.isLoading, slug]);

  return (
    <SlideoutModal isOpen={isOpen} onClose={onClose}>
      <div>
        <Header>
          <Heading tag={Tag.H3}>
            <FormattedMessage
              id="RegisterHealthFocusModalTitle"
              defaultMessage="Register health focus"
              description="Register health focus modal title"
            />
          </Heading>
          <IconButton onClick={onClose} padding>
            <Icon type={IconType.Close} />
          </IconButton>
        </Header>
        <Body>{content}</Body>
      </div>
    </SlideoutModal>
  );
};

export default RegisterContentModal;
