import styled from 'styled-components';

export const FieldGrid = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
`;

export const InfoText = styled.p`
  font-size: 14px;
  margin-top: 8px;
  text-align: left;
  color: ${({ theme }) => theme.colors.grayText};
`;

export const SelectItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
  cursor: pointer;
`;
