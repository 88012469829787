import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { ContestMe, ContestTeamUser } from 'models';
import { sortArrayByProperty } from 'utils/array';

import StaticCard from 'components/UI/Cards/StaticCard';
import EmptyState from 'components/UI/EmptyState';
import { IconType } from 'components/UI/Icon';
import TopListProgressItem from 'components/fragments/TopListProgressItem';

import { TeamList } from './styles';

type Props = {
  me: ContestMe;
  users?: ContestTeamUser[];
  individualResults?: boolean;
  total: number;
};

const CompetitionTopList: FC<Props> = ({
  me,
  users,
  individualResults,
  total,
}) => {
  // Check if users exist
  if (!users?.length) {
    return (
      <EmptyState iconType={IconType.Users} padding>
        <FormattedMessage
          id="competitionTopListEmptyState"
          defaultMessage="No users found"
          description="Empty state for competition top list"
        />
      </EmptyState>
    );
  }

  return (
    <StaticCard>
      <TeamList>
        {sortArrayByProperty(users, 'total', 'desc').map((user, i) => {
          let totalSteps: number;
          let progress: number | null;
          const showResults = individualResults || user.id === me.id;

          if (showResults) {
            const userTotal = user.id === me.id ? me.total : user.total;
            const cut = userTotal / total;
            progress = !isNaN(cut) && isFinite(cut) ? cut * 100 : 0;
            totalSteps = userTotal;
          } else {
            progress = null;
            totalSteps = 0;
          }

          return (
            <TopListProgressItem
              key={user.id}
              email={user.email}
              alias={user.alias}
              profilePictureAssetId={user.profilePictureAssetId}
              showResults={showResults}
              totalSteps={totalSteps}
              progress={progress}
              isLast={users.length === i + 1}
            />
          );
        })}
      </TeamList>
    </StaticCard>
  );
};

export default CompetitionTopList;
