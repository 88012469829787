import React, { FC, Fragment, useCallback, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  localizationSelector,
  setLayoutHeader,
  useCompleteExerciseDescriptionPairMutation,
  useDeleteExerciseDescriptionPairMutation,
  useGetCompletedExerciseEntitiesQuery,
  useGetExerciseQuery,
} from 'store';

import { Category, ContentWidth, Gap } from 'styles';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useLocalizedText } from 'hooks/useLocalizedText';

import RichText from 'components/fragments/RichText';
import HeroSmall from 'components/UI/Heros/HeroSmall';
import Heading from 'components/UI/Heading';
import Loader from 'components/UI/Loader';
import { IconType } from 'components/UI/Icon';

import { ButtonGrid, CategoryHeader } from './styles';
import Button from 'components/UI/Button';

const ExerciseGuide: FC = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const getText = useLocalizedText();
  const { slug } = useParams();
  const { language } = useAppSelector(localizationSelector);
  const location = useLocation();
  const navigate = useNavigate();

  const { exerciseKey, exerciseProgramSlug } = location.state ?? {};
  const showCompletedState = exerciseKey != null && exerciseProgramSlug != null;

  const { isCompleted, isLoading: completedIsLoading } =
    useGetCompletedExerciseEntitiesQuery(
      {
        exerciseProgramSlug: exerciseProgramSlug as string,
      },
      {
        skip: !showCompletedState,
        selectFromResult: (result) => {
          return {
            isLoading: result.isLoading,
            isCompleted:
              result.data?.completedExercises?.find(
                (pair) => pair.key === exerciseKey
              ) != null,
          };
        },
      }
    );
  const [checkProgramExercise, { isLoading: checkIsLoading }] =
    useCompleteExerciseDescriptionPairMutation();
  const [uncheckProgramExercise, { isLoading: uncheckIsLoading }] =
    useDeleteExerciseDescriptionPairMutation();

  // Hooks
  const { data, isLoading } = useGetExerciseQuery({
    slug,
    language: language?.languageCode,
  });

  // Set header
  useEffect(() => {
    if (slug) {
      dispatch(
        setLayoutHeader({
          title: intl.formatMessage({
            id: 'backButton',
            defaultMessage: 'Back',
            description: 'Back button text',
          }),
          inverted: true,
          icon: IconType.Back,
          reminder: { slug: slug, redirectUrl: window.location.href },
          favorite: {
            reference: slug,
            type: 'exercise',
          },
        })
      );
    }
  }, [dispatch, intl, slug]);

  const onToggleCompleted = useCallback(async () => {
    const result = (
      isCompleted ? uncheckProgramExercise : checkProgramExercise
    )({
      exerciseProgramSlug: exerciseProgramSlug as string,
      exerciseKey: exerciseKey as string,
    });
    if (!isCompleted) {
      await result.unwrap();
      navigate(-1);
    }
  }, [
    isCompleted,
    uncheckProgramExercise,
    checkProgramExercise,
    exerciseProgramSlug,
    exerciseKey,
    navigate,
  ]);

  const renderMarkButton = useCallback(() => {
    if (completedIsLoading || checkIsLoading || uncheckIsLoading) {
      console.log({ completedIsLoading, checkIsLoading, uncheckIsLoading });
      return <Loader color="blue" />;
    }
    if (isCompleted) {
      return (
        <Button
          background="white"
          color="pink"
          border="pink"
          onClick={onToggleCompleted}
        >
          <FormattedMessage
            id="pageMeasureProgramStepUnmarkDone"
            defaultMessage="Unmark as done"
            description="Unmark as done button for exercise program step"
          />
        </Button>
      );
    }
    return (
      <Button
        background="blue"
        color="white"
        border="blue"
        onClick={onToggleCompleted}
      >
        <FormattedMessage
          id="pageMeasureProgramStepMarkDone"
          defaultMessage="Mark as done"
          description="Mark as done button for exercise program step"
        />
      </Button>
    );
  }, [
    checkIsLoading,
    completedIsLoading,
    isCompleted,
    onToggleCompleted,
    uncheckIsLoading,
  ]);

  // Loading
  if (isLoading) {
    return <Loader color="blue" padding />;
  }

  // No data
  if (!data) {
    return null;
  }

  const { title, content } = data;

  return (
    <Fragment>
      <HeroSmall>
        <CategoryHeader>
          <Category>
            <FormattedMessage
              id="pageExerciseTitle"
              defaultMessage="Exercise"
              description="Page title for exercise"
            />
          </Category>
          <Heading>{getText(title)}</Heading>
        </CategoryHeader>
      </HeroSmall>
      <ContentWidth isSurface>
        {content && <RichText>{content}</RichText>}
        <Gap />
        {showCompletedState && <ButtonGrid>{renderMarkButton()}</ButtonGrid>}
      </ContentWidth>
    </Fragment>
  );
};

export default ExerciseGuide;
