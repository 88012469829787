import { FC, MouseEventHandler, ReactNode } from 'react';

import { Card } from './styles';

type Props = {
  inverted?: boolean;
  children: ReactNode;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

const ClickableCard: FC<Props> = ({ onClick, children, inverted = false }) => {
  return (
    <Card role="button" onClick={onClick} inverted={inverted}>
      {children}
    </Card>
  );
};

export default ClickableCard;
