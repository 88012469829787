import AdminQuizResults from 'components/fragments/AdminQuizResults';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

const AdminHealthQuizResults: FC = () => {
  const { id } = useParams<{
    id: string;
  }>();
  if (id == null) {
    return null;
  }
  return <AdminQuizResults id={id} quizType={'health'} />;
};

export default AdminHealthQuizResults;
