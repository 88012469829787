import React, { FC } from 'react';
import { ThemeColor } from 'styles';

import { Container } from './styles';

type Props = {
  background?: ThemeColor;
  color?: ThemeColor;
  children: string;
};

const Badge: FC<Props> = ({
  background = 'black',
  color = 'white',
  children,
}) => (
  <Container background={background} color={color}>
    {children}
  </Container>
);

export default Badge;
