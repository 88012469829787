import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import Heading from 'components/UI/Heading';
import BackgroundCover from 'components/UI/BackgroundCover';
import CoverHeader from 'components/UI/CoverHeader';

import { CoverContent, Description } from 'styles';
import { useLanguageFromQuery } from 'hooks/useLanguageFromQuery';

const RegisterInviteSuccess: FC = () => {
  useLanguageFromQuery();
  return (
    <BackgroundCover padding>
      <CoverHeader />
      <CoverContent>
        <Heading>
          <FormattedMessage
            id="registerInviteSuccessTitle"
            defaultMessage="Thank you for your registration"
            description="Register invite success page title"
          />
        </Heading>
        <Description>
          <FormattedMessage
            id="registerInviteSuccess"
            defaultMessage="You will shortly receive a registration email from no‑reply@wellr.se which you may use to finalise your registration. Please check your junk mail if you have not received an email."
            description="Register invite success message"
          />
        </Description>
      </CoverContent>
    </BackgroundCover>
  );
};

export default RegisterInviteSuccess;
