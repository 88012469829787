import { FC, useEffect, Fragment, useMemo, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ContentWidth, FlexContainer, MediumGap } from 'styles';
import { useAppDispatch } from 'hooks/redux';
import {
  setLayoutHeader,
  useDeleteCompanyMutation,
  useGetCompanyMembershipInfoQuery,
  useGetCompanyQuery,
  useUpdateCompanyMutation,
} from 'store';

import Heading, { Tag } from 'components/UI/Heading';
import Loader from 'components/UI/Loader';

import Icon, { IconType } from 'components/UI/Icon';

import * as routes from 'router/routes';
import { useNavigate, useParams } from 'react-router-dom';
import EmptyState from 'components/UI/EmptyState';
import Table from 'components/UI/Table/Table';
import IconButton from 'components/UI/IconButton';
import useModal from 'hooks/useModal';
import ConfirmActionPopup from 'components/popups/ConfirmActionPopup';
import { toast } from 'react-hot-toast';
import UpdateCompanyForm from 'components/forms/UpdateCompanyForm';
import CompanyCommunicationManager from 'components/fragments/CompanyCommunicationManager';
import CompanyInviteCodes from 'components/fragments/CompanyInviteCodes';

const AdminCompanyDetails: FC = () => {
  const intl = useIntl();
  const { companyId } = useParams();
  const dispatch = useAppDispatch();
  const [isDeleting, setIsDeleting] = useState(false);

  // Hooks
  const companyResult = useGetCompanyQuery(
    {
      companyId: companyId as string,
    },
    { skip: !companyId || isDeleting }
  );

  const companyMembershipResult = useGetCompanyMembershipInfoQuery(
    {
      companyDescription: companyResult.data?.description as string,
    },
    { skip: !companyResult.data?.description }
  );

  const navigate = useNavigate();
  const {
    modalOpen: confirmDeleteOpen,
    onCloseModal: onCloseConfirmDelete,
    onOpenModal: onOpenConfirmDelete,
  } = useModal();

  const [updateCompany, updateCompanyResult] = useUpdateCompanyMutation();

  useEffect(() => {
    if (updateCompanyResult.isSuccess) {
      toast.success(
        intl.formatMessage({
          id: 'updateCompanySuccess',
          defaultMessage: 'Company updated successfully',
          description: 'Company update toast success message',
        })
      );
    } else if (updateCompanyResult.isError) {
      toast.error(
        intl.formatMessage({
          id: 'updateCompanyError',
          defaultMessage: 'Company update failed',
          description: 'Company updated toast error message',
        })
      );
    }
  }, [updateCompanyResult.isSuccess, updateCompanyResult.isError, intl]);

  const [deleteCompany, deleteCompanyResult] = useDeleteCompanyMutation();

  const onDeleteCompany = useCallback(async () => {
    setIsDeleting(true); // prevent refetch
    deleteCompany({ companyId: companyId as string });
  }, [deleteCompany, companyId, setIsDeleting]);

  useEffect(() => {
    if (deleteCompanyResult.isSuccess) {
      toast.success(
        intl.formatMessage({
          id: 'deleteCompanySuccess',
          defaultMessage: 'Company deleted successfully',
          description: 'Company delete toast success message',
        })
      );
      navigate(routes.ADMIN_COMPANIES);
      setIsDeleting(false);
    } else if (deleteCompanyResult.isError) {
      toast.error(
        intl.formatMessage({
          id: 'deleteCompanyError',
          defaultMessage: 'Company deletion failed',
          description: 'Company deleted toast error message',
        })
      );
      setIsDeleting(false);
    }
  }, [
    deleteCompanyResult.isSuccess,
    deleteCompanyResult.isError,
    intl,
    navigate,
  ]);

  // Set header
  useEffect(() => {
    dispatch(
      setLayoutHeader({
        title: intl.formatMessage({
          id: 'pageAdminCompaniesTitle',
          defaultMessage: 'Companies',
          description: 'Page title for admin companies',
        }),
        inverted: true,
        icon: IconType.Back,
      })
    );
  }, [dispatch, intl]);

  const emailsWithGDPRConsent =
    companyMembershipResult.data?.emailsWithGdprConsent;

  const tableData = useMemo(
    () =>
      companyResult.data?.memberships.map((membership) => ({
        membershipId: membership.membershipId,
        name: membership.firstName + ' ' + membership.lastName,
        email: membership.email,
        gdpr: emailsWithGDPRConsent
          ? emailsWithGDPRConsent.includes(membership.email)
            ? intl.formatMessage({
                id: 'yes',
                description: '"Yes" label',
                defaultMessage: 'Yes',
              })
            : intl.formatMessage({
                id: 'no',
                description: '"No" label',
                defaultMessage: 'No',
              })
          : '...',
      })),
    [companyResult, emailsWithGDPRConsent, intl]
  );

  const tableColumns = useMemo(
    () => [
      {
        key: 'membershipId' as const,
        title: intl.formatMessage({
          id: 'tableColumnHeaderMembershipId',
          defaultMessage: 'Membership ID',
          description: 'Table column header for membership ID',
        }),
      },
      {
        key: 'name' as const,
        title: intl.formatMessage({
          id: 'tableColumnHeaderFullName',
          defaultMessage: 'Name',
          description: 'Table column header for member full name',
        }),
      },
      {
        key: 'email' as const,
        title: intl.formatMessage({
          id: 'tableColumnHeaderEmail',
          defaultMessage: 'Email',
          description: 'Table column header for email',
        }),
      },
      {
        key: 'gdpr' as const,
        title: intl.formatMessage({
          id: 'tableColumnHeaderGDPRConsent',
          defaultMessage: 'GDPR Consent',
          description: 'Table column header for GDPR Consent',
        }),
      },
    ],
    [intl]
  );

  if (
    companyResult.isLoading ||
    deleteCompanyResult.isLoading ||
    deleteCompanyResult.isSuccess ||
    updateCompanyResult.isLoading
  ) {
    return <Loader color="blue" padding />;
  }

  if (!companyResult.data || !tableData) {
    return (
      <EmptyState iconType={IconType.User} padding>
        <FormattedMessage
          id="pageAdminCompanyDetailsNotFound"
          defaultMessage="A Company with the provided id was not found"
          description="Not found text for admin company details page"
        />
      </EmptyState>
    );
  }

  return (
    <Fragment>
      <ContentWidth isSurface>
        <FlexContainer justifyContent="space-between" alignItems="center">
          <Heading tag={Tag.H2}>{companyResult.data.description}</Heading>
          <IconButton
            onClick={onOpenConfirmDelete}
            title={intl.formatMessage({
              id: 'deleteCompanyIconButtonTitle',
              defaultMessage: 'Delete company',
              description: 'Delete company icon button title',
            })}
          >
            <Icon type={IconType.Trash} size={28} color="error" />
          </IconButton>
          <ConfirmActionPopup
            isOpen={confirmDeleteOpen}
            onConfirm={onDeleteCompany}
            onAbort={onCloseConfirmDelete}
          >
            <>
              <Heading tag={Tag.H4}>
                <FormattedMessage
                  id="deleteCompanyConfirmHeading"
                  defaultMessage="Delete company"
                  description="Heading text in the delete company popup"
                />
              </Heading>
              <FormattedMessage
                id="deleteCompanyConfirmBody"
                defaultMessage="Are you sure you want to delete this company?"
                description="Body text in the delete company popup"
              />
            </>
          </ConfirmActionPopup>
        </FlexContainer>
        <UpdateCompanyForm
          company={{
            description: companyResult.data.description,
            umbrellaCompanyId: companyResult.data.umbrellaCompanyId,
            healthQuizMinResultsForStats:
              companyResult.data.healthQuizMinResultsForStats,
            pulseQuizMinResultsForStats:
              companyResult.data.pulseQuizMinResultsForStats,
          }}
          submitForm={(data) =>
            updateCompany({
              companyId: companyId as string,
              description: data.description,
              umbrellaCompanyId: data.umbrellaCompanyId,
              healthQuizMinResultsForStats: data.healthQuizMinResultsForStats,
              pulseQuizMinResultsForStats: data.pulseQuizMinResultsForStats,
            })
          }
        />
        <MediumGap />
        <CompanyCommunicationManager id={companyId as string} type="company" />
        <MediumGap />
        <CompanyInviteCodes companyId={companyId as string} />
        <MediumGap />
        <Heading tag={Tag.H4}>
          <FormattedMessage
            id="adminCompanyDetailsTitle"
            defaultMessage="Company memberships"
            description="Company memberships title on Admin users page"
          />
        </Heading>
        <Table
          columns={tableColumns}
          data={tableData}
          emptyText={intl.formatMessage({
            id: 'adminCompanyDetailsEmpty',
            defaultMessage: 'This company has no memberships',
            description: 'Company memberships empty state',
          })}
        />
      </ContentWidth>
    </Fragment>
  );
};

export default AdminCompanyDetails;
