import { FC, Fragment, useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  setLayoutHeader,
  useGetMeasureStepQuery,
  useMarkMeasureStepMutation,
} from 'store';

import {
  Article,
  Exercise,
  ExerciseProgram,
  MeasureStepReferenceType,
  QuizItem,
} from 'models';
import { Category, ContentWidth, Gap } from 'styles';
import { useAppDispatch } from 'hooks/redux';
import { useLocalizedText } from 'hooks/useLocalizedText';
import * as routes from 'router/routes';
import { getImage } from 'utils/asset';

import HealthCard from 'components/cards/TestCard';
import RichText from 'components/fragments/RichText';
import { IconType } from 'components/UI/Icon';
import HeroSmall from 'components/UI/Heros/HeroSmall';
import Heading, { Tag } from 'components/UI/Heading';
import Loader from 'components/UI/Loader';
import EmptyState from 'components/UI/EmptyState';
import Button from 'components/UI/Button';
import Image from 'components/UI/Image';

import { CategoryHeader, ButtonGrid } from './styles';
import { useActiveLanguage } from 'hooks/useActiveLanguage';
import { confetti } from 'tsparticles-confetti';
import { toast } from 'react-hot-toast';
import ExcerciseProgramProgress from 'components/fragments/ExcerciseProgramProgress';

const MeasureProgramStep: FC = () => {
  const dispatch = useAppDispatch();
  const getText = useLocalizedText();
  const navigate = useNavigate();

  const location = useLocation();
  const intl = useIntl();
  const { slug, stepId } = useParams();

  // Hooks
  const language = useActiveLanguage();
  const [markMeasureStep, markResult] = useMarkMeasureStepMutation();
  const { data, isLoading, isFetching } = useGetMeasureStepQuery({
    slug,
    stepId,
    language: language?.languageCode,
  });

  // Set header
  useEffect(() => {
    if (slug) {
      dispatch(
        setLayoutHeader({
          title: intl.formatMessage({
            id: 'backButton',
            defaultMessage: 'Back',
            description: 'Back button text',
          }),
          inverted: true,
          icon: IconType.Back,
          link: `${routes.MEASURES}/${slug}`,
        })
      );
    }
  }, [dispatch, intl, slug]);

  // Actions
  const onStartClick = useCallback(
    (slug: string) => async () =>
      navigate(`${routes.QUIZ}/${slug}?redirect=${location.pathname}`),
    [navigate, location]
  );
  const onActiveClick = useCallback(
    () => navigate(routes.MEASURES),
    [navigate]
  );

  // Mark as done
  const onMarkDone = useCallback(
    (completed: boolean) => async () => {
      const result = markMeasureStep({
        measureSlug: slug as string,
        measureStepId: stepId as string,
        language: language.languageCode,
        completed,
      });
      if (completed) {
        const { steps } = await result.unwrap();
        const healthPlanCompleted =
          steps != null && steps.every(({ completed }) => completed);
        if (healthPlanCompleted) {
          navigate(routes.MEASURES, {
            replace: true,
          });
          window.scrollTo(0, 0);
          confetti({
            spread: 360,
            ticks: 50,
            gravity: 0,
            decay: 0.94,
            startVelocity: 30,
            colors: ['FFE400', 'FFBD00', 'E89400', 'FFCA6C', 'FDFFB8'],
            particleCount: 80,
            scalar: 1.2,
            shapes: ['star'],
          });
          toast.success(
            intl.formatMessage({
              id: 'healthPlanCompleted',
              defaultMessage: 'Well done! You have completed a health plan!',
              description: 'Toast message for health plan completed',
            })
          );
        } else {
          navigate(`${routes.MEASURES}/${slug}`, {
            state: { scrollToMeasures: true },
          });
        }
      }
    },
    [markMeasureStep, slug, stepId, language.languageCode, navigate, intl]
  );

  // Mark button
  const renderMarkButton = useCallback(
    (completed: boolean) => {
      if (isFetching || markResult.isLoading) {
        return <Loader color="blue" />;
      }
      if (completed) {
        return (
          <Button
            background="white"
            color="pink"
            border="pink"
            onClick={onMarkDone(false)}
          >
            <FormattedMessage
              id="pageMeasureProgramStepUnmarkDone"
              defaultMessage="Unmark as done"
              description="Unmark as done button for exercise program step"
            />
          </Button>
        );
      }
      return (
        <Button
          background="blue"
          color="white"
          border="blue"
          onClick={onMarkDone(true)}
        >
          <FormattedMessage
            id="pageMeasureProgramStepMarkDone"
            defaultMessage="Mark as done"
            description="Mark as done button for exercise program step"
          />
        </Button>
      );
    },
    [onMarkDone, isFetching, markResult]
  );

  // Content
  const content = useMemo(() => {
    if (!data) {
      return null;
    }
    const { data: refData, type: refType, slug: refSlug } = data.reference;
    switch (refType) {
      case MeasureStepReferenceType.ExerciseProgram:
        const program = refData as ExerciseProgram;
        return (
          <ExcerciseProgramProgress
            slug={refSlug}
            exerciseDescriptionPairs={program.exerciseDescriptionPairs}
            exerciseProgramSteps={program.exerciseProgramSteps}
          />
        );
      case MeasureStepReferenceType.Exercise:
        const exercise = refData as Exercise;
        return (
          <Fragment>
            <Image image={getImage(exercise.image)} />
            <Heading tag={Tag.H2}>{getText(exercise.title)}</Heading>
            {exercise.content && <RichText>{exercise.content}</RichText>}
          </Fragment>
        );
      case MeasureStepReferenceType.Article:
        const article = refData as Article;
        return (
          <Fragment>
            <Image image={getImage(article.image)} />
            <Category>{getText(article.category.title)}</Category>
            <Heading tag={Tag.H2}>{getText(article.title)}</Heading>
            {article.content && <RichText>{article.content}</RichText>}
          </Fragment>
        );
      case MeasureStepReferenceType.Quiz:
        const quiz = refData as QuizItem;
        return (
          <HealthCard
            key={quiz.quizSlug}
            category={quiz.quizDefinition.healthCategory}
            text={quiz.quizDefinition.summary}
            wellrPoints={quiz.quizDefinition.wellrPoints}
            results={quiz.quizResults}
            onClick={onStartClick(quiz.quizSlug)}
            onActiveClick={onActiveClick}
            buttonText={intl.formatMessage({
              id: 'takeTestButton',
              defaultMessage: 'Take test',
              description: 'Take test button text',
            })}
            buttonActiveText={intl.formatMessage({
              id: 'implementMeasuresButton',
              defaultMessage: 'To the health plans',
              description: 'Implement measures button text',
            })}
          />
        );
      default:
        return null;
    }
  }, [data, intl, getText, onActiveClick, onStartClick]);

  // Loading
  if (isLoading) {
    return <Loader color="blue" padding />;
  }

  // No data
  if (!data) {
    return (
      <EmptyState iconType={IconType.Health} padding>
        <FormattedMessage
          id="pageMeasureProgramStepEmptyState"
          defaultMessage="Measure step not found"
          description="Empty state for measure program step"
        />
      </EmptyState>
    );
  }

  const { title, stepNumber, completed } = data;

  return (
    <Fragment>
      <HeroSmall>
        <CategoryHeader>
          <Category>
            <FormattedMessage
              id="pageMeasureProgramStepByline"
              defaultMessage="Step {stepNumber}"
              description="Page byline for measure program step"
              values={{
                stepNumber,
              }}
            />
          </Category>
          <Heading>{getText(title)}</Heading>
        </CategoryHeader>
      </HeroSmall>
      <ContentWidth isSurface>
        {content}
        <Gap />
        <ButtonGrid>{renderMarkButton(completed)}</ButtonGrid>
      </ContentWidth>
    </Fragment>
  );
};

export default MeasureProgramStep;
