import { defaultLanguage } from 'assets/data';
import { localizationSelector } from 'store';
import { useAppSelector } from './redux';

export const useActiveLanguage = () => {
  const { language } = useAppSelector(localizationSelector);
  if (language === null) {
    return defaultLanguage;
  }
  return language;
};
