import React, { FC, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-hot-toast';

import { useDeleteOfficeMutation, useUpdateOfficeMutation } from 'store';

import Loader from 'components/UI/Loader/Loader';
import Icon, { IconType } from 'components/UI/Icon';
import IconButton from 'components/UI/IconButton';
import Heading, { Tag } from 'components/UI/Heading';
import SlideoutModal from 'components/UI/SlideoutModal';

import { Header, Body } from './styles';
import { usePretendCompany } from 'hooks/usePretendCompany';
import CreateUpdateOfficeForm from 'components/forms/CreateUpdateOfficeForm';

type Props = {
  office: {
    description: string;
    id: string;
    headOffice: string | null;
  } | null;
  isOpen: boolean;
  onClose: () => void;
};

const UpdateOfficeModal: FC<Props> = ({ office, isOpen, onClose }) => {
  const intl = useIntl();
  const companyId = usePretendCompany();

  const [updateOffice, updateOfficeResult] = useUpdateOfficeMutation();

  const [deleteOffice, deleteOfficeResult] = useDeleteOfficeMutation();

  useEffect(() => {
    if (updateOfficeResult.isSuccess) {
      toast.success(
        intl.formatMessage({
          id: 'updateOfficeModalSuccess',
          defaultMessage: 'Office update successful',
          description: 'Update office success message',
        })
      );
      onClose();
    }
  }, [updateOfficeResult.isSuccess, intl, onClose]);

  useEffect(() => {
    if (deleteOfficeResult.isSuccess) {
      toast.success(
        intl.formatMessage({
          id: 'deleteOfficeModalSuccess',
          defaultMessage: 'Office successfully deleted',
          description: 'Delete office success message',
        })
      );
      onClose();
    }
  }, [
    updateOfficeResult.isSuccess,
    intl,
    onClose,
    deleteOfficeResult.isSuccess,
  ]);

  // Content
  const content = useMemo(() => {
    if (updateOfficeResult.isLoading) {
      return <Loader padding color="blue" />;
    }
    if (!office) {
      return null;
    }
    return (
      <CreateUpdateOfficeForm
        office={office}
        submitForm={(form) =>
          updateOffice({
            officeId: office?.id ?? '',
            headOfficeId: form.headOffice === '' ? null : form.headOffice,
            description: form.officeName,
            companyId,
          })
        }
        onDelete={() => {
          deleteOffice({
            officeId: office?.id ?? '',
            companyId,
          });
        }}
      />
    );
  }, [
    companyId,
    deleteOffice,
    office,
    updateOffice,
    updateOfficeResult.isLoading,
  ]);

  const onModalClose = () => {
    onClose();
  };

  return (
    <SlideoutModal isOpen={isOpen} onClose={onModalClose}>
      <div>
        <Header>
          <Heading tag={Tag.H4}>
            <FormattedMessage
              id="updateOfficeModalTitle"
              defaultMessage="Update office"
              description="Modal title for update office"
            />
          </Heading>
          <IconButton onClick={onClose} padding>
            <Icon type={IconType.Close} />
          </IconButton>
        </Header>
        <Body>{content}</Body>
      </div>
    </SlideoutModal>
  );
};

export default UpdateOfficeModal;
