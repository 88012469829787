import Lottie from 'lottie-react';
import styled from 'styled-components';
import { breakpoints } from 'styles';

export const Grid = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Card = styled.div<{ inverted: boolean }>`
  display: flex;

  background: ${({ theme, inverted }) =>
    inverted ? theme.colors.white : theme.colors.backgroundLight};
  font-size: 16px;
  border-radius: 16px;
  overflow: hidden;
  min-height: 64px;
    box-shadow: 0 0px 10px ${({ theme }) => theme.colors.blueLight};

    @media (max-width: ${breakpoints.m}px) {
      margin: 16px
    }
`;


export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 16px;
  background: ${({ theme }) => theme.colors.blueLight};
  padding: 8px;
  height: 26px;
  border-radius: 100%;
`;

export const ProgressContainer = styled.div`
  position: relative;
  width: 100%;
  justify-content: flex-end;
`;

// Styled component for the Lottie animation
export const StyledLottie = styled(Lottie)`
  position: absolute;
  width: 98%;
  height: 100%;
  pointer-events: none;
`;

export const Action = styled.div`
  display: flex;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.borderLight};
`;

export const ButtonContent = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const Overlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
`;

export const CardTitle = styled.p<{ inverted?: boolean }>`
  color: ${({ theme, inverted }) => inverted ? theme.colors.borderLight : theme.colors.grayText};
  display: flex;
  gap: 8px;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

interface CheckboxContainerProps {
  disabled?: boolean;
}

export const CheckboxContainer = styled.div<CheckboxContainerProps>`
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 35px;
  padding-top: 16px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;

export const ImageView = styled.img`
  width: 100%;
  min-height: 256px;
  height: 100%;
  object-fit: cover;
  user-drag: none;
`;

export const ImageContainer = styled.div`
  position: relative;
  max-width: 280px;
`;

export const CardContainer = styled.div`
  display: flex;
  position: relative;
  min-width: 320px;
  width: 100%;
  overflow: auto;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const CardContent = styled.div<{ center?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${({ center }) => center ? 'center' : ''};
  margin: 0px 16px 0px 16px;

  @media (max-width: ${breakpoints.m}px) {
  align-items: center;
}
  h3 {
    margin: 0;
  }
`;

export const CardText = styled.div`
  flex: 1;


  h2 {
    margin-bottom: 8px;
  }
`;

export const CardTextHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Text = styled.p`
  margin: 0;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px 16px 0px 16px;

  h3 {
    margin: 0;
  }
`;

export const Row = styled.div`
  display: flex;
`;

export const Description = styled.p`
  font-size: 14px;
  max-height: 120px;
  overflow: auto;
`;