import { FC, useMemo } from 'react';

import CompetitionCard from 'components/cards/CompetitionCard';
import { useGetAvailableContestsQuery } from 'store';
import { getImage } from 'utils/asset';
import { useLocalizedText } from 'hooks/useLocalizedText';
import { CardList } from './styles';
import { useTagFilters } from 'hooks/useTagFilters';
import { filterArrayByTags } from 'utils/array';
import * as routes from 'router/routes';

interface ChallengeCardListProps {
  languageCode?: string;
}

const ChallengeCardList: FC<ChallengeCardListProps> = ({ languageCode }) => {
  const getText = useLocalizedText();

  // Hooks
  const available = useGetAvailableContestsQuery({
    language: languageCode,
  });

  const availableChallenges = useMemo(() => {
    if (available.data == null) {
      return [];
    }
    return available.data.filter((item) => item.type === 'Challenge');
  }, [available.data]);

  const tags = useMemo(() => {
    const items: Array<{ slug: string; title: string }> = availableChallenges
      .map((item) => item.tags)
      .flat();
    // remove duplicates:
    return items.filter(
      (item, index) => items.findIndex((i) => i.slug === item.slug) === index
    );
  }, [availableChallenges]);

  const [filters] = useTagFilters('challenge', tags);

  const filteredChallenges = useMemo(() => {
    if (tags.length === 0) {
      return availableChallenges;
    }
    return availableChallenges.filter((item) =>
      filterArrayByTags(filters, item.tags)
    );
  }, [availableChallenges, filters, tags]);

  return (
    <CardList>
      {filteredChallenges.map((item, i) => (
        <CompetitionCard
          key={`${item.slug}-${i + 1}`}
          title={getText(item.title)}
          image={getImage(item.image)}
          difficulty={item.difficulty}
          wellrPoints={item.wellrPoints}
          link={`${routes.COMPETE_AVAILABLE_CHALLENGES}/${item.slug}`}
          type={item.type}
        />
      ))}
    </CardList>
  );
};

export default ChallengeCardList;
