import styled from 'styled-components';

export const CheckboxContainer = styled.div`
  display: flex;
  vertical-align: middle;
`;

export const Icon = styled.svg`
  fill: none;
  stroke: ${({ theme }) => theme.colors.white};
  stroke-width: 2px;
  padding: 4px;
`;

export const HiddenCheckbox = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

type Props = {
  isChecked: boolean;
  isError: boolean;
};

export const StyledCheckbox = styled.div.attrs({ type: 'checkbox' })<Props>`
  display: inline-block;
  width: 24px;
  height: 24px;
  background: ${({ theme, isChecked }) =>
    isChecked ? theme.colors.blue : theme.colors.white};
  border: 1px solid
    ${({ isError, theme }) => {
      const { error, borderDark } = theme.colors;
      if (isError) {
        return error;
      }
      return borderDark;
    }};
  border-radius: 10px;
  transition: all 150ms;
  cursor: pointer;

  ${Icon} {
    visibility: ${({ isChecked }) => (isChecked ? 'visible' : 'hidden')};
  }
`;
