import { FC } from 'react';
import { NavigateOptions, useNavigate } from 'react-router-dom';

import { Image } from 'models';
import Heading, { Tag } from 'components/UI/Heading';
import Icon, { IconType } from 'components/UI/Icon';
import ClickableCard from 'components/UI/Cards/ClickableCard';

import {
  Grid,
  Column,
  Description,
  Img,
  Container,
  ImageContainer,
  IconContainer,
  Byline,
  CheckmarkContainer,
} from './styles';
import CheckBadge from 'components/UI/CheckBadge';

type Props = {
  link: string;
  linkOptions?: NavigateOptions;
  title: string | null;
  byline?: string;
  image?: Image | null;
  filter?: boolean;
  isCompleted?: boolean;
};

const CategoryCard: FC<Props> = ({
  image,
  title,
  byline,
  link,
  linkOptions,
  filter,
  isCompleted,
}) => {
  const navigate = useNavigate();

  return (
    <ClickableCard onClick={() => navigate(link, linkOptions)}>
      <Grid>
        <Container>
          {image && (
            <ImageContainer filter={filter ? 1 : 0}>
              <Img src={image.src} alt={image.alt} />
            </ImageContainer>
          )}
          {isCompleted && (
            <CheckmarkContainer>
              <CheckBadge />
            </CheckmarkContainer>
          )}
          <Column>
            <Heading tag={Tag.H5}>{title}</Heading>
            <Byline>{byline && <Description>{byline}</Description>}</Byline>
          </Column>
        </Container>
        <IconContainer>
          <Icon type={IconType.Arrow} />
        </IconContainer>
      </Grid>
    </ClickableCard>
  );
};

export default CategoryCard;
