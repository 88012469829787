import styled from 'styled-components';
import { breakpoints } from 'styles';

export const StatsCardList = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;

  @media (max-width: ${breakpoints.m}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
