import { useMemo } from 'react';
import { type RegisterOptions } from 'react-hook-form';
import { useIntl } from 'react-intl';

export const useMinMaxValidation = (min: number, max: number) => {
  const intl = useIntl();
  const numberValidation: RegisterOptions = useMemo(
    () => ({
      valueAsNumber: true,
      min: {
        message: intl.formatMessage(
          {
            id: 'inputBadMinValueError',
            defaultMessage: 'Minimum value is {value}',
            description: 'Error message for bad minimum value input',
          },
          { value: min }
        ),
        value: min,
      },
      max: {
        value: max,
        message: intl.formatMessage(
          {
            id: 'inputBadMaxValueError',
            defaultMessage: 'Maximum value is {value}',
            description: 'Error message for healthQuizMinResultsForStats input',
          },
          { value: max }
        ),
      },
    }),
    [intl, max, min]
  );
  return numberValidation;
};
