import React, { FC, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { MembershipSettings, UserActivity } from 'models';

import StaticCard from 'components/UI/Cards/StaticCard';
import EmptyState from 'components/UI/EmptyState';
import { IconType } from 'components/UI/Icon';
import TopListProgressItem from 'components/fragments/TopListProgressItem';

import { InformationMessage, TeamList } from './styles';
import { useGetMembershipSettingsQuery, useUpdateMembershipSettingsMutation } from 'store';
import Button from 'components/UI/Button';
import ContentModal from 'components/modals/ContentModal';
import Loader from 'components/UI/Loader';
import { ActionContainer } from 'styles';
import ToggleCard from 'components/UI/Cards/ToggleCard';

type Props = {
  me: UserActivity;
  users?: UserActivity[];
  points?: boolean;
  total: number;
  page?: number;
};

const OverviewTopList: FC<Props> = ({
  me,
  users,
  total,
  points,
  page
}) => {
  const intl = useIntl();

  //States
  const [topListInfoModalOpen, setTopListInfoModalOpen] =
    useState<boolean>(false);

  const onOpenTopListInfoModal = useCallback(
    () => setTopListInfoModalOpen(true),
    []
  );
  const onCloseTopListInfoModal = useCallback(
    () => setTopListInfoModalOpen(false),
    []
  );

  //hooks
  const settings = useGetMembershipSettingsQuery();
  const [updateSettings] = useUpdateMembershipSettingsMutation();

  // On toggle settings
  const onToggle = useCallback(
    (key: keyof MembershipSettings) => (active: boolean) => {
      const { data } = settings;
      if (data) {
        updateSettings({ ...data, [key]: active });
      }
    },
    [updateSettings, settings]
  );

  if (settings.isLoading) {
    return <Loader color="blue" padding />;
  }

  if (settings.data?.showInToplistOverview === false) {
    return (
      <>
        <EmptyState iconType={IconType.Settings} padding>
          <InformationMessage>
            <FormattedMessage
              id="overviewToplistActivateMessage"
              defaultMessage="Activate toplist"
              description="Active competition top list"
            />
            <Button
              color={'white'}
              border={'borderLight'}
              background={'pink'}
              size="small"
              onClick={onOpenTopListInfoModal}
            >
              <FormattedMessage
                id="pageSettingsTitle"
                defaultMessage="Settings"
                description="Page title for settings" />
            </Button>
          </InformationMessage>
        </EmptyState>

        <ContentModal
          isOpen={topListInfoModalOpen}
          onClose={onCloseTopListInfoModal}
          title={intl.formatMessage({
            id: 'pageCompetitionTopListTitle',
            defaultMessage: 'Top list',
            description: 'Title for top list section on competition page',
          })}
        >
          <FormattedMessage
            id="overviewTopListInfoPolicy"
            defaultMessage={`
            Welcome to the Toplist! We're glad you want to join.{NewLine}
            To participate, you need to give your approval. By participating in the Toplist, you agree that we will display your name, score, and progress on our leaderboard, so everyone can see your achievement and you can see theirs.
            {NewLine}{NewLine}
            But don't worry, if you ever change your mind, you can easily adjust your settings by visiting your profile. We look forward to seeing you on the leaderboard and sharing the joy of achievements together!`}
            description="Information message for top list"
            values={{ NewLine: <br /> }}
            tagName={'p'}
          />
          <ActionContainer>

            <ToggleCard
              title={intl.formatMessage({
                id: 'settingsShowInToplistOverviewTitle',
                defaultMessage: 'Show me in top list in overview',
                description: 'Settings title for show me in top list',
              })}
              isActive={settings.data.showInToplistOverview}
              onToggle={() => {
                onToggle('showInToplistOverview')(!settings.data?.showInToplistOverview);
                onCloseTopListInfoModal();
              }}
            />
          </ActionContainer>
        </ContentModal>
      </>
    );

  }
  // Check if users exist
  else if (!users?.length) {
    return (
      <EmptyState iconType={IconType.Users} padding>
        <FormattedMessage
          id="competitionTopListEmptyState"
          defaultMessage="No users found"
          description="Empty state for competition top list"
        />
      </EmptyState>
    );
  }

  return (
    <StaticCard>
      <TeamList>
        {users.map((item, i) => {
          const user = users.find((user) => user.userId === item.userId);
          if (!user) {
            return null;
          }

          let progress: number | null;
          const cut = points ? user.points / total : user.steps / total;
          progress = !isNaN(cut) && isFinite(cut) ? cut * 100 : 0;

          return (
            <TopListProgressItem
              key={user.userId}
              email={user.email}
              alias={user.firstName + ' ' + user.lastName}
              profilePictureAssetId={user.profilePictureAssetId}
              totalSteps={points ? user.points : user.steps}
              progress={progress}
              toplistPosition={i + 1}
              isLast={users.length === i + 1}
              points={points}
              page={page}
            />
          );
        })}
      </TeamList>
    </StaticCard>
  );
};

export default OverviewTopList;
