import { FC, useMemo } from 'react';
import Button from 'components/UI/Button';
import StaticCard from 'components/UI/Cards/StaticCard';
import { MediaDisplay } from 'styles';
import { useLocalizedText } from 'hooks/useLocalizedText';
import {
  ContentText,
  HealthCategory,
} from 'models';

import {
  Grid,
  Column,
  Header,
  HeaderCol,
  ActionColumn,
  Description,
  DisabledTitle,

} from './styles';
import { getCategoryIcon } from 'utils/asset';
import WellrSteps from 'components/UI/WellrSteps';
import CheckBadge from 'components/UI/CheckBadge';
import Icon, { IconType } from 'components/UI/Icon';

type Props = {
  category: HealthCategory | null;
  title: ContentText | null;
  text: ContentText | null;
  contestAwardedStepsOnComplete: number;
  completed?: boolean;
  hasContestEnded?: boolean;

  buttonText?: string;
  buttonActiveText?: string;
  onClick?: () => void;
};

const ContestQuizCard: FC<Props> = ({
  category,
  title,
  text,
  contestAwardedStepsOnComplete,
  hasContestEnded,
  completed,
  onClick,
  buttonText,
}) => {
  const getText = useLocalizedText();

  // Content for card
  const content = useMemo(() => {
    return <Description>{getText(text)}</Description>;
  }, [getText, text]);

  // Actions
  const actions = useMemo(() => {
    if (completed) {
      return (
        <ActionColumn>
          <CheckBadge type="grey" />
        </ActionColumn>
      );
    }
    return (
      <ActionColumn>
        <MediaDisplay breakpoint="s" isLarger>
          <WellrSteps icon={false} count={contestAwardedStepsOnComplete} />
        </MediaDisplay>
        <Button background="blue" disabled={hasContestEnded} onClick={onClick}>
          {buttonText}
        </Button>
      </ActionColumn>
    );
  }, [completed, contestAwardedStepsOnComplete, hasContestEnded, onClick, buttonText]);

  // Category
  const healthCategory = useMemo(() => {
    if (category === null && title === null) {
      return null;
    }

    return <DisabledTitle>{getText(title ?? category?.title)}</DisabledTitle>;
  }, [category, getText, title]);


  const categoryIcon = useMemo(
    () => (category?.icon ? getCategoryIcon(category.icon, false) : null),
    [category?.icon]
  );

  return (
    <StaticCard>
      <Grid isActive={false}>
        <Column>
          <Header>
            <HeaderCol>
              {categoryIcon ? (
                <img src={categoryIcon.src} alt={categoryIcon.alt} />
              ) : (<Icon type={IconType.Info} />)}
              {healthCategory}
            </HeaderCol>
          </Header>
          {content}
        </Column>
        {actions}
      </Grid>
    </StaticCard>
  );
};

export default ContestQuizCard;
