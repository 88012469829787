import React, { FC, useEffect } from 'react';
import { confetti } from 'tsparticles-confetti';
import {
  ButtonContainer,
  Dialog,
  DialogBody,
  MilestoneIcon,
  Overlay,
} from './styles';
import Button from 'components/UI/Button';
import { FormattedMessage } from 'react-intl';
import Loader from 'components/UI/Loader';

type Props = {
  isOpen: boolean;
  isLoading?: boolean;
  onConfirm: () => void;
  onAbort: () => void;
  icon?: string;
  children: React.ReactNode;
};

const MilestonePopup: FC<Props> = ({
  isOpen,
  onConfirm,
  onAbort,
  children,
  icon,
  isLoading = false,
}) => {
  // Freeze body
  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'unset';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  // Trigger confetti when isOpen changes
  useEffect(() => {
    if (isOpen) {
      confetti({
        spread: 360,
        ticks: 50,
        gravity: 0,
        decay: 0.94,
        startVelocity: 30,
        colors: ['FFE400', 'FFBD00', 'E89400', 'FFCA6C', 'FDFFB8'],
        particleCount: 80,
        scalar: 1.2,
        shapes: ['circle', 'square', 'triangle', 'polygon', 'star'],
      });
    }
  }, [isOpen]);

  return (
    <>
      <Overlay open={isOpen} onClick={onAbort} />
      <Dialog open={isOpen}>
        {isOpen && (
          <>
            {icon && (
              <MilestoneIcon src={icon} alt="Level icon" />
            )}
            <DialogBody>{children}</DialogBody>
            {isLoading ? (
              <div style={{ margin: '10px 0' }}>
                <Loader color="blue" />
              </div>
            ) : (
              <ButtonContainer>
                <Button
                  onClick={onConfirm}
                  background="yellowDark"
                  color="grayText"
                  size='large'
                  disabled={isLoading}
                >
                  <FormattedMessage
                    id="confirmButtonN"
                    defaultMessage="Confirm"
                    description="Confirm button text"
                  />
                </Button>
              </ButtonContainer>
            )}
          </>
        )}
      </Dialog>
    </>
  );
};

export default MilestonePopup;