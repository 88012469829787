import {
  getEndpointURL,
  WellrEndpointDefinition,
  WellrEndpointPath,
} from 'utils/api';

/** Get completed diet recipes */
const getInvitableMembershipsWithStructurePath: WellrEndpointPath =
  '/api/membership/search/my-invitable-memberships-with-structure';
const getInvitableMembershipsWithStructureMethod = 'get';
type GetInvitableMembershipsWithStructure = WellrEndpointDefinition<
  typeof getInvitableMembershipsWithStructurePath,
  typeof getInvitableMembershipsWithStructureMethod
>;

export type GetInvitableMembershipsWithStructureType = {
  result: GetInvitableMembershipsWithStructure['responseBody'];
  queryArgs: void;
};

export const endpoints = {
  getInvitableMembershipsWithStructure: {
    path: getEndpointURL(getInvitableMembershipsWithStructurePath),
    method: getInvitableMembershipsWithStructureMethod.toUpperCase(),
  },
} as const;
