import styled from 'styled-components';
import { getRankColor } from 'utils/contest';

export const TeamList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TeamItem = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
`;

export const TeamItemData = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  gap: 8px;

  h4 {
    margin: 0;
  }
`;

export const TeamItemGrid = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const TeamItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
`;

export const TeamItemHeaderName = styled.div`
  display: flex;
  overflow: hidden;
  align-items: center;
  gap: 8px;

  > * {
  overflow: hidden;
  text-overflow: ellipsis;
  }
`;

export const TeamItemText = styled.span`
  color: ${({ theme }) => theme.colors.grayText};
  font-size: 14px;
`;

export const TeamItemProgress = styled.div`
  width: 100%;
`;

type TeamRankProps = {
  rank: number;
  isMine: boolean;
};

export const TeamRank = styled.div<TeamRankProps>`
  min-width: 36px;
  height: 36px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme, rank, isMine }) =>
    getRankColor(theme, rank, isMine)};
  border-radius: 18px;
  font-weight: bold;
  cursor: pointer;
`;
