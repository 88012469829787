import styled from 'styled-components';
import { breakpoints } from 'styles';

export const CategoryHeader = styled.div`
  h1 {
    margin: 0;
  }

  p {
    color: ${({ theme }) => theme.colors.white};
  }

  span {
    color: ${({ theme }) => theme.colors.white};
    font-size: 14px;
  }
`;

export const Byline = styled.div`
  display: flex;
  align-items: center;
  margin: 16px 0 0;
`;

export const ButtonGrid = styled.div`
  display: flex;
  gap: 8px;

  @media (max-width: ${breakpoints.s}px) {
    flex-direction: column-reverse;
  }
`;
