import { useState, useEffect } from 'react';
import * as storage from 'utils/storage';

export const useLocalStorage = <T>(
  key: string,
  defaultValue: T
): [T, (value: T) => void] => {
  // State
  const [value, setValue] = useState(() => {
    const currentValue = storage.getItem<T>(key);

    if (!currentValue) {
      return defaultValue;
    }
    return currentValue;
  });

  // Update storage when value changes
  useEffect(() => {
    storage.setItem<T>(key, value);
  }, [value, key]);

  return [value, setValue];
};
