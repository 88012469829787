import styled from 'styled-components';
import { ThemeColor } from 'styles';

type Props = {
  background: ThemeColor;
  color: ThemeColor;
};

export const Container = styled.div<Props>`
  color: ${({ color, theme }) => theme.colors[color]};
  background-color: ${({ background, theme }) => theme.colors[background]};
  font-size: 14px;
  font-weight: 600;
  padding: 4px 8px;
  border-radius: 16px;
  text-align: center;
`;
