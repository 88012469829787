import styled from 'styled-components';

export const Grid = styled.div`
  margin-bottom: 32px;
`;

export const SelectItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
  cursor: pointer;
`;
