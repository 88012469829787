import Icon, { IconType } from 'components/UI/Icon';
import { FC } from 'react';
import { FlexContainer } from 'styles';
import StaticCard from '../StaticCard';
import {
  CardContainer,
  CardDescription,
  CardHeading,
  IconContainer,
} from './styles';

type Props = {
  title: string;
  description?: string;
  onEdit?: () => void;
  onDelete?: () => void;
};

const ActionCard: FC<Props> = (props) => {
  return (
    <StaticCard>
      <CardContainer>
        <div>
          <CardHeading>{props.title}</CardHeading>
          {props.description != null && (
            <CardDescription>{props.description}</CardDescription>
          )}
        </div>
        <FlexContainer gap={8}>
          {props.onEdit != null && (
            <IconContainer onClick={props.onEdit}>
              <Icon type={IconType.Edit} />
            </IconContainer>
          )}
          {props.onDelete != null && (
            <IconContainer onClick={props.onDelete}>
              <Icon type={IconType.Trash} color="error" />
            </IconContainer>
          )}
        </FlexContainer>
      </CardContainer>
    </StaticCard>
  );
};

export default ActionCard;
