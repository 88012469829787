import React, { FC, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ContentWidth } from 'styles';
import { useAppDispatch } from 'hooks/redux';
import { setLayoutHeader, useLazyGetUserByEmailQuery } from 'store';

import Heading, { Tag } from 'components/UI/Heading';

import * as routes from 'router/routes';
import SearchField from 'components/UI/SearchField';
import { Navigate } from 'react-router-dom';
import ListMenu from 'components/UI/ListMenu';

const AdminUsers: FC<{ companyAdminView?: boolean }> = ({
  companyAdminView,
}) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  // Hooks
  const [getUserByEmail, { isSuccess, isFetching, data }] =
    useLazyGetUserByEmailQuery();

  // Set header
  useEffect(() => {
    dispatch(
      setLayoutHeader({
        title: intl.formatMessage({
          id: 'pageAdminUsersTitle',
          defaultMessage: 'Users',
          description: 'Page title for admin users',
        }),
      })
    );
  }, [dispatch, intl]);

  // Redirect to results on successful search result
  if (isSuccess && data && !isFetching) {
    const userRoute = companyAdminView
      ? `${routes.ADMIN_COMPANY_USERS}/${data.membership.userId}`
      : `${routes.ADMIN_USERS}/${data.email}`;
    return <Navigate to={userRoute} />;
  }

  return (
    <ContentWidth isSurface>
      {companyAdminView && (
        <>
          <ListMenu
            menu={[
              {
                id: 1,
                text: intl.formatMessage({
                  id: 'pageAdminDepartmentAddUserButton',
                  defaultMessage: 'Add new user',
                  description:
                    'Button to create a new user on admin department page',
                }),
                link: routes.ADMIN_USER_CREATE,
              },
            ]}
          />
          <br />
        </>
      )}
      <Heading tag={Tag.H4}>
        <FormattedMessage
          id="pageAdminUsersManageTitle"
          defaultMessage="View and manage user"
          description="Admin manage users title"
        />
      </Heading>
      <SearchField
        onSearch={getUserByEmail}
        disabled={isFetching}
        placeholder={intl.formatMessage({
          id: 'inputEmailPlaceholder',
          defaultMessage: 'Enter email',
          description: 'Placeholder for email input',
        })}
      />
    </ContentWidth>
  );
};

export default AdminUsers;
