import { Difficulty } from 'models';
import styled from 'styled-components';

export const Grid = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DisabledTitle = styled.span`
  color: ${({ theme }) => theme.colors.grayText};
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
`;

export const Container = styled.div`
  display: flex;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 82px;
  padding: 8px 0 8px 16px;

  h5 {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Byline = styled.div`
  display: flex;
  align-items: center;
  margin: 4px 0 0;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 8px;
`;

type ImageProps = {
  filter?: number;
};

export const ImageContainer = styled.div<ImageProps>`
  position: relative;
  width: 78px;
  height: 82px;
  min-width: 78px;

  ${({ theme, filter }) => {
    if (filter) {
      return `
        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          mix-blend-mode: multiply;
          background: ${theme.colors.black};
          opacity: 0.4;
        }
      `;
    }
  }};
`;

export const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Action = styled.div`
  flex: 1;
  margin: 16px;

  button {
    width: 100%;
  }
`;
type DifficultyProps = {
  difficulty?: string;
};

export const DifficultyText = styled.span<DifficultyProps>`
  color: ${({ theme, difficulty }) =>
    theme.colors[difficulty === 'easy' ? 'success' : difficulty === 'medium' ? 'caution' : 'error']};
  font-size: 12px;
  font-weight: bold;
  margin-right: 4px;
`;

