import styled from 'styled-components';

export const AnswerAlternatives = styled.div`
  margin-top: 10px;
  gap: 10px;
  display: flex;
  flex-direction: column;
`;

export const AnswerAlternative = styled.div`
  margin-bottom: 10px;
  margin-top: 10px;
`;

export const ProgressHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;
