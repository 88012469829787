import { IconType } from 'components/UI/Icon';
import { ReferenceType } from 'models';
import * as routes from 'router/routes';

const refTypeRouteMap = {
  exercise: routes.EXERCISES,
  exerciseCategory: routes.EXERCISES_CATEGORY,
  exerciseProgram: routes.EXERCISE_PROGRAM,
  exerciseProgramCategory: routes.EXERCISE_PROGRAMS_CATEGORY,
  article: routes.INSPIRATION_ARTICLE,
  articleCategory: routes.INSPIRATION,
  recipe: routes.RECIPE,
  diet: routes.DIET_CATEGORY,
  frontendPath: '',
  contest: routes.COMPETE_AVAILABLE_CHALLENGES,
  challenge: routes.COMPETE_AVAILABLE_CHALLENGES,
  challengeCategory: routes.COMPETE_AVAILABLE_CHALLENGES_CATEGORY,
};

const refTypeIconMap = {
  exercise: IconType.Exercise,
  exerciseCategory: IconType.Exercise,
  challengeCategory: IconType.Flag,
  exerciseProgram: IconType.Exercise,
  exerciseProgramCategory: IconType.Exercise,
  article: IconType.Inspiration,
  articleCategory: IconType.Inspiration,
  recipe: IconType.Food,
  diet: IconType.Food,
  frontendPath: IconType.Search,
  contest: IconType.CheckeredFlag,
  challenge: IconType.Flag,
};

export function getRefTypeIcon(type: ReferenceType): IconType {
  return refTypeIconMap[type];
}

export function getRefLink(type: ReferenceType, slug: string): string {
  return `${refTypeRouteMap[type]}/${slug}`;
}

export function getAppStoreLink() {
  // Check if the device is iOS (iPhone/iPad)
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  if (isIOS) {
    return 'https://apps.apple.com/se/app/wellr-2-0/id6446771835';
  } else {
    return 'https://play.google.com/store/apps/details?id=se.wellr.reboot';
  }
}

export function linkIsInternal(link: string): boolean {
  return (
    link.includes('my.wellr.app') || link.includes(window.location.hostname)
  );
}
