import styled from 'styled-components';
import { ThemeColor } from 'styles';

export const Steps = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
`;

export const StepHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
`;

export const StepCol = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  h3 {
    line-height: 32px;
    margin: 0;
  }
`;

export const StepGoalButton = styled.div`
  cursor: pointer;
`;

export const StepRefreshButton = styled.div`
  height: 26px;
  width: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 100%;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
`;

export const ProgressIndicatorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  > div {
    display: flex;
    align-items: center;
    gap: 4px;
  }
`;

export const Circle = styled.div<{ color: ThemeColor }>`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: ${({ theme, color }) => theme.colors[color]};
`;

export const StepProgress = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const SmallText = styled.span<{ capitalize?: boolean }>`
  color: ${({ theme }) => theme.colors.grayText};
  font-size: 14px;
  line-height: 26px;
  :first-letter {
    text-transform: ${({ capitalize }) =>
    capitalize === true ? 'capitalize' : 'none'};
  }
`;

export const LargeText = styled.p`
  color: ${({ theme }) => theme.colors.grayText};
  display: flex;
  margin: 0;
`;

export const StarIcon = styled.svg.attrs({
  viewBox: "20 0 576 512",
  xmlns: "http://www.w3.org/2000/svg",
  fill: "currentColor"
})`
  color: ${({ theme }) => theme.colors.yellowDark};
  align-self: center;
  justify-self: center;
  right: -8px;
  width: 1em;
  height: 1em;
`;
