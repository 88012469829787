import { FC, useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { ContentWidth } from 'styles';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  localizationSelector,
  setLayoutHeader,
  useLazySearchContentQuery,
} from 'store';
import { ReferenceType } from 'models';
import { getRefLink, getRefTypeIcon } from 'utils/link';

import { IconType } from 'components/UI/Icon';
import Loader from 'components/UI/Loader';
import EmptyState from 'components/UI/EmptyState';
import IconCard from 'components/UI/Cards/IconCard';

import { CardList } from './styles';
import SearchField from 'components/UI/SearchField';
import { useQuery } from 'hooks/useQuery';

const Search: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchString] = useQuery('searchString');
  const intl = useIntl();
  const { language } = useAppSelector(localizationSelector);

  // Hooks
  const [search, { data, isFetching, isUninitialized }] =
    useLazySearchContentQuery();

  useEffect(() => {
    if (isUninitialized && searchString) {
      search({
        language: language?.languageCode,
        searchString: searchString,
      });
    }
  });

  // Set header
  useEffect(() => {
    dispatch(
      setLayoutHeader({
        title: intl.formatMessage({
          id: 'pageSearchTitle',
          defaultMessage: 'Search',
          description: 'Page title for search',
        }),
      })
    );
  }, [dispatch, intl]);

  // Actions
  const onClick = useCallback(
    (type: ReferenceType, slug: string) => () =>
      navigate(getRefLink(type, slug)),
    [navigate]
  );

  // Content
  const content = useMemo(() => {
    // Loading
    if (isFetching) {
      return <Loader color="blue" padding />;
    }

    if (isUninitialized) {
      return null;
    }

    // No data
    if (!data?.length) {
      return (
        <EmptyState iconType={IconType.Search} padding>
          <FormattedMessage
            id="pageSearchEmptyState"
            defaultMessage="No search results found"
            description="Empty state for search results"
          />
        </EmptyState>
      );
    }

    return (
      <CardList>
        {data.map(({ slug, title, type, typeTitle }) => {
          return (
            <IconCard
              key={slug}
              title={title ?? ''}
              category={typeTitle ?? ''}
              onClick={onClick(type as ReferenceType, slug as string)}
              onIconClick={() => {}}
              icon={getRefTypeIcon(type as ReferenceType)}
            />
          );
        })}
      </CardList>
    );
  }, [isFetching, isUninitialized, data, onClick]);

  return (
    <ContentWidth isSurface>
      <SearchField
        defaultValue={searchString ?? ''}
        onSearch={(value) => {
          search({
            language: language?.languageCode,
            searchString: value,
          });
          navigate(`?searchString=${value}`);
        }}
      />
      {content}
    </ContentWidth>
  );
};

export default Search;
