import styled from 'styled-components';
import { motion } from 'framer-motion';
import { breakpoints } from 'styles';

export const Container = styled(motion.div)`
  display: flex;
  justify-content: center;
  min-height: 100%;
  position: relative;
  overflow: hidden;

  @media (max-width: ${breakpoints.xl}px) {
    background-size: 150%;
  }
`;

type ContentProps = {
  padding: boolean;
};

export const Content = styled.div<ContentProps>`
  position: relative;
  color: ${({ theme }) => theme.colors.white};
  padding: ${({ padding }) => (padding ? '32px 24px 0' : 0)};
  flex: 1;
  z-index: 2;

  @media (max-width: ${breakpoints.xl}px) {
    background-size: 24px;
  }
`;

export const Illustration = styled.svg`
  position: absolute;
  width: 140%;
  height: 140%;
  top: -40%;

  @media (min-width: ${breakpoints.xl}px) {
    width: 160%;
    height: 160%;
  }
`;
