import Button from 'components/UI/Button';
import StaticCard from 'components/UI/Cards/StaticCard';
import Heading, { Tag } from 'components/UI/Heading';
import HeadingWithInfo from 'components/UI/HeadingWithInfo';
import { useMemo, useState } from 'react';

import { SmallButtonGrid, SectionHeader, TopListItemContainer } from './styles';

type TopListData<ItemType> = {
  topList: ItemType[];
  renderTopListItem: (item: ItemType, index: number) => JSX.Element;
};

type HeaderButton<T> = {
  title: string;
} & TopListData<T>;

type Props<T> =
  | {
      headerTitle: string;
      onClickHeaderInfo?: () => void;
      headerButtons: HeaderButton<T>[];
    }
  | ({
      headerTitle: string;
      onClickHeaderInfo?: () => void;
    } & TopListData<T>);

const TopList = <T extends unknown>(props: Props<T>) => {
  const [activeButton, setActiveButton] = useState(0);

  const isEmpty = useMemo(() => {
    if ('topList' in props) {
      return props.topList.length === 0;
    }
    return props.headerButtons[activeButton].topList.length === 0;
  }, [props, activeButton]);

  return (
    <>
      <SectionHeader>
        {props.onClickHeaderInfo != null ? (
          <HeadingWithInfo tag={Tag.H4} onClickInfo={props.onClickHeaderInfo}>
            {props.headerTitle}
          </HeadingWithInfo>
        ) : (
          <Heading tag={Tag.H4}>{props.headerTitle}</Heading>
        )}
        {'headerButtons' in props && (
          <SmallButtonGrid>
            {props.headerButtons.map((button, i) => (
              <Button
                key={i}
                color={activeButton === i ? 'white' : 'blue'}
                border={activeButton === i ? 'blue' : 'borderDark'}
                background={activeButton === i ? 'blue' : 'transparent'}
                onClick={() => setActiveButton(i)}
                size="small"
              >
                {button.title}
              </Button>
            ))}
          </SmallButtonGrid>
        )}
      </SectionHeader>
      <StaticCard>
        <TopListItemContainer>
          {isEmpty && <div style={{ textAlign: 'center' }}>N/A</div>}
          {'topList' in props
            ? props.topList.map((item, index) =>
                props.renderTopListItem(item, index)
              )
            : props.headerButtons[activeButton].topList.map((item, index) =>
                props.headerButtons[activeButton].renderTopListItem(item, index)
              )}
        </TopListItemContainer>
      </StaticCard>
    </>
  );
};

export default TopList;
