import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ContentWidth } from 'styles';
import { useAppDispatch } from 'hooks/redux';
import {
  setLayoutHeader,
  useGenerateMfaCodeQuery,
  useGetUserQuery,
  useToggleMfaMutation,
} from 'store';

import { IconType } from 'components/UI/Icon';
import Heading from 'components/UI/Heading';
import Loader from 'components/UI/Loader';
import EmptyState from 'components/UI/EmptyState';
import Setup2FAForm from 'components/forms/Setup2FAForm';
import ToggleCard from 'components/UI/Cards/ToggleCard';
import Disable2FAModal from 'components/modals/Disable2FAModal';

const MFASettings: FC = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();

  // Hooks
  const { isLoading, data } = useGetUserQuery();
  const [toggleMfa, toggleResult] = useToggleMfaMutation();
  const mfaCode = useGenerateMfaCodeQuery(undefined, {
    skip: data?.twoFactorAuthEnabled,
  });

  // State
  const [disableModalOpen, setDisableModalOpen] = useState<boolean>(false);

  // Set header
  useEffect(() => {
    dispatch(
      setLayoutHeader({
        title: intl.formatMessage({
          id: 'pageSettingsTitle',
          defaultMessage: 'Settings',
          description: 'Page title for settings',
        }),
        icon: IconType.Back,
      })
    );
  }, [dispatch, intl]);

  // Submit
  const onSubmit = useCallback(
    (code: string) => toggleMfa({ code }),
    [toggleMfa]
  );

  // Close
  const onCloseDisableModal = useCallback(() => setDisableModalOpen(false), []);
  const onOpenDisableModal = useCallback(() => setDisableModalOpen(true), []);

  // Content
  const content = useMemo(() => {
    if (isLoading || mfaCode.isLoading || toggleResult.isLoading) {
      return <Loader padding color="blue" />;
    }
    if (!data) {
      return (
        <EmptyState iconType={IconType.User} padding>
          <FormattedMessage
            id="pageProfileSettingsEmptyState"
            defaultMessage="Unable to get user data"
            description="Empty state for user profile settings"
          />
        </EmptyState>
      );
    }
    if (data.twoFactorAuthEnabled) {
      return (
        <ToggleCard
          title={intl.formatMessage({
            id: 'settingsTwoFactorAuthenticationTitle',
            defaultMessage: 'Two-Factor authentication enabled',
            description: 'Settings title for two-factor authentication',
          })}
          isActive={data.twoFactorAuthEnabled}
          onToggle={onOpenDisableModal}
        />
      );
    }
    return <Setup2FAForm data={mfaCode.data} submitForm={onSubmit} />;
  }, [
    intl,
    data,
    mfaCode,
    isLoading,
    toggleResult,
    onSubmit,
    onOpenDisableModal,
  ]);

  return (
    <ContentWidth isSurface>
      <Heading>
        <FormattedMessage
          id="pageMFASettingsTitle"
          defaultMessage="Two-Factor Authentication"
          description="Page title for mfa settings"
        />
      </Heading>
      {content}
      <Disable2FAModal
        isOpen={disableModalOpen}
        onClose={onCloseDisableModal}
      />
    </ContentWidth>
  );
};

export default MFASettings;
