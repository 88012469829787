import styled from 'styled-components';

export const BorderedList = styled.div`
  > * {
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
    transition: 0.2s ease-in-out;
    &:first-child {
      border-top: 1px solid ${({ theme }) => theme.colors.borderLight};
    }
    &:last-child {
      border-bottom: none;
    }
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  gap: 8px;
`;

export const CardDescription = styled.div`
  font-size: 12px;
  fony-family: 'Mark-Medium';
  color: ${({ theme }) => theme.colors.grayText};
`;

export const EditContainer = styled.div`
  cursor: pointer;
`;
