import React, { FC, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import {
  localizationSelector,
  setLayoutHeader,
  useGetExerciseProgramCategoryQuery,
} from 'store';

import { Category, ContentWidth } from 'styles';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useLocalizedText } from 'hooks/useLocalizedText';
import { getImage } from 'utils/asset';
import * as routes from 'router/routes';

import Loader from 'components/UI/Loader';
import Heading from 'components/UI/Heading';
import CategoryCard from 'components/UI/Cards/CategoryCard';
import EmptyState from 'components/UI/EmptyState';
import { IconType } from 'components/UI/Icon';

import { CardList, CategoryHeader } from './styles';

const ExerciseProgramsCategory: FC = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const getText = useLocalizedText();
  const { slug } = useParams();
  const { language } = useAppSelector(localizationSelector);

  // Set header
  useEffect(() => {
    if (slug) {
      dispatch(
        setLayoutHeader({
          title: intl.formatMessage({
            id: 'tabExercisePrograms',
            defaultMessage: 'Programs',
            description: 'Tab item for exercise programs',
          }),
          icon: IconType.Back,
          reminder: { slug: slug, redirectUrl: window.location.href },
          link: routes.EXERCISE,
          favorite: {
            reference: slug,
            type: 'exerciseProgramCategory',
          },
        })
      );
    }
  }, [dispatch, intl, slug]);

  // Hooks
  const { data, isLoading } = useGetExerciseProgramCategoryQuery({
    slug,
    language: language?.languageCode,
  });

  if (isLoading) {
    return <Loader color="blue" padding />;
  }

  // No data
  if (!data) {
    return (
      <EmptyState iconType={IconType.Exercise} padding>
        <FormattedMessage
          id="pageExerciseProgramsCategoryEmptyState"
          defaultMessage="Exercise program category not found"
          description="Empty state for exercise program category"
        />
      </EmptyState>
    );
  }

  const {
    exerciseProgramCategory: [category],
    exercisePrograms,
  } = data;

  return (
    <ContentWidth isSurface>
      <CategoryHeader>
        <Category>
          <FormattedMessage
            id="pageExerciseProgramsCategory"
            defaultMessage="Program"
            description="Page category for exercise programs"
          />
        </Category>
        <Heading>{getText(category.title)}</Heading>
      </CategoryHeader>
      <CardList>
        {exercisePrograms.map(({ _id, title, image, slug }) => {
          return (
            <CategoryCard
              key={_id}
              title={getText(title)}
              link={`${routes.EXERCISE_PROGRAM}/${slug.current}`}
              image={getImage(image)}
            />
          );
        })}
      </CardList>
    </ContentWidth>
  );
};

export default ExerciseProgramsCategory;
