import React, { FC, ReactNode, useEffect } from 'react';

import { ModalContainer } from './styles';

type Props = {
  children: ReactNode;
  isOpen: boolean;
};

const Modal: FC<Props> = ({ children, isOpen }) => {
  // Freeze body
  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'unset';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  return (
    <ModalContainer
      initial={false}
      variants={{
        open: {
          opacity: 1,
          display: 'block',
        },
        closed: {
          opacity: 0,
          display: 'none',
        },
      }}
      transition={{ type: 'tween' }}
      animate={isOpen ? 'open' : 'closed'}
    >
      {children}
    </ModalContainer>
  );
};

export default Modal;
