import React, { FC, MouseEventHandler, ReactNode } from 'react';
import { Container, HeadingContent, IconButton } from './styles';
import Heading, { Tag } from '../Heading/Heading';
import Icon, { IconType } from '../Icon/Icon';
import { ThemeColor } from 'styles';

type Props = {
  color?: ThemeColor;
  center?: boolean;
  children: ReactNode;
  tag?: Tag;
  onClickInfo: MouseEventHandler<HTMLDivElement>;
};

const HeadingWithInfo: FC<Props> = ({
  tag = Tag.H1,
  children,
  onClickInfo,
  center = false,
  color = 'black',
}) => {
  return (
    <Container center={center}>
      <Heading tag={tag}>
        <HeadingContent>
          {children}
          <IconButton onClick={onClickInfo}>
            <Icon type={IconType.Info} color={color} />
          </IconButton>
        </HeadingContent>
      </Heading>
    </Container>
  );
};

export default HeadingWithInfo;
