import styled from 'styled-components';
import { breakpoints, shadows } from 'styles';

type ButtonProps = {
  position: 'left' | 'right';
  hideOnMobile: boolean;
  hideOnDisabled: boolean;
};

export const Button = styled.button<ButtonProps>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 20px;
  left: ${({ position }) => (position === 'left' ? '-18px' : 'initial')};
  right: ${({ position }) => (position === 'right' ? '-18px' : 'initial')};
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 1;
  box-shadow: ${shadows.overlay};
  cursor: pointer;

  &:disabled {
    opacity: ${({ hideOnDisabled }) => (hideOnDisabled ? 0 : '80%')};
  }

  @media (max-width: ${breakpoints.m}px) {
    display: ${({ hideOnMobile }) => (hideOnMobile ? 'none' : 'flex')};
  }
`;

export const IconContainer = styled.div<Pick<ButtonProps, 'position'>>`
  display: flex;
  transform: ${({ position }) =>
    position === 'left' ? 'rotate(180deg)' : 'initial'};
`;
