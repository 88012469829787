import React, { type ReactNode } from 'react';
import {
  TableElement,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableWrapper,
} from './styles';
import Icon, { IconType } from '../Icon';
import EmptyState from '../EmptyState';

type Column<T> = {
  key: keyof T;
  title: string | ReactNode;
  render?: (value: unknown) => ReactNode;
};

type Props<T> = {
  data: T[];
  columns: Column<T>[];
  onRowClick?: (row: T) => void;
  emptyText?: string;
};

const Table = <T extends Record<string, any>>({
  data,
  columns,
  onRowClick,
  emptyText,
}: Props<T>) => {
  if (emptyText != null && data.length === 0) {
    return (
      <EmptyState align="left" compact>
        {emptyText}
      </EmptyState>
    );
  }
  return (
    <TableWrapper>
      <TableElement>
        <TableHeader>
          <tr>
            {columns.map((column) => (
              <TableHeaderCell key={column.key.toString()}>
                {column.title}
              </TableHeaderCell>
            ))}
            {onRowClick && <TableHeaderCell />}
          </tr>
        </TableHeader>
        <TableBody>
          {data.map((row, rowIndex) => (
            <TableRow
              key={rowIndex}
              clickable={onRowClick != null}
              onClick={() => onRowClick?.(row)}
            >
              {columns.map((column) => (
                <TableCell key={column.key.toString()}>
                  {column.render
                    ? column.render(row[column.key])
                    : row[column.key]}
                </TableCell>
              ))}
              {onRowClick && (
                <TableCell>
                  <Icon type={IconType.Arrow} />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </TableElement>
    </TableWrapper>
  );
};

export default Table;
