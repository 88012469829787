import { ActivityToplist } from 'models';
import {
  getEndpointURL,
  WellrEndpointDefinition,
  WellrEndpointPath,
} from 'utils/api';


const getActivityStatePath: WellrEndpointPath = '/api/activity/me/company/total-steps';
const getActivityStateMethod = 'get';
type GetActivityState = WellrEndpointDefinition<
  typeof getActivityStatePath,
  typeof getActivityStateMethod
  >;

const getTotalPointsStatePath: WellrEndpointPath = '/api/activity/me/company/total-points';
const getTotalPointsStateMethod = 'get';
type GetTotalPointsState = WellrEndpointDefinition<
  typeof getTotalPointsStatePath,
  typeof getActivityStateMethod
>;

export type GetActivityStateType = {
  result: ActivityToplist;
  queryArgs: Required<GetActivityState['request']['params']['query']>;
  };

export type GetTotalPointsStateType = {
  result: ActivityToplist;
  queryArgs: Required<GetTotalPointsState['request']['params']['query']>;
};

export const endpoints = {
  getActivityState: {
    path: getEndpointURL(getActivityStatePath),
    method: getActivityStateMethod,
  },
  getTotalPointsState: {
    path: getEndpointURL(getTotalPointsStatePath),
    method: getTotalPointsStateMethod,
  },
};