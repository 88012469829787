import Icon, { IconType } from 'components/UI/Icon';
import { FC } from 'react';
import { FlexContainer } from 'styles';
import StaticCard from '../StaticCard';
import {
  CardContainer,
  CardDescription,
  CardHeading,
  IconContainer,
} from './styles';
import ToggleButton from '../../ToggleButton/ToggleButton';

type Props = {
  title: string;
  description?: string;
  isActive: boolean;
  isLocked?: boolean;
  onToggle: (active: boolean) => void;
  onDelete: () => void;
};

const NotificationDeviceCard: FC<Props> = ({
  title,
  description,
  isActive,
  isLocked,
  onToggle,
  onDelete,
}) => {
  return (
    <StaticCard>
      <CardContainer>
        <FlexContainer>
          <div>
            <CardHeading>{title}</CardHeading>
            {description != null && (
              <CardDescription isActive={true}>Disable/enable notifications on device</CardDescription>
            )}
          </div>
        </FlexContainer>
      </CardContainer>
    </StaticCard>
  );
};

export default NotificationDeviceCard;
