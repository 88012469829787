import React, { FC, useEffect, useMemo } from 'react';
import Loader from 'components/UI/Loader';
import IconButton from 'components/UI/IconButton';
import SlideoutModal from 'components/UI/SlideoutModal';
import Heading, { Tag } from 'components/UI/Heading';
import Icon, { IconType } from 'components/UI/Icon';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-hot-toast';
import { Header, Body } from './styles';
import { useAddOrganizationNotificationScheduleMutation } from 'store/notificationScheduleService/notificationScheduleService';
import RegisterContentOrganizationForm from 'components/forms/RegisterContentOrganizationForm';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  companyId: string;
  onSuccessfulSubmit?: () => void;
  date: Date;
};

const RegisterContentOrganizationModal: FC<Props> = ({
  isOpen,
  onClose,
  companyId,
  date,
  onSuccessfulSubmit,
}) => {
  const intl = useIntl();

  // Hooks
  const [registerContent, registerResult] = useAddOrganizationNotificationScheduleMutation();

  useEffect(() => {
    if (registerResult.isSuccess) {
      toast.success(
        intl.formatMessage({
          id: 'RegisterHealthFocusModalSuccess',
          defaultMessage: 'Health focus registration successful',
          description: 'Register health focus modal success',
        })
      );
      onClose();
      onSuccessfulSubmit && onSuccessfulSubmit();
    }
  }, [registerResult, intl, onClose]);

  const content = useMemo(() => {
    if (registerResult.isLoading) {
      return <Loader padding color="blue" />;
    }

    return (
      <RegisterContentOrganizationForm
        date={date}
        submitForm={registerContent}
        isOpen={isOpen}
        companyId={companyId}
        redirectUrl={window.location.href}
      />
    );
  }, [date]);

  return (
    <SlideoutModal isOpen={isOpen} onClose={onClose}>
      <div>
        <Header>
          <Heading tag={Tag.H3}>
            <FormattedMessage
              id="RegisterHealthFocusModalTitle"
              defaultMessage="Register health focus"
              description="Register health focus modal title"
            />
          </Heading>
          <IconButton onClick={onClose} padding>
            <Icon type={IconType.Close} />
          </IconButton>
        </Header>
        <Body>{content}</Body>
      </div>
    </SlideoutModal>
  );
};

export default RegisterContentOrganizationModal;
