import { FC, useCallback } from 'react';

import { ToggleSwitch, ToggleHandle } from './styles';

type Props = {
  isActive?: boolean;
  isLocked?: boolean;
  onToggle: (active: boolean) => void;
};

const RadioButton: FC<Props> = ({ isActive = false, isLocked = false, onToggle }) => {
  const onClick = useCallback(() => {
    if (isLocked) return;
    onToggle(!isActive)
  }, [isActive, onToggle]);

  return (
    <ToggleSwitch isActive={isActive} isLocked={isLocked} onClick={onClick}>
      <ToggleHandle
        layout
        transition={{
          type: 'spring',
          stiffness: 700,
          damping: 30,
        }}
        $isLocked={isLocked}
      />
    </ToggleSwitch>
  );
};

export default RadioButton;
