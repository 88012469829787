import React, { FC, useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import {
  localizationSelector,
  useGetActivityTypesQuery,
  useGetMonthlyActivitiesQuery,
  useGetStepGoalQuery,
} from 'store';
import { useAppSelector } from 'hooks/redux';
import { isSameDay } from 'utils/date';
import { sortArrayByProperty } from 'utils/array';
import { groupActivities } from 'utils/activity';

import ActivityList from 'components/fragments/ActivityList';
import RegisterActivityModal from 'components/modals/RegisterActivityModal';
import StaticCard from 'components/UI/Cards/StaticCard';
import Loader from 'components/UI/Loader';
import Button from 'components/UI/Button';

import { Grid, Action, CalendarGrid } from './styles';

const ActivitiesMonthly: FC = () => {
  const { language } = useAppSelector(localizationSelector);

  const RE_FETCH_TIMEOUT_MS = 1000 * 10
  // State
  const [lastGetStepGoalFetch] = useState(0);
  const [date, setActive] = useState<Date>(new Date());
  const [registerModalOpen, setRegisterModalOpen] = useState<boolean>(false);

  const { dailyStepGoal } = useGetStepGoalQuery(undefined, {
    refetchOnMountOrArgChange: true,

    refetchOnFocus: lastGetStepGoalFetch + RE_FETCH_TIMEOUT_MS < Date.now(),
    selectFromResult: (response) => ({
      dailyStepGoal: response.data?.dailyStepGoal,
      isLoading: response.isLoading,
      fulfilledTimeStamp: response.fulfilledTimeStamp
    }),
  });

  const setTimeToCurrent = (date: Date): Date => {
    const newDate = new Date(date);
    const now = new Date();
    newDate.setHours(now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds());
    return newDate;
  };

  // Hooks
  const activityTypes = useGetActivityTypesQuery({
    language: language?.languageCode,
  });
  const activities = useGetMonthlyActivitiesQuery({
    date: date.toString(),
  });

  // Open / close modal
  const onOpenModal = useCallback(() => setRegisterModalOpen(true), []);
  const onCloseModal = useCallback(() => setRegisterModalOpen(false), []);

  // Activity dates
  const activityDates = useMemo(() => {
    const { data } = activities;
    if (!data) {
      return [];
    }
    return groupActivities(data).map((item) => new Date(item.timestamp));
  }, [activities]);



  // Last activities
  const dayActivities = useMemo(() => {
    if (!activities.data || !activityTypes.data) {
      return null;
    }
    const sortedActivities = sortArrayByProperty(
      activities.data,
      'timestamp',
      'desc'
    );
    const filteredActivities = sortedActivities.filter((item) =>
      isSameDay(new Date(item.timestamp), date)
    );

    return <ActivityList data={filteredActivities} showWalkSteps />;
  }, [activities, activityTypes, date]);


  // Get step count
  const getStepCount = useCallback(
    (day: number) => {
      if (!activities.data) {
        return 0;
      }

      return activities.data.reduce((sum, { timestamp, value }) => {
        if (new Date(timestamp).getDay() === day) {
          return sum + value.stepsAwarded;
        }
        return sum;
      }, 0);
    },
    [activities.data]
  );


  // Utility function
  const isActivityAboveGoal = (activities: any[], date: Date, goal: number) => {
    if (goal === undefined || goal === null || goal === 0) {
      return false;
    }
    const steps = activities.reduce((sum, { timestamp, value }) => {
      if (isSameDay(new Date(timestamp), date)) {
        return sum + value.stepsAwarded;
      }
      return sum;
    }, 0);

    return steps >= goal;
  };

  // Loading
  if (activityTypes.isLoading || activities.isLoading) {
    return <Loader color="blue" padding />;
  }

  // No data
  if (!activityTypes.data || !activities.data) {
    return null;
  }

  return (
    <StaticCard>
      <Grid>
        <CalendarGrid>
          <Calendar
            value={date}
            onActiveStartDateChange={({ activeStartDate }) =>
              setActive(activeStartDate as Date)
            }
            onChange={(value) => setActive(setTimeToCurrent(value as Date))}
            tileClassName={({ date, view }) => {
              if (
                view === 'month' &&
                activityDates.some((activityDate) =>
                  isSameDay(activityDate, date)
                )
              ) {
                if (activities.data && isActivityAboveGoal(activities.data, date, dailyStepGoal!)) {
                  return 'goalMetDay';
                }
                return 'activeCalendarDay';
              }
              return '';
            }}
          />
        </CalendarGrid>
        {dayActivities}
        <Action>
          <Button color="blue" background="transparent" onClick={onOpenModal}>
            <FormattedMessage
              id="registerActivityButton"
              defaultMessage="Register activity"
              description="Register activity button"
            />
          </Button>
        </Action>
      </Grid>
      <RegisterActivityModal
        date={date}
        isOpen={registerModalOpen}
        onClose={onCloseModal}
      />
    </StaticCard>
  );
};

export default ActivitiesMonthly;
