import React, { FC, useEffect, Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ContentWidth } from 'styles';
import { useAppDispatch } from 'hooks/redux';
import { setLayoutHeader, useGetCompanyCompetitionsQuery } from 'store';

import Heading, { Tag } from 'components/UI/Heading';
import Loader from 'components/UI/Loader';

import { CardList } from './styles';
import * as routes from 'router/routes';
import CompetitionCard from 'components/cards/CompetitionCard';
import { getImage } from 'utils/asset';
import { useLocalizedText } from 'hooks/useLocalizedText';
import EmptyState from 'components/UI/EmptyState';
import { IconType } from 'components/UI/Icon';
import { usePretendCompany } from 'hooks/usePretendCompany';

const AdminCompletedCompetitions: FC = () => {
  const intl = useIntl();
  const companyId = usePretendCompany();
  const dispatch = useAppDispatch();

  // Hooks
  const getText = useLocalizedText();
  const { data, isLoading } = useGetCompanyCompetitionsQuery({ companyId });

  // Set header
  useEffect(() => {
    dispatch(
      setLayoutHeader({
        title: intl.formatMessage({
          id: 'pageAdminCompetitionsTitle',
          defaultMessage: 'Competitions',
          description: 'Page title for admin competitions page',
        }),
        icon: IconType.Back,
        link: routes.ADMIN_COMPETITIONS,
      })
    );
  }, [dispatch, intl]);

  // Loading
  if (isLoading) {
    return <Loader color="blue" padding />;
  }

  // No data
  if (!data) {
    return null;
  }

  return (
    <ContentWidth isSurface>
      <Fragment>
        <Heading tag={Tag.H4}>
          <FormattedMessage
            id="pageCompeteCompletedCompetitionsTitle"
            defaultMessage="Completed competitions"
            description="Section title for completed competitions"
          />
        </Heading>
        <CardList>
          {!data.completedContests?.length && (
            <EmptyState iconType={IconType.Flag} padding>
              <FormattedMessage
                id="completedCompetitionsEmptyState"
                defaultMessage="No completed competitions"
                description="Empty state for completed competitions"
              />
            </EmptyState>
          )}
          {data.completedContests?.map((item, i) => (
            <CompetitionCard
              key={item.id}
              title={getText(item.title)}
              image={getImage(item.image)}
              wellrPoints={item.wellrPoints}
              link={`${routes.ADMIN_COMPETITIONS}/${item.id}`}
              accepted={false}
              type={item.type}
            />
          ))}
        </CardList>
      </Fragment>
    </ContentWidth>
  );
};

export default AdminCompletedCompetitions;
