import React, { FC, useCallback, useState } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { ActionContainer, Gap, TwoColGrid } from 'styles';
import { InviteUserQuery, UserGender } from 'models';

import Button from 'components/UI/Button/Button';
import TextField from 'components/UI/TextField/TextField';
import SelectField from 'components/UI/SelectField';
import Heading, { Tag } from 'components/UI/Heading';
import GdprModal from 'components/modals/GdprModal';
import { useUserGenders } from 'hooks/useGenders';
import { useQuery } from 'hooks/useQuery';

type Props = {
  submitForm: (data: InviteUserQuery) => void;
  deny: (data: InviteUserQuery) => void;
};

const ConfirmRegisterInviteForm: FC<Props> = ({ submitForm, deny }) => {
  const intl = useIntl();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: null,
      lastName: null,
      gender: UserGender.Unknown,
      dateOfBirth: null,
      password: null,
      verifyPassword: null,
    },
  });

  const [registrationCode] = useQuery('registrationCode');

  // State
  const [isGdprOpen, setIsGdprOpen] = useState<boolean>(false);

  // Watch
  const currentPassword = watch('password');

  // Hooks
  const genders = useUserGenders();

  // Submit
  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    (values) => {
      if (!isGdprOpen) {
        return setIsGdprOpen(true);
      }
      submitForm({
        secretCode: registrationCode as string,
        body: {
          firstName: values.firstName,
          lastName: values.lastName,
          gender: values.gender,
          dateOfBirth: values.dateOfBirth,
          password: {
            password: values.password,
            verifyPassword: values.verifyPassword,
          },
        },
      });
    },
    [submitForm, isGdprOpen, registrationCode]
  );

  // Submit
  const onDeny = useCallback(
    () => deny({ secretCode: registrationCode as string }),
    [deny, registrationCode]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Heading tag={Tag.H3}>
        <FormattedMessage
          id="confirmRegisterSectionPersonalTitle"
          defaultMessage="Personal information"
          description="Confirm register section personal information title"
        />
      </Heading>
      <TextField
        label={intl.formatMessage({
          id: 'inputFirstNameLabel',
          defaultMessage: 'First name',
          description: 'Label for first name input',
        })}
        placeholder={intl.formatMessage({
          id: 'inputFirstNamePlaceholder',
          defaultMessage: 'Enter first name',
          description: 'Placeholder for first name input',
        })}
        error={errors.firstName}
        register={register('firstName', {
          required: intl.formatMessage({
            id: 'inputErrorFieldRequired',
            defaultMessage: 'Field is is required',
            description: 'Input error for field required',
          }),
        })}
      />
      <TextField
        label={intl.formatMessage({
          id: 'inputLastNameLabel',
          defaultMessage: 'Last name',
          description: 'Label for last name input',
        })}
        placeholder={intl.formatMessage({
          id: 'inputLastNamePlaceholder',
          defaultMessage: 'Enter last name',
          description: 'Placeholder for last name input',
        })}
        error={errors.lastName}
        register={register('lastName', {
          required: intl.formatMessage({
            id: 'inputErrorFieldRequired',
            defaultMessage: 'Field is is required',
            description: 'Input error for field required',
          }),
        })}
      />
      <TwoColGrid>
        <SelectField
          label={intl.formatMessage({
            id: 'inputGenderLabel',
            defaultMessage: 'Gender',
            description: 'Label for gender input',
          })}
          register={register('gender', {
            required: intl.formatMessage({
              id: 'inputErrorFieldRequired',
              defaultMessage: 'Field is is required',
              description: 'Input error for field required',
            }),
          })}
          options={genders}
          error={errors.gender}
        />
        <TextField
          label={intl.formatMessage({
            id: 'inputBirthDateLabel',
            defaultMessage: 'Birth date',
            description: 'Label for birth date input',
          })}
          placeholder={intl.formatMessage({
            id: 'inputBirthDatePlaceholder',
            defaultMessage: 'Enter birth date',
            description: 'Placeholder for birth date input',
          })}
          type="date"
          error={errors.dateOfBirth}
          register={register('dateOfBirth', {
            required: intl.formatMessage({
              id: 'inputErrorBirthDateRequired',
              defaultMessage: 'Birth date is required',
              description: 'Input error for birth date required',
            }),
          })}
        />
      </TwoColGrid>
      <Heading tag={Tag.H3}>
        <FormattedMessage
          id="confirmRegisterSectionAccountTitle"
          defaultMessage="Account credentials"
          description="Confirm register section account title"
        />
      </Heading>
      <TextField
        label={intl.formatMessage({
          id: 'inputPasswordLabel',
          defaultMessage: 'Password',
          description: 'Label for password input',
        })}
        placeholder={intl.formatMessage({
          id: 'inputPasswordPlaceholder',
          defaultMessage: 'Enter password',
          description: 'Placeholder for password input',
        })}
        register={register('password', {
          required: intl.formatMessage({
            id: 'inputErrorPasswordRequired',
            defaultMessage: 'Password is required',
            description: 'Input error for password required',
          }),
          minLength: {
            value: 8,
            message: intl.formatMessage({
              id: 'inputErrorPasswordLength',
              defaultMessage: 'Password cannot be less than 8 characters',
              description: 'Input error for password length',
            }),
          },
          validate: (value: any) => {
            if (value.search(/[a-z]/) < 0) {
              return intl.formatMessage({
                id: 'inputErrorPasswordOneLowercase',
                defaultMessage:
                  'Password must contain at least one lowercase letter',
                description: 'Input error for password lowercase letter',
              });
            }
            if (value.search(/[A-Z]/) < 0) {
              return intl.formatMessage({
                id: 'inputErrorPasswordOneUppercase',
                defaultMessage:
                  'Password must contain at least one uppercase letter',
                description: 'Input error for password uppercase letter',
              });
            }
            if (value.search(/[0-9]/) < 0) {
              return intl.formatMessage({
                id: 'inputErrorPasswordOneNumber',
                defaultMessage: 'Password must contain at least one number',
                description: 'Input error for password one number',
              });
            }
            return true;
          },
        })}
        type="password"
        error={errors.password}
      />
      <TextField
        label={intl.formatMessage({
          id: 'inputVerifyPasswordLabel',
          defaultMessage: 'Verify password',
          description: 'Label for verify password input',
        })}
        placeholder={intl.formatMessage({
          id: 'inputPasswordPlaceholder',
          defaultMessage: 'Enter password',
          description: 'Placeholder for password input',
        })}
        register={register('verifyPassword', {
          required: intl.formatMessage({
            id: 'inputErrorPasswordRequired',
            defaultMessage: 'Password is required',
            description: 'Input error for password required',
          }),
          validate: (value) =>
            value === currentPassword ||
            intl.formatMessage({
              id: 'inputErrorPasswordNoMatch',
              defaultMessage: 'Passwords do not match',
              description: 'Input error for no match passwords',
            }),
        })}
        type="password"
        error={errors.verifyPassword}
      />
      <Gap />
      <ActionContainer>
        <Button type="submit" color="black" background="yellow">
          <FormattedMessage
            id="confirmButton"
            defaultMessage="Confirm"
            description="Confirm button text"
          />
        </Button>
      </ActionContainer>
      <GdprModal
        isOpen={isGdprOpen}
        onClose={() => setIsGdprOpen(false)}
        onDeny={onDeny}
      />
    </form>
  );
};

export default ConfirmRegisterInviteForm;
