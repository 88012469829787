import React, { FC, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import {
  authSelector,
  login,
  setLanguageByCode,
  useGetUserQuery,
  useLoginMfaMutation,
  useLoginMutation,
  useMigrateUserMutation,
} from 'store';
import * as routes from 'router/routes';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

import Heading from 'components/UI/Heading';
import Loader from 'components/UI/Loader';
import BackgroundCover from 'components/UI/BackgroundCover';
import CoverHeader from 'components/UI/CoverHeader';
import LoginForm from 'components/forms/LoginForm';
import Login2FAForm from 'components/forms/Login2FAForm';

import { CoverContent } from 'styles';
import GdprModal from 'components/modals/GdprModal';

const Login: FC = () => {
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useAppSelector(authSelector);

  // Hooks
  const navigate = useNavigate();
  const [migrateUser, migrateUserResult] = useMigrateUserMutation();
  const [loginUser, loginResult] = useLoginMutation();
  const [login2FAUser, login2FAResult] = useLoginMfaMutation();
  const userResponse = useGetUserQuery(undefined, {
    skip: !isAuthenticated,
    selectFromResult: (result) => ({
      isSuccess: result.isSuccess,
      language: result.data?.language,
      isLoading: result.isLoading,
    }),
  });

  // State
  const [isGdprOpen, setIsGdprOpen] = useState<boolean>(false);

  // Login on success
  useEffect(() => {
    if (loginResult.data?.migratableUser === true) {
      setIsGdprOpen(true);
      return;
    }
    if (loginResult.data && !loginResult.data.twoFactorAuthRequired) {
      dispatch(login(loginResult.data.token));
    }
    if (login2FAResult.data) {
      dispatch(login(login2FAResult.data));
    }
  }, [dispatch, loginResult, login2FAResult]);

  // Handle user migration
  useEffect(() => {
    if (migrateUserResult.isSuccess && migrateUserResult.data) {
      dispatch(login(migrateUserResult.data.token));
    }
  }, [dispatch, migrateUserResult.isSuccess, migrateUserResult.data]);

  // Form loading
  const content = useMemo(() => {
    const { data, isLoading } = loginResult;

    if (isLoading || userResponse.isLoading || migrateUserResult.isLoading) {
      return <Loader padding />;
    }
    if (data?.twoFactorAuthRequired) {
      return <Login2FAForm id={data.id} submitForm={login2FAUser} />;
    }
    return <LoginForm submitForm={loginUser} />;
  }, [
    loginResult,
    userResponse.isLoading,
    migrateUserResult.isLoading,
    loginUser,
    login2FAUser,
  ]);

  useEffect(() => {
    if (
      userResponse.isSuccess &&
      (login2FAResult.isSuccess ||
        (loginResult.isSuccess && !loginResult.data.twoFactorAuthRequired))
    ) {
      dispatch(setLanguageByCode(userResponse.language));
      if (migrateUserResult.isSuccess) {
        navigate(routes.REGISTER_COMPLETE);
      } else {
        navigate(routes.ROOT);
      }
    }
  }, [
    dispatch,
    navigate,
    userResponse,
    loginResult,
    login2FAResult,
    migrateUserResult.isSuccess,
  ]);

  return (
    <BackgroundCover padding>
      <CoverHeader />
      <CoverContent>
        <Heading>
          <FormattedMessage
            id="loginTitle"
            defaultMessage="Log in"
            description="Login page title"
          />
        </Heading>
        {content}
      </CoverContent>
      {loginResult.data?.migratableUser === true && (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            migrateUser({
              email: loginResult.originalArgs?.email as string,
              password: loginResult.originalArgs?.password as string,
            });
            setIsGdprOpen(false);
          }}
        >
          <GdprModal
            isOpen={isGdprOpen}
            onClose={() => setIsGdprOpen(false)}
            onDeny={() => setIsGdprOpen(false)}
            migratingUser={true}
          />
        </form>
      )}
    </BackgroundCover>
  );
};

export default Login;
