
import React, { FC, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-hot-toast';


import Loader from 'components/UI/Loader/Loader';
import Icon, { IconType } from 'components/UI/Icon';
import IconButton from 'components/UI/IconButton';
import Heading, { Tag } from 'components/UI/Heading';
import SlideoutModal from 'components/UI/SlideoutModal';

import { Header, Body } from './styles';
import { User } from 'models';
import { useChangeMembershipMutation, useCreateMembershipMutation } from 'store';
import UpdateUserCompanyForm from 'components/forms/UpdateUserCompanyForm';
import useClaims from 'hooks/useClaims';

type Props = {
  user: User;
  isOpen: boolean;
  onClose: () => void;

};

const UpdateUserCompanyModal: FC<Props> = ({
  user,
  isOpen,
  onClose,
}) => {
  const intl = useIntl();

  const claims = useClaims();
  let accessRightType = claims?.accessrighttype;
  const [createMembership, membershipResult] = useCreateMembershipMutation();
  const [changeMembership] = useChangeMembershipMutation();
  // Update user by changing membership

  useEffect(() => {
    if (membershipResult.isSuccess) {
      toast.success(
        intl.formatMessage({
          id: 'updateUserModalSuccess',
          defaultMessage: 'User update successful',
          description: 'User update success message',
        })
      );
      changeMembership({
        userId: user.id,
        membershipId: membershipResult.data.id ?? '',
      });
      onClose();
    }
  }, [membershipResult, intl, onClose]);

  const content = useMemo(() => {
    if (membershipResult.isLoading) {
      return <Loader padding color="blue" />;
    }
    return (
      <UpdateUserCompanyForm
        submitForm={(data) => {
          if (accessRightType !== undefined) {
            toast.error(
              intl.formatMessage({
                id: 'updateUserCompanyFailureRegularUser',
                defaultMessage: 'User company update failed, user have to be regular user',
                description: 'User update success message',
              })
            );
          }
          else {
            createMembership({
              companyAdmin: user.companyAdmin,
              companyId: data.companyId,
              moduleAccessRightCollection: undefined as unknown as string,
              userId: user.id,
              departmentId: data.departmentId,
            })
          }
        }}
      />
    );
  }, [user, createMembership, membershipResult.isLoading]);

  return (
    <SlideoutModal isOpen={isOpen} onClose={onClose}>
      <div>
        <Header>
          <Heading tag={Tag.H3}>
            <FormattedMessage
              id="updateUserCompanyModalTitle"
              defaultMessage="Choose company"
              description="Change company modal title"
            />
          </Heading>
          <IconButton onClick={onClose} padding>
            <Icon type={IconType.Close} />
          </IconButton>
        </Header>
        <Body>{content}</Body>
      </div>
    </SlideoutModal>
  );
};

export default UpdateUserCompanyModal;