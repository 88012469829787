import { useWindowSize } from 'hooks/useWindowSize';
import React, { FC, Fragment, ReactNode, useEffect, useMemo } from 'react';
import { breakpoints } from 'styles';

import { ModalBackground, ModalContainer } from './styles';

type Props = {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
};

const SlideoutModal: FC<Props> = ({ children, isOpen, onClose }) => {
  // Hooks
  const { width } = useWindowSize();

  const isMobile = useMemo(() => width < breakpoints.l, [width]);

  // Freeze body
  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'unset';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  return (
    <Fragment>
      <ModalBackground
        variants={{
          open: {
            opacity: 0.4,
            display: 'block',
          },
          closed: {
            opacity: 0,
            display: 'none',
          },
        }}
        transition={{ type: 'tween' }}
        animate={isOpen ? 'open' : 'closed'}
        onClick={onClose}
      />
      <ModalContainer
        initial={false}
        variants={{
          open: {
            right: 0,
            opacity: 1,
            display: 'block',
          },
          closed: {
            right: isMobile ? 0 : '-400px',
            opacity: 0,
            display: isMobile ? 'none' : 'block',
          },
        }}
        transition={{ type: 'tween' }}
        animate={isOpen ? 'open' : 'closed'}
      >
        {children}
      </ModalContainer>
    </Fragment>
  );
};

export default SlideoutModal;
