import React, { FC, useState, useEffect, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import { ContentWidth } from 'styles';
import { useAppDispatch } from 'hooks/redux';
import {
  setLayoutHeader,
  useAnonymizeUserMutation,
  useGetCompanyQuery,
  useGetUserByEmailQuery,
  useGetUserRegistrationCodeQuery,
} from 'store';
import UpdateUserEmailModal from 'components/modals/UpdateUserEmailModal';
import * as routes from 'router/routes';
import Heading, { Tag } from 'components/UI/Heading';
import Loader from 'components/UI/Loader';
import { IconType } from 'components/UI/Icon';
import Button from 'components/UI/Button';
import EmptyState from 'components/UI/EmptyState';
import DataItem from 'components/UI/DataItem';
import { DataGrid } from './styles';
import StaticCard from 'components/UI/Cards/StaticCard/StaticCard';
import ConfirmActionPopup from 'components/popups/ConfirmActionPopup';
import toast from 'react-hot-toast';
import UpdateUserRegistrationCodeModal from 'components/modals/UpdateUserRegistrationCodeModal';
import { formatDateTime } from 'utils/date';
import UpdateUserCompanyModal from 'components/modals/UpdateUserCompanyModal';
import UpdateUserUmbrellaAdminModal from 'components/modals/UpdateUserUmbrellaAdminModal';
import { useGetUserAccessRightsQuery } from 'store/accessRightsService/accessRightsService';

const AdminUserDetails: FC = () => {
  const intl = useIntl();
  const { email } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Hooks
  const [anonymizeUser, anonymizeUserResult] = useAnonymizeUserMutation();
  const { data, isLoading, refetch: refetchUser } = useGetUserByEmailQuery(email as string, {
    skip: !email || anonymizeUserResult.isSuccess,
  });
  const userRegistrationCodeResult = useGetUserRegistrationCodeQuery(
    {
      email: email as string,
    },
    { skip: !data }
  );
  const { data: companyResult, isLoading: isLoadingCompany , refetch: refetchCompany } = useGetCompanyQuery(
    {
      companyId: data?.membership?.companyId as string,
    },
    { skip: data?.membership?.companyId == null }
  );
  const { data: userAccessRights, isLoading: userAccessRightsIsLoading, refetch } = useGetUserAccessRightsQuery(data?.membership?.id as string ?? undefined);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  // Set header
  useEffect(() => {
    dispatch(
      setLayoutHeader({
        title: intl.formatMessage({
          id: 'pageAdminUsersTitle',
          defaultMessage: 'Users',
          description: 'Page title for admin users',
        }),
        icon: IconType.Back,
        link: routes.ADMIN_USERS,
      })
    );
  }, [dispatch, intl]);

  useEffect(() => {
    if (anonymizeUserResult.isSuccess) {
      toast.success(
        intl.formatMessage({
          id: 'anonymizeUserSuccess',
          defaultMessage: 'User successfully anonymized.',
          description: 'Toast success text when successfully anonymized user.',
        })
      );
      navigate(routes.ADMIN_USERS);
    }
  }, [anonymizeUserResult.isSuccess, intl, navigate]);

  // State
  const [userModalOpen, setUserModalOpen] = useState<'code' | 'email' | 'company' | 'umbrella' | null>(
    null
  );

  // Close
  const onModalOpen = useCallback(
    (modal: 'code' | 'email' | 'company' | 'umbrella') => setUserModalOpen(modal),
    []
  );
  const onModalClose = useCallback(() => {
    setUserModalOpen(null);
    setTimeout(() => {
      refetch();
      refetchUser();
      refetchCompany();
    }, 1000);
  }, [ refetch, refetchUser, refetchCompany]);

  // Details
  const userDetails = useMemo(() => {
    if (!data) {
      return null;
    }
    const details = [
      {
        label: intl.formatMessage({
          id: 'adminUserNameLabel',
          description: 'Admin user details Name label',
          defaultMessage: 'Name',
        }),
        value: `${data.firstName} ${data.lastName}`,
      },
      {
        label: intl.formatMessage({
          id: 'adminUserEmailLabel',
          description: 'Admin user details Email label',
          defaultMessage: 'Email',
        }),
        value: data.email,
      },
      {
        label: intl.formatMessage({
          id: 'adminUserIDLabel',
          description: 'Admin user details ID label',
          defaultMessage: 'ID',
        }),
        value: data.id,
      },
      {
        label: intl.formatMessage({
          id: 'adminUserCompanyLabel',
          description: 'Admin user details company label',
          defaultMessage: 'Company',
        }),
        value: companyResult?.description,
      },
      {
        label: intl.formatMessage({
          id: 'adminUserCompanyAdminLabel',
          description: 'Admin user details CompanyAdmin label',
          defaultMessage: 'Company admin',
        }),
        value: data.companyAdmin
          ? intl.formatMessage({
            id: 'yes',
            description: '"Yes" label',
            defaultMessage: 'Yes',
          })
          : intl.formatMessage({
            id: 'no',
            description: '"No" label',
            defaultMessage: 'No',
          }),
      },
      {
        label: intl.formatMessage({
          id: 'adminUserPointsLabel',
          description: 'Admin user details Points label',
          defaultMessage: 'Points',
        }),
        value: data.points,
      },
      {
        label: intl.formatMessage({
          id: 'adminUserStepsLabel',
          description: 'Admin user details Steps label',
          defaultMessage: 'Steps',
        }),
        value: data.steps,
      },
      {
        label: intl.formatMessage({
          id: 'adminUserRegistrationCodeLabels',
          description: 'Admin user details RegistrationCode label',
          defaultMessage: 'Registration code & expiry date',
        }),
        value: userRegistrationCodeResult.isLoading ? (
          '...'
        ) : userRegistrationCodeResult.isError ? (
          '?'
        ) : userRegistrationCodeResult.data?.resetCode ? (
          <>
            <div>{userRegistrationCodeResult.data.resetCode}</div>
            <div>
              {formatDateTime(
                new Date(userRegistrationCodeResult.data.expires)
              )}
            </div>
          </>
        ) : (
          ''
        ),
      },
    ];
    return (
      <DataGrid>
        {details.map((item) => (
          <DataItem key={item.label} description={item.label}>
            {item.value}
          </DataItem>
        ))}
      </DataGrid>
    );
  }, [data, intl, companyResult?.description, userRegistrationCodeResult, userAccessRights]);

  // Loading
  if (
    isLoadingCompany ||
    isLoading ||
    anonymizeUserResult.isLoading ||
    anonymizeUserResult.isSuccess ||
    userAccessRightsIsLoading
  ) {
    return <Loader color="blue" padding />;
  }

  // Empty state
  if (!data) {
    return (
      <EmptyState iconType={IconType.User} padding>
        <FormattedMessage
          id="pageAdminUserDetailsNotFound"
          defaultMessage="A User with the provided email was not found"
          description="Not found text for admin user details page"
        />
      </EmptyState>
    );
  }

  return (
    <ContentWidth isSurface>
      <Heading tag={Tag.H4}>
        <FormattedMessage
          id="adminUsersDetailsTitle"
          defaultMessage="User details"
          description="User details title on Admin users page"
        />
      </Heading>
      <StaticCard padding>
        {userDetails}
        {userRegistrationCodeResult.data != null && (
          <>
            <Button
              color="blue"
              size="small"
              background="transparent"
              border="borderDark"
              onClick={() => onModalOpen('code')}
            >
              <FormattedMessage
                id="adminUsersManageRegistrationCodeButton"
                defaultMessage="Manage registration code"
                description="Button for managing user registration code on Admin users details page"
              />
            </Button>
            <br />
          </>
        )}
        <Button
          color="blue"
          size="small"
          background="transparent"
          border="borderDark"
          onClick={() => onModalOpen('email')}
        >
          <FormattedMessage
            id="adminUsersChangeEmailButton"
            defaultMessage="Change email"
            description="Button for changing user email on Admin users details page"
          />
        </Button>
        <br />
        <Button
          color="blue"
          size="small"
          background="transparent"
          border="borderDark"
          onClick={() => onModalOpen('company')}
        >
          <FormattedMessage
            id="adminUsersChangeCompanyButton"
            defaultMessage="Change user company"
            description="Button for changing the users company"
          />
        </Button>
        <br />
        <Button
          color={userAccessRights?.accessRights.some(right => right.type.toString() === 'Umbrella') ? 'error' : 'blue'}
          size="small"
          background="transparent"
          border="borderDark"
          onClick={() => onModalOpen('umbrella')}
        >
          {userAccessRights?.accessRights.some(right => right.type.toString() === 'Umbrella') ? (
            <FormattedMessage
              id="adminUserRevokeUmbrellaButton"
              defaultMessage="Revoke umbrella company admin"
              description="Button label for revoking user umbrella company admin access on Admin department user details "
            />
          ) : (
            <FormattedMessage
              id="adminUserPromoteUmbrellaButton"
              defaultMessage="Promote to umbrella company admin"
              description="Button label for promoting user umbrella company admin access on Admin department user details page"
            />
          )
          }
        </Button>
        <br />
        <Button
          color="error"
          size="small"
          background="transparent"
          border="borderDark"
          onClick={() => setShowConfirmDelete(true)}
        >
          <FormattedMessage
            id="adminUsersAnonymizeUserButton"
            defaultMessage="Anonymize user"
            description="Button for changing user email on Admin users details page"
          />
        </Button>
      </StaticCard>
      <ConfirmActionPopup
        isOpen={showConfirmDelete}
        onConfirm={() => {
          anonymizeUser({
            userEmail: email as string,
          });
          setShowConfirmDelete(false);
        }}
        onAbort={() => setShowConfirmDelete(false)}
      >
        <>
          <Heading tag={Tag.H4}>
            <FormattedMessage
              id="anonymizeUserConfirmHeading"
              defaultMessage="Anonymize user"
              description="Heading text in the anonymize user confirm popup"
            />
          </Heading>
          <FormattedMessage
            id="anonymizeUserConfirmBody"
            defaultMessage="Are you sure you want to anonymize this user?"
            description="Body text in the anonymize user popup"
          />
        </>
      </ConfirmActionPopup>
      <UpdateUserEmailModal
        user={data}
        isOpen={userModalOpen === 'email'}
        onClose={onModalClose}
      />
      <UpdateUserRegistrationCodeModal
        data={userRegistrationCodeResult.data ?? null}
        isOpen={userModalOpen === 'code'}
        onClose={onModalClose}
      />
      <UpdateUserUmbrellaAdminModal
        membershipId={data.membership.id ?? ''}
        umbrellaCompanyId={companyResult?.umbrellaCompanyId ?? ''}
        isOpen={userModalOpen === 'umbrella'}
        onClose={onModalClose}
      />
      <UpdateUserCompanyModal
        user={data}
        isOpen={userModalOpen === 'company'}
        onClose={onModalClose}
      />
    </ContentWidth>
  );
};

export default AdminUserDetails;
