import React, { FC, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { ColGrid, TwoColGrid } from 'styles';
import SelectField from 'components/UI/SelectField/SelectField';
import { useForm } from 'react-hook-form';
import {
  AgeTypesInQuizStatistics,
  GendersInQuizStatistics,
  OrganizationEntities,
  RolesInQuizStatistics,
} from 'models';
import { useRoleOptionsForStatistics } from 'hooks/useRoles';
import { useGenderOptionsForStatistics } from 'hooks/useGenders';
import { selectOptionsParser } from 'utils/array';

import { SmallGap } from './styles';
import { useAgeOptionsForStatistics } from 'hooks/useAgeOptions';

import { components } from 'generated/api';

export type ResultFilter = {
  organizationEntityTypeFilter: OrganizationEntities;
  organizationEntityDescriptionFilter: string | null;
  genderFilter: GendersInQuizStatistics;
  roleFilter: RolesInQuizStatistics;
  ageFilter: AgeTypesInQuizStatistics;
};

export type ResultData =
  | {
      organizationEntityType: OrganizationEntities | undefined;
      organizationEntityDescription: string | null | undefined;
      gender: GendersInQuizStatistics | undefined;
      role: RolesInQuizStatistics | undefined;
      age: AgeTypesInQuizStatistics | undefined;
    }
  | Pick<
      components['schemas']['QuizStatisticsResultDisplayDto'],
      | 'organizationEntityType'
      | 'organizationEntityDescription'
      | 'age'
      | 'role'
      | 'gender'
    >;

type Props = {
  results: ResultData[];
  baselineResults?: Array<{
    gender: components['schemas']['GenderInQuizStatistics'];
    role: components['schemas']['RolesInQuizStatistics'];
    age: components['schemas']['AgeInQuizStatistics'];
  }>;
  withAgeFilter?: boolean;
  resultCallback: (
    resultIndex: number | null,
    filters: ResultFilter,
    baselineIndex: number | null
  ) => void;
};

const CompanyResultFilter: FC<Props> = ({
  results,
  baselineResults,
  resultCallback,
  withAgeFilter = true,
}) => {
  const intl = useIntl();
  const { register, watch, setValue } = useForm<ResultFilter>({
    defaultValues: {
      organizationEntityTypeFilter: OrganizationEntities.Company,
      organizationEntityDescriptionFilter: null,
      genderFilter: GendersInQuizStatistics.All,
      roleFilter: RolesInQuizStatistics.All,
      ageFilter: 'All',
    },
  });

  const entityTypeFilter = watch('organizationEntityTypeFilter');
  const entityDescriptionFilter = watch('organizationEntityDescriptionFilter');
  const genderFilter = watch('genderFilter');
  const roleFilter = watch('roleFilter');
  const ageFilter = watch('ageFilter');

  useEffect(() => {
    setValue(
      'organizationEntityDescriptionFilter',
      results.find((item) => item.organizationEntityType === entityTypeFilter)
        ?.organizationEntityDescription || null
    );
  }, [entityTypeFilter, results, setValue]);

  // Entity type filter. Distinct entity types from averageResults
  const orgEntityTypeOptions = useMemo(
    () =>
      selectOptionsParser(
        results.map(
          ({ organizationEntityType }) => organizationEntityType ?? null
        )
      ),
    [results]
  );

  // Results matching the currently selected entity type
  const orgEntityTypeResults = useMemo(
    () =>
      results.filter(
        ({ organizationEntityType }) =>
          organizationEntityType === entityTypeFilter
      ),
    [entityTypeFilter, results]
  );

  // Organization description options that matches currently selected entity type
  const orgEntityDescriptionOptions = useMemo(
    () =>
      selectOptionsParser(
        orgEntityTypeResults.map(
          ({ organizationEntityDescription }) =>
            organizationEntityDescription ?? null
        )
      ),
    [orgEntityTypeResults]
  );

  const genderFilterOptions = useGenderOptionsForStatistics();
  const roleFilterOptions = useRoleOptionsForStatistics();
  const ageFilterOptions = useAgeOptionsForStatistics();

  useEffect(() => {
    resultCallback(
      results.findIndex(
        (item) =>
          item.organizationEntityType === entityTypeFilter &&
          item.organizationEntityDescription === entityDescriptionFilter &&
          item.gender === genderFilter &&
          item.role === roleFilter &&
          (withAgeFilter === false || item.age === ageFilter)
      ) ?? null,
      {
        organizationEntityTypeFilter: entityTypeFilter,
        organizationEntityDescriptionFilter: entityDescriptionFilter,
        genderFilter,
        roleFilter,
        ageFilter,
      },
      baselineResults?.findIndex(
        (item) =>
          item.gender === genderFilter &&
          item.role === roleFilter &&
          (withAgeFilter === false || item.age === ageFilter)
      ) ?? null
    );
  }, [
    entityTypeFilter,
    entityDescriptionFilter,
    genderFilter,
    roleFilter,
    results,
    resultCallback,
    withAgeFilter,
    ageFilter,
    baselineResults,
  ]);

  return (
    <>
      <TwoColGrid>
        <SelectField
          label={intl.formatMessage({
            id: 'filterOrganizationEntityTypeLabel',
            defaultMessage: 'Organization entity type',
            description: 'Label for the organization entity type filter',
          })}
          options={orgEntityTypeOptions}
          register={register('organizationEntityTypeFilter')}
        />
        <SelectField
          disabled={entityTypeFilter === 'Company'}
          label={intl.formatMessage({
            id: 'filterOrganizationEntityDescriptionLabel',
            defaultMessage: 'Organization entity description',
            description: 'Label for the organization entity description filter',
          })}
          options={orgEntityDescriptionOptions}
          register={register('organizationEntityDescriptionFilter')}
        />
      </TwoColGrid>
      <SmallGap />
      <ColGrid columns={withAgeFilter ? 3 : 2}>
        <SelectField
          label={intl.formatMessage({
            id: 'filterRoleLabel',
            defaultMessage: 'Role',
            description: 'Label for the role filter',
          })}
          options={roleFilterOptions}
          register={register('roleFilter')}
        />
        <SelectField
          label={intl.formatMessage({
            id: 'filterGenderLabel',
            defaultMessage: 'Gender',
            description: 'Label for the gender filter',
          })}
          options={genderFilterOptions}
          register={register('genderFilter')}
        />
        {withAgeFilter && (
          <SelectField
            label={intl.formatMessage({
              id: 'filterAgeLabel',
              defaultMessage: 'Age',
              description: 'Label for the age filter',
            })}
            options={ageFilterOptions}
            register={register('ageFilter')}
          />
        )}
      </ColGrid>
    </>
  );
};

export default CompanyResultFilter;
