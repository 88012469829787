import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { breakpoints } from 'styles';

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const MenuLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.colors.borderLight};
  border-radius: 16px;
  padding: 16px;
  gap: 16px;
  @media (min-width: ${breakpoints.m}px) {
    &:hover {
      color: ${({ theme }) => theme.colors.orange};
    }
  }
`;

export const Content = styled.span`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  gap: 12px;
`;
