import useBaselineResultText from 'hooks/useBaselineResultText';
import { FC } from 'react';
import { FlexContainer, ThemeColor } from 'styles';
import Icon, { IconType } from '../Icon';
import ProgressBar from '../ProgressBar';
import { BaselineIconWrapper, Container, ResultText } from './styles';

type Props = {
  progress: number | null;
  color?: ThemeColor | string;
  average: number | null;
  averageTitle: string | null;
};

const ProgressBarWithAverage: FC<Props> = ({
  progress,
  color = 'blue',
  average,
  averageTitle,
}) => {
  const resultText = useBaselineResultText(progress, average);
  return (
    <Container>
      <ProgressBar progress={progress} color={color} />
      {average != null && (
        <div>
          <FlexContainer gap={0} flexDirection="column">
            <BaselineIconWrapper
              baselinePercentage={average}
              title={averageTitle ?? undefined}
            >
              <Icon type={IconType.Triangle} color="blue" size={16} />
            </BaselineIconWrapper>
          </FlexContainer>
          <ResultText>{resultText}</ResultText>
        </div>
      )}
    </Container>
  );
};

export default ProgressBarWithAverage;
