import { useEffect, useState } from "react";

type Claims = {
  userid: string;
  membershipid: string;
  companyid: string;
  moduleaccessrights: string;
  type: string;
  accessrighttype: string;
  exp: number;
  iat: number;
  nbf: number;
};

const useClaims = (): Claims | null => {
  const tokenData = JSON.parse(localStorage.getItem('session') as string);

  if (!tokenData || !tokenData.accessToken) {
    return null;
  }

  const accessToken = tokenData.accessToken;
  const tokenParts = accessToken.split('.');
  const payloadPart = tokenParts[1];
  const base64UrlPayload = payloadPart.replace(/-/g, '+').replace(/_/g, '/');
  const payload = JSON.parse(window.atob(base64UrlPayload));

  return payload;
};

export default useClaims;