import React, { FC, Fragment, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { SubmitHandler, useForm } from 'react-hook-form';

import Button from 'components/UI/Button';
import TextField from 'components/UI/TextField';

import { components } from 'generated/api';
import { useUploadPredefinedTeamsMutation } from 'store';
import toast from 'react-hot-toast';

export interface UploadTeamsData {
  generateTeams: components['schemas']['GenerateTeamsCompanyAdmin'];
  start: string;
  stop?: string;
  title?: components['schemas']['Languages'];
}

interface UploadTeamsFields {
  file: FileList;
}

type Props = {
  contestId: string;
};

const UploadTeamsForm: FC<Props> = ({ contestId }) => {
  const intl = useIntl();

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<UploadTeamsFields>();

  const [uploadTeams, { isLoading, isSuccess }] =
    useUploadPredefinedTeamsMutation();

  const onSubmit: SubmitHandler<UploadTeamsFields> = (values) => {
    uploadTeams({ contestId, file: values.file[0] });
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success(
        intl.formatMessage({
          id: 'uploadTeamsSuccess',
          defaultMessage: 'Teams successfully uploaded',
          description:
            'Toast success text when successfully uploaded teams to a competition',
        })
      );
      reset();
    }
  }, [isSuccess, intl, reset]);

  return (
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          label={intl.formatMessage({
            id: 'inputTeamsFileInputLabel',
            defaultMessage: 'Teams excel file',
            description: 'Label for start date input',
          })}
          placeholder={intl.formatMessage({
            id: 'inputTeamsFileInputPlaceholder',
            defaultMessage: 'Enter start date',
            description: 'Placeholder for start date input',
          })}
          type="file"
          error={errors.file}
          register={register('file', { required: true })}
        />
        <Button background="blue" type="submit" disabled={isLoading}>
          <FormattedMessage
            id="uploadTeamsButton"
            defaultMessage="Upload teams"
            description="Upload competition teams button text"
          />
        </Button>
      </form>
    </Fragment>
  );
};

export default UploadTeamsForm;
