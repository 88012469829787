import { RolesInQuizStatistics } from 'models';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

type RoleOptionsForStatistics = Array<{
  name: string;
  value: RolesInQuizStatistics;
}>;

export const useRoleOptionsForStatistics = (): RoleOptionsForStatistics => {
  const intl = useIntl();
  return useMemo(
    () => [
      {
        name: intl.formatMessage({
          id: 'RoleSelectLabelAll',
          defaultMessage: 'All',
          description: 'Select label for all roles',
        }),
        value: RolesInQuizStatistics.All,
      },
      {
        name: intl.formatMessage({
          id: 'RoleSelectLabelEmployees',
          defaultMessage: 'Employees',
          description: 'Select label for employee role',
        }),
        value: RolesInQuizStatistics.Employees,
      },
      {
        name: intl.formatMessage({
          id: 'RoleSelectLabelManagers',
          defaultMessage: 'Managers',
          description: 'Select label for manager role',
        }),
        value: RolesInQuizStatistics.Managers,
      },
    ],
    [intl]
  );
};
