import { PageHeader } from 'models';
import { useEffect } from 'react';
import { setLayoutHeader } from 'store';
import { useAppDispatch } from './redux';

export const usePageHeader = (props: PageHeader) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setLayoutHeader(props));
  }, [dispatch, props]);
};
