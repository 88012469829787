import { FC, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';

import Button from 'components/UI/Button/Button';
import { ActionContainer } from 'styles';
import { useGetCompanyStructureQuery } from 'store';
import { SelectOption } from 'models';
import { FieldGrid } from './styles';
import Loader from 'components/UI/Loader';
import { usePretendCompany } from 'hooks/usePretendCompany';
import SelectMultiField from 'components/UI/SelectMultiField';

type Props = {
  submitForm: (data: CreateDepartmentFormData) => void;
};

export type CreateDepartmentFormData = {
  departmentIds: string[];
};

const UpdateUserDepartmentAdminForm: FC<Props> = ({ submitForm }) => {
  const intl = useIntl();
  const companyId = usePretendCompany();
  const { handleSubmit, setValue, formState: { errors } } = useForm<CreateDepartmentFormData>({
    defaultValues: {
      departmentIds: [],
    },
  });
  const { data, isLoading } = useGetCompanyStructureQuery({ companyId });

  const departmentOptions: SelectOption[] = useMemo(() => {
    if (!data) {
      return [];
    }

    const departments = (data.departments ?? []).map(({ id, description }) => ({
      value: id,
      name: description ?? '',
    }));

    const headOfficeDepartments = (data.headOffices ?? []).flatMap(
      (headoffice) =>
        (headoffice.offices ?? []).flatMap((office) =>
          (office.departments ?? []).map((department) => ({
            value: department.id,
            name: `${department.description} - ${office.description} (${headoffice.description})`,
          }))
        )
    );

    const officeDepartments = (data.offices ?? []).flatMap((office) =>
      (office.departments ?? []).map((department) => ({
        value: department.id,
        name: `${department.description} - ${office.description}`,
      }))
    );

    // concat and sort departments alphabetically
    return [
      ...departments,
      ...officeDepartments,
      ...headOfficeDepartments,
    ].sort((a, b) => a.name.localeCompare(b.name));
  }, [data]);

  const handleSelectedItemsChange = (selectedItems: string[]) => {
    setValue('departmentIds', selectedItems);
  };


  if (isLoading || !data) {
    return <Loader />;
  }

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <FieldGrid>
        <SelectMultiField
          label={intl.formatMessage({
            id: 'inputDepartmentLabel',
            defaultMessage: 'Departments',
            description: 'Label for department input',
          })}
          options={departmentOptions}
          defaultValue={[]}
          error={errors.departmentIds}
          onSelectedItemsChange={handleSelectedItemsChange}
        />
      </FieldGrid>
      <ActionContainer>
        <Button type="submit" background="blue">
          <FormattedMessage
            id="saveButton"
            defaultMessage="Save"
            description="Save button text"
          />
        </Button>
      </ActionContainer>
    </form>
  );
};

export default UpdateUserDepartmentAdminForm;
