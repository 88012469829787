import { FC, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import Button from 'components/UI/Button/Button';
import { ActionContainer } from 'styles';
import { useGetCompaniesQuery, useGetCompanyStructureQuery } from 'store';
import { SelectOption } from 'models';
import { FieldGrid } from './styles';
import Loader from 'components/UI/Loader';
import SelectField from 'components/UI/SelectField';

type Props = {
  submitForm: (data: UpdateUserCompanyFormData) => void;
};

export type UpdateUserCompanyFormData = {
  companyId: string;
  departmentId: string;
};

const UpdateUserCompanyForm: FC<Props> = ({ submitForm }) => {
  const intl = useIntl();
  const { handleSubmit, register, setValue, watch, formState: { errors } } = useForm<UpdateUserCompanyFormData>({
    defaultValues: {
      companyId: '',
      departmentId: '',
    },
  });

  const selectedCompanyId = watch('companyId');

  const { data, isLoading } = useGetCompaniesQuery();
  const { data: companyStructure } = useGetCompanyStructureQuery({ companyId: selectedCompanyId });


  // Set departmentId when company changes
  useEffect(() => {
    if (selectedCompanyId && companyStructure) {
      const firstDepartment = companyStructure.departments?.[0]; // Add nullish coalescing operator
      if (firstDepartment) {
        setValue('departmentId', firstDepartment.id);
      }
    }
  }, [selectedCompanyId, companyStructure, setValue]);


  const companyOptions: SelectOption[] = useMemo(() => {
    if (!data) {
      return [];
    }

    return (data ?? []).map(({ id, description }) => ({
      value: id,
      name: description ?? '',
    }));
  }, [data]);

  const departmentOptions: SelectOption[] = useMemo(() => {
    if (!companyStructure) {
      return [];
    }

    const departments = (companyStructure.departments ?? []).map(({ id, description }) => ({
      value: id,
      name: description ?? '',
    }));

    const headOfficeDepartments = (companyStructure.headOffices ?? []).flatMap(
      (headoffice) =>
        (headoffice.offices ?? []).flatMap((office) =>
          (office.departments ?? []).map((department) => ({
            value: department.id,
            name: `${department.description} - ${office.description} (${headoffice.description})`,
          }))
        )
    );

    const officeDepartments = (companyStructure.offices ?? []).flatMap((office) =>
      (office.departments ?? []).map((department) => ({
        value: department.id,
        name: `${department.description} - ${office.description}`,
      }))
    );

    // concat and sort departments alphabetically
    return [
      ...departments,
      ...officeDepartments,
      ...headOfficeDepartments,
    ].sort((a, b) => a.name.localeCompare(b.name));
  }, [companyStructure]);

  if (isLoading || !data) {
    return <Loader />;
  }

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <FieldGrid>
        <SelectField
          register={register('companyId', { required: 'Company is required' })}
          label={intl.formatMessage({
            id: 'inputCompanieLabel',
            defaultMessage: 'Companies',
            description: 'Label for companie input',
          })}
          options={companyOptions}
          defaultValue={''}
          error={errors.companyId}
        />
        <SelectField
          register={register('departmentId', { required: 'Department is required' })}
          label={intl.formatMessage({
            id: 'inputDepartmentLabel',
            "defaultMessage": "Department",
            "description": "Label for department input"
          })}
          options={departmentOptions}
          defaultValue={''}
          error={errors.departmentId}
        />
      </FieldGrid>
      <ActionContainer>
        <Button type="submit" background="blue">
          <FormattedMessage
            id="saveButton"
            defaultMessage="Save"
            description="Save button text"
          />
        </Button>
      </ActionContainer>
    </form>
  );
};

export default UpdateUserCompanyForm;
