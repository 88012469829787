import { FC, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useUpdateContestDateMutation } from 'store';

import Icon, { IconType } from 'components/UI/Icon';
import IconButton from 'components/UI/IconButton';
import Heading, { Tag } from 'components/UI/Heading';
import SlideoutModal from 'components/UI/SlideoutModal';

import { Header, Body } from './styles';
import { useForm } from 'react-hook-form';
import { ActionContainer } from 'styles';
import Button from 'components/UI/Button';
import TextField from 'components/UI/TextField';
import Loader from 'components/UI/Loader';

type UpdateCompetitionDateForm = {
  start: string;
  stop: string;
};

type Props = {
  id: string;
  isOpen: boolean;
  onClose: () => void;
  contestStop: string;
};

const UpdateCompetitionDatesModal: FC<Props> = ({
  id,
  isOpen,
  onClose,
  contestStop,
}) => {
  const intl = useIntl();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UpdateCompetitionDateForm>();


  const [updateDates, { isLoading }] =
    useUpdateContestDateMutation();

  // Content
  const content = useMemo(() => {
    if (isLoading) {
      return <Loader color="blue" padding />;
    }
    return (
      <form
        onSubmit={handleSubmit(async (form) => {
          const { start, stop } = form;
          const adjustedStop = new Date(new Date(stop).getTime() - 2 * 60 * 60 * 1000).toISOString();
          await updateDates({
            id,
            start,
            stop: adjustedStop
          })
          onClose();
        })}
      >
        <TextField
          label={intl.formatMessage({
            id: 'inputEndDateLabel',
            defaultMessage:
              'Contest stop time',
            description: 'Label for stop time compoetition input',
          })}
          type="datetime-local"
          error={errors.stop}
          register={register('stop')}
          defaultValue={contestStop}
        />
        <ActionContainer>
          <Button type="submit" background="blue">
            <FormattedMessage
              id="updateButton"
              defaultMessage="Update"
              description="Update button text"
            />
          </Button>
        </ActionContainer>
      </form>
    );
  }, [isLoading, handleSubmit, intl, errors.stop, register, contestStop, updateDates, id, onClose]);

  return (
    <SlideoutModal isOpen={isOpen} onClose={onClose}>
      <div>
        <Header>
          <Heading tag={Tag.H4}>
            <FormattedMessage
              id="contestUpdateNewDateLabel"
              defaultMessage="Update contest date"
              description="Label for update contest date"
            />
          </Heading>
          <IconButton onClick={onClose} padding>
            <Icon type={IconType.Close} />
          </IconButton>
        </Header>
        <Body>{content}</Body>
      </div>
    </SlideoutModal>
  );
};

export default UpdateCompetitionDatesModal;
