import { FC, ReactNode, MouseEvent } from 'react';
import { ButtonBase, ButtonBgColor, ButtonColor, ButtonSize } from './styles';

export type Props = {
  children: ReactNode;
  color?: ButtonColor;
  background?: ButtonBgColor;
  border?: ButtonColor;
  size?: ButtonSize;
  type?: 'submit' | 'reset' | 'button';
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  marginRight?: boolean;
  disabled?: boolean;
  backgroundOpacity?: number;
};

const Button: FC<Props> = ({
  children,
  onClick,
  border,
  type = 'button',
  color = 'white',
  background = 'black',
  size = 'large',
  disabled = false,
  marginRight = false,
  backgroundOpacity
}) => {
  return (
    <ButtonBase
      type={type}
      backgroundOpacity={backgroundOpacity}
      size={size}
      color={color}
      border={border}
      background={background}
      marginRight={marginRight}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </ButtonBase>
  );
};

export default Button;
