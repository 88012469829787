import { NotificationSchedule } from "models/notificationSchedule/notificationSchedule";

export function groupNotificationSchedules(schedules: NotificationSchedule[]) {
  const grouped: NotificationSchedule[] = [];
  schedules.forEach((schedule) => {
    const found = grouped.find((item) => item.creationAndSendDateOfNotification === schedule.creationAndSendDateOfNotification);
    if (!found) {
      grouped.push(schedule);
    }
  });
  return grouped;
}