import { FC, Fragment, useCallback, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import * as routes from 'router/routes';
import { useLocalizedText } from 'hooks/useLocalizedText';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { getCategoryIcon } from 'utils/asset';
import {
  localizationSelector,
  setLayoutHeader,
  useGetMeasureQuery,
} from 'store';
import { ContentWidth, Gap } from 'styles';

import MeasureStepCard from 'components/cards/MeasureStepCard';
import Heading, { Tag } from 'components/UI/Heading';
import HeroSmall from 'components/UI/Heros/HeroSmall';
import { IconType } from 'components/UI/Icon';
import EmptyState from 'components/UI/EmptyState';
import Loader from 'components/UI/Loader';
import WellrCoins from 'components/UI/WellrCoins';
import RichText from 'components/fragments/RichText';

import { CardList, HealthCategory, Title } from './styles';

const MeasureProgram: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const getText = useLocalizedText();
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { slug } = useParams();
  const { language } = useAppSelector(localizationSelector);

  // Hooks
  const { data, isLoading } = useGetMeasureQuery({
    language: language?.languageCode,
    slug,
  });

  const measureSection = useCallback(
    (node: HTMLDivElement) => {
      if (node !== null) {
        setTimeout(() => {
          node.scrollIntoView({ behavior: 'smooth' });
          navigate('', {
            state: { ...location.state, scrollToMeasures: false },
          });
        }, 500);
      }
    },
    [location.state, navigate]
  );

  // Set header
  useEffect(() => {
    dispatch(
      setLayoutHeader({
        title: intl.formatMessage({
          id: 'backButton',
          defaultMessage: 'Back',
          description: 'Back button text',
        }),
        inverted: true,
        icon: IconType.Back,
        link: routes.MEASURES,
      })
    );
  }, [dispatch, intl]);

  // Actions
  const onStepClick = useCallback(
    (slug: string, stepId: string) => async () =>
      navigate(`${routes.MEASURES}/${slug}/step/${stepId}`),
    [navigate]
  );

  // Loading
  if (isLoading) {
    return <Loader color="blue" padding />;
  }

  if (!data) {
    return (
      <EmptyState iconType={IconType.Health} padding>
        <FormattedMessage
          id="pageMeasureProgramEmptyState"
          defaultMessage="Measure program not found"
          description="Empty state for measure program"
        />
      </EmptyState>
    );
  }

  const { content, healthCategory } = data;
  const img = getCategoryIcon(healthCategory.icon, true);

  return (
    <Fragment>
      <HeroSmall>
        <HealthCategory>
          {img && <img src={img.src} alt={img.alt} color="white" />}
          {getText(healthCategory.title)}
        </HealthCategory>
        <Title>{getText(data.title)}</Title>
        <WellrCoins count={data.wellrPoints} inverted />
      </HeroSmall>
      <ContentWidth isSurface noMargins>
        <Heading tag={Tag.H4}>
          <FormattedMessage
            id="pageMeasureProgramAboutTitle"
            defaultMessage="About the program"
            description="Section title for about the measure program"
          />
        </Heading>
        {content && <RichText>{content}</RichText>}
        <Gap />
        <Heading tag={Tag.H4}>
          <FormattedMessage
            id="pageMeasureProgramStartTitle"
            defaultMessage="Start here"
            description="Section title for start the measure program"
          />
        </Heading>
        <div ref={location.state?.scrollToMeasures ? measureSection : null} />
        <CardList>
          {data.steps.map((step, i) => (
            <MeasureStepCard
              key={step.id}
              title={step.title}
              isCompleted={step.completed}
              stepNumber={i + 1}
              onClick={onStepClick(data.slug, step.id)}
            />
          ))}
        </CardList>
      </ContentWidth>
    </Fragment>
  );
};

export default MeasureProgram;
