import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const BaselineIconWrapper = styled.div<{ baselinePercentage: number }>`
  text-align: left;
  cursor: pointer;
  position: relative;
  top: -4px;
  left: ${({ baselinePercentage }) => `calc(${baselinePercentage}% - 8px)`};
`;

export const ResultText = styled.p`
  color: ${({ theme }) => theme.colors.grayText};
  font-size: 14px;
  text-align: left;
  margin: -6px 0 0 3px;
`;
