import React, { FC } from 'react';

import Icon, { IconType } from 'components/UI/Icon';

import { Button, IconContainer } from './styles';

type Props = {
  id: string;
  position: 'left' | 'right';
  hideOnDisabled?: boolean;
  hideOnMobile?: boolean;
};

const SliderNav: FC<Props> = ({
  id,
  position,
  hideOnDisabled = false,
  hideOnMobile = true,
}) => {
  return (
    <Button
      className={
        position === 'left' ? `prev-slide-btn-${id}` : `next-slide-btn-${id}`
      }
      position={position}
      hideOnDisabled={hideOnDisabled}
      hideOnMobile={hideOnMobile}
    >
      <IconContainer position={position}>
        <Icon type={IconType.Arrow} />
      </IconContainer>
    </Button>
  );
};

export default SliderNav;
